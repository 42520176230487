import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { enum_api_uri, enum_ip_status, enum_mo_title } from "../../../config/enum";
import { useDispatch } from "react-redux";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle } from "../../../store/commonSlice";


const MoReceiving = () => {
    const a_list_invoces = enum_api_uri.a_list_invoces;
    const a_list_product = enum_api_uri.a_list_product;
    registerLocale('ko', ko);
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const location = useLocation();
    const [invoiceList, setInvoiceList] = useState([]);
    const [onInvoice, setOnInvoice] = useState(0);
    const [mine, setMine] = useState(false);
    const basicDate = new Date();
    const navigate = useNavigate();

    // 내 자료보기 체크
    const setMineCheck = (e) => {
        if(e.target.checked){
            setMine(true);
        }else{
            setMine(false);
        }
    };

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    useEffect(()=>{
        setInvoiceList(invoiceList);
    },[invoiceList]);


    // 페이지들어오면 최근송품장리스트 뿌려주기
    useEffect(()=>{
        let today = moment(basicDate).format('YYYYMMDD');
        axios.get(`${a_list_invoces}?ip_status=&ip_s_kind=&ip_date_start=${today}&ip_date_end=${today}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                console.log(res.data);
                let data = res.data;
                setInvoiceList(data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);

    // 입하신청자료 조회 -> 송품장리스트
    const inquireSubmit = (values) => {
        let regex = /[^0-9]/g;
        let ip_status = values.ip_status.replace(regex,'');
        let ip_s_kind = values.ip_s_kind.replace(regex,'');
        let ip_date_start = moment(values.ip_date_start).format('YYYYMMDD');
        let ip_date_end = moment(values.ip_date_end).format('YYYYMMDD');
        let my_trans = '';
        if(mine == true){
            my_trans = true;
        }
        axios.get(`${a_list_invoces}?ip_status=${ip_status}&ip_s_kind=${ip_s_kind}&ip_date_start=${ip_date_start}&ip_date_end=${ip_date_end}&my_trans=${my_trans}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                setInvoiceList(data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //송품장리스트 클릭시 해당송품장 상세페이지로 이동
    const invoiceClickHandler = (invo) => {
        window.location.href = '/auctioneer/mo/receiving/detail/'+invo.ip_date_online+'-'+invo.ip_no_online;
    };

    return(
        <div className="page_sub">
            <Formik
                initialValues={{
                    ip_date_start: basicDate || "",
                    ip_date_end: basicDate || "",
                    ip_s_kind: "",
                    ip_status: "",
                }}
                onSubmit={inquireSubmit}
            >
                {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="search_box">
                            <ul>
                                <li>
                                    <p className="txt">신청일자</p>
                                    <div>
                                        <div className="flex_end">
                                            <div className="custom_check bp5">
                                                <label htmlFor="saveId">
                                                    <input type="checkbox" id="saveId" onChange={e => setMineCheck(e)} checked={mine} />
                                                    <span className="check">체크</span>
                                                    <span className="txt w_100">내 자료보기</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="date_input">
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={values.ip_date_start}
                                                    onChange={date => setFieldValue('ip_date_start', date)}
                                                    locale="ko"
                                                    dateFormat="yyyy-MM-dd"
                                                    name="ip_date_start"
                                                />
                                            </div>
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={values.ip_date_end}
                                                    onChange={date => setFieldValue('ip_date_end', date)}
                                                    locale="ko"
                                                    dateFormat="yyyy-MM-dd"
                                                    name="ip_date_end"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="txt">구분</p>
                                    <div className="custom_select">
                                        <select 
                                            name="ip_s_kind"
                                            value={values.ip_s_kind}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ display: 'block' }}
                                        > 
                                            <option value="">전체</option>
                                            <option value="ip_s_kind_1">입찰</option>
                                            <option value="ip_s_kind_2">경매</option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <p className="txt">상태</p>
                                    <div className="flex_between sel_btn_box">
                                        <div className="custom_select">
                                            <select 
                                                name="ip_status"
                                                value={values.ip_status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ display: 'block' }}
                                            > 
                                                <option value="">전체</option>
                                                <option value="ip_status_1">입하대기</option>
                                                <option value="ip_status_2">입하승인(입찰대기)</option>
                                            </select>
                                        </div>
                                        <button className="btn_s2" type="submit">조회</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </form>
                )}
            </Formik>
            <div className="bottom_box tm10">
                <div className="scroll_wrap">
                    <div className="custom_table gray_table">
                        <table>
                            <colgroup>
                                <col style={{width:'auto'}} />
                                <col style={{width:'23%'}} />
                                <col style={{width:'23%'}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="tx_c">출하처</th>
                                    <th className="tx_c">상태</th>
                                    <th className="tx_c">경매사</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceList.length > 0 ? invoiceList.map((invoice,i)=>{
                                    let ip_status = Number(invoice.ip_status.trim());
                                        ip_status = enum_ip_status[ip_status][0];
                                    return(
                                        <tr key={i} 
                                            onClick={()=>{
                                                setOnInvoice(i);
                                                let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                invoiceData = {
                                                    ip_date_online:invoice.ip_date_online.trim(),
                                                    ip_no_online:invoice.ip_no_online.trim(),
                                                    ip_s_kind:invoice.ip_s_kind.trim(),
                                                    ip_kind:invoice.ip_kind.trim(),
                                                    ip_loca:invoice.ip_loca.trim(),
                                                };
                                                localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                invoiceClickHandler(invoice);
                                            }}
                                            className={`${onInvoice === i ? "on" : null}`}
                                        >
                                            <td>{invoice.as_name}</td>
                                            <td className="tx_c">{ip_status}</td>
                                            <td className="tx_c">{invoice.ip_auct_name}</td>
                                        </tr>    
                                    );
                                })
                                : <tr><td colSpan="3" className="tx_c">데이터가 없습니다.</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MoReceiving;