import { useState,useEffect,useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { isMobile, isBrowser } from 'react-device-detect';
import {enum_api_uri,enum_ip_status,enum_sell_kind,enum_from_kind,enum_mo_title} from "../../../config/enum";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { confirmPop, closePop } from "../../../store/popupSlice";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import LeftCont from "../../../components/pc/component/LeftCont";
import ProductInfo from "../../../components/pc/component/ProductInfo";
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import history from "../../../config/history";

const ShipmentProductsDetail = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const { spId } = useParams();
    const m_detail_ship_product = enum_api_uri.m_detail_ship_product;
    const c_search_origin = enum_api_uri.c_search_origin;
    const m_memo_insert = enum_api_uri.m_memo_insert;
    const token = localStorage.getItem("token");
    const [detail, setDetail] = useState([]);
    const [locInput, setLocInput] = useState();
    const [locTxt, setLocTxt] = useState([]);
    const [proList, setProList] = useState([]);
    const [proPrice, setProPrice] = useState('');
    const [proMemo, setProMemo] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);
    const [errorTxt, setErrorTxt] = useState([]);

    useEffect(()=>{
        setErrorTxt(errorTxt);
        console.log(errorTxt);
    },[errorTxt]);

    //상세페이지 뒤로가기
    useEffect(() => {

        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf('/');
            let tit = path.substring(0, idx);
                tit = enum_mo_title[tit];
            dispatch(moTitle(tit));
        }
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(() => {
        getDetail(spId);
    },[]);

    useEffect(() => {
        setDetail(detail);
    },[detail]);

    useEffect(() => {
        setLocTxt(locTxt);
    },[locTxt]);

    useEffect(() => {
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setProPrice(proPrice);
    },[proPrice]);

    useEffect(() => {
        setProMemo(proMemo);
    },[proMemo]);


    //출하물품 상세정보 받아오기(상단)
    const getDetail = async(spId) =>{
        let dinfoArr = spId.split('-');
        let ip_date_online = dinfoArr[0]
        let ip_code_online = dinfoArr[1]
              

            axios.get(`${m_detail_ship_product.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_code_online)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                
                //날짜 형식 변경
                
                res.data.deli_start_date  = CF.MDFWithOutHour(res.data.ip_delivery_date_start); //moment(res.data.ip_delivery_date_start).format('YYYY/MM/DD');
                res.data.deli_start_end  = CF.MDFWithOutHour(res.data.ip_delivery_date_end); // moment(res.data.ip_delivery_date_end).format('YYYY/MM/DD');

                // //시작일시&종료일시
                if(res.data.ip_auct_date_start.trim() !=""){res.data.auct_start_date  = CF.MDFWithHour(res.data.ip_auct_date_start)}
                if(res.data.ip_auct_date_end.trim() !=""){res.data.auct_end_date  = CF.MDFWithHour(res.data.ip_auct_date_end)}

                //매매구분 (입찰/경매)
                let ipStatus = res.data.ip_status.trim();
                res.data.ipStatus = enum_ip_status[ipStatus][0];

                //매매구분 (입찰/경매)
                let inv_type = res.data.ip_s_kind.trim();
                res.data.inv_type = enum_sell_kind[inv_type];

                //판매구분 (국내/수입)
                let from_type = res.data.ip_kind.trim();
                res.data.from_type = enum_from_kind[from_type];

                //출하주
                res.data.as_name = res.data.as_name.trim();

                //경매사
                res.data.auct_name = (res.data.ip_auct_name === null) ? '미정' : res.data.ip_auct_name;
                                
                //산지
                // let loc = res.data.ip_loca.trim()
                // setLocInput(loc)
                res.data.ip_loca_name = CF.objValueTrim(res.data.ip_loca_name);

                //상세항목 넣기
                setDetail(res.data);

                //하단 등록된 물품 
                setProList(res.data.detltrns);
                
                //에러문구
                let error = res.data.detltrns.map(()=>{
                    return false;
                });
                setErrorTxt(error);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //메모저장하기
    const memoSubmit = async (values, idx, deDate, deNo, deSeq, deSeq2) => {
        let proPrice = CF.RemoveIntComma(values.proPrice);
        let body = {
            price: proPrice,
            memo: values.proMemo
        };
        if(proPrice > 0){
            axios.post (`${m_memo_insert.replace(':ip_date_online', detail.ip_date_online).replace(':ip_no_online', detail.ip_no_online).replace(':de_date_online', deDate).replace(':de_no_online', deNo).replace(':de_seq', deSeq).replace(':de_seq2', deSeq2)}`, body,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'메모가 저장되었습니다.',
                        confirmPopBtn:1,
                    }));
                    setConfirm(true);
                    getDetail(spId);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({	
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }else{
            let copy = [...errorTxt]
            copy[idx] = true;
            setErrorTxt(copy);
        }
    };

    //메모삭제하기
    const memoDelt = (deDate, deNo, deSeq, deSeq2,{setFieldValue}) => {
        axios.delete (`${m_memo_insert.replace(':ip_date_online', detail.ip_date_online).replace(':ip_no_online', detail.ip_no_online).replace(':de_date_online', deDate).replace(':de_no_online', deNo).replace(':de_seq', deSeq).replace(':de_seq2', deSeq2)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'메모가 삭제되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
                getDetail(spId);
                setFieldValue("proPrice", "");
                setFieldValue("proMemo", "");
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //목록으로 이동 (뒤로가기)
    const pageBack = () => {
        history.back();
    };

    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">출하물품 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>출하물품 조회</li>
                                </ul>
                            </div>
                            <div className="flex_between bp10">
                                <p className="medium">출하물품 상세</p>
                                <span className="tag">경매사 : {detail.auct_name}</span>
                            </div>
                            <div className="custom_table">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "13%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="tx_l">등록번호</th>
                                            <td className="tx_l txt_gray">{spId}</td>
                                            <th className="tx_l">출하주</th>
                                            <td className="tx_l txt_gray">{detail.as_name}</td>
                                            <th className="tx_l">현재상태</th>
                                            <td className="tx_l"><span className="tag">{detail.ipStatus}</span></td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">매매구분</th>
                                            <td className="tx_l txt_gray">{detail.inv_type}</td>
                                            <th className="tx_l">시작일시</th>
                                            {/* <td className="tx_l txt_gray">08월08일 13시00분</td> */}
                                            <td className="tx_l txt_gray">{detail.auct_start_date}</td>
                                            <th className="tx_l">팰릿</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">판매구분</th>
                                            <td className="tx_l txt_gray">{detail.from_type}</td>
                                            <th className="tx_l">종료일시</th>
                                            {/* <td className="tx_l txt_gray">08월08일 13시00분</td> */}
                                            <td className="tx_l txt_gray">{detail.auct_end_date}</td>
                                            <th className="tx_l">목재(옥타곤)</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">산지</th>
                                            <td className="tx_l txt_gray" colSpan="5">{detail.ip_loca_name} ({detail.ip_loca})</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">배송가능기간</th>
                                            {/* <td className="tx_l txt_gray" colSpan="5">2022/10/12  ~ 2022/10/12</td> */}
                                            <td className="tx_l txt_gray" colSpan="5">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="inner_box line_top">
                            <p className="medium bp20">등록된 물품 : {proList.length}건</p>
                            
                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_pro">
                                        <p>등록된 물품이 없습니다.</p>
                                    </div>
                                )}
                            {proList.length > 0 && proList.map((detltrns,i) => {

                                return (
                                    <div key={i} className="bm30">
                                        <ProductInfo 
                                            deFarmName={detltrns.de_farm_name || ""}
                                            deGodsName={detltrns.gm_name}
                                            deWeight={detltrns.de_weight || ""}
                                            dePacking={detltrns.de_packing_name || ""}
                                            deQuality={detltrns.de_quality_name || ""}
                                            deHigh={detltrns.de_high_name || ""}
                                            deQuantity={detltrns.de_quantity || ""}
                                            deCost2={detltrns.de_cost2 || ""}
                                            deDansu={detltrns.de_dansu || ""}
                                            deHopePri={detltrns.de_hope_pri || ""}
                                            deCont1={detltrns.de_cont1 || ""}
                                            deCont2={detltrns.de_cont2 || ""}
                                            deCont3={detltrns.de_cont3 || ""}
                                            deImg1={detltrns.de_img1 || ""}
                                            deImg2={detltrns.de_img2 || ""}
                                            deVideo={detltrns.de_video || ""}
                                        />

                                        {/* 경매일때만 단가메모, 입찰자메모입력폼 보임 */}
                                        {detail.inv_type == '경매' && detltrns.de_status < 9 &&
                                            <div className="custom_table t_border2 tm12">
                                                <Formik
                                                    initialValues={{
                                                        proPrice: detltrns.memo.price || '',
                                                        proMemo: detltrns.memo.memo || '',
                                                    }}
                                                    onSubmit={(e) => {
                                                        memoSubmit(e, i, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                                    }}
                                                >
                                                    {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                                        <form onSubmit={handleSubmit}>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: "13%" }} />
                                                                    <col style={{ width: "15%" }} />
                                                                    <col style={{ width: "13%" }} />
                                                                    <col style={{ width: "auto" }} />
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="tx_l">단가 메모 (원)</th>
                                                                        <td>
                                                                            <div className="custom_input h_40">
                                                                                <NumericFormat thousandSeparator="," decimalScale={0} value={values.proPrice} name="proPrice" onChange={handleChange} className="tx_r" />
                                                                            </div>
                                                                        </td>
                                                                        <th className="tx_l">입찰자메모</th>
                                                                        <td>
                                                                            <div className="flex_between input_btn input_btn2">
                                                                                <div className="custom_input h_40">
                                                                                    <input
                                                                                    id="proMemo"
                                                                                    name="proMemo"
                                                                                    type="text"
                                                                                    value = {values.proMemo}
                                                                                    onChange={handleChange}
                                                                                    />
                                                                                    <button  type ="button" className="btn_delt" onClick={() => setFieldValue("proMemo", "")}>지우기버튼</button>
                                                                                </div>
                                                                                <button type ="submit" className="btn_s2">메모저장</button>
                                                                                <button type ="button" onClick={()=>{
                                                                                    if(values.proPrice){
                                                                                        memoDelt(detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2,{setFieldValue});
                                                                                    }else{
                                                                                        dispatch(confirmPop({
                                                                                            confirmPopTit:'알림',
                                                                                            confirmPopTxt:'저장된 메모가없습니다.',
                                                                                            confirmPopBtn:1,
                                                                                        }));
                                                                                        setConfirm(true);
                                                                                    }
                                                                                }} className="btn_s4">메모삭제</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {errorTxt[i] === true &&
                                                                <div className="alert_txt f_12">단가메모를 입력해주세요.</div>
                                                            }
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        }

                                        {/* 경매일때만 단가메모, 입찰자메모보임 */}
                                        {detail.inv_type == '경매' && detltrns.de_status >= 9 &&
                                            <div className="custom_table t_border2 tm12 wide_table">
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: "13%" }} />
                                                        <col style={{ width: "15%" }} />
                                                        <col style={{ width: "13%" }} />
                                                        <col style={{ width: "auto" }} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th className="tx_l">단가 메모 (원)</th>
                                                            <td className="tx_r">{detltrns.memo.price && CF.MakeIntComma(detltrns.memo.price) + "원"}</td>
                                                            <th className="tx_l">입찰자메모</th>
                                                            <td className="tx_l">{detltrns.memo.memo}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                )})}
                        </div>
                        <div className="rp30 bp30 lp30 flex_end">
                            <button className="btn_type4" onClick={pageBack}>목록으로 이동</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <div className="inner_box">
                    <div className="flex_between bp10 tp20 rp10 lp10">
                        <p className="medium f_16">출하물품 상세</p>
                        <span className="tag">경매사 : {detail.auct_name}</span>
                    </div>
                    <div className="custom_table gray_table">
                        <table>
                            <colgroup>
                                <col style={{ width: "24%" }} />
                                <col style={{ width: "26%" }} />
                                <col style={{ width: "24%" }} />
                                <col style={{ width: "26%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록번호</th>
                                    <td colSpan="3">{spId}</td>
                                </tr>
                                <tr>
                                    <th>출하주</th>
                                    <td colSpan="3">{detail.as_name}</td>
                                </tr>
                                <tr>
                                    <th>현재상태</th>
                                    <td><span className="tag">{detail.ipStatus}</span></td>
                                    <th>매매구분</th>
                                    <td>{detail.inv_type}</td>
                                </tr>
                                <tr>
                                    <th>시작일시</th>
                                    <td colSpan="3">{detail.auct_start_date}</td>
                                </tr>
                                <tr>
                                    <th>판매구분</th>
                                    <td colSpan="3">{detail.from_type}</td>
                                </tr>
                                <tr>
                                    <th>산지</th>
                                    <td colSpan="3">{detail.ip_loca_name} ({detail.ip_loca})</td>
                                </tr>
                                <tr>
                                    <th>팰릿</th>
                                    <td className="tx_r">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                    <th className="rp0">목재(옥타곤)</th>
                                    <td className="tx_r">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                                </tr>
                                
                                <tr>
                                    <th className="rp0">배송가능기간</th>
                                    <td colSpan="3">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="inner_box line_top tp20">
                    <p className="medium bp10 lp10">등록된 물품 : <span className="txt_sky">{proList.length}</span>건</p>
                    
                        {/* 등록물품 없을때 ▼ */}
                        {proList.length === 0 && (
                            <div className="none_pro">
                                <p>등록된 물품이 없습니다.</p>
                            </div>
                        )}
                    {proList.length > 0 && proList.map((detltrns,i) => {
                        return (
                            <div key={i} className="bm30">
                                <ProductInfo 
                                    deFarmName={detltrns.de_farm_name.trim() || ""}
                                    deFarmCode={detltrns.de_farm_code.trim() || ""}
                                    deGodsName={detltrns.gm_name}
                                    deWeight={detltrns.de_weight || ""}
                                    dePacking={detltrns.de_packing_name || ""}
                                    deQuality={detltrns.de_quality_name || ""}
                                    deHigh={detltrns.de_high_name || ""}
                                    deQuantity={detltrns.de_quantity || ""}
                                    deCost2={detltrns.de_cost2 || ""}
                                    deDansu={detltrns.de_dansu || ""}
                                    deHopePri={detltrns.de_hope_pri || ""}
                                    deCont1={detltrns.de_cont1 || ""}
                                    deCont2={detltrns.de_cont2 || ""}
                                    deCont3={detltrns.de_cont3 || ""}
                                    deImg1={detltrns.de_img1 || ""}
                                    deImg2={detltrns.de_img2 || ""}
                                    deVideo={detltrns.de_video || ""}
                                />

                                {/* 경매일때만 단가메모, 입찰자메모입력폼 보임 */}
                                {detail.inv_type == '경매' && detltrns.de_status < 9 &&
                                    <div className="custom_table gray_table t_border2 tm5">
                                        <Formik
                                            initialValues={{
                                                proPrice: detltrns.memo.price || '',
                                                proMemo: detltrns.memo.memo || '',
                                            }}
                                            onSubmit={(e) => {
                                                memoSubmit(e, i, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                            }}
                                        >
                                            {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                                <form onSubmit={handleSubmit}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: "30%" }} />
                                                            <col style={{ width: "70%" }} />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th>단가 메모 (원)</th>
                                                                <td>
                                                                    <div className="custom_input">
                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={values.proPrice} name="proPrice" onChange={handleChange} className="tx_r" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>입찰자메모</th>
                                                                <td>
                                                                    <div className="input_btn">
                                                                        <div className="custom_input w_100">
                                                                            <input
                                                                                id="proMemo"
                                                                                name="proMemo"
                                                                                type="text"
                                                                                value = {values.proMemo}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <button  type ="button" className="btn_delt" onClick={() => setFieldValue("proMemo", "")}>지우기버튼</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn_half_box tm10">
                                                                        <button type ="submit" className="btn_s2">메모저장</button>
                                                                        <button type ="button" onClick={()=>{
                                                                            if(values.proPrice){
                                                                                memoDelt(detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2,{setFieldValue});
                                                                            }else{
                                                                                dispatch(confirmPop({
                                                                                    confirmPopTit:'알림',
                                                                                    confirmPopTxt:'저장된 메모가없습니다.',
                                                                                    confirmPopBtn:1,
                                                                                }));
                                                                                setConfirm(true);
                                                                            }
                                                                        }} className="btn_s4">메모삭제</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {errorTxt[i] === true &&
                                                        <div className="alert_txt f_12">단가메모를 입력해주세요.</div>
                                                    }
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                }

                                {/* 경매일때만 단가메모, 입찰자메모 보임 */}
                                {detail.inv_type == '경매' && detltrns.de_status >= 9 &&
                                    <div className="custom_table gray_table t_border2 tm5">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "30%" }} />
                                                <col style={{ width: "70%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>단가 메모 (원)</th>
                                                    <td className="tx_r">{detltrns.memo.price && CF.MakeIntComma(detltrns.memo.price) + "원"}</td>
                                                </tr>
                                                <tr>
                                                    <th>입찰자메모</th>
                                                    <td>{detltrns.memo.memo}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                    )})}
                </div>
                <div className="cont2 flex_end">
                    <button className="btn_type4 bm20" onClick={pageBack}>목록으로 이동</button>
                </div>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default ShipmentProductsDetail;