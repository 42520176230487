import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { closePop, restartAuction, confirmPop } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";
import { enum_api_uri } from "../../../../config/enum";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "../Function";
import moment from "moment";
import { useSocket } from "../../../SocketProvider";


const AuctionViewPop = () => {
    const m_auction = enum_api_uri.m_auction;
    const m_auction_products = enum_api_uri.m_auction_products;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);
    const [auctList, setAuctList] = useState([]);
    const [proList, setProList] = useState([]);
    const socket = useSocket();

    useEffect(() => {
        //소켓 연결
        if (socket) {
            socket.emit(
                "realtimeinit",
                {
                    room: "realtime",
                },
            );

        }

        //온라인 경매 낙찰물품리스트 가져오기
        socket.on("realtime" , (msg) => {
            console.log(msg);
            console.log(`rec: ${JSON.stringify(msg, null, 2)}`);
            setProList(prevProList => [...msg, ...prevProList]);
        });
    }, [socket]);

    //팝업닫기
    const closePopHandler = () => {
        dispatch(closePop(null));
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setAuctList(auctList);
    },[auctList]);

    useEffect(()=>{
        setProList(proList);
    },[proList]);


    useEffect(()=>{
        //온라인 경매리스트 가져오기
        axios.get(`${m_auction}`)
        .then((res)=>{
            if(res.status === 200){
                setAuctList(res.data);
            }
        })
        .catch((error) => {
            // const err_msg = CF.errorMsgHandler(error);
            // dispatch(confirmPop({
            //     confirmPopTit:'알림',
            //     confirmPopTxt: err_msg,
            //     confirmPopBtn:1,
            // }));
            // setConfirm(true);
        });
    },[]);


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">온라인 경매</p>
                    </div>
                    <div className="scroll_wrap">
                        <p className="f_16 medium">온라인 경매정보</p>
                        <div className="custom_table s_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>경매시간</th>
                                        <th>대표품목</th>
                                        <th>경매사</th>
                                        <th>경매물건수</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {auctList && 
                                        auctList.length > 0 ? auctList.map((auct,i)=>{
                                            auct = CF.trimObjectValues(auct);
                                            return(
                                                <tr key={i}>
                                                    <td>{CF.MDFWithHour(auct.auct_dt,4)}</td>
                                                    <td>{auct.gm_name}</td>
                                                    <td>{auct.is_name}</td>
                                                    <td>{auct.auct_cnt}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan={4}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <p className="tp20 f_16 medium">실시간 경매 현황</p>
                        <div className="custom_table s_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'120px'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>등록번호</th>
                                        <th>품명</th>
                                        <th>규격</th>
                                        <th>등급</th>
                                        <th>중량</th>
                                        <th>수량</th>
                                        <th>낙찰단가</th>
                                        <th>낙찰시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {proList && 
                                        proList.length > 0 ? proList.map((pro,i)=>{
                                            pro = CF.trimObjectValues(pro);
                                            return(
                                                <tr key={i}>
                                                    <td>{pro.de_date_online}</td>
                                                    <td>{pro.gm_name}</td>
                                                    <td>{pro.de_packing_name}</td>
                                                    <td>{pro.de_high_name}</td>
                                                    <td className="tx_r">{CF.MakeIntComma(pro.de_weight)}kg</td>
                                                    <td className="tx_r">{CF.MakeIntComma(pro.de_quantity)}개</td>
                                                    <td className="tx_r">{CF.MakeIntComma(pro.de_price1)}원</td>
                                                    <td>{pro.close_dt}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan={8}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {/* {isMobile &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">온라인 경매</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <p className="f_16 medium">온라인 경매정보</p>
                        <div className="custom_table s_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>경매시간</th>
                                        <th>대표품목</th>
                                        <th>경매사</th>
                                        <th>경매물건수</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {auctList && auctList.map((auct,i)=>{
                                        auct = CF.trimObjectValues(auct);
                                        return(
                                            <tr key={i}>
                                                <td>{CF.MDFWithHour(auct.auct_dt,4)}</td>
                                                <td>{auct.gm_name}</td>
                                                <td>{auct.is_name}</td>
                                                <td>{auct.auct_cnt}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <p className="tp20 f_16 medium">실시간 경매 현황</p>
                        <div className="custom_table s_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>등록번호</th>
                                        <th>품명</th>
                                        <th>규격</th>
                                        <th>등급</th>
                                        <th>중량</th>
                                        <th>수량</th>
                                        <th>낙찰단가</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {proList && proList.map((pro,i)=>{
                                        pro = CF.trimObjectValues(pro);
                                        return(
                                            <tr key={i}>
                                                <td>{pro.de_date_online}</td>
                                                <td>{pro.gm_name}</td>
                                                <td>{pro.de_packing_name}</td>
                                                <td>{pro.de_high_name}</td>
                                                <td className="tx_r">{CF.MakeIntComma(pro.de_weight)}kg</td>
                                                <td className="tx_r">{CF.MakeIntComma(pro.de_quantity)}개</td>
                                                <td className="tx_r">{CF.MakeIntComma(pro.de_price1)}원</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        } */}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default AuctionViewPop;