import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
    name: "user", //state 이름
    initialState: {
        name:"",
        id:"",
        kind:9,
        lastDate:"",
        assoCode:"",
        dealList:[],
        isLogin: null,
        appMemId: '', //앱자동로그인한 회원아이디
    },
    reducers:{
        loginUser: (state, action) => {
            state.name = action.payload.name;
            state.id = action.payload.id;
            state.kind = action.payload.kind;
            state.lastDate = action.payload.lastDate;
            state.assoCode = action.payload.assoCode;
        },
        isLogin: (state) => {
            state.isLogin = true;
        },
        dealList: (state, action) => {
            state.dealList = action.payload;
        },
        clearUser: (state) => {
            state.name = "";
            state.id = "";
            state.kind = "";
            state.lastDate = "";
            state.assoCode = "";
            state.isLogin = false;
        },
        appMemId: (state, action) => {
            state.appMemId = action.payload;
        },
    }
});

export const { loginUser, isLogin, dealList, clearUser, appMemId } = user.actions;
export default user;