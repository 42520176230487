import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as CF from "../../../pc/component/Function"
import { enum_api_uri, enum_de_status, enum_sell_kind, enum_from_kind } from "../../../../config/enum";
import { closePop, mediaPop } from "../../../../store/popupSlice";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { useParams, useLocation } from "react-router-dom";
import imgNone from "../../../../images/img_none.svg";
import videoUp from "../../../../images/video_upload.svg";

const AuctionProDetailPop = () => {
    const m_subdetail_auction = enum_api_uri.m_subdetail_auction;
    const dispatch = useDispatch();
    const [subDetail, setSubDetail] = useState();
    const token = localStorage.getItem("token");
    const { auct_code } = useParams();
    const api_uri = enum_api_uri.api_uri;

    const closePopHandler = () => {
        dispatch(closePop());
    }

    useEffect(()=>{
        setSubDetail(subDetail);
        // console.log(subDetail);
    },[subDetail]);

    // 송품장,물품정보 가져오기
    useEffect(() => {
        let auctProData = JSON.parse(localStorage.getItem('auctProData'));

        axios.get (`${m_subdetail_auction.replace(':asso_code', auct_code).replace(':de_date_online', auctProData.de_date_online).replace(':de_no_online', auctProData.de_no_online).replace(':de_seq', auctProData.de_seq).replace(':de_seq2', auctProData.de_seq2)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            // console.log(res)
            if(res.status === 200){
                console.log(res.data);
                res.data.nowStatus =  enum_de_status[res.data.de_status.trim()][0];
                res.data.as_name =  res.data.as_name.trim();
                res.data.de_weight = CF.MakeIntComma(res.data.de_weight);
                res.data.de_quantity = CF.MakeIntComma(res.data.de_quantity);
                if(res.data.ip_kpp != null){
                    res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                }
                if(res.data.ip_kcp != null){
                    res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                }
                if(res.data.de_img1 != null){
                    res.data.de_img1 = res.data.de_img1.trim();
                    if(res.data.de_img1.length > 0){
                        res.data.de_img1 = res.data.de_img1.replace(/\\/ig,"\\\\");
                        res.data.de_img1 = api_uri+'/'+res.data.de_img1;
                    }
                }
                if(res.data.de_img2 != null){
                    res.data.de_img2 = res.data.de_img2.trim();
                    if(res.data.de_img2.length > 0){
                        res.data.de_img2 = res.data.de_img2.replace(/\\/ig,"\\\\");
                        res.data.de_img2 = api_uri+'/'+res.data.de_img2;
                    }
                }
                if(res.data.de_video != null){
                    res.data.de_video = res.data.de_video.trim();
                    if(res.data.de_video.length > 0){
                        res.data.de_video = res.data.de_video.replace(/\\/ig,"\\\\");
                        res.data.de_video = api_uri+'/'+res.data.de_video;
                    }
                }
                if (res.data.memo.hasOwnProperty('price')) {
                    res.data.memo.price = CF.MakeIntComma(res.data.memo.price);
                }else{
                    res.data.memo.price = 0;
                }
                setSubDetail(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }, []);
    

    
    
    return(<>
        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">물품 기본정보</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'30%'}} />
                                    <col style={{width:'70%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>메모</th>
                                        <td><div className="over_txt"><span>{subDetail && subDetail.memo.memo}</span></div></td>
                                    </tr>
                                    <tr className="tr_sky">
                                        <th>메모단가</th>
                                        <td>{subDetail && subDetail.memo.price}원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>현재상태</th>
                                        <td><span className="tag">{subDetail && subDetail.nowStatus}</span></td>
                                        <th>경매사</th>
                                        <td><span className="tag">{subDetail && subDetail.ip_auct_name}</span></td>
                                    </tr>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">
                                            <div className="over_txt"><span>{subDetail && subDetail.as_name}</span></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>입하번호</th>
                                        <td colSpan="3">{subDetail && `${subDetail.de_date_online}-${subDetail.de_no_online}`}</td>
                                    </tr>
                                    <tr>
                                        <th>번호</th>
                                        <td>{subDetail && subDetail.de_gods_code}</td>
                                        <th>생산자</th>
                                        <td>{subDetail && subDetail.de_farm_name}</td>
                                    </tr>
                                    <tr>
                                        <th>팰릿</th>
                                        <td className="tx_r">{subDetail && subDetail.ip_kpp}개</td>
                                        <th className="rp0">목재(옥타곤)</th>
                                        <td className="tx_r">{subDetail && subDetail.ip_kcp}개</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>품목</th>
                                        <td colSpan="3"><div className="over_txt"><span>{subDetail && `${subDetail.gm_name === null ? subDetail.gm_stmd_name : subDetail.gm_name}(${subDetail.de_gods_code})`}</span></div></td>
                                    </tr>
                                    <tr>
                                        <th>규격</th>
                                        <td>{subDetail && subDetail.de_packing_name}</td>
                                        <th>등급</th>
                                        <td>{subDetail && subDetail.de_high_name}</td>
                                    </tr>
                                    <tr>
                                        <th>수량</th>
                                        <td className="tx_r">{subDetail && subDetail.de_quantity}개</td>
                                        <th>중량</th>
                                        <td className="tx_r">{subDetail && subDetail.de_weight}kg</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="f_16 medium tp10 lp10 bp10">물품설명</p>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'30%'}} />
                                    <col style={{width:'70%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>품질</th>
                                        <td><div className="over_txt"><span>{subDetail && subDetail.de_cont1}</span></div></td>
                                    </tr>
                                    <tr>
                                        <th>당도, 산도 등</th>
                                        <td><div className="over_txt"><span>{subDetail && subDetail.de_cont2}</span></div></td>
                                    </tr>
                                    <tr>
                                        <th>특이사항</th>
                                        <td><div className="over_txt"><span>{subDetail && subDetail.de_cont3}</span></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ul className="media_ul li_center tm10 bp20">
                            <li>
                                {subDetail && subDetail.de_img1 === "" ? 
                                    <img src={imgNone} alt="이미지" className="w_49px"/>
                                    :   <div className="thumb_box" onClick={() => {
                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${subDetail.de_img1}`}))
                                        }}>
                                            <img src={subDetail && `${subDetail.de_img1}`} alt="이미지"/>
                                        </div>
                                }
                            </li>
                            <li>
                                {subDetail && subDetail.de_img2 === "" ? 
                                    <img src={imgNone} alt="이미지" className="w_49px"/>
                                    :   <div className="thumb_box" onClick={() => {
                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진2',mediaSrc:`${subDetail.de_img2}`}))
                                        }}>
                                            <img src={subDetail && `${subDetail.de_img2}`} alt="이미지"/>
                                        </div>
                                }
                            </li>
                            <li>
                                {subDetail && subDetail.de_video === "" ? 
                                    <img src={videoUp} alt="이미지"/>
                                    :   <div className="thumb_box" onClick={() => {
                                            dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${subDetail.de_video}`}))
                                        }}>
                                            <video src={subDetail && `${subDetail.de_video}`} />
                                        </div>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        }
    </>)
};

export default AuctionProDetailPop;