import { configureStore } from '@reduxjs/toolkit';
import user from './userSlice';
import popup from './popupSlice';
import invoice from './invoiceSlice';
import common from './commonSlice';

export default configureStore({
  reducer: { 
    user: user.reducer,
    popup: popup.reducer,
    invoice: invoice.reducer,
    common: common.reducer,
  }
});