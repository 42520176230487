import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { closePop } from "../../../../store/popupSlice";
import { enum_api_uri, debounce, debounce2 } from "../../../../config/enum";
import ConfirmPop from "./ConfirmPop";
import { confirmPop, defaultInfoModifyOk, defaultInfoModifyPop } from "../../../../store/popupSlice";
import { isBrowser, isMobile } from "react-device-detect";
import { NumericFormat } from 'react-number-format';
import * as CF from "../Function"; // (customFunction)

const DefaultInfoModifyPop = () => {
    const a_list_product = enum_api_uri.a_list_product;
    const a_modify_invoice = enum_api_uri.a_modify_invoice;
    const c_search_origin = enum_api_uri.c_search_origin;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const popup = useSelector((state)=>state.popup);
    const [LocaCodeDrop, setLocaCodeDrop] = useState(false);
    const [locaCodeInput, setLocaCodeInput] = useState('');
    const [locaInput, setLocaInput] = useState('');
    const [locaCode, setLocaCode] =  useState([]);
    const [locaError, setLocaError] =  useState(false);
    const [locaInputClick, setLocaInputClick] =  useState(false);
    const [invoiceData, setInvoiceData] = useState();
    const [modifyConfirm, setModifyConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [ipKind, setIpKind] = useState(1);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    useEffect(() => {
        setIpKind(ipKind);
    },[ipKind]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setModifyConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    //송품장정보 불러오기
    useEffect(()=>{
        let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
        let ip_date_online = invoiceData.ip_date_online;
        let ip_no_online = invoiceData.ip_no_online;
        let ip_s_kind = invoiceData.ip_s_kind;
        let ip_kind = invoiceData.ip_kind;
        axios.get(`${a_list_product.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                console.log(res.data);
                res.data.ip_s_kind = 'ip_s_kind_' + ip_s_kind;
                res.data.ip_kind = 'ip_kind_' + ip_kind;
                setInvoiceData(res.data);
                setLocaCodeInput(res.data.ip_loca);
                setLocaInput(res.data.ip_loca_name);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    },[]);

    //송품장정보 수정하기
    const submit = (values) => {
        let regex = /[^0-9]/g;
        let ip_s_kind = values.ip_s_kind.replace(regex,'');
        let ip_kind = ipKind;
        let body = {
            ip_s_kind: ip_s_kind,
            ip_kind: ip_kind,
            ip_loca: locaCodeInput,
            ip_kpp: values.ip_kpp,
            ip_kcp: values.ip_kcp,
        };
        let getData = localStorage.getItem("invoiceData");
        getData = JSON.parse(getData);
        let ip_date_online = getData.ip_date_online;
        let ip_no_online = getData.ip_no_online;
        axios.put(`${a_modify_invoice.replace(':ip_date_online',ip_date_online).replace(':ip_no_online',ip_no_online)}`,body,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                invoiceData = {
                    ip_date_online:ip_date_online,
                    ip_no_online:ip_no_online,
                    ip_s_kind:ip_s_kind,
                    ip_kind:ip_kind,
                    ip_loca:locaCodeInput,
                };
                localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                dispatch(defaultInfoModifyPop(false));
                dispatch(defaultInfoModifyOk(true));
                setTimeout(() => {
                    dispatch(defaultInfoModifyOk(false));
                },100);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        })
    };

    // 송품장 산지 검색
    useEffect(() => {
        setLocaCodeInput(locaCodeInput);
        if(locaInputClick){
            if(locaCodeInput.length > 0){
                const et_kind = parseInt(ipKind) == 1 ? 'O' : 'N';
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=${et_kind}&search_word=${locaCodeInput}&ip_kind=${ipKind}`,{
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setLocaCode(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setLocaCodeDrop(true);
                },debounce2);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setLocaCodeDrop(false);
            }
        }
    },[locaCodeInput]);

    useEffect(()=>{
        setInvoiceData(invoiceData);
    },[invoiceData]);

    //기본정보 수정하기
    const modifyHandler = (values) => {
        if(values.ip_kpp > 100 || values.ip_kpp > 100){
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'팰릿 또는 목재는 100개 이하로만 작성해주세요.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }else{
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'기본정보를 수정하시겠습니까?',
                confirmPopBtn:2,
            }));
            setModifyConfirm(true);
        }
    };

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont3">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">기본정보 수정하기</p>
                        <p className="txt">※ 모든 내역의 변경은 출하주와의 사전 협의가 있어야 합니다.</p>
                    </div>
                    {invoiceData &&
                        <Formik
                            initialValues={{
                                ip_s_kind: invoiceData.ip_s_kind || "",
                                ip_kind: invoiceData.ip_kind || "",
                                ip_kpp: invoiceData.ip_kpp || "",
                                ip_kcp: invoiceData.ip_kcp || "",
                            }}
                            onSubmit={submit}
                        >
                            {({values, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="custom_table gray_table">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'12%'}}/>
                                                <col style={{width:'auto'}}/>
                                                <col style={{width:'12%'}}/>
                                                <col style={{width:'auto'}}/>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>매매구분</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select 
                                                                name="ip_s_kind"
                                                                value={values.ip_s_kind}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="" hidden>매매구분</option>
                                                                <option value="ip_s_kind_1">입찰</option>
                                                                <option value="ip_s_kind_2">경매</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <th>판매구분</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select 
                                                                name="ip_kind"
                                                                value={values.ip_kind || 'ip_kind_1'}
                                                                onChange={(e)=>{
                                                                    handleChange(e);
                                                                    let regex = /[^0-9]/g;
                                                                    let value = e.currentTarget.value.replace(regex,'');
                                                                    setIpKind(value);
                                                                    setLocaCodeInput('');
                                                                    setLocaInput('');
                                                                }}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="ip_kind_1">국내</option>
                                                                <option value="ip_kind_2">수입</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>산지</th>
                                                    <td colSpan="3">
                                                        <div className="drop_input_box">
                                                            <div className="drop_wrap">
                                                                <div className="custom_input h_40">
                                                                    <input type="text" value={locaCodeInput || ""} onFocus={()=> {setLocaInputClick(true)}} onClick={() => {setLocaInputClick(true)}} 
                                                                        onChange={(e) =>{
                                                                            setLocaCodeInput(e.target.value);
                                                                        }} 
                                                                        onKeyDown={(e)=>{
                                                                            if(e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                modifyHandler(values);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {LocaCodeDrop && (<>
                                                                    <div className="dim" onClick={() => {
                                                                        setLocaCodeDrop(false);
                                                                        setLocaInputClick(false);
                                                                    }}></div>
                                                                    <div className="drop_box">    
                                                                        <ul className="list_ul">
                                                                            {locaCode.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                            {locaCode.length > 0 && (locaCode.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    setLocaCodeInput(locaCode[i].et_code.trim());
                                                                                    setLocaInput(locaCode[i].et_name.trim());
                                                                                    setLocaCodeDrop(false);
                                                                                    setLocaInputClick(false);
                                                                                    setLocaError(false);
                                                                                }}>{locaCode[i].et_name.trim()}</li>;
                                                                            }))}
                                                                        </ul>
                                                                    </div>
                                                                </>)}
                                                            </div>
                                                            <div className="custom_input h_40">
                                                                <input type="text" readOnly value={locaInput || ""} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>팰릿</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kpp} name="ip_kpp" onChange={handleChange} className="tx_r" 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        modifyHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <th>목재(옥타곤)</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kcp} name="ip_kcp" onChange={handleChange} className="tx_r" 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        modifyHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex_center tp15">
                                        <button className="btn_type rm10" type="button"
                                            onClick={()=>{
                                                modifyHandler(values);
                                            }}
                                        >수정하기</button>
                                        <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                                    </div>
                                    {modifyConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setModifyConfirm(false)}} onClickHandler={()=>{submit(values)}} />}
                                </form>
                            )}
                        </Formik>
                    }
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont small_pop">
                    <div className="mo_pop_tit">
                        <p className="tit">기본정보 수정하기</p>
                        <p className="txt">※ 모든 내역의 변경은 출하주와의 사전 협의가 있어야 합니다.</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    {invoiceData &&
                        <Formik
                            initialValues={{
                                ip_s_kind: invoiceData.ip_s_kind || "",
                                ip_kind: invoiceData.ip_kind || "",
                                ip_kpp: invoiceData.ip_kpp || "",
                                ip_kcp: invoiceData.ip_kcp || "",
                            }}
                            onSubmit={submit}
                        >
                            {({values, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="custom_table gray_table">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'33%'}}/>
                                                <col style={{width:'67%'}}/>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>매매구분</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select 
                                                                name="ip_s_kind"
                                                                value={values.ip_s_kind}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="" hidden>매매구분</option>
                                                                <option value="ip_s_kind_1">입찰</option>
                                                                <option value="ip_s_kind_2">경매</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>판매구분</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select 
                                                                name="ip_kind"
                                                                value={values.ip_kind || 'ip_kind_1'}
                                                                onChange={(e)=>{
                                                                    handleChange(e);
                                                                    let regex = /[^0-9]/g;
                                                                    let value = e.currentTarget.value.replace(regex,'');
                                                                    setIpKind(value);
                                                                    setLocaCodeInput('');
                                                                    setLocaInput('');
                                                                }}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="ip_kind_1">국내</option>
                                                                <option value="ip_kind_2">수입</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>산지</th>
                                                    <td>
                                                        <div className="drop_input_box bm5">
                                                            <div className="drop_wrap w_100">
                                                                <div className="custom_input">
                                                                    <input type="text" value={locaCodeInput || ""} onFocus={()=> {setLocaInputClick(true)}} onClick={() => {setLocaInputClick(true)}} 
                                                                        onChange={(e) =>{
                                                                            setLocaCodeInput(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                {LocaCodeDrop && (<>
                                                                    <div className="dim" onClick={() => {
                                                                        setLocaCodeDrop(false);
                                                                        setLocaInputClick(false);
                                                                    }}></div>
                                                                    <div className="drop_box">    
                                                                        <ul className="list_ul">
                                                                            {locaCode.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                            {locaCode.length > 0 && (locaCode.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    setLocaCodeInput(locaCode[i].et_code.trim());
                                                                                    setLocaInput(locaCode[i].et_name.trim());
                                                                                    setLocaCodeDrop(false);
                                                                                    setLocaInputClick(false);
                                                                                    setLocaError(false);
                                                                                }}>{locaCode[i].et_name.trim()}</li>;
                                                                            }))}
                                                                        </ul>
                                                                    </div>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                        <div className="custom_input">
                                                            <input type="text" readOnly value={locaInput || ""} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>팰릿</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kpp} name="ip_kpp" onChange={handleChange} className="tx_r" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="rp0">목재(옥타곤)</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kcp} name="ip_kcp" onChange={handleChange} className="tx_r" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="btn_half_box tp10">
                                        <button className="btn_type rm10" type="button"
                                            onClick={()=>{
                                                modifyHandler(values);
                                            }}
                                        >수정하기</button>
                                        <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                                    </div>
                                    {modifyConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setModifyConfirm(false)}} onClickHandler={()=>{submit(values)}} />}
                                </form>
                            )}
                        </Formik>
                    }
                </div>
            </div>
        }
        
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}} />}
        
    </>);
};

export default DefaultInfoModifyPop;