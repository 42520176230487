import { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from 'lodash';
import {enum_api_uri} from "../../../../config/enum";
import { closePop } from "../../../../store/popupSlice";
import moment, { now } from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "../../../../components/pc/component/Function"; // (customFunction)

const OrderingPop = (state) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const auctCode =localStorage.getItem('auctCode');
    const [cfList, setCFList] = useState([]);
    let [mvVal, setMvVal] = useState();
    let [targetNo, setTargetNo] = useState();
    let [targetNoTemp, setTargetNoTemp] = useState([1,2]);
    let [startPoint, setStartPoint] = useState(0);
    let [keys, setKeys] = useState([]);
    let [hasArray, setHasArray] = useState(0);//배열을 만든적이 있는가?
    let [infiLoop, setInfiLoop] = useState(0);    
    let [nowPointer, setNowPointer] = useState(0);    
    let [auctDt, setAuctDt] = useState('');    
    const [selectVal, setSelectVal] = useState();
    const [dateOption, setDateOntion] = useState([]);
    
    const [onProduct, setOnProduct] = useState(0);
    const a_list_order_auction_dt = enum_api_uri.a_list_order_auction_dt;
    const a_list_order_auction = enum_api_uri.a_list_order_auction;
    const order = useSelector((state) => state.order);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    // useEffect(() => {
    //     getCfList(auctCode);    
    // },[]);
    
    useEffect(() => {
        setMvVal(mvVal);
    },[mvVal]);

    useEffect(() => {
        setTargetNo(targetNo);
    },[targetNo]);
    useEffect(() => {
        setTargetNoTemp(targetNoTemp);
    },[targetNoTemp]);

    useEffect(() => {
        setOnProduct(onProduct);
    },[onProduct]);
    
    useEffect(() => {
        setCFList(cfList);
    },[cfList]);

    useEffect(() => {
        setAuctDt(auctDt);
        if(auctDt !== ""){
            getCfList(auctCode,auctDt);
        }
    },[auctDt]);


    //리스너 생성&죽이기
    useEffect(() => {
        document.addEventListener("keydown", keyFunction, false);   
        document.addEventListener("keyup", keysReleased, false);   
        return () => {
        document.removeEventListener("keydown", keyFunction, false);   
        document.removeEventListener("keyup", keysReleased, false);   
        }
    }, [targetNo,mvVal,cfList,infiLoop]);

    //리스너 변수 세팅
    const keyFunction = (e) => {
        keyFunctionExe(cfList,targetNo,mvVal,e); //단독 
        // keyFunctionExe(cfList,targetNoTemp,mvVal,e); //다중
    }

    function keysReleased(e) {
        keys[e.keyCode] = false;
    }
    //리스너 생성&죽이기


    //리스트 전송
    const submitList = function(auctCode){
        let de_date_online_val = []
        let de_no_online_val = []
        let de_seq_val = []
        let de_seq2_val = []
        cfList.map((listVal,i) => {
            de_date_online_val.push(listVal.de_date_online)
            de_no_online_val.push(listVal.de_no_online)
            de_seq_val.push(listVal.de_seq)
            de_seq2_val.push(listVal.de_seq2)
        });

        // let productData = JSON.parse(localStorage.getItem('productData'));
        let body = {de_date_online:de_date_online_val, de_no_online:de_no_online_val, de_seq:de_seq_val, de_seq2:de_seq2_val, };
        axios.put(`${a_list_order_auction.replace(':asso_code', auctCode).replace(':auct_dt', auctDt)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                alert('저장완료')

                setTargetNo();
                setMvVal();

                setStartPoint()//포인터 시작값
                setNowPointer()//포인터 마지막값
                setTargetNoTemp();
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(()=>{
        axios.get(`${a_list_order_auction_dt.replace(':asso_code', auctCode)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setDateOntion([...res.data]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);


    const keyFunctionExe = function(state,targetNo,mvVal,e){
        let arList = state        
        let plk = [];
        // let newPoint = 0;

        //key[16] 좌우 상관없이 shift키
        keys[e.keyCode] = true;

        //쉬프트를 눌렀을경우~~
        if(keys[16]){
            console.log(targetNo)
            let nowPointerTemp = nowPointer
            if (keys[38]) {
                // shiftKeys
                //up
                nowPointerTemp = nowPointerTemp - 1
            }
            if (keys[40]) {
                //down
                nowPointerTemp = nowPointerTemp + 1
            }

            if (nowPointerTemp < 0 || nowPointerTemp >= state.length){
                return false;
            }else{
                nowPointer = nowPointerTemp
                setNowPointer(nowPointer);
                //포인터를 설정한다. 포인터와 타겟이 같지 않으면 배열로 돌리자
                
                let newArr = []
                if(startPoint != nowPointer){ //배열을 만들어야 할경우
                    let a = 0;
                    let b = 0;
                    setHasArray(1);
                    
                    if(startPoint > nowPointer )
                    {
                        a = nowPointer
                        b = startPoint
                    }
                    if(startPoint < nowPointer )
                    {
                        a = startPoint
                        b = nowPointer
                    }

                    for (var i = a; i <= b; i++) {
                        newArr.push(i)
                    }
                    // console.log(newArr)
                    targetNo = newArr
                    setTargetNo(targetNo)
                }
                if((startPoint == nowPointer) && hasArray == 1){
                    console.log("Same")
                    console.log(startPoint, nowPointer)
                    newArr = [startPoint]
                    console.log(newArr)
                    targetNo = newArr
                    setTargetNo(targetNo)
                }
            }

            // targetNo
        }else{
            if(e.key === "ArrowUp" || e.key === "ArrowDown"){
                if (e.key === "ArrowUp") {
                    mvVal = - 1
                }
                if (e.key === "ArrowDown") {
                    mvVal = 1
                    if (mvVal >= arList.length) {
                        mvVal =  arList.length - 1
                    }
                }
                //타겟이 배열일경우
                if(Array.isArray(targetNo)){
                    let arrNewTarget = []
                    let tLeng = targetNo.length
                    let fTarget = targetNo[0] //첫번째 타겟
                    let isGoodWork = 0 //작동이 잘 되는가?

                    //값검증(리스트 숫자 삐져나가는거 있는지?)
                    let newPosition = 0;
                    targetNo.map((targetNo,i) => {
                        newPosition = targetNo + mvVal;
                        // checkGuide(newPosition,state.length)

                        if (newPosition < 0 || newPosition >= state.length){
                            isGoodWork = 1; //작동하면 안된다~~
                            return;
                        } 
                        
                    });
                    //값검증(리스트 숫자 삐져나가는거 있는지?)


                    //위에서 작동이 되는지 확인했다면~~  isGoodWork ==0
                    if(isGoodWork == 0){
                        //타겟 배열 합치기
                        targetNo.map((targetVal,i) => {
                            arrNewTarget[i] = state[targetVal]
                        });

                        //원본에서 타겟배열 삭제
                        state.splice(fTarget, tLeng);

                        newPosition = 0;
                        let newTargetArr = []
                        targetNo.map((targetNo,i) => {
                            newPosition = targetNo + mvVal;
                            state.splice(newPosition, 0, arrNewTarget[i]);
                            newTargetArr[i] = newPosition
                            // console.log(arrNewTarget[i]+"->"+newPosition)
                        });

                        
                        setStartPoint(newTargetArr[0])//포인터 시작값
                        setNowPointer(newTargetArr.at(-1))//포인터 마지막값
                        setTargetNoTemp(newTargetArr);
                        targetNo = newTargetArr
                        setCFList(state);
                    }


                }else{//배열아닐때
                    plk = changeArrayOrder(arList, targetNo, mvVal);

                    if(plk != undefined){
                        plk.map((plk,i) => {
                            plk.uqNo = i;
                        });
                        let plkNew = [...plk];
                        setCFList(plkNew);

                        if (e.key === "ArrowUp") {
                            targetNo = targetNo - 1
                            if (targetNo <= 0) {
                                targetNo = 0
                            }
                        }
                        if (e.key === "ArrowDown") {
                            targetNo = targetNo + 1
                            if (targetNo >= arList.length) {
                                targetNo =  arList.length - 1
                            }
                        }
                        
                    }
                    
                }
                // setTargetNoTemp(targetNo);
                // console.log(targetNo)
                setStartPoint(targetNo)//포인터 시작값
                setNowPointer(targetNo);
                setTargetNo(targetNo);
                setMvVal(mvVal);

                //리스너 유지용
                infiLoop = infiLoop + 1;
                setInfiLoop(infiLoop);
            }
            if(e.key === "Enter"){
                submitList(auctCode)
            }
            if(e.key === "Escape"){
                setTargetNo();
                setMvVal();

                setStartPoint()//포인터 시작값
                setNowPointer()//포인터 마지막값
                setTargetNoTemp();
            }
        }
    }


    const getCfList = (auctCode) =>{
        axios.get(`${a_list_order_auction.replace(':asso_code', auctCode).replace(':auct_dt', auctDt)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            console.log(res.data)
            const data = res.data;
            data.map((data,i) => {
                data.uqNo = i;
            });
            let state = [...data];
            setCFList(state)
        })
        .catch((error) => {
            console.log(error);
        })
    }


    const changeArrayOrder = function(list, targetIdx, moveValue) {
        // 배열값이 없는 경우 나가기
        if (list.length < 0) return;
      
        // 이동할 index 값을 변수에 선언
        const newPosition = targetIdx + moveValue;
      
        // 이동할 값이 0보다 작거나 최대값을 벗어나는 경우 종료
        if (newPosition < 0 || newPosition >= list.length) return;
      
        // 임의의 변수를 하나 만들고 배열 값 저장
        const tempList = JSON.parse(JSON.stringify(list));
        
        // 옮길 대상을 target 변수에 저장하기
        const target = tempList.splice(targetIdx, 1)[0];
      
        // 새로운 위치에 옮길 대상을 추가하기
        tempList.splice(newPosition, 0, target);
        
        return tempList;

    };
    
    


    return(<>
        {/* PC일때 */}
        {isBrowser && 
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont3">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">경매순서지정</p>
                        <p className="txt">※ 순서 변경 품목 선택 후 키보드 상하 키를 통해 순서를 설정할 수 있습니다. 저장 버튼을 클릭해야 변경사항이 반영됩니다.</p>
                    </div>
                    <div className="scroll_wrap">
                        <div className="flex_end bp10">
                            <div className="custom_select w_180">
                                <select 
                                    value={selectVal}
                                    onChange={(e)=>{
                                        setSelectVal(e.currentTarget.value);
                                        setAuctDt(e.currentTarget.value);
                                    }}
                                >
                                    <option value="">날짜선택</option>
                                    {dateOption && dateOption.map((option,i)=>{
                                        return(
                                            <option key={i} value={option.auct_dt}>{CF.MDFWithHour(option.auct_dt, 4)}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="custom_table s_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'15%'}}/>
                                    <col style={{width:'6%'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>입하번호</th>
                                        <th>번호</th>
                                        <th>출하주</th>
                                        <th>생산자</th>
                                        <th>품목명</th>
                                        <th>중량</th>
                                        <th>규격</th>
                                        <th>등급</th>
                                        <th>수량</th>
                                        <th>희망단가</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 등록물품 없을때 ▼ */}
                                    {cfList.length === 0 && (
                                            <tr>
                                                <td colSpan = "10"><p>조회된 물품이 없습니다. </p></td>
                                            </tr>
                                        )}
                                        {/* 등록물품 있을때 ▼ */}
                                        {/* 선택시 className="tr_blue" */}
                                    {cfList.length > 0 && cfList.map((cfValues,i) => {
                                        let trClassName = ''
                                        if(Array.isArray(targetNo)){
                                            if(targetNo.includes(i)){trClassName = 'tr_blue'}else{trClassName = ''}
                                        }else{
                                            if(targetNo === i){trClassName = 'tr_blue'}else{trClassName = ''}
                                        }
                                        // if(i === nowPointer){trClassName = 'tr_current'}else{trClassName = ''}
                                        return (
                                        <tr key={i}
                                            onClick={()=> {
                                            // setTargetNo(cfValues.uqNo);tr_current
                                            // setStartPoint(cfValues.uqNo);
                                            // setNowPointer(cfValues.uqNo);
                                            setTargetNo(i);
                                            setStartPoint(i);
                                            setNowPointer(i);
                                            setMvVal(i);                                        
                                            }}
                                            className={`${trClassName}`}
                                            >
                                            <td>{cfValues.de_date_online}-{cfValues.de_no_online}</td>
                                            <td>{cfValues.de_seq}-{cfValues.de_seq2}</td>
                                            <td>{cfValues.de_asso_name.trim()}</td>
                                            <td>{cfValues.de_farm_name.trim()}</td>
                                            <td>
                                                <div className="over_txt"><span>{cfValues.gm_name.trim()}</span></div>
                                            </td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_weight)}kg</td>
                                            <td>{cfValues.de_packing_name.trim()}</td>
                                            <td>{cfValues.de_high_name.trim()}</td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_quantity)}개</td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_hope_pri)}원</td>
                                        </tr>
                                        
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex_center tp15">
                        <button type = "submit" className="btn_type rm10" onClick = {()=>submitList(auctCode)}>저장</button>
                        <button type = "button" className="btn_type4" onClick={closePopHandler}>닫기</button>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile && 
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont small_pop">
                    <div className="mo_pop_tit">
                        <p className="tit">경매순서지정</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <p className="f_13 txt_gray tp15 bp10">※ 순서 변경 품목 선택 후 키보드 상하 키를 통해 순서를 설정할 수 있습니다. 저장 버튼을 클릭해야 변경사항이 반영됩니다.</p>
                    <div className="scroll_wrap over_x m_h_300">
                        <div className="custom_select">
                            <select 
                                value={selectVal}
                                onChange={(e)=>{
                                    setSelectVal(e.currentTarget.value);
                                    setAuctDt(e.currentTarget.value);
                                }}
                            >
                                <option value="">날짜선택</option>
                                {dateOption && dateOption.map((option,i)=>{
                                    return(
                                        <option key={i} value={option.auct_dt}>{CF.MDFWithHour(option.auct_dt, 4)}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="custom_table s_table w_900px tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'15%'}}/>
                                    <col style={{width:'6%'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'auto'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                    <col style={{width:'8%'}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>입하번호</th>
                                        <th>번호</th>
                                        <th>출하주</th>
                                        <th>생산자</th>
                                        <th>품목명</th>
                                        <th>중량</th>
                                        <th>규격</th>
                                        <th>등급</th>
                                        <th>수량</th>
                                        <th>희망단가</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 등록물품 없을때 ▼ */}
                                    {cfList.length === 0 && (
                                            <tr>
                                                <td colSpan = "10"><p>조회된 물품이 없습니다. </p></td>
                                            </tr>
                                        )}
                                        {/* 등록물품 있을때 ▼ */}
                                        {/* 선택시 className="tr_blue" */}
                                    {cfList.length > 0 && cfList.map((cfValues,i) => {
                                        let trClassName = ''
                                        if(Array.isArray(targetNo)){
                                            if(targetNo.includes(i)){trClassName = 'tr_blue'}else{trClassName = ''}
                                        }else{
                                            if(targetNo === i){trClassName = 'tr_blue'}else{trClassName = ''}
                                        }
                                        // if(i === nowPointer){trClassName = 'tr_current'}else{trClassName = ''}
                                        return (
                                        <tr key={i}
                                            onClick={()=> {
                                            // setTargetNo(cfValues.uqNo);tr_current
                                            // setStartPoint(cfValues.uqNo);
                                            // setNowPointer(cfValues.uqNo);
                                            setTargetNo(i);
                                            setStartPoint(i);
                                            setNowPointer(i);
                                            setMvVal(i);                                        
                                            }}
                                            className={`${trClassName}`}
                                            >
                                            <td>{cfValues.de_date_online}-{cfValues.de_no_online}</td>
                                            <td>{cfValues.de_seq}-{cfValues.de_seq2}</td>
                                            <td>{cfValues.de_asso_name.trim()}</td>
                                            <td>{cfValues.de_farm_name.trim()}</td>
                                            <td>
                                                <div className="over_txt"><span>{cfValues.gm_name.trim()}</span></div>
                                            </td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_weight)}kg</td>
                                            <td>{cfValues.de_packing_name.trim()}</td>
                                            <td>{cfValues.de_high_name.trim()}</td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_quantity)}개</td>
                                            <td className="tx_r">{CF.MakeIntComma(cfValues.de_hope_pri)}원</td>
                                        </tr>
                                        
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn_half_box tp10">
                        <button type = "submit" className="btn_type rm10" onClick = {()=>submitList(auctCode)}>저장</button>
                        <button type = "button" className="btn_type4" onClick={closePopHandler}>닫기</button>
                    </div>
                </div>
            </div>
        }
    </>);
};

export default OrderingPop;