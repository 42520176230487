
// import moment from "moment";
import moment from "moment-timezone";


// //날짜 변경 함수 (날짜 원형 , 날짜 포맷)
export function MakeDateForm (origin, format){
  let resultDate = moment(origin).utc().format(format);
  return resultDate
}


// MakeDateForm
export function MDFWithOutHour (origin, format){
  let resultDate = origin.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  return resultDate
}


// //날짜 시간이 포함된 날짜 202301012000
export function MDFWithHour (origin, format){
  let resultDate =''
  if(format == 2){
    resultDate = origin.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$2/$3 $4:$5')
  }else if(format == 3){
    resultDate = moment(origin).utc().format("YYYY-MM-DD HH:mm");
  }else if(format == 4){
    resultDate = origin.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5')
  }else{
    resultDate = origin.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4시 $5분')
  }
  return resultDate

  // origin = moment(origin,'YYYY/MM/DD HH:mm').format();
  // origin = moment(origin).toISOString();
  // origin = new Date(origin).toString();

  // let resultDate = moment(origin).format(format);
  // return resultDate
}


//숫자형 콤마넣기
export function MakeIntComma (intVal){
    intVal *= 1; //숫자형 변경
    const option = {
        maximumFractionDigits: 4 //최대 소수점 자리
      };
    const result = intVal.toLocaleString('ko-KR', option);
        
    return result
}
//숫자형 콤마넣기

//숫자형 콤마지우기
export function RemoveIntComma (val){
  val = val+",";
  let regex = /[^0-9]/g;
  let number = val.replace(regex,'');

  return number
}
//숫자형 콤마지우기


//빈배열인지 확인
export function isEmptyArr(arr)  {
    if(Array.isArray(arr) && arr.length === 0)  {
      return true;
    }
    
    return false;
}

// 연락처 형식
export function MakePhoneForm(origin) {
  // 입력된 문자열에서 문자 제거
  const digitsOnly = origin.replace(/\D+/g, '');

  // 전화번호 정규식 (앞자리: 02 또는 3자리 숫자, 중간 자리: 3~4자리 숫자, 마지막 자리: 4자리 숫자)
  const phoneNumberRegex = /^(02|\d{3})(\d{3,4})(\d{4})$/;

  // 정규식을 사용해 전화번호 형식에 맞게 변경
  const formatted = digitsOnly.replace(phoneNumberRegex, '$1-$2-$3');

  // 변경된 전화번호 반환
  return formatted;
}

//api 에러메시지
export function errorMsgHandler (error) {  
  let errorMsg = '';
  let errorType = '';

  if(error.hasOwnProperty('message')){
    errorType = "javascript-api";
  }

  if(error.hasOwnProperty('response') && error.response.hasOwnProperty('data')){ // 일반 에러 객체 처리
    errorType = "custom";
  }
  
  if(error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('error')){ // 일반 에러 객체 처리
    errorType = "express-validator";
  };

  if(errorType === "javascript-api"){ // 일반 에러 객체 처리
    errorMsg = error.message;
  }else if(errorType === "custom"){ // 일반 에러 객체 처리
    errorMsg = error.response.data.msg;
  }else if(errorType === "express-validator"){ // validatior 에러 객체 처리
    errorMsg = error.response.data.error.errors[0].msg;
  }else{
    errorMsg = '알 수 없는 오류가 발생했습니다.';
  }
  return errorMsg
}

//데이터 tirm()
export function objValueTrim (obj) {  
  if(obj === null || obj === undefined || obj === ''){
    return '';
  } else {
    return obj.trim();
  }
}

//리스트페이지 조회정보 세션스토리지에 저장하기
export function setListPage (data) {
  sessionStorage.setItem("listPage",JSON.stringify(data));
}

//리스트페이지 페이징숫자 세션스토리지에 저장하기
export function setPageNum (data) {
  sessionStorage.setItem("pageNum",data);
}

//리스트페이지 스크롤위치 세션스토리지에 저장하기
export function setScrollY (data) {
  sessionStorage.setItem("ScrollY",data);
}

//날짜 시간,분단위로 나누기
export function getDateFormat(str) {
  str = objValueTrim(str);
  var strDate = '';
  var strHour = '';
  var strMinute = '';

  if (str.length === 12) {
    strDate = str.substring(0, 4) + '-' + str.substring(4, 6) + '-' + str.substring(6, 8);
    strDate = moment(strDate).toISOString();
    strDate = new Date(strDate);
    strHour = str.substring(8, 10);
    strMinute = str.substring(10, 12);
  } 

  return [strDate, strHour, strMinute];
}

// 경매사-입하작업 경매/입찰 시간 없을경우 오늘날짜
// export function getDefaultBiddingDate(addHour = 1) {
//   // 현재 시간 기준 10분 단위 값
//   const addTowHour = moment().add(60, 'minutes').format("YYYY-MM-DD HH:mm");
//   const currentRounded = moment(addTowHour).startOf('hour').add(Math.floor(moment().add(9, 'minutes').minute() / 10) * 10, 'minutes');
  
//   // 2시간 후의 값
//   const laterHours = moment(currentRounded).add(addHour, 'hours');

//   const date = {
//     start_date: currentRounded.format("YYYY-MM-DD"),
//     start_hour: currentRounded.format("HH"),
//     start_minute: currentRounded.format("mm"),
//     end_date: laterHours.format("YYYY-MM-DD"),
//     end_hour: laterHours.format("HH"),
//     end_minute: laterHours.format("mm"),
//   }

//   return date;
// }

// 경매사-입하작업 경매/입찰 시간 없을경우 오늘날짜
export function getDefaultBiddingDate(addHour = 1) {
  // 현재 시간 기준 10분 단위 값
  const addTowHour = moment().add(60, 'minutes').format("YYYY-MM-DD HH:mm");
  const currentRounded = moment(addTowHour).startOf('hour');
  
  // 만약 현재 시간이 51분 이상이면 다음 시간대로 이동, 아니면 10분 단위로 반올림
  if (moment().minute() >= 51) {
    currentRounded.add(1, 'hour').startOf('hour').minute(0);
  } else {
    currentRounded.add(Math.floor(moment().add(9, 'minutes').minute() / 10) * 10, 'minutes');
  }
  
  // 2시간 후의 값
  const laterHours = moment(currentRounded).add(addHour, 'hours');

  const date = {
    start_date: currentRounded.format("YYYY-MM-DD"),
    start_hour: currentRounded.format("HH"),
    start_minute: currentRounded.format("mm"),
    end_date: laterHours.format("YYYY-MM-DD"),
    end_hour: laterHours.format("HH"),
    end_minute: laterHours.format("mm"),
  }

  return date;
}

export function trimObjectValues(obj) {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      result[key] = null;
    } else if (typeof obj[key] === 'string') {
      result[key] = obj[key].trim();
    } else {
      result[key] = obj[key];
    }
  });
  return result;
};
