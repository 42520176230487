import { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import {enum_api_uri, debounce, debounce2, enum_mo_title} from "../../../config/enum";
import LeftCont from "../../../components/pc/component/LeftCont";
import {BidSFProductInfo} from "../../../components/pc/component/ProductList";
import Pagination from "../../../components/pc/component/Pagination";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { isMobile, isBrowser } from 'react-device-detect';
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import { confirmPop } from "../../../store/popupSlice";
import * as CF from "../../../components/pc/component/Function";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";

const BidProducts = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const basicDate = new Date();
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [ipSKind, setIpSKind] = useState('');
    const [dateError, setDateError] = useState(false);
    const [proList, setProList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const location = useLocation();
    const [insuConfirm,setInsuConfirm] = useState(false);
    const [insuOkConfirm,setInsuOkConfirm] = useState(false);
    const [confirm,setConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);
    const common = useSelector((state) => state.common);
    const [detailData, setDetailData] = useState({});
    const m_list_sbid = enum_api_uri.m_list_sbid;
    const c_search_gm_name = enum_api_uri.c_search_gm;
    const c_search_gm_stmd_name = enum_api_uri.c_search_gm_stmd;
    const m_list_sbid_cf_reg = enum_api_uri.m_list_sbid_cf_reg;
    const [scrollMove, setScrollMove] = useState(false);
    registerLocale('ko', ko);
    const [listInsu, setListInsu] = useState([]);
    const [insuIdx, setInsuIdx] = useState(null);


    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);
        
        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setInsuConfirm(false);
            setInsuOkConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setIpSKind(ipSKind);
    },[ipSKind]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(() => {
        setProList(proList);
        const newListInsu = proList.map(()=>{
            return false;
        });
        setListInsu(newListInsu);
    },[proList]);

    useEffect(() => {
        setListInsu(listInsu);
        console.log(listInsu);
    },[listInsu]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setTotalPrice(totalPrice);
    },[totalPrice]);

    useEffect(() => {
        setPaging(viewPaging);
    },[viewPaging]);

    useEffect(() => {
        setDetailData(detailData);
        console.log(detailData)
    },[detailData]);


    useEffect(()=>{ //처음 한번 리스트 불러오기a1

        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_s_kind = listPage.ip_s_kind;
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setIpSKind(listPage.ip_s_kind);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_s_kind = '';
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_s_kind:'',
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);

    //인수등록 컨펌
    const insuClickHandler = (deDate, deNo, deSeq, deSeq2, i) => {
        dispatch(confirmPop({
            confirmPopTit:'알림',
            confirmPopTxt:'인수등록을 하시겠습니까?',
            confirmPopBtn:2,
        }));
        setInsuConfirm(true);
        let data = {
            deDate:deDate,
            deNo:deNo,
            deSeq:deSeq,
            deSeq2:deSeq2,
        }
        setDetailData({...data});

        setInsuIdx(i);
    };

    //인수등록
    const insuReg = async () => { 
        let body = {};
        axios.post(`${m_list_sbid_cf_reg.replace(':de_date_online', detailData.deDate).replace(':de_no_online', detailData.deNo).replace(':de_seq', detailData.deSeq).replace(':de_seq2', detailData.deSeq2)}`,body,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            console.log(res);
            if(res.status === 200){
                setInsuOkConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'인수등록이 완료되었습니다.',
                    confirmPopBtn:1,
                }));

                let newListInsu = [...listInsu];
                newListInsu[insuIdx] = true;
                setListInsu(newListInsu);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //경매목록 조회 버튼 클릭시
    const submit = async (values) => {
        let regex = /[^0-9]/g;
        let ip_s_kind = ipSKind.replace(regex,'');
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');

        let val = [];
        val.ip_s_kind = ip_s_kind;
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_s_kind:ip_s_kind,
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };
    
    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_s_kind = ipSKind;
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };
    

    //리스트 받아오기(현재 페이지 공통)
    const getList = async(val) =>{
        axios.get(`${m_list_sbid}?ip_date_start=${val.ip_date_start}&ip_date_end=${val.ip_date_end}&ip_s_kind=${val.ip_s_kind}&record_size=${val.record_size}&page_no=${val.page_no_now}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            // console.log(res);
            if(res.status === 200){
                const data = res.data
                let state = [...data.results];
                let resCount = data.total_record_count;
                let totalPrice = data.total_price;
                setProList(state); //리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setTotalPrice(totalPrice); // 검색결과 전체 건수 총합 금액 
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    }

    
    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">낙찰물품 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>낙찰물품 조회</li>
                                </ul>
                            </div>
                            <Formik
                                initialValues={{
                                    // ip_s_kind: ipSKind || ""
                                }}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                            {({values, setFieldValue, handleSubmit, errors, touched,handleChange,handleBlur}) => (
                            <form onSubmit={handleSubmit}>
                            <ul className="search_box">
                                <li>
                                    <p className="txt">출하일자</p>
                                    <div className="date_input">
                                        <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                        <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                    </div>
                                </li>
                                <li>
                                    <p className="txt">구분</p>
                                    <div className="clearfix">
                                        <div className="custom_select rm5">
                                            <select 
                                                value={`ip_s_kind_${ipSKind}`}
                                                onChange={(e)=>{
                                                    let value = e.currentTarget.value;
                                                        value = value.replace(/[^0-9]/g,'');
                                                    setIpSKind(value);
                                                }}
                                                style={{ display: 'block' }}
                                            >
                                                <option value="">전체</option>
                                                <option value="ip_s_kind_1">입찰</option>
                                                <option value="ip_s_kind_2">경매</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn_s">조회</button>
                                    </div>
                                </li>
                            </ul>
                            {dateError && 
                                <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                            }
                            <div className="tm30">
                                <div className="flex_between">
                                    <p className="medium bp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                    {proList.length > 0 && 
                                        <div className="tx_r bp10">
                                            <span className="tag2">금액 : <strong>{CF.MakeIntComma(totalPrice)}원</strong></span>
                                        </div>
                                    }
                                </div>
                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_data"><span>데이터가 없습니다.</span></div>
                                )}

                                {/* 등록물품 있을때 ▼ */}
                                {proList.length > 0 && 
                                    <div className="custom_table s_table">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'135px'}} />
                                                <col style={{width:'130px'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'80px'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'130px'}} />
                                                <col style={{width:'70px'}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>등록번호</th>
                                                    <th className="rp0 lp0">경매/입찰시간</th>
                                                    <th className="th_link"><span>품목명</span></th>
                                                    <th>수량</th>
                                                    <th>단가</th>
                                                    <th>금액</th>
                                                    <th>상태</th>
                                                    <th>보기</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proList.map((a,i) => {
                                                    //  console.log(proList);
                                                    return (
                                                        <BidSFProductInfo 
                                                            de_date_online={proList[i].de_date_online || ""}
                                                            de_ip_no={proList[i].de_ip_no || ""}
                                                            ip_s_kind={proList[i].ip_s_kind || ""}
                                                            ip_auct_name={proList[i].ip_auct_name || ""}
                                                            ip_auct_date_start={proList[i].ip_auct_date_start || ""}
                                                            gm_name={proList[i].gm_name === null ? proList[i].gm_stmd_name : proList[i].gm_name || ""}
                                                            de_status={proList[i].de_status || ""}
                                                            de_quantity={proList[i].de_quantity || ""}
                                                            de_seq={proList[i].de_seq || ""}
                                                            de_seq2={proList[i].de_seq2 || ""}
                                                            pindex={i +1 || ""}
                                                            insuClickHandler={insuClickHandler}
                                                            de_price1={proList[i].de_price1}
                                                            de_amt1={proList[i].de_amt1}
                                                            idx={i}
                                                            insuDone={listInsu[i]}
                                                        />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {/* 조회가 없을때 ▼ */}
                                {viewPaging === 0 && (
                                <div className="pagination">
                                    <button className="prev">
                                        <span>이전 페이지</span>
                                    </button>
                                    <ul className="num_box">
                                        <li className="on">
                                            <button>1</button>
                                        </li>
                                    </ul>
                                    <button className="next">
                                        <span>다음 페이지</span>
                                    </button>
                                </div>
                                )}
                                {/* 조회가 페이징보다 클때 ▼ */}
                                {viewPaging > 0 &&
                                        <Pagination 
                                        nowPage={page_no}
                                        totalCnt={viewPaging}
                                        setPerPage={perPage}
                                        moveToPage={moveToPage}

                                        />
                                }
                            </div>
                            </form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        // ip_s_kind: ipSKind || ""
                    }}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                {({values, setFieldValue, handleSubmit, errors, touched, handleChange, handleBlur}) => (
                <form onSubmit={handleSubmit}>
                <div className="search_box">
                    <ul>
                        <li>
                            <p className="txt">경매일자</p>
                            <div className="date_input">
                                <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                            </div>
                        </li>
                        <li>
                            <p className="txt">구분</p>
                            <div className="custom_select">
                                <select 
                                    value={`ip_s_kind_${ipSKind}`}
                                    onChange={(e)=>{
                                        let value = e.currentTarget.value;
                                            value = value.replace(/[^0-9]/g,'');
                                        setIpSKind(value);
                                    }}
                                    style={{ display: 'block' }}
                                >
                                    <option value="ip_s_kind">전체</option>
                                    <option value="ip_s_kind_1">입찰</option>
                                    <option value="ip_s_kind_2">경매</option>
                                </select>
                            </div>
                        </li>
                    </ul>
                    <button type="submit" className="btn_s2 w_100 tm10">조회</button>
                </div>
                {dateError && 
                    <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                }
                <div className="tm30">
                    <p className="medium bp10 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                    {proList.length > 0 && 
                        <div className="tag2 tx_r bp10">
                            <p>금액 : <strong>{CF.MakeIntComma(totalPrice)}원</strong></p>
                        </div>
                    }
                    {/* 등록물품 없을때 ▼ */}
                    {proList.length === 0 && (
                        <div className="none_data"><span>데이터가 없습니다.</span></div>
                    )}

                    {/* 등록물품 있을때 ▼ */}
                    {proList.length > 0 && 
                        <div className="list_result_box">     
                            {proList.map((a,i) => {
                                return (
                                    <div className="custom_table gray_table" key={i}>
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "23%" }} />
                                                <col style={{ width: "27%" }} />
                                                <col style={{ width: "23%" }} />
                                                <col style={{ width: "27%" }} />
                                            </colgroup>
                                            <BidSFProductInfo 
                                                de_date_online={proList[i].de_date_online || ""}
                                                de_ip_no={proList[i].de_ip_no || ""}
                                                ip_s_kind={proList[i].ip_s_kind || ""}
                                                ip_auct_name={proList[i].ip_auct_name || ""}
                                                ip_auct_date_start={proList[i].ip_auct_date_start || ""}
                                                gm_name={proList[i].gm_name === null ? proList[i].gm_stmd_name : proList[i].gm_name || ""}
                                                de_status={proList[i].de_status || ""}
                                                de_quantity={proList[i].de_quantity || ""}
                                                de_seq={proList[i].de_seq || ""}
                                                de_seq2={proList[i].de_seq2 || ""}
                                                pindex={i +1 || ""}
                                                insuClickHandler={insuClickHandler}
                                                de_price1={proList[i].de_price1}
                                                de_amt1={proList[i].de_amt1}
                                                idx={i}
                                                insuDone={listInsu[i]}
                                            />
                                        </table>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    
                    {/* 조회가 없을때 ▼ */}
                    {viewPaging === 0 && (
                    <div className="pagination">
                        <button className="prev">
                            <span>이전 페이지</span>
                        </button>
                        <ul className="num_box">
                            <li className="on">
                                <button>1</button>
                            </li>
                        </ul>
                        <button className="next">
                            <span>다음 페이지</span>
                        </button>
                    </div>
                    )}
                    {/* 조회가 페이징보다 클때 ▼ */}
                    {viewPaging > 0 &&
                            <Pagination 
                            nowPage={page_no}
                            totalCnt={viewPaging}
                            setPerPage={perPage}
                            moveToPage={moveToPage}

                            />
                    }
                </div>
                </form>
                )}
                </Formik>
            </div>
        }

        {/* 인수등록 완료 확인confirm팝업 */}
        {insuConfirm && <ConfirmPop onClickHandler={insuReg} />}

        {/* 인수등록 확인confirm팝업 */}
        {insuOkConfirm && <ConfirmPop />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default BidProducts;