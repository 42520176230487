import React from 'react';
import {enum_ip_status,enum_de_status,enum_sell_kind} from "../../../config/enum";
import { Link } from "react-router-dom";
import { isMobile, isBrowser } from 'react-device-detect';
import * as CF from "../component/Function";

//중도매인 - 출하물품 조회용
function ShipProductInfo(props){
    let ip_regNo = props.ip_date_online+"-"+props.ip_no_online;
    let kind = props.ip_s_kind.trim();
        kind = enum_sell_kind[kind]
    let auct_name = (props.ip_auct_name.trim() == "") ? '미정' : props.ip_auct_name.trim();
    let auct_date_start = (props.ip_auct_date_start.trim() == "") ? '미정' : props.ip_auct_date_start.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let auct_date_end = (props.ip_auct_date_end.trim() == "") ? '' : props.ip_auct_date_end.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let status = props.ip_status.trim();
    let auct_status = enum_ip_status[status][0];
    let linkUrl = '/merchant/shipment-products/detail/';

    return(<>
    
        {isBrowser &&
            <>
                <td><Link to = {`${linkUrl}${ip_regNo}`} className="btn2_s"
                    onClick={()=>{
                        //리스트페이지 현재스크롤위치 저장
                        CF.setScrollY(window.scrollY);
                    }}>{ip_regNo}</Link>
                </td>
                <td>{kind}</td>
                <td>{auct_name}</td>
                <td>{auct_date_start}
                    {kind == "입찰" && 
                        <p>{auct_date_end}</p>
                    }
                </td>
                <td>
                    <div className="over_txt">
                        <span>
                            <Link to = {`${linkUrl}${ip_regNo}`} 
                                onClick={()=>{
                                    //리스트페이지 현재스크롤위치 저장
                                    CF.setScrollY(window.scrollY);
                                }}
                                >{props.gm_name}{props.de_count > 1 ? '외' : ''}
                            </Link>
                        </span>
                    </div>
                </td>
                <td className='tx_r'>{CF.MakeIntComma(props.de_count)}</td>
                <td className='tx_r'>{CF.MakeIntComma(props.de_quantity)}</td>
                <td>
                    {auct_status}
                    {kind == "경매" && props.de_memo_count > 0 &&
                        <div className='tm3'>
                            <Link to = {`${linkUrl}${ip_regNo}`} className="btn2_s"
                                onClick={()=>{
                                    //리스트페이지 현재스크롤위치 저장
                                    CF.setScrollY(window.scrollY);
                                }}
                            >메모 
                                {props.de_memo_count > 0 && 
                                    <span>({props.de_memo_count})</span>
                                }
                            </Link>
                        </div>
                    }
                </td>
            </>
        }

        {isMobile &&
            <>
                <tr>
                    <th className='tx_l'>등록번호</th>
                    <td colSpan="3" className='tx_l'>{ip_regNo}<Link to = {`${linkUrl}${ip_regNo}`} className="btn_view lm10" onClick={()=>{CF.setScrollY(window.scrollY)}}>보기</Link></td>
                </tr>
                <tr>
                    <th className='tx_l'>구분</th>
                    <td className='tx_l'>{kind}</td>
                    <th className='tx_l'>경매사</th>
                    <td className='tx_l'><span className='tag'>{auct_name}</span></td>
                </tr>
                <tr>
                    <th className='tx_l'>경매시간</th>
                    <td colSpan="3" className='tx_l'>{auct_date_start}</td>
                </tr>
                <tr>
                    <th className='tx_l'>대표품목</th>
                    <td colSpan="3" className='tx_l'><Link to = {`${linkUrl}${ip_regNo}`} onClick={()=>{CF.setScrollY(window.scrollY)}}>{props.gm_name}{props.de_count > 1 ? '외' : ''}</Link></td>
                </tr>
                <tr>
                    <th className='tx_l'>수량</th>
                    <td className='tx_r'>{CF.MakeIntComma(props.de_quantity)}개</td>
                    <th className='tx_l'>입하</th>
                    <td className='tx_r'>{CF.MakeIntComma(props.de_count)}개</td>
                </tr>
                <tr>
                    <th className='tx_l'>상태</th>
                    <td colSpan="3">
                        <span className='tag'>{auct_status}</span>
                        {kind == "경매" && props.de_memo_count > 0 &&
                            <Link to = {`${linkUrl}${ip_regNo}`} className="btn2_s lm10" onClick={()=>{CF.setScrollY(window.scrollY)}}>메모 ({props.de_memo_count})</Link>
                        }
                    </td>
                </tr>
            </>
        }
        
    </>);
}
//중도매인 - 출하물품 조회용


//중도매인 - 온라인입찰용
const BidProductInfo=(props)=>{
    let ip_regNo = props.ip_date_online+"-"+props.ip_no_online;
    let auct_name = (props.ip_auct_name.trim() == "") ? '미정' : props.ip_auct_name.trim();
    let auct_date_start = (props.ip_auct_date_start.trim() == "") ? '미정' : props.ip_auct_date_start.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let auct_date_end = (props.ip_auct_date_end.trim() == "") ? '미정' : props.ip_auct_date_end.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let status = props.ip_status.trim();
    let auct_status = enum_ip_status[status][0];
    let linkUrl = '/merchant/online-bid/detail/';
    let btn_val = "보기";

    //(입하 != 입찰 ) && 입찰중 = 입찰하기
    // if((props.income_tot != props.bidding_tot) && (status == 8)){
    if(status == 8){
        btn_val = "입찰하기";
    }

    return(<>
        {isBrowser &&
            <tr  key = {props.pindex}>
                <td><Link to= {`${linkUrl}${ip_regNo}`} className="btn2_s" onClick={()=>{CF.setScrollY(window.scrollY)}}>{ip_regNo}</Link></td>
                <td>{auct_name}</td>
                <td>{auct_date_start} <br></br> {auct_date_end} </td>
                <td><Link to= {`${linkUrl}${ip_regNo}`} className="txt_black" onClick={()=>{CF.setScrollY(window.scrollY)}}><div className="over_txt"><span>{props.gm_name}{props.de_quan_tot > 1 ? '외' : ''}</span></div></Link></td>
                <td className='tx_r'>{CF.MakeIntComma(props.income_tot)}</td>
                <td className='tx_r'>{CF.MakeIntComma(props.bidding_tot)}</td>
                <td className='tx_r'>{CF.MakeIntComma(props.success_tot)}</td>
                <td>{auct_status}</td>
                <td><Link to={`${linkUrl}${ip_regNo}`} className="btn2_s" onClick={()=>{CF.setScrollY(window.scrollY)}}>{btn_val}</Link></td>
            </tr>
        }

        {isMobile && 
            <tbody key = {props.pindex}>
                <tr>
                    <th className='tx_l'>등록번호</th>
                    <td colSpan="3" className='tx_l'>
                        <Link to= {`${linkUrl}${ip_regNo}`} className="txt_black" onClick={()=>{CF.setScrollY(window.scrollY)}}>{ip_regNo}</Link>
                        {auct_status == "입찰중" ? 
                            <Link to={`${linkUrl}${ip_regNo}`} className="btn_bid lm10" onClick={()=>{CF.setScrollY(window.scrollY)}}>{btn_val}</Link>
                            :<Link to={`${linkUrl}${ip_regNo}`} className="btn_view lm10" onClick={()=>{CF.setScrollY(window.scrollY)}}>{btn_val}</Link>
                        }
                    </td>
                </tr>
                <tr>
                    <th className='tx_l'>경매사</th>
                    <td colSpan="3" className='tx_l'><span className='tag'>{auct_name}</span></td>
                </tr>
                <tr>
                    <th className='tx_l'>입찰시작</th>
                    <td colSpan="3" className='tx_l'>{auct_date_start}</td>
                </tr>
                <tr>
                    <th className='tx_l'>입찰종료</th>
                    <td colSpan="3" className='tx_l'>{auct_date_end}</td>
                </tr>
                <tr>
                    <th className='tx_l'>대표품목</th>
                    <td colSpan="3" className='tx_l'>{props.gm_name}{props.income_tot > 1 ? '외' : ''}</td>
                </tr>
                <tr>
                    <th className='tx_l'>입하</th>
                    <td className='tx_r'>{CF.MakeIntComma(props.income_tot)}개</td>
                    <th className='tx_l'>입찰</th>
                    <td className='tx_r'>{CF.MakeIntComma(props.bidding_tot)}개</td>
                </tr>
                <tr>
                    <th className='tx_l'>낙찰</th>
                    <td className='tx_r'>{CF.MakeIntComma(props.success_tot)}개</td>
                    <th className='tx_l'>상태</th>
                    <td className='tx_l'><span className='tag'>{auct_status}</span></td>
                </tr>
            </tbody>
        }
    </>);
}
//중도매인 - 온라인입찰용



//중도매인 - 낙찰물품조회
const BidSFProductInfo=(props)=>{
    // console.log(props)
    let ip_regNo = props.de_date_online+"-"+props.de_ip_no;
    let seq_mass = "/"+props.de_seq+"/"+props.de_seq2;
    let kind = props.ip_s_kind.trim();
        kind = enum_sell_kind[kind]
    let auct_name = (props.ip_auct_name.trim() == "") ? '미정' : props.ip_auct_name.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let auct_date_start = (props.ip_auct_date_start.trim() == "") ? '미정' : props.ip_auct_date_start.trim().replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    let status = props.de_status.trim();
    let auct_status = enum_de_status[status][0];
    let nextActionBtn = ''
    let linkUrl = '/merchant/bid-products/detail/';
    if(status == 10 || status == 12){ nextActionBtn = <Link to={`${linkUrl}${ip_regNo}${seq_mass}`} onClick={()=>{CF.setScrollY(window.scrollY)}} className='btn2_s2'>배송지정보등록</Link>}
    if(status == 15){
        nextActionBtn = <button type='button'
                            onClick={()=> {
                                props.insuClickHandler(props.de_date_online, props.de_ip_no, props.de_seq, props.de_seq2, props.idx);
                            }}
                            className='btn2_s2'
                        >인수등록</button>
    }
 
    return(<>
        {isBrowser &&
            <tr key = {props.pindex}>
                <td>
                    <div className='bm5'>
                        <span className='rm5'>{kind}</span>
                        <span>{auct_name}</span>
                    </div>
                    <Link to= {`${linkUrl}${ip_regNo}${seq_mass}`} className="btn2_s" onClick={()=>{CF.setScrollY(window.scrollY)}}>{ip_regNo}</Link>
                </td>
                <td>{auct_date_start}</td>
                <td>
                    <Link to= {`${linkUrl}${ip_regNo}${seq_mass}`} onClick={()=>{CF.setScrollY(window.scrollY)}}><div className="over_txt"><span>{props.gm_name}</span></div></Link>
                </td>
                <td className='tx_r'>{CF.MakeIntComma(props.de_quantity)}개</td>
                <td className='tx_r'>{CF.MakeIntComma(props.de_price1)}원</td>
                <td className='tx_r'>{CF.MakeIntComma(props.de_amt1)}원</td>
                {props.insuDone ? 
                    <td><p>완료</p></td>
                    : <td><p>{auct_status}</p><div className='tm5'>{nextActionBtn}</div></td>
                }
                <td><Link to={`${linkUrl}${ip_regNo}${seq_mass}`} className="btn2_s" onClick={()=>{CF.setScrollY(window.scrollY)}}>보기</Link></td>
            </tr>
        }

        {isMobile &&
            <tbody>
                <tr>
                    <th className='tx_l'>등록번호</th>
                    <td colSpan="3" className='tx_l'>
                        <Link to= {`${linkUrl}${ip_regNo}${seq_mass}`} className="txt_black" onClick={()=>{CF.setScrollY(window.scrollY)}}>{ip_regNo}</Link>
                        <Link to={`${linkUrl}${ip_regNo}${seq_mass}`} className="btn_view lm10" onClick={()=>{CF.setScrollY(window.scrollY)}}>보기</Link>
                    </td>
                </tr>
                <tr>
                    <th className='tx_l'>구분</th>
                    <td>{kind}</td>
                    <th className='tx_l'>경매사</th>
                    <td><span className='tag'>{auct_name}</span></td>
                </tr>
                <tr>
                    <th className='tx_l'>경매/입찰시간</th>
                    <td colSpan="3">{auct_date_start}</td>
                </tr>
                <tr>
                    <th className='tx_l'>품목명</th>
                    <td >{props.gm_name}</td>
                    <th className='tx_l'>수량</th>
                    <td className='tx_r' >{CF.MakeIntComma(props.de_quantity)}개</td>
                </tr>
                <tr>
                    <th className='tx_l'>단가</th>
                    <td className='tx_r' >{CF.MakeIntComma(props.de_price1)}원</td>
                    <th className='tx_l'>금액</th>
                    <td className='tx_r' >{CF.MakeIntComma(props.de_amt1)}원</td>
                </tr>
                <tr>
                    <th className='tx_l'>상태</th>
                    <td colSpan="3">
                        {props.insuDone ? 
                            <div className='flex'>
                                <span className='tag'>완료</span>
                            </div>
                            :   <div className='flex'>
                                    <span className='tag'>{auct_status}</span>
                                    <div className='lm5'>{nextActionBtn}</div>
                                </div>
                        }
                    </td>
                </tr>
            </tbody>
        }
    </>);
}
//중도매인 - 낙찰물품조회




export  {ShipProductInfo , BidProductInfo, BidSFProductInfo}

