import { createSlice } from "@reduxjs/toolkit";

const common = createSlice({
    name: "common", //state 이름
    initialState: {
        moTitle:"",
        detailPageBack:false
    },
    reducers:{
        moTitle: (state, action) => {
            state.moTitle = action.payload;
        },
        detailPageBack: (state, action) => {
            state.detailPageBack = action.payload;
        },
    }
});

export const { moTitle, detailPageBack } = common.actions;
export default common;