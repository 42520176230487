import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { enum_api_uri, enum_de_status, enum_sell_kind, enum_from_kind } from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import moment from "moment";
import { PatternFormat, NumericFormat } from "react-number-format";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";
import sampleImg from "../../../images/sample/sample_img.jpg";
import sampleVideo from "../../../images/sample/sample_video.mp4";
import { mediaAllPop, mediaPop, confirmPop, restartPop, closePop } from "../../../store/popupSlice";
import { detailPageBack } from "../../../store/commonSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import { useNavigate, useParams } from "react-router-dom";
import imgNone from "../../../images/img_none.svg";
import videoUp from "../../../images/video_upload.svg";
import history from "../../../config/history";
import { useSocket } from "../../../components/SocketProvider";

// 소켓 연결에 필요한 변수 선언
// const api_uri = enum_api_uri.api_uri;
// const token = localStorage.getItem("token");

// // 메시지전송-------------------------
// const socket = io(api_uri, {
//     reconnection: false, // 자동 재접속을 비활성화합니다.
//     cors: { origin: "*", },
//     extraHeaders: {
//     Authorization: `Bearer ${token}`,
//     },
// });

const OnlineAuctionDetail = () => {
    const a_message_list = enum_api_uri.a_message_list;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [clientPopShow, setClientPopShow] = useState({show:false, type:'All'});
    const [clientPopTit, setClientPopTit] = useState("");
    const [clientInfoBox, setClientInfoBox] = useState({name:'', price1:'', price2:''});
    const [clientActive, setClientActive] = useState(null);
    const [infoBoxShow, setInfoBoxShow] = useState(false);
    const [infoName, setInfoName] = useState();
    const [infoPrice1, setInfoPrice1] = useState();
    const [infoPrice2, setInfoPrice2] = useState();
    const [infoBox, setInfoBox] = useState([]);
    const token = localStorage.getItem("token");
    const user = useSelector((state)=>state.user);
    const dispatch = useDispatch();
    const auctCode = localStorage.getItem("auctCode");
    const api_uri = enum_api_uri.api_uri;
    const a_list_auction = enum_api_uri.a_list_auction;
    const a_detail_auction = enum_api_uri.a_detail_auction;
    const a_client_info = enum_api_uri.a_client_info;
    const a_bid_history = enum_api_uri.a_bid_history;
    const m_chat = enum_api_uri.m_chat;
    const [productList, setProductList] = useState([]);
    const [onProduct, setOnProduct] = useState(0);
    const [productData, setProductData] = useState();
    const [clientList, setClientList] = useState([]);
    const [clientPcList, setClientPcList] = useState([]);
    const [clientMoList, setClientMoList] = useState([]);
    const [bidList, setBidList] = useState([]);
    const [bidErrorList, setBidErrorList] = useState([]);
    const [priMsgPopShow, setPriMsgPopShow] = useState(false);
    const [priMsgList, setPriMsgList] = useState([]);
    const [priMsgOn, setPriMsgOn] = useState();
    const [priMsgOnTxt, setPriMsgOnTxt] = useState('');
    const [allMsgPopShow, setAllMsgPopShow] = useState(false);
    const [allMsgList, setAllMsgList] = useState([]);
    const [allMsgOn, setAllMsgOn] = useState();
    const [allMsgOnTxt, setAllMsgOnTxt] = useState('');
    const [endConfirm, setEndConfirm] = useState(false);
    const [countPop, setCountPop] = useState(false);
    const [countPopType, setCountPopType] = useState('start');
    const [seconds, setSeconds] = useState(3);
    const nickname = useRef();
    const message = useRef();
    const [messages, setMessages] = useState([]);
    const messageBox = useRef();
    const popup = useSelector((state) => state.popup);
    const { auct_code, auct_date } = useParams();
    const [auctStart, setAuctStart] = useState(true);
    const [auctSuccess, setAuctSuccess] = useState(false);
    const [auctFail, setAuctFail] = useState(false);
    const [myAuct, setMyAuct] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [nicknameInput, setNicknameInput] = useState('');
    const [tabContOn, setTabContOn] = useState(0);
    const [nicknameFocus, setNicknameFocus] = useState(false);
    const [messageFocus, setMessageFocus] = useState(false);
    const [myConnect, setMyConnect] = useState(true);
    const [auctStatus, setAuctStatus] = useState("");
    const proListScrollWrap = useRef();
    const proListTable = useRef();
    const socket = useSocket();
    const navigate = useNavigate();

    let reconnectAttempts = 0;
    const maxReconnectAttempts = 10;

    //상세페이지 뒤로가기
    useEffect(() => {

        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));

            socket.disconnect(); // 서버와의 연결을 끊습니다.

            //온라인경매상세 localStorage 삭제
            localStorage.removeItem("auctStartData");
            localStorage.removeItem("auctProData");
            localStorage.removeItem("clientList");
            localStorage.removeItem("subList");
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setEndConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setAuctStatus(auctStatus);
    },[auctStatus]);

    useEffect(()=>{
        setMyConnect(myConnect);
    },[myConnect]);

    useEffect(()=>{
        setNicknameFocus(nicknameFocus);
    },[nicknameFocus]);

    useEffect(()=>{
        setMessageFocus(messageFocus);
    },[messageFocus]);

    useEffect(()=>{
        setTabContOn(tabContOn);
    },[tabContOn]);

    useEffect(()=>{
        setMyAuct(myAuct);
    },[myAuct]);

    useEffect(()=>{
        setProductList(productList);
    },[productList]);

    useEffect(()=>{
        setOnProduct(onProduct);
    },[onProduct]);

    useEffect(()=>{
        setProductData(productData);
    },[productData]);

    // 페이지 들어왔을때 내경매인지 확인
    useEffect(()=>{
        if(auctCode == auct_code){
            setMyAuct(true);
        }
    },[]);

    useEffect(()=>{
        setClientList(clientList);
        // // console.log(clientList)

        let newPcList = clientList.filter(client => client.device === "PC");
        setClientPcList(newPcList);

        let newMoList = clientList.filter(client => client.device === "MOBILE");
        setClientMoList(newMoList);
    },[clientList]);

    useEffect(()=>{
        setClientPcList(clientPcList);
    },[clientPcList]);

    useEffect(()=>{
        setClientMoList(clientMoList);
    },[clientMoList]);

    useEffect(()=>{
        setBidList(bidList);
        console.log(bidList)
    },[bidList]);
    
    useEffect(()=>{
        setBidErrorList(bidErrorList);
    },[bidErrorList]);


    //선택된 경매상품의 입찰,응찰,오류 조회
    const bidHistoryHandler = (de_date_online,de_no_online,de_seq,de_seq2) => {
        axios.get(`${a_bid_history.replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                let bidding = res.data.bidding;
                if(bidding.length > 0){
                    // setBidList([...bidding].sort((a,b) => (b.price - a.price)));
                    setBidList([...bidding].sort((a, b) => {
                        if (a.price === b.price) {
                            return a.idx - b.idx;
                        } else {
                            return b.price - a.price;
                        }
                    }));
                }else{
                    setBidList([]);
                }
                let error = res.data.error;
                // // console.log(error)
                if(error.length > 0){
                    // setBidErrorList(bidErrorList => [...bidErrorList,...error]);
                    setBidErrorList([...error]);
                }else{
                    setBidErrorList([]);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //채팅내역 가져오기
    useEffect(()=>{
        axios.get(`${m_chat.replace(':auct_code',auct_code).replace(':auct_date', auct_date)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                // // console.log(res.data);
                let chatList = res.data;
                let NewChatList = chatList.map((data)=>{
                    let newData = {};
                    newData["msg"] = data.msg;
                    newData["time"] = moment(data.reg_dt).utc().format("HH:mm");
                    newData["type"] = auctCode == data.sender_asso_code ? "send" : "receive";
                    newData["user"] = {"asso_code":data.sender_asso_code};
                    newData["pri_asso_code"] = data.receiver_asso_code;
                    return newData;
                });
                // // console.log(NewChatList);
                setMessages(NewChatList);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    },[]);

    //경매상품 리스트 뿌려주기
    useEffect(()=>{
        axios.get(`${a_list_auction.replace(':asso_code', auct_code).replace(':auct_date', auct_date)}?search_date_start=&search_date_end=&ip_no_online_start=&ip_no_online_end=&de_status`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                // // console.log(res.data)
                let data = res.data.detltrns;
                setProductList(data);

                setAuctStatus(res.data.onlineAuction.auct_status);

                //경매상품 리스트 localStorage subList 저장
                JSON.parse(localStorage.getItem('subList'));
                localStorage.setItem("subList", JSON.stringify(data));

                let idx = data.findIndex(pro => pro.de_status == 6);
                let auctProData = JSON.parse(localStorage.getItem('auctProData'));

                //경매중인물품 없으면 물픔리스트중 맨처음물품 on ,localStorage auctProData 저장
                if(idx < 0){
                    auctProData = {
                        de_date_online:data[0].de_date_online,
                        de_no_online:data[0].de_no_online,
                        de_seq:data[0].de_seq,
                        de_seq2:data[0].de_seq2,
                        de_status:data[0].de_status,
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                }
                // 경매중인물품 있으면 그물품 on ,localStorage auctProData 저장
                else{
                    auctProData = {
                        de_date_online:data[idx].de_date_online,
                        de_no_online:data[idx].de_no_online,
                        de_seq:data[idx].de_seq,
                        de_seq2:data[idx].de_seq2,
                        de_status:data[idx].de_status,
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                    setOnProduct(idx);
                    setAuctStart(false);
                    setAuctSuccess(true);
                    setAuctFail(true);
                }
                
                //상품상세정보 가져오기
                setTimeout(()=>{
                    proListClickHandler();
                },100);

                //선택된 경매상품의 입찰,응찰,오류 조회
                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    },[]);

    //경매자료 조회
    const searchSubmit = (values) => {
        let regex = /[^0-9]/g;
        let search_start = values.search_date_start.replace(regex,'');
        let search_end = values.search_date_end.replace(regex,'');
        let de_status = values.de_status.replace(regex,'');

        // console.log(`auct_date: ${auct_date}`);

        axios.get(`${a_list_auction.replace(':asso_code', auctCode).replace(':auct_date', auct_date)}?search_date_start=${search_start}&search_date_end=${search_end}&ip_no_online_start=${values.ip_no_online_start}&ip_no_online_end=${values.ip_no_online_end}&de_status=${de_status}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                // // console.log(res)
                let data = res.data.detltrns;
                setProductList(data);
                let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                auctProData = {
                    de_date_online:data[0].de_date_online,
                    de_no_online:data[0].de_no_online,
                    de_seq:data[0].de_seq,
                    de_seq2:data[0].de_seq2,
                    de_status:data[0].de_status,
                };
                localStorage.setItem("auctProData", JSON.stringify(auctProData));
                setTimeout(()=>{
                    proListClickHandler();
                },100);

                //선택된 경매상품의 입찰,응찰,오류 조회
                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //경매상품클릭시 상품정보 localStorage 저장
    const proClickHandler = (pro) => {
        let auctProData = JSON.parse(localStorage.getItem('auctProData'));
        auctProData = {
            de_date_online:pro.de_date_online,
            de_no_online:pro.de_no_online,
            de_seq:pro.de_seq,
            de_seq2:pro.de_seq2,
            de_status:pro.de_status,
        };
        localStorage.setItem("auctProData", JSON.stringify(auctProData));
        setTimeout(()=>{
            proListClickHandler();
        },100);
    };

    //경매상품클릭시 상세정보 뿌려주기
    const proListClickHandler = () => {
        const auctProData = JSON.parse(localStorage.getItem("auctProData"));
        axios.get(`${a_detail_auction.replace(':asso_code', auctCode).replace(':de_date_online',auctProData.de_date_online).replace(':de_no_online',auctProData.de_no_online).replace(':de_seq',auctProData.de_seq).replace(':de_seq2',auctProData.de_seq2)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                // //시작일시&종료일시
                if(res.data.de_auct_date_start !== null){
                    if(res.data.de_auct_date_start.trim() !=""){
                        res.data.de_auct_date_start  = CF.MDFWithHour(res.data.de_auct_date_start)
                    }
                }
                if(res.data.de_auct_date_end !== null){
                    if(res.data.de_auct_date_end.trim() !=""){
                        res.data.de_auct_date_end  = CF.MDFWithHour(res.data.de_auct_date_end)
                    }
                }
                if(res.data.de_img1 !== null){
                    res.data.de_img1 = res.data.de_img1.trim();
                    if(res.data.de_img1.length > 0){
                        res.data.de_img1 = res.data.de_img1.replace(/\\/ig,"\\\\");
                        res.data.de_img1 = api_uri+'/'+res.data.de_img1;
                    }
                }
                if(res.data.de_img2 !== null){
                    res.data.de_img2 = res.data.de_img2.trim();
                    if(res.data.de_img2.length > 0){
                        res.data.de_img2 = res.data.de_img2.replace(/\\/ig,"\\\\");
                        res.data.de_img2 = api_uri+'/'+res.data.de_img2;
                    }
                }
                if(res.data.de_video !== null){
                    res.data.de_video = res.data.de_video.trim();
                    if(res.data.de_video.length > 0){
                        res.data.de_video = res.data.de_video.replace(/\\/ig,"\\\\");
                        res.data.de_video = api_uri+'/'+res.data.de_video;
                    }
                }
                res.data.de_status = Number(res.data.de_status.trim());
                let ip_s_kind = Number(res.data.ip_s_kind.trim());
                res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                let ip_kind = Number(res.data.ip_kind.trim());
                res.data.ip_kind = enum_from_kind[ip_kind];
                res.data.de_reg_dt = moment(res.data.de_reg_dt).utc().format('YYYY-MM-DD HH시 mm분');
                if(res.data.de_loca_name !== null){
                    res.data.de_loca_name = res.data.de_loca_name.trim();
                }
                res.data.as_addr1 = res.data.as_addr1.trim();
                res.data.as_addr2 = res.data.as_addr2.trim();
                res.data.de_hope_pri = CF.MakeIntComma(res.data.de_hope_pri);
                res.data.de_quantity = CF.MakeIntComma(res.data.de_quantity);
                res.data.de_weight = CF.MakeIntComma(res.data.de_weight);
                if(res.data.ip_kpp !== null){
                    res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                }
                if(res.data.ip_kcp !== null){
                    res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                }
                res.data.de_packing_name = res.data.de_packing_name.trim();
                res.data.de_high_name = res.data.de_high_name.trim();
                setProductData(res.data);

                //선택된 경매상품의 입찰,응찰,오류 조회
                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //접속자 클릭시 접속자정보팝업
    const clientClickHandler = (e,idx,asso_code) => {
        setClientActive(idx);
        const y = e.currentTarget.offsetTop;
        const x = e.currentTarget.offsetLeft;

        axios.get(`${a_client_info.replace(':asso_code', asso_code)}`, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                let name = '['+data.am_code+'-'+data.am_name.trim()+'] '+data.am_own_name.trim()+' ('+data.am_tel3.trim()+')';
                let price1 = CF.MakeIntComma(data.am_m_limit);
                let price2 = CF.MakeIntComma(data.am_m_remain);
                setClientInfoBox({
                    name: name,
                    price1: price1,
                    price2: price2
                });
                setInfoBoxShow(true);
                setInfoName(name);
                setInfoPrice1(price1);
                setInfoPrice2(price2);
                setInfoBox([y + 10, x - 280]);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //오류난 중도매인 클릭시 개인메시지팝업 뜨기
    const errorUserClickHandler = () => {
        if(myAuct){
            setPriMsgPopShow(true);
            axios.get(`${a_message_list.replace(':asso_code', user.assoCode)}?page_no=&record_size=&message_type=P`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res)=>{
                if(res.status === 200){
                    let data = res.data;
                    let state = [...data.message];
                    let arr = [];
                    state.map(cont => {
                        arr.push(cont['content']);
                        setPriMsgList(arr);
                    })
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    //경매시작, 경매종료 타이머 팝업
    useEffect(() => {
        if(countPop){
            const countdown = setInterval(() => {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 1) {
                    setCountPop(false);
                    setSeconds(3);
                }
            }, 1000);
            return () => clearInterval(countdown);
        }
    },[seconds, countPop]);


    // 경매방 이름(ID)으로 사용 됨
    const room = auct_code + auct_date;

    // 실시간 정보 관련 초기화
    // 경매방, 닉네임 설정
    const socketInit = (e) => {    
        const data = { auct_code: auct_code, auct_date: auct_date };
        socket.emit("init", { room: room, data: data, userinfoType: "init default", user: user }, (data)=>{
            console.log(`init`);

            if(data.client) {
                setClientList([...data.client]);
                JSON.parse(localStorage.getItem('clientList'));
                localStorage.setItem("clientList", JSON.stringify(data.client));                
            }
        });
    };

    //경매리스트 스크롤자동으로 변경하기
    const scrollMove = (idx) => {
        console.log(idx)
        const targetTr = document.querySelectorAll('.left_cont .scroll_wrap .custom_table4 tr')[idx];
        const targetTrOffsetTop = targetTr.offsetTop;
        proListScrollWrap.current.scrollTo({ top: targetTrOffsetTop, behavior: 'smooth' });
    };

    // 경매 - 시작
    const handleAuctionStart = () => {
        if(myAuct && auctStart){
            if(clientList.length > 0){
                let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                let detltrns ={
                    de_date_online: auctProData.de_date_online,
                    de_no_online: auctProData.de_no_online,
                    de_seq: auctProData.de_seq,
                    de_seq2: auctProData.de_seq2,
                    de_status: 6,
                }
                JSON.parse(localStorage.getItem('auctStartData'));
                setTimeout(()=>{
                    //물품상태가 거래완료전이면 바로 경매시작
                    if(auctProData.de_status < 9){
                        socket.emit("auction_start", {room: room, auct_date:auct_date, path: "auction_start", detltrns: detltrns }, (result) => { 
                            // console.log(JSON.stringify(result));
                            if (result.result === "fail") {
                                // console.log(`result.error.msg: ${result.error.msg}`);
                                dispatch(confirmPop({
                                    confirmPopTit:'알림',
                                    confirmPopTxt:result.error.msg,
                                    confirmPopBtn:1,
                                }));
                                setConfirm(true);
                            } else {
                                localStorage.setItem("auctProData", JSON.stringify(detltrns));
                                localStorage.setItem("auctStartData", JSON.stringify(detltrns));
                                let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
                                setAuctStart(false);
                                setAuctSuccess(true);
                                setAuctFail(true);
                                setCountPop(true);
                                setCountPopType('start');
                                // // console.log(`서버 처리 완료 - 메시지 창에 내 메시지 갱신할 것 `);
                                let copy = {...productData};
                                let status = 6;
                                let time = new Date();
                                    time = moment(time).format('YYYY-MM-DD HH시 mm분');
                                copy['de_status'] = status;
                                copy['de_auct_date_start'] = time;
                                setProductData(copy);

                                let newProductList = productList.filter(pro => {
                                    if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                                        pro.de_status = "6";
                                        return pro;
                                    }else{
                                        return pro;
                                    }
                                });
                                setProductList(newProductList);
                                JSON.parse(localStorage.getItem('subList'));
                                localStorage.setItem("subList", JSON.stringify(newProductList));
                            }
                        });
                    }
                    //물품상태가 낙찰,유찰일때는 재경매사유작성팝업
                    else{
                        dispatch(restartPop(true));
                    }
                },100);
            }else{
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'현재 접속자가 없습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        }
        if(!auctStart){
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'현재 경매중입니다.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };

    //물품상태가 낙찰,유찰일때는 재경매사유작성완료하면 경매시작
    useEffect(()=>{
        if(popup.restartAuction){
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));
            let detltrns ={
                de_date_online: auctProData.de_date_online,
                de_no_online: auctProData.de_no_online,
                de_seq: auctProData.de_seq,
                de_seq2: auctProData.de_seq2,
                de_status: 6,
            }
            JSON.parse(localStorage.getItem('auctStartData'));
            setTimeout(()=>{
                socket.emit("auction_start", {room: room, auct_date:auct_date, path: "auction_start", detltrns: detltrns }, (result) => {
                    // console.log(JSON.stringify(result));
                    if (result.result === "fail") {
                        // console.log(`result.error.msg: ${result.error.msg}`);
                    } else {
                        // console.log(`서버 처리 완료 - 메시지 창에 내 메시지 갱신할 것 `);
                        setAuctStart(false);
                        setAuctSuccess(true);
                        setAuctFail(true);
                        setCountPop(true);
                        setCountPopType('start');
                        localStorage.setItem("auctProData", JSON.stringify(detltrns));
                        localStorage.setItem("auctStartData", JSON.stringify(detltrns));
                        let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));

                        //경매시작하면 물품리스트에서 현재물품 경매중상태로 변경하기
                        const newProductData = {
                            de_status : '6'
                        };
                        let copy = {...productData,...newProductData};
                        let time = new Date();
                            time = moment(time).format('YYYY-MM-DD HH시 mm분');
                        copy['de_auct_date_start'] = time;
                        copy['de_auct_date_end'] = "";
                        setProductData(copy);

                        let newProductList = productList.filter(pro => {
                            if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                                pro.de_status = "6";
                                pro.de_amer_code = "";
                                pro.de_price1 = 0;
                                return pro;
                            }else{
                                return pro;
                            }
                        });
                        setProductList(newProductList);
                        JSON.parse(localStorage.getItem('subList'));
                        localStorage.setItem("subList", JSON.stringify(newProductList));

                        //경매리스트 스크롤자동변경
                        scrollMove(onProduct);

                        //선택된 경매상품의 입찰,응찰,오류 조회
                        bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
                    }
                });
            },100);
        }
    },[popup.restartAuction]);


    // 경매 - 낙찰
    const handleAuctionSuccessfull = () => {
        // // console.log(myAuct,auctSuccess);
        if(myAuct && auctSuccess){
            //입찰자가 있을때만 낙찰가능
            if(bidList.length > 0){
                let amer_code = bidList[0].asso_code;
                let price = bidList[0].price;
                let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                let detltrns ={
                    de_date_online: auctProData.de_date_online,
                    de_no_online: auctProData.de_no_online,
                    de_seq: auctProData.de_seq,
                    de_seq2: auctProData.de_seq2,
                    de_status:10,
                    de_price1:price,
                }
                socket.emit("auction_successfull", {room: room, path: "auction_successfull", detltrns: detltrns }, (result) => {
                    // console.log(result);
                    if (result.statusCode) {
                        //console.log(`낙찰 서버 처리 실패`);
                    } else {
                        //console.log(`낙찰 서버 처리 완료`);
                        setAuctStart(true);
                        setAuctSuccess(false);
                        setAuctFail(false);
                        JSON.parse(localStorage.getItem('auctStartData'));
                        localStorage.setItem("auctStartData", JSON.stringify(detltrns));
                        let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
                        setCountPop(true);
                        setCountPopType('end');
                        let copy = {...productData};
                        let time = new Date();
                            time = moment(time).format('YYYY-MM-DD HH시 mm분');
                        copy['de_auct_date_end'] = time;
                        copy['de_status'] = 10;
                        setProductData(copy);

                        let newProductList = productList.filter(pro => {
                            if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                                pro.de_status = "10";
                                pro.de_amer_code = amer_code;
                                pro.de_price1 = price;
                                return pro;
                            }else{
                                return pro;
                            }
                        });
                        setProductList(newProductList);
                        JSON.parse(localStorage.getItem('subList'));
                        localStorage.setItem("subList", JSON.stringify(newProductList));
                        let subList = JSON.parse(localStorage.getItem('subList'));

                        //낙찰완료되면 다음물품으로 on
                        setTimeout(()=>{
                            let idx = subList.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                            if(subList.length > idx+1){
                                setOnProduct(idx+1);
                                let data = subList[idx+1];
                                proClickHandler(data);

                                //경매리스트 스크롤자동변경
                                scrollMove(idx+1);
                            }else{
                                let data = subList[idx];
                                proClickHandler(data);
                            }
                        },100);
                    }
                    // console.log(`result: ${JSON.stringify(result)}`);
                }
                );
            }else{
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입찰자가 없습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        }
        if(!auctSuccess){
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'경매가 시작되지 않았습니다.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };


    // 경매 - 유찰
    const handleAuctionFail = () => {
        if(myAuct && auctFail){
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));
            let detltrns ={
                de_date_online: auctProData.de_date_online,
                de_no_online: auctProData.de_no_online,
                de_seq: auctProData.de_seq,
                de_seq2: auctProData.de_seq2,
                de_status:11,
            }
            socket.emit("auction_fail",
                {
                    room: room, 
                    path: "auction_fail",
                    de_date_online: auctProData.de_date_online,
                    de_no_online: auctProData.de_no_online,
                    de_seq: auctProData.de_seq,
                    de_seq2: auctProData.de_seq2,
                    de_status:11,
                },
                (result) => {
                    if (result.statusCode) {
                        // console.log(`유찰 서버 처리 실패`);
                    } else {
                        // console.log(`유찰 서버 처리 완료`);
                        JSON.parse(localStorage.getItem('auctStartData'));
                        localStorage.setItem("auctStartData", JSON.stringify(detltrns));
                        let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
                        setAuctStart(true);
                        setAuctSuccess(false);
                        setAuctFail(false);
                        setCountPop(true);
                        setCountPopType('end');
                        let copy = {...productData};
                        let time = new Date();
                            time = moment(time).format('YYYY-MM-DD HH시 mm분');
                        copy['de_auct_date_end'] = time;
                        copy['de_status'] = 11;
                        setProductData(copy);
                        
                        let newProductList = productList.filter(pro => {
                            if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                                pro.de_status = "11";
                                return pro;
                            }else{
                                return pro;
                            }
                        });
                        setProductList(newProductList);
                        JSON.parse(localStorage.getItem('subList'));
                        localStorage.setItem("subList", JSON.stringify(newProductList));
                        let subList = JSON.parse(localStorage.getItem('subList'));
                        
                        //유찰완료되면 다음물품으로 on
                        setTimeout(()=>{
                            let idx = subList.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                            if(subList.length > idx+1){
                                setOnProduct(idx+1);
                                let data = subList[idx+1];
                                proClickHandler(data);

                                //경매리스트 스크롤자동변경
                                scrollMove(idx+1);
                            }else{
                                let data = subList[idx];
                                proClickHandler(data);
                            }
                        },100);
                    }
                    // console.log(`result: ${JSON.stringify(result)}`);
                }
            );
        }
        if(!auctFail){
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'경매가 시작되지 않았습니다.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };

    //키보드로 경매시작,낙찰 
    useEffect(() => {
        function handleKeyPress(event) {
            if(!nicknameFocus && !messageFocus && popup.restartPop === null && popup.messageSetPop === null){
                // 스페이스바 -> 경매시작   
                if (event.keyCode === 32) {
                    event.preventDefault();
                    handleAuctionStart();
                }

                // 엔터키 -> 경매낙찰
                if (event.key === "Enter") { 
                    event.preventDefault();
                    handleAuctionSuccessfull();
                }
            }
        }
    
        document.addEventListener("keydown", handleKeyPress);
    
        return () => {
          document.removeEventListener("keydown", handleKeyPress);
        };
    }, [myAuct,auctStart,auctSuccess,clientList,nicknameFocus,messageFocus,popup.restartPop,popup.messageSetPop]);

    //채팅 새메시지가 뜨면 스크롤밑으로
    useEffect(() => {
        setMessages(messages);
        // // console.log(`메시지${JSON.stringify(messages, null, 2)}`)
        messageBox.current.scrollTop = messageBox.current.scrollHeight;
    }, [messages]);

    useEffect(()=>{
        setAllMsgList(allMsgList);
    },[allMsgList]);

    useEffect(()=>{
        setAllMsgOnTxt(allMsgOnTxt);
    },[allMsgOnTxt]);

    useEffect(()=>{
        setPriMsgList(priMsgList);
    },[priMsgList]);

    useEffect(()=>{
        setPriMsgOnTxt(priMsgOnTxt);
    },[priMsgOnTxt]);

    // 메시지 - 노출
    const addMessage = (msg) => {
        setMessages(messages => [...messages, msg]);
    };
    
    // 경매종료 이벤트 처리 경매종료버튼 클릭
    const handleOnlineAuctionClose = () => {
        if(myAuct){
            axios.get(`${a_list_auction.replace(':asso_code', auct_code).replace(':auct_date', auct_date)}?search_date_start=&search_date_end=&ip_no_online_start=&ip_no_online_end=&de_status`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res)=>{
                if(res.status === 200){
                    let proList = res.data.detltrns;
                    let proNewList = proList.filter((pro) => pro.de_status.trim() < 10 );
                    if(proNewList.length > 0){
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt:'경매 물건이 남아있습니다. 경매종료를 하시겠습니까?',
                            confirmPopBtn:2,
                        }));
                        setEndConfirm(true);
                    }else{
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt:'경매종료를 하시겠습니까?',
                            confirmPopBtn:2,
                        }));
                        setEndConfirm(true);
                    }
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    // 경매종료 이벤트 처리 
    const endHandler = () => {
        socket.emit("online_auction_close", { room: room, auct_date: auct_date}, () => {
            navigate("/auctioneer/bid-result");
        });
    };

    // 주요메시지 - 전송
    const handleImportantMessage = (e) => {
        if(myAuct){
            const value = nickname.current.value;
            socket.emit("important_message", {room: room, path: "nickname", nickname: value });
            // console.log(`nickname changed: ${value}`);

            setAllMsgPopShow(true);
            axios.get(`${a_message_list.replace(':asso_code', user.assoCode)}?page_no=&record_size=&message_type=A`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res)=>{
                if(res.status === 200){
                    let data = res.data;
                    let state = [...data.message];
                    let arr = [];
                    state.map(cont => {
                        arr.push(cont['content']);
                        setAllMsgList(arr);
                    })
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };


    //개인 주요메시지 보내기 - 오류발생 이력에서 메시지 보내는 핸들러 
    const handlePriMessage = () => {
        const value = priMsgOnTxt;
        const code = nickname.current.value;

        const logMsg = {
            auct_code: auct_code,
            auct_dt: auct_date,
            sender_asso_code: auctCode,
            receiver_asso_code: code,
            msg: value,
        }
        
        socket.emit("message", { room: room, path: "message", message: value, asso_code: code, logMsg: logMsg }, () => {
            const msg = {
                "type": "send",
                "msg": value,
                "time": getNow(),
                "pri_asso_code": code
            }
            addMessage(msg);
            setPriMsgPopShow(false);
            setNicknameInput('');
        });
    };

    //전체 주요메시지 보내기
    const handleAllMessage = () => {
        const value = allMsgOnTxt;
        const code = nickname.current.value;

        const logMsg = {
            auct_code: auct_code,
            auct_dt: auct_date,
            sender_asso_code: auctCode,
            receiver_asso_code: 'all',
            msg: value,
        }

        socket.emit("message", { room: room, path: "message", message: value, asso_code: code, logMsg: logMsg }, () => {
            const msg = {
                "type": "send",
                "msg": value,
                "time": getNow()
            }
            addMessage(msg);
            setAllMsgPopShow(false);
        });
    };

    //메시지 시간
    function getNow() {
        const currDate = new Date();
        return (
            ("0" + currDate.getHours()).slice(-2) +
            ":" +
            ("0" + currDate.getMinutes()).slice(-2)
        );
    }

    // 메시지 - 전송
    const handleMessage = (e) => {
        const value = message.current.value;
        const code = nickname.current.value;
          
        const logMsg = {
            auct_code: auct_code,
            auct_dt: auct_date,
            sender_asso_code: auctCode,
            receiver_asso_code: code || 'all',
            msg: value,
        }

        if(myAuct){
            if(value.length > 0){
                socket.emit("message", { room: room, path: "message", message: value, asso_code: code, logMsg: logMsg }, () => {
                    let pri_asso_code = '';
                    if(code){
                        pri_asso_code =  code;
                    }else{
                        pri_asso_code = 'all';
                    }
                    const msg = {
                        "type": "send",
                        "msg": value,
                        "time": getNow(),
                        "pri_asso_code": pri_asso_code
                    }
                    addMessage(msg);
                });
                message.current.value = '';
                setNicknameInput('');
            }else{
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'메시지를 입력해주세요.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        }
    };

    
    useEffect(()=>{
        socket.on('connect', () => {
            setMyConnect(true);
            // console.log('Connected to the server');
            reconnectAttempts = 0; // 연결에 성공하면 재접속 시도 횟수를 초기화합니다.
        });
        
        socket.on('disconnect', () => {
            socketInit();
            setMyConnect(false);
            // console.log('Disconnected from the server');
            if (reconnectAttempts < maxReconnectAttempts) {
                setTimeout(() => {
                reconnectAttempts++;
                // console.log(`Reconnect attempt ${reconnectAttempts}`);
                socket.connect();
                }, 3000); // 재접속 시도 간격을 설정합니다. 여기서는 3초로 설정했습니다.
            } else {
                // console.log('Maximum reconnect attempts reached');
            }
        });

        // 닉네임 변경 - 수신
        socket.on("nickname", (msg) => {
            // console.log(JSON.stringify(msg, null, 2));
            msg.user = user
            addMessage(msg);
        });

        // 메시지 - 수신
        socket.on("message", (msg) => {
            // console.log(JSON.stringify(msg, null, 2));
            addMessage(msg);
        });

        // 메시지 - 수신
        socket.on("message_private", (msg) => {
            console.log(JSON.stringify(msg, null, 2));
            addMessage(msg);
        });

        // 접속자 수신
        socket.on("join", (client) => {
            console.log(JSON.stringify(client, null, 2));
            setClientList([...client]);
            JSON.parse(localStorage.getItem('clientList'));
            localStorage.setItem("clientList", JSON.stringify(client));
        });

        // 접속자 수신
        socket.on("auth_error", (msg) => {
            // console.log(msg);
        });

        //중도매인 온라인 경매 응찰 정보 수신 
        socket.on("bid", (result) => {
            // console.log(`result`);
            // console.log(JSON.stringify(result, null, 2));
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));

            //선택된 경매상품의 입찰,응찰,오류 조회
            bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
        });

        // 중도매인 온라인 경매 응찰취소 정보 수신 
        socket.on("bid_cancel", (result) => {
            // console.log(`result`);
            // console.log(JSON.stringify(result, null, 2));
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));

            //선택된 경매상품의 입찰,응찰,오류 조회
            bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
        });

        // 중도매인 온라인 경매 응찰 정보 수신 
        socket.on("leave_merchant", (result) => {
            // console.log(`result`);
            console.log(JSON.stringify(result, null, 2));
            // console.log(clientList)
            if(result.leaveUser){
                let list = JSON.parse(localStorage.getItem('clientList'));
                let newList = list.filter((client) => client.asso_code !== result.leaveUser.asso_code);
                // console.log(newList);
                setClientList(newList);
                localStorage.setItem("clientList", JSON.stringify(newList));
            }
        });
    }, []);

    // 페이지 접근시 바로 닉네임 세팅
    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }
    }, []);

    useEffect(()=>{
        socketInit();
    },[socket.connected]);

    return(
        <div className="page_sub">
            <div className="auction_wrap">
                <div className="cont">
                    <Formik
                        initialValues={{
                            search_date_start: "",
                            search_date_end: "",
                            de_status: "",
                            ip_no_online_start: "",
                            ip_no_online_end: "",
                        }}
                        onSubmit={searchSubmit}
                    >
                        {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="top_cont flex_between">
                                    <p className="tit">온라인 경매</p>
                                    <ul className="flex_end inner_ul2">
                                        {/* <li>
                                            <div className="date_input2 flex_between">
                                                <div className="custom_input">
                                                    <PatternFormat name="search_date_start" value={values.search_date_start} 
                                                        format="####-##-## ##:##"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="custom_input">
                                                    <PatternFormat name="search_date_end" value={values.search_date_end} 
                                                        format="####-##-## ##:##"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="custom_select">
                                                <select 
                                                    name="de_status"
                                                    value={values.de_status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ display: 'block' }}
                                                >
                                                    <option value="" >상태구분</option>
                                                    <option value="de_status_5">경매대기</option>
                                                    <option value="de_status_6">경매중</option>
                                                    <option value="de_status_7">입찰대기</option>
                                                    <option value="de_status_8">입찰중</option>
                                                    <option value="de_status_9">입찰종료</option>
                                                    <option value="de_status_10">거래완료(경매)</option>
                                                    <option value="de_status_11">거래완료(입찰)</option>
                                                    <option value="de_status_12">완료(불낙)</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex_between">
                                                <p className="txt">입하번호</p>
                                                <div className="flex_between num_box">
                                                    <div className="flex_between">
                                                        <div className="custom_input">
                                                            <input type="text" name="ip_no_online_start" value={values.ip_no_online_start} onChange={handleChange} />
                                                        </div>
                                                        <div className="custom_input">
                                                            <input type="text" name="ip_no_online_end" value={values.ip_no_online_end} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <button className="btn_s" type="submit">경매자료 조회</button>
                                                </div>
                                            </div>
                                        </li> */}
                                        <li className="flex rm20">
                                            <p className="f_16 rm20">경매시작시간 : {CF.MDFWithHour(auct_date,4)}</p>
                                            {auctStatus != "5" && <button className="btn_s2" type="button" onClick={handleOnlineAuctionClose}>경매종료</button>}
                                        </li>
                                        <li className="flex">
                                            <ul className="flex connect_box">
                                                <li className="flex">
                                                    <span>경매사</span>
                                                    <p className={`circle ${myConnect ? "" : "out"}`}></p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <div className="auction_cont auction_cont2 flex_between">
                        <div className="left_cont">
                            <div className="border_box">
                                <div className="scroll_wrap" ref={proListScrollWrap}>
                                    <div className="custom_table4 pro_list_table" ref={proListTable}>
                                        <table>
                                            <colgroup>
                                                <col style={{width:'10%'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'10%'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'10%'}} />
                                                <col style={{width:'10%'}} />
                                                <col style={{width:'6%'}} />
                                                <col style={{width:'6%'}} />
                                                <col style={{width:'10%'}} />
                                                <col style={{width:'8%'}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>번호</th>
                                                    <th>출하주</th>
                                                    <th>생산자</th>
                                                    <th>품명</th>
                                                    <th>규격</th>
                                                    <th>등급</th>
                                                    <th>중량</th>
                                                    <th>수량</th>
                                                    <th>가격</th>
                                                    <th>낙찰자</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productList && productList.map((pro,i)=>{
                                                    let de_status = pro.de_status.trim();
                                                    let de_weight = CF.MakeIntComma(pro.de_weight);
                                                    let de_quantity = CF.MakeIntComma(pro.de_quantity);
                                                    let de_hope_pri = CF.MakeIntComma(pro.de_hope_pri);
                                                    return(
                                                        <tr onClick={()=>{
                                                                proClickHandler(pro);
                                                                // let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                                                                // auctProData = {
                                                                //     de_date_online:pro.de_date_online,
                                                                //     de_no_online:pro.de_no_online,
                                                                //     de_seq:pro.de_seq,
                                                                //     de_seq2:pro.de_seq2,
                                                                //     de_status:pro.de_status,
                                                                // };
                                                                // localStorage.setItem("auctProData", JSON.stringify(auctProData));
                                                                // setTimeout(()=>{
                                                                //     proListClickHandler();
                                                                // },100);
                                                                setOnProduct(i);
                                                            }} 
                                                            className={onProduct === i ? "tr_current" 
                                                                        : de_status == 11 ? "tr_red2" 
                                                                        : de_status > 8 ? "tr_red" : "tr_blue"} 
                                                            key={i}
                                                        >
                                                            <td>{pro.de_no_online+pro.de_seq+pro.de_seq2}</td>
                                                            <td><span className="ellipsis">{pro.as_name}</span></td>
                                                            <td>{pro.de_farm_name}</td>
                                                            <td><span className="ellipsis">{pro.gm_name === null ? pro.gm_stmd_name : pro.gm_name}</span></td>
                                                            <td>{pro.de_packing_name}</td>
                                                            <td>{pro.de_dansu > 1 && pro.de_dansu + "/"}{pro.de_high_name}</td>
                                                            <td className="tx_r">{de_weight}</td>
                                                            <td className="tx_r">{de_quantity}</td>
                                                            <td className="tx_r">{CF.MakeIntComma(pro.de_price1)}</td>
                                                            <td>{pro.de_amer_code ? pro.de_amer_code : '-'}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tp20">
                                <div className="flex_between">
                                    <ul className="tab_ul flex">
                                        <li className={tabContOn === 0 ? 'on' : ''} onClick={()=>{setTabContOn(0)}}>기본정보</li>
                                        <li className={tabContOn === 1 ? 'on' : ''} onClick={()=>{setTabContOn(1)}}>사진/동영상</li>
                                    </ul>
                                    <span className="tag">경매사 : {productData && productData.ip_auct_name}</span>
                                </div>
                                <div className="tab_wrap">
                                    <div className={`tab_cont ${tabContOn === 0 ? 'on' : ''}`}>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'10%'}} />
                                                    <col style={{width:'70px'}} />
                                                    <col style={{width:'70px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'10%'}} />
                                                    <col style={{width:'40%'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>현재상태</th>
                                                        <td colSpan="3">{productData && enum_de_status[productData.de_status][0]}</td>
                                                        <th>경매시작</th>
                                                        <td>{productData && productData.de_auct_date_start !== null ? productData.de_auct_date_start : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>경매종료</th>
                                                        <td colSpan="3" className="p0">
                                                            <div className="flex_between lp9">
                                                                <span>{productData && productData.de_auct_date_end !== null ? productData.de_auct_date_end : '-'}</span>
                                                                {productData && productData.de_status == 10 &&
                                                                    <div className="soldout_txt"><span>판매완료</span></div>
                                                                }
                                                                {productData && productData.de_status === 12 &&
                                                                    <div className="soldout_txt"><span>유찰완료</span></div>
                                                                }
                                                            </div>
                                                        </td>
                                                        <th>매매구분</th>
                                                        <td>{productData && productData.ip_s_kind}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>출하주</th>
                                                        <td colSpan="3">{productData && `${productData.ip_asso_code} ${productData.as_name}`}</td>
                                                        <th>등록일시</th>
                                                        <td>{productData && productData.de_reg_dt}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>판매구분</th>
                                                        <td>{productData && productData.ip_kind}</td>
                                                        <th>산지</th>
                                                        <td>{productData && productData.de_loca_name}</td>
                                                        <th>출하처 주소</th>
                                                        <td>{productData && `${productData.as_addr1} ${productData.as_addr2}`}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom_table gray_table tm10">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>생산자명</th>
                                                        {/* <td>{productData && `${productData.de_farm_name}(${productData.de_farm_code})`}</td> */}
                                                        <td>{productData && `${productData.de_farm_name}`}</td>
                                                        <th>품목</th>
                                                        {/* <td><div className="over_txt"><span>{productData && `${productData.gm_name}(${productData.de_gods_code})`}</span></div></td> */}
                                                        <td><div className="over_txt"><span>{productData && `${productData.gm_name}`}</span></div></td>
                                                        <td colSpan="2" className="price_box">
                                                            <div className="flex">
                                                                <p>희망단가</p>
                                                                <p className="tx_r">{productData && productData.de_hope_pri}원</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>수량</th>
                                                        <td className="tx_r">{productData && productData.de_quantity}개</td>
                                                        <th>중량</th>
                                                        <td className="tx_r">{productData && productData.de_weight}kg</td>
                                                        <th>규격</th>
                                                        <td>{productData && productData.de_packing_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>등급</th>
                                                        <td>{productData && productData.de_high_name}</td>
                                                        <th className="tx_r">팰릿</th>
                                                        <td className="tx_r">{productData && productData.ip_kpp}개</td>
                                                        <th>목재(옥타곤)</th>
                                                        <td className="tx_r">{productData && productData.ip_kcp}개</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom_table gray_table tm10">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>품질</th>
                                                        <td><div className="over_txt"><span>{productData && productData.de_cont1}</span></div></td>
                                                        <th>당도,산도 등</th>
                                                        <td><div className="over_txt"><span>{productData && productData.de_cont2}</span></div></td>
                                                    </tr>
                                                    <tr>
                                                        <th>특이사항</th>
                                                        <td><div className="over_txt"><span>{productData && productData.de_cont3}</span></div></td>
                                                        <th>경매사메모</th>
                                                        <td><div className="over_txt"><span>{productData && productData.memo.memo}</span></div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={`tab_cont ${tabContOn === 1 ? 'on' : ''}`}>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="tx_c">사진1</th>
                                                        <th className="tx_c">사진2</th>
                                                        <th className="tx_c">동영상</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="tx_c">
                                                            {productData && productData.de_img1 === "" ? 
                                                                <div className="flex_center h_250"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                                :   productData && <div className="thumb_box h_250 over_hidden" onClick={() => {
                                                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${productData.de_img1}`}))
                                                                    }}>
                                                                        <img src={`${productData.de_img1}`} alt="이미지"/>
                                                                    </div>
                                                            }
                                                        </td>
                                                        <td className="tx_c">
                                                            {productData && productData.de_img2 === "" ? 
                                                                <div className="flex_center h_250"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                                :   productData && <div className="thumb_box h_250 over_hidden" onClick={() => {
                                                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${productData.de_img2}`}))
                                                                    }}>
                                                                        <img src={`${productData.de_img2}`} alt="이미지"/>
                                                                    </div>
                                                            }
                                                        </td>
                                                        <td className="tx_c">
                                                            {productData &&  productData.de_video === "" ? 
                                                                <div className="flex_center h_250"><img src={videoUp} alt="이미지"/></div>
                                                                :   productData && <div className="thumb_box h_250 over_hidden" onClick={() => {
                                                                        dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${productData.de_video}`}))
                                                                    }}>
                                                                        <video src={`${productData.de_video}`} />
                                                                    </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right_cont">
                            <div className="top_box">
                                <div className="user_box">
                                    <div className="box flex_center">
                                        <button className="rm20"
                                            onClick={() => {
                                                if(clientList.length > 0){
                                                    setClientPopTit('');
                                                    setClientPopShow({show:!clientPopShow.show,type:'All'});
                                                }
                                            }}>접속자조회
                                        </button>
                                        <button className="rm5 ic_btn" 
                                            onClick={() => {
                                                if(clientPcList.length > 0){
                                                    setClientPopTit('PC ');
                                                    setClientPopShow({show:!clientPopShow.show,type:'PC'});
                                                }
                                            }}>PC<span>{clientPcList.length}</span>
                                        </button>
                                        <button className="ic_btn"
                                            onClick={() => {
                                                if(clientMoList.length > 0){
                                                    setClientPopTit('모바일 ');
                                                    setClientPopShow({show:!clientPopShow.show,type:"MO"});
                                                }
                                            }}>모바일<span>{clientMoList.length}</span>
                                        </button>
                                    </div>
                                    <ul className="txt_ul flex_between">
                                        {bidList.length > 0 ? 
                                            <li>{CF.MakeIntComma(bidList[0].price)}</li>
                                            : <li>-</li>
                                        }
                                        {bidList.length > 0 ? 
                                            <li>{bidList[0].asso_code}</li>
                                            : <li>-</li>
                                        }
                                    </ul>
                                    {/* 접속자 팝업 */}
                                    {clientPopShow.show === true && (
                                        <div className="user_pop">
                                            <div className="dim" onClick={()=>{
                                                setClientPopShow({show:false});
                                                setClientActive(null);
                                                setInfoBoxShow(false);
                                            }}></div>
                                            <div className="inner_cont">
                                                <p className="tit">{clientPopTit}접속자</p>
                                                <div className="scroll_wrap">
                                                    <ul className={`flex_wrap list_ul ${clientPopShow.type === "MO" ? "list_ul2" : ""}`}>
                                                        {/* 전체접속자 */}
                                                        {clientPopShow.type === "All" && clientList.map((client,i) => {
                                                            return(
                                                                <li key={i} onClick={(e)=>{clientClickHandler(e,i,client.asso_code);}}
                                                                    className={i == clientActive ? 'on':''}
                                                                >
                                                                    <span className="ellipsis">{`[${client.asso_code}] ${client.mem_nm}`}</span>
                                                                </li>
                                                            );
                                                        })}

                                                        {/* PC접속자 */}
                                                        {clientPopShow.type === "PC" && clientPcList.map((client,i) => {
                                                            return(
                                                                <li key={i} onClick={(e)=>{clientClickHandler(e,i,client.asso_code);}}
                                                                    className={i == clientActive ? 'on':''}
                                                                >
                                                                    <span className="ellipsis">{`[${client.asso_code}] ${client.mem_nm}`}</span>
                                                                </li>
                                                            );
                                                        })}

                                                        {/* MO접속자 */}
                                                        {clientPopShow.type === "MO" && clientMoList.map((client,i) => {
                                                            return(
                                                                <li key={i} onClick={(e)=>{clientClickHandler(e,i,client.asso_code);}}
                                                                    className={i == clientActive ? 'on':''}
                                                                >
                                                                    <span className="ellipsis">{`[${client.asso_code}] ${client.mem_nm}`}</span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                                {infoBoxShow && (
                                                    <InfoBox name={infoName} price1={infoPrice1} price2={infoPrice2} position={infoBox} />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="table_box">
                                    <div className="border_box">
                                        <div className="scroll_wrap">
                                            <div className="custom_table6">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width:'60%'}} />
                                                        <col style={{width:'auto'}} />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>단가</th>
                                                            <th>응찰자</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bidList && bidList.map((bid,i)=>{
                                                            return(
                                                                <tr key={i} className={i === 0 ? 'on' : ''}>
                                                                    <td className="tx_r rp30">{CF.MakeIntComma(bid.price)}</td>
                                                                    <td>{bid.asso_code}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {auctStatus != "5" && 
                                            <ul className="btn_ul3 flex">
                                                <li>
                                                    <button onClick={handleAuctionStart}>시작</button>
                                                </li>
                                                <li>
                                                    <button onClick={handleAuctionSuccessfull}>낙찰</button>
                                                </li>
                                                <li>
                                                    <button onClick={handleAuctionFail}>유찰</button>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="border_box scroll_wrap tm12">
                                        <div className="custom_table4 error_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'85px'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>시간</th>
                                                        <th>오류형태</th>
                                                        <th>응찰가</th>
                                                        <th>응찰자</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bidErrorList && bidErrorList.map((error,i)=>{
                                                        let time = moment(error.l_time).format("HH:mm.ss.SSS");
                                                        return(
                                                            <tr className="tr_excess" onClick={()=>{
                                                                errorUserClickHandler();
                                                                setNicknameInput(error.l_amer);
                                                            }} key={i}>
                                                                <td className="f_13">{time}</td>
                                                                <td className="f_13">{error.l_gbn}</td>
                                                                <td className="f_13 tx_r rp20">{CF.MakeIntComma(error.l_price)}</td>
                                                                <td className="f_13">{error.l_amer}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="message_wrap tm20">
                                <div className="top_box">
                                    <div className="scroll_wrap" ref={messageBox}>
                                        <ul className="box" id="message_list">
                                            {messages && messages.map((msg,i)=>{
                                                return(
                                                    <li key={i} className={msg.type}>
                                                        <p className="txt">
                                                            {msg.type === "receive" && <strong>{msg.user.asso_code}</strong>}
                                                            {msg.type === "send" &&  msg.pri_asso_code != "all" && <strong>{msg.pri_asso_code}</strong>}
                                                            {msg.msg}
                                                        </p>
                                                        <span className="time">{msg.time}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="bottom_box2 flex_wrap">
                                    <div className="box flex_between w_100">
                                        <div className="custom_input">
                                            <NumericFormat placeholder="중도매인" getInputRef={nickname} value={nicknameInput} onChange={(e)=>{setNicknameInput(e.currentTarget.value)}} allowLeadingZeros={true} onFocus={()=>{setNicknameFocus(true)}} onBlur={()=>{setNicknameFocus(false)}} />
                                        </div>
                                        <button className="btn_s3" onClick={handleImportantMessage}>주요메시지</button>
                                    </div>
                                    <div className="box2 flex_between w_100">
                                        <div className="custom_input">
                                            <input type="text" placeholder="메시지를 입력해 주세요" ref={message} onFocus={()=>{setMessageFocus(true)}} onBlur={()=>{setMessageFocus(false)}} onKeyDown={(e)=>{
                                                if(e.key === "Enter") {
                                                    if (e.nativeEvent.isComposing === false) {
                                                        e.preventDefault();
                                                        handleMessage();
                                                    }
                                                }
                                            }}/>
                                        </div>
                                        <button className="btn_s" type="button" onClick={handleMessage}>전송</button>
                                    </div>
                                </div>

                                {/* 개인 주요메시지 팝업 */}
                                {priMsgPopShow &&
                                    <div className="message_pop">
                                        <div className="dim" onClick={()=>{setPriMsgPopShow(false);setNicknameInput('');}}></div>
                                        <div className="inner_cont">
                                            <div className="scroll_wrap">
                                                <ul className="txt_ul">
                                                    {priMsgList && priMsgList.map((msg,i)=>{
                                                        return(
                                                            <li key={i} 
                                                                onClick={()=>{
                                                                    setPriMsgOn(i);
                                                                    setPriMsgOnTxt(msg);
                                                                }} 
                                                                className={`${priMsgOn === i ? "on" : ''}`}>{msg}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <button className="btn_s" onClick={handlePriMessage}>메시지 <br/>전송</button>
                                        </div>
                                    </div>
                                }

                                {/* 전체 주요메시지 팝업 */}
                                {allMsgPopShow &&
                                    <div className="message_pop message_pop2">
                                        <div className="dim" onClick={()=>{setAllMsgPopShow(false)}}></div>
                                        <div className="inner_cont flex_wrap">
                                            {/* <p className="txt">[007] 응찰 : 30,000원 (2020808211048)</p> */}
                                            <div className="scroll_wrap">
                                                <ul className="txt_ul">
                                                    {allMsgList && allMsgList.map((msg,i)=>{
                                                        return(
                                                            <li key={i} 
                                                                onClick={()=>{
                                                                    setAllMsgOn(i);
                                                                    setAllMsgOnTxt(msg);
                                                                }} 
                                                                className={`${allMsgOn === i ? "on" : ''}`}>{msg}</li>
                                                        );
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                            <button className="btn_s" onClick={handleAllMessage}>메시지 <br/>전송</button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 경매카운트 ( 경매종료시 count_cont 클래스에 end 추가)*/}
            {countPop &&
                <div className="pop_wrap">
                    <div className="dim"></div>
                    <div className={countPopType == 'end' ? 'count_cont end' : 'count_cont'}>
                        <p className="num">{seconds}</p>
                        <p className="txt">{countPopType == 'end' ? '경매종료' : '경매시작'}</p>
                    </div>
                </div>
            }
            
            {/* 경매종료 confirm팝업 */}
            {endConfirm && <ConfirmPop onClickHandler={endHandler} />} 

            {/* 확인confirm팝업 */}
            {confirm && <ConfirmPop />} 

        </div>
    );
};


// 접속자조회팝업에서 접속자클릭시 뜨는 정보팝업
const InfoBox = (props) => {
    return(
        <div className="info_box" style={{top:props.position[0], left:props.position[1]}}>
            <p className="txt">{props.name}</p>
            <ul className="txt_ul">
                <li className="flex_between">
                    <span>한 도 액</span>
                    <span>{props.price1}</span>
                </li>
                <li className="flex_between">
                    <span>구매가능</span>
                    <span>{props.price2}</span>
                </li>
            </ul>
        </div>
    );
}

export default OnlineAuctionDetail;


