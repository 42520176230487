import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle } from "../../../store/commonSlice";
import { enum_api_uri, enum_mo_title } from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';


const Statistics = () => {
    const a_auctioneers_list = enum_api_uri.a_auctioneers_list;
    const a_statistics_month = enum_api_uri.a_statistics_month;
    const a_statistics_day = enum_api_uri.a_statistics_day;
    const a_statistics_month_excel = enum_api_uri.a_statistics_month_excel;
    const a_statistics_day_excel = enum_api_uri.a_statistics_day_excel;
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [ip_date_start, setIpDateStart] = useState('');
    const [ip_date_end, setIpDateEnd] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const location = useLocation();
    registerLocale('ko', ko);
    const basicDate = new Date();
    const [code, setCode] = useState("");
    const [codeList, setCodeList] = useState([]);
    const [monthDataList, setMonthDataList] = useState([]);
    const [dayDataList, setDayDataList] = useState([]);
    let sumTon = 0;
    let sumAmt1 = 0;
    let sumQuantity = 0;
    let sumDayTon = 0;
    let sumDayAmt1 = 0;


    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);


    useEffect(()=>{ //처음 한번 리스트 불러오기
        let val = [];
        val.ip_auct_date_start = moment().format('YYYYMMDD');
        val.ip_auct_date_end = moment().format('YYYYMMDD');
        
        setIpDateStart(basicDate)
        setIpDateEnd(basicDate)

        getMonthList(val);
        getDayList(val);

        //사원코드 리스트가져오기 (경매사목록)
        getCodeList();
    },[]);


    //사원코드 리스트가져오기 (경매사목록)
    const getCodeList = () => {
        axios.get(a_auctioneers_list,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                const data = res.data;
                setCodeList(data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    //월별통계 가져오기
    const getMonthList = (val) => {
        axios.get(`${a_statistics_month}?ip_auct_date_start=${val.ip_auct_date_start}&ip_auct_date_end=${val.ip_auct_date_end}${code.length > 0 ? '&is_code='+code : ''}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                const data = res.data;
                setMonthDataList(data);

                // 대상기간날짜 변환
                const dateS = moment(val.ip_auct_date_start).format('YYYY.MM.DD');
                const dateE = moment(val.ip_auct_date_end).format('YYYY.MM.DD');
                setDateStart(dateS);
                setDateEnd(dateE);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    //일별통계 가져오기
    const getDayList = (val) => {
        axios.get(`${a_statistics_day}?ip_auct_date_start=${val.ip_auct_date_start}&ip_auct_date_end=${val.ip_auct_date_end}${code.length > 0 ? '&is_code='+code : ''}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                const data = res.data;
                setDayDataList(data);

                // 대상기간날짜 변환
                const dateS = moment(val.ip_auct_date_start).format('YYYY.MM.DD');
                const dateE = moment(val.ip_auct_date_end).format('YYYY.MM.DD');
                setDateStart(dateS);
                setDateEnd(dateE);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    // 결과리스트 조회
    const submit = () => {
        let val = [];
        val.ip_auct_date_start = moment(ip_date_start).format('YYYYMMDD'); 
        val.ip_auct_date_end = moment(ip_date_end).format('YYYYMMDD'); 

        getMonthList(val);
        getDayList(val);
    };


    //pdf 다운로드버튼클릭시 다운로드페이지 열기
    const pdfBtnClickHandler = (type) => {
        const date_start = moment(ip_date_start).format('YYYYMMDD');
        const date_end = moment(ip_date_end).format('YYYYMMDD');

        let isCode;
        if(code.length > 0){
            isCode = code;
        }else{
            isCode = 0;
        }

        window.open(`statistics/pdf/${type}/${date_start}/${date_end}/${isCode}`);
    };


    //엑셀파일다운로드
    const excelBtnClickHandler = (type) => {
        const ip_auct_date_start = moment(ip_date_start).format('YYYYMMDD'); 
        const ip_auct_date_end = moment(ip_date_end).format('YYYYMMDD'); 

        if(type == 'month'){
            axios.get(`${a_statistics_month_excel}?ip_auct_date_start=${ip_auct_date_start}&ip_auct_date_end=${ip_auct_date_end}${code.length > 0 ? '&is_code='+code : ''}`,
                {
                    headers:{Authorization: `Bearer ${token}`},
                    responseType: 'blob' // 요청 데이터 형식을 blob으로 설정
                }
            )
            .then((res)=>{
                if(res.status === 200){
                    // Blob을 File로 변환
                    const blob = new Blob([res.data], { type: res.headers['content-type'] });
                    const url = window.URL.createObjectURL(blob);

                    // a 태그를 생성하여 다운로드 링크 설정
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = '월별통계.xlsx'; // 원하는 파일명으로 설정

                    // a 태그를 DOM에 추가하고 클릭하여 다운로드 진행
                    document.body.appendChild(a);
                    a.click();

                    // 사용이 끝난 a 태그를 DOM에서 제거
                    document.body.removeChild(a);

                    // Blob URL을 해제
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }else if(type == 'day'){
            axios.get(`${a_statistics_day_excel}?ip_auct_date_start=${ip_auct_date_start}&ip_auct_date_end=${ip_auct_date_end}${code.length > 0 ? '&is_code='+code : ''}`,
                {
                    headers:{Authorization: `Bearer ${token}`},
                    responseType: 'blob' // 요청 데이터 형식을 blob으로 설정
                }
            )
            .then((res)=>{
                if(res.status === 200){
                    // Blob을 File로 변환
                    const blob = new Blob([res.data], { type: res.headers['content-type'] });
                    const url = window.URL.createObjectURL(blob);

                    // a 태그를 생성하여 다운로드 링크 설정
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = '일별통계.xlsx'; // 원하는 파일명으로 설정

                    // a 태그를 DOM에 추가하고 클릭하여 다운로드 진행
                    document.body.appendChild(a);
                    a.click();

                    // 사용이 끝난 a 태그를 DOM에서 제거
                    document.body.removeChild(a);

                    // Blob URL을 해제
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className="auction_wrap auction_wrap2 bp30">
                    <div className="cont">
                        <Formik
                            initialValues={{
                                ip_auct_date_start: basicDate || "",
                                ip_auct_date_end: basicDate || "",
                            }}
                            onSubmit={submit}
                        >
                            {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="top_cont flex_between">
                                        <p className="tit">통계 검색</p>
                                        <ul className="flex inner_ul2 inner_ul3">
                                            <li className="rm40">
                                                <div className="date_input2 flex_between">
                                                    <div className="custom_input">
                                                        <DatePicker
                                                            selected={ip_date_start}
                                                            dateFormat="yyyy-MM-dd"
                                                            locale="ko"
                                                            onChange={date => setIpDateStart(date)}
                                                        />
                                                    </div>
                                                    <div className="custom_input">
                                                        <DatePicker
                                                            selected={ip_date_end}
                                                            dateFormat="yyyy-MM-dd"
                                                            locale="ko"
                                                            onChange={date => setIpDateEnd(date)}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="flex">
                                                <div className="custom_select rm5">
                                                    <select 
                                                        value={code}
                                                        onChange={(e)=>{
                                                            const val = e.currentTarget.value;
                                                            setCode(val);
                                                        }}
                                                        style={{ display: 'block' }}
                                                    >
                                                        <option value="">사원코드</option>
                                                        {codeList.map((cont,i)=>{
                                                            return(<option key={i} value={cont.is_code}>{`${cont.is_name}(${cont.is_code})`}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                                <button className="btn_s" type="submit">결과조회</button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <div className="tp20">
                            <div className="bp40">
                                <div className="tx_c">
                                    <p className="f_20 medium">이미지 경매 현황</p>
                                    <p className="f_15 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                                </div>
                                {monthDataList.length > 0 ? 
                                    <>
                                        <div className="flex_between bp10">
                                            <p className="f_15">법인명 : 구리청과㈜</p>
                                            <p className="f_12 txt_gray">단위 : Kg, 원</p>
                                        </div>
                                        <div className="custom_table gray_table2">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>구분</th>
                                                        <th>물량</th>
                                                        <th>금액</th>
                                                        <th>비고</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {monthDataList.map((cont,i)=>{
                                                        const date = moment(cont.de_date).format('YYYY-MM');

                                                        //물량,금액 값을 ,를 제외하고 숫자로 변환
                                                        const ton = parseFloat(cont.de_ton.replace(/,/g, ''));
                                                        const amt1 = parseInt(cont.de_amt1.replace(/,/g, ''));

                                                        //물량,금액 합계
                                                        sumTon = sumTon + ton; 
                                                        sumAmt1 = sumAmt1 + amt1; 
                                                        
                                                        return(
                                                            <tr key={i}>
                                                                <td>{date}</td>
                                                                <td className="tx_r">{cont.de_ton}</td>
                                                                <td className="tx_r">{cont.de_amt1}</td>
                                                                <td></td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr className="total_tr">
                                                        <td>계</td>
                                                        <td className="tx_r">{CF.MakeIntComma(sumTon)}</td>
                                                        <td className="tx_r">{CF.MakeIntComma(sumAmt1)}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex_end tp40">
                                            <button type="button" className="btn_pdf rm5" onClick={()=>pdfBtnClickHandler("month")}>PDF 파일 다운로드</button>
                                            <button type="button" className="btn_excel" onClick={()=>excelBtnClickHandler("month")}>엑셀 파일 다운로드</button>
                                        </div>
                                    </>
                                    :<div className="none_data tm20"><span>데이터가 없습니다.</span></div>
                                }
                            </div>
                            <div className="bp80">
                                <div className="tx_c">
                                    <p className="f_20 medium">이미지 경매 거래 현황</p>
                                    <p className="f_15 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                                </div>
                                {dayDataList.length > 0 ? 
                                    <>
                                        <div className="flex_between bp10">
                                            <p className="f_15">법인명 : 구리청과㈜</p>
                                            <p className="f_12 txt_gray">단위 : Kg, 원</p>
                                        </div>
                                        <div className="custom_table gray_table2">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'95px'}} />
                                                    <col style={{width:'75px'}} />
                                                    <col style={{width:'75px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'8%'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'7%'}} />
                                                    <col style={{width:'9%'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>판매일자</th>
                                                        <th>원표번호</th>
                                                        <th>일련번호</th>
                                                        <th>팀</th>
                                                        <th>중도매인</th>
                                                        <th>생산자</th>
                                                        <th>품종</th>
                                                        <th>상태</th>
                                                        <th>중량</th>
                                                        <th>등급</th>
                                                        <th>거래량</th>
                                                        <th>단관수</th>
                                                        <th>경락가</th>
                                                        <th>거래물량</th>
                                                        <th>거래금액</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dayDataList.map((cont,i)=>{
                                                        const date = moment(cont.de_date).format("YYYY-MM-DD");

                                                        //거래량,거래물량,거래금액 합계
                                                        sumQuantity = sumQuantity + cont.de_quantity;
                                                        sumDayTon = sumDayTon + cont.de_ton; 
                                                        sumDayAmt1 = sumDayAmt1 + cont.de_amt1; 

                                                        return(
                                                            <tr key={i}>
                                                                <td>{date}</td>
                                                                <td>{cont.de_no}</td>
                                                                <td>{cont.de_seq}</td>
                                                                <td><div className="over_txt"><span>{cont.is_dept_name}</span></div></td>
                                                                <td>{cont.de_amer_code}</td>
                                                                <td><div className="over_txt"><span>{cont.de_farm_name}</span></div></td>
                                                                <td className="txt_gray"><div className="over_txt"><span>{cont.gm_name}</span></div></td>
                                                                <td>{cont.gm_status}</td>
                                                                <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_weight)}</td>
                                                                <td className="txt_gray"><div className="over_txt"><span>{cont.de_dansu}</span></div></td>
                                                                <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_quantity)}</td>
                                                                <td>{cont.de_dansu2}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(cont.de_price1)}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(cont.de_ton)}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(cont.de_amt1)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr className="total_tr">
                                                        <td colSpan={10}>계</td>
                                                        <td className="tx_r">{CF.MakeIntComma(sumQuantity)}</td>
                                                        <td colSpan={2}></td>
                                                        <td className="tx_r">{CF.MakeIntComma(sumDayTon)}</td>
                                                        <td className="tx_r">{CF.MakeIntComma(sumDayAmt1)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex_end tp40">
                                            <button type="button" className="btn_pdf rm5" onClick={()=>pdfBtnClickHandler("day")}>PDF 파일 다운로드</button>
                                            <button type="button" className="btn_excel" onClick={()=>excelBtnClickHandler("day")}>엑셀 파일 다운로드</button>
                                        </div>
                                    </>
                                    :<div className="none_data tm20"><span>데이터가 없습니다.</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        ip_auct_date_start: basicDate || "",
                        ip_auct_date_end: basicDate || "",
                    }}
                    onSubmit={submit}
                >
                    {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="search_box">
                                <ul>
                                    <li>
                                        <p className="txt">통계일자</p>
                                        <div className="date_input">
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={ip_date_start}
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="ko"
                                                    onChange={date => setIpDateStart(date)}
                                                />
                                            </div>
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={ip_date_end}
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="ko"
                                                    onChange={date => setIpDateEnd(date)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="txt">사원코드</p>
                                        <div className="flex_between sel_btn_box">
                                            <div className="custom_select">
                                                <select 
                                                    value={code}
                                                    onChange={(e)=>{
                                                        const val = e.currentTarget.value;
                                                        setCode(val);
                                                    }}
                                                    style={{ display: 'block' }}
                                                >
                                                    <option value="">사원코드</option>
                                                    {codeList.map((cont,i)=>{
                                                        return(<option key={i} value={cont.is_code}>{`${cont.is_name}(${cont.is_code})`}</option>);
                                                    })}
                                                </select>
                                            </div>
                                            <button className="btn_s2" type="submit">조회</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className="tp20">
                    <div className="bp40">
                        <div className="tx_c">
                            <p className="f_16 medium">이미지 경매 현황</p>
                            <p className="f_14 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                        </div>
                        {monthDataList.length > 0 ? 
                            <>
                                <div className="flex_between bp10 lp10 rp10">
                                    <p className="f_12">법인명 : 구리청과㈜</p>
                                    <p className="f_12 txt_gray">단위 : Kg, 원</p>
                                </div>
                                <div className="over_scroll_box">
                                    <div className="custom_table gray_table2 w_1200px">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>구분</th>
                                                    <th>물량</th>
                                                    <th>금액</th>
                                                    <th>비고</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {monthDataList.map((cont,i)=>{
                                                    const date = moment(cont.de_date).format('YYYY-MM');

                                                    //물량,금액 값을 ,를 제외하고 숫자로 변환
                                                    const ton = parseFloat(cont.de_ton.replace(/,/g, ''));
                                                    const amt1 = parseInt(cont.de_amt1.replace(/,/g, ''));

                                                    //물량,금액 합계
                                                    sumTon = sumTon + ton; 
                                                    sumAmt1 = sumAmt1 + amt1; 
                                                    
                                                    return(
                                                        <tr key={i}>
                                                            <td>{date}</td>
                                                            <td className="tx_r">{cont.de_ton}</td>
                                                            <td className="tx_r">{cont.de_amt1}</td>
                                                            <td></td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr className="total_tr">
                                                    <td>계</td>
                                                    <td className="tx_r">{CF.MakeIntComma(sumTon)}</td>
                                                    <td className="tx_r">{CF.MakeIntComma(sumAmt1)}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                            :<div className="none_data tm20"><span>데이터가 없습니다.</span></div>
                        }
                    </div>
                    <div className="bp80">
                        <div className="tx_c">
                            <p className="f_16 medium">이미지 경매 거래 현황</p>
                            <p className="f_14 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                        </div>
                        {dayDataList.length > 0 ? 
                            <>
                                <div className="flex_between bp10 lp10 rp10">
                                    <p className="f_12">법인명 : 구리청과㈜</p>
                                    <p className="f_12 txt_gray">단위 : Kg, 원</p>
                                </div>
                                <div className="over_scroll_box">
                                    <div className="custom_table gray_table2 w_1200px">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'95px'}} />
                                                <col style={{width:'75px'}} />
                                                <col style={{width:'75px'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'8%'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'7%'}} />
                                                <col style={{width:'9%'}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>판매일자</th>
                                                    <th>원표번호</th>
                                                    <th>일련번호</th>
                                                    <th>팀</th>
                                                    <th>중도매인</th>
                                                    <th>생산자</th>
                                                    <th>품종</th>
                                                    <th>상태</th>
                                                    <th>중량</th>
                                                    <th>등급</th>
                                                    <th>거래량</th>
                                                    <th>단관수</th>
                                                    <th>경락가</th>
                                                    <th>거래물량</th>
                                                    <th>거래금액</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dayDataList.map((cont,i)=>{
                                                    const date = moment(cont.de_date).format("YYYY-MM-DD");

                                                    //거래량,거래물량,거래금액 합계
                                                    sumQuantity = sumQuantity + cont.de_quantity;
                                                    sumDayTon = sumDayTon + cont.de_ton; 
                                                    sumDayAmt1 = sumDayAmt1 + cont.de_amt1; 

                                                    return(
                                                        <tr key={i}>
                                                            <td>{date}</td>
                                                            <td>{cont.de_no}</td>
                                                            <td>{cont.de_seq}</td>
                                                            <td><div className="over_txt"><span>{cont.is_dept_name}</span></div></td>
                                                            <td>{cont.de_amer_code}</td>
                                                            <td><div className="over_txt"><span>{cont.de_farm_name}</span></div></td>
                                                            <td className="txt_gray"><div className="over_txt"><span>{cont.gm_name}</span></div></td>
                                                            <td>{cont.gm_status}</td>
                                                            <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_weight)}</td>
                                                            <td className="txt_gray"><div className="over_txt"><span>{cont.de_dansu}</span></div></td>
                                                            <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_quantity)}</td>
                                                            <td>{cont.de_dansu2}</td>
                                                            <td className="tx_r">{CF.MakeIntComma(cont.de_price1)}</td>
                                                            <td className="tx_r">{CF.MakeIntComma(cont.de_ton)}</td>
                                                            <td className="tx_r">{CF.MakeIntComma(cont.de_amt1)}</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr className="total_tr">
                                                    <td colSpan={10}>계</td>
                                                    <td className="tx_r">{CF.MakeIntComma(sumQuantity)}</td>
                                                    <td colSpan={2}></td>
                                                    <td className="tx_r">{CF.MakeIntComma(sumDayTon)}</td>
                                                    <td className="tx_r">{CF.MakeIntComma(sumDayAmt1)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                            :<div className="none_data tm20"><span>데이터가 없습니다.</span></div>
                        }
                    </div>
                </div>
            </div>
        }
    </>);
};

export default Statistics;