import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { messageSetPop, tableProducePop, auctionViewPop } from "../../../store/popupSlice";
import logo from "../../../images/logo.svg";

const Header = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [pageOut, setPageOut] = useState(false);

    useEffect(()=>{
        setPageOut(pageOut);
    },[pageOut]);

    useEffect(()=>{
        let path = location.pathname;
        if(path.includes('/auctioneer/online-auction/')){
            setPageOut(true);
        }else{
            setPageOut(false);
        }
    },[location]);

    //경매사일때 온라인경매상세 페이지에서 다른페이지로 이동시 컨펌창
    const pageMoveHandler = (url) => {
        if(pageOut){
            if(window.confirm('경매페이지를 나가시겠습니까?')){
                navigate(url);
                window.location.reload();

                //온라인경매상세 localStorage 삭제
                localStorage.removeItem("auctStartData");
                localStorage.removeItem("auctProData");
                localStorage.removeItem("clientList");
                localStorage.removeItem("subList");
            }
        }else{
            navigate(url);
            window.location.reload();
        }
    };

    return(
        <header className="header">
            <div className="cont">
                <Link to="/" className="logo"><img src={logo} alt="로고" /></Link>
                {user.isLogin ?
                    <>
                        {/* 출하주 메뉴 */}
                        {user.kind === 1 && (<ul className="menu_ul">
                            <li>
                                <Link to="/shipper/invoice-register" className="txt" >송품장 등록</Link>
                            </li>
                            <li>
                                <Link to="/shipper/invoice-check" className="txt" >송품장 조회</Link>
                            </li>
                            <li>
                                <Link to="/shipper/online-check" className="txt" >온라인거래결과 조회</Link>
                            </li>
                            <li>
                                <button onClick={()=>{dispatch(auctionViewPop(true))}} className="txt">실시간 경매</button>
                            </li>
                        </ul>)}
                        
                        {/* 경매사 메뉴 */}
                        {user.kind === 7 && (<ul className="menu_ul">
                            <li>
                                <Link to="/auctioneer/receiving" onClick={(e)=>{
                                    if(pageOut){
                                        e.preventDefault();
                                        pageMoveHandler("/auctioneer/receiving");
                                    }
                                }} className="txt" >입하작업</Link>
                            </li>
                            <li>
                                <Link to="/auctioneer/online-auction" onClick={(e)=>{
                                    if(pageOut){
                                        e.preventDefault();
                                        pageMoveHandler("/auctioneer/online-auction");
                                    }
                                }} className="txt" >온라인경매</Link>
                            </li>
                            <li>
                                <Link to="/auctioneer/bid-result" onClick={(e)=>{
                                    if(pageOut){
                                        e.preventDefault();
                                        pageMoveHandler("/auctioneer/bid-result");
                                    }
                                }} className="txt" >경매/입찰 결과</Link>
                            </li>
                            <li>
                                <Link to="/auctioneer/statistics" onClick={(e)=>{
                                    if(pageOut){
                                        e.preventDefault();
                                        pageMoveHandler("/auctioneer/statistics");
                                    }
                                }} className="txt" >통계</Link>
                            </li>
                            {/* <li>
                                <button onClick={()=>{dispatch(tableProducePop(true))}} className="txt">판매 원표생성</button>
                            </li> */}
                            <li>
                                <button onClick={()=>{dispatch(messageSetPop(true))}} className="txt">설정</button>
                            </li>
                            <li>
                                <button onClick={()=>{dispatch(auctionViewPop(true))}} className="txt">실시간 경매</button>
                            </li>
                        </ul>)}

                        {/* 중도매인 메뉴 */}
                        {user.kind === 3 && (<ul className="menu_ul">
                            <li>
                                <Link to="/merchant/shipment-products" className="txt" >출하물품 조회</Link>
                            </li>
                            <li>
                                <Link to="/merchant/online-bid" className="txt" >온라인입찰</Link>
                            </li>
                            <li>
                                <Link to="/merchant/auction" className="txt" >온라인 경매</Link>
                            </li>
                            <li>
                                <Link to="/merchant/bid-products" className="txt" >낙찰물품 조회</Link>
                            </li>
                            <li>
                                <button onClick={()=>{dispatch(auctionViewPop(true))}} className="txt">실시간 경매</button>
                            </li>
                        </ul>)}
                    </>
                    :<ul className="menu_ul">
                        <li>
                            <button onClick={()=>{dispatch(auctionViewPop(true))}} className="txt">실시간 경매</button>
                        </li>
                    </ul>
                }
            </div>
        </header>
    );
};

export default Header;