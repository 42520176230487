import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser, isMobile } from "react-device-detect";
import moment from "moment";
import axios from "axios";
import { NumericFormat, NumberFormatBase } from "react-number-format";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import { deliveryAddPop, confirmPop, deliveryRefresh, deliveryInfo } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";
import DaumPost from "../DaumPost";

const DeliveryAddPop = () => {
    registerLocale('ko', ko);
    const p_deli = enum_api_uri.p_deli;
    const p_deli_edit = enum_api_uri.p_deli_edit;
    const m_bank = enum_api_uri.m_bank;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);
    const [deliInfo, setDeliInfo] = useState({});
    const [errorCost, setErrorCost] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorTel, setErrorTel] = useState(false);
    const [errorCar, setErrorCar] = useState(false);
    const [errorBank, setErrorBank] = useState(false);
    const [errorBank2, setErrorBank2] = useState(false);
    const [bankList, setBankList] = useState([]);

    const closePopHandler = () => {
        dispatch(deliveryAddPop({deliveryAddPop:false}));
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
            setSaveConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setDeliInfo(deliInfo);
    },[deliInfo]);

    useEffect(()=>{
        setBankList(bankList);
    },[bankList]);

    useEffect(()=>{
        setErrorCost(errorCost);
    },[errorCost]);

    useEffect(()=>{
        setErrorName(errorName);
    },[errorName]);

    useEffect(()=>{
        setErrorTel(errorTel);
    },[errorTel]);

    useEffect(()=>{
        setErrorCar(errorCar);
    },[errorCar]);

    useEffect(()=>{
        setErrorBank(errorBank);
    },[errorBank]);

    useEffect(()=>{
        setErrorBank2(errorBank2);
    },[errorBank2]);

    // 맨처음 은행목록 가져오기
    useEffect(()=>{
        axios.get(`${m_bank}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                setBankList(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);


    //연락처input 포맷
    function MakePhoneForm(origin) {
        // 입력된 문자열에서 문자 제거
        const digitsOnly = origin.replace(/\D+/g, '');
      
        // 전화번호 정규식 (앞자리: 02 또는 3자리 숫자, 중간 자리: 3~4자리 숫자, 마지막 자리: 4자리 숫자)
        const phoneNumberRegex = /^(02|\d{3})(\d{3,4})(\d{4})$/;
      
        // 정규식을 사용해 전화번호 형식에 맞게 변경
        const formatted = digitsOnly.replace(phoneNumberRegex, '$1-$2-$3');
      
        // 변경된 전화번호 반환
        return formatted;
    }

    //운임정보 저장
    const saveClickHandler = (values) => {
        if(CF.RemoveIntComma(values.de_cost2) === 0 || values.de_cost2.length === 0){
            setErrorCost(true);
        }if(values.de_deli_nm.length === 0){
            setErrorName(true);
        }if(values.de_deli_tel.length === 0){
            setErrorTel(true);
        }if(values.de_deli_car_num.length === 0){
            setErrorCar(true);
        }if(values.de_deli_bank_nm.length === 0){
            setErrorBank(true);
        }if(values.de_deli_bank_num.length === 0){
            setErrorBank2(true);
        }if(CF.RemoveIntComma(values.de_cost2) > 0 && 
            values.de_deli_nm.length > 0 && 
            values.de_deli_tel.length > 0 && 
            values.de_deli_car_num.length > 0 && 
            values.de_deli_bank_nm.length > 0 && 
            values.de_deli_bank_num.length > 0 ){

            setErrorCost(false);
            setErrorName(false);
            setErrorTel(false);
            setErrorCar(false);
            setErrorBank(false);
            setErrorBank2(false);

            setDeliInfo({...values});
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'운임정보를 저장하시겠습니까?',
                confirmPopBtn:2,
            }));
            setSaveConfirm(true);
        }
    };

    //운임정보 저장하기
    const saveHandler = () => {
        let tel = deliInfo.de_deli_tel.replace(/[^0-9]/g, '');
        let body = {
            deli_cost2: CF.RemoveIntComma(deliInfo.de_cost2),
            deli_nm: deliInfo.de_deli_nm,
            deli_tel: tel,
            deli_car_num: deliInfo.de_deli_car_num,
            deli_bank_nm: deliInfo.de_deli_bank_nm,
            deli_bank_num: deliInfo.de_deli_bank_num,
        };
        // 수정일때
        if(popup.deliveryEdit){
            axios.put(`${p_deli_edit.replace(":idx",popup.deliveryInfo.idx)}`, body,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(deliveryRefresh(true));
                    dispatch(deliveryAddPop({deliveryAddPop:false, deliveryEdit:false}));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //새로 등록일때
        else{
            axios.post(`${p_deli}`, body,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(deliveryRefresh(true));
                    dispatch(deliveryAddPop({deliveryAddPop:false, deliveryEdit:false}));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="pop_cont pop_cont3">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">운임정보 등록</p>
                    </div>
                    <div className="custom_table t_border2">
                        <Formik
                            initialValues={{
                                de_cost2: popup.deliveryInfo.deli_cost2 || "",
                                de_deli_nm: CF.objValueTrim(popup.deliveryInfo.deli_nm) || "",
                                de_deli_tel: CF.objValueTrim(popup.deliveryInfo.deli_tel) ||  "",
                                de_deli_car_num: CF.objValueTrim(popup.deliveryInfo.deli_car_num) ||  "",
                                de_deli_bank_nm: CF.objValueTrim(popup.deliveryInfo.deli_bank_nm) ||  "",
                                de_deli_bank_num: CF.objValueTrim(popup.deliveryInfo.deli_bank_num) ||  "",
                            }}
                            validateOnMount={true}
                            enableReinitialize={true}
                        >
                            {({values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="custom_table wide_table input_table">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '13%' }} />
                                                <col style={{ width: '20.333%' }} />
                                                <col style={{ width: '13%' }} />
                                                <col style={{ width: '20.333%' }} />
                                                <col style={{ width: '13%' }} />
                                                <col style={{ width: '20.333%' }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>운송비</th>
                                                    <td>
                                                        <div className="custom_input h_40 txt_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" name="de_cost2" value={values.de_cost2}  onChange={handleChange}
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        saveClickHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                            <span className="txt">원</span>
                                                        </div>
                                                        {errorCost &&
                                                            <div className="alert_txt f_12 tx_l">운송비를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                    <th>기사명<span className="f_13">(예금주)</span></th>
                                                    <td>
                                                        <div className="custom_input h_40 tx_l">
                                                            <input type="text" name="de_deli_nm" value={values.de_deli_nm} onChange={handleChange} 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        saveClickHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {errorName &&
                                                            <div className="alert_txt f_12 tx_l">기사명을 입력해주세요.</div>
                                                        }
                                                    </td>
                                                    <th>전화번호</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <NumberFormatBase name="de_deli_tel" maxLength="13" value={values.de_deli_tel} onChange={handleChange} format={MakePhoneForm} 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        saveClickHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {errorTel &&
                                                            <div className="alert_txt f_12 tx_l">전화번호를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>차량번호</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <input type="text" name="de_deli_car_num" value={values.de_deli_car_num} onChange={handleChange} 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        saveClickHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {errorCar &&
                                                            <div className="alert_txt f_12 tx_l">차량번호를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                    <th>운임 송금은행</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select
                                                                name="de_deli_bank_nm"
                                                                value={values.de_deli_bank_nm}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="" hidden>은행선택</option>
                                                                {bankList && bankList.map((bank,i)=>{
                                                                    bank = CF.trimObjectValues(bank);
                                                                    return(
                                                                        <option value={bank.et_name} key={i}>{bank.et_name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        {errorBank &&
                                                            <div className="alert_txt f_12 tx_l">송금은행을 선택해주세요.</div>
                                                        }
                                                    </td>
                                                    <th>운임계좌</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <input type="text" name="de_deli_bank_num" value={values.de_deli_bank_num} onChange={handleChange} 
                                                                onKeyDown={(e)=>{
                                                                    if(e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        saveClickHandler(values);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {errorBank2 &&
                                                            <div className="alert_txt f_12 tx_l">계좌를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex_center tp30 bp10">
                                        <button className="btn_type" type="button" onClick={()=>{
                                            saveClickHandler(values);
                                        }}>저장</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">운임정보 등록</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="custom_table t_border2">
                        <Formik
                            initialValues={{
                                de_cost2: popup.deliveryInfo.deli_cost2 || "",
                                de_deli_nm: CF.objValueTrim(popup.deliveryInfo.deli_nm) || "",
                                de_deli_tel: CF.objValueTrim(popup.deliveryInfo.deli_tel) ||  "",
                                de_deli_car_num: CF.objValueTrim(popup.deliveryInfo.deli_car_num) ||  "",
                                de_deli_bank_nm: CF.objValueTrim(popup.deliveryInfo.deli_bank_nm) ||  "",
                                de_deli_bank_num: CF.objValueTrim(popup.deliveryInfo.deli_bank_num) ||  "",
                            }}
                            validateOnMount={true}
                            enableReinitialize={true}
                        >
                            {({values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="custom_table wide_table input_table">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '100px' }} />
                                                <col style={{ width: 'auto'}} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>운송비</th>
                                                    <td>
                                                        <div className="custom_input h_40 txt_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" name="de_cost2" value={values.de_cost2}  onChange={handleChange}/>
                                                            <span className="txt">원</span>
                                                        </div>
                                                        {errorCost &&
                                                            <div className="alert_txt f_12 tx_l">운송비를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>기사명<span className="f_13">(예금주)</span></th>
                                                    <td>
                                                        <div className="custom_input h_40 tx_l">
                                                            <input type="text" name="de_deli_nm" value={values.de_deli_nm} onChange={handleChange} />
                                                        </div>
                                                        {errorName &&
                                                            <div className="alert_txt f_12 tx_l">기사명을 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>전화번호</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <NumberFormatBase name="de_deli_tel" maxLength="13" value={values.de_deli_tel} onChange={handleChange} format={MakePhoneForm} />
                                                        </div>
                                                        {errorTel &&
                                                            <div className="alert_txt f_12 tx_l">전화번호를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>차량번호</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <input type="text" name="de_deli_car_num" value={values.de_deli_car_num} onChange={handleChange} />
                                                        </div>
                                                        {errorCar &&
                                                            <div className="alert_txt f_12 tx_l">차량번호를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>운임 송금은행</th>
                                                    <td>
                                                        <div className="custom_select">
                                                            <select
                                                                name="de_deli_bank_nm"
                                                                value={values.de_deli_bank_nm}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <option value="" hidden>은행선택</option>
                                                                {bankList && bankList.map((bank,i)=>{
                                                                    bank = CF.trimObjectValues(bank);
                                                                    return(
                                                                        <option value={bank.et_name} key={i}>{bank.et_name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        {errorBank &&
                                                            <div className="alert_txt f_12 tx_l">송금은행을 선택해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>운임계좌</th>
                                                    <td>
                                                        <div className="custom_input h_40">
                                                            <input type="text" name="de_deli_bank_num" value={values.de_deli_bank_num} onChange={handleChange} />
                                                        </div>
                                                        {errorBank2 &&
                                                            <div className="alert_txt f_12 tx_l">계좌를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex_center tp30 bp10">
                                        <button className="btn_type" type="button" onClick={()=>{
                                            saveClickHandler(values);
                                        }}>저장</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        }

        {/* 운임정보저장 confirm팝업 */}
        {saveConfirm && <ConfirmPop onClickHandler={saveHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default DeliveryAddPop;