import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closePop, mediaPop, mediaAllPop } from "../../../../store/popupSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {enum_api_uri, debounce, debounce2} from "../../../../config/enum";
import InputDatepicker from "../InputDatepicker";
import NewProductPop from "./NewProductPop";
import DealHistoryPop from "./DealHistoryPop";
import PrevProductPop from "./PrevProductPop";
import ProDetailPop from "./ProDetailPop";
import DefaultInfoModifyPop from "./DefaultInfoModifyPop";
import ProDivisionPop from "./ProDivisionPop";
import MessageSetPop from "./MessageSetPop";
import MessageSetAddPop from "./MessageSetAddPop";
import OrderingPop from "./OrderingPop";
import TableProducePop from "./TableProducePop";
import AuctionProDetailPop from "../../../mo/component/popup/AuctionProDetailPop";
import BidListPop from "../../../mo/component/popup/BidListPop";
import ResultDetailPop from "./ResultDetailPop";
import ReasonPop from "./ReasonPop";
import RestartPop from "./RestartPop";
import AuctionViewPop from "./AuctionViewPop";
import AddressPop from "./AddressPop";
import AddressAddPop from "./AddressAddPop";
import DeliveryPop from "./DeliveryPop";
import DeliveryAddPop from "./DeliveryAddPop";
import LoadingPop from "./LoadingPop";
import { isBrowser, isMobile } from "react-device-detect";

const Popup = (props) => {   
    const [txtPop, setTxtPop] = useState(false);
    const user = useSelector((state) => state.user);
    const popup = useSelector((state) => state.popup);
    const dispatch = useDispatch();
    
    const closePopHandler = () => {
        dispatch(closePop());
    }

    return(
        <Fragment>
        
        {/* 출하자 -----------------------------------------------------------------*/}
            {/* 출하자 | 거래내역 팝업 */}
            {popup.dealHistoryPop && 
                <DealHistoryPop />
            }

            {/* 출하자 | 송품장작성 - 새로운상품추가 팝업 */}
            {popup.newProductPop && 
                <NewProductPop />
            }

            {/* 출하자 | 송품장작성 - 필수입력안내 팝업 */}
            {/* <div className="pop_wrap">
                <div className="dim"></div>
                <div className="pop_cont pop_cont2">
                    <button className="pop_close" onClick={props.onHidePopup}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">안내</p>
                    </div>
                    <div className="t_border tx_c">
                        <p className="f_17 medium tp20"><span className="txt_sky">* </span>은 필수 입력항목 입니다.</p>
                        <p className="f_13 txt_gray">필수 입력사항을 채워주세요.</p>
                        <div className="flex_center tp30 bp10">
                            <button className="btn_type4">확인</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* 출하자 | 송품장작성 - 이전출하상품가져오기 팝업 */}
            {popup.prevProductPop &&
                <PrevProductPop />
            }

            {/* 출하자 | 송품장조회 - 물품상세 팝업 */}
            {popup.proDetailPop && 
                <ProDetailPop />
            }

            {/* 출하자 | 온라인거래결과조회 상세 - 운임정보팝업 */}
            {popup.deliveryPop &&
                <DeliveryPop />
            }

            {/* 출하자 | 온라인거래결과조회 상세 - 운임정보 추가팝업 */}
            {popup.deliveryAddPop &&
                <DeliveryAddPop />
            }

        {/* 중도매인 -----------------------------------------------------------------*/}
            {/* 중도매인 | 출하물품조회 상세 - 입찰 팝업 */}
            {/* <div className="pop_wrap">
                <div className="dim"></div>
                <div className="pop_cont pop_cont2">
                    <button className="pop_close">닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">안내</p>
                    </div>
                    <div className="t_border tx_c">
                        <p className="f_17 medium tp20">현재 단가로 입찰 진행 하시겠습니까?</p>
                        <div className="flex_center tp40 bp10">
                            <button className="btn_type rm10">확인</button>
                            <button className="btn_type4">취소</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* 중도매인 | 낙찰물품조회 - 인수등록 팝업 */}
            {/* <div className="pop_wrap">
                <div className="dim"></div>
                <div className="pop_cont pop_cont2">
                    <button className="pop_close">닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">안내</p>
                    </div>
                    <div className="t_border tx_c">
                        <p className="f_17 medium tp20">인수등록을 하시겠습니까?</p>
                        <div className="flex_center tp40 bp10">
                            <button className="btn_type rm10">확인</button>
                            <button className="btn_type4">취소</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* 중도매인 | 온라인경매 상세 - 물품상세팝업 (모바일일때만) */}
            {popup.auctionProDetailPop &&
                <AuctionProDetailPop />
            }

            {/* 중도매인 | 온라인경매 상세 - 응찰목록팝업 (모바일일때만) */}
            {popup.bidListPop &&
                <BidListPop />
            }

            {/* 중도매인 | 낙찰물품 상세 - 배송지팝업 */}
            {popup.addressPop &&
                <AddressPop />
            }

            {/* 중도매인 | 낙찰물품 상세 - 배송지추가팝업 */}
            {popup.addressAddPop &&
                <AddressAddPop />
            }

        {/* 경매사 -----------------------------------------------------------------*/}
            {/* 경매사 | 입하작업 - 기본정보수정하기 팝업 */}
            {popup.defaultInfoModifyPop &&
                <DefaultInfoModifyPop />
            }

            {/* 경매사 | 입하작업 - 경매순서지정 팝업 */}
            {popup.orderingPop && <OrderingPop />}

            {/* 경매사 | 경매완료(원표생성) 팝업 */}
            {popup.tableProducePop && <TableProducePop />}
            
            {/* 경매사 | 주요메세지설정 팝업 */}
            {popup.messageSetPop && <MessageSetPop />}

            {/* 경매사 | 주요메세지설정 -> 등록 팝업 */}
            {popup.messageSetAddPop && <MessageSetAddPop />}

            {/* 경매사 | 입하작업 - 물품분할설정 팝업 */}
            {popup.proDivisionPop && <ProDivisionPop />}

            {/* 경매사 | 경매/입찰결과 - 물품상세 팝업 */}
            {popup.resultDetailPop && <ResultDetailPop />}

            {/* 경매사 | 경매입찰결과 - 물품상세 팝업 - 불낙처리시 사유작성팝업 */}
            {popup.reasonPop && <ReasonPop />}

            {/* 경매사 | 온라인경매 - 낙찰,유찰된물품 재경매할때 사유작성팝업 */}
            {popup.restartPop && <RestartPop />}
            

        {/* 공통 -----------------------------------------------------------------*/}
            {/* 사진, 동영상 다같이나오는 팝업 */}
            {popup.mediaAllPop && (
                <div className="pop_wrap">
                    <div className="dim" onClick={()=>{dispatch(mediaAllPop({mediaAllPop:null}))}}></div>
                    <div className="pop_cont">
                        <button className="pop_close" onClick={()=>{dispatch(mediaAllPop({mediaAllPop:null}))}}>닫기버튼</button>
                        <div className="pop_tit">
                            <p className="tit">사진/동영상</p>
                        </div>
                        <ul className="img_video_box flex_between">
                            <li>
                                {popup.mediaAllImg1 &&
                                    <div className="thumb_box" onClick={()=>{
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:popup.mediaAllImg1}))
                                    }}><img src={popup.mediaAllImg1} alt="이미지" /></div>
                                }
                            </li>
                            <li>
                                {popup.mediaAllImg2 &&
                                    <div className="thumb_box" onClick={()=>{
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진2',mediaSrc:popup.mediaAllImg2}))
                                    }}><img src={popup.mediaAllImg2} alt="이미지" /></div>
                                }
                            </li>
                            <li>
                                {popup.mediaAllVideo && 
                                    <div className="thumb_box" onClick={()=>{
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:popup.mediaAllVideo}))
                                    }}><video src={popup.mediaAllVideo} /></div>
                                }
                            </li>
                        </ul>
                        <div className="flex_center tp20">
                            <button className="btn_type4" onClick={()=>{dispatch(mediaAllPop({mediaAllPop:null}))}}>닫기</button>
                        </div>
                    </div>
                </div>
            )}

            {/* 사진, 동영상 팝업 */}
            {popup.mediaPop && <>
                {isBrowser &&
                    <div className="pop_wrap">
                        <div className="dim" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}></div>
                        <div className="pop_cont">
                            <button className="pop_close" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}>닫기버튼</button>
                            <div className="pop_tit">
                                <p className="tit">{popup.mediaTit}</p>
                            </div>
                            <div className="scroll_wrap tx_c">
                                <div className="img_box">
                                    {popup.mediaTit === '동영상' ? (
                                        <video src={popup.mediaSrc} autoPlay muted controls />
                                    ) : <img src={popup.mediaSrc} alt="이미지" />
                                    }
                                </div>
                                <div className="flex_center tp20">
                                    <button className="btn_type4" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}>닫기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {isMobile &&
                    <div className="pop_wrap">
                        <div className="dim" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}></div>
                        <div className="mo_pop_cont small_pop">
                            <div className="mo_pop_tit">
                                <p className="tit">{popup.mediaTit}</p>
                                <button className="pop_close" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}>닫기버튼</button>
                            </div>
                            <div className="scroll_wrap tx_c">
                                <div className="img_box tp10">
                                    {popup.mediaTit === '동영상' ? (
                                        <video src={popup.mediaSrc} autoPlay muted controls />
                                    ) : <img src={popup.mediaSrc} alt="이미지" />
                                    }
                                </div>
                                <div className="tp10">
                                    <button className="btn_type4 w_100" onClick={()=>{dispatch(mediaPop({mediaPop:false}))}}>닫기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>}

            {/* 실시간경매현황 팝업 */}
            {popup.auctionViewPop && <AuctionViewPop />}

            {/* 로딩 팝업 */}
            {popup.loadingPop && <LoadingPop />}

        </Fragment>
    );
};

export default Popup;