import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menu_p1 from "../../../images/mo/ic_mo_menu_p01.svg";
import menu_p2 from "../../../images/mo/ic_mo_menu_p02.svg";
import menu_p3 from "../../../images/mo/ic_mo_menu_p03.svg";
import menu_p4 from "../../../images/mo/ic_mo_menu_p04.svg";
import menu_a1 from "../../../images/mo/ic_mo_menu_a01.svg";
import menu_a2 from "../../../images/mo/ic_mo_menu_a02.svg";
import menu_a3 from "../../../images/mo/ic_mo_menu_a03.svg";
import menu_m1 from "../../../images/mo/ic_mo_menu_m01.svg";
import menu_m2 from "../../../images/mo/ic_mo_menu_m02.svg";
import menu_m3 from "../../../images/mo/ic_mo_menu_m03.svg";
import menu_m4 from "../../../images/mo/ic_mo_menu_m04.svg";

const MoMenu = () => {
    const user = useSelector((state) => state.user);

    return(<>
        {user.isLogin && <>
            {/* 출하주 메뉴 */}
            {user.kind === 1 &&
                <ul className="menu_ul">
                    <li>
                        <Link to="/shipper/invoice-register">
                            <div className="img">
                                <img src={menu_p1} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>송품장 등록</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/shipper/invoice-check">
                            <div className="img">
                                <img src={menu_p2} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>송품장 조회</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/shipper/online-check">
                            <div className="img">
                                <img src={menu_p3} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>온라인 <br/>거래결과 조회</span>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link>
                            <div className="img">
                                <img src={menu_p4} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>실시간 경매보기</span>
                            </div>
                        </Link>
                    </li> */}
                </ul>
            }

            {/* 경매사 메뉴 */}
            {user.kind === 7 &&
                <ul className="menu_ul">
                    <li>
                        <Link to="/auctioneer/mo/receiving">
                            <div className="img">
                                <img src={menu_a1} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>입하작업</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/auctioneer/bid-result" >
                            <div className="img">
                                <img src={menu_a2} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>경매/입찰 결과조회</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/auctioneer/statistics" >
                            <div className="img">
                                <img src={menu_a3} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>통계</span>
                            </div>
                        </Link>
                    </li>
                </ul>
            }

            {/* 중도매인 메뉴 */}
            {user.kind === 3 &&
                <ul className="menu_ul">
                    <li>
                        <Link to="/merchant/shipment-products" >
                            <div className="img">
                                <img src={menu_m1} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>출하물품 조회</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/merchant/online-bid" >
                            <div className="img">
                                <img src={menu_m2} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>온라인 입찰</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/merchant/auction" >
                            <div className="img">
                                <img src={menu_m3} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>온라인 경매</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/merchant/bid-products" >
                            <div className="img">
                                <img src={menu_m4} alt="메뉴 아이콘"></img>
                            </div>
                            <div className="txt flex_center">
                                <span>낙찰물품 조회</span>
                            </div>
                        </Link>
                    </li>
                </ul>
            }
        </>}
    </>);
};

export default MoMenu;