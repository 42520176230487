import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { enum_api_uri, debounce, debounce2 } from "../../../../config/enum";
import { closePop, prevProductPop, prevProductAdd, confirmPop } from "../../../../store/popupSlice";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "../Function";
import InputDatepicker from "../InputDatepicker";
import moment from "moment/moment";
import ConfirmPop from "./ConfirmPop";

const PrevProductPop = () => {
    const [farmNameInput, setFarmNameInput] = useState('');
    const [farmsDrop, setFarmsDrop] = useState(false);
    const [farms, setFarms] = useState([]);
    const [farmCodeData, setFarmCodeData] = useState('');
    const [farmNameInputClick, setFarmNameInputClick] =  useState(false);
    const [gmCodeInput, setGmCodeInput] = useState('');
    const [gmNameInput, setGmNameInput] = useState('');
    const [gmNameDrop, setGmNameDrop] = useState(false);
    const [gmStmdName, setGmStmdName] =  useState([]);
    const [gmName, setGmName] =  useState([]);
    const [gmNameInputClick, setGmNameInputClick] = useState(false);
    const [alert, setAlert] = useState(false);
    const [list, setList] = useState([]);
    const [addList, setAddList] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const c_search_farms= enum_api_uri.c_search_farms;
    const c_search_gm_stmd = enum_api_uri.c_search_gm_stmd;
    const c_search_gm = enum_api_uri.c_search_gm;
    const p_list_product_prev = enum_api_uri.p_list_product_prev;
    const p_list_product_prev_copy = enum_api_uri.p_list_product_prev_copy;
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [deDate, setDeDate] = useState([]);
    const [deNoOnline, setDeNoOnline] = useState([]);
    const [deSeq, setDeSeq] = useState([]);
    const [deSeq2, setDeSeq2] = useState([]);
    const [dropLoading, setDropLoading] = useState(false);
    const [dateOk, setDateOk] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);

    //검색날짜 기본설정 1개월전 ~ 오늘 // 최대검색기간 1년
    const basicStartDate = new Date().setMonth(new Date().getMonth() - 1);
    const [dStartDate, setDStartDate] = useState(basicStartDate);
    const [dEndDate, setDEndDate] = useState(new Date());
    const maxEndDate = new Date(dStartDate).setFullYear(new Date(dStartDate).getFullYear() + 1);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);
    

    const closePopHandler = () => {
        dispatch(closePop());
    }

    useEffect(()=>{
        setDateOk(dateOk);
    },[dateOk]);

    useEffect(()=>{
        setDropLoading(dropLoading);
    },[dropLoading]);

    useEffect(() => {
        setFarmNameInputClick(farmNameInputClick);
    },[farmNameInputClick]);

    const dStartChange = (date) => {
        setDStartDate(date);
        setAlert(false);
    };

    const dEndChange = (date) => {
        setDEndDate(date);
        setAlert(false);
    };

    useEffect(()=>{
        if(dStartDate != null && dEndDate != null){
            setDateOk(false);
        }else{
            setDateOk(true);
        }
    },[dStartDate,dEndDate]);

    //생산자 검색
    useEffect(() => {
        setFarmNameInput(farmNameInput);
        if(farmNameInputClick){
            if(farmNameInput.length > 0){
                setDropLoading(true);
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_farms}?ft_farm=${farmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setFarms(res.data);
                            setDropLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setFarmsDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setFarmsDrop(false);
            }
        }
    },[farmNameInput]);

    // 출하상품 검색
    const searchHandler = () => {
        let startDate = moment(dStartDate).format("YYYYMMDD");
        let endDate = moment(dEndDate).format("YYYYMMDD");
        if(farmNameInput.length === 0 && dateOk){
            setAlert(true);
        }else{
            setAlert(false);
            axios.get(`${p_list_product_prev}?de_farm_code=${farmCodeData}&de_gods_code=${gmCodeInput}&de_date_online_start=${startDate}&de_date_online_end=${endDate}`,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    let data = res.data
                    let state = [...data];
                    setList(state);
                    console.log(state);
                }
            })
            .catch((error) => {
                const err_msg = error.response.data.msg || "알수없는 에러가 발생했습니다.";
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    //맨처음 날짜만으로 바로 검색하기
    useEffect(()=>{
        let startDate = moment(dStartDate).format("YYYYMMDD");
        let endDate = moment(dEndDate).format("YYYYMMDD");
        if(farmNameInput.length === 0 && dateOk){
            setAlert(true);
        }else{
            setAlert(false);
            axios.get(`${p_list_product_prev}?de_farm_code=&de_gods_code=&de_date_online_start=${startDate}&de_date_online_end=${endDate}`,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    let data = res.data
                    let state = [...data];
                    setList(state);
                    console.log(state);
                }
            })
            .catch((error) => {
                const err_msg = error.response.data.msg || "알수없는 에러가 발생했습니다.";
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    },[]);

    // 선택물품 추가
    const addHandler = () => {
        let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
        let ipDate = getData.ip_date_online;
        let ipNoOnline = getData.ip_no_online;
        let body ={
            de_date_online: deDate,
            de_ip_no: deNoOnline,
            de_seq: deSeq,
            de_seq2: deSeq2,
        };
        axios.post(`${p_list_product_prev_copy.replace(':ip_date_online',ipDate).replace(':ip_no_online',ipNoOnline)}`,body,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                closePopHandler();
                dispatch(prevProductAdd(true));
                setTimeout(() => {
                    dispatch(prevProductAdd(false));
                    dispatch(prevProductPop(false));
                },100);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const checkHandler = (checked, value) => {
        // console.log(checked, value);
        if (checked) {
            setCheckedItems([...checkedItems, value]);
        } else if (!checked && checkedItems.includes(value)) {
            setCheckedItems(checkedItems.filter((el) => el !== value));
        }
    }

    // 선택한물품 정보
    useEffect(() => {
        setCheckedItems(checkedItems);
        let de_date_online = [];
        let de_ip_no = [];
        let de_seq = [];
        let de_seq2 = [];
        if(checkedItems.length > 0){
            checkedItems.map(data => {
                const data_arr = data.split(",");
                de_date_online.push(data_arr[0]);
                de_ip_no.push(data_arr[1]);
                de_seq.push(data_arr[2]);
                de_seq2.push(data_arr[3]);
                setDeDate(de_date_online);
                setDeNoOnline(de_ip_no);
                setDeSeq(de_seq);
                setDeSeq2(de_seq2);
            })
        }else{
            setDeDate([]);
            setDeNoOnline([]);
            setDeSeq([]);
            setDeSeq2([]);
        }
    },[checkedItems]);

    

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">이전 출하상품가져오기</p>
                    </div>
                    <ul className="search_box">
                        <li>
                            <p className="txt w_100">생산자명</p>
                            <div className="drop_wrap w_90 tm3">
                                <div className="custom_input2" >
                                    <input type="text" value={farmNameInput || ""} 
                                        onFocus={() => {setFarmNameInputClick(true)}} 
                                        onBlur={() => {
                                            setFarmNameInputClick(false);
                                            setTimeout(()=>{
                                                setFarmsDrop(false);
                                            },200);
                                        }}
                                        onChange={(e) => {
                                            setFarmNameInput(e.target.value);
                                        }} 
                                    />
                                </div>
                                {farmsDrop && (<>
                                    <div className="drop_box">    
                                        <ul className="list_ul">
                                            {farms.length === 0 &&
                                                <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                            }

                                            {farms.length > 0 && (farms.map((a,i) => {
                                                return <li key={i}  onClick={(e) => {
                                                    setFarmNameInput(farms[i].ft_farm_name.trim());
                                                    setFarmsDrop(false);
                                                    setFarmNameInputClick(false);
                                                    setFarmCodeData(farms[i].ft_farm_code.trim());
                                                }}>{`${farms[i].ft_farm_name.trim()}(${farms[i].ft_farm_code.trim()})`}</li>;
                                            }))}
                                        </ul>
                                    </div>
                                </>)}
                            </div>
                        </li>
                        <li>
                            <p className="txt w_100 bp3">날짜</p>
                            <div className="input_box2 w_90 tm3">
                                <div className="date_input">
                                    <InputDatepicker selectedDate={dStartDate} ChangeHandler={dStartChange} />
                                    <InputDatepicker selectedDate={dEndDate} ChangeHandler={dEndChange} minDate={dStartDate} maxDate={maxEndDate} />
                                </div>
                                <button className="btn_type3" type="button" onClick={searchHandler}>검색</button>
                            </div>
                        </li>
                        {alert && <li className="w_100"><span className="alert_txt f_12 tp5">생산자명 또는 날짜를 입력해주세요.</span></li>}
                    </ul>
                    <div className="scroll_wrap">
                        <p className="tp30 f_15 medium">검색결과 목록</p>
                        <p className="f_12 txt_gray">* 출하물품 선택 후 '추가’버튼을 눌러야 추가할 수 있습니다.</p>
                        <div className="custom_table s_table tm10">
                            {list.length > 0 ?
                                <table>
                                    <colgroup>
                                        <col style={{width:'50px'}} />
                                        <col style={{width:'13%'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'25%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>선택</th>
                                            <th>등록번호</th>
                                            <th>생산자코드</th>
                                            <th>생산자(명)</th>
                                            <th>품명</th>
                                            <th>중량</th>
                                            <th>규격</th>
                                            <th>등급</th>
                                            <th>수량</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((a,i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>
                                                        <div className="custom_check">
                                                            <label className="flex_center" htmlFor={`pro_check_${i}`}>
                                                                <input type="checkbox" id={`pro_check_${i}`}
                                                                    onChange={(e) => {
                                                                        checkHandler(e.currentTarget.checked, e.currentTarget.value);
                                                                    }}
                                                                    value={`${list[i].de_date_online},${list[i].de_ip_no},${list[i].de_seq},${list[i].de_seq2}`}
                                                                />
                                                                <span className="check">체크</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>{`${list[i].de_date_online}-${list[i].de_no_online}`}</td>
                                                    <td>{list[i].de_farm_code}</td>
                                                    <td>{list[i].de_farm_name}</td>
                                                    <td>{list[i].gm_name}</td>
                                                    <td className="tx_r">{CF.MakeIntComma(list[i].de_weight)}kg</td>
                                                    <td>{list[i].de_packing_name}</td>
                                                    <td>{list[i].de_high_name}</td>
                                                    <td className="tx_r">{CF.MakeIntComma(list[i].de_quantity)}개</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                : <div className="none_data"><span>데이터가 없습니다.</span></div>
                            }
                        </div>
                    </div>
                    <div className="flex_center tp30">
                        <button className="btn_type rm10" onClick={addHandler}>추가</button>
                        <button className="btn_type4" onClick={closePopHandler}>취소</button>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">이전 출하상품가져오기</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap scroll_wrap2">
                        <div className="search_box">
                            <ul>
                                <li>
                                    <p className="txt">생산자명</p>
                                    <div className="drop_wrap">
                                        <div className="custom_input" >
                                            <input type="text" value={farmNameInput || ""} 
                                                onFocus={() => {setFarmNameInputClick(true)}} 
                                                onBlur={() => {
                                                    setFarmNameInputClick(false);
                                                    setTimeout(()=>{
                                                        setFarmsDrop(false);
                                                    },200);
                                                }}
                                                onChange={(e) => {
                                                    setFarmNameInput(e.target.value);
                                                }} 
                                            />
                                        </div>
                                        {farmsDrop && (<>
                                            <div className="drop_box">    
                                                <ul className="list_ul">
                                                    {farms.length === 0 &&
                                                        <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                    }

                                                    {farms.length > 0 && (farms.map((a,i) => {
                                                        return <li key={i}  onClick={(e) => {
                                                            setFarmNameInput(farms[i].ft_farm_name.trim());
                                                            setFarmsDrop(false);
                                                            setFarmNameInputClick(false);
                                                            setFarmCodeData(farms[i].ft_farm_code.trim());
                                                        }}>{`${farms[i].ft_farm_name.trim()}(${farms[i].ft_farm_code.trim()})`}</li>;
                                                    }))}
                                                </ul>
                                            </div>
                                        </>)}
                                    </div>
                                </li>
                                <li>
                                    <p className="txt">날짜</p>
                                    <div className="date_input">
                                        <InputDatepicker selectedDate={dStartDate} ChangeHandler={dStartChange} />
                                        <InputDatepicker selectedDate={dEndDate} ChangeHandler={dEndChange} minDate={dStartDate} maxDate={maxEndDate} />
                                    </div>
                                </li>
                                {alert && <li className="w_100"><span className="alert_txt f_12 tp5">생산자명 또는 품명을 입력해주세요.</span></li>}
                            </ul>
                            <button className="btn_type3 w_100 tm10" type="button" onClick={searchHandler}>검색</button>
                        </div>
                        <div className="cont2">
                            <p className="tp20 f_15 medium">검색결과 목록</p>
                            <p className="f_12 txt_gray">* 출하물품 선택 후 '추가’버튼을 눌러야 추가할 수 있습니다.</p>
                        </div>
                        {list.length > 0 ? <>
                                {list.map((a,i) => {
                                    return(<div className="custom_table s_table tm10">
                                        <div className="">
                                            <div className="custom_check custom_check2">
                                                <label htmlFor={`pro_check_${i}`}>
                                                    <input type="checkbox" id={`pro_check_${i}`}
                                                        onChange={(e) => {
                                                            checkHandler(e.currentTarget.checked, e.currentTarget.value);
                                                        }}
                                                        value={`${list[i].de_date_online},${list[i].de_ip_no},${list[i].de_seq},${list[i].de_seq2}`}
                                                    />
                                                    <span className="check">체크</span>
                                                    <span className="txt">{list[i].de_farm_name+"("+list[i].de_farm_code.trim()+")"}</span>
                                                    <span className="bg"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <table>
                                            <colgroup>
                                                <col style={{width:'23%'}} />
                                                <col style={{width:'27%'}} />
                                                <col style={{width:'23%'}} />
                                                <col style={{width:'27%'}} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="tx_l">등록번호</th>
                                                    <td colSpan="3" className="tx_l">{`${list[i].de_date_online}-${list[i].de_no_online}`}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">품명</th>
                                                    <td colSpan="3" className="tx_l">{list[i].gm_name}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">중량</th>
                                                    <td className="tx_r">{CF.MakeIntComma(list[i].de_weight)}kg</td>
                                                    <th className="tx_l">규격</th>
                                                    <td className="tx_l">{list[i].de_packing_name}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">등급</th>
                                                    <td className="tx_l">{list[i].de_high_name}</td>
                                                    <th className="tx_l">수량</th>
                                                    <td className="tx_r">{CF.MakeIntComma(list[i].de_quantity)}개</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)
                                })}
                            </>
                            : <div className="none_data tm20"><span>데이터가 없습니다.</span></div>
                        }
                    </div>
                    <div className="btn_half_box cont2 tm20">
                        <button className="btn_type rm10" onClick={addHandler}>추가</button>
                        <button className="btn_type4" onClick={closePopHandler}>취소</button>
                    </div>
                </div>
            </div>
        }

        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}} />}
    </>);
};

export default PrevProductPop;