import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import {enum_api_uri, enum_mo_title} from "../../config/enum";
import DaumPost from "../../components/pc/component/DaumPost";
import { isBrowser, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { moTitle } from "../../store/commonSlice";
import ConfirmPop from "../../components/pc/component/popup/ConfirmPop";
import { confirmPop } from "../../store/popupSlice";

const SignUp = () => {
    const [stepState, setStepState] = useState(1);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [userId, setUserId] = useState('');
    const idRef = useRef(null);
    const [phoneInput, setPhoneInput] = useState('');
    const [numInput, setNumInput] = useState('');
    const [addressInput, setAddressInput] = useState('');
    const [addressInput2, setAddressInput2] = useState('');
    const [usableId, setUsableId] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const m_email_exist = enum_api_uri.m_email_exist;
    const m_join = enum_api_uri.m_join;
    const location = useLocation();
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    // 약관동의
    const allAgreeHandler = (checked) => {
        setIsAllChecked(!isAllChecked)
        if (checked) {
          setCheckedItems([...checkedItems, 'provision', 'privacy'])
        } else if ((!checked && checkedItems.includes('provision')) || (!checked && checkedItems.includes('privacy'))) {
          setCheckedItems([])
        }
    }
    const agreeHandler = (checked, value) => {
        if (checked) {
            setCheckedItems([...checkedItems, value])
        } else if (!checked && checkedItems.includes(value)) {
            setCheckedItems(checkedItems.filter((el) => el !== value))
        }
    }

    useEffect(() => {
        if (checkedItems.length >= 2) {
          setIsAllChecked(true)
        } else {
          setIsAllChecked(false)
        }
    }, [checkedItems])

    const nextStepHandler = () => {
        setStepState(stepState + 1);
    };

    const step1Handler = (e) => {
        e.preventDefault();
        isAllChecked ? nextStepHandler()
        :   setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'전체약관에 동의해주세요.',
                confirmPopBtn:1,
            }));
    };

    // 아이디중복확인
    const idCheckHandler = () => {
        let id = idRef.current.value;
        if(id.length > 0){
            let body = {mem_id:id};
            axios.get(`${m_email_exist.replace(':mem_id',id)}`,body)
            .then((res)=>{
                if(res.status === 200){
                    setConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'이미 존재하는 아이디 입니다.',
                        confirmPopBtn:1,
                    }));
                    setUsableId(false);
                }
            })
            .catch((error) => {
                if(error.response.status === 401) {
                    setConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'사용가능한 아이디 입니다.',
                        confirmPopBtn:1,
                    }));
                    setUsableId(true);
                }
            })
        }else{
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'아이디를 입력해주세요.',
                confirmPopBtn:1,
            }));
            setUsableId(false);
        }
    };

    // input 자동하이픈
    useEffect(() => {
        if (phoneInput.length === 11) {
            setPhoneInput(phoneInput.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
        if (phoneInput.length === 13) {
            setPhoneInput(phoneInput.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phoneInput]);

    useEffect(() => {
        if (numInput.length === 10) {
            setNumInput(numInput.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
        if (numInput.length === 12) {
            setNumInput(numInput.replace(/-/g, '').replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [numInput]);

    
    const validationSchema = Yup.object().shape({
        userid: Yup.string()
            .required("아이디를 입력해주세요."),
        password: Yup.string()
            .min(4, '4자 이상 입력해주세요.')
            .required("비밀번호를 입력해주세요."),
        password2: Yup.string()
            .oneOf([Yup.ref("password"), null], "비밀번호가 일치하지 않습니다.")
            .required("비밀번호를 입력해주세요."),
        name: Yup.string()
            .required("이름을 입력해주세요."),
        phone: Yup.string()
            .required("연락처를 입력해주세요."),
        email: Yup.string()
            .defined("이메일을 입력해주세요.")
            .email("올바른 이메일 형식이 아닙니다."),
        // address: Yup.string()
        //     .defined("주소를 입력해주세요."),
        // number: Yup.string()
        //     .defined("사업장번호를 입력해주세요."),
        member: Yup.mixed()
            .oneOf(['member1','member7','member3','member9'])
            .defined("회원을 선택해주세요."),
        sms_yn: Yup.mixed()
            .oneOf(['sms_yn0','sms_yn1'])
            .required("문자서비스 수신동의를 선택해주세요."),
        email_yn: Yup.mixed()
            .oneOf(['email_yn0','email_yn1'])
            .required("이메일 수신동의를 선택해주세요."),
    });


    //회원가입 버튼 클릭시
    const signupBtnClickHandler = () => {
        if(enroll_company.zonecode){
            setAddressError(false);
        }else{
            setAddressError(true);
        }
    };


    // 회원가입
    const submit = async (values) => {
        if(!usableId){
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'아이디중복확인을 해주세요.',
                confirmPopBtn:1,
            }));
        }else if(addressError){
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'주소를 입력해주세요.',
                confirmPopBtn:1,
            }));
        }else{
            try {
                var regex = /[^0-9]/g;
                const member = values.member.replace(regex,'');
                const email_yn = values.email_yn.replace(regex,'');
                const sms_yn = values.sms_yn.replace(regex,'');
                let body = {
                    mem_id:values.userid,
                    mem_pw:values.password,
                    mem_nm:values.name,
                    mem_hand:values.phone,
                    mem_email:values.email,
                    mem_zip:enroll_company.zonecode,
                    mem_addr1:enroll_company.address,
                    mem_addr2:values.address3,
                    mem_tel:values.number,
                    mem_kind:member,
                    email_yn:email_yn,
                    sms_yn:sms_yn
                };
                // console.log(body);
                await axios.post(`${m_join}`,body)
                .then((res)=>{
                    if(res.status === 200){
                        setStepState(3);
                        setUserId(values.userid);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
                setConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:`error${error}`,
                    confirmPopBtn:1,
                }));
            }
        }
    };

    // 주소찾기
    const [enroll_company, setEnroll_company] = useState({
        address:"",
    });
    
    // 다음주소팝업
    const [daumPop, setDaumPop] = useState(false);
    
    const handleComplete = (e) => {
        e.preventDefault();
        setDaumPop(!daumPop);
    }

    //주소값 변경시 주소에러문구
    useEffect(()=>{
        if(enroll_company.zonecode){
            setAddressError(false);
        }
    },[enroll_company]);

    
    return(
        <div className="page_form">
            <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={true} draggable={false} />
            
            {/* PC일때 */}
            {isBrowser && <>
                <div className="top_banner">
                    <div>
                        <h3>회원가입</h3>
                        <p>농민의 소중한 땀의 결실에 보답하기위해 최선을 다하겠습니다.</p>
                    </div>
                </div>
                <div className="cont">
                    <ul className="step_box flex">
                        <li className={`flex_between ${stepState === 1  && 'on'}`}>
                            <p><strong>STEP 01</strong>약관동의</p>
                            <div className="img"></div>
                        </li>
                        <li className={`flex_between ${stepState === 2  && 'on'}`}>
                            <p><strong>STEP 02</strong>회원정보입력</p>
                            <div className="img"></div>
                        </li>
                        <li className={`flex_between ${stepState === 3  && 'on'}`}>
                            <p><strong>STEP 03</strong>가입완료</p>
                            <div className="img"></div>
                        </li>
                    </ul>

                    {/* 약관동의 */}
                    {stepState === 1 && (<>
                        <div className="flex_between">
                            <p className="tit">회원가입 & 약관동의</p>
                            <div className="custom_check">
                                <label htmlFor="all_agree">
                                    <input type="checkbox" id="all_agree" 
                                        onChange={(e) => allAgreeHandler(e.currentTarget.checked)}
                                        checked={isAllChecked}
                                    />
                                    <span className="check">체크</span>
                                    <span className="txt">전체 약관에 동의합니다.</span>
                                </label>
                            </div>
                        </div>
                        <form onSubmit={step1Handler}>
                            <div className="gray_box">
                                <ul className="terms_box">
                                    <li>
                                        <p className="bold bp10">이용약관</p>
                                        <div className="scroll_wrap">
                                            <p>본 이용약관은 공정거래위원회 표준약관 제 10023 호에 의거하여 작성되었습니다. <br/>
                                                제1조(목적) <br/>
                                                이 약관은 구리청과 (전자거래 사업자)이 운영하는 사이버 몰 구리청과쇼핑몰(이하 "구리청과"이라 한다)에서 제공하는 인터넷관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. <br/>
                                                ※ pc통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
                                                제2조(정의)<br/>
                                            </p>
                                        </div>
                                        <div className="flex_end tp10">
                                            <div className="custom_check">
                                                <label htmlFor="agree1">
                                                    <input type="checkbox" id="agree1" value="provision"
                                                        onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                                        checked={checkedItems.includes('provision') ? true : false}
                                                    />
                                                    <span className="check">체크</span>
                                                    <span className="txt">읽고 이해 했으며, 내용에 동의 합니다.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="tp20">
                                        <p className="bold bp10">이용약관</p>
                                        <div className="scroll_wrap">
                                            <p>본 이용약관은 공정거래위원회 표준약관 제 10023 호에 의거하여 작성되었습니다. <br/>
                                                제1조(목적) <br/>
                                                이 약관은 구리청과 (전자거래 사업자)이 운영하는 사이버 몰 구리청과쇼핑몰(이하 "구리청과"이라 한다)에서 제공하는 인터넷관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. <br/>
                                                ※ pc통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
                                                제2조(정의)<br/>
                                            </p>
                                        </div>
                                        <div className="flex_end tp10">
                                            <div className="custom_check">
                                                <label htmlFor="agree2">
                                                    <input type="checkbox" id="agree2" value="privacy" 
                                                        onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                                        checked={checkedItems.includes('privacy') ? true : false}
                                                    />
                                                    <span className="check">체크</span>
                                                    <span className="txt">읽고 이해 했으며, 내용에 동의 합니다.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex_center tp30 btn_box">
                                <Link to="/" className="btn_type4 rm10">취소하기</Link>
                                <button type="submit" className="btn_type">다음단계</button>
                            </div>
                        </form>
                    </>)}
                    
                    {/* 회원정보입력 */}
                    {stepState === 2 && (<>
                        <div className="flex">
                            <p className="tit rp30">회원가입 정보입력</p>
                            <p className="txt"><span className="txt_black">ID는 변경할 수 없으니 신중하게 선택해 주시기 바랍니다.</span><br/>연락처, 주소 정보는 배송 및 이벤트 제공 등에 사용되므로 반드시 실제 정보를 입력하시기 바랍니다.</p>
                        </div>
                        <Formik
                            initialValues={{
                                userid: "",
                                password: "",
                                password2: "",
                                name: "",
                                phone: "",
                                email: "",
                                address: "",
                                address2: "",
                                address3: "",
                                number: "",
                                member: "",
                                sms_yn: "",
                                email_yn: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={submit}
                            validateOnMount={true}
                        >
                            {({values, handleSubmit, handleChange, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="gray_box">
                                        <ul className="form_ul">
                                            <li>
                                                <p className="input_tit">아이디 <span>*</span></p>
                                                <div className="box">
                                                    <div className="input_box3">
                                                        <div className="custom_input">
                                                            <input type="text" placeholder="아이디를 입력해주세요." value={values.userid} name="userid" onChange={handleChange} ref={idRef} />
                                                        </div>
                                                        <button type="button" className="btn_s2" onClick={idCheckHandler}>중복확인</button>
                                                    </div>
                                                    {errors.userid && touched.userid ?
                                                        <div className="alert_txt">
                                                            {errors.userid}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">비밀번호 <span>*</span></p>
                                                <div className="box flex_wrap">
                                                    <div className="custom_input">
                                                        <input type="password" placeholder="비밀번호를 입력해주세요." value={values.password} name="password" onChange={handleChange} />
                                                    </div>
                                                    <p className="f_14 txt_gray2 lp16">4자 이상 입력해주세요.</p>
                                                    {errors.password && touched.password ?
                                                        <div className="alert_txt">
                                                            {errors.password}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">비밀번호 확인 <span>*</span></p>
                                                <div className="box">
                                                    <div className="custom_input">
                                                        <input type="password" placeholder="비밀번호를 입력해주세요." value={values.password2} name="password2" onChange={handleChange} />
                                                    </div>
                                                    {errors.password2 && touched.password2 ?
                                                        <div className="alert_txt">
                                                            {errors.password2}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">이름 <span>*</span></p>
                                                <div className="box">
                                                    <div className="custom_input">
                                                        <input type="text" placeholder="이름을 입력해주세요." value={values.name} name="name" onChange={handleChange} />
                                                    </div>
                                                    {errors.name && touched.name ?
                                                        <div className="alert_txt">
                                                            {errors.name}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">연락처 <span>*</span></p>
                                                <div className="box">
                                                    <div className="custom_input" >
                                                        <input type="text" maxLength="13" value={phoneInput} name="phone" onChange={(e) => {
                                                            handleChange(e)
                                                            setPhoneInput(e.currentTarget.value);
                                                        }} />
                                                    </div>
                                                    {errors.phone && touched.phone ?
                                                        <div className="alert_txt">
                                                            {errors.phone}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">이메일 <span>*</span></p>
                                                <div className="box">
                                                    <div className="custom_input">
                                                        <input type="text" value={values.email} name="email" onChange={handleChange} />
                                                    </div>
                                                    {errors.email && touched.email ?
                                                        <div className="alert_txt">
                                                            {errors.email}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">주소 <span>*</span></p>
                                                <div className="box">
                                                    <div className="input_box3 bp5">
                                                        <div className="custom_input">
                                                            <input type="text" value={enroll_company.zonecode || ""} name="address" onChange={(e) => {
                                                                handleChange(e);
                                                            }} readOnly />
                                                        </div>
                                                        <button type="button" className="btn_s2" onClick={handleComplete}>주소찾기</button>
                                                    </div>
                                                    <div className="w_100 flex_between input_box4">
                                                        <div className="custom_input">
                                                            <input type="text" value={enroll_company.address || ""} name="address2" onChange={(e) => {
                                                                handleChange(e);
                                                            }} readOnly />
                                                        </div>
                                                        <div className="custom_input">
                                                            <input type="text" placeholder="상세주소 입력" value={values.address3} name="address3" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    {addressError && <div className="alert_txt w_50">주소를 입력해주세요.</div>}
                                                </div>
                                                {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
                                            </li>
                                            <li>
                                                <p className="input_tit">사업장 전화번호</p>
                                                <div className="box">
                                                    <div className="custom_input">
                                                        <input type="text" maxLength="12" value={numInput} name="number" onChange={(e) => {
                                                            handleChange(e)
                                                            setNumInput(e.currentTarget.value);
                                                        }} />
                                                    </div>
                                                    {errors.number && touched.number ?
                                                        <div className="alert_txt">
                                                            {errors.number}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li>
                                                <p className="input_tit">회원구분 <span>*</span></p>
                                                <div className="box">
                                                    <ul className="flex tp10">
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="member1" name="member" value="member1" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">출하주</span>
                                                            </label>
                                                        </li>
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="member7" name="member" value="member7" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">경매사</span>
                                                            </label>
                                                        </li>
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="member3" name="member" value="member3" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">중도매인</span>
                                                            </label>
                                                        </li>
                                                        {/* <li className="custom_radio">
                                                            <label>
                                                                <Field type="radio" id="member9" name="member" value="member9" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">기타</span>
                                                            </label>
                                                        </li> */}
                                                    </ul>
                                                    {errors.member && touched.member ?
                                                        <div className="alert_txt">
                                                            {errors.member}
                                                        </div>
                                                    : null}
                                                </div>
                                            </li>
                                            <li className="check_li flex">
                                                <div className="w_40 flex">
                                                    <p className="input_tit">문자서비스 수신동의 <span>*</span></p>
                                                    <div className="box">
                                                        <ul className="flex tp10">
                                                            <li className="custom_radio rm10">
                                                                <label>
                                                                    <Field type="radio" id="sms_yn0" name="sms_yn" value="sms_yn0" onChange={handleChange} />
                                                                    <span className="check">체크</span>
                                                                    <span className="txt">수신동의</span>
                                                                </label>
                                                            </li>
                                                            <li className="custom_radio rm10">
                                                                <label>
                                                                    <Field type="radio" id="sms_yn1" name="sms_yn" value="sms_yn1" onChange={handleChange} />
                                                                    <span className="check">체크</span>
                                                                    <span className="txt">수신거부</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                        {errors.sms_yn && touched.sms_yn ?
                                                            <div className="alert_txt">
                                                                {errors.sms_yn}
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="w_40 flex">
                                                    <p className="input_tit">이메일 수신동의 <span>*</span></p>
                                                    <div className="box">
                                                        <ul className="flex tp10">
                                                            <li className="custom_radio rm10">
                                                                <label>
                                                                    <Field type="radio" id="email_yn0" name="email_yn" value="email_yn0" onChange={handleChange} />
                                                                    <span className="check">체크</span>
                                                                    <span className="txt">수신동의</span>
                                                                </label>
                                                            </li>
                                                            <li className="custom_radio rm10">
                                                                <label>
                                                                    <Field type="radio" id="email_yn1" name="email_yn" value="email_yn1" onChange={handleChange} />
                                                                    <span className="check">체크</span>
                                                                    <span className="txt">수신거부</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                        {errors.email_yn && touched.email_yn ?
                                                            <div className="alert_txt">
                                                                {errors.email_yn}
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex_center tp30 btn_box">
                                        <button type="button" className="btn_type4 rm10">취소하기</button>
                                        <button type="submit" className="btn_type" onClick={signupBtnClickHandler}>회원가입</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </>)}

                    {/* 가입완료 */}
                    {stepState === 3 && (<>
                        <div className="tx_c tp130">
                            <p className="big_tit">회원가입 완료</p>
                            <div className="line_box">
                                <div className="img"></div>
                                <p className="f_17 medium bp10">축하합니다!</p>
                                <p className="f_15 txt_gray"><span className="medium">구리청과</span> 회원가입이 완료되었습니다.</p>
                                <p className="f_15 txt_gray">가입하신 ID는 <span className="txt_sky">{userId}</span> 입니다.</p>
                            </div>
                            <div className="flex_center tp20 btn_box">
                                <Link to="/" className="btn_type">로그인</Link>
                            </div>
                        </div>
                    </>)}
                    
                </div>
            </>}

            {/* 모바일일때 */}
            {isMobile && <div className="cont">
                <div className="step_box">
                    <div className="flex_between">
                        {stepState === 1 && <p className="tit">회원가입 & 약관동의</p>}
                        {stepState === 2 && <p className="tit">회원가입 정보입력</p>}
                        {stepState === 3 && <p className="tit">완료</p>}
                        <ul className="flex">
                            <li className={`${stepState === 1  && 'on'}`}>
                                <span>1</span>
                            </li>
                            <li className={`${stepState === 2  && 'on'}`}>
                                <span>2</span>
                            </li>
                            <li className={`${stepState === 3  && 'on'}`}>
                                <span>3</span>
                            </li>
                        </ul>
                    </div>
                    {stepState === 2 && 
                        <p className="txt"><span>ID는 변경할 수 없으니 신중하게 선택해 주시기 바랍니다.</span><br/>
                        연락처, 주소 정보는 배송 및 이벤트 제공 등에 사용되므로 반드시 실제 정보를 입력하시기 바랍니다.</p>
                    }
                </div>

                {/* 약관동의 */}
                {stepState === 1 && (<>
                    <form onSubmit={step1Handler}>
                        <div className="gray_box">
                            <ul className="terms_box">
                                <li>
                                    <p className="bold bp10">이용약관</p>
                                    <div className="scroll_wrap">
                                        <p>본 이용약관은 공정거래위원회 표준약관 제 10023 호에 의거하여 작성되었습니다. <br/>
                                            제1조(목적) <br/>
                                            이 약관은 구리청과 (전자거래 사업자)이 운영하는 사이버 몰 구리청과쇼핑몰(이하 "구리청과"이라 한다)에서 제공하는 인터넷관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. <br/>
                                            ※ pc통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
                                            제2조(정의)<br/>
                                        </p>
                                    </div>
                                    <div className="tp10">
                                        <div className="custom_check">
                                            <label htmlFor="agree1">
                                                <input type="checkbox" id="agree1" value="provision"
                                                    onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                                    checked={checkedItems.includes('provision') ? true : false}
                                                />
                                                <span className="check">체크</span>
                                                <span className="txt">읽고 이해 했으며, 내용에 동의 합니다.</span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li className="tp20">
                                    <p className="bold bp10">이용약관</p>
                                    <div className="scroll_wrap">
                                        <p>본 이용약관은 공정거래위원회 표준약관 제 10023 호에 의거하여 작성되었습니다. <br/>
                                            제1조(목적) <br/>
                                            이 약관은 구리청과 (전자거래 사업자)이 운영하는 사이버 몰 구리청과쇼핑몰(이하 "구리청과"이라 한다)에서 제공하는 인터넷관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. <br/>
                                            ※ pc통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
                                            제2조(정의)<br/>
                                        </p>
                                    </div>
                                    <div className="tp10">
                                        <div className="custom_check">
                                            <label htmlFor="agree2">
                                                <input type="checkbox" id="agree2" value="privacy" 
                                                    onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                                    checked={checkedItems.includes('privacy') ? true : false}
                                                />
                                                <span className="check">체크</span>
                                                <span className="txt">읽고 이해 했으며, 내용에 동의 합니다.</span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="tm10">
                            <div className="custom_check">
                                <label htmlFor="all_agree">
                                    <input type="checkbox" id="all_agree" 
                                        onChange={(e) => allAgreeHandler(e.currentTarget.checked)}
                                        checked={isAllChecked}
                                    />
                                    <span className="check">체크</span>
                                    <span className="txt">전체 약관에 동의합니다.</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex_center tp30 btn_box">
                            <button type="button" className="btn_type4 rm10">취소하기</button>
                            <button type="submit" className="btn_type">다음단계</button>
                        </div>
                    </form>
                    
                </>)}
                
                {/* 회원정보입력 */}
                {stepState === 2 && (<>
                    <Formik
                        initialValues={{
                            userid: "",
                            password: "",
                            password2: "",
                            name: "",
                            phone: "",
                            email: "",
                            address: "",
                            address2: "",
                            address3: "",
                            number: "",
                            member: "",
                            sms_yn: "",
                            email_yn: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={submit}
                        validateOnMount={true}
                    >
                        {({values, handleSubmit, handleChange, errors, touched}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="gray_box">
                                    <ul className="form_ul">
                                        <li>
                                            <p className="input_tit">아이디 <span>*</span></p>
                                            <div className="box">
                                                <div className="input_box3">
                                                    <div className="custom_input">
                                                        <input type="text" placeholder="아이디를 입력해주세요." value={values.userid} name="userid" onChange={handleChange} ref={idRef} />
                                                    </div>
                                                    <button type="button" className="btn_s2" onClick={idCheckHandler}>중복확인</button>
                                                </div>
                                                {errors.userid && touched.userid ?
                                                    <div className="alert_txt">
                                                        {errors.userid}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">비밀번호 <span>*</span></p>
                                            <div className="box flex_wrap">
                                                <div className="custom_input">
                                                    <input type="password" placeholder="비밀번호를 입력해주세요." value={values.password} name="password" onChange={handleChange} />
                                                </div>
                                                <p className="f_13 txt_gray2 tp3">4자 이상 입력해주세요.</p>
                                                {errors.password && touched.password ?
                                                    <div className="alert_txt">
                                                        {errors.password}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">비밀번호 확인 <span>*</span></p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="password" placeholder="비밀번호를 입력해주세요." value={values.password2} name="password2" onChange={handleChange} />
                                                </div>
                                                {errors.password2 && touched.password2 ?
                                                    <div className="alert_txt">
                                                        {errors.password2}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">이름 <span>*</span></p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="text" placeholder="이름을 입력해주세요." value={values.name} name="name" onChange={handleChange} />
                                                </div>
                                                {errors.name && touched.name ?
                                                    <div className="alert_txt">
                                                        {errors.name}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">연락처 <span>*</span></p>
                                            <div className="box">
                                                <div className="custom_input" >
                                                    <input type="text" maxLength="13" value={phoneInput} name="phone" onChange={(e) => {
                                                        handleChange(e)
                                                        setPhoneInput(e.currentTarget.value);
                                                    }} />
                                                </div>
                                                {errors.phone && touched.phone ?
                                                    <div className="alert_txt">
                                                        {errors.phone}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">이메일 <span>*</span></p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="text" value={values.email} name="email" onChange={handleChange} />
                                                </div>
                                                {errors.email && touched.email ?
                                                    <div className="alert_txt">
                                                        {errors.email}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">주소 <span>*</span></p>
                                            <div className="box">
                                                <div className="input_box3 bp5">
                                                    <div className="custom_input">
                                                        <input type="text" value={enroll_company.zonecode || ""} name="address" onChange={(e) => {
                                                            handleChange(e);
                                                            setAddressInput(e.currentTarget.value);
                                                        }} readOnly />
                                                    </div>
                                                    <button type="button" className="btn_s2" onClick={handleComplete}>주소찾기</button>
                                                </div>
                                                <div className="custom_input bm5">
                                                    <input type="text" value={enroll_company.address || ""} name="address2" onChange={(e) => {
                                                        handleChange(e);
                                                        setAddressInput2(e.currentTarget.value);
                                                    }} readOnly />
                                                </div>
                                                <div className="custom_input">
                                                    <input type="text" placeholder="상세주소 입력" value={values.address3} name="address3" onChange={handleChange} />
                                                </div>
                                                {addressError && <div className="alert_txt">주소를 입력해주세요.</div>}
                                            </div>
                                            {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
                                        </li>
                                        <li>
                                            <p className="input_tit">사업장 전화번호</p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="text" maxLength="12" value={numInput} name="number" onChange={(e) => {
                                                        handleChange(e)
                                                        setNumInput(e.currentTarget.value);
                                                    }} />
                                                </div>
                                                {errors.number && touched.number ?
                                                    <div className="alert_txt">
                                                        {errors.number}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">회원구분 <span>*</span></p>
                                            <div className="box">
                                                <ul className="flex tp10">
                                                    <li className="custom_radio rm10">
                                                        <label>
                                                            <Field type="radio" id="member1" name="member" value="member1" onChange={handleChange} />
                                                            <span className="check">체크</span>
                                                            <span className="txt">출하주</span>
                                                        </label>
                                                    </li>
                                                    <li className="custom_radio rm10">
                                                        <label>
                                                            <Field type="radio" id="member7" name="member" value="member7" onChange={handleChange} />
                                                            <span className="check">체크</span>
                                                            <span className="txt">경매사</span>
                                                        </label>
                                                    </li>
                                                    <li className="custom_radio rm10">
                                                        <label>
                                                            <Field type="radio" id="member3" name="member" value="member3" onChange={handleChange} />
                                                            <span className="check">체크</span>
                                                            <span className="txt">중도매인</span>
                                                        </label>
                                                    </li>
                                                    {/* <li className="custom_radio">
                                                        <label>
                                                            <Field type="radio" id="member9" name="member" value="member9" onChange={handleChange} />
                                                            <span className="check">체크</span>
                                                            <span className="txt">기타</span>
                                                        </label>
                                                    </li> */}
                                                </ul>
                                                {errors.member && touched.member ?
                                                    <div className="alert_txt">
                                                        {errors.member}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li className="check_li">
                                            <div className="bp25">
                                                <p className="input_tit">문자서비스 수신동의 <span>*</span></p>
                                                <div className="box">
                                                    <ul className="flex tp10">
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="sms_yn0" name="sms_yn" value="sms_yn0" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">수신동의</span>
                                                            </label>
                                                        </li>
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="sms_yn1" name="sms_yn" value="sms_yn1" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">수신거부</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    {errors.sms_yn && touched.sms_yn ?
                                                        <div className="alert_txt">
                                                            {errors.sms_yn}
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="">
                                                <p className="input_tit">이메일 수신동의 <span>*</span></p>
                                                <div className="box">
                                                    <ul className="flex tp10">
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="email_yn0" name="email_yn" value="email_yn0" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">수신동의</span>
                                                            </label>
                                                        </li>
                                                        <li className="custom_radio rm10">
                                                            <label>
                                                                <Field type="radio" id="email_yn1" name="email_yn" value="email_yn1" onChange={handleChange} />
                                                                <span className="check">체크</span>
                                                                <span className="txt">수신거부</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    {errors.email_yn && touched.email_yn ?
                                                        <div className="alert_txt">
                                                            {errors.email_yn}
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex_center tp30 btn_box">
                                    <Link to="/" className="btn_type4 rm10">취소하기</Link>
                                    <button type="submit" className="btn_type" onClick={signupBtnClickHandler}>회원가입</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </>)}

                {/* 가입완료 */}
                {stepState === 3 && (<>
                    <div className="tx_c">
                        <p className="f_20 bold">회원가입 완료</p>
                        <div className="line_box">
                            <div className="img"></div>
                            <p className="f_17 medium bp10">축하합니다!</p>
                            <p className="f_15 txt_gray"><span className="medium">구리청과</span> 회원가입이 완료되었습니다.</p>
                            <p className="f_15 txt_gray">가입하신 ID는 <span className="txt_sky">{userId}</span> 입니다.</p>
                        </div>
                        <div className="flex_center tp20 btn_box">
                            <Link to="/" className="btn_type">로그인</Link>
                        </div>
                    </div>
                </>)}
            </div>}

            {/* confirm팝업 */}
            {confirm && <ConfirmPop />}
        </div>
    );
};

export default SignUp;