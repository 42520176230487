import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newProductPop, prevProductPop, productPopModify, productDelt, confirmPop, prevProductAdd } from "../../../store/popupSlice";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import LeftCont from "../../../components/pc/component/LeftCont";
import ProductInfo from "../../../components/pc/component/ProductInfo";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";
import ic_get from "../../../images/ic_get.svg";
import ic_plus from "../../../images/ic_plus.svg";
import {enum_api_uri, debounce, debounce2, enum_mo_title, p_approval_invoice} from "../../../config/enum";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle } from "../../../store/commonSlice";
import { NumericFormat } from 'react-number-format';
import * as CF from "../../../components/pc/component/Function"; // (customFunction)

const InvoiceRegister = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);

    const [registration, setRegistration] = useState(false);
    const [LocaCodeDrop, setLocaCodeDrop] = useState(false);
    const [locaCodeInput, setLocaCodeInput] = useState('');
    const [locaInput, setLocaInput] = useState('');
    const [locaCode, setLocaCode] =  useState([]);
    const [locaError, setLocaError] =  useState(false);
    const [locaInputClick, setLocaInputClick] =  useState(false);
    const [proList, setProList] = useState([]);
    const p_create_invoice = enum_api_uri.p_create_invoice;
    const p_modify_invoice = enum_api_uri.p_modify_invoice;
    const c_search_origin = enum_api_uri.c_search_origin;
    const p_list_product = enum_api_uri.p_list_product;
    const p_modify_product = enum_api_uri.p_modify_product;
    const p_approval_invoice = enum_api_uri.p_approval_invoice;
    const user = useSelector((state) => state.user);
    const popup = useSelector((state) => state.popup);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [dStartDate, setDStartDate] = useState('');
    const [dEndDate, setDEndDate] = useState('');
    const [dateError, setDateError] = useState(false);
    const [woTot, setWoTot] = useState('0');
    const [woTotError, setWoTotError] = useState(false);
    const location = useLocation();
    const [proDeltConfirm, setProDeltConfirm] = useState(false);
    const [invoApprovalConfirm, setInvoApprovalConfirm] = useState(false);
    const [invoApprovalCancelConfirm, setInvoApprovalCancelConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [ipKind, setIpKind] = useState(1);
    const [ipStatus, setIpStatus] = useState(1);
    const [serverError, setServerError] = useState(false);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setInvoApprovalConfirm(false);
            setInvoApprovalCancelConfirm(false);
            setProDeltConfirm(false);
            setServerError(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    const dStartChange = (date) => {
        setDStartDate(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setDEndDate(date);
        setDateError(false);
    };

    useEffect(() => {
        setProList(proList);
        console.log(proList)
    },[proList]);

    useEffect(() => {
        setIpStatus(ipStatus);
    },[ipStatus]);

    useEffect(() => {
        setIpKind(ipKind);
        setLocaCode('');
        setLocaInput('');
    },[ipKind]);

    // 송품장작성시 유효성체크
    const validationSchema = Yup.object().shape({
        ip_s_kind: Yup.string()
            .required()
            .oneOf(["ip_s_kind_1","ip_s_kind_2"])
            .label("매매구분을 선택해주세요."),
        // ip_kpp: Yup.number().moreThan(0),
        // ip_kcp: Yup.number().moreThan(0),
        // ip_kind: Yup.string()
        //     .required()
        //     .oneOf(["ip_kind_1","ip_kind_2"])
        //     .label("판매구분을 선택해주세요."),
        // ip_quan_tot: Yup.number().moreThan(0)
    });

    //송품장 생성/변경-------------------------------------------------
    const submit = (values) => {
        let regex = /[^0-9]/g;
        const ip_s_kind = values.ip_s_kind.replace(regex,'');
        const ip_kind = ipKind;

        //숫자형 콤마 제거
        values.ip_kcp = CF.RemoveIntComma(values.ip_kcp)
        values.ip_kpp = CF.RemoveIntComma(values.ip_kpp)
        values.ip_quan_tot = CF.RemoveIntComma(values.ip_quan_tot)
        values.woTot = CF.RemoveIntComma(values.woTot)

        let startDate = moment(dStartDate).format('YYYYMMDD');
        let endDate = moment(dEndDate).format('YYYYMMDD');

        let body = {
            mem_id: user.id,
            ip_s_kind: ip_s_kind,
            ip_kpp: values.ip_kpp,
            ip_kcp: values.ip_kcp,
            ip_kind: ip_kind,
            ip_wo_tot: values.woTot,
            ip_loca: locaCodeInput,
            ip_quan_tot: values.ip_quan_tot,
            ip_delivery_date_start: startDate,
            ip_delivery_date_end: endDate
        };
        if(!locaError && !dateError){
            if(values.ip_kcp > 100 || values.ip_kpp > 100){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'팰릿 또는 목재는 100개 이하로만 작성해주세요.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }else{
                // 송품장 작성
                if(!registration){
                    //console.log(body);
                    axios.post(`${p_create_invoice}`,body,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            let data = JSON.parse(localStorage.getItem('invoiceData'));
                            data = {
                                ip_date_online:res.data.ip_date_online,
                                ip_no_online:res.data.ip_no_online,
                                ip_s_kind:body.ip_s_kind,
                                ip_kind:body.ip_kind,
                                ip_loca:body.ip_loca,
                            }
                            localStorage.setItem("invoiceData", JSON.stringify(data));
                            setRegistration(true);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                }
                // 송품장 변경
                else{
                    let getData = localStorage.getItem("invoiceData");
                    getData = JSON.parse(getData);
                    let ipDate = getData.ip_date_online;
                    let ipNoOnline = getData.ip_no_online;
                    axios.put(`${p_modify_invoice.replace(':ip_date_online',ipDate).replace(':ip_no_online',ipNoOnline)}`,body,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            console.log('송품장변경 완료');
                            dispatch(confirmPop({
                                confirmPopTit:'알림',
                                confirmPopTxt:'송품장정보가 변경되었습니다.',
                                confirmPopBtn:1,
                            }));
                            setConfirm(true);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                }
            }
        }
    };

    // 송품장 산지 검색
    useEffect(() => {
        setLocaCodeInput(locaCodeInput);
        if(locaInputClick){
            if(locaCodeInput.length > 0){
                const et_kind = parseInt(ipKind) == 1 ? 'O' : 'N';
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=${et_kind}&search_word=${locaCodeInput}&ip_kind=${ipKind}`,{
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setLocaCode(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setLocaCodeDrop(true);
                },debounce2);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setLocaCodeDrop(false);
            }
        }
    },[locaCodeInput]);

    //송품장 입하신청
    const invoApproval = () => {
        let data = JSON.parse(localStorage.getItem('invoiceData'));
        let ip_date_online = data.ip_date_online;
        let ip_no_online = data.ip_no_online;
        let body = {
            ip_status:2,
        };
        axios.put(`${p_approval_invoice.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`, body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                // console.log(res.data);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입하신청이 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
                setIpStatus(2);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //송품장 입하신청취소
    const invoApprovalCancel = () => {
        let data = JSON.parse(localStorage.getItem('invoiceData'));
        let ip_date_online = data.ip_date_online;
        let ip_no_online = data.ip_no_online;
        let body = {
            ip_status:1,
        };
        axios.put(`${p_approval_invoice.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`, body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            console.log(res);
            if(res.status === 200){
                // console.log(res.data);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입하신청취소가 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
                setIpStatus(1);
                // window.location.reload();
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //등록된 물품조회
    useEffect(() => {
        if(popup.newProductAdd || popup.prevProductAdd){
            let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
            let ipDate = getData.ip_date_online;
            let ipNoOnline = getData.ip_no_online;
            axios.get(`${p_list_product.replace(':ip_date_online',ipDate).replace(':ip_no_online',ipNoOnline)}`,{
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    console.log(res.data);
                    let data = res.data;
                    let state = [...data];
                    setProList(state);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    },[popup.newProductAdd, popup.prevProductAdd]);

    // 물품삭제
    const proDeltHandler = () => {
        let getData = localStorage.getItem("invoiceData");
        getData = JSON.parse(getData);
        let ipDate = getData.ip_date_online;
        let ipNoOnline = getData.ip_no_online;
        axios.delete(`${p_modify_product.replace(':ip_date_online',ipDate).replace(':ip_no_online',ipNoOnline).replace(':de_date_online',popup.deDate).replace(':de_no_online',popup.deNo).replace(':de_seq',popup.deSeq).replace(':de_seq2',popup.deSeq2)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                // 물품삭제후 등록된물품 조회
                axios.get(`${p_list_product.replace(':ip_date_online',ipDate).replace(':ip_no_online',ipNoOnline)}`,{
                    headers:{Authorization: `Bearer ${token}`}
                })
                .then((res)=>{
                    if(res.status === 200){
                        let data = res.data
                        let state = [...data];
                        setProList(state);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };


    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont
                        user={props.user}
                        contFold={contFold}
                        setContFold={setContFold}
                    />
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">송품장 작성</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>출하자</li>
                                    <li>송품장 작성</li>
                                </ul>
                            </div>
                            <p className="medium bp10">기본정보</p>
                            <Formik
                                initialValues={{
                                    ip_s_kind: "",
                                    ip_kpp: "",
                                    ip_kcp: "",
                                    ip_kind: "",
                                    ip_quan_tot: "0",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                                {({values, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                                    <form onSubmit={handleSubmit}>
                                        {/* <FormObserver /> */}
                                        <div className="custom_table2 input_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{ width: "14%" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "14%" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "14%" }} />
                                                    <col style={{ width: "auto" }} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <span>* </span>매매구분
                                                        </th>
                                                        <td>
                                                            <div className="custom_select">
                                                                <select 
                                                                    name="ip_s_kind"
                                                                    value={values.ip_s_kind}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    style={{ display: 'block' }}
                                                                >
                                                                    <option value="" hidden>매매구분</option>
                                                                    <option value="ip_s_kind_1">입찰</option>
                                                                    <option value="ip_s_kind_2">경매</option>
                                                                </select>
                                                            </div>
                                                            {errors.ip_s_kind && touched.ip_s_kind ?
                                                                <div className="alert_txt f_12">
                                                                    매매구분을 선택해주세요.
                                                                </div>
                                                            : null}
                                                        </td>
                                                        <th>팰릿</th>
                                                        <td>
                                                            <div className="custom_input h_40 txt_input">
                                                               <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kpp} name="ip_kpp" onChange={handleChange} className="tx_r" />
                                                                <span className="txt">개</span>
                                                            </div>
                                                            {errors.ip_kpp && touched.ip_kpp ?
                                                                <div className="alert_txt f_12">
                                                                    숫자로만 입력해주세요.
                                                                </div>
                                                            : null}
                                                        </td>
                                                        <th>목재(옥타곤)</th>
                                                        <td>
                                                            <div className="custom_input h_40 txt_input">
                                                                <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kcp} name="ip_kcp" onChange={handleChange} className="tx_r"/>
                                                                <span className="txt">개</span>
                                                            </div>
                                                            {errors.ip_kcp && touched.ip_kcp ?
                                                                <div className="alert_txt f_12">
                                                                    숫자로만 입력해주세요.
                                                                </div>
                                                            : null}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <span>* </span>판매구분
                                                        </th>
                                                        <td>
                                                            <div className="custom_select">
                                                                <select 
                                                                    name="ip_kind"
                                                                    value={values.ip_kind || 'ip_kind_1'}
                                                                    onChange={(e)=>{
                                                                        handleChange(e);
                                                                        let regex = /[^0-9]/g;
                                                                        let value = e.currentTarget.value.replace(regex,'');
                                                                        setIpKind(value);
                                                                        setLocaCodeInput('');
                                                                        setLocaInput('');
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    style={{ display: 'block' }}
                                                                >
                                                                    <option value="ip_kind_1">국내</option>
                                                                    <option value="ip_kind_2">수입</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <th>
                                                            <span>* </span>산지
                                                        </th>
                                                        <td colSpan="3">
                                                            <div className="drop_input_box">
                                                                <div className="drop_wrap">
                                                                    <div className="custom_input h_40">
                                                                        <input type="text" maxLength="5" value={locaCodeInput || ""} 
                                                                            onFocus={()=> {setLocaInputClick(true)}} 
                                                                            onBlur={() => {
                                                                                setLocaInputClick(false);
                                                                                setTimeout(()=>{
                                                                                    setLocaCodeDrop(false);
                                                                                },200);
                                                                            }}
                                                                            onChange={(e) =>{
                                                                                setLocaCodeInput(e.target.value);
                                                                            }} 
                                                                        />
                                                                    </div>
                                                                    {LocaCodeDrop && (<>
                                                                        <div className="drop_box loca">    
                                                                            <ul className="list_ul">
                                                                                {locaCode.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                                {locaCode.length > 0 && (locaCode.map((a,i) => {
                                                                                    return <li key={i}  onClick={(e) => {
                                                                                        setLocaCodeInput(locaCode[i].et_code.trim());
                                                                                        setLocaInput(locaCode[i].et_name.trim());
                                                                                        setLocaCodeDrop(false);
                                                                                        setLocaInputClick(false);
                                                                                        setLocaError(false);
                                                                                    }}>{locaCode[i].et_name.trim()}</li>;
                                                                                }))}
                                                                            </ul>
                                                                        </div>
                                                                    </>)}
                                                                </div>
                                                                <div className="custom_input h_40">
                                                                    <input type="text" readOnly value={locaInput || ""} />
                                                                </div>
                                                            </div>
                                                            {locaError && 
                                                                <div className="alert_txt f_12">산지코드를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>총 운송비</th>
                                                        <td>
                                                            <div className="custom_input h_40 txt_input">
                                                                <NumericFormat thousandSeparator="," decimalScale={0}
                                                                    className="tx_r"
                                                                    name="woTot"
                                                                    value={woTot}  onChange={handleChange}
                                                                />
                                                                <span className="txt">원</span>
                                                            </div>
                                                            {woTotError &&
                                                                <div className="alert_txt f_12">
                                                                    숫자로만 입력해주세요.
                                                                </div>
                                                            }
                                                        </td>
                                                        <th>총 수량</th>
                                                        <td colSpan="3">
                                                            <div className="custom_input h_40 w_33">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} value={values.ip_quan_tot} name="ip_quan_tot" onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors.ip_quan_tot && touched.ip_quan_tot ?
                                                                <div className="alert_txt f_12">
                                                                    숫자로만 입력해주세요.
                                                                </div>
                                                            : null}
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <th>
                                                            <span>* </span>배송가능기간
                                                        </th>
                                                        <td colSpan="5">
                                                            <div className="date_input">
                                                                <InputDatepicker selectedDate={dStartDate} ChangeHandler={dStartChange} minDate={new Date()} />
                                                                <InputDatepicker selectedDate={dEndDate} ChangeHandler={dEndChange} minDate={new Date()} />
                                                            </div>
                                                            {dateError && 
                                                                <div className="flex">
                                                                    <div className="alert_txt f_12">배송가능기간을 입력해주세요.</div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="clearfix2 tp16">
                                            <div>
                                                {ipStatus == 1 && <>
                                                    <p className="alert_txt2 bp5">(<span>*</span>)마크가 표시된 항목은 필수 입력 항목입니다.</p>
                                                    <p className="alert_txt">※ 필수 입력항목을 확인해 주세요.</p>
                                                </>}
                                            </div>
                                            <div className="flex_end">
                                                <button className={`${registration ? "btn_type4" : "btn_type"} w_180`} type="submit" onClick={() => {
                                                    if(locaCodeInput.length == 0 || locaInput.length == 0){
                                                        setLocaError(true);
                                                    }if(dStartDate.length == 0 || dEndDate.length == 0){
                                                        setDateError(true);
                                                    }
                                                }}>
                                                    {registration ? "송품장변경(저장)" : "송품장 생성"}
                                                </button>
                                                {registration && ipStatus == 1 &&
                                                    <button className="btn_type lm10" type="button" onClick={()=>{
                                                        if(proList.length > 0){
                                                            dispatch(confirmPop({
                                                                confirmPopTit:'알림',
                                                                confirmPopTxt:'입하신청을 하시겠습니까?',
                                                                confirmPopBtn:2,
                                                            }));
                                                            setInvoApprovalConfirm(true);
                                                        }else{
                                                            dispatch(confirmPop({
                                                                confirmPopTit:'알림',
                                                                confirmPopTxt:'물품은 1개 이상 추가해야 합니다.',
                                                                confirmPopBtn:1,
                                                            }));
                                                            setConfirm(true);
                                                        }
                                                    }}>입하신청</button>
                                                }
                                                {registration && ipStatus != 1 &&
                                                    <button className="btn_type lm10" type="button" onClick={()=>{
                                                        dispatch(confirmPop({
                                                            confirmPopTit:'알림',
                                                            confirmPopTxt:'입하신청취소를 하시겠습니까?',
                                                            confirmPopBtn:2,
                                                        }));
                                                        setInvoApprovalCancelConfirm(true);
                                                    }}>입하신청 취소</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                        {registration && (
                            <div className="inner_box line_top">
                                <div className="flex_between bp20">
                                    <p className="medium">등록된 물품 : {proList.length}건</p>
                                    <div className="flex">
                                        {ipStatus == 1 ?
                                            <button className="btn_type4 flex_center f_15 rm10" onClick={() => {dispatch(prevProductPop(true))}}>
                                                <img src={ic_get} alt="아이콘" />
                                                이전 출하상품 가져오기
                                            </button>
                                            :<button className="btn_type4 flex_center f_15 rm10" onClick={() => {
                                                dispatch(confirmPop({
                                                    confirmPopTit:'알림',
                                                    confirmPopTxt:'현재 입하신청상태로 물품추가가 불가합니다.',
                                                    confirmPopBtn:1,
                                                }));
                                                setConfirm(true);
                                            }}>
                                                <img src={ic_get} alt="아이콘" />
                                                이전 출하상품 가져오기
                                            </button>
                                        }
                                        {ipStatus == 1 ?
                                            <button className="btn_type4 flex_center f_15" onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(newProductPop());
                                            }}>
                                                <img src={ic_plus} alt="아이콘" />
                                                물품추가등록
                                            </button>
                                            :<button className="btn_type4 flex_center f_15" onClick={() => {
                                                dispatch(confirmPop({
                                                    confirmPopTit:'알림',
                                                    confirmPopTxt:'현재 입하신청상태로 물품추가가 불가합니다.',
                                                    confirmPopBtn:1,
                                                }));
                                                setConfirm(true);
                                            }}>
                                                <img src={ic_plus} alt="아이콘" />
                                                물품추가등록
                                            </button>
                                        }
                                    </div>
                                </div>

                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_pro">
                                        <p>등록된 물품이 없습니다. <br /><span>‘물품추가’</span>또는 <span>‘이전 출하상품 가져오기’</span> 버튼을 눌러 등록해 주세요.</p>
                                    </div>
                                )}
                                
                                {/* 등록물품 있을때 ▼ */}
                                {proList.length > 0 && proList.map((a,i) => {
                                    return (
                                        <div key={i} className="bm30">
                                            <ProductInfo 
                                                deFarmName={proList[i].de_farm_name}
                                                deGodsName={proList[i].gm_name}
                                                deWeight={proList[i].de_weight}
                                                dePacking={proList[i].de_packing_name}
                                                deQuality={proList[i].de_quality_name}
                                                deHigh={proList[i].de_high_name}
                                                deQuantity={proList[i].de_quantity}
                                                deCost2={proList[i].de_cost2}
                                                deDansu={proList[i].de_dansu}
                                                deHopePri={proList[i].de_hope_pri}
                                                deCont1={proList[i].de_cont1}
                                                deCont2={proList[i].de_cont2}
                                                deCont3={proList[i].de_cont3}
                                                deImg1={proList[i].de_img1 || ""}
                                                deImg2={proList[i].de_img2 || ""}
                                                deVideo={proList[i].de_video || ""}
                                            />
                                            {ipStatus == 1 &&
                                                <div className="tp10 flex_end">
                                                    <button className="btn3_s rm8" onClick={()=> {
                                                        dispatch(newProductPop());
                                                        dispatch(productPopModify({
                                                            deDate:proList[i].de_date_online,
                                                            deNo:proList[i].de_no_online,
                                                            deSeq:proList[i].de_seq,
                                                            deSeq2:proList[i].de_seq2,
                                                            deFarmName:proList[i].de_farm_name,
                                                            deFarmCode:proList[i].de_farm_code,
                                                            deGodsCode:proList[i].de_gods_code,
                                                            deGodsName:proList[i].gm_name,
                                                            deWeight:proList[i].de_weight,
                                                            dePacking:proList[i].de_packing_name,
                                                            dePackingCode:proList[i].de_packing,
                                                            deQuality:proList[i].de_quality_name,
                                                            deQualityCode:proList[i].de_quality,
                                                            deHigh:proList[i].de_high_name,
                                                            deHighCode:proList[i].de_high,
                                                            deQuantity:proList[i].de_quantity,
                                                            deCost2:proList[i].de_cost2,
                                                            deDansu:proList[i].de_dansu,
                                                            deHopePri:proList[i].de_hope_pri,
                                                            deCont1:proList[i].de_cont1,
                                                            deCont2:proList[i].de_cont2,
                                                            deCont3:proList[i].de_cont3,
                                                            deImg1:proList[i].de_img1,
                                                            deImg2:proList[i].de_img2,
                                                            deVideo:proList[i].de_video,
                                                        }));
                                                    }}>수정</button>
                                                    <button className="btn3_s" onClick={() => {
                                                        dispatch(productDelt({
                                                            deDate:proList[i].de_date_online,
                                                            deNo:proList[i].de_no_online,
                                                            deSeq:proList[i].de_seq,
                                                            deSeq2:proList[i].de_seq2,
                                                        }));
                                                        dispatch(confirmPop({
                                                            confirmPopTit:'알림',
                                                            confirmPopTxt:'물품을 삭제하시겠습니까?',
                                                            confirmPopBtn:2,
                                                            confirmPopBtnBlack:true,
                                                        }))
                                                        setProDeltConfirm(true);
                                                    }}>삭제</button>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>         
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <div className="cont2">
                    <p className="medium f_16 bp10 tp20">기본정보</p>
                </div>
                <Formik
                    initialValues={{
                        ip_s_kind: "",
                        ip_kpp: "",
                        ip_kcp: "",
                        ip_kind: "",
                        ip_quan_tot: "0",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                    {({values, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="custom_table2">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "30%" }} />
                                        <col style={{ width: "70%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span>* </span>매매구분
                                            </th>
                                            <td>
                                                <div className="custom_select">
                                                    <select 
                                                        name="ip_s_kind"
                                                        value={values.ip_s_kind}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ display: 'block' }}
                                                    >
                                                        <option value="" hidden>매매구분</option>
                                                        <option value="ip_s_kind_1">입찰</option>
                                                        <option value="ip_s_kind_2">경매</option>
                                                    </select>
                                                </div>
                                                {errors.ip_s_kind && touched.ip_s_kind ?
                                                    <div className="alert_txt f_12">
                                                        매매구분을 선택해주세요.
                                                    </div>
                                                : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>팰릿</th>
                                            <td>
                                                <div className="custom_input txt_input">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kpp} name="ip_kpp" onChange={handleChange} className="tx_r"/>
                                                    <span className="txt">개</span>
                                                </div>
                                                {errors.ip_kpp && touched.ip_kpp ?
                                                    <div className="alert_txt f_12">
                                                        숫자로만 입력해주세요.
                                                    </div>
                                                : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>목재(옥타곤)</th>
                                            <td>
                                                <div className="custom_input txt_input">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={values.ip_kcp} name="ip_kcp" onChange={handleChange} className="tx_r"/>
                                                    <span className="txt">개</span>
                                                </div>
                                                {errors.ip_kcp && touched.ip_kcp ?
                                                    <div className="alert_txt f_12">
                                                        숫자로만 입력해주세요.
                                                    </div>
                                                : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>* </span>판매구분
                                            </th>
                                            <td>
                                                <div className="custom_select">
                                                    <select 
                                                        name="ip_kind"
                                                        value={values.ip_kind || 'ip_kind_1'}
                                                        onChange={(e)=>{
                                                            handleChange(e);
                                                            let regex = /[^0-9]/g;
                                                            let value = e.currentTarget.value.replace(regex,'');
                                                            setIpKind(value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        style={{ display: 'block' }}
                                                    >
                                                        <option value="ip_kind_1">국내</option>
                                                        <option value="ip_kind_2">수입</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>* </span>산지
                                            </th>
                                            <td>
                                                <div className="drop_input_box">
                                                    <div className="drop_wrap">
                                                        <div className="custom_input">
                                                            <input type="text" value={locaCodeInput || ""} 
                                                                onFocus={()=> {setLocaInputClick(true)}} 
                                                                onBlur={() => {
                                                                    setLocaInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setLocaCodeDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setLocaCodeInput(e.target.value);
                                                                }} 
                                                            />
                                                        </div>
                                                        {LocaCodeDrop && (<>
                                                            <div className="drop_box">    
                                                                <ul className="list_ul">
                                                                    {locaCode.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                    {locaCode.length > 0 && (locaCode.map((a,i) => {
                                                                        return <li key={i}  onClick={(e) => {
                                                                            setLocaCodeInput(locaCode[i].et_code.trim());
                                                                            setLocaInput(locaCode[i].et_name.trim());
                                                                            setLocaCodeDrop(false);
                                                                            setLocaInputClick(false);
                                                                            setLocaError(false);
                                                                        }}>{locaCode[i].et_name.trim()}</li>;
                                                                    }))}
                                                                </ul>
                                                            </div>
                                                        </>)}
                                                    </div>
                                                    <div className="custom_input">
                                                        <input type="text" readOnly value={locaInput || ""} />
                                                    </div>
                                                </div>
                                                {locaError && 
                                                    <div className="alert_txt f_12">산지코드를 입력해주세요.</div>
                                                }
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <th>총 운송비</th>
                                            <td>
                                                <div className="custom_input h_40 txt_input">
                                                <NumericFormat thousandSeparator="," decimalScale={0}
                                                        className="tx_r"
                                                        name="woTot"
                                                        value={woTot}  onChange={handleChange}
                                                    />
                                                    <span className="txt">원</span>
                                                </div>
                                                {woTotError &&
                                                    <div className="alert_txt f_12">
                                                        숫자로만 입력해주세요.
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>총 수량</th>
                                            <td colSpan="3">
                                                <div className="custom_input">
                                                <NumericFormat thousandSeparator="," decimalScale={0} value={values.ip_quan_tot} name="ip_quan_tot" onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.ip_quan_tot && touched.ip_quan_tot ?
                                                    <div className="alert_txt f_12">
                                                        숫자로만 입력해주세요.
                                                    </div>
                                                : null}
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <th>
                                                <span>* </span>배송가능기간
                                            </th>
                                            <td>
                                                <div className="date_input">
                                                    <InputDatepicker selectedDate={dStartDate} ChangeHandler={dStartChange} minDate={new Date()}/>
                                                    <InputDatepicker selectedDate={dEndDate} ChangeHandler={dEndChange} minDate={new Date()}/>
                                                </div>
                                                {dateError && 
                                                    <div className="flex">
                                                        <div className="alert_txt f_12">배송가능기간을 입력해주세요.</div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="cont2">
                                {ipStatus == 1 && 
                                    <div className="tp10">
                                        <p className="alert_txt2 bp5 f_12">※ 새로운 송품장 입력 모드 입니다. (<span>*</span>)마크가 표시된 항목은 필수 입력 항목입니다.</p>
                                        <p className="alert_txt f_12">※ 필수 입력항목을 확인해 주세요.</p>
                                    </div>
                                }
                                <div className="bm20 tp20">
                                    <button className={`${registration ? "btn_type4" : "btn_type"} w_100`} type="submit" onClick={() => {
                                        if(locaCodeInput.length == 0 || locaInput.length == 0){
                                            setLocaError(true);
                                        }if(dStartDate.length == 0 || dEndDate.length == 0){
                                            setDateError(true);
                                        }
                                    }}>
                                        {registration ? "송품장변경(저장)" : "송품장 생성"}
                                    </button>
                                    {registration && ipStatus == 1 &&
                                        <button className="btn_type w_100 tm10" type="button" onClick={()=>{
                                            if(proList.length > 0){
                                                dispatch(confirmPop({
                                                    confirmPopTit:'알림',
                                                    confirmPopTxt:'입하신청을 하시겠습니까?',
                                                    confirmPopBtn:2,
                                                }));
                                                setInvoApprovalConfirm(true);
                                            }else{
                                                dispatch(confirmPop({
                                                    confirmPopTit:'알림',
                                                    confirmPopTxt:'물품은 1개 이상 추가해야 합니다.',
                                                    confirmPopBtn:1,
                                                }));
                                                setConfirm(true);
                                            }
                                        }}>입하신청</button>
                                    }
                                    {registration && ipStatus != 1 &&
                                        <button className="btn_type w_100 tm10" type="button" onClick={()=>{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'입하신청취소를 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setInvoApprovalCancelConfirm(true);
                                        }}>입하신청 취소</button>
                                    }
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                {registration && (<>
                    <div className="cont2">
                        <div className="flex_between bp40">
                            <div className="btn_half_box">
                                {ipStatus == 1 ?
                                    <button className="btn_type4 flex_center f_13" onClick={() => {dispatch(prevProductPop(true))}}>
                                        이전 출하상품 가져오기
                                    </button>
                                    :<button className="btn_type4 flex_center f_13" onClick={() => {
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'현재 입하신청상태로 물품추가가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }}>
                                        이전 출하상품 가져오기
                                    </button>
                                }
                                {ipStatus == 1 ?
                                    <button className="btn_type4 flex_center f_13" onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(newProductPop());
                                    }}>
                                        물품추가등록
                                    </button>
                                    :<button className="btn_type4 flex_center f_13" onClick={() => {
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'현재 입하신청상태로 물품추가가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }}>
                                        물품추가등록
                                    </button>
                                }
                            </div>
                        </div>
                        <p className="medium bp10">등록된 물품 : <span className="txt_sky">{proList.length}</span>건</p>
                    </div>

                    {/* 등록물품 없을때 ▼ */}
                    {proList.length === 0 && (
                        <div className="none_pro f_13">
                            <p>등록된 물품이 없습니다. <br /><span>‘물품추가’</span>또는 <span>‘이전 출하상품 가져오기’</span> 버튼을 눌러 <br/>등록해 주세요.</p>
                        </div>
                    )}
                    
                    {/* 등록물품 있을때 ▼ */}
                    {proList.length > 0 && proList.map((a,i) => {
                        return (
                            <div key={i} className="bm30">
                                <ProductInfo 
                                    deFarmName={proList[i].de_farm_name}
                                    deFarmCode={proList[i].de_farm_code.trim()}
                                    deGodsName={proList[i].gm_name}
                                    deWeight={proList[i].de_weight}
                                    dePacking={proList[i].de_packing_name}
                                    deQuality={proList[i].de_quality_name}
                                    deHigh={proList[i].de_high_name}
                                    deQuantity={proList[i].de_quantity}
                                    deCost2={proList[i].de_cost2}
                                    deDansu={proList[i].de_dansu}
                                    deHopePri={proList[i].de_hope_pri}
                                    deCont1={proList[i].de_cont1}
                                    deCont2={proList[i].de_cont2}
                                    deCont3={proList[i].de_cont3}
                                    deImg1={proList[i].de_img1 || ""}
                                    deImg2={proList[i].de_img2 || ""}
                                    deVideo={proList[i].de_video || ""}
                                />
                                {ipStatus == 1 &&
                                    <div className="tp10 flex_end rp10">
                                        <button className="btn3_s rm8" onClick={()=> {
                                            dispatch(newProductPop());
                                            dispatch(productPopModify({
                                                deDate:proList[i].de_date_online,
                                                deNo:proList[i].de_no_online,
                                                deSeq:proList[i].de_seq,
                                                deSeq2:proList[i].de_seq2,
                                                deFarmName:proList[i].de_farm_name,
                                                deFarmCode:proList[i].de_farm_code,
                                                deGodsCode:proList[i].de_gods_code,
                                                deGodsName:proList[i].gm_name,
                                                deWeight:proList[i].de_weight,
                                                dePacking:proList[i].de_packing_name,
                                                dePackingCode:proList[i].de_packing,
                                                deQuality:proList[i].de_quality_name,
                                                deQualityCode:proList[i].de_quality,
                                                deHigh:proList[i].de_high_name,
                                                deHighCode:proList[i].de_high,
                                                deQuantity:proList[i].de_quantity,
                                                deCost2:proList[i].de_cost2,
                                                deDansu:proList[i].de_dansu,
                                                deHopePri:proList[i].de_hope_pri,
                                                deCont1:proList[i].de_cont1,
                                                deCont2:proList[i].de_cont2,
                                                deCont3:proList[i].de_cont3,
                                                deImg1:proList[i].de_img1,
                                                deImg2:proList[i].de_img2,
                                                deVideo:proList[i].de_video,
                                            }));
                                        }}>수정</button>
                                        <button className="btn3_s" onClick={() => {
                                            dispatch(productDelt({
                                                deDate:proList[i].de_date_online,
                                                deNo:proList[i].de_no_online,
                                                deSeq:proList[i].de_seq,
                                                deSeq2:proList[i].de_seq2,
                                            }));
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품을 삭제하시겠습니까?',
                                                confirmPopBtn:2,
                                                confirmPopBtnBlack:true,
                                            }))
                                            setProDeltConfirm(true);
                                        }}>삭제</button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                    
                </>)}
            </div>
        }

        {/* 송품장 입하신청 confirm팝업 */}
        {invoApprovalConfirm && <ConfirmPop onClickHandler={invoApproval} />}

        {/* 송품장 입하신청취소 confirm팝업 */}
        {invoApprovalCancelConfirm && <ConfirmPop onClickHandler={invoApprovalCancel} />}

        {/* 등록된물품 삭제 confirm팝업 */}
        {proDeltConfirm && <ConfirmPop onClickHandler={proDeltHandler} />}   

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default InvoiceRegister;
