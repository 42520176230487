import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser, isMobile } from "react-device-detect";
import axios from "axios";
import { Formik } from "formik";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import { addressAddPop, confirmPop, addressRefresh, addressInfo } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";
import DaumPost from "../DaumPost";

const AddressAddPop = () => {
    const m_deli = enum_api_uri.m_deli;
    const m_deli_edit = enum_api_uri.m_deli_edit;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);
    const [hphone, setHphone] = useState("");
    const [addressInput, setAddressInput] = useState('');
    const [addressInput2, setAddressInput2] = useState('');
    const [errorReceiver, setErrorReceiver] = useState(false);
    const [errorHphone, setErrorHphone] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [deliInfo, setDeliInfo] = useState({});

    const closePopHandler = () => {
        dispatch(addressAddPop({addressAddPop:false}))
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
            setSaveConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setDeliInfo(deliInfo);
    },[deliInfo]);

    // 주소찾기
    const [enroll_company, setEnroll_company] = useState({
        address:"",zonecode:""
    });

    // 다음주소팝업
    const [daumPop, setDaumPop] = useState(false);

    useEffect(()=>{
        setAddressInput(enroll_company.zonecode);
        setAddressInput2(enroll_company.address);
    },[enroll_company]);

    const handleComplete = (e) => {
        e.preventDefault();
        setDaumPop(!daumPop);
    }

    //배송지 수정일때 정보뿌려주기
    useEffect(()=>{
        if(popup.addressEdit){
            setHphone(popup.addressInfo.deli_rece_tel);
            setAddressInput(popup.addressInfo.deli_rece_post);
            setAddressInput2(popup.addressInfo.deli_rece_addr1);
        }
    },[popup.addressEdit]);

    //배송지 저장
    const saveClickHandler = (values) => {
        if(values.receiver.length == 0){
            setErrorReceiver(true);
        }else{
            setErrorReceiver(false);
        }
        if(hphone.length == 0){
            setErrorHphone(true);
        }else{
            setErrorHphone(false);
        }
        if(addressInput.trim().length == 0){
            setErrorAddress(true);
        }else{
            setErrorAddress(false);
        }
        if(values.receiver.length > 0 && hphone.length > 0 && addressInput.trim().length > 0){
            setErrorReceiver(false);
            setErrorHphone(false);
            setErrorAddress(false);
            setDeliInfo({...values});
            setSaveConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'배송지정보를 저장하시겠습니까?',
                confirmPopBtn:2,
            }));
        }
    };

    //배송지 저장하기
    const saveHandler = () => {
        let body = {
            deli_rece_nm:deliInfo.receiver,
            deli_rece_tel:hphone,
            deli_rece_post:addressInput,
            deli_rece_addr1:addressInput2,
            deli_rece_addr2:deliInfo.address2,
        };
        // 수정일때
        if(popup.addressEdit){
            axios.put(`${m_deli_edit.replace(":idx",popup.addressInfo.idx)}`, body,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(addressRefresh(true));
                    dispatch(addressAddPop({addressAddPop:false, addressEdit:false}));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //새로 등록일때
        else{
            axios.post(`${m_deli}`, body,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(addressRefresh(true));
                    dispatch(addressAddPop({addressAddPop:false, addressEdit:false}));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="pop_cont pop_cont3">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">배송지 등록</p>
                    </div>

                    <div className="custom_table t_border2">
                        <Formik
                            initialValues={{
                                receiver: popup.addressInfo.deli_rece_nm || "", //수령인
                                //hphone: "", //연락처
                                //zonecode: "", //배송지 - 우편번호
                                //address: "", //배송지 - 주소1
                                address2: popup.addressInfo.deli_rece_addr2 || "", //배송지 - 주소2
                            }}
                            validateOnMount={true}
                        >
                            {({values, handleSubmit, handleChange, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "auto" }} />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "auto" }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th className="tx_l">수령자명</th>
                                                <td>
                                                    <div className="custom_input2">
                                                        <input type="text" value={values.receiver}  name ="receiver"  onChange={handleChange}
                                                            onKeyDown={(e)=>{
                                                                if(e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    saveClickHandler(values);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {errorReceiver &&
                                                        <div className="alert_txt f_12 tx_l">수령자명을 입력해주세요.</div>
                                                    }
                                                </td>
                                                <th className="tx_l">연락처</th>
                                                <td>
                                                    <div className="custom_input2">
                                                        <input type="text" value={hphone} maxLength="13" 
                                                            onChange={(e)=>{
                                                                setHphone(CF.MakePhoneForm(e.currentTarget.value));
                                                            }}
                                                            onKeyDown={(e)=>{
                                                                if(e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    saveClickHandler(values);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {errorHphone &&
                                                        <div className="alert_txt f_12 tx_l">연락처를 입력해주세요.</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">배송지</th>
                                                <td colSpan={3}>
                                                    <div className="flex_between address_box">
                                                        <div className="flex">
                                                            <div className="custom_input2 w_100px rm5">
                                                                <input type="text" value={enroll_company.zonecode || addressInput} name="zipcode" onChange={(e) => {
                                                                    handleChange(e);
                                                                    setAddressInput(e.currentTarget.value);
                                                                }} readOnly />
                                                            </div>
                                                            <button className="btn_s3"  onClick={handleComplete}>우편번호 검색</button>
                                                        </div>
                                                        <div className="custom_input2">
                                                            <input type="text"  value={enroll_company.address || addressInput2} name="address" onChange={(e) => {
                                                                    handleChange(e);
                                                                    setAddressInput2(e.currentTarget.value);
                                                                }} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="custom_input2 tm10">
                                                        <input type="text" name = "address2" value={values.address2}  onChange={handleChange}
                                                            onKeyDown={(e)=>{
                                                                if(e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    saveClickHandler(values);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {errorAddress &&
                                                        <div className="alert_txt f_12 tx_l">배송지를 입력해주세요.</div>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="flex_center tp30 bp10">
                                        <button className="btn_type" type="button" onClick={()=>{
                                            saveClickHandler(values);
                                        }}>저장</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
                {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">배송지 등록</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="custom_table t_border2">
                        <Formik
                            initialValues={{
                                receiver: popup.addressInfo.deli_rece_nm || "", //수령인
                                //hphone: "", //연락처
                                //zonecode: "", //배송지 - 우편번호
                                //address: "", //배송지 - 주소1
                                address2: popup.addressInfo.deli_rece_addr2 || "", //배송지 - 주소2
                            }}
                            validateOnMount={true}
                        >
                            {({values, handleSubmit, handleChange, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "80px" }} />
                                            <col style={{ width: "auto" }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th className="tx_l">수령자명</th>
                                                <td>
                                                    <div className="custom_input2">
                                                        <input type="text" value={values.receiver}  name ="receiver"  onChange={handleChange}/>
                                                    </div>
                                                    {errorReceiver &&
                                                        <div className="alert_txt f_12 tx_l">수령자명을 입력해주세요.</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">연락처</th>
                                                <td>
                                                    <div className="custom_input2">
                                                        <input type="text" value={hphone} maxLength="13" onChange={(e)=>{
                                                            setHphone(CF.MakePhoneForm(e.currentTarget.value));
                                                        }}/>
                                                    </div>
                                                    {errorHphone &&
                                                        <div className="alert_txt f_12 tx_l">연락처를 입력해주세요.</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">배송지</th>
                                                <td>
                                                    <div className="flex">
                                                        <div className="custom_input2 w_100px rm5">
                                                            <input type="text" value={enroll_company.zonecode || addressInput} name="zipcode" onChange={(e) => {
                                                                handleChange(e);
                                                                setAddressInput(e.currentTarget.value);
                                                            }} readOnly />
                                                        </div>
                                                        <button className="btn_s3"  onClick={handleComplete}>우편번호 검색</button>
                                                    </div>
                                                    <div className="custom_input2 tm5">
                                                        <input type="text"  value={enroll_company.address || addressInput2} name="address" onChange={(e) => {
                                                            handleChange(e);
                                                            setAddressInput2(e.currentTarget.value);
                                                        }} readOnly />
                                                    </div>
                                                    <div className="custom_input2 tm10">
                                                        <input type="text" name = "address2" value={values.address2}  onChange={handleChange}/>
                                                    </div>
                                                    {errorAddress &&
                                                        <div className="alert_txt f_12 tx_l">배송지를 입력해주세요.</div>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="flex_center tp30 bp10">
                                        <button className="btn_type" type="button" onClick={()=>{
                                            saveClickHandler(values);
                                        }}>저장</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
                {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
            </div>
        }

        {/* 배송지저장 confirm팝업 */}
        {saveConfirm && <ConfirmPop onClickHandler={saveHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default AddressAddPop;