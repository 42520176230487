import { useSelector, useDispatch } from "react-redux";
import { closePop } from "../../../../store/popupSlice";
import { isBrowser, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import history from "../../../../config/history";

const ConfirmPop = (props) => {

    const popup = useSelector((state) => state.popup);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const closePopHandler = () => {

        //창 리로드 <ConfirmPop wReload = "reload"/> 형식으로 쓰면 현재 창이 리로드 된다.
        if(props.wReload == 'reload'){
            window.location.reload();    //리로드!리로드!        
        }

        //창 이동 <ConfirmPop goBack = {2}/> 형식으로 쓰면 숫자만큼 확인후 뒤로감
        if(props.goBack){
            // navigate(-props.goBack); // 돌아가자..
            history.back(-props.goBack);
        }

        //현재 팝업만 닫기 <ConfirmPop closePop = "this"/> 형식으로 쓰면 현재팝업만 닫기
        if(props.closePop == 'this'){
            props.onCloseHandler();
        }

        if(!props.wReload && !props.goBack && !props.closePop){
            dispatch(closePop());
        }
    }

    return (<>
        {popup.confirmPop && <>
            {/* PC일때 */}
            {isBrowser && 
                <div className="pop_wrap">
                    <div className="dim"></div>
                    <div className="pop_cont pop_cont2">
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                        <div className="pop_tit">
                            <p className="tit">{popup.confirmPopTit}</p>
                        </div>
                        <div className="t_border tx_c">
                            <p className="f_17 medium tp20 keep">{popup.confirmPopTxt}</p>
                            <p className="f_13 txt_gray keep">{popup.confirmPopTxt2}</p>
                            {popup.confirmPopBtn === 1 &&
                                <div className="flex_center tp30 bp10">
                                    <button className="btn_type4" onClick={() => {
                                        closePopHandler();
                                    }}>확인</button>
                                </div>
                            }
                            {popup.confirmPopBtn === 2 &&
                                <div className="flex_center tp40 bp10">
                                    <button 
                                        className={`${popup.confirmPopBtnBlack === true ? 'btn_type3' : 'btn_type'} rm10`} 
                                        onClick={() => {
                                            props.onClickHandler();
                                            closePopHandler();
                                        }}
                                    >
                                        확인
                                    </button>
                                    <button className="btn_type4" onClick={() => {
                                        closePopHandler();
                                    }}>취소</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {/* 모바일일때 */}
            {isMobile && 
                <div className="pop_wrap">
                    <div className="dim"></div>
                    <div className="mo_pop_cont small_pop">
                        <div className="mo_pop_tit">
                            <p className="tit">{popup.confirmPopTit}</p>
                            <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                        </div>
                        <div className="t_border tx_c">
                            <p className="f_14 medium tp25">{popup.confirmPopTxt}</p>
                            <p className="f_13 txt_gray">{popup.confirmPopTxt2}</p>
                            {popup.confirmPopBtn === 1 &&
                                <div className="tp25">
                                    <button className="btn_type4 w_100" onClick={() => {
                                        closePopHandler();
                                    }}>확인</button>
                                </div>
                            }
                            {popup.confirmPopBtn === 2 &&
                                <div className="btn_half_box tp25">
                                    <button 
                                        className={`${popup.confirmPopBtnBlack === true ? 'btn_type3' : 'btn_type'} rm10`} 
                                        onClick={() => {
                                            props.onClickHandler();
                                            closePopHandler();
                                        }}
                                    >
                                        확인
                                    </button>
                                    <button className="btn_type4" onClick={() => {
                                        closePopHandler();
                                    }}>취소</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>}
    </>);
};

export default ConfirmPop;