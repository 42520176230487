import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { enum_api_uri, enum_de_status } from "../../config/enum";
import moment from "moment";
import * as CF from "../../components/pc/component/Function";
import logo from "../../images/logo_ic.svg";

const Print = () => {
    const token = localStorage.getItem("token");
    const { ip_date_online, ip_no_online } = useParams();
    const m_print = enum_api_uri.m_print;
    const [invoiceData, setInvoiceData] = useState({});
    const [proList, setProList] = useState([]);
    const [deliList, setDeliList] = useState([]);

    useEffect(()=>{
        setInvoiceData(invoiceData);
        console.log(invoiceData);
    },[invoiceData]);

    useEffect(()=>{
        setProList(proList);
        console.log(proList);
    },[proList]);

    useEffect(()=>{
        setDeliList(deliList);
        console.log(deliList);
    },[deliList]);

    //송품장정보 가져오기
    useEffect(()=>{
        axios.get(`${m_print.replace(':ip_date_online',ip_date_online).replace(':ip_no_online',ip_no_online)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                res.data = CF.trimObjectValues(res.data);
                setInvoiceData(res.data);
                setProList(res.data.detltrns);
                const proDeliList = res.data.detltrns.filter(item => item.de_deli_nm && item.de_deli_nm.trim().length > 0);
                setDeliList(proDeliList);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);

    //송품장출력 버튼클릭시 인쇄
    const onClickPrint = () => {
        window.print();
    };

    let sumQuantity = 0;

    return(
        <div className="page_print">
            <p className="top_tit">표준송품장</p>
            <div className="custom_table wide_table">
                <table>
                    <colgroup>
                        <col style={{width:'20%'}} />
                        <col style={{width:'30%'}} />
                        <col style={{width:'20%'}} />
                        <col style={{width:'30%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>출하일</th>
                            <td className="tx_l">{invoiceData && moment(invoiceData.ip_date_online).format("YYYY. MM. DD")}</td>
                            <th>도착일</th>
                            <td className="tx_l">{invoiceData && moment(invoiceData.de_complete_close_dt).format("YYYY. MM. DD")}</td>
                        </tr>
                        <tr>
                            <th>수 신</th>
                            <td className="tx_l">
                                <span className="txt_black">구 리 청 과 ㈜</span>
                                <span className="lp8">귀하</span>
                            </td>
                            <th>발 신</th>
                            <td className="tx_l txt_black">{invoiceData && invoiceData.as_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="gray_box">아래 농수산물 위탁 판매를 의뢰합니다.</div>
            <div className="flex tp10 rp20 bp10 lp20">
                <p className="rm40 medium">{invoiceData && moment(invoiceData.ip_date_online).format("YYYY 년  MM 월  DD 일")}</p>
                <p>성명: {invoiceData && invoiceData.as_name}</p>
            </div>
            <div className="custom_table wide_table t_border2">
                <table>
                    <colgroup>
                        <col style={{width:'30%'}} />
                        <col style={{width:'70%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>거래형태(해당란 ○표)</th>
                            <td className="tx_l">
                                <span className="rp8">매수거래</span>
                                <span className="rp20">(　　　)</span>
                                <span className="rp8">위탁거래</span>
                                <span className="rp20">(　○　)</span>
                                <span className="rp8">중개거래</span>
                                <span>(　　　)</span>
                            </td>
                        </tr>
                        <tr>
                            <th>매매방법(해당란 ○표)</th>
                            <td className="tx_l">
                                <div className="flex_between">
                                    <div>
                                        <span className="rp8">경매•입찰</span>
                                        <span className="rp20">(　　　)</span>
                                        <span className="rp8">정가•수의매매</span>
                                        <span className="rp20">(　○　)</span>
                                    </div>
                                    <span className="f_13">※ 거래형태, 매매방법을 각각 기재</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="custom_table">
                <div className="gray_box">출하내역</div>
                <table className="t_border2">
                    <colgroup>
                        <col style={{width:'55px'}} />
                        <col style={{width:'auto'}} />
                        <col style={{width:'50px'}} />
                        <col style={{width:'auto'}} />
                        <col style={{width:'55px'}} />
                        <col style={{width:'130px'}} />
                        <col style={{width:'80px'}} />
                        <col style={{width:'70px'}} />
                        <col style={{width:'70px'}} />
                        <col style={{width:'70px'}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="tx_c letter_narrow" rowSpan="2">출하자<br/>신고번호</th>
                            <th className="tx_c letter_narrow" rowSpan="2">생산자</th>
                            <th className="tx_c letter_narrow" rowSpan="2">원산지</th>
                            <th className="tx_c letter_narrow" rowSpan="2">품목<br/>종류</th>
                            <th className="tx_c letter_narrow" rowSpan="2">품질<br/>인증번호</th>
                            <th className="tx_c letter_narrow">우수농산물 관리제도<br/>인증번호</th>
                            <th className="tx_c letter_narrow" rowSpan="2">중량</th>
                            <th className="tx_c letter_narrow" rowSpan="2">규격</th>
                            <th className="tx_c letter_narrow" rowSpan="2">등급</th>
                            <th className="tx_c letter_narrow" rowSpan="2">수량</th>
                        </tr>
                        <tr>
                            <th className="tx_c l_border2">이력추적 관리번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proList && proList.map((pro,i)=>{
                            pro = CF.trimObjectValues(pro);
                            sumQuantity = sumQuantity + pro.de_quantity;
                            let status = enum_de_status[pro.de_status][0];
                            return(
                                <tr key={i}>
                                    <td></td>
                                    <td>{pro.de_farm_name}</td>
                                    <td>{pro.de_kind == "1" ? "국내" : pro.de_kind == "2" && "수입"}</td>
                                    <td>{pro.gm_name}</td>
                                    <td></td>
                                    <td className="p0">
                                        <ul className="td_box">
                                            <li></li>
                                            <li>{pro.de_ip_date+pro.de_ip_no+pro.de_seq+pro.de_seq2}<br/>{`(${status})`}</li>
                                        </ul>
                                    </td>
                                    <td className="tx_r">{CF.MakeIntComma(pro.de_weight)}kg</td>
                                    <td>{pro.de_packing_name}</td>
                                    <td>{pro.de_high_name}</td>
                                    <td className="tx_r">{CF.MakeIntComma(pro.de_quantity)}</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <th className="tx_c" colSpan="9">합계</th>
                            <td className="tx_r">{CF.MakeIntComma(sumQuantity)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="custom_table">
                <div className="gray_box">운송자</div>
                <table className="t_border2">
                    <colgroup>
                        <col style={{width:'auto'}} />
                        <col style={{width:'120px'}} />
                        <col style={{width:'120px'}} />
                        <col style={{width:'130px'}} />
                        <col style={{width:'150px'}} />
                        <col style={{width:'90px'}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="tx_c">관리번호</th>
                            <th className="tx_c">기사명</th>
                            <th className="tx_c">운임</th>
                            <th className="tx_c" rowSpan="2">은행명</th>
                            <th className="tx_c" rowSpan="2">계좌번호</th>
                            <th className="tx_c" rowSpan="2">예금주</th>
                        </tr>
                        <tr>
                            <th className="tx_c">품목/종류</th>
                            <th className="tx_c">전화번호</th>
                            <th className="tx_c">차량번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliList && deliList.length === 0 &&
                            <tr>
                                <td colSpan={6}>운송자 정보가 존재하지 않습니다.</td>    
                            </tr>
                        }
                        {deliList && deliList.length > 0 &&
                            deliList.map((pro, i) => {
                            pro = CF.trimObjectValues(pro);
                                if (pro.de_deli_bank_nm && pro.de_deli_bank_nm.length > 0) {
                                    return (
                                        <tr key={i}>
                                            <td className="p0">
                                                <ul className="td_box">
                                                    <li>{pro.de_ip_date+pro.de_ip_no+pro.de_seq+pro.de_seq2}</li>
                                                    <li>{pro.gm_name}</li>
                                                </ul>
                                            </td>
                                            <td className="p0">
                                                <ul className="td_box">
                                                    <li>{pro.de_deli_nm}</li>
                                                    <li>{pro.de_deli_tel && CF.MakePhoneForm(pro.de_deli_tel)}</li>
                                                </ul>
                                            </td>
                                            <td className="p0">
                                                <ul className="td_box">
                                                    <li className="tx_r rp9 lp9">{CF.MakeIntComma(pro.de_cost2)}원</li>
                                                    <li>{pro.de_deli_car_num}</li>
                                                </ul>
                                            </td>
                                            <td>{pro.de_deli_bank_nm}</td>
                                            <td>{pro.de_deli_bank_num}</td>
                                            <td>{pro.de_deli_nm}</td>
                                        </tr>
                                    );
                                }
                            })}
                    </tbody>
                </table>
            </div>
            <div className="custom_table wide_table">
                <div className="gray_box">출하자</div>
                <table className="t_border2">
                    <colgroup>
                        <col style={{width:'14%'}} />
                        <col style={{width:'36%'}} />
                        <col style={{width:'14%'}} />
                        <col style={{width:'36%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="rp0">단체명(성명)</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_name}</td>
                            <th>주소</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_addr1 + invoiceData.as_addr2}</td>
                        </tr>
                        <tr>
                            <th>전 화</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_tel}</td>
                            <th>팩  스</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_fax}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="custom_table wide_table t_border_none">
                <table>
                    <colgroup>
                        <col style={{width:'35%'}} />
                        <col style={{width:'65%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>출하자신고번호 또는 산지유통인 번호</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_ent && invoiceData.as_ent.length > 0 ? invoiceData.as_ent : invoiceData.as_pr_no}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="custom_table wide_table t_border_none">
                <table>
                    <colgroup>
                        <col style={{width:'12%'}} />
                        <col style={{width:'21.333%'}} />
                        <col style={{width:'12%'}} />
                        <col style={{width:'21.333%'}} />
                        <col style={{width:'12%'}} />
                        <col style={{width:'21.333%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>은행명</th>
                            <td className="tx_l">{invoiceData && invoiceData.et_name}</td>
                            <th>계좌번호</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_bankno}</td>
                            <th>예금주</th>
                            <td className="tx_l">{invoiceData && invoiceData.as_bankowner}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="custom_table gray_table">
                <table>
                    <colgroup>
                        <col style={{width:'15%'}} />
                        <col style={{width:'18.333%'}} />
                        <col style={{width:'15%'}} />
                        <col style={{width:'18.333%'}} />
                        <col style={{width:'15%'}} />
                        <col style={{width:'18.333%'}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tx_c txt_gray rp0 lp0">매수 / 중개금액</th>
                            <td className="tx_l"></td>
                            <th className="tx_c txt_gray rp0 lp0">매수대금 지급일</th>
                            <td className="tx_l"></td>
                            <th className="tx_c txt_gray rp0 lp0">중대 상대자</th>
                            <td className="tx_l"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex_between tp20">
                <div className="flex logo">
                    <img src={logo} alt="로고"/>
                    <span className="lp10">구리청과 주식회사</span>
                </div>
                <ul className="flex">
                    <li className="flex rp40">
                        <span className="rp15">전 화</span>
                        <span className="txt_gray">031) 556 - 2000</span>
                    </li>
                    <li className="flex">
                        <span className="rp15">팩 스</span>
                        <span className="txt_gray">031) 551 - 9200</span>
                    </li>
                </ul>
            </div>
            <div className="tp10 flex_end">
                <button className="btn_type3" onClick={onClickPrint}>송품장 출력</button>
            </div>
        </div>
    );
};

export default Print;