import { useDispatch, useSelector } from "react-redux";
import { mediaPop } from "../../../store/popupSlice";
import imgUp from "../../../images/img_upload.svg";
import imgNone from "../../../images/img_none.svg";
import videoUp from "../../../images/video_upload.svg";
import { enum_api_uri } from "../../../config/enum";
import * as CF from "./Function";
import { isMobile, isBrowser } from 'react-device-detect';

const ProductInfo = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state)=>state.user);
    const api_uri = enum_api_uri.api_uri;
    const deImg1 = props.deImg1.replace(/\\/ig,"\\\\");
    const deImg2 = props.deImg2.replace(/\\/ig,"\\\\");
    const deVideo = props.deVideo.replace(/\\/ig,"\\\\");
    let deWeight = CF.MakeIntComma(props.deWeight);
    let deQuantity = CF.MakeIntComma(props.deQuantity);
    let deCost2 = CF.MakeIntComma(props.deCost2);
    let deDansu = CF.MakeIntComma(props.deDansu);
    let deHopePri = CF.MakeIntComma(props.deHopePri);

    return(
        <>
            {/* PC일때 */}
            {isBrowser &&
                <>
                    <div className="custom_table wide_table">
                        <table>
                            <colgroup>
                                <col style={{ width: "9%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "9%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "9%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "9%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "9%" }} />
                                <col style={{ width: "11%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>생산자</th>
                                    <td className="tx_l txt_gray">
                                        {props.deFarmName}
                                    </td>
                                    <th>품명</th>
                                    <td className="tx_l txt_gray" colSpan={`${user.kind === 3 && '3'}`} >
                                        <div className="over_txt"><span>{props.deGodsName}</span></div>
                                    </td>
                                    <th>중량</th>
                                    <td className="tx_r txt_gray">
                                        {deWeight}kg
                                    </td>
                                    <th>규격</th>
                                    <td className="tx_l txt_gray">
                                        {props.dePacking}
                                    </td>
                                    {user.kind !== 3 && (
                                    <>
                                        <th>친환경</th>
                                        <td className="tx_l txt_gray">
                                            {props.deQuality}
                                        </td>
                                    </>)}
                                </tr>
                                <tr>
                                    {user.kind === 3 && (
                                    <>
                                        <th>친환경</th>
                                        <td className="tx_l txt_gray">
                                            {props.deQuality}
                                        </td>
                                    </>)}
                                    <th>등급</th>
                                    <td className="tx_l txt_gray">
                                        {props.deHigh}
                                    </td>
                                    <th>수량</th>
                                    <td className="tx_r txt_gray">
                                        {deQuantity}개
                                    </td>
                                    <th>운임비</th>
                                    <td className="tx_r txt_gray">
                                        {deCost2}원
                                    </td>
                                    <th>단수</th>
                                    <td className="tx_r txt_gray">
                                        {deDansu}단
                                    </td>
                                    {user.kind !== 3 && (
                                    <>
                                        <th className="sky_bg">희망단가</th>
                                        <td className="tx_r txt_gray sky_bg">
                                            {deHopePri}원
                                        </td>
                                    </>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="custom_table wide_table t_border_none">
                        <table>
                            <colgroup>
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "37%" }} />
                                <col style={{ width: "16.666%" }} />
                                <col style={{ width: "16.666%" }} />
                                <col style={{ width: "16.666%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="tx_c">구분</th>
                                    <th className="tx_c">
                                        물품설명
                                    </th>
                                    <th className="tx_c">사진1</th>
                                    <th className="tx_c">사진2</th>
                                    <th className="tx_c">동영상</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>품질</td>
                                    <td className="tx_l">
                                        {props.deCont1}
                                    </td>
                                    <td rowSpan="3">
                                        {props.deImg1 === "" ? 
                                            <img src={imgNone} alt="이미지" className="w_49px"/>
                                            :   <div className="thumb_box" onClick={() => {
                                                    dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${api_uri}/${deImg1}`}))
                                                }}>
                                                    <img src={`${api_uri}/${deImg1}`} alt="이미지"/>
                                                </div>
                                        }
                                    </td>
                                    <td rowSpan="3">
                                        {props.deImg2 === "" ? 
                                            <img src={imgNone} alt="이미지" className="w_49px"/>
                                            :   <div className="thumb_box" onClick={() => {
                                                    dispatch(mediaPop({mediaPop:true,mediaTit:'사진2',mediaSrc:`${api_uri}/${deImg2}`}))
                                                }}>
                                                    <img src={`${api_uri}/${deImg2}`} alt="이미지"/>
                                                </div>
                                        }
                                    </td>
                                    <td rowSpan="3">
                                        {props.deVideo === "" ? 
                                            <img src={videoUp} alt="이미지"/>
                                            :   <div className="thumb_box" onClick={() => {
                                                    dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${api_uri}/${deVideo}`}))
                                                }}>
                                                    <video src={`${api_uri}/${deVideo}`} />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>당도,산도 등</td>
                                    <td className="tx_l">
                                        {props.deCont2}
                                    </td>
                                </tr>
                                <tr>
                                    <td>특이사항</td>
                                    <td className="tx_l">
                                        {props.deCont3}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }

            {/* 모바일일때 */}
            {isMobile &&
                <>
                    <div className="custom_table gray_table">
                        <div className="farm_box">{`${props.deFarmName} (${props.deFarmCode})`}</div>
                        <table>
                            <colgroup>
                                <col style={{ width: "23%" }} />
                                <col style={{ width: "27%" }} />
                                <col style={{ width: "23%" }} />
                                <col style={{ width: "27%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>품명</th>
                                    <td colSpan="3">{props.deGodsName}</td>
                                </tr>
                                <tr>
                                    <th>중량</th>
                                    <td className="tx_r">
                                        {deWeight}kg
                                    </td>
                                    <th>규격</th>
                                    <td>
                                        {props.dePacking}
                                    </td>
                                </tr>
                                <tr>
                                    <th>친환경</th>
                                    <td>
                                        {props.deQuality}
                                    </td>
                                    <th>등급</th>
                                    <td>
                                        {props.deHigh}
                                    </td>
                                </tr>
                                <tr>
                                    <th>수량</th>
                                    <td className="tx_r">
                                        {deQuantity}개
                                    </td>
                                    <th>운임비</th>
                                    <td className="tx_r">
                                        {deCost2}원
                                    </td>
                                </tr>
                                <tr>
                                    <th>단수</th>
                                    <td colSpan="3" className="tx_r">
                                        {deDansu}단
                                    </td>
                                </tr>
                                {user.kind !== 3 &&
                                    <tr>
                                        <th className="sky_bg">희망단가</th>
                                        <td colSpan="3" className="tx_r sky_bg">
                                            {deHopePri}원
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <p className="f_16 medium tp10 lp10 bp10">물품설명</p>
                    <div className="custom_table gray_table">
                        <table>
                            <colgroup>
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "70%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>품질</th>
                                    <td>
                                        {props.deCont1}
                                    </td>
                                </tr>
                                <tr>
                                    <th>당도,산도 등</th>
                                    <td>
                                        {props.deCont2}
                                    </td>
                                </tr>
                                <tr>
                                    <th>특이사항</th>
                                    <td>
                                        {props.deCont3}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ul className="media_ul">
                        <li>
                            {props.deImg1 === "" ? 
                                <img src={imgNone} alt="이미지" className="w_49px"/>
                                :   <div className="thumb_box" onClick={() => {
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${api_uri}/${deImg1}`}))
                                    }}>
                                        <img src={`${api_uri}/${deImg1}`} alt="이미지"/>
                                    </div>
                            }
                        </li>
                        <li>
                            {props.deImg2 === "" ? 
                                <img src={imgNone} alt="이미지" className="w_49px"/>
                                :   <div className="thumb_box" onClick={() => {
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'사진2',mediaSrc:`${api_uri}/${deImg2}`}))
                                    }}>
                                        <img src={`${api_uri}/${deImg2}`} alt="이미지"/>
                                    </div>
                            }
                        </li>
                        <li>
                            {props.deVideo === "" ? 
                                <img src={videoUp} alt="이미지"/>
                                :   <div className="thumb_box" onClick={() => {
                                        dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${api_uri}/${deVideo}`}))
                                    }}>
                                        <video src={`${api_uri}/${deVideo}`} />
                                    </div>
                            }
                        </li>
                    </ul>
                </>
            }
        </>
    );
};

export default ProductInfo;