import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { closePop } from "../../../../store/popupSlice";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import ConfirmPop from "./ConfirmPop";
import { confirmPop, proDivisionOk, proDivisionPop } from "../../../../store/popupSlice";
import { isBrowser, isMobile } from "react-device-detect";

const ProDivisionPop = () => {
    const a_devide_product = enum_api_uri.a_devide_product;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const popup = useSelector((state) => state.popup);
    const [quantity, setQuantity] = useState();
    const [number, setNumber] = useState(1);
    const [changeQuantity, setChangeQuantity] = useState();
    const [changeNumber, setChangeNumber] = useState(1);
    const [confirm, setConfirm] = useState(false);
    const [diviConfirm, setDiviConfirm] = useState(false);

    const closePopHandler = () => {
        dispatch(proDivisionPop(null));
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
            setDiviConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setQuantity(quantity);
    },[quantity]);

    useEffect(()=>{
        setNumber(number);
    },[number]);

    useEffect(()=>{
        let productData = JSON.parse(localStorage.getItem('productData'));
        let de_quantity = productData.de_quantity;
        setQuantity(de_quantity);
        setChangeQuantity(de_quantity);
    },[]);

    //수량계산하기
    const countHandler = () => {
        if(number < quantity){
            setChangeQuantity(quantity - number);
            setChangeNumber(number);
        }if(number >= quantity){
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: '기존 물품의 수량보다 분할되는 물품의 수량이 큽니다.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };

    //수량계산 ConfirmPop 팝업에서 확인하기
    const changeHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let de_ip_date = productData.de_ip_date;
        let de_ip_no = productData.de_ip_no;
        let de_date_online = productData.de_date_online;
        let de_no_online = productData.de_no_online;
        let de_seq = productData.de_seq;
        let de_seq2 = productData.de_seq2;
        let body = {
            de_quantity:number
        };
        axios.put(`${a_devide_product.replace(':de_ip_date', de_ip_date).replace(':de_ip_no', de_ip_no).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                dispatch(proDivisionOk(true));
                setTimeout(() => {
                    dispatch(proDivisionOk(false));
                },100);
                closePopHandler();
            }
        })
        .catch((error) => {
            console.log(`error`);
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //모바일일때 수량올리기버튼
    const upHandler = () => {
        if(number < quantity - 1){
            setNumber(number + 1);
        }
    };

    //모바일일때 수량내리기
    const downHandler = () => {
        if(number > 1){
            setNumber(number - 1);
        }
    };


    return(<>
        {/* PC일때 */}
        {isBrowser && 
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont4">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">물품 분할설정</p>
                        <p className="txt">※ 물품 분할 수량을 입력해 주세요. <br/>※ 원 물품의 수량과 새로 분할되는 수량의 합계가 일치하지 않아도 입력은 가능합니다. <br/>※ 생산자와 협의 후 입력해 주세요.</p>
                    </div>
                    <div className="search_box bp0">
                        <div className="custom_table2 input_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'30%'}}/>
                                    <col style={{width:'auto'}}/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th className="th_gray2">현재 수량</th>
                                        <td>{CF.MakeIntComma(quantity)} 개</td>
                                    </tr>
                                    <tr>
                                        <th className="th_gray2">분할 수량</th>
                                        <td>
                                            <div className="flex">
                                                <div className="custom_input h_35 w_120px">
                                                    <input type="number" min="1" max={quantity} defaultValue="1" onChange={(e)=>{setNumber(e.currentTarget.value)}}/>
                                                </div>
                                                <button className="btn3_s2 lm5 rp10 lp10" onClick={countHandler}>수량계산</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="arrow_down"></div>
                    </div>
                    <div className="custom_table2 input_table tm20">
                        <table>
                            <colgroup>
                                <col style={{width:'30%'}}/>
                                <col style={{width:'auto'}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기존 물품 수량</th>
                                    <td>{CF.MakeIntComma(changeNumber)} 개</td>
                                </tr>
                                <tr>
                                    <th>분할 된 수량</th>
                                    <td>{CF.MakeIntComma(changeQuantity)} 개</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex_center tp15">
                        <button className="btn_type rm10"
                            onClick={()=>{
                                dispatch(confirmPop({
                                    confirmPopTit:'알림',
                                    confirmPopTxt:'분할하시겠습니까?', 
                                    confirmPopBtn:2,
                                }));
                                setDiviConfirm(true);
                            }}
                        >저장</button>
                        <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile && 
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont small_pop">
                    <div className="mo_pop_tit">
                        <p className="tit">물품 분할설정</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <p className="f_13 txt_gray tp15 bp10">※ 물품 분할 수량을 입력해 주세요. <br/>※ 원 물품의 수량과 새로 분할되는 수량의 합계가 일치하지 않아도 입력은 가능합니다. <br/>※ 생산자와 협의 후 입력해 주세요.</p>
                    <div className="custom_table2 input_table">
                        <table>
                            <colgroup>
                                <col style={{width:'30%'}}/>
                                <col style={{width:'auto'}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="th_gray2">현재 수량</th>
                                    <td>{CF.MakeIntComma(quantity)} 개</td>
                                </tr>
                                <tr>
                                    <th className="th_gray2">분할 수량</th>
                                    <td>
                                        <div className="custom_input">
                                            <input type="number" min="1" max={quantity} value={number} onChange={(e)=>{setNumber(e.currentTarget.value)}}/>
                                        </div>
                                        <div className="flex_between num_btn_box tp3">
                                            <div className="flex_between">
                                                <button className="btn_up" onClick={upHandler}>올리기</button>
                                                <button className="btn_down" onClick={downHandler}>내리기</button>
                                            </div>
                                            <button className="btn3_s2" onClick={countHandler}>수량계산</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="custom_table2 input_table tm10">
                        <table>
                            <colgroup>
                                <col style={{width:'38%'}}/>
                                <col style={{width:'auto'}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="rp0">기존 물품 수량</th>
                                    <td>{CF.MakeIntComma(changeNumber)} 개</td>
                                </tr>
                                <tr>
                                    <th className="rp0">분할 된 수량</th>
                                    <td>{CF.MakeIntComma(changeQuantity)} 개</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="btn_half_box tp10">
                        <button className="btn_type rm10"
                            onClick={()=>{
                                dispatch(confirmPop({
                                    confirmPopTit:'알림',
                                    confirmPopTxt:'분할하시겠습니까?', 
                                    confirmPopBtn:2,
                                }));
                                setDiviConfirm(true);
                            }}
                        >저장</button>
                        <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                    </div>
                </div>
            </div>
        }

        {/* 물품분할 confirm팝업 */}   
        {diviConfirm && <ConfirmPop onClickHandler={changeHandler} />}

        {/* confirm팝업 */}   
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}} />}
    </>);
};

export default ProDivisionPop;