import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { PatternFormat } from "react-number-format";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle } from "../../../store/commonSlice";
import { enum_api_uri,enum_ip_status,enum_sell_kind,enum_mo_title } from "../../../config/enum";
import Pagination from "../../../components/pc/component/Pagination";
import * as CF from "../../../components/pc/component/Function";
import { resultDetailPop } from "../../../store/popupSlice";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';

const BidResult = () => {
    const a_auction_result = enum_api_uri.a_auction_result;
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const my_code = localStorage.getItem("auctCode");
    const [ip_date_start, setIpDateStart] = useState('');
    const [ip_date_end, setIpDateEnd] = useState('');
    const [ip_s_kind, setIpSKind] = useState('');
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const [resultList, setResultList] = useState([]);
    const location = useLocation();
    registerLocale('ko', ko);
    const basicDate = new Date();

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    useEffect(()=>{
        setResultList(resultList);
        console.log(resultList);
    },[resultList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
        console.log(viewPaging)
    },[viewPaging]);

    useEffect(()=>{ //처음 한번 리스트 불러오기
        let val = [];
        val.ip_s_kind = "";
        val.ip_date_start  = moment().format('YYYYMMDD');
        val.ip_date_end  =moment().format('YYYYMMDD');
        val.page_no_now = 1
        val.record_size = 10
        
        setIpDateStart(basicDate)
        setIpDateEnd(basicDate)

        setPageNo(1);
        getList(val);
    },[]);

    // 결과리스트 조회
    const submit = (values) => {
        let regex = /[^0-9]/g;
        let ip_s_kind = values.ip_s_kind.replace(regex,'');

        let val = [];
        val.ip_s_kind = ip_s_kind;
        val.ip_date_start = moment(values.ip_date_start).format('YYYYMMDD'); 
        val.ip_date_end = moment(values.ip_date_end).format('YYYYMMDD'); 
        val.page_no_now = 1;
        setPageNo(1);
        getList(val); //리스트 받아오기
    };

    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_s_kind = ip_s_kind;
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = goPage; //움직일 페이지
        setPageNo(goPage);
        getList(val); //리스트 받아오기
    };

    const getList = (val) => {
        //리스팅 날짜 정보를 저장한다.
        val.record_size = perPage;
        setIpDateStart(val.ip_date_start);
        setIpDateEnd(val.ip_date_end);
        setIpSKind(val.ip_s_kind);

        axios.get(`${a_auction_result.replace(':asso_code',my_code)}?ip_date_start=${val.ip_date_start}&ip_date_end=${val.ip_date_end}&ip_s_kind=${val.ip_s_kind}&page_no=${val.page_no_now}&record_size=${val.record_size}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                let state = [...data.results];
                let resCount = data.total_record_count;
                setResultList(state);//리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤
                console.log(resCount)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className="auction_wrap auction_wrap2 bp30">
                    <div className="cont">
                        <Formik
                            initialValues={{
                                ip_date_start: basicDate || "",
                                ip_date_end: basicDate || "",
                                ip_s_kind: "",
                            }}
                            onSubmit={submit}
                        >
                            {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="top_cont flex_between">
                                        <p className="tit">경매/입찰결과</p>
                                        <ul className="flex inner_ul2 inner_ul3">
                                            <li className="rm40">
                                                <div className="date_input2 flex_between">
                                                    <div className="custom_input">
                                                        <DatePicker
                                                            selected={values.ip_date_start}
                                                            dateFormat="yyyy-MM-dd"
                                                            locale="ko"
                                                            name="ip_date_start"
                                                            onChange={date => setFieldValue('ip_date_start', date)}
                                                        />
                                                    </div>
                                                    <div className="custom_input">
                                                        <DatePicker
                                                            selected={values.ip_date_end}
                                                            dateFormat="yyyy-MM-dd"
                                                            locale="ko"
                                                            name="ip_date_end"
                                                            onChange={date => setFieldValue('ip_date_end', date)}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="flex">
                                                <div className="custom_select rm5">
                                                    <select 
                                                        name="ip_s_kind"
                                                        value={values.ip_s_kind}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ display: 'block' }}
                                                    >
                                                        <option value="ip_s_kind">전체</option>
                                                        <option value="ip_s_kind_1">입찰</option>
                                                        <option value="ip_s_kind_2">경매</option>
                                                    </select>
                                                </div>
                                                <button className="btn_s" type="submit">결과조회</button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <div className="tp20">
                            <p className="medium bp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                            {resultList.length == 0 && <div className="none_data"><span>데이터가 없습니다.</span></div>}
                            {resultList.length > 0 &&
                                <div className="custom_table s_table">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'120px'}} />
                                            <col style={{width:'45px'}} />
                                            <col style={{width:'150px'}} />
                                            <col style={{width:'90px'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'80px'}} />
                                            <col style={{width:'70px'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>등록번호</th>
                                                <th>구분</th>
                                                <th>경매/입찰시간</th>
                                                <th>대표 품목명</th>
                                                <th className="l_border th_yellow">입하</th>
                                                <th className="l_border th_blue">승인</th>
                                                <th className="th_blue">미승인</th>
                                                <th className="l_border th_green">대기</th>
                                                <th className="th_green">진행</th>
                                                <th className="th_green">종료</th>
                                                <th className="l_border th_purple">낙찰</th>
                                                <th className="th_purple">유찰</th>
                                                <th className="l_border th_orange">운송<br/>대기</th>
                                                <th className="th_orange">배송</th>
                                                <th className="th_orange">완료</th>
                                                <th className="l_border">상태</th>
                                                <th>보기</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resultList.map((data,i) => {
                                                let ip_s_kind = enum_sell_kind[data.ip_s_kind.trim()];
                                                let ip_auct_date_start = CF.MDFWithHour(data.ip_auct_date_start);
                                                let ip_auct_date_end = CF.MDFWithHour(data.ip_auct_date_end);
                                                let status = data.ip_status.trim();
                                                    status = enum_ip_status[status][0];
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.ip_date_online+'-'+data.ip_no_online}</td>
                                                        <td>{ip_s_kind}</td>
                                                        <td>
                                                            <p>{ip_auct_date_start}</p>
                                                            <p>{ip_auct_date_end}</p>
                                                        </td>
                                                        <td>
                                                            <div className="over_txt"><span>{`${data.gm_name}${data.income_tot > 1 ? '외' : ''}`}</span></div>
                                                        </td>
                                                        <td className='tx_r l_border td_yellow'>{CF.MakeIntComma(data.income_tot)}</td>
                                                        <td className='tx_r l_border td_blue'>{CF.MakeIntComma(data.granted_tot)}</td>
                                                        <td className='tx_r td_blue'>{CF.MakeIntComma(data.denied_tot)}</td>
                                                        <td className='tx_r l_border td_green'>{CF.MakeIntComma(data.process_wait)}</td>
                                                        <td className='tx_r td_green'>{CF.MakeIntComma(data.process_tot)}</td>
                                                        <td className='tx_r td_green'>{CF.MakeIntComma(data.process_close_tot)}</td>
                                                        <td className='tx_r l_border td_purple'>{CF.MakeIntComma(data.success_tot)}</td>
                                                        <td className='tx_r td_purple'>{CF.MakeIntComma(data.fail_tot)}</td>
                                                        <td className='tx_r l_border td_orange'>{CF.MakeIntComma(data.trans_wait_tot)}</td>
                                                        <td className='tx_r td_orange'>{CF.MakeIntComma(data.trans_tot)}</td>
                                                        <td className='tx_r td_orange'>{CF.MakeIntComma(data.close_tot)}</td>
                                                        <td className="l_border">
                                                            <span>{status}</span>
                                                            {data.de_wating_close_tot > 0 && <div className="btn2_s2 tm3">거래체결<span className="txt_sky"> ({data.de_wating_close_tot})</span></div>}
                                                        </td>
                                                        <td>
                                                            <button 
                                                                className="btn2_s"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                    invoiceData = {
                                                                        ip_date_online:data.ip_date_online.trim(),
                                                                        ip_no_online:data.ip_no_online.trim(),
                                                                        ip_s_kind:data.ip_s_kind.trim(),
                                                                        ip_kind:data.ip_kind.trim(),
                                                                        ip_loca:data.ip_loca.trim(),
                                                                    };
                                                                    localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                                    dispatch(resultDetailPop({
                                                                        resultDetailPop:true,
                                                                    }));
                                                                }}
                                                            >
                                                                보기
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        {/* 조회가 없을때 ▼ */}
                        {viewPaging === 0 && (
                            <div className="pagination">
                                <button className="prev">
                                    <span>이전 페이지</span>
                                </button>
                                <ul className="num_box">
                                    <li className="on">
                                        <button>1</button>
                                    </li>
                                </ul>
                                <button className="next">
                                    <span>다음 페이지</span>
                                </button>
                            </div>
                        )}
                        {/* 조회가 페이징보다 클때 ▼ */}
                        {viewPaging > 0 &&
                            <Pagination 
                                nowPage={page_no}
                                totalCnt={viewPaging}
                                setPerPage={perPage}
                                moveToPage={moveToPage}
                            />
                        }
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        ip_date_start: basicDate || "",
                        ip_date_end: basicDate || "",
                        ip_s_kind: "",
                    }}
                    onSubmit={submit}
                >
                    {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="search_box">
                                <ul>
                                    <li>
                                        <p className="txt">신청일자</p>
                                        <div className="date_input">
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={values.ip_date_start}
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="ko"
                                                    name="ip_date_start"
                                                    onChange={date => setFieldValue('ip_date_start', date)}
                                                />
                                            </div>
                                            <div className="custom_input">
                                                <DatePicker
                                                    selected={values.ip_date_end}
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="ko"
                                                    name="ip_date_end"
                                                    onChange={date => setFieldValue('ip_date_end', date)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="txt">구분</p>
                                        <div className="flex_between sel_btn_box">
                                            <div className="custom_select">
                                                <select 
                                                    name="ip_s_kind"
                                                    value={values.ip_s_kind}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ display: 'block' }}
                                                >
                                                    <option value="ip_s_kind">전체</option>
                                                    <option value="ip_s_kind_1">입찰</option>
                                                    <option value="ip_s_kind_2">경매</option>
                                                </select>
                                            </div>
                                            <button className="btn_s2" type="submit">조회</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className="tp20">
                    <p className="medium bp20 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                    {resultList.length == 0 && <div className="none_data"><span>데이터가 없습니다.</span></div>}
                    {resultList.length > 0 && <>
                        {resultList.map((data,i) => {
                            let ip_s_kind = enum_sell_kind[data.ip_s_kind.trim()];
                            let ip_auct_date_start = CF.MDFWithHour(data.ip_auct_date_start);
                            let ip_auct_date_end = CF.MDFWithHour(data.ip_auct_date_end);
                            let status = data.ip_status.trim();
                                status = enum_ip_status[status][0];
                            return (
                                <div className="custom_table gray_table bm20" key={i}>
                                    <table>
                                        <colgroup>
                                            <col style={{width:'24%'}} />
                                            <col style={{width:'26%'}} />
                                            <col style={{width:'24%'}} />
                                            <col style={{width:'26%'}} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>등록번호</th>
                                                <td colSpan="3">
                                                    <span>{data.ip_date_online+'-'+data.ip_no_online}</span>
                                                    <button 
                                                        className="btn_view lm10"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                            invoiceData = {
                                                                ip_date_online:data.ip_date_online.trim(),
                                                                ip_no_online:data.ip_no_online.trim(),
                                                                ip_s_kind:data.ip_s_kind.trim(),
                                                                ip_kind:data.ip_kind.trim(),
                                                                ip_loca:data.ip_loca.trim(),
                                                            };
                                                            localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                            dispatch(resultDetailPop({
                                                                resultDetailPop:true,
                                                            }));
                                                        }}
                                                    >
                                                        보기
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매/입찰<br/>시간</th>
                                                <td colSpan="3">
                                                    <p>{ip_auct_date_start}</p>
                                                    <p>{ip_auct_date_end}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>대표 품목명</th>
                                                <td colSpan="3">{`${data.gm_name}${data.income_tot > 1 ? '외' : ''}`}</td>
                                            </tr>
                                            <tr>
                                                <th>상태</th>
                                                <td>
                                                    <span className="tag">{status}</span>
                                                    {data.de_wating_close_tot > 0 && <div className="btn2_s2 lm5">거래체결<span className="txt_sky"> ({data.de_wating_close_tot})</span></div>}
                                                </td>
                                                <th>구분</th>
                                                <td>{ip_s_kind}</td>
                                            </tr>
                                            <tr>
                                                <th>입하</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.income_tot)}개</td>
                                                <th>승인</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.granted_tot)}개</td>
                                            </tr>
                                            <tr>
                                                <th>미승인</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.denied_tot)}개</td>
                                                <th>대기</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.process_wait)}개</td>
                                            </tr>
                                            <tr>
                                                <th>진행</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.process_tot)}개</td>
                                                <th>종료</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.process_close_tot)}개</td>
                                            </tr>
                                            <tr>
                                                <th>낙찰</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.success_tot)}개</td>
                                                <th>유찰</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.fail_tot)}개</td>
                                            </tr>
                                            <tr>
                                                <th>운송대기</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.trans_wait_tot)}개</td>
                                                <th>배송</th>
                                                <td className='tx_r'>{CF.MakeIntComma(data.trans_tot)}개</td>
                                            </tr>
                                            <tr>
                                                <th>완료</th>
                                                <td className='tx_r' colSpan="3">{CF.MakeIntComma(data.close_tot)}개</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })}
                    </>}
                </div>
                {/* 조회가 없을때 ▼ */}
                {viewPaging === 0 && (
                    <div className="pagination">
                        <button className="prev">
                            <span>이전 페이지</span>
                        </button>
                        <ul className="num_box">
                            <li className="on">
                                <button>1</button>
                            </li>
                        </ul>
                        <button className="next">
                            <span>다음 페이지</span>
                        </button>
                    </div>
                )}
                {/* 조회가 페이징보다 클때 ▼ */}
                {viewPaging > 0 &&
                    <Pagination 
                        nowPage={page_no}
                        totalCnt={viewPaging}
                        setPerPage={perPage}
                        moveToPage={moveToPage}
                    />
                }
            </div>
        }
    </>);
};

export default BidResult;