import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser, isMobile } from "react-device-detect";
import axios from "axios";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import ConfirmPop from "./ConfirmPop";
import { confirmPop, deliveryPop, deliveryAddPop, deliveryRefresh, deliveryInfo, deliverySelect } from "../../../../store/popupSlice";

const DeliveryPop = () => {
    const p_deli_list = enum_api_uri.p_deli_list;
    const p_deli_edit = enum_api_uri.p_deli_edit;
    const token = localStorage.getItem("token");
    const user = useSelector((state)=>state.user);
    const popup = useSelector((state)=>state.popup);
    const dispatch = useDispatch();
    const [deliList, setDeliList] = useState([]);
    const [deliIdx, setDeliIdx] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [deltConfirm, setDeltConfirm] = useState(false);

    const closePopHandler = () => {
        dispatch(deliveryPop(false));
    }

    useEffect(()=>{
        setDeliList(deliList);
    },[deliList]);

    //등록된 배송지 전체조회
    const getList = () => {
        axios.get(`${p_deli_list}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                setDeliList(data);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //맨처음 등록된 배송지 전체조회
    useEffect(()=>{
        getList();
    },[]);

    //배송지 새로 등록 or 수정했을때 배송지 전체조회하기
    useEffect(()=>{
        if(popup.deliveryRefresh){
            getList();
            dispatch(deliveryRefresh(false));
        }
    },[popup.deliveryRefresh]);

    //배송지 삭제
    const deltHandler = () => {
        axios.delete(`${p_deli_edit.replace(':idx', deliIdx)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                const newDeliList = deliList.filter(cont => cont.idx !== deliIdx);
                setDeliList(newDeliList);

                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'삭제되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont3">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">운임정보 설정</p>
                    </div>
                    <div className="scroll_wrap">
                        <div className="custom_table s_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'80px'}} />
                                    <col style={{width:'80px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'78px'}} />
                                    <col style={{width:'78px'}} />
                                    <col style={{width:'78px'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>운송비</th>
                                        <th>기사명</th>
                                        <th>전화번호</th>
                                        <th>차량번호</th>
                                        <th>운임송금은행</th>
                                        <th>운임계좌</th>
                                        <th>선택</th>
                                        <th>수정</th>
                                        <th>삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deliList && deliList.length > 0 ? 
                                        deliList.map((deli,i)=>{
                                            deli = CF.trimObjectValues(deli);
                                            deli.deli_tel = CF.objValueTrim(deli.deli_tel);

                                            return(
                                                <tr key={i}>
                                                    <td className="tx_r">{CF.MakeIntComma(deli.deli_cost2)}원</td>
                                                    <td>{deli.deli_nm}</td>
                                                    <td>{CF.MakePhoneForm(deli.deli_tel)}</td>
                                                    <td>{deli.deli_car_num}</td>
                                                    <td>{deli.deli_bank_nm}</td>
                                                    <td>{deli.deli_bank_num}</td>
                                                    <td>
                                                        <button className="btn_s4" onClick={()=>{
                                                            dispatch(deliveryPop(false));
                                                            dispatch(deliveryInfo(deli));
                                                            dispatch(deliverySelect(true));
                                                        }}>선택</button>
                                                    </td>
                                                    <td>
                                                        <button className="btn_s2" onClick={()=>{
                                                            dispatch(deliveryAddPop({deliveryAddPop:true,deliveryEdit:true}));
                                                            dispatch(deliveryInfo(deli));
                                                        }}>수정</button>
                                                    </td>
                                                    <td>
                                                        <button className="btn_s3" onClick={()=>{
                                                            setDeliIdx(deli.idx);
                                                            dispatch(confirmPop({
                                                                confirmPopTit:'알림',
                                                                confirmPopTxt:'운송정보를 삭제하시겠습니까?',
                                                                confirmPopBtn:2,
                                                            }));
                                                            setDeltConfirm(true);
                                                        }}>삭제</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan={9}>등록된 운임정보가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex_end tp10">
                        <button className="btn_type" onClick={()=>{
                            dispatch(deliveryAddPop({deliveryAddPop:true,deliveryEdit:false}));
                            dispatch(deliveryInfo({}));
                        }}>등록</button>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">운임정보 설정</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap scroll_wrap2 over_x">
                        <div className="custom_table s_table w_900px">
                        <table>
                                <colgroup>
                                    <col style={{width:'80px'}} />
                                    <col style={{width:'80px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'78px'}} />
                                    <col style={{width:'78px'}} />
                                    <col style={{width:'78px'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>운송비</th>
                                        <th>기사명</th>
                                        <th>전화번호</th>
                                        <th>차량번호</th>
                                        <th>운임송금은행</th>
                                        <th>운임계좌</th>
                                        <th>선택</th>
                                        <th>수정</th>
                                        <th>삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deliList && deliList.length > 0 ? 
                                        deliList.map((deli,i)=>{
                                            deli = CF.trimObjectValues(deli);
                                            deli.deli_tel = CF.objValueTrim(deli.deli_tel);

                                            return(
                                                <tr key={i}>
                                                    <td className="tx_r">{CF.MakeIntComma(deli.deli_cost2)}원</td>
                                                    <td>{deli.deli_nm}</td>
                                                    <td>{CF.MakePhoneForm(deli.deli_tel)}</td>
                                                    <td>{deli.deli_car_num}</td>
                                                    <td>{deli.deli_bank_nm}</td>
                                                    <td>{deli.deli_bank_num}</td>
                                                    <td>
                                                        <button className="btn_s4" onClick={()=>{
                                                            dispatch(deliveryPop(false));
                                                            dispatch(deliveryInfo(deli));
                                                            dispatch(deliverySelect(true));
                                                        }}>선택</button>
                                                    </td>
                                                    <td>
                                                        <button className="btn_s2" onClick={()=>{
                                                            dispatch(deliveryAddPop({deliveryAddPop:true,deliveryEdit:true}));
                                                            dispatch(deliveryInfo(deli));
                                                        }}>수정</button>
                                                    </td>
                                                    <td>
                                                        <button className="btn_s3" onClick={()=>{
                                                            setDeliIdx(deli.idx);
                                                            dispatch(confirmPop({
                                                                confirmPopTit:'알림',
                                                                confirmPopTxt:'운송정보를 삭제하시겠습니까?',
                                                                confirmPopBtn:2,
                                                            }));
                                                            setDeltConfirm(true);
                                                        }}>삭제</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan={6}>등록된 운임정보가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex_center tp10 bp10">
                        <button className="btn_type" onClick={()=>{
                            dispatch(deliveryAddPop({deliveryAddPop:true,deliveryEdit:false}));
                            dispatch(deliveryInfo({}));
                        }}>등록</button>
                    </div>
                </div>
            </div>
        }

        {/* 배송지 삭제 confirm팝업 */}
        {deltConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setDeltConfirm(false)}} onClickHandler={deltHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default DeliveryPop;