import { isBrowser, isMobile } from 'react-device-detect';
import Header from "./Header";
import Footer from "./Footer";
import MoHeader from '../../mo/layout/MoHeader';

const Layout = (props) => {
    return (
        <>
            {isBrowser &&
                <>
                    <Header />
                    <main>{props.children}</main>
                    <Footer />
                </>
            }
            {isMobile &&
                <>
                    <MoHeader />
                    <main>{props.children}</main>
                </>
            }
        </>
    );
};

export default Layout;
