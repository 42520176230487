import { createSlice } from "@reduxjs/toolkit";

const popup = createSlice({
    name: "popup", //state 이름
    initialState: {
        // 공통 팝업 -----------------------------
        //로딩 팝업
        loadingPop: false,
        // 사진/동영상 팝업
        mediaPop: null,
        mediaTit:"",
        mediaSrc:"",
        // 사진/동영상 다같이 보이는 팝업(전광판 상품상세 사진/동영상팝업)
        mediaAllPop: null,
        mediaAllImg1:"",
        mediaAllImg2:"",
        mediaAllVideo:"",
        //안내,알림 팝업
        confirmPop: null,
        confirmPopTit: "",
        confirmPopTxt: "",
        confirmPopTxt2: "",
        confirmPopBtn: "",
        confirmPopBtnBlack: false,
        //송품장,물품 상세 팝업
        proDetailPop:null,
        //실시간경매현황 팝업
        auctionViewPop:null,

        // 출하주 팝업 -----------------------------
        newProductPop: false,
        newProductAdd: null,
        dealHistoryPop: null,
        prevProductPop: null,
        prevProductAdd: null,
        // 등록된물품수정팝업--
        productPopModify:false,
        deDate:"",
        deNo:"",
        deSeq:"",
        deSeq2:"",
        deFarmName:"",
        deFarmCode:"",
        deGodsCode:"",
        deGodsName:"",
        deWeight:"",
        dePacking:"",
        dePackingCode:"",
        deQuality:"",
        deQualityCode:"",
        deHigh:"",
        deHighCode:"",
        deQuantity:"",
        deCost2:"",
        deDansu:"",
        deHopePri:"",
        deCont1:"",
        deCont2:"",
        deCont3:"",
        deImg1:"",
        deImg2:"",
        deVideo:"",
        // //등록된물품수정팝업--
        deliveryPop:false,
        deliveryPopNum:null,
        deliveryAddPop:false,
        deliveryEdit:false,
        deliveryInfo:{},
        deliveryRefresh:false,
        deliverySelect:false,

        // 중도메인 팝업 -----------------------------
        //중도메인 입찰 & 취소 관련
        ip_date_online :"",
        ip_no_online :"",
        de_date_online :"",
        de_no_online :"",
        de_seq :"",
        de_seq2 :"",
        //중도메인 입찰 취소 관련
        auctionProDetailPop:null,
        bidListPop:null,
        bidListPopCode:null,
        bidListPopDate:null,
        addressPop:false,
        addressAddPop:false,
        addressEdit:false,
        addressInfo:{},
        addressRefresh:false,
        addressSelect:false,

        // 경매사 팝업 -----------------------------
        defaultInfoModifyPop:null,
        defaultInfoModifyOk:null,
        proDivisionPop:null,
        proDivisionOk:null,
        messageSetPop:null,
        messageSetAddPop:null,
        orderingPop:null,
        tableProducePop:null,
        resultDetailPop:null,
        reasonPop:null,
        reasonPopTxt:"",
        reasonPopbidSuccess:{},
        reasonPopOk:false,
        restartPop:null,
        restartAuction:null,
    },
    reducers:{
        //공통----------
        loadingPop: (state, action) => {
            state.loadingPop = action.payload;
        },
        //모든팝업 닫기
        closePop: (state) => {
            state.newProductPop = null;
            state.dealHistoryPop = null;
            state.prevProductPop = null;
            state.confirmPop = null;
            state.proDetailPop = null;
            state.defaultInfoModifyPop = null;
            // state.proDivisionPop = null;
            state.messageSetPop = null;
            state.messageSetAddPop = null;
            state.orderingPop = null;
            state.tableProducePop = null;
            state.auctionProDetailPop = null;
            state.bidListPop = null;
            state.restartPop = null;
            state.auctionViewPop = null;
        },
        dealHistoryPop: (state) => {
            state.dealHistoryPop = true;
        },
        mediaPop: (state, action) => {
            state.mediaPop = action.payload.mediaPop;
            state.mediaTit = action.payload.mediaTit;
            state.mediaSrc = action.payload.mediaSrc;
        },
        mediaAllPop: (state, action) => {
            state.mediaAllPop = action.payload.mediaAllPop;
            state.mediaAllImg1 = action.payload.mediaAllImg1;
            state.mediaAllImg2 = action.payload.mediaAllImg2;
            state.mediaAllVideo = action.payload.mediaAllVideo;
        },
        confirmPop: (state, action) => {
            state.confirmPop = true;
            state.confirmPopTit = action.payload.confirmPopTit;
            state.confirmPopTxt = action.payload.confirmPopTxt;
            state.confirmPopTxt2 = action.payload.confirmPopTxt2;
            state.confirmPopBtn = action.payload.confirmPopBtn;
            state.confirmPopBtnBlack = action.payload.confirmPopBtnBlack;
        },
        auctionViewPop: (state, action) => {
            state.auctionViewPop = action.payload;
        },

        //츨히주 팝업--------------------
        newProductPop: (state) => {
            state.newProductPop = true;
        },
        newProductAdd: (state, action) => {
            state.newProductAdd = action.payload;
        },
        productPopModifyClose: (state) => {
            state.productPopModify = null;
            state.deDate = "";
            state.deNo = "";
            state.deSeq = "";
            state.deSeq2 = "";
            state.deFarmName = "";
            state.deFarmCode = "";
            state.deGodsCode = "";
            state.deGodsName = "";
            state.deWeight = "";
            state.dePacking = "";
            state.dePackingCode = "";
            state.deQuality = "";
            state.deQualityCode = "";
            state.deHigh = "";
            state.deHighCode = "";
            state.deQuantity = "";
            state.deCost2 = "";
            state.deDansu = "";
            state.deHopePri = "";
            state.deCont1 = "";
            state.deCont2 = "";
            state.deCont3 = "";
            state.deImg1 = "";
            state.deImg2 = "";
            state.deVideo = "";
        },
        prevProductPop: (state, action) => {
            state.prevProductPop = action.payload;
        },
        prevProductAdd: (state, action) => {
            state.prevProductAdd = action.payload;
        },
        productPopModify: (state, action) => {
            state.productPopModify = true;
            state.deDate = action.payload.deDate;
            state.deNo =  action.payload.deNo;
            state.deSeq =  action.payload.deSeq;
            state.deSeq2 =  action.payload.deSeq2;
            state.deFarmName = action.payload.deFarmName;
            state.deFarmCode = action.payload.deFarmCode;
            state.deGodsCode = action.payload.deGodsCode;
            state.deGodsName = action.payload.deGodsName;
            state.deWeight = action.payload.deWeight;
            state.dePackingCode = action.payload.dePackingCode;
            state.dePacking = action.payload.dePacking;
            state.deQuality = action.payload.deQuality;
            state.deQualityCode = action.payload.deQualityCode;
            state.deHigh = action.payload.deHigh;
            state.deHighCode = action.payload.deHighCode;
            state.deQuantity = action.payload.deQuantity;
            state.deCost2 = action.payload.deCost2;
            state.deDansu = action.payload.deDansu;
            state.deHopePri = action.payload.deHopePri;
            state.deCont1 = action.payload.deCont1;
            state.deCont2 = action.payload.deCont2;
            state.deCont3 = action.payload.deCont3;
            state.deImg1 = action.payload.deImg1;
            state.deImg2 = action.payload.deImg2;
            state.deVideo = action.payload.deVideo;
        },
        productDelt: (state, action) => {
            state.deDate = action.payload.deDate;
            state.deNo = action.payload.deNo;
            state.deSeq = action.payload.deSeq;
            state.deSeq2 = action.payload.deSeq2;
        },
        proDetailPop: (state, action) => {
            state.proDetailPop = action.payload;
        },
        deliveryPop: (state, action) => {
            state.deliveryPop = action.payload;
        },
        deliveryPopNum: (state, action) => {
            state.deliveryPopNum = action.payload;
        },
        deliveryAddPop: (state, action) => {
            state.deliveryAddPop = action.payload.deliveryAddPop;
            state.deliveryEdit = action.payload.deliveryEdit;
        },
        deliveryRefresh: (state, action) => {
            state.deliveryRefresh = action.payload;
        },
        deliveryInfo: (state, action) => {
            state.deliveryInfo = action.payload;
        },
        deliverySelect: (state, action) => {
            state.deliverySelect = action.payload;
        },

        //경매사 팝업-------------------------
        defaultInfoModifyPop: (state, action) => {
            state.defaultInfoModifyPop = action.payload;
        },
        defaultInfoModifyOk: (state, action) => {
            state.defaultInfoModifyOk = action.payload;
        },
        proDivisionPop: (state, action) => {
            state.proDivisionPop = action.payload;
        },
        proDivisionOk: (state, action) => {
            state.proDivisionOk = action.payload;
        },
        messageSetPop: (state, action) => {
            state.messageSetPop = action.payload;
        },
        messageSetAddPop: (state, action) => {
            state.messageSetAddPop = action.payload;
        },
        orderingPop: (state, action) => {
            state.orderingPop = action.payload;
        },
        tableProducePop: (state, action) => {
            state.tableProducePop = action.payload;
        },
        resultDetailPop: (state, action) => {
            state.resultDetailPop = action.payload;
        },
        reasonPop: (state, action) => {
            state.reasonPop = action.payload.reasonPop;
            state.reasonPopTxt = action.payload.reasonPopTxt;
            state.reasonPopbidSuccess = action.payload.reasonPopbidSuccess;
        },
        reasonPopOk: (state, action) => {
            state.reasonPopOk = action.payload;
        },
        restartPop: (state, action) => {
            state.restartPop = action.payload;
        },
        restartAuction: (state, action) => {
            state.restartAuction = action.payload;
        },

        //중도매인 팝업--------------------
        //입찰취소
        ipCancelPop: (state, action) => {
            // console.log(action)
            state.ip_date_online = action.payload.de_date_online;
            state.ip_no_online = action.payload.deNo;
            state.de_date_online = action.payload.de_date_online;
            state.de_no_online = action.payload.deNo;
            state.de_seq = action.payload.deSeq;
            state.de_seq2 = action.payload.deSeq2;
        },
        //입찰하기
        ipChalPop: (state, action) => {
            // console.log(action)
            state.ip_date_online = action.payload.de_date_online;
            state.ip_no_online = action.payload.deNo;
            state.de_date_online = action.payload.de_date_online;
            state.de_no_online = action.payload.deNo;
            state.de_seq = action.payload.deSeq;
            state.de_seq2 = action.payload.deSeq2;
        },
        auctionProDetailPop: (state, action) =>{
            state.auctionProDetailPop = action.payload;
        },
        bidListPop: (state, action) =>{
            state.bidListPop = action.payload.bidListPop;
            state.bidListPopCode = action.payload.bidListPopCode;
            state.bidListPopDate = action.payload.bidListPopDate;
        },
        addressPop: (state, action) => {
            state.addressPop = action.payload;
        },
        addressAddPop: (state, action) => {
            state.addressAddPop = action.payload.addressAddPop;
            state.addressEdit = action.payload.addressEdit;
        },
        addressRefresh: (state, action) => {
            state.addressRefresh = action.payload;
        },
        addressInfo: (state, action) => {
            state.addressInfo = action.payload;
        },
        addressSelect: (state, action) => {
            state.addressSelect = action.payload;
        },
    }
});

export const {
    loadingPop,
    closePop,
    newProductPop,
    newProductAdd,
    productPopModifyClose,
    dealHistoryPop,
    prevProductPop,
    prevProductAdd,
    productPopModify,
    productDelt,
    mediaPop,
    mediaAllPop,
    confirmPop,
    proDetailPop,
    deliveryPop,
    deliveryPopNum,
    deliveryAddPop,
    deliveryRefresh,
    deliveryInfo,
    deliverySelect,
    defaultInfoModifyPop,
    defaultInfoModifyOk,
    proDivisionPop,
    proDivisionOk,
    ipCancelPop,
    ipChalPop,
    messageSetPop,
    messageSetAddPop,
    orderingPop,
    tableProducePop,
    auctionProDetailPop,
    bidListPop,
    addressPop,
    addressAddPop,
    addressRefresh,
    addressInfo,
    addressSelect,
    resultDetailPop,
    reasonPop,
    reasonPopOk,
    restartPop,
    restartAuction,
    auctionViewPop
} = popup.actions;
export default popup;