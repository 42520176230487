import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { closePop } from "../../../../store/popupSlice";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import { messageSetAddPop, confirmPop } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";

const MessageSetAddPop = () => {
    const a_message_add = enum_api_uri.a_message_add;
    const token = localStorage.getItem("token");
    const user = useSelector((state)=>state.user);
    const dispatch = useDispatch();
    const [messageType, setMessageType] = useState('P');
    const [messageInput, setMessageInput] = useState('');
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setMessageType(messageType);
    },[messageType]);

    useEffect(()=>{
        setMessageInput(messageInput);
    },[messageInput]);

    const messageAddHandler = () => {
        let body = {
            message_type:messageType,
            content:messageInput
        };
        axios.post(`${a_message_add.replace(':asso_code', user.assoCode)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                dispatch(messageSetAddPop(false));
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    return(<>
        <div className="pop_wrap">
            <div className="pop_cont pop_cont2">
                <button className="pop_close" onClick={()=>{dispatch(messageSetAddPop(false))}}>닫기버튼</button>
                <div className="pop_tit">
                    <p className="tit">등록</p>
                </div>
                <div className="t_border2 tp20">
                    <div className="custom_select w_150px bm5">
                        <select onChange={(e)=>{setMessageType(e.target.value)}} value={messageType}>
                            <option value="P">개인메시지</option>
                            <option value="A">전체메시지</option>
                        </select>
                    </div>
                    <div className="custom_input h_40">
                        <input type="text" value={messageInput} onChange={(e)=>{setMessageInput(e.currentTarget.value)}} />
                    </div>
                    <div className="flex_center tp30 bp10">
                        <button className="btn_type" onClick={messageAddHandler}>저장</button>
                    </div>
                </div>
            </div>
        </div>

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default MessageSetAddPop;