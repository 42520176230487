import { useState,useEffect,useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik,useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { isMobile, isBrowser } from 'react-device-detect';
import {enum_api_uri} from "../../../config/enum";
import {enum_ip_status,enum_sell_kind,enum_from_kind,enum_mo_title} from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import LeftCont from "../../../components/pc/component/LeftCont";
import ProductInfo from "../../../components/pc/component/ProductInfo";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import {ipCancelPop, confirmPop, closePop} from "../../../store/popupSlice";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { NumericFormat } from 'react-number-format';
import history from "../../../config/history";

const OnlineBidDetail = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const { obId } = useParams();
    // const [ip_date_online] = useState('');
    // const [ip_code_online] = useState('');
    const m_detail_bid = enum_api_uri.m_detail_bid;
    const c_search_origin = enum_api_uri.c_search_origin;
    const m_bid_insert = enum_api_uri.m_bid_insert;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [detail, setDetail] = useState([]);
    const [locInput, setLocInput] = useState();
    const [locTxt, setLocTxt] = useState([]);
    const [proList, setProList] = useState([]);
    const [ipPrice, setIpPrice] = useState();
    const [ipNumber, setIpNumber] = useState();
    const [ipTime, setIpTime] = useState();
    const [ipTotalCal, setIpTotalCal] = useState();
    const [deQuantity, setdeQuantity] = useState(0);
    const popup = useSelector((state) => state.popup);
    const [ipBtn, setIpBtn] = useState();//입찰 or 취소 버튼
    let plok = useRef(0);
    const location = useLocation();
    const [confirm, setConfirm] = useState(false);
    const [bidCancelConfirm, setBidCancelConfirm] = useState(false);

    //상세페이지 뒤로가기
    useEffect(() => {
        dispatch(detailPageBack(false));

        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf('/');
            let tit = path.substring(0, idx);
                tit = enum_mo_title[tit];
            dispatch(moTitle(tit));
        }
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setBidCancelConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);
    

    useEffect(() => {
        getDetail(obId);
    },[]);


    useEffect(() => {
        setDetail(detail);
        console.log(detail);
    },[detail]);

    useEffect(() => {
        setLocTxt(locTxt);
    },[locTxt]);

    useEffect(() => {
        setProList(proList);
        console.log(proList)
    },[proList]);
    
    //입찰자번호
    useEffect(() => {
        setIpNumber(ipNumber);
    },[ipNumber]);

    //입찰일시
    useEffect(() => {
        setIpTime(ipTime);
    },[ipTime]);

    //총금액 계산식
    useEffect(() => {
        setIpTotalCal(ipTotalCal);
    },[ipTotalCal]);


    //품명그룹 조회
    useEffect(() => {
        setIpPrice(ipPrice);
    },[ipPrice]);


    const validationSchema = Yup.object().shape({
        // proPrice: Yup.number("숫자만 입력가능합니다.").required("단가 메모를 입력해주세요."),
        // proMemo: Yup.string().required("입찰자 메모를 입력해주세요.")
    });


    //입찰취소
    const bidCancel = async () => {
        axios.delete (`${m_bid_insert.replace(':ip_date_online', popup.de_date_online).replace(':ip_no_online', popup.de_no_online).replace(':de_date_online', popup.de_date_online).replace(':de_no_online', popup.de_no_online).replace(':de_seq', popup.de_seq).replace(':de_seq2', popup.de_seq2)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            // console.log(res);
            if(res.status === 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입찰취소가 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
                getDetail(obId);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };


    //입찰하기
    const bidSubmit = async (values,deDate, deNo, deSeq, deSeq2) => {
        let de_date_online = deDate;
        let de_no_online = deNo;
        let de_seq = deSeq;
        let de_seq2 = deSeq2;
        let bidPrice = CF.RemoveIntComma(values.bidPrice)

        let body = {
            price: bidPrice,
            memo: values.proMemo
        };

        if(bidPrice > 0){
            axios.post (`${m_bid_insert.replace(':ip_date_online', detail.ip_date_online).replace(':ip_no_online', detail.ip_no_online).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`, body,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'입찰이 완료되었습니다.',
                        confirmPopBtn:1,
                    }));
                    setConfirm(true);
                    getDetail(obId);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({	
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }else{
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'입찰단가를 확인해주세요.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };

    
    //출하물품 상세정보 받아오기(상단)
    const getDetail = async(obId) =>{
        let dinfoArr = obId.split('-');
        let ip_date_online = dinfoArr[0]
        let ip_no_online = dinfoArr[1]

        axios.get(`${m_detail_bid.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                console.log(res.data)
                
                // //날짜 형식 변경              
                res.data.deli_start_date  =CF.MDFWithOutHour(res.data.ip_delivery_date_start); 
                res.data.deli_start_end  = CF.MDFWithOutHour(res.data.ip_delivery_date_end); 

                // //시작일시&종료일시
                if(res.data.ip_auct_date_start.trim() !=""){res.data.auct_start_date  = CF.MDFWithHour(res.data.ip_auct_date_start); }
                if(res.data.ip_auct_date_end.trim() !=""){res.data.auct_end_date  = CF.MDFWithHour(res.data.ip_auct_date_end);}
                

                //매매구분 (입찰/경매)
                let ipStatus = res.data.ip_status.trim();
                res.data.ipStatus = enum_ip_status[ipStatus][0];

                //매매구분 (입찰/경매)
                let inv_type = res.data.ip_s_kind.trim();
                res.data.inv_type = enum_sell_kind[inv_type];

                //판매구분 (국내/수입)
                let from_type = res.data.ip_kind.trim();
                res.data.from_type = enum_from_kind[from_type];

                //출하주
                res.data.as_name = res.data.as_name.trim();

                //경매사
                res.data.auct_name = (res.data.ip_auct_name === null) ? '미정' : res.data.ip_auct_name;
                                
                //산지
                // let loc = res.data.ip_loca.trim()
                // setLocInput(loc)
                res.data.ip_loca_name = CF.objValueTrim(res.data.ip_loca_name);
                
                //물품 수량
                setdeQuantity(res.data.detltrns[0].de_quantity);

                //하단 등록된 물품 
                setProList(res.data.detltrns);

                //상세항목 넣기
                setDetail(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })

    }

    //목록으로 이동 (뒤로가기)
    const pageBack = () => {
        history.back();
    };
    
    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">온라인 입찰 - 출하물품 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>온라인입찰</li>
                                    <li>출하물품 조회</li>
                                </ul>
                            </div>
                            <div className="flex_between bp10">
                                <p className="medium">출하물품 상세</p>
                                <span className="tag">경매사 : {detail.auct_name}</span>
                            </div>
                            <div className="custom_table">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "13%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="tx_l">등록번호</th>
                                            <td className="tx_l txt_gray">{obId}</td>
                                            <th className="tx_l">출하주</th>
                                            <td className="tx_l txt_gray">{detail.as_name}</td>
                                            <th className="tx_l">현재상태</th>
                                            <td className="tx_l"><span className="tag">{detail.ipStatus}</span></td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">매매구분</th>
                                            <td className="tx_l txt_gray">{detail.inv_type}</td>
                                            <th className="tx_l">시작일시</th>
                                            <td className="tx_l txt_gray">{detail.auct_start_date}</td>
                                            <th className="tx_l">팰릿</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">판매구분</th>
                                            <td className="tx_l txt_gray">{detail.from_type}</td>
                                            <th className="tx_l">종료일시</th>
                                            <td className="tx_l txt_gray">{detail.auct_end_date}</td>
                                            <th className="tx_l">목재(옥타곤)</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">산지</th>
                                            <td className="tx_l txt_gray" colSpan="5">{detail.ip_loca_name} ({detail.ip_loca})</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">배송가능기간</th>
                                            <td className="tx_l txt_gray" colSpan="5">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="inner_box line_top">
                            <p className="medium bp20">등록된 물품: {proList.length}건</p>
                            
                            {/* 등록물품 없을때 ▼ */}
                            {proList.length === 0 && (
                                <div className="none_pro">
                                    <p>등록된 물품이 없습니다. <br /><span>‘물품추가’</span>또는 <span>‘이전 출하상품 가져오기’</span> 버튼을 눌러 등록해 주세요.</p>
                                </div>
                            )}

                            {/* 등록물품 있을때 ▼ */}
                            {proList.length > 0 && proList.map((detltrns,i) => {
                                let bidPrice = 0;
                                if(detltrns.memo.asso_code != undefined){
                                    let bidQuantity = detltrns.de_quantity; //수량 간소화
                                    let bidPrice = detltrns.memo.price; //가격 간소화
                                    let de_date_online = detltrns.de_date_online; //날짜
                                    let deNo = detltrns.de_ip_no; //고유값
                                    let deSeq = detltrns.de_seq; //상품값
                                    let deSeq2 = detltrns.de_seq2; //상품 나눔값
                                
                                    detltrns.ipNumber = detltrns.memo.asso_code
                                    detltrns.ipTime = CF.MDFWithHour(detltrns.memo.reg_dt,3)

                                    //단수가 0 초과일때만 총금액에 표시
                                    let dansu;
                                    if(detltrns.de_dansu > 0){
                                        dansu = " * "+ CF.MakeIntComma(detltrns.de_dansu) + "단";
                                    }else{
                                        dansu = "";
                                    }

                                    //단수가 0 초과일때만 총금액에 *
                                    let totalPrice;
                                    if(detltrns.de_dansu > 0){
                                        totalPrice = bidQuantity * detltrns.de_dansu * bidPrice;
                                    }else{
                                        totalPrice = bidQuantity * bidPrice;
                                    }

                                    detltrns.ipTotalCal = `${CF.MakeIntComma(bidQuantity)}개${dansu} * ${CF.MakeIntComma(bidPrice)}원 = ${CF.MakeIntComma(totalPrice)}원` //총금액 
                                    detltrns.ipBtn = <button type = "button"  className="btn3_s3 w_70px" onClick={() => {
                                        dispatch(ipCancelPop({de_date_online, deNo, deSeq, deSeq2}));
                                        setBidCancelConfirm(true);
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'입찰을 취소하시겠습니까?',
                                            confirmPopBtn:2,
                                            confirmPopBtnBlack:true,
                                        }))
                                    }}
                                    >입찰취소</button> 
                                }
                                if(detltrns.memo.asso_code == undefined){
                                    
                                    detltrns.ipNumber = ''
                                    detltrns.ipTime = ''
                                    detltrns.ipTotalCal = ''
                                    detltrns.ipBtn = <button type = "submit" className="btn3_s4 w_70px rm5">입찰</button>
                                }
                                return (
                                    <div key={i} className="bm30">
                                        <ProductInfo 
                                            deFarmName={detltrns.de_farm_name || ""}
                                            deGodsName={detltrns.gm_name}
                                            deWeight={detltrns.de_weight || ""}
                                            dePacking={detltrns.de_packing_name || ""}
                                            deQuality={detltrns.de_quality_name || ""}
                                            deHigh={detltrns.de_high_name || ""}
                                            deQuantity={detltrns.de_quantity || ""}
                                            deCost2={detltrns.de_cost2 || ""}
                                            deDansu={detltrns.de_dansu || ""}
                                            deHopePri={detltrns.de_hope_pri || ""}
                                            deCont1={detltrns.de_cont1 || ""}
                                            deCont2={detltrns.de_cont2 || ""}
                                            deCont3={detltrns.de_cont3 || ""}
                                            deImg1={detltrns.de_img1 || ""}
                                            deImg2={detltrns.de_img2 || ""}
                                            deVideo={detltrns.de_video || ""}
                                        />
                                        {/* 상태가 입찰중일때만 입찰하기 노출 */}
                                        {detail.ip_status == 8 &&
                                            <div className="custom_table wide_table t_border2 tm12">
                                                <Formik
                                                    initialValues={{
                                                        // bidPrice: detltrns.memo.price ? detltrns.memo.price : '',
                                                        // bidQty: detltrns.de_quantity,
                                                        // ipTotalCal : detltrns.ipTotalCal || ''
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={(e) => {
                                                        bidSubmit(e, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                                    }}
                                                    validateOnMount={true}
                                                >
                                                {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                                <form onSubmit={handleSubmit}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: "12%" }} />
                                                            <col style={{ width: "auto" }} />
                                                            <col style={{ width: "12%" }} />
                                                            <col style={{ width: "auto" }} />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th className="tx_l">입찰자 번호</th>
                                                                <td className="tx_l tuseRefx_l">{detltrns.ipNumber}</td>
                                                                <th className="tx_l">입찰일시</th>
                                                                <td className="tx_l">{detltrns.ipTime}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="tx_l">총 금액</th>
                                                                <td className="tx_l">
                                                                    <div className="custom_input2 h_35 text_input">
                                                                        <input
                                                                            id="ipTotalCal"
                                                                            name="ipTotalCal"
                                                                            type="text"
                                                                            // value = {values.ipTotalCal}
                                                                            value = {detltrns.ipTotalCal}
                                                                            className={"f_15 p0"}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <th className="tx_l">입찰단가</th>
                                                                <td>
                                                                    <div className="flex">
                                                                        <div className={`custom_input2 h_35 rm5 ${detltrns.memo.asso_code != undefined ? 'text_input flex' : ''}`}>
                                                                            <NumericFormat thousandSeparator="," decimalScale={0}
                                                                                id="bidPrice"
                                                                                name="bidPrice"
                                                                                value = {detltrns.memo.price ? detltrns.memo.price : ""}
                                                                                // value = {values.bidPrice}
                                                                                onChange={(e) => {   
                                                                                    handleChange(e);
                                                                                    let purePrice = CF.RemoveIntComma(e.target.value);

                                                                                    //단수가 0 초과일때만 총금액에 표시
                                                                                    let dansu;
                                                                                    if(detltrns.de_dansu > 0){
                                                                                        dansu = " * "+ CF.MakeIntComma(detltrns.de_dansu) + "단";
                                                                                    }else{
                                                                                        dansu = "";
                                                                                    }

                                                                                    //단수가 0 초과일때만 총금액에 *
                                                                                    let totalPrice;
                                                                                    if(detltrns.de_dansu > 0){
                                                                                        totalPrice = detltrns.de_quantity * detltrns.de_dansu * purePrice;
                                                                                    }else{
                                                                                        totalPrice = detltrns.de_quantity * purePrice;
                                                                                    }

                                                                                    let bidCalc = `${CF.MakeIntComma(detltrns.de_quantity)}개${dansu} * ${CF.MakeIntComma(purePrice)}원 = ${CF.MakeIntComma(totalPrice)}원` //총금액 
                                                                                    // setFieldValue("ipTotalCal",bidCalc);

                                                                                    proList.filter(pro => {
                                                                                        if (pro.seq === detltrns.seq && pro.seq2 === detltrns.seq2) {
                                                                                            pro.ipTotalCal = bidCalc;
                                                                                            return pro;
                                                                                        } else {
                                                                                            return pro;
                                                                                        }
                                                                                    });
                                                                                    // setProList(newProList);
                                                                                }}
                                                                                className={"tx_r f_15"}
                                                                                readOnly={detltrns.memo.asso_code != undefined ? true : false}
                                                                            />
                                                                            {detltrns.memo.price != undefined && <span className="rm10">원</span>}
                                                                        </div>
                                                                        <div className="flex">
                                                                            {detltrns.ipBtn}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {errors.bidPrice && touched.bidPrice ?
                                                        <div className="alert_txt f_12">
                                                            {errors.bidPrice}
                                                        </div>
                                                    : null}
                                                </form>
                                                )}
                                                </Formik>
                                            </div>
                                        }

                                        {/* 상태가 입찰종료일때 자신의 입찰내역이있을때만 노출 */}
                                        {detail.ip_status ==  9 && detltrns.memo.hasOwnProperty('price') &&
                                            <div className="custom_table wide_table t_border2 tm12">
                                                <Formik
                                                    initialValues={{
                                                        bidPrice: detltrns.memo.price || '',
                                                        bidQty: detltrns.de_quantity,
                                                        ipTotalCal : detltrns.ipTotalCal || ''
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={(e) => {
                                                        bidSubmit(e, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                                    }}
                                                    validateOnMount={true}
                                                >
                                                {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                                <form onSubmit={handleSubmit}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: "12%" }} />
                                                            <col style={{ width: "auto" }} />
                                                            <col style={{ width: "12%" }} />
                                                            <col style={{ width: "auto" }} />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th className="tx_l">입찰자 번호</th>
                                                                <td className="tx_l tuseRefx_l">{detltrns.ipNumber}</td>
                                                                <th className="tx_l">입찰일시</th>
                                                                <td className="tx_l">{detltrns.ipTime}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="tx_l">총 금액</th>
                                                                <td className="tx_r">
                                                                    <div className="custom_input2 h_35 text_input">
                                                                        <input
                                                                            id="ipTotalCal"
                                                                            name="ipTotalCal"
                                                                            type="text"
                                                                            defaultValue = {values.ipTotalCal}
                                                                            // onChange={(e) => {CheckText(e.target.value)}}
                                                                            className={"f_15 p0"}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <th className="tx_l">입찰단가</th>
                                                                <td>
                                                                    <p className="tx_r">{CF.MakeIntComma(detltrns.memo.price)}원</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                                )}
                                                </Formik>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className="rp30 bp30 lp30 flex_end">
                            <button className="btn_type4" onClick={pageBack}>목록으로 이동</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <div className="inner_box">
                    <div className="flex_between bp10 tp20 rp10 lp10">
                        <p className="medium f_16">출하물품 상세</p>
                        <span className="tag">경매사 : {detail.auct_name}</span>
                    </div>
                    <div className="custom_table">
                        <table>
                            <colgroup>
                                <col style={{ width: "24%" }} />
                                <col style={{ width: "26%" }} />
                                <col style={{ width: "24%" }} />
                                <col style={{ width: "26%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tx_l">등록번호</th>
                                    <td className="tx_l txt_gray" colSpan="3">{obId}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">출하주</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.as_name}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">현재상태</th>
                                    <td className="tx_l"><span className="tag">{detail.ipStatus}</span></td>
                                    <th className="tx_l">매매구분</th>
                                    <td className="tx_l txt_gray">{detail.inv_type}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">시작일시</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.auct_start_date}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">종료일시</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.auct_end_date}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">판매구분</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.from_type}</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">산지</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.ip_loca_name} ({detail.ip_loca})</td>
                                </tr>
                                <tr>
                                    <th className="tx_l">팰릿</th>
                                    <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                    <th className="tx_l rp0">목재(옥타곤)</th>
                                    <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                                    
                                </tr>
                                <tr>
                                    <th className="tx_l rp0">배송가능기간</th>
                                    <td className="tx_l txt_gray" colSpan="3">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="inner_box line_top tp20">
                    <p className="medium bp20 lp10">등록된 물품 : <span className="txt_sky">{proList.length}</span>건</p>
                    
                    {/* 등록물품 없을때 ▼ */}
                    {proList.length === 0 && (
                        <div className="none_pro">
                            <p>등록된 물품이 없습니다. <br /><span>‘물품추가’</span>또는 <span>‘이전 출하상품 가져오기’</span> 버튼을 눌러 등록해 주세요.</p>
                        </div>
                    )}

                    {/* 등록물품 있을때 ▼ */}
                    {proList.length > 0 && proList.map((detltrns,i) => {
                        let bidPrice = 0;
                        if(detltrns.memo.asso_code != undefined){
                            let bidQuantity = detltrns.de_quantity; //수량 간소화
                            let bidPrice = detltrns.memo.price; //가격 간소화
                            let de_date_online = detltrns.de_date_online; //날짜
                            let deNo = detltrns.de_ip_no; //고유값
                            let deSeq = detltrns.de_seq; //상품값
                            let deSeq2 = detltrns.de_seq2; //상품 나눔값
                        
                            detltrns.ipNumber = detltrns.memo.asso_code
                            detltrns.ipTime = CF.MDFWithHour(detltrns.memo.reg_dt,3);

                            //단수가 0 초과일때만 총금액에 표시
                            let dansu;
                            if(detltrns.de_dansu > 0){
                                dansu = " * "+ CF.MakeIntComma(detltrns.de_dansu) + "단";
                            }else{
                                dansu = "";
                            }

                            //단수가 0 초과일때만 총금액에 *
                            let totalPrice;
                            if(detltrns.de_dansu > 0){
                                totalPrice = bidQuantity * detltrns.de_dansu * bidPrice;
                            }else{
                                totalPrice = bidQuantity * bidPrice;
                            }

                            detltrns.ipTotalCal = `${CF.MakeIntComma(bidQuantity)}개${dansu} * ${CF.MakeIntComma(bidPrice)}원 = ${CF.MakeIntComma(totalPrice)}원` //총금액 
                            detltrns.ipBtn = <button type = "button"  className="btn3_s3 w_70px" onClick={() => {
                                dispatch(ipCancelPop({de_date_online, deNo, deSeq, deSeq2}));
                                setBidCancelConfirm(true);
                                dispatch(confirmPop({
                                    confirmPopTit:'알림',
                                    confirmPopTxt:'입찰을 취소하시겠습니까?',
                                    confirmPopBtn:2,
                                    confirmPopBtnBlack:true,
                                }))
                            }}
                            >입찰취소</button> 
                        }
                        if(detltrns.memo.asso_code == undefined){
                            detltrns.ipNumber = ''
                            detltrns.ipTime = ''
                            detltrns.ipTotalCal = ''
                            detltrns.ipBtn = <button type = "submit" className="btn3_s4 w_70px rm5">입찰</button>
                        }
                        return (<div key={i} className="bm30">
                            <ProductInfo 
                                deFarmName={detltrns.de_farm_name || ""}
                                deFarmCode={detltrns.de_farm_code || ""}
                                deGodsName={detltrns.gm_name}
                                deWeight={detltrns.de_weight || ""}
                                dePacking={detltrns.de_packing_name || ""}
                                deQuality={detltrns.de_quality_name || ""}
                                deHigh={detltrns.de_high_name || ""}
                                deQuantity={detltrns.de_quantity || ""}
                                deCost2={detltrns.de_cost2 || ""}
                                deDansu={detltrns.de_dansu || ""}
                                deHopePri={detltrns.de_hope_pri || ""}
                                deCont1={detltrns.de_cont1 || ""}
                                deCont2={detltrns.de_cont2 || ""}
                                deCont3={detltrns.de_cont3 || ""}
                                deImg1={detltrns.de_img1 || ""}
                                deImg2={detltrns.de_img2 || ""}
                                deVideo={detltrns.de_video || ""}
                            />

                            {/* 상태가 입찰중일때만 입찰하기 노출 */}
                            {detail.ip_status == 8 &&
                                <div className="custom_table wide_table t_border2 tm12">
                                    <Formik
                                        initialValues={{
                                            // bidPrice: detltrns.memo.price ? detltrns.memo.price : '',
                                            // bidQty: detltrns.de_quantity,
                                            // ipTotalCal : detltrns.ipTotalCal || ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(e) => {
                                            bidSubmit(e, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                        }}
                                        validateOnMount={true}
                                    >
                                    {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                    <form onSubmit={handleSubmit}>
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "30%" }} />
                                                <col style={{ width: "70%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="tx_l">입찰자 번호</th>
                                                    <td className="tx_l tuseRefx_l">{detltrns.ipNumber}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">입찰일시</th>
                                                    <td className="tx_l">{detltrns.ipTime}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">총 금액</th>
                                                    <td className="tx_l">
                                                        <div className="custom_input2 h_35 text_input">
                                                            <input
                                                                id="ipTotalCal"
                                                                name="ipTotalCal"
                                                                type="text"
                                                                // value = {values.ipTotalCal}
                                                                value = {detltrns.ipTotalCal}
                                                                className={"tx_r"}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">입찰단가</th>
                                                    <td>
                                                        <div className="flex_end">
                                                            <div className={`custom_input2 h_35 rm5 ${detltrns.memo.asso_code != undefined ? 'text_input flex' : ''}`}>
                                                                <NumericFormat thousandSeparator="," decimalScale={0}
                                                                    id="bidPrice"
                                                                    name="bidPrice"
                                                                    value = {detltrns.memo.price ? detltrns.memo.price : ""}
                                                                    // value = {values.bidPrice}
                                                                    onChange={(e) => {   
                                                                        handleChange(e);
                                                                        let purePrice = CF.RemoveIntComma(e.target.value);

                                                                        //단수가 0 초과일때만 총금액에 표시
                                                                        let dansu;
                                                                        if(detltrns.de_dansu > 0){
                                                                            dansu = " * "+ CF.MakeIntComma(detltrns.de_dansu) + "단";
                                                                        }else{
                                                                            dansu = "";
                                                                        }

                                                                        //단수가 0 초과일때만 총금액에 *
                                                                        let totalPrice;
                                                                        if(detltrns.de_dansu > 0){
                                                                            totalPrice = detltrns.de_quantity * detltrns.de_dansu * purePrice;
                                                                        }else{
                                                                            totalPrice = detltrns.de_quantity * purePrice;
                                                                        }

                                                                        let bidCalc = `${CF.MakeIntComma(detltrns.de_quantity)}개${dansu} * ${CF.MakeIntComma(purePrice)}원 = ${CF.MakeIntComma(totalPrice)}원`//총금액 
                                                                        // setFieldValue("ipTotalCal",bidCalc );

                                                                        proList.filter(pro => {
                                                                            if (pro.seq === detltrns.seq && pro.seq2 === detltrns.seq2) {
                                                                                pro.ipTotalCal = bidCalc;
                                                                                return pro;
                                                                            } else {
                                                                                return pro;
                                                                            }
                                                                        });
                                                                        // setProList(newProList);
                                                                    }}
                                                                    className={"tx_r"}
                                                                    readOnly={detltrns.memo.asso_code != undefined ? true : false}
                                                                />
                                                                {detltrns.memo.price != undefined && <span className="rm10 txt_black">원</span>}
                                                            </div>
                                                            <div className="flex">
                                                                {detltrns.ipBtn}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {errors.bidPrice && touched.bidPrice ?
                                            <div className="alert_txt f_12">
                                                {errors.bidPrice}
                                            </div>
                                        : null}
                                    </form>
                                    )}
                                    </Formik>
                                </div>
                            }

                            {/* 상태가 입찰종료일때 자신의 입찰내역이있을때만 노출 */}
                            {detail.ip_status ==  9 && detltrns.memo.hasOwnProperty('price') &&
                                <div className="custom_table wide_table t_border2 tm12">
                                    <Formik
                                        initialValues={{
                                            bidPrice: detltrns.memo.price || '',
                                            bidQty: detltrns.de_quantity,
                                            ipTotalCal : detltrns.ipTotalCal || ''

                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(e) => {
                                            bidSubmit(e, detltrns.de_date_online, detltrns.de_no_online, detltrns.de_seq, detltrns.de_seq2);
                                        }}
                                        validateOnMount={true}
                                    >
                                    {({values, handleSubmit,handleChange,setFieldValue,errors, touched}) => (
                                    <form onSubmit={handleSubmit}>
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "30%" }} />
                                                <col style={{ width: "70%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="tx_l">입찰자 번호</th>
                                                    <td className="tx_l tuseRefx_l">{detltrns.ipNumber}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">입찰일시</th>
                                                    <td className="tx_l">{detltrns.ipTime}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">총 금액</th>
                                                    <td className="tx_l">
                                                        <div className="custom_input2 h_35 text_input">
                                                            <input
                                                                id="ipTotalCal"
                                                                name="ipTotalCal"
                                                                type="text"
                                                                defaultValue = {values.ipTotalCal}
                                                                // onChange={(e) => {CheckText(e.target.value)}}
                                                                className={"tx_r"}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">입찰단가</th>
                                                    <td className="tx_r">{CF.MakeIntComma(detltrns.memo.price)}원</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                    )}
                                    </Formik>
                                </div>
                            }
                        </div>
                    )})}
                </div>
                <div className="cont2 flex_end">
                    <button className="btn_type4 bm20" onClick={pageBack}>목록으로 이동</button>
                </div>
            </div>
        }

        {/* 입찰취소confirm팝업 */}
        {bidCancelConfirm && <ConfirmPop onClickHandler={bidCancel} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default OnlineBidDetail;