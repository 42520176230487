import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as CF from "../../../pc/component/Function"
import { enum_api_uri, enum_memo_status } from "../../../../config/enum";
import { closePop, mediaPop } from "../../../../store/popupSlice";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { useParams, useLocation } from "react-router-dom";
import imgNone from "../../../../images/img_none.svg";
import videoUp from "../../../../images/video_upload.svg";

const BidListPop = () => {
    const m_bid_history = enum_api_uri.m_bid_history;
    const dispatch = useDispatch();
    const [subDetail, setSubDetail] = useState();
    const token = localStorage.getItem("token");
    const [bidList, setBidList] = useState([]);
    const popup = useSelector((state)=>state.popup);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    useEffect(()=>{
        setSubDetail(subDetail);
        // console.log(subDetail);
    },[subDetail]);

    //페이지 들어왔을때 응찰내역 조회
    useEffect(()=>{
        let auctProData = JSON.parse(localStorage.getItem('auctProData'));
        axios.get(`${m_bid_history.replace(':auct_code',popup.bidListPopCode).replace(':auct_date', popup.bidListPopDate).replace(':de_date_online', auctProData.de_date_online).replace(':de_no_online', auctProData.de_no_online).replace(':de_seq', auctProData.de_seq).replace(':de_seq2', auctProData.de_seq2)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                setBidList([...res.data]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);

      
    return(<>
        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">응찰목록</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <div className="custom_table5">
                            <table>
                                <colgroup>
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>응찰시간</th>
                                        <th>응찰단가</th>
                                        <th>구분</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bidList.length > 0 && bidList.map((bid,i)=>{
                                        // console.log(bid)
                                        // let num = bid.de_no_online+bid.de_seq+bid.de_seq2;
                                        // let time = moment(bid.reg_dt).utc().format("HH:mm.ss.SSS");
                                        // let price = CF.MakeIntComma(bid.price);
                                        // let status = enum_memo_status[bid.memo_status.trim()][0];
                                        let time = moment(bid.l_time).format("HH:mm.ss.SSS");
                                        let gbn = bid.l_gbn;
                                        let price = CF.MakeIntComma(bid.l_price);
                                        return(
                                            <tr key={i} className={bid.memo_status == 0 ? 'tr_orange' : bid.memo_status == 7 ? 'tr_yellow' : bid.memo_status == 8 ? 'tr_blue' : ''}>
                                                <td>{time}</td>
                                                <td className="tx_r">{price}</td>
                                                <td>{gbn}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
};

export default BidListPop;