import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { enum_api_uri, enum_ip_status, enum_sell_kind, enum_from_kind } from "../../../../config/enum";
import * as CF from "../../component/Function";
import { closePop, mediaPop } from "../../../../store/popupSlice";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import imgNone from "../../../../images/img_none.svg";
import videoUp from "../../../../images/video_upload.svg";

const ProDetailPop = () => {
    const p_list_product3 = enum_api_uri.p_list_product3;
    const api_uri = enum_api_uri.api_uri;
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState({});
    const [proData, setProData] = useState({});
    const token = localStorage.getItem("token");
    const popup = useSelector((state)=>state.popup);

    const closePopHandler = () => {
        dispatch(closePop());
    }
    console.log(popup.proDetailPopType)

    // 송품장,물품정보 가져오기
    useEffect(() => {
        let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
        let getProData = localStorage.getItem("productData");
            getProData = JSON.parse(getProData);
        let ip_date_online = getData.ip_date_online;
        let ip_no_online = getData.ip_no_online;
        let de_date_online = getProData.de_date_online;
        let de_no_online = getProData.de_no_online;
        let de_seq = getProData.de_seq;
        let de_seq2 = getProData.de_seq2;

        axios.get(`${p_list_product3.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                //console.log(res.data);
                res.data.ip_reg_dt = moment(res.data.ip_reg_dt).utc().format('YYYY년 MM월 DD일 HH시 mm분 ss초');
                // //시작일시&종료일시
                if(res.data.ip_auct_date_start !== null){
                    res.data.ip_auct_date_start = res.data.ip_auct_date_start.trim();
                    if(res.data.ip_auct_date_start !=""){
                        res.data.ip_auct_date_start  = CF.MDFWithHour(res.data.ip_auct_date_start)
                    }
                }
                if(res.data.ip_auct_date_end !== null){
                    res.data.ip_auct_date_end = res.data.ip_auct_date_end.trim();
                    if(res.data.ip_auct_date_end !=""){
                        res.data.ip_auct_date_end  = CF.MDFWithHour(res.data.ip_auct_date_end)
                    }
                }
                let ip_status = res.data.ip_status.trim();
                res.data.ip_status = enum_ip_status[ip_status][0];
                let ip_s_kind = res.data.ip_s_kind.trim();
                res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                let ip_kind = res.data.ip_kind.trim();
                res.data.ip_kind = enum_from_kind[ip_kind];
                if(res.data.ip_kpp !== null){
                    res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                }
                if(res.data.ip_kcp !== null){
                    res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                }
                if(res.data.ip_wo_tot !== null){
                    res.data.ip_wo_tot = CF.MakeIntComma(res.data.ip_wo_tot);
                }
                if(res.data.ip_quan_tot !== null){
                    res.data.ip_quan_tot = CF.MakeIntComma(res.data.ip_quan_tot);
                }
                res.data.ip_delivery_date_start  = moment(res.data.ip_delivery_date_start.trim()).format('YYYY-MM-DD');
                res.data.ip_delivery_date_end  = moment(res.data.ip_delivery_date_end.trim()).format('YYYY-MM-DD');
                res.data.detltrns.de_weight = CF.MakeIntComma(res.data.detltrns.de_weight);
                res.data.detltrns.de_quantity = CF.MakeIntComma(res.data.detltrns.de_quantity);
                res.data.detltrns.de_cost2 = CF.MakeIntComma(res.data.detltrns.de_cost2);
                res.data.detltrns.de_hope_pri = CF.MakeIntComma(res.data.detltrns.de_hope_pri);

                if(res.data.detltrns.de_img1 != null){
                    res.data.detltrns.de_img1 = res.data.detltrns.de_img1.trim();
                    if(res.data.detltrns.de_img1.length > 0){
                        res.data.detltrns.de_img1 = res.data.detltrns.de_img1.replace(/\\/ig,"\\\\");
                        res.data.detltrns.de_img1 = api_uri+'/'+res.data.detltrns.de_img1;
                    }
                }
                if(res.data.detltrns.de_img2 != null){
                    res.data.detltrns.de_img2 = res.data.detltrns.de_img2.trim();
                    if(res.data.detltrns.de_img2.length > 0){
                        res.data.detltrns.de_img2 = res.data.detltrns.de_img2.replace(/\\/ig,"\\\\");
                        res.data.detltrns.de_img2 = api_uri+'/'+res.data.detltrns.de_img2;
                    }
                }
                if(res.data.detltrns.de_video != null){
                    res.data.detltrns.de_video = res.data.detltrns.de_video.trim();
                    if(res.data.detltrns.de_video.length > 0){
                        res.data.detltrns.de_video = res.data.detltrns.de_video.replace(/\\/ig,"\\\\");
                        res.data.detltrns.de_video = api_uri+'/'+res.data.detltrns.de_video;
                    }
                }

                setInvoiceData(res.data);
                setProData(res.data.detltrns);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }, []);
    
    useEffect(()=>{
        setInvoiceData(invoiceData);
        console.log(invoiceData);
    },[invoiceData]);

    useEffect(()=>{
        setProData(proData);
        console.log(proData);
    },[proData]);

    
    
    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">물품상세</p>
                    </div>
                    <div className="scroll_wrap">
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'84px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'84px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'84px'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">
                                            <div className="flex_between">
                                                <span>{invoiceData.as_name}</span>
                                                {invoiceData.ip_auct_name !== null &&
                                                    <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                                                }
                                            </div>
                                        </td>
                                        <th>등록일시</th>
                                        <td>{invoiceData.ip_reg_dt}</td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td>{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                        <th>상태</th>
                                        <td>
                                            <span className="tag">{invoiceData.ip_status}</span>
                                        </td>
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            <th>입찰시간</th>
                                            : <th>경매시간</th>
                                        }
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            invoiceData.ip_auct_date_start ?
                                                <td>{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'}<br/>{invoiceData.ip_auct_date_end}</td>
                                                : <td>-</td>
                                            :invoiceData.ip_auct_date_start ?
                                            <td>{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'+invoiceData.ip_auct_date_end}</td>
                                            : <td>-</td>
                                        }
                                    </tr>
                                    <tr>
                                        <th>매매구분</th>
                                        <td>{invoiceData.ip_s_kind}</td>
                                        <th>팰릿</th>
                                        <td className="tx_r">{invoiceData.ip_kpp}개</td>
                                        <th>목재<span className="f_12">(옥타곤)</span></th>
                                        <td className="tx_r">{invoiceData.ip_kcp}개</td>
                                    </tr>
                                    <tr>
                                        <th>판매구분</th>
                                        <td>{invoiceData.ip_kind}</td>
                                        <th>산지</th>
                                        <td colSpan="3">{`${invoiceData.ip_loca_name}(${invoiceData.ip_loca})`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>총 운송비</th>
                                        <td className="tx_r">{invoiceData.ip_wo_tot}원</td>
                                        <th>총 수량</th>
                                        <td className="tx_r">{invoiceData.ip_quan_tot}개</td>
                                        <th>배송가능기간</th>
                                        <td>{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="tm10">
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>{proData.de_farm_name}</td>
                                            <th>품명</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.gm_name}</span></div>
                                            </td>
                                            <th>중량</th>
                                            <td className="tx_r">{proData.de_weight}kg</td>
                                            <th>규격</th>
                                            <td>{proData.de_packing_name}</td>
                                            <th>친환경</th>
                                            <td>{proData.de_quality_name}</td>
                                        </tr>
                                        <tr>
                                            <th>등급</th>
                                            <td>{proData.de_high_name}</td>
                                            <th>수량</th>
                                            <td className="tx_r">{proData.de_quantity}개</td>
                                            <th>운임비</th>
                                            <td className="tx_r">{proData.de_cost2}원</td>
                                            <th>단수</th>
                                            <td className="tx_r">{proData.de_dansu}단</td>
                                            <th className="sky_bg">희망단가</th>
                                            <td className="tx_r sky_bg">{proData.de_hope_pri}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table t_border_none">
                                <table>
                                    <colgroup>
                                        <col style={{width:'90px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'90px'}} />
                                        <col style={{width:'auto'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.de_cont1}</span></div>
                                            </td>
                                            <th>당도,산도 등</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.de_cont2}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td colSpan="3">
                                                <div className="over_txt"><span>{proData.de_cont3}</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table t_border_none">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="tx_c">사진1</th>
                                            <th className="tx_c">사진2</th>
                                            <th className="tx_c">동영상</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tx_c">
                                                {proData && proData.de_img1 === "" ? 
                                                    <div className="flex_center h_160"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                    :   proData && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${proData.de_img1}`}))
                                                        }}>
                                                            <img src={`${proData.de_img1}`} alt="이미지"/>
                                                        </div>
                                                }
                                            </td>
                                            <td className="tx_c">
                                                {proData && proData.de_img2 === "" ? 
                                                    <div className="flex_center h_160"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                    :   proData && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${proData.de_img2}`}))
                                                        }}>
                                                            <img src={`${proData.de_img2}`} alt="이미지"/>
                                                        </div>
                                                }
                                            </td>
                                            <td className="tx_c">
                                                {proData &&  proData.de_video === "" ? 
                                                    <div className="flex_center h_160"><img src={videoUp} alt="이미지"/></div>
                                                    :   proData && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${proData.de_video}`}))
                                                        }}>
                                                            <video src={`${proData.de_video}`} />
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {proData.de_status >= 14 &&
                            <div className="tm10">
                                <div className="custom_table gray_table">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'90px'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'90px'}} />
                                            <col style={{width:'auto'}} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th className="rp0">배송요청일자</th>
                                                <td>{moment(proData.de_deli_req_date).format("YYYY-MM-DD")}</td>
                                                <th>수령자명</th>
                                                <td>{proData.de_deli_rece_nm}</td>
                                            </tr>
                                            <tr>
                                                <th>배송지</th>
                                                <td>
                                                    {proData.de_deli_rece_addr1 !== null ?
                                                        proData.de_deli_rece_addr1+proData.de_deli_rece_addr2+'('+proData.de_deli_rece_post+')'
                                                        : '-'
                                                    }
                                                </td>
                                                <th>메모</th>
                                                <td>{proData.de_deli_rece_remk !== null ? proData.de_deli_rece_remk : '-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {proData.de_status >= 15 &&
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'100px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'100px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'100px'}} />
                                        <col style={{width:'auto'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>운송비</th>
                                            <td className="tx_r">{proData.de_cost2}원</td>
                                            <th>기사명<span className="f_12">(예금주)</span></th>
                                            <td>{proData.de_deli_nm}</td>
                                            <th>전화번호</th>
                                            <td>{CF.MakePhoneForm(proData.de_deli_tel)}</td>
                                        </tr>
                                        <tr>
                                            <th>차량번호</th>
                                            <td>{proData.de_deli_car_num}</td>
                                            <th>운임 송금은행</th>
                                            <td>{proData.de_deli_bank_nm}</td>
                                            <th>배송일자</th>
                                            <td>{moment(proData.de_deli_date).format('YYYY-MM-DD')}</td>
                                        </tr>
                                        <tr>
                                            <th>운임계좌</th>
                                            <td colSpan="5">{proData.de_deli_bank_num}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">물품상세</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <div className="cont2 flex_between tm15 bm10">
                            <p className="f_16 medium">기본정보</p>
                            {invoiceData.ip_auct_name !== null &&
                                <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                            }
                        </div>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">{invoiceData.as_name}</td>
                                    </tr>
                                    <tr>
                                        <th>등록일시</th>
                                        <td colSpan="3">{invoiceData.ip_reg_dt}</td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td colSpan="3">{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                    </tr>
                                    <tr>
                                        <th>상태</th>
                                        <td colSpan="3">
                                            <span className="tag">{invoiceData.ip_status}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            <th>입찰시간</th>
                                            : <th>경매시간</th>
                                        }
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            invoiceData.ip_auct_date_start ?
                                                <td colSpan="3">{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'}<br/>{invoiceData.ip_auct_date_end}</td>
                                                : <td colSpan="3">-</td>
                                            :invoiceData.ip_auct_date_start ?
                                            <td colSpan="3">{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'+invoiceData.ip_auct_date_end}</td>
                                            : <td colSpan="3">-</td>
                                        }
                                    </tr>
                                    <tr>
                                        <th>매매구분</th>
                                        <td>{invoiceData.ip_s_kind}</td>
                                        <th>팰릿</th>
                                        <td className="tx_r">{invoiceData.ip_kpp}개</td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">목재<span className="f_12">(옥타곤)</span></th>
                                        <td className="tx_r">{invoiceData.ip_kcp}개</td>
                                        <th>판매구분</th>
                                        <td>{invoiceData.ip_kind}</td>
                                    </tr>
                                    <tr>
                                        <th>산지</th>
                                        <td colSpan="3">{`${invoiceData.ip_loca_name}(${invoiceData.ip_loca})`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="f_16 medium tp20 lp10">운송정보</p>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>총 운송비</th>
                                        <td className="tx_r">{invoiceData.ip_wo_tot}원</td>
                                        <th>총 수량</th>
                                        <td className="tx_r">{invoiceData.ip_quan_tot}개</td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">배송가능기간</th>
                                        <td colSpan="3">{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="tm10">
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'23%'}} />
                                        <col style={{width:'27%'}} />
                                        <col style={{width:'23%'}} />
                                        <col style={{width:'27%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>{proData.de_farm_name}</td>
                                            <th>품명</th>
                                            <td>{proData.gm_stmd_name}</td>
                                        </tr>
                                        <tr>
                                            <th>중량</th>
                                            <td className="tx_r">{proData.de_weight}kg</td>
                                            <th>규격</th>
                                            <td>{proData.de_packing_name}</td>
                                        </tr>
                                        <tr>
                                            <th>친환경</th>
                                            <td>{proData.de_quality_name}</td>
                                            <th>등급</th>
                                            <td>{proData.de_high_name}</td>
                                        </tr>
                                        <tr>
                                            <th>수량</th>
                                            <td className="tx_r">{proData.de_quantity}개</td>
                                            <th>운임비</th>
                                            <td className="tx_r">{proData.de_cost2}원</td>
                                        </tr>
                                        <tr>
                                            <th>단수</th>
                                            <td className="tx_r">{proData.de_dansu}단</td>
                                            <th className="sky_bg">희망단가</th>
                                            <td className="tx_r sky_bg">{proData.de_hope_pri}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'25%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>{proData.de_cont1}</td>
                                        </tr>
                                        <tr>
                                            <th>당도,산도 등</th>
                                            <td>{proData.de_cont2}</td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td>{proData.de_cont3}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {proData.de_status >= 14 && <>
                                <p className="f_16 medium tp20 lp10">배송정보</p>
                                <div className="custom_table gray_table tm10">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'25%'}} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>배송요청일자</th>
                                                <td>{moment(proData.de_deli_req_date).format("YYYY-MM-DD")}</td>
                                            </tr>
                                            <tr>
                                                <th>수령자명</th>
                                                <td>{proData.de_deli_rece_nm}</td>
                                            </tr>
                                            <tr>
                                                <th>배송지</th>
                                                <td>
                                                    {proData.de_deli_rece_addr1 !== null ?
                                                        proData.de_deli_rece_addr1+proData.de_deli_rece_addr2+'('+proData.de_deli_rece_post+')'
                                                        : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>메모</th>
                                                <td>{proData.de_deli_rece_remk !== null ? proData.de_deli_rece_remk : '-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>}
                            {proData.de_status >= 15 && <>
                                <p className="f_16 medium tp20 lp10">운임정보</p>
                                <div className="custom_table gray_table tm10">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'25%'}} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>운송비</th>
                                                <td className="tx_r">{proData.de_cost2}원</td>
                                            </tr>
                                            <tr>
                                                <th>기사명<span className="f_12">(예금주)</span></th>
                                                <td>{proData.de_deli_nm}</td>
                                            </tr>
                                            <tr>
                                                <th>전화번호</th>
                                                <td>{CF.MakePhoneForm(proData.de_deli_tel)}</td>
                                            </tr>
                                            <tr>
                                                <th>차량번호</th>
                                                <td>{proData.de_deli_car_num}</td>
                                            </tr>
                                            <tr>
                                                <th>운임 송금은행</th>
                                                <td>{proData.de_deli_bank_nm}</td>
                                            </tr>
                                            <tr>
                                                <th>배송일자</th>
                                                <td>{moment(proData.de_deli_date).format('YYYY-MM-DD')}</td>
                                            </tr>
                                            <tr>
                                                <th>운임계좌</th>
                                                <td>{proData.de_deli_bank_num}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>}

                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
};

export default ProDetailPop;