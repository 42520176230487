import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { reasonPop, reasonPopOk ,confirmPop } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";
import { enum_api_uri } from "../../../../config/enum";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "../Function";

const ReasonPop = () => {
    const a_bid_success = enum_api_uri.a_bid_success;
    const a_bid_fail = enum_api_uri.a_bid_fail;
    const a_bid_return = enum_api_uri.a_bid_return;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [txt,setTxt] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [doneConfirm, setDoneConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);
    const [error, setError] = useState(false);
    const txtArea = useRef();

    const closePopHandler = () => {
        dispatch(reasonPop({reasonPop:null}));
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
            setDoneConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setTxt(txt);
    },[txt]);

    useEffect(()=>{
        txtArea.current.focus();
    },[]);

    //입찰-희망단가보다 응찰가가 낮을때 낙찰처리사유 저장하기
    const bidCloseHandler = () => {
        if(txt.length > 0){
            setError(false);
            let getData = localStorage.getItem("productData");
                getData = JSON.parse(getData);
            let body = {
                idx:popup.reasonPopbidSuccess.idx,
                de_amer_code:popup.reasonPopbidSuccess.asso_code,
                price:popup.reasonPopbidSuccess.price,
                de_close_reason:txt
            };
            axios.put(`${a_bid_success.replace(':de_date_online', getData.de_date_online).replace(':de_no_online', getData.de_no_online).replace(':de_seq', getData.de_seq).replace(':de_seq2', getData.de_seq2)}`,body,{
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    //productData 상태코드 변경
                    let newProductData = JSON.parse(localStorage.getItem('productData'));
                    newProductData['de_status'] = "12";
                    localStorage.setItem("productData", JSON.stringify(newProductData));

                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'사유가 저장되었습니다.',
                        confirmPopBtn:1,
                    }));
                    setDoneConfirm(true);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //사유미작성시 에러문구뜨기
        else{
            setError(true);
        }
    };

    //입찰-유찰사유 저장하기
    const bidFailCloseHandler = () => {
        if(txt.length > 0){
            setError(false);
            let getData = localStorage.getItem("productData");
                getData = JSON.parse(getData);
            let body = {
                de_close_reason:txt
            };
            axios.put(`${a_bid_fail.replace(':de_date_online', getData.de_date_online).replace(':de_no_online', getData.de_no_online).replace(':de_seq', getData.de_seq).replace(':de_seq2', getData.de_seq2)}`, body, {
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    //productData 상태코드 변경
                    let newProductData = JSON.parse(localStorage.getItem('productData'));
                    newProductData['de_status'] = "13";
                    localStorage.setItem("productData", JSON.stringify(newProductData));

                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'사유가 저장되었습니다.',
                        confirmPopBtn:1,
                    }));
                    setDoneConfirm(true);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //사유미작성시 에러문구뜨기
        else{
            setError(true);
        }
    };

    //입찰-결과취소사유 저장하기
    const bidReturnHandler = () => {
        if(txt.length > 0){
            setError(false);
            let getData = localStorage.getItem("productData");
                getData = JSON.parse(getData);
            let body = {
                de_close_reason:txt
            };
            axios.put(`${a_bid_return.replace(':de_date_online', getData.de_date_online).replace(':de_no_online', getData.de_no_online).replace(':de_seq', getData.de_seq).replace(':de_seq2', getData.de_seq2)}`, body, {
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    //productData 상태코드 변경
                    let newProductData = JSON.parse(localStorage.getItem('productData'));
                    newProductData['de_status'] = "9";
                    localStorage.setItem("productData", JSON.stringify(newProductData));

                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'사유가 저장되었습니다.',
                        confirmPopBtn:1,
                    }));
                    setDoneConfirm(true);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //사유미작성시 에러문구뜨기
        else{
            setError(true);
        }
    };

    const saveHandler = () => {
        if(popup.reasonPopTxt === "낙찰처리"){
            bidCloseHandler();
        }if(popup.reasonPopTxt === "유찰처리"){
            bidFailCloseHandler();
        }if(popup.reasonPopTxt === "결과 취소"){
            bidReturnHandler();
        }
    };

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont2">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">사유 작성</p>
                        <p className="txt tp10"><span className="txt_black medium">※ 주의</span><br/>{popup.reasonPopTxt}에 관한 사유를 작성해주세요.</p>
                    </div>
                    <div className="">
                        <div className="custom_text">
                            <textarea rows="7" ref={txtArea} value={txt} onChange={(e)=>{setTxt(e.currentTarget.value);}} />
                        </div>
                        {error && <div className="alert_txt f_12">사유를 작성해주세요.</div>}
                        <div className="flex_center tp20">
                            <button className="btn_type rm10" onClick={saveHandler}>저장</button>
                            <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont small_pop">
                    <div className="mo_pop_tit">
                        <p className="tit">사유 작성</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <p className="f_13 txt_gray tp15 bp10"><span className="txt_black medium">※ 주의</span><br/>{popup.reasonPopTxt}에 관한 사유를 작성해주세요.</p>
                    <div className="">
                        <div className="custom_text">
                            <textarea rows="7" ref={txtArea} value={txt} onChange={(e)=>{setTxt(e.currentTarget.value);}} />
                        </div>
                        {error && <div className="alert_txt f_12">사유를 작성해주세요.</div>}
                        <div className="btn_half_box tp10">
                            <button className="btn_type rm10" onClick={saveHandler}>저장</button>
                            <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 

        {/* 사유작성완료 confirm팝업 */}
        {doneConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{
            setDoneConfirm(false);
            dispatch(reasonPop({reasonPop:null}));
            dispatch(reasonPopOk(true));
        }}/>} 
    </>);
};

export default ReasonPop;