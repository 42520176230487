import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../../../images/logo.svg"

const MoHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state)=>state.user);
    const common = useSelector((state)=>state.common);
    const [mainPage, setMainPage] = useState(false);
    const [gnbOn, setGnbOn] = useState(false);

    useEffect(()=>{
        setMainPage(mainPage);
    },[mainPage]);

    //메인페이지에서는 백헤더 안보이기
    useEffect(() => {
        if(location.pathname === '/'){
            setMainPage(true);
        }else{
            setMainPage(false);
        }
    }, [location]);

    //페이지변경되면 gnb 닫기
    useEffect(()=>{
        setGnbOn(false);
    },[location.pathname]);

    // 이전버튼 클릭시
    const backHandler = () => {
        // 중도매인 전광판페이지일때
        if(location.pathname.includes("/merchant/auction/")){
            navigate("/merchant/auction");
            localStorage.getItem("firstJoin");
            localStorage.setItem("firstJoin", false);
        }else{
            navigate(-1);
        }
    };

    return(<>
        {!mainPage &&
            <header className="header">
                <div className="inner">
                    <button className="btn_back" onClick={backHandler}>이전</button>
                    <p className="tit">{common.moTitle}</p>
                    <button type="button" className="btn_menu" onClick={()=>{setGnbOn(true)}}>메뉴버튼</button>
                </div>
                <div className={`gnb_wrap ${gnbOn ? "on" : ""}`}>
                    <div className="dim" onClick={()=>{setGnbOn(false)}}></div>
                    <div className="gnb_area">
                        <Link to="/" className="gnb_logo">
                            <img src={logo} alt="로고" />
                        </Link>

                        {/* 출하주 메뉴 */}
                        {user.kind === 1 &&
                            <ul className="gnb">
                                <li><Link to="/shipper/invoice-register">송품장 등록</Link></li>
                                <li><Link to="/shipper/invoice-check">송품장 조회</Link></li>
                                <li><Link to="/shipper/online-check">온라인 거래결과 조회</Link></li>
                            </ul>
                        }

                        {/* 경매사 메뉴 */}
                        {user.kind === 7 &&
                            <ul className="gnb">
                                <li><Link to="/auctioneer/mo/receiving">입하작업</Link></li>
                                <li><Link to="/auctioneer/bid-result">경매/입찰 결과조회</Link></li>
                                <li><Link to="/auctioneer/statistics">통계</Link></li>
                            </ul>
                        }

                        {/* 중도매인 메뉴 */}
                        {user.kind === 3 &&
                            <ul className="gnb">
                                <li><Link to="/merchant/shipment-products">출하물품 조회</Link></li>
                                <li><Link to="/merchant/online-bid">온라인 입찰</Link></li>
                                <li><Link to="/merchant/auction">온라인 경매</Link></li>
                                <li><Link to="/merchant/bid-products">낙찰물품 조회</Link></li>
                            </ul>
                        }
                    </div>
                    <button type="button" className="btn_close" onClick={()=>{setGnbOn(false)}}>모바일메뉴닫기버튼</button>
                </div>
            </header>
        }
    </>);
};

export default MoHeader;