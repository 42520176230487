import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { enum_api_uri } from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function";
import { loadingPop } from "../../../store/popupSlice";
import logo from "../../../images/logo_ic.svg";


const StatisticsPdf = () => {
    const a_statistics_month = enum_api_uri.a_statistics_month;
    const a_statistics_day = enum_api_uri.a_statistics_day;
    const token = localStorage.getItem("token");
    const { type, date_start, date_end, code } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [monthDataList, setMonthDataList] = useState([]);
    const [dayDataList, setDayDataList] = useState([]);
    let sumTon = 0;
    let sumAmt1 = 0;
    let sumQuantity = 0;
    let sumDayTon = 0;
    let sumDayAmt1 = 0;


    //월별통계 가져오기
    const getMonthList = () => {
        axios.get(`${a_statistics_month}?ip_auct_date_start=${date_start}&ip_auct_date_end=${date_end}${code > 0 ? '&is_code='+code : ''}`,
            // {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                const data = res.data;
                setMonthDataList(data);

                // 대상기간날짜 변환
                const dateS = moment(date_start).format('YYYY.MM.DD');
                const dateE = moment(date_end).format('YYYY.MM.DD');
                setDateStart(dateS);
                setDateEnd(dateE);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //일별통계 가져오기
    const getDayList = (val) => {
        axios.get(`${a_statistics_day}?ip_auct_date_start=${date_start}&ip_auct_date_end=${date_end}${code > 0 ? '&is_code='+code : ''}`,
            // {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                const data = res.data;
                setDayDataList(data);

                // 대상기간날짜 변환
                const dateS = moment(date_start).format('YYYY.MM.DD');
                const dateE = moment(date_end).format('YYYY.MM.DD');
                setDateStart(dateS);
                setDateEnd(dateE);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    useEffect(()=>{
        if(type == "month"){
            getMonthList();
        }else if(type == "day"){
            getDayList();
        }
    },[type, date_start, date_end, code]);


    //pdf다운로드
    const pdfDownHandler = () => {
        dispatch(loadingPop(true));

        let typeTxt = '';
        if(type == 'month'){
            typeTxt = '월별';
        }else if(type == 'day'){
            typeTxt = '일별'
        }

        const pdf_url = 'https://online.kurifnv.com'+location.pathname;
        const pdf_name = date_start+date_end+typeTxt;
        const url = 'https://www.winnernet.kr/pdf/';
        const formData = new FormData();
            formData.append("pdf_url", pdf_url);
            formData.append("pdf_name", pdf_name);
            formData.append("folderName", "");
            formData.append("landscapeMode", false);
            formData.append("margin", "");
            formData.append("scale", "");
            formData.append("delaySecond", "");

        axios.post(url, formData,
            {
                //headers:{Authorization: `Bearer ${token}`},
                responseType: 'blob', // 요청 데이터 형식을 blob으로 설정
                "Content-Type": "multipart/form-data",
            }
        )
        .then((res)=>{
            if(res.status === 200){
                dispatch(loadingPop(false));

                // Blob을 File로 변환
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);

                // a 태그를 생성하여 다운로드 링크 설정
                const a = document.createElement('a');
                a.href = url;
                a.download = pdf_name+'.pdf'; // 원하는 파일명으로 설정

                // a 태그를 DOM에 추가하고 클릭하여 다운로드 진행
                document.body.appendChild(a);
                a.click();

                // 사용이 끝난 a 태그를 DOM에서 제거
                document.body.removeChild(a);

                // Blob URL을 해제
                window.URL.revokeObjectURL(url);
            }
        })
        .catch((error) => {
            dispatch(loadingPop(false));
            console.log(error);
        });
    };



    return(<>
        <div className="page_print page_pdf" id="content">
            <p className="top_tit">통계</p>
            {type == 'month' ?
                <>
                    <div className="tx_c">
                        <p className="f_20 medium">이미지 경매 현황</p>
                        <p className="f_15 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                    </div>
                    <div className="flex_between bp10">
                        <p className="f_15">법인명 : 구리청과㈜</p>
                        <p className="f_12 txt_gray">단위 : Kg, 원</p>
                    </div>
                    <div className="custom_table gray_table2">
                        <table>
                            <colgroup>
                                <col style={{width:'auto'}} />
                                <col style={{width:'auto'}} />
                                <col style={{width:'auto'}} />
                                <col style={{width:'auto'}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>물량</th>
                                    <th>금액</th>
                                    <th>비고</th>
                                </tr>
                            </thead>
                            <tbody>
                                {monthDataList.map((cont,i)=>{
                                    const date = moment(cont.de_date).format('YYYY-MM');

                                    //물량,금액 값을 ,를 제외하고 숫자로 변환
                                    const ton = parseFloat(cont.de_ton.replace(/,/g, ''));
                                    const amt1 = parseInt(cont.de_amt1.replace(/,/g, ''));

                                    //물량,금액 합계
                                    sumTon = sumTon + ton; 
                                    sumAmt1 = sumAmt1 + amt1; 
                                    
                                    return(
                                        <tr key={i}>
                                            <td>{date}</td>
                                            <td className="tx_r">{cont.de_ton}</td>
                                            <td className="tx_r">{cont.de_amt1}</td>
                                            <td></td>
                                        </tr>
                                    );
                                })}
                                <tr className="total_tr">
                                    <td>계</td>
                                    <td className="tx_r">{CF.MakeIntComma(sumTon)}</td>
                                    <td className="tx_r">{CF.MakeIntComma(sumAmt1)}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
                : type == 'day' &&
                <>
                    <div className="tx_c">
                        <p className="f_20 medium">이미지 경매 거래 현황</p>
                        <p className="f_15 tp4">{`(대상기간 : ${dateStart} ~ ${dateEnd})`}</p>
                    </div>
                    <div className="flex_between bp10">
                        <p className="f_15">법인명 : 구리청과㈜</p>
                        <p className="f_12 txt_gray">단위 : Kg, 원</p>
                    </div>
                    <div className="custom_table gray_table2 narrow_td">
                        <table>
                            <colgroup>
                                <col style={{'width':'70px'}} />
                                <col style={{'width':'45px'}} />
                                <col style={{'width':'50px'}} />
                                <col style={{'width':'10%'}} />
                                <col style={{'width':'50px'}} />
                                <col style={{'width':'60px'}} />
                                <col style={{'width':'9%'}} />
                                <col style={{'width':'35px'}} />
                                <col style={{'width':'7%'}} />
                                <col style={{'width':'7%'}} />
                                <col style={{'width':'7%'}} />
                                <col style={{'width':'9%'}} />
                                <col style={{'width':'12%'}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>판매<br/>일자</th>
                                    <th>원표<br/>번호</th>
                                    <th>일련<br/>번호</th>
                                    <th>팀</th>
                                    <th>중도<br/>매인</th>
                                    <th>생산자</th>
                                    <th>품종</th>
                                    <th>중량</th>
                                    <th>등급</th>
                                    <th>거래량</th>
                                    <th>경락가</th>
                                    <th>거래물량</th>
                                    <th>거래금액</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dayDataList.map((cont,i)=>{
                                    const date = moment(cont.de_date).format("YYYY-MM-DD");

                                    //거래량,거래물량,거래금액 합계
                                    sumQuantity = sumQuantity + cont.de_quantity;
                                    sumDayTon = sumDayTon + cont.de_ton; 
                                    sumDayAmt1 = sumDayAmt1 + cont.de_amt1; 

                                    return(
                                        <tr key={i}>
                                            <td>{date}</td>
                                            <td>{cont.de_no}</td>
                                            <td>{cont.de_seq}</td>
                                            <td>{cont.is_dept_name}</td>
                                            <td>{cont.de_amer_code}</td>
                                            <td>{cont.de_farm_name}</td>
                                            <td className="txt_gray">{cont.gm_name}</td>
                                            <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_weight)}</td>
                                            <td className="txt_gray">{cont.de_dansu}</td>
                                            <td className="txt_gray tx_r">{CF.MakeIntComma(cont.de_quantity)}</td>
                                            <td className="tx_r">{CF.MakeIntComma(cont.de_price1)}</td>
                                            <td className="tx_r">{CF.MakeIntComma(cont.de_ton)}</td>
                                            <td className="tx_r">{CF.MakeIntComma(cont.de_amt1)}</td>
                                        </tr>
                                    );
                                })}
                                <tr className="total_tr">
                                    <td colSpan={9}>계</td>
                                    <td className="tx_r">{CF.MakeIntComma(sumQuantity)}</td>
                                    <td></td>
                                    <td className="tx_r">{CF.MakeIntComma(sumDayTon)}</td>
                                    <td className="tx_r">{CF.MakeIntComma(sumDayAmt1)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }

            <div className="flex_between tp20">
                <div className="flex logo">
                    <img src={logo} alt="로고"/>
                    <span className="lp10">구리청과 주식회사</span>
                </div>
                <ul className="flex">
                    <li className="flex rp40">
                        <span className="rp15">전 화</span>
                        <span className="txt_gray">031) 556 - 2000</span>
                    </li>
                    <li className="flex">
                        <span className="rp15">팩 스</span>
                        <span className="txt_gray">031) 551 - 9200</span>
                    </li>
                </ul>
            </div>
            <div className="tp10 flex_end">
                <button className="btn_type3" onClick={pdfDownHandler}>PDF 파일 다운로드</button>
            </div>
        </div>
    </>);
};

export default StatisticsPdf;