import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { closePop, restartAuction, confirmPop, restartPop } from "../../../../store/popupSlice";
import ConfirmPop from "./ConfirmPop";
import { enum_api_uri } from "../../../../config/enum";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "../Function";

const RestartPop = () => {
    const a_bid_restart = enum_api_uri.a_bid_restart;
    const a_bid_restart_list = enum_api_uri.a_bid_restart_list;
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state) => state.popup);
    const [selList, setSelList] = useState([]);
    const [selValue, setSelValue] = useState([]);

    const closePopHandler = () => {
        dispatch(restartPop(null));
    }

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setSelList(selList);
    },[selList]);

    useEffect(()=>{
        setSelValue(selValue);
        console.log(selValue)
    },[selValue]);

    //재경매사유 리스트 가져오기
    useEffect(()=>{
        axios.get(`${a_bid_restart_list}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                setSelList(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);

    //재경매사유 저장하기
    const saveHandler = () => {
        if(selValue.length > 0){
            let getData = localStorage.getItem("auctProData");
                getData = JSON.parse(getData);
            let body = {
                de_close_reason : selValue[0],
                de_close_code : selValue[1],
            };
            axios.put(`${a_bid_restart.replace(':de_date_online', getData.de_date_online).replace(':de_no_online', getData.de_no_online).replace(':de_seq', getData.de_seq).replace(':de_seq2', getData.de_seq2)}`, body, {
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    dispatch(closePop(null));
                    dispatch(restartAuction(true));
                    setTimeout(()=>{
                        dispatch(restartAuction(false));
                    },100);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        //사유미작성시 에러문구뜨기
        else{
            setError(true);
        }
    };

    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont pop_cont2">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">재경매 사유 작성</p>
                        <p className="txt tp10"><span className="txt_black medium">※ 주의</span><br/>재경매에 관한 사유를 선택해주세요.</p>
                    </div>
                    <div className="">
                        <div className="custom_select">
                            <select onChange={(e)=>{
                                const selectedValue = e.currentTarget.value; // 선택된 값
                                const selectedIndex = e.currentTarget.selectedIndex; // 선택된 옵션의 인덱스
                                const selectedOption = e.currentTarget.options[selectedIndex]; // 선택된 옵션 엘리먼트
                                const etCode = selectedOption.getAttribute("data-et-code"); // data-et-code 속성값 가져오기
                                const val = [selectedValue,etCode];
                                setSelValue([...val]);
                            }}>
                                <option value="">사유선택</option>
                                {selList && selList.map((list,i)=>{
                                    return(
                                        <option key={i} value={list.et_name} data-et-code={list.et_code}>{list.et_name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {error && <div className="alert_txt f_12">사유를 선택해주세요.</div>}
                        <div className="flex_center tp20">
                            <button className="btn_type rm10" onClick={saveHandler}>저장</button>
                            <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="mo_pop_cont small_pop">
                    <div className="mo_pop_tit">
                        <p className="tit">재경매 사유 작성</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <p className="f_13 txt_gray tp15 bp10"><span className="txt_black medium">※ 주의</span><br/>재경매에 관한 사유를 선택해주세요.</p>
                    <div className="">
                        <div className="custom_select">
                            <select onChange={(e)=>{
                                const selectedValue = e.currentTarget.value; // 선택된 값
                                const selectedIndex = e.currentTarget.selectedIndex; // 선택된 옵션의 인덱스
                                const selectedOption = e.currentTarget.options[selectedIndex]; // 선택된 옵션 엘리먼트
                                const etCode = selectedOption.getAttribute("data-et-code"); // data-et-code 속성값 가져오기
                                const val = [selectedValue,etCode];
                                setSelValue([...val]);
                            }}>
                                <option value="">사유선택</option>
                                {selList && selList.map((list,i)=>{
                                    return(
                                        <option key={i} value={list.et_name} data-et-code={list.et_code}>{list.et_name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {error && <div className="alert_txt f_12">사유를 선택해주세요.</div>}
                        <div className="btn_half_box tp10">
                            <button className="btn_type rm10" onClick={saveHandler}>저장</button>
                            <button className="btn_type4" onClick={closePopHandler}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default RestartPop;