import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { enum_api_uri } from "../../../../config/enum";
import { closePop } from "../../../../store/popupSlice"; 
import { dealList } from "../../../../store/userSlice";
import InputDatepicker from "../InputDatepicker";
import ConfirmPop from "./ConfirmPop";
import { confirmPop } from "../../../../store/popupSlice"; 
import * as CF from "../Function"; // (customFunction)

const DealHistoryPop = () => {
    let today = new Date();
    const [dealDate, setDealDate] = useState(today);
    const [confirm, setConfirm] = useState(false);
    const user = useSelector((state) => state.user);
    const popup = useSelector((state) => state.popup);
    const dispatch = useDispatch();
    const c_deal_statistics = enum_api_uri.c_deal_statistics;

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    const dealDateChange = (date) => {
        setDealDate(date);
    };

    useEffect(() => {
        let token = localStorage.getItem("token");
        let today = new Date(dealDate);
        let year = today.getFullYear();
        let month = ('0' + (today.getMonth() + 1)).slice(-2);
        let day = ('0' + today.getDate()).slice(-2);
        let dateString = year + month + day;
        axios.get(`${c_deal_statistics}?de_date_online=${dateString}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let deal = res.data;
                dispatch(dealList(deal));
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    },[dealDate]);

    return(<>
        <div className="pop_wrap deal_popup">
            <div className="dim" onClick={closePopHandler}></div>
            <div className="pop_cont pop_cont2">
                <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                <div className="pop_tit">
                    <p className="tit">거래내역</p>
                </div>
                <div className="inner_box">
                    <ul className="flex bp10">
                        <li className="flex w_60">
                            <p className="f_13 txt_gray rm5">거래일자</p>
                            <div className="date_input w_65">
                                <InputDatepicker selectedDate={dealDate} ChangeHandler={dealDateChange} />
                            </div>
                        </li>
                        <li className="flex_between w_40">
                            <p className="f_13 txt_gray">완료</p>
                            <p className="f_20 bold txt_sky">{user.dealList.st_11 || 0}</p>
                        </li>
                    </ul>
                    <div className="deal_box">
                        {/* 출하주,경매사 일경우 */}
                        {user.kind === 1 || user.kind === 7 ? (
                            <ul className="txt_ul">
                                <li className="bg_yellow">
                                    <p>입하</p>
                                    <strong>{user.dealList.st_1 || 0}</strong>
                                </li>
                                <li className="bg_blue">
                                    <p>승인</p>
                                    <strong>{user.dealList.st_2 || 0}</strong>
                                </li>
                                <li className="bg_blue">
                                    <p>미승인</p>
                                    <strong>{user.dealList.st_3 || 0}</strong>
                                </li>
                                <li className="bg_green">
                                    <p>대기</p>
                                    <strong>{user.dealList.st_4 || 0}</strong>
                                </li>
                                <li className="bg_green">
                                    <p>진행</p>
                                    <strong>{user.dealList.st_5 || 0}</strong>
                                </li>
                                <li className="bg_green">
                                    <p>종료</p>
                                    <strong>{user.dealList.st_6 || 0}</strong>
                                </li>
                                <li className="w_25 bg_purple">
                                    <p>낙찰</p>
                                    <strong>{user.dealList.st_7 || 0}</strong>
                                </li>
                                <li className="w_25 bg_purple">
                                    <p>유찰</p>
                                    <strong>{user.dealList.st_8 || 0}</strong>
                                </li>
                                <li className="w_25 bg_orange">
                                    <p>운송대기</p>
                                    <strong>{user.dealList.st_9 || 0}</strong>
                                </li>
                                <li className="w_25 bg_orange">
                                    <p>배송</p>
                                    <strong>{user.dealList.st_10 || 0}</strong>
                                </li>
                            </ul>
                        ): null}
                        
                        {/* 중도매인 일경우 */}
                        {user.kind === 3 && (
                            <ul className="txt_ul">
                                <li className="bg_green">
                                    <p>대기</p>
                                    <strong>{user.dealList.st_4 || 0}</strong>
                                </li>
                                <li className="bg_green">
                                    <p>진행</p>
                                    <strong>{user.dealList.st_5 || 0}</strong>
                                </li>
                                <li className="bg_green">
                                    <p>종료</p>
                                    <strong>{user.dealList.st_6 || 0}</strong>
                                </li>
                                <li className="w_25 bg_purple">
                                    <p>낙찰</p>
                                    <strong>{user.dealList.st_7 || 0}</strong>
                                </li>
                                <li className="w_25 bg_purple">
                                    <p>유찰</p>
                                    <strong>{user.dealList.st_8 || 0}</strong>
                                </li>
                                <li className="w_25 bg_orange">
                                    <p>운송대기</p>
                                    <strong>{user.dealList.st_9 || 0}</strong>
                                </li>
                                <li className="w_25 bg_orange">
                                    <p>배송</p>
                                    <strong>{user.dealList.st_10 || 0}</strong>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <div className="tp20">
                    <button className="btn_type2 w_100" onClick={closePopHandler}>닫기</button>
                </div>
            </div>
        </div>

            {/* 확인confirm팝업 */}
            {confirm && <ConfirmPop />}
        </>
    );
};

export default DealHistoryPop;