import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="cont">
                <div className="txt_box">
                    <ul className="txt_ul">
                        <li>구리청과 주식회사</li>
                        <li>대표이사 : 이석우</li>
                        <li>주소 : 경기도 구리시 동구릉로136번길 90(인창동)</li>
                    </ul>
                    <ul className="txt_ul">
                        <li>고객센터 : 031-556-2000</li>
                        <li>팩스 : 031-551-9200</li>
                        <li>정산(과일) : 031-560-6155</li>
                        <li>정산(채소) : 031-560-6156</li>
                    </ul>
                    <ul className="txt_ul">
                        <li>이메일 : webmaster@kurifnv.com</li>
                        <li>이메일 무단 수집 거부</li>
                    </ul>
                </div>
                <ul className="txt_ul">
                    <li>
                        <Link>이용약관</Link>
                    </li>
                    <li>
                        <Link>개인정보처리방침</Link>
                    </li>
                </ul>
                <p className="copy">Copyright ⓒ 2019 구리청과 All Right Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
