import { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { isBrowser, isMobile } from 'react-device-detect';

const DaumPost = (props) => {

    const onCompletePost = (data) => {
        console.log(data);
        let fullAddress = data.address;
        let extraAddress = '';
        // userSelectedType 선택한 값 타입
        // if (data.addressType === 'R') {
        if (data.userSelectedType === 'R') {    
            //선택값이 도로명이면
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }


        if (data.userSelectedType === 'J') {    
            //선택값이 지번이면
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress = data.jibunAddress;
        }

        // console.log(data)
        // console.log(fullAddress)
        // console.log(data.zonecode)

        props.setcompany({
            ...props.company,
            address:fullAddress,
            zonecode:data.zonecode
        })

        if(fullAddress){
            setVisible(false);
        }
    };

    const postCodeStylePc = {
        width:"400px",
        height:"470px",
        border:"1px solid #ddd"
    };

    const postCodeStyleMo = {
        width:"300px",
        height:"400px",
        border:"1px solid #ddd"
    };

    const [visible, setVisible] = useState(true); // 다음 우편번호 컴포넌트의 노출여부 상태

    return(<>
        {visible &&
            <div className='daum_pop'>
                <div className='top_box'>
                    <button className='btn_close' onClick={props.handleComplete}>닫기버튼</button>
                </div>
                <DaumPostcodeEmbed style={isBrowser ? postCodeStylePc : isMobile && postCodeStyleMo} autoClose onComplete={onCompletePost} />
            </div>
        }
    </>);

};

export default DaumPost;