import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appMemId } from "../../store/userSlice";


const AppLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //앱자동로그인 체크후 메인페이지 이동
    useEffect(() => {
        let intervalCounter = 0;
      
        const appLoginInterval = setInterval(() => {
            const app_login = localStorage.getItem('app_trying_login');
            const mem_id = localStorage.getItem('app_mem_id');
      
            if (app_login) {
                if (mem_id) {
                    dispatch(appMemId(mem_id));
                }
                clearInterval(appLoginInterval);
                navigate('/');
            }
      
            intervalCounter++;
      
            //1초안에 app_login 값이 없으면 메인페이지 이동
            if (intervalCounter >= 10) {
                clearInterval(appLoginInterval);
                navigate('/');
            }
        }, 100);
      
        return () => clearInterval(appLoginInterval);
    }, []);
      


    return(<></>);
};

export default AppLogin;