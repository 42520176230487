import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { enum_api_uri, enum_sell_kind, enum_from_kind, enum_de_status, debounce, debounce2, enum_mo_title, enum_ip_status } from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import { useDispatch, useSelector } from "react-redux";
import { PatternFormat, NumericFormat } from "react-number-format";
import { defaultInfoModifyPop, proDivisionPop, confirmPop, mediaPop, messageSetPop, orderingPop, closePop } from "../../../store/popupSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle } from "../../../store/commonSlice";


const MoReceivingDetail = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate2, setStartDate2] = useState(null);
    const [endDate2, setEndDate2] = useState(null);
    const api_uri = enum_api_uri.api_uri;
    const a_list_invoces = enum_api_uri.a_list_invoces;
    const a_list_product = enum_api_uri.a_list_product;
    const a_list_product2 = enum_api_uri.a_list_product2;
    const a_invoice_status = enum_api_uri.a_invoice_status;
    const c_search_farms= enum_api_uri.c_search_farms;
    const c_search_gm_stmd = enum_api_uri.c_search_gm_stmd;
    const c_search_gm = enum_api_uri.c_search_gm;
    const c_search_origin = enum_api_uri.c_search_origin;
    const a_copy_product = enum_api_uri.a_copy_product;
    const a_status_product = enum_api_uri.a_status_product;
    const a_delt_product = enum_api_uri.a_delt_product;
    const a_msg_product = enum_api_uri.a_msg_product;
    const a_invoice_vali = enum_api_uri.a_invoice_vali;
    registerLocale('ko', ko);
    const token = localStorage.getItem("token");
    const popup = useSelector((state)=>state.popup);
    const dispatch = useDispatch();
    const location = useLocation();
    const proListBox = useRef();
    const [invoiceList, setInvoiceList] = useState([]);
    const [onInvoice, setOnInvoice] = useState(0);
    const [invoiceData, setInvoiceData] = useState({});
    const [proList, setProList] = useState([]);
    const [onProduct, setOnProduct] = useState(0);
    const [auctDateStart, setAuctDateStart] = useState();
    const [auctDateEnd, setAuctDateEnd] = useState();
    const [auctDateError, setAuctDateError] = useState(false);
    const [invoApprovalConfirm, setInvoApprovalConfirm] = useState(false);
    const [invoCancelConfirm, setInvoCancelConfirm] = useState(false);
    const [proCopyConfirm, setProCopyConfirm] = useState(false);
    const [proAllApprovalConfirm, setProAllApprovalConfirm] = useState(false);
    const [proApprovalConfirm, setProApprovalConfirm] = useState(false);
    const [proCancelConfirm, setProCancelConfirm] = useState(false);
    const [proUnapprovalConfirm, setProUnapprovalConfirm] = useState(false);
    const [proDeltConfirm, setProDeltConfirm] = useState(false);
    const [proModifyConfirm, setProModifyConfirm] = useState(false);
    const [proModifyNotConfirm, setProModifyNotConfirm] = useState(false);
    const [smsSendConfirm, setSmsSendConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [farmNameInput, setFarmNameInput] = useState('');
    const [farmsDrop, setFarmsDrop] = useState(false);
    const [farms, setFarms] = useState([]);
    const [farmCodeData, setFarmCodeData] = useState('');
    const [farmNameInputClick, setFarmNameInputClick] =  useState(false);
    const [gmCodeInput, setGmCodeInput] = useState('');
    const [gmNameInput, setGmNameInput] = useState('');
    const [gmNameDrop, setGmNameDrop] = useState(false);
    const [gmStmdName, setGmStmdName] =  useState([]);
    const [gmName, setGmName] =  useState([]);
    const [gmNameInputClick, setGmNameInputClick] = useState(false);
    const [packingInput, setPackingInput] = useState('');
    const [packingCode, setPackingCode] = useState('');
    const [packingDrop, setPackingDrop] = useState(false);
    const [packing, setPacking] =  useState([]);
    const [packingInputClick, setPackingInputClick] =  useState(false);
    const [qualityInput, setQualityInput] = useState('');
    const [qualityCode, setQualityCode] = useState('');
    const [qualityDrop, setQualityDrop] = useState(false);
    const [quality, setQuality] =  useState([]);
    const [qualityInputClick, setQualityInputClick] =  useState(false);
    const [highInput, setHighInput] = useState('');
    const [highCode, setHighCode] = useState('');
    const [highDrop, setHighDrop] = useState(false);
    const [high, setHigh] =  useState([]);
    const [highInputClick, setHighInputClick] =  useState(false);
    const [deWeight, setDeWeight] = useState('');
    const [deQuantity, setDeQuantity] = useState('');
    const [deCost2, setDeCost2] = useState('');
    const [deDansu, setDeDansu] = useState('');
    const [deHopePri, setDeHopePri] = useState('');
    const [deCont1, setDeCont1] = useState('');
    const [deCont2, setDeCont2] = useState('');
    const [deCont3, setDeCont3] = useState('');
    const [memo, setMemo] = useState('');
    const [deImg1, setDeImg1] = useState('');
    const [deImg2, setDeImg2] = useState('');
    const [deVideo, setDeVideo] = useState('');
    const [farmPhone, setFarmPhone] = useState('');
    const [smsText, setSmsText] = useState('');
    const { invoiceId } = useParams();
    const ipDate = invoiceId.substring(0, 8);
    const ipNoOnline = invoiceId.substring(9, 14);
    const [dropLoading, setDropLoading] = useState(false);
    const [tableDate, setTableDate] = useState('');
    const [tableNum, setTableNum] = useState('');
    const [checkedListId,setCheckedListId] = useState([]);
    const [checkedListVal,setCheckedListVal] = useState([]);
    const [checkedDeDate,setCheckedDeDate] = useState([]);
    const [checkedDeNo,setCheckedDeNo] = useState([]);
    const [checkedDeSeq,setCheckedDeSeq] = useState([]);
    const [checkedDeSeq2,setCheckedDeSeq2] = useState([]);
    const [allChecked, setAllChecked] = useState(true);
    const [auctStartHour, setAuctStartHour] = useState('');
    const [auctStartMinute, setAuctStartMinute] = useState('');
    const [auctEndHour, setAuctEndHour] = useState('');
    const [auctEndMinute, setAuctEndMinute] = useState('');
    const [hourOptions, setHourOptions] = useState([
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
    ]);
    const [minuteOptions, setMinuteOptions] = useState([
        "00",
        "10",
        "20",
        "30",
        "40",
        "50",
    ]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf('/');
            let tit = path.substring(0, idx);
                tit = enum_mo_title[tit];
            dispatch(moTitle(tit));
        }
    },[]);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    useEffect(()=>{
        setCheckedListId(checkedListId);

        if(checkedListId.length >= proList.length){
            setAllChecked(true);
        }else{
            setAllChecked(false);
        }
    },[checkedListId]);

    useEffect(()=>{
        setCheckedListVal(checkedListVal);
        console.log(checkedListVal);
        let de_date_online = [];
        let de_no_online = [];
        let de_seq = [];
        let de_seq2 = [];
        if(checkedListVal.length > 0){
            checkedListVal.map(data => {
                const data_arr = data.split(",");
                de_date_online.push(data_arr[0]);
                de_no_online.push(data_arr[1]);
                de_seq.push(data_arr[2]);
                de_seq2.push(data_arr[3]);
                setCheckedDeDate(de_date_online);
                setCheckedDeNo(de_no_online);
                setCheckedDeSeq(de_seq);
                setCheckedDeSeq2(de_seq2);
            })
        }else{
            setCheckedDeDate([]);
            setCheckedDeNo([]);
            setCheckedDeSeq([]);
            setCheckedDeSeq2([]);
        }
    },[checkedListVal]);

    // 전체체크박스
    const allCheckHandler = (checked) => {
        setAllChecked(!allChecked);
        if(checked){
            let checkedId = [];
            checkedId = proList.map((pro,i)=>{
                i = i+1;
                return "pro_check_" + i;
            });
            setCheckedListId(checkedId);

            let checkedVal = [];
            checkedVal = proList.map((pro)=>{
                return pro.de_date_online + ',' + pro.de_ip_no + ',' + pro.de_seq + ',' + pro.de_seq2;
            });
            setCheckedListVal(checkedVal);
        }else{
            setCheckedListId([]);
            setCheckedListVal([]);
        }
    };

    //체크박스
    const checkHandler = (checked, id, val) => {
        if(checked){
            setCheckedListId([...checkedListId, id]);
            setCheckedListVal([...checkedListVal, val]);
        }else if(!checked && checkedListId.includes(id)){
            setCheckedListId(checkedListId.filter((el)=>el !== id));
            setCheckedListVal(checkedListVal.filter((el)=>el !== val));
        }
    };

    useEffect(()=>{
        setDropLoading(dropLoading);
    },[dropLoading]);
    
    useEffect(()=>{
        setInvoiceData(invoiceData);
        console.log(invoiceData);
    },[invoiceData]);

    useEffect(()=>{
        setAuctDateStart(auctDateStart);
    },[auctDateStart]);

    useEffect(()=>{
        setAuctDateEnd(auctDateEnd);
    },[auctDateEnd]);

    useEffect(()=>{
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setFarmNameInputClick(farmNameInputClick);
    },[farmNameInputClick]);

    useEffect(() => {
        setDeWeight(deWeight);
    },[deWeight]);

    useEffect(() => {
        setFarmPhone(farmPhone);
    }, [farmPhone]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setInvoApprovalConfirm(false);
            setInvoCancelConfirm(false);
            setProCopyConfirm(false);
            setProAllApprovalConfirm(false);
            setProApprovalConfirm(false);
            setProCancelConfirm(false);
            setProUnapprovalConfirm(false);
            setProDeltConfirm(false);
            setProModifyConfirm(false);
            setProModifyNotConfirm(false);
            setSmsSendConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    const startDate2Change = (date) => {
        setStartDate2(date);
    };
    
    const endDate2Change = (date) => {
        setEndDate2(date);
    };
    
    // 페이지들어오면 최근송품장리스트 뿌려주기
    useEffect(()=>{
        axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                res.data.ip_reg_dt = moment(res.data.ip_reg_dt).utc().format('YYYY년 MM월 DD일 HH시 mm분 ss초');

                //경매/입찰시간
                let [strDate, strHour, strMinute] = CF.getDateFormat(res.data.ip_auct_date_start);
                let [endDate, endHour, endMinute] = CF.getDateFormat(res.data.ip_auct_date_end);
                const defaultDate = CF.getDefaultBiddingDate();

                if(strDate.length > 0){
                    setAuctDateStart(strDate);
                }else{
                    setAuctDateStart(new Date(defaultDate.start_date));
                }

                if(strHour.length > 0){
                    setAuctStartHour(strHour);
                }else{
                    setAuctStartHour(defaultDate.start_hour);
                }
                
                if(strMinute.length > 0){
                    setAuctStartMinute(strMinute);
                }else{
                    setAuctStartMinute(defaultDate.start_minute);
                }

                if(endDate.length > 0){
                    setAuctDateEnd(endDate);
                }else{
                    setAuctDateEnd(new Date(defaultDate.end_date));
                }

                if(endHour.length > 0){
                    setAuctEndHour(endHour);
                }else{
                    setAuctEndHour(defaultDate.end_hour);
                }

                if(endMinute.length > 0){
                    setAuctEndMinute(endMinute);
                }else{
                    setAuctEndMinute(defaultDate.end_minute);
                }

                let ip_status = Number(res.data.ip_status.trim());
                res.data.ip_status = enum_ip_status[ip_status][0];
                let ip_s_kind = Number(res.data.ip_s_kind.trim());
                res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                let ip_kind = Number(res.data.ip_kind.trim());
                res.data.ip_kind = enum_from_kind[ip_kind];
                if(!res.data.ip_kpp === null){
                    if(res.data.ip_kpp.trim().length > 0){
                        res.data.ip_kpp = res.data.ip_kpp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                }
                if(!res.data.ip_kcp === null){
                    if(res.data.ip_kcp.trim().length > 0){
                        res.data.ip_kcp = res.data.ip_kcp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                }
                if(!res.data.ip_wo_tot === null){
                    if(res.data.ip_wo_tot.trim().length > 0){
                        res.data.ip_wo_tot = res.data.ip_wo_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                }
                if(!res.data.ip_quan_tot === null){
                    if(res.data.ip_quan_tot.trim().length > 0){
                        res.data.ip_quan_tot = res.data.ip_quan_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                }
                res.data.ip_delivery_date_start  = moment(res.data.ip_delivery_date_start.trim()).format('YYYY-MM-DD');
                res.data.ip_delivery_date_end  = moment(res.data.ip_delivery_date_end.trim()).format('YYYY-MM-DD');
                res.data.ip_loca_name = res.data.ip_loca_name.trim();
                res.data.ip_loca = res.data.ip_loca.trim();

                //송품장 원장일자,원장번호
                res.data.ip_date = CF.objValueTrim(res.data.ip_date);
                if(res.data.ip_date.length > 0){
                    setTableDate(res.data.ip_date);
                }else{
                    let today = new Date();
                        today = moment().format("YYYYMMDD");
                    setTableDate(today);
                }
                res.data.ip_no = CF.objValueTrim(res.data.ip_no);
                setTableNum(res.data.ip_no);

                setInvoiceData(res.data);
                let list = [...res.data.detltrns];
                setProList(list);

                // 등록된물품 리스트의 맨처음 물품정보 뿌려주기
                proListBox.current.scrollTo(0,0);
                setOnProduct(0);
                if(list.length > 0){
                    proListClickHandler(list[0]);
                    let productData = JSON.parse(localStorage.getItem('productData'));
                    productData = {
                        de_ip_date:list[0].de_ip_date,
                        de_ip_no:list[0].de_ip_no,
                        de_date_online:list[0].de_date_online,
                        de_no_online:list[0].de_no_online,
                        de_seq:list[0].de_seq,
                        de_seq2:list[0].de_seq2,
                        de_quantity:list[0].de_quantity,
                        de_status:list[0].de_status
                    }
                    localStorage.setItem("productData", JSON.stringify(productData));

                    //등록된물품 체크박스
                    let checkedId = [];
                    checkedId = list.map((pro,i)=>{
                        i = i+1;
                        return "pro_check_" + i;
                    });
                    setCheckedListId(checkedId);

                    let checkedVal = [];
                    checkedVal = list.map((pro)=>{
                        return pro.de_date_online + ',' + pro.de_ip_no + ',' + pro.de_seq + ',' + pro.de_seq2;
                    });
                    setCheckedListVal(checkedVal);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },[]);


    // 송품장 기본정보수정했을때 수정된 송품장정보로 뿌려주기
    useEffect(()=>{
        if(popup.defaultInfoModifyOk){
            axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    let ip_s_kind = res.data.ip_s_kind.trim();
                    res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                    let ip_kind = res.data.ip_kind.trim();
                    res.data.ip_kind = enum_from_kind[ip_kind];
                    if(!res.data.ip_kpp === null){
                        if(res.data.ip_kpp.trim().length > 0){
                            res.data.ip_kpp = res.data.ip_kpp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                    if(!res.data.ip_kcp === null){
                        if(res.data.ip_kcp.trim().length > 0){
                            res.data.ip_kcp = res.data.ip_kcp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                    const newInvoiceData = {
                        ip_s_kind : res.data.ip_s_kind,
                        ip_kind : res.data.ip_kind,
                        ip_loca : res.data.ip_loca.trim(),
                        ip_loca_name : res.data.ip_loca_name.trim(),
                        ip_kpp : res.data.ip_kpp,
                        ip_kcp : res.data.ip_kcp
                    }
                    let copy = {...invoiceData,...newInvoiceData};
                    setInvoiceData(copy);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },[popup.defaultInfoModifyOk]);
    

    //송품장 입하승인전 입찰시간입력했는지 확인
    const invoApprovalCheck = () => {
        let data = JSON.parse(localStorage.getItem('invoiceData'));
        if(invoiceData.ip_status == '입하신청' || invoiceData.ip_status == '입하승인'){
            if(data.ip_s_kind == 1){
                if(auctDateStart == "" || auctStartHour == "" || auctStartMinute == "" || auctDateEnd == "" || auctEndHour == "" || auctEndMinute == ""){
                    setAuctDateError(true);
                }else{
                    setAuctDateError(false);
                    setInvoApprovalConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'입하승인을 하시겠습니까?',
                        confirmPopBtn:2,
                    }));
                }
            }else{
                if(auctDateStart == "" || auctStartHour == "" || auctStartMinute == ""){
                    setAuctDateError(true);
                }else{
                    setAuctDateError(false);
                    setInvoApprovalConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'입하승인을 하시겠습니까?',
                        confirmPopBtn:2,
                    }));
                }
            }
        }else{
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'입하승인이 불가합니다.',
                confirmPopBtn:1,
            }));
            setConfirm(true);
        }
    };

    //송품장 입하승인
    const invoApproval = () => {
        let startDate = moment(auctDateStart).format('YYYYMMDD');
        let endDate = moment(auctDateEnd).format('YYYYMMDD');
        let ip_auct_date_start = startDate+auctStartHour+auctStartMinute;
        let ip_auct_date_end = endDate+auctEndHour+auctEndMinute;
        let body = {
            ip_auct_date_start:ip_auct_date_start,
            ip_auct_date_end:ip_auct_date_end,
            ip_status:3,
            ip_date:tableDate,
            ip_no:tableNum,
        };
        axios.put(`${a_invoice_status.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입하승인이 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);

                const newInvoiceData = {
                    ip_status : '입하승인',
                }
                let copy = {...invoiceData,...newInvoiceData};
                setInvoiceData(copy);

                //등록된물품전체 입하미승인제외 입하승인으로 상태변경
                const newProList = proList.filter(pro => {
                    if (pro.de_status == 1 ) {
                        pro.de_status = "2";
                        return pro;
                    } else {
                        return pro;
                    }
                });
                setProList(newProList);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //송품장 원장일자,원장번호 유효성검사
    const tableValidation = () => {
        axios.get(`${a_invoice_vali.replace(':ip_date', tableDate).replace(':ip_no', tableNum)}`,{
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    useEffect(()=>{
        setTableDate(tableDate);
        // if(tableDate.length > 7 && tableNum.length > 3){
        //     tableValidation();
        // }
    },[tableDate]);

    useEffect(()=>{
        setTableNum(tableNum);
        // if(tableDate.length > 7 && tableNum.length > 3){
        //     tableValidation();
        // }
    },[tableNum]);

    //송품장 입하승인취소
    const invoCancel = () => {
        let body = {
            ip_auct_date_start:"",
            ip_auct_date_end:"",
            ip_status:2,
            ip_date:"",
            ip_no:"",
        };
        axios.put(`${a_invoice_status.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                // setAuctDateStart('');
                // setAuctStartHour('');
                // setAuctStartMinute('');
                // setAuctDateEnd('');
                // setAuctEndHour('');
                // setAuctEndMinute('');
                // setTableDate('');
                setTableNum('');

                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'입하승인취소가 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);

                const newInvoiceData = {
                    ip_status : '입하신청',
                }
                let copy = {...invoiceData,...newInvoiceData};
                setInvoiceData(copy);

                //등록된물품전체 입하미승인제외 입하신청으로 상태변경
                const newProList = proList.filter(pro => {
                    if (pro.de_status == 2 ) {
                        pro.de_status = "1";
                        return pro;
                    } else {
                        return pro;
                    }
                });
                setProList(newProList);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //물품분할하기 완료되면 물풀리스트정보 다시 뿌려주기
    useEffect(()=>{
        if(popup.proDivisionOk){
            axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                headers:{Authorization: `Bearer ${token}`}
            })
            .then((res)=>{
                if(res.status === 200){
                    console.log(res.data);
                    let list = [...res.data.detltrns];
                    setProList(list);

                    //현재 on 된 물품 수량 변경해주기
                    let productData = JSON.parse(localStorage.getItem('productData'));
                    productData.de_quantity = list[onProduct].de_quantity;
                    localStorage.setItem("productData", JSON.stringify(productData));

                    //등록된물품 체크박스
                    let checkedId = [];
                    checkedId = list.map((pro,i)=>{
                        i = i+1;
                        return "pro_check_" + i;
                    });
                    setCheckedListId(checkedId);

                    let checkedVal = [];
                    checkedVal = list.map((pro)=>{
                        return pro.de_date_online + ',' + pro.de_ip_no + ',' + pro.de_seq + ',' + pro.de_seq2;
                    });
                    setCheckedListVal(checkedVal);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({	
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    },[popup.proDivisionOk]);

    //물품 복사하기
    const proCopyHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let de_ip_date = productData.de_ip_date;
        let de_ip_no = productData.de_ip_no;
        let de_date_online = productData.de_date_online;
        let de_no_online = productData.de_no_online;
        let de_seq = productData.de_seq;
        let de_seq2 = productData.de_seq2;
        let body = {};
        axios.post(`${a_copy_product.replace(':de_ip_date', de_ip_date).replace(':de_ip_no', de_ip_no).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                console.log(res.data);
                axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                    headers:{Authorization: `Bearer ${token}`}
                })
                .then((res)=>{
                    if(res.status === 200){
                        // 송품장 ip_asso_code 저장
                        let assoCode = JSON.parse(localStorage.getItem('assoCode'));
                        assoCode = {asso_code:res.data.ip_asso_code.trim()};
                        localStorage.setItem("assoCode", JSON.stringify(assoCode));

                        res.data.ip_reg_dt = moment(res.data.ip_reg_dt).utc().format('YYYY년 MM월 DD일 HH시 mm분 ss초');

                        let [strDate, strHour, strMinute] = CF.getDateFormat(res.data.ip_auct_date_start);
                        let [endDate, endHour, endMinute] = CF.getDateFormat(res.data.ip_auct_date_end);
                        setAuctDateStart(strDate);
                        setAuctStartHour(strHour);
                        setAuctStartMinute(strMinute);
                        setAuctDateEnd(endDate);
                        setAuctEndHour(endHour);
                        setAuctEndMinute(endMinute);

                        let ip_status = res.data.ip_status.trim();
                        res.data.ip_status = enum_ip_status[ip_status][0];
                        let ip_s_kind = res.data.ip_s_kind.trim();
                        res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                        let ip_kind = res.data.ip_kind.trim();
                        res.data.ip_kind = enum_from_kind[ip_kind];
                        if(!res.data.ip_kpp === null){
                            if(res.data.ip_kpp.trim().length > 0){
                                res.data.ip_kpp = res.data.ip_kpp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        }
                        if(!res.data.ip_kcp === null){
                            if(res.data.ip_kcp.trim().length > 0){
                                res.data.ip_kcp = res.data.ip_kcp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        }
                        if(!res.data.ip_wo_tot === null){
                            if(res.data.ip_wo_tot.trim().length > 0){
                                res.data.ip_wo_tot = res.data.ip_wo_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        }
                        if(!res.data.ip_quan_tot === null){
                            if(res.data.ip_quan_tot.trim().length > 0){
                                res.data.ip_quan_tot = res.data.ip_quan_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        }
                        res.data.ip_delivery_date_start  = moment(res.data.ip_delivery_date_start.trim()).format('YYYY-MM-DD');
                        res.data.ip_delivery_date_end  = moment(res.data.ip_delivery_date_end.trim()).format('YYYY-MM-DD');

                        setInvoiceData(res.data);
                        let list = [...res.data.detltrns];
                        setProList(list);

                        // 물품리스트맨마지막에 복사된물품 추가된후 스크롤 리스트맨밑으로
                        setTimeout(()=>{
                            proListBox.current.scrollTop = proListBox.current.scrollHeight;
                        },100);

                        //등록된물품 체크박스
                        let checkedId = [];
                        checkedId = list.map((pro,i)=>{
                            i = i+1;
                            return "pro_check_" + i;
                        });
                        setCheckedListId(checkedId);

                        let checkedVal = [];
                        checkedVal = list.map((pro)=>{
                            return pro.de_date_online + ',' + pro.de_ip_no + ',' + pro.de_seq + ',' + pro.de_seq2;
                        });
                        setCheckedListVal(checkedVal);
                    }
                })
                .catch((error) => {
                    const err_msg = CF.errorMsgHandler(error);
                    dispatch(confirmPop({	
                        confirmPopTit:'알림',
                        confirmPopTxt: err_msg,
                        confirmPopBtn:1,
                    }));
                    setConfirm(true);
                });
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //물품 승인하기
    const proApprovalHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let body = {
            de_status:2,
            products:{
                de_date_online: checkedDeDate,
                de_no_online: checkedDeNo,
                de_seq: checkedDeSeq,
                de_seq2: checkedDeSeq2,
            }
        };
        axios.put(`${a_status_product.replace(':ip_date_online', productData.de_ip_date).replace(':ip_no_online', productData.de_ip_no)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setTimeout(()=>{
                    axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            let list = [...res.data.detltrns];
                            setProList(list);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({	
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
            }
            if(res.status !== 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: res.data.msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //물품 승인취소하기
    const proCancelHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let body = {
            de_status:1,
            products:{
                de_date_online: checkedDeDate,
                de_no_online: checkedDeNo,
                de_seq: checkedDeSeq,
                de_seq2: checkedDeSeq2,
            }
        };
        axios.put(`${a_status_product.replace(':ip_date_online', productData.de_ip_date).replace(':ip_no_online', productData.de_ip_no)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setTimeout(()=>{
                    axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            let list = [...res.data.detltrns];
                            setProList(list);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({	
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
            }
            if(res.status !== 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: res.data.msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //물품 미승인하기
    const proUnapprovalHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let body = {
            de_status:3,
            products:{
                de_date_online: checkedDeDate,
                de_no_online: checkedDeNo,
                de_seq: checkedDeSeq,
                de_seq2: checkedDeSeq2,
            }
        };
        axios.put(`${a_status_product.replace(':ip_date_online', productData.de_ip_date).replace(':ip_no_online', productData.de_ip_no)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setTimeout(()=>{
                    axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            let list = [...res.data.detltrns];
                            setProList(list);
                        }
                        if(res.status !== 200){
                            dispatch(confirmPop({
                                confirmPopTit:'알림',
                                confirmPopTxt: res.data.msg,
                                confirmPopBtn:1,
                            }));
                            setConfirm(true);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({	
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //물품 삭제하기
    const proDeltHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let body = {
            de_date_online: checkedDeDate,
            de_no_online: checkedDeNo,
            de_seq: checkedDeSeq,
            de_seq2: checkedDeSeq2,
        };
        axios({
            method: 'delete',
            url: `${a_delt_product.replace(':ip_date_online', productData.de_ip_date).replace(':ip_no_online', productData.de_ip_no)}`,
            data: body,
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                setTimeout(()=>{
                    axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            //송품장 총 수량 정보만 다시 뿌려주기
                            if(!res.data.ip_quan_tot === null){
                                if(res.data.ip_quan_tot.trim().length > 0){
                                    res.data.ip_quan_tot = res.data.ip_quan_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                            }
                            setInvoiceData((prev)=>({...prev,ip_quan_tot:res.data.ip_quan_tot}));

                            let list = [...res.data.detltrns];
                            setProList(list);

                            // 등록된물품 리스트의 맨처음 물품정보 뿌려주기
                            proListBox.current.scrollTo(0,0);
                            setOnProduct(0);
                            if(list.length > 0){
                                proListClickHandler(list[0]);
                                let productData = JSON.parse(localStorage.getItem('productData'));
                                productData = {
                                    de_ip_date:list[0].de_ip_date,
                                    de_ip_no:list[0].de_ip_no,
                                    de_date_online:list[0].de_date_online,
                                    de_no_online:list[0].de_no_online,
                                    de_seq:list[0].de_seq,
                                    de_seq2:list[0].de_seq2,
                                    de_quantity:list[0].de_quantity,
                                    de_status:list[0].de_status
                                }
                                localStorage.setItem("productData", JSON.stringify(productData));
                            }

                            // 체크박스 해제
                            setCheckedListId([]);
                            setCheckedListVal([]);
                        }
                        if(res.status !== 200){
                            dispatch(confirmPop({
                                confirmPopTit:'알림',
                                confirmPopTxt: res.data.msg,
                                confirmPopBtn:1,
                            }));
                            setConfirm(true);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({	
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //송품장에 등록된물품 리스트 클릭시 물품정보 뿌려주기
    const proListClickHandler = (data) => {
        axios.get(`${a_list_product2.replace(':de_ip_date', data.de_ip_date).replace(':de_ip_no', data.de_ip_no).replace(':de_date_online', data.de_date_online).replace(':de_no_online', data.de_no_online).replace(':de_seq', data.de_seq).replace(':de_seq2', data.de_seq2)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                let data = res.data;
                // console.log(data);
                let deImg1 = data.de_img1.replace(/\\/ig,"\\\\");
                let deImg2 = data.de_img2.replace(/\\/ig,"\\\\");
                let deVideo = data.de_video.replace(/\\/ig,"\\\\");
                setFarmNameInput(CF.objValueTrim(data.de_farm_name));
                setFarmCodeData(CF.objValueTrim(data.de_farm_code));
                setGmCodeInput(CF.objValueTrim(data.de_gods_code));
                setGmNameInput(CF.objValueTrim(data.gm_name));
                setDeWeight(CF.MakeIntComma(data.de_weight));
                setPackingInput(CF.objValueTrim(data.de_packing_name));
                setPackingCode(data.de_packing);
                setQualityInput(CF.objValueTrim(data.de_quality_name));
                setQualityCode(CF.objValueTrim(data.de_quality)); 
                setHighInput(CF.objValueTrim(data.de_high_name));
                setHighCode(CF.objValueTrim(data.de_high));
                setDeQuantity(CF.MakeIntComma(data.de_quantity));
                setDeCost2(CF.MakeIntComma(data.de_cost2));
                setDeDansu(CF.MakeIntComma(data.de_dansu));
                setDeHopePri(CF.MakeIntComma(data.de_hope_pri));
                setDeCont1(CF.objValueTrim(data.de_cont1));
                setDeCont2(CF.objValueTrim(data.de_cont2));
                setDeCont3(CF.objValueTrim(data.de_cont3));
                if(data.memo.memo === undefined){
                    setMemo('');
                }else{
                    setMemo(data.memo.memo);
                }
                if(deImg1.length > 0){
                    setDeImg1(`${api_uri}/${deImg1}`);
                }else{
                    setDeImg1('');
                }
                if(deImg2.length > 0){
                    setDeImg2(`${api_uri}/${deImg2}`);
                }else{
                    setDeImg2('');
                }
                if(deVideo.length > 0){
                    setDeVideo(`${api_uri}/${deVideo}`);
                }else{
                    setDeVideo('');
                }
                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //생산자 검색
    useEffect(() => {
        if(farmNameInputClick){
            let assoCode = JSON.parse(localStorage.getItem('assoCode'));
            assoCode = assoCode.asso_code;
            setFarmNameInput(farmNameInput);
            if(farmNameInput.length > 0){
                setDropLoading(true);
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_farms}?ft_farm=${farmNameInput}&ft_asso_code=${assoCode}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setFarms(res.data);
                            setDropLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setFarmsDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setFarmsDrop(false);
            }
        }
    },[farmNameInput]);

    //품명그룹 검색
    useEffect(() => {
        setGmNameInput(gmNameInput);
        if(gmNameInputClick){
            if(gmNameInput.length > 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_gm_stmd}?gm_stmd=${gmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setGmStmdName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerAxios2 = setTimeout(() => {
                    axios.get(`${c_search_gm}?gm=${gmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setGmName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setGmNameDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerAxios2,timerDrop);
            }else{
                setGmNameDrop(false);
            }
        }
    },[gmNameInput]);

    //규격 검색
    useEffect(() => {
        setPackingInput(packingInput);
        if(packingInputClick){
            if(packingInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=D&search_word=${packingInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setPacking(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setPackingDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setPackingDrop(false);
            }
        }
    },[packingInput,packingInputClick]);

    //친환경 검색
    useEffect(() => {
        setQualityInput(qualityInput);
        if(qualityInputClick){
            if(qualityInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=F&search_word=${qualityInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setQuality(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setQualityDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setQualityDrop(false);
            }
        }
    },[qualityInput,qualityInputClick]);

    //등급 검색
    useEffect(() => {
        setHighInput(highInput);
        if(highInputClick){
            if(highInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=E&search_word=${highInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setHigh(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setHighDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setHighDrop(false);
            }
        }
    },[highInput,highInputClick]);

    //물품 변경사항 저장
    const proModifyHandler = () => {
        console.log('물품 변경사항 저장');
        let productData = JSON.parse(localStorage.getItem('productData'));

        let regex = ",";
        let de_weight = deWeight.toString().replace(regex,'');

        let body = {
            de_farm_code:farmCodeData,
            de_farm_name:farmNameInput,
            de_gods_code:gmCodeInput,
            de_weight:de_weight,
            de_packing:packingCode,
            de_quality:qualityCode,
            de_high:highCode,
            de_rmk:highInput.trim(),
            de_quantity:CF.RemoveIntComma(deQuantity),
            de_cost2:CF.RemoveIntComma(deCost2),
            de_dansu:CF.RemoveIntComma(deDansu),
            de_hope_pri:CF.RemoveIntComma(deHopePri),
            de_cont1:deCont1,
            de_cont2:deCont2,
            de_cont3:deCont3,
            memo:memo,
        };
        axios.put(`${a_list_product2.replace(':de_ip_date', productData.de_ip_date).replace(':de_ip_no', productData.de_ip_no).replace(':de_date_online', productData.de_date_online).replace(':de_no_online', productData.de_no_online).replace(':de_seq', productData.de_seq).replace(':de_seq2', productData.de_seq2)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setTimeout(()=>{
                    axios.get(`${a_list_product.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            //송품장 총 수량 정보만 다시 뿌려주기
                            if(!res.data.ip_quan_tot === null){
                                if(res.data.ip_quan_tot.trim().length > 0){
                                    res.data.ip_quan_tot = res.data.ip_quan_tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                            }
                            setInvoiceData((prev)=>({...prev,ip_quan_tot:res.data.ip_quan_tot}));

                            let list = [...res.data.detltrns];
                            setProList(list);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                },debounce);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //SMS전송하기
    const smsSendHandler = () => {
        let productData = JSON.parse(localStorage.getItem('productData'));
        let phone = farmPhone.replace(/[^0-9]/g, '');
        let body = {
            mem_hand:phone,
            subject:smsText
        };
        axios.post(`${a_msg_product.replace(':de_ip_date', productData.de_ip_date).replace(':de_ip_no', productData.de_ip_no).replace(':de_date_online', productData.de_date_online).replace(':de_no_online', productData.de_no_online).replace(':de_seq', productData.de_seq).replace(':de_seq2', productData.de_seq2)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                setSmsText('');
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return(
        <div className="page_sub">
            <div className="cont_wrap2 tp20">
                <div className="right_cont">
                    <ul className="cont2 top_box flex_between">
                        <li onClick={()=>{
                            if(invoiceData.ip_status == '입하신청'){
                                dispatch(defaultInfoModifyPop(true));
                            }else{
                                dispatch(confirmPop({
                                    confirmPopTit:'알림',
                                    confirmPopTxt:'기본정보 수정이 불가합니다.',
                                    confirmPopBtn:1,
                                }));
                                setConfirm(true);
                            }
                        }}><span>기본정보 수정하기</span></li>
                        <li onClick={()=>{dispatch(orderingPop(true))}}><span>경매순서지정</span></li>
                    </ul>
                    <div className="bottom_box tm15">
                        <div className="flex_between lp10 bp10 rp10">
                            <p className="medium f_16">기본정보</p>
                            {invoiceData.ip_auct_name !== null &&
                                <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                            }
                        </div>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'24%'}} />
                                    <col style={{width:'26%'}} />
                                    <col style={{width:'24%'}} />
                                    <col style={{width:'26%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">{invoiceData.as_name}</td>
                                    </tr>
                                    <tr>
                                        <th>등록일시</th>
                                        <td colSpan="3">{invoiceData.ip_reg_dt}</td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td colSpan="3">{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                    </tr>
                                    <tr>
                                        <th>상태</th>
                                        <td colSpan="3"><span className="tag">{invoiceData.ip_status}</span></td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">{`${invoiceData.ip_s_kind == '입찰' ? '입찰' : '경매시작'}시간`}</th>
                                        {/* <td colSpan="3">
                                            <div className="custom_input">
                                                <PatternFormat value={auctDateStart || ''} 
                                                    format="####-##-## ##:##"
                                                    onChange={(e)=>{
                                                        let regex = /[^0-9]/g;
                                                        let value = e.currentTarget.value.replace(regex,'');
                                                        setAuctDateStart(e.currentTarget.value);
                                                        if(value.length === 12){
                                                            setAuctDateError(false);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {invoiceData.ip_s_kind == '입찰' &&
                                                <div className="custom_input tm5">
                                                    <PatternFormat value={auctDateEnd || ''} 
                                                        format="####-##-## ##:##"
                                                        onChange={(e)=>{
                                                            setAuctDateEnd(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {auctDateError && <div className="alert_txt f_12">입찰시간을 입력해주세요.</div>}
                                        </td> */}
                                        <td colSpan="3">
                                            <div className="date_input date_input3 date_time">
                                                <div className="custom_input rm5">
                                                    <DatePicker
                                                        selected={auctDateStart || ''}
                                                        onChange={date => {
                                                            setAuctDateStart(date);
                                                        }}
                                                        locale="ko"
                                                        dateFormat="yyyy-MM-dd"
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                                <div className="custom_select rm5">
                                                    <select 
                                                        value={auctStartHour}
                                                        onChange={(e)=>{
                                                            setAuctStartHour(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option value="" hidden>시</option>
                                                        {hourOptions.map((option,i)=>{
                                                            return(
                                                                <option value={option}>{option}시</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="custom_select">
                                                    <select 
                                                        value={auctStartMinute}
                                                        onChange={(e)=>{
                                                            setAuctStartMinute(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option value="" hidden>분</option>
                                                        {minuteOptions.map((option,i)=>{
                                                            return(
                                                                <option value={option}>{option}분</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            {invoiceData.ip_s_kind == '입찰' &&
                                                <div className="date_input date_input3 date_time tm5">
                                                    <div className="custom_input rm5">
                                                        <DatePicker
                                                            selected={auctDateEnd || ''}
                                                            onChange={date =>{
                                                                setAuctDateEnd(date);
                                                            }}
                                                            locale="ko"
                                                            dateFormat="yyyy-MM-dd"
                                                            minDate={new Date()}
                                                        />
                                                    </div>
                                                    <div className="custom_select rm5">
                                                        <select 
                                                            value={auctEndHour}
                                                            onChange={(e)=>{
                                                                setAuctEndHour(e.currentTarget.value);
                                                            }}
                                                        >
                                                            <option value="" hidden>시</option>
                                                            {hourOptions.map((option,i)=>{
                                                                return(
                                                                    <option value={option}>{option}시</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="custom_select">
                                                        <select 
                                                            value={auctEndMinute}
                                                            onChange={(e)=>{
                                                                setAuctEndMinute(e.currentTarget.value);
                                                            }}
                                                        >
                                                            <option value="" hidden>분</option>
                                                            {minuteOptions.map((option,i)=>{
                                                                return(
                                                                    <option value={option}>{option}분</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {auctDateError && <div className="alert_txt f_12">입찰시간을 입력해주세요.</div>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>매매구분</th>
                                        <td>{invoiceData.ip_s_kind}</td>
                                        <th>팰릿</th>
                                        <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kpp)} 개</td>
                                    </tr>
                                    <tr>
                                        <th>목재<span className="f_12">(옥타곤)</span></th>
                                        <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kcp)} 개</td>
                                        <th>판매구분</th>
                                        <td>{invoiceData.ip_kind}</td>
                                    </tr>
                                    <tr>
                                        <th>산지</th>
                                        <td colSpan="3">{`${invoiceData.ip_loca_name} (${invoiceData.ip_loca})`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="medium f_16 tp20 bp10 lp10">운송정보</p>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'24%'}} />
                                    <col style={{width:'26%'}} />
                                    <col style={{width:'24%'}} />
                                    <col style={{width:'26%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>총 운송비</th>
                                        <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_wo_tot)} 원</td>
                                        <th>총 수량</th>
                                        <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_quan_tot)} 개</td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">배송가능기간</th>
                                        <td colSpan="3">{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'24%'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>원장일자</th>
                                        <td>
                                            <div className="custom_input">
                                                <NumericFormat value={tableDate} allowLeadingZeros={true} onChange={(e)=>{
                                                    setTableDate(e.currentTarget.value);
                                                }} maxLength="8"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>원장번호</th>
                                        <td>
                                            <div className="custom_input">
                                                <NumericFormat value={tableNum} allowLeadingZeros={true} onChange={(e)=>{
                                                    setTableNum(e.currentTarget.value);
                                                }} maxLength="4"/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="medium f_16 tp20 bp10 lp10">물품목록</p>
                        <div className="border_box scroll_wrap h_160 over_x" ref={proListBox}>
                            <div className="w_900px">
                                <div className="custom_table4">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'40px'}} />
                                            <col style={{width:'70px'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'13%'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'7%'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'6%'}} />
                                            <col style={{width:'6%'}} />
                                            <col style={{width:'11%'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="custom_check">
                                                        <label className="flex_center" htmlFor={`pro_check_all`}>
                                                            <input type="checkbox" id={`pro_check_all`} checked={allChecked}
                                                                onChange={(e) => {
                                                                    allCheckHandler(e.currentTarget.checked);
                                                                }}
                                                            />
                                                            <span className="check">체크</span>
                                                        </label>
                                                    </div>
                                                </th>
                                                <th>경매순서</th>
                                                <th>번호</th>
                                                <th>상태</th>
                                                <th>생산자</th>
                                                <th>품목</th>
                                                <th>품목명</th>
                                                <th>중량</th>
                                                <th>규격</th>
                                                <th>등급</th>
                                                <th>수량</th>
                                                <th>희망단가</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {proList && proList.map((product, idx)=>{
                                                let de_status = product.de_status.trim();
                                                    de_status = enum_de_status[de_status][0];
                                                let de_weight = CF.MakeIntComma(product.de_weight);
                                                let de_quantity = CF.MakeIntComma(product.de_quantity);
                                                let de_hope_pri = CF.MakeIntComma(product.de_hope_pri);
                                                return(
                                                    <tr key={idx} 
                                                        onClick={()=> {
                                                            setOnProduct(idx);
                                                            proListClickHandler(product);
                                                            let productData = JSON.parse(localStorage.getItem('productData'));
                                                            productData = {
                                                                de_ip_date:product.de_ip_date,
                                                                de_ip_no:product.de_ip_no,
                                                                de_date_online:product.de_date_online,
                                                                de_no_online:product.de_no_online,
                                                                de_seq:product.de_seq,
                                                                de_seq2:product.de_seq2,
                                                                de_quantity:product.de_quantity,
                                                                de_status:product.de_status
                                                            }
                                                            localStorage.setItem("productData", JSON.stringify(productData));
                                                        }}
                                                        className={`${onProduct === idx ? "tr_current" : "tr_blue"}`}
                                                    >
                                                        <td>
                                                            <div className="custom_check">
                                                                <label className="flex_center" htmlFor={`pro_check_${idx+1}`}>
                                                                    <input type="checkbox" id={`pro_check_${idx+1}`}
                                                                        onChange={(e) => {
                                                                            checkHandler(e.currentTarget.checked, e.currentTarget.id, e.currentTarget.value);
                                                                        }}
                                                                        checked={checkedListId.includes(`pro_check_${idx+1}`) ? true : false}
                                                                        value={`${product.de_date_online},${product.de_ip_no},${product.de_seq},${product.de_seq2}`}
                                                                    />
                                                                    <span className="check">체크</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>{idx+1}</td>
                                                        <td>{product.de_no_online}</td>
                                                        <td>{de_status}</td>
                                                        <td>{product.de_farm_name}</td>
                                                        <td>{product.de_gods_code}</td>
                                                        <td>{product.gm_name === null ? product.gm_stmd_name : product.gm_name}</td>
                                                        <td className="tx_r">{de_weight}kg</td>
                                                        <td>{product.de_packing_name}</td>
                                                        <td>{product.de_high_name}</td>
                                                        <td className="tx_r">{de_quantity}</td>
                                                        <td className="tx_r">{de_hope_pri}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="many_btn_box cont2 tm10">
                            <div className="flex_between bp10">
                                <button className="btn3_s3" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        if(checkedListId.length > 0){
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'승인을 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setProApprovalConfirm(true);
                                        }else{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품을 1개이상 선택해주세요.',
                                                confirmPopBtn:1,
                                            }));
                                            setConfirm(true);
                                        }
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'승인이 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>승인</button>
                                <button className="btn3_s3" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        if(checkedListId.length > 0){
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'승인취소를 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setProCancelConfirm(true);
                                        }else{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품을 1개이상 선택해주세요.',
                                                confirmPopBtn:1,
                                            }));
                                            setConfirm(true);
                                        }
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'승인취소가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>승인취소</button>
                                <button className="btn3_s" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        if(checkedListId.length > 0){
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'미승인을 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setProUnapprovalConfirm(true);
                                        }else{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품을 1개이상 선택해주세요.',
                                                confirmPopBtn:1,
                                            }));
                                            setConfirm(true);
                                        }
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'미승인이 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>미승인</button>
                                <button className="btn3_s" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        if(checkedListId.length > 0){
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품삭제를 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setProDeltConfirm(true);
                                        }else{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'물품을 1개이상 선택해주세요.',
                                                confirmPopBtn:1,
                                            }));
                                            setConfirm(true);
                                        }
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품삭제가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>삭제하기</button>
                            </div>
                            <div className="btn_half_box">
                                <button className="btn3_s" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        dispatch(proDivisionPop(true));
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품분할판매가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>물품 분할판매 설정</button>
                                <button className="btn3_s" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품을 복사하시겠습니까?',
                                            confirmPopBtn:2,
                                        }));
                                        setProCopyConfirm(true);
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품복사가 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}>물품 복사하기</button>
                            </div>
                        </div>
                        <p className="medium f_16 tp20 bp10 lp10">물품상세</p>
                        {invoiceData.ip_status == '입하신청' && <>
                            <div className="custom_table gray_table">
                                <table>
                                    <colgroup>
                                        <col style={{width:'20%'}} />
                                        <col style={{width:'30%'}} />
                                        <col style={{width:'20%'}} />
                                        <col style={{width:'30%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>
                                                <div className="drop_wrap">
                                                    <div className="custom_input h_30" >
                                                        <input type="text" value={farmNameInput || ""} 
                                                            onFocus={() => {setFarmNameInputClick(true)}} 
                                                            onBlur={() => {
                                                                setFarmNameInputClick(false);
                                                                setTimeout(()=>{
                                                                    setFarmsDrop(false);
                                                                },200);
                                                            }}
                                                            onChange={(e) => {
                                                                setFarmNameInput(e.target.value);
                                                            }} 
                                                        />
                                                    </div>
                                                    {farmsDrop && (<>
                                                        <div className="drop_box drop_box2">    
                                                            <ul className="list_ul">
                                                                {farms.length === 0 &&
                                                                    <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                                }

                                                                {farms.length > 0 && (farms.map((a,i) => {
                                                                    return <li key={i}  onClick={(e) => {
                                                                        setFarmNameInput(farms[i].ft_farm_name.trim());
                                                                        setFarmsDrop(false);
                                                                        setFarmNameInputClick(false);
                                                                        setFarmCodeData(farms[i].ft_farm_code.trim());
                                                                    }}>{`${farms[i].ft_farm_name.trim()}(${farms[i].ft_farm_code.trim()})`}</li>;
                                                                }))}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                            </td>
                                            <th>품명</th>
                                            <td>
                                                <div className="drop_wrap">
                                                    <div className="custom_input h_30">
                                                        <input type="text" value={gmNameInput || ""} 
                                                            onFocus={() => {setGmNameInputClick(true)}}
                                                            onBlur={() => {
                                                                setGmNameInputClick(false);
                                                                setTimeout(()=>{
                                                                    setGmNameDrop(false);
                                                                },200);
                                                            }}
                                                            onChange={(e) => {
                                                                setGmNameInput(e.target.value);
                                                            }} 
                                                        />
                                                    </div>
                                                    {gmNameDrop && (<>
                                                        <div className="drop_box drop_box2">
                                                            <ul className="list_ul2">
                                                                {gmStmdName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품목]</p>
                                                                        <ul className="list_ul">
                                                                            {gmStmdName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    setGmNameInput(gmStmdName[i].gm_stmd_name.trim());
                                                                                    setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품명]</p>
                                                                        <ul className="list_ul">
                                                                            {gmName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    setGmNameInput(gmName[i].gm_name.trim());
                                                                                    setGmCodeInput(gmName[i].gm_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmName[i].gm_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>중량</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <NumericFormat thousandSeparator="," value={deWeight} onChange={(e)=>{setDeWeight(e.currentTarget.value)}} className="tx_r" />
                                                </div>
                                            </td>
                                            <th>규격</th>
                                            <td>
                                                <div className="drop_wrap">
                                                    <div className="custom_input h_30">
                                                        <input type="text" value={packingInput || ""} 
                                                        onFocus={() => {
                                                            setPackingInputClick(true);
                                                        }}
                                                        onBlur={() => {
                                                            setPackingInputClick(false);
                                                            setTimeout(()=>{
                                                                setPackingDrop(false);
                                                            },200);
                                                        }}
                                                        onChange={(e) =>{
                                                            setPackingInput(e.target.value);
                                                        }} />
                                                    </div>
                                                    {packingDrop && (<>
                                                        <div className="drop_box drop_box2">    
                                                            <ul className="list_ul">
                                                                {packing.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                {packing.length > 0 && (packing.map((a,i) => {
                                                                    return <li key={i}  onClick={(e) => {
                                                                        setPackingInput(packing[i].et_name.trim());
                                                                        setPackingDrop(false);
                                                                        setPackingInputClick(false);
                                                                        setPackingCode(packing[i].et_code.trim());
                                                                    }}>{packing[i].et_name.trim()}</li>;
                                                                }))}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>친환경</th>
                                            <td>
                                                <div className="drop_wrap">
                                                    <div className="custom_input h_30">
                                                        <input type="text" value={qualityInput || ""} 
                                                        onFocus={() => {setQualityInputClick(true)}} 
                                                        onBlur={() => {
                                                            setQualityInputClick(false);
                                                            setTimeout(()=>{
                                                                setQualityDrop(false);
                                                            },200);
                                                        }}
                                                        onChange={(e) =>{
                                                            setQualityInput(e.target.value);
                                                        }} />
                                                    </div>
                                                    {qualityDrop && (<>
                                                        <div className="drop_box drop_box2">    
                                                            <ul className="list_ul">
                                                                {quality.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                {quality.length > 0 && (quality.map((a,i) => {
                                                                    return <li key={i}  onClick={(e) => {
                                                                        setQualityInput(quality[i].et_name.trim());
                                                                        setQualityDrop(false);
                                                                        setQualityInputClick(false);
                                                                        setQualityCode(quality[i].et_code.trim());
                                                                    }}>{quality[i].et_name.trim()}</li>;
                                                                }))}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                            </td>
                                            <th>등급</th>
                                            <td>
                                                <div className="drop_wrap">
                                                    <div className="custom_input h_30">
                                                        <input type="text" value={highInput || ""} 
                                                        onFocus={() => {setHighInputClick(true)}} 
                                                        onBlur={() => {
                                                            setHighInputClick(false);
                                                            setTimeout(()=>{
                                                                setHighDrop(false);
                                                            },200);
                                                        }}
                                                        onChange={(e) =>{
                                                            setHighInput(e.target.value);
                                                            setHighCode("00");
                                                        }} />
                                                    </div>
                                                    {highDrop && (<>
                                                        <div className="drop_box drop_box2">    
                                                            <ul className="list_ul">
                                                                {high.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                {high.length > 0 && (high.map((a,i) => {
                                                                    return <li key={i}  onClick={(e) => {
                                                                        setHighInput(high[i].et_name.trim());
                                                                        setHighDrop(false);
                                                                        setHighInputClick(false);
                                                                        setHighCode(high[i].et_code.trim());
                                                                    }}>{high[i].et_name.trim()}</li>;
                                                                }))}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>수량</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} value={deQuantity} onChange={(e)=>{setDeQuantity(e.currentTarget.value)}} className="tx_r" />
                                                </div>
                                            </td>
                                            <th>운임비</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} value={deCost2} onChange={(e)=>{setDeCost2(e.currentTarget.value)}} className="tx_r" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>단수</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} maxLength="3" value={deDansu} onChange={(e)=>{setDeDansu(e.currentTarget.value)}} className="tx_r" />
                                                </div>
                                            </td>
                                            <th>희망단가</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <NumericFormat thousandSeparator="," decimalScale={0} value={deHopePri} onChange={(e)=>{setDeHopePri(e.currentTarget.value)}} className="tx_r" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table tm20">
                                <table>
                                    <colgroup>
                                        <col style={{width:'25%'}} />
                                        <col style={{width:'75%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <input type="text" value={deCont1} onChange={(e)=>{setDeCont1(e.currentTarget.value)}} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>당도,산도 등</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <input type="text" value={deCont2} onChange={(e)=>{setDeCont2(e.currentTarget.value)}} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <input type="text" value={deCont3} onChange={(e)=>{setDeCont3(e.currentTarget.value)}} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>경매사메모</th>
                                            <td>
                                                <div className="custom_input h_30">
                                                    <input type="text" value={memo} onChange={(e)=>{setMemo(e.currentTarget.value)}} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="cont2 tm20">
                                <button className="btn_type3 w_100" type="button" onClick={()=>{
                                    let productData = JSON.parse(localStorage.getItem('productData'));
                                    if(productData.de_status < 4){
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품변경사항을 저장하시겠습니까?',
                                            confirmPopBtn:2,
                                        }));
                                        setProModifyConfirm(true);
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'물품변경이 불가합니다.',
                                            confirmPopBtn:1,
                                        }));
                                        setProModifyNotConfirm(true);
                                    }
                                }}>변경사항 저장</button>
                            </div>
                        </>}
                        {invoiceData.ip_status != '입하신청' && <>
                            <div className="custom_table gray_table">
                                <table>
                                    <colgroup>
                                        <col style={{width:'20%'}} />
                                        <col style={{width:'30%'}} />
                                        <col style={{width:'20%'}} />
                                        <col style={{width:'30%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>{farmNameInput}</td>
                                            <th>품명</th>
                                            <td>{gmNameInput}</td>
                                        </tr>
                                        <tr>
                                            <th>중량</th>
                                            <td className="tx_r">{deWeight}kg</td>
                                            <th>규격</th>
                                            <td>{packingInput}</td>
                                        </tr>
                                        <tr>
                                            <th>친환경</th>
                                            <td>{qualityInput}</td>
                                            <th>등급</th>
                                            <td>{highInput}</td>
                                        </tr>
                                        <tr>
                                            <th>수량</th>
                                            <td className="tx_r">{deQuantity}개</td>
                                            <th>운임비</th>
                                            <td className="tx_r">{deCost2}원</td>
                                        </tr>
                                        <tr>
                                            <th>단수</th>
                                            <td className="tx_r">{deDansu}단</td>
                                            <th>희망단가</th>
                                            <td className="tx_r">{deHopePri}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table tm20">
                                <table>
                                    <colgroup>
                                        <col style={{width:'25%'}} />
                                        <col style={{width:'75%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>{deCont1}</td>
                                        </tr>
                                        <tr>
                                            <th>당도,산도 등</th>
                                            <td>{deCont2}</td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td>{deCont3}</td>
                                        </tr>
                                        <tr>
                                            <th>경매사메모</th>
                                            <td>{memo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        <div className="media_info_box cont2">
                            <p className="medium f_16 tp30 bp10">사진/동영상 정보</p>
                            <ul className="img_ul flex_between">
                                <li>
                                    <div className="img">
                                        {deImg1 && 
                                            <img src={deImg1} alt="이미지" onClick={() => {
                                                dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:deImg1}))
                                            }}/>
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        {deImg2 && 
                                            <img src={deImg2} alt="이미지" onClick={() => {
                                                dispatch(mediaPop({mediaPop:true,mediaTit:'사진2',mediaSrc:deImg2}))
                                            }}/>
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        {deVideo && 
                                            <video src={deVideo} onClick={() => {
                                                dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:deVideo}))
                                            }}/>
                                        }
                                    </div>
                                </li>
                            </ul>
                            <p className="f_13 tp10">※ 사진, 동영상을 클릭 시 확대보기가 동작합니다.</p>
                        </div>
                        <p className="medium f_16 tp30 bp10 lp10">SMS 전송하기</p>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'75%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>생산자명</th>
                                        <td>
                                            <div className="custom_input">
                                                <input type="text" value={farmNameInput} readOnly />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td>
                                            <div className="custom_input">
                                                <PatternFormat
                                                    name="date_start"
                                                    format="###-####-####"
                                                    value={farmPhone}
                                                    onChange={(e)=>{setFarmPhone(e.currentTarget.value);}}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td>
                                            <div className="custom_input h_30">
                                                <input type="text" placeholder="메시지 내용을 입력해 주세요." value={smsText} onChange={(e)=>{setSmsText(e.currentTarget.value);}}/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="cont2 tm10">
                            <button className="btn_type3 w_100" onClick={()=>{
                                if(farmNameInput.length > 0 && farmPhone.length > 0 && smsText.length > 0){
                                    dispatch(confirmPop({
                                        confirmPopTit:'알림',
                                        confirmPopTxt:'SMS를 전송하시겠습니까?',
                                        confirmPopBtn:2,
                                    }));
                                    setSmsSendConfirm(true);
                                }else{
                                    dispatch(confirmPop({
                                        confirmPopTit:'알림',
                                        confirmPopTxt:'연락처 또는 내용을 입력해주세요.',
                                        confirmPopBtn:1,
                                    }));
                                    setConfirm(true);
                                }
                            }}>SMS 전송하기</button>
                        </div>
                        <div className="btn_half_box tp20 rp10 lp10">
                            <button className="btn_s rm10" type="button" onClick={invoApprovalCheck}><span>입하승인</span></button>
                            <button className="btn_s3" 
                                onClick={()=>{
                                    if(invoiceData.ip_status == '입하승인' || invoiceData.ip_status == '입찰대기'){
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'영업프로그램의 데이터도 삭제됩니다. 입하승인취소를 하시겠습니까?',
                                            confirmPopBtn:2,
                                        }));
                                        setInvoCancelConfirm(true);
                                    }else{
                                        dispatch(confirmPop({
                                            confirmPopTit:'알림',
                                            confirmPopTxt:'입하승인 취소가 불가능한 상태입니다. 관리자에게 문의하세요.',
                                            confirmPopBtn:1,
                                        }));
                                        setConfirm(true);
                                    }
                                }}
                            >
                                입하승인취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* 입하승인 confirm팝업 */}
            {invoApprovalConfirm && <ConfirmPop onClickHandler={invoApproval} />}

            {/* 입하승인취소 confirm팝업 */}
            {invoCancelConfirm && <ConfirmPop onClickHandler={invoCancel} />}

            {/* 물품복사하기 confirm팝업 */}
            {proCopyConfirm && <ConfirmPop onClickHandler={proCopyHandler} />}

            {/* 물품승인 confirm팝업 */}
            {proApprovalConfirm && <ConfirmPop onClickHandler={proApprovalHandler} />}

            {/* 물품승인취소 confirm팝업 */}
            {proCancelConfirm && <ConfirmPop onClickHandler={proCancelHandler} />}

            {/* 물품미승인 confirm팝업 */}
            {proUnapprovalConfirm && <ConfirmPop onClickHandler={proUnapprovalHandler} />}

            {/* 물품삭제 confirm팝업 */}
            {proDeltConfirm && <ConfirmPop onClickHandler={proDeltHandler} />}

            {/* 물품 변경사항 저장 confirm팝업 */}
            {proModifyConfirm && <ConfirmPop onClickHandler={proModifyHandler} />}

            {/* 물품 변경사항 저장 못할때 confirm팝업 */}
            {proModifyNotConfirm && <ConfirmPop onClickHandler={closePopHandler}/>}

            {/* SMS전송 confirm팝업 */}
            {smsSendConfirm && <ConfirmPop onClickHandler={smsSendHandler} />}

            {/* 확인confirm팝업 */}
            {confirm && <ConfirmPop />}
            
        </div>
    );
};

export default MoReceivingDetail;