import { useRef, useEffect ,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {enum_api_uri} from "../../../config/enum";
import {enum_de_status,enum_sell_kind,enum_from_kind,enum_mo_title,enum_memo_status} from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import { mediaAllPop, mediaPop, confirmPop, closePop, auctionProDetailPop, bidListPop } from "../../../store/popupSlice";
import { isMobile, isBrowser } from 'react-device-detect';
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import imgNone from "../../../images/img_none.svg";
import videoUp from "../../../images/video_upload.svg";
import history from "../../../config/history";
import { useSocket } from "../../../components/SocketProvider";

const AuctionDetail = () => {
    const token = localStorage.getItem("token");
    const { auct_code, auct_date } = useParams();
    const my_code = localStorage.getItem("auctCode");
    const m_sublist_auction = enum_api_uri.m_sublist_auction;
    const m_subdetail_auction = enum_api_uri.m_subdetail_auction;
    const m_merchant_info = enum_api_uri.m_merchant_info;
    const m_bid_history = enum_api_uri.m_bid_history;
    const m_base_price = enum_api_uri.m_base_price;
    const m_chat = enum_api_uri.m_chat;
    const socketUrl = enum_api_uri.api_uri;
    const dispatch = useDispatch();
    const location = useLocation();
    const [myInfo, setMyInfo] = useState();
    const [percent, setPercent] = useState('');
    const [subList, setSubList] = useState([]);
    const [subDetail, setSubDetail] = useState();
    const [subListOn, setSubListOn] = useState(0);
    const [bidPriceBtnOn, setBidPriceBtnOn] = useState(0);
    const [bidConfirm, setBidConfirm] = useState(false);
    const bid = useRef();
    const [bidInput, setBidInput] = useState('');
    const message = useRef();
    const [messages, setMessages] = useState([]);
    const messageBox = useRef();
    const [countPop, setCountPop] = useState(false);
    const [countPopType, setCountPopType] = useState('start');
    const [seconds, setSeconds] = useState(3);
    const [auctionPro, setAuctionPro] = useState({});
    const [auctionAutoCheck, setAuctionAutoCheck] = useState(true);
    const [bidList, setBidList] = useState([]);
    const [moMemoPop, setMoMemoPop] = useState(false);
    const [auctionEnd, setAuctionEnd] = useState(false);
    const [auctionStart, setAuctionStart] = useState(null);
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);
    const bidHistoryBox = useRef();
    const [tabContOn, setTabContOn] = useState(0);
    const [auctConnect, setAuctConnect] = useState(true);
    const [myConnect, setMyConnect] = useState(true);
    const proListScrollWrap = useRef();
    const proListTable = useRef();
    const socket = useSocket();

    //맨처음 한번만 새로고침실행
    useEffect(()=>{
        let join = localStorage.getItem("firstJoin");
        if(join === "false"){
            window.location.reload();
            localStorage.getItem("firstJoin");
            localStorage.setItem("firstJoin", true);
        }
    },[]);
    
    //상세페이지 뒤로가기
    useEffect(() => {
        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));

            //전광판 나가기
            socket.emit("leave_merchant", 
                {
                    room: room,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
                () => {

                }
            );

            //뒤로가기클릭시 온라인경매상세 localStorage 삭제
            localStorage.removeItem("auctStartData");
            localStorage.removeItem("auctProData");
            localStorage.removeItem("autoCheck");
            localStorage.removeItem("subList");
            localStorage.removeItem("subDetail");
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
                navigate("/merchant/auction/");
            }
        });

        return unlistenHistoryEvent;
    },[]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf("/");
            let tit = path.substring(0, idx);
                idx = tit.lastIndexOf("/");
                tit = tit.substring(0, idx);
                tit = enum_mo_title[tit];
            dispatch(moTitle(tit));
        }
    },[]);

    const closePopHandler = () => {
        dispatch(closePop());
    }

    // 경매방 이름(ID)으로 사용 됨
    const room = auct_code + auct_date;

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setBidConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);
    
    useEffect(()=>{
        setAuctConnect(auctConnect);
    },[auctConnect]);

    useEffect(()=>{
        setMyConnect(myConnect);
    },[myConnect]);

    useEffect(()=>{
        setTabContOn(tabContOn);
    },[tabContOn]);

    useEffect(()=>{
        setAuctionEnd(auctionEnd);
    },[auctionEnd]);

    useEffect(()=>{
        setAuctionStart(auctionStart);
    },[auctionStart]);

    useEffect(()=>{
        setBidInput(bidInput);
        // console.log()
    },[bidInput]);

    useEffect(()=>{
        setSubList(subList);
        // console.log(subList)
    },[subList]);

    useEffect(()=>{
        setSubDetail(subDetail);
        // console.log(subDetail);
    },[subDetail]);

    useEffect(()=>{
        setMyInfo(myInfo);
        // console.log(myInfo)
    },[myInfo]);

    useEffect(()=>{
        setAuctionPro(auctionPro);
        // console.log(auctionPro)
    },[auctionPro]);

    useEffect(()=>{
        setAuctionAutoCheck(auctionAutoCheck);
        // console.log(auctionAutoCheck)
    },[auctionAutoCheck]);

    //경매시작시 카운트팝업 끝나고 응찰가격입력 input 포커스
    useEffect(()=>{
        setCountPop(countPop);
        if(!countPop && auctionStart){
            //응찰가격입력 input 포커스
            bid.current.setSelectionRange(0,0);
            bid.current.focus();
        }
    },[countPop]);

    useEffect(()=>{
        setBidList(bidList);
        if(isBrowser){
            bidHistoryBox.current.scrollTop = bidHistoryBox.current.scrollHeight;
        }
    },[bidList]);

    useEffect(()=>{
        setSubListOn(subListOn);
    },[subListOn]);

    //채팅 새메시지가 뜨면 스크롤밑으로
    useEffect(() => {
        setMessages(messages);
        messageBox.current.scrollTop = messageBox.current.scrollHeight;
    }, [messages]);

    //경매방 재입장인지 확인
    // useEffect(()=>{
    //     let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
    //     if(auctStartData){
    //         if(auctStartData.hasOwnProperty('detltrns')){
    //             if(room == auctStartData.room){
    //                 setAuctionEnd(false);
    //                 setAuctionStart(true);
    //             }
    //         }
    //     }
    // },[]);

    //중도매인 자신정보 가져오기
    const getMyInfo = () => {
        axios.get(`${m_merchant_info.replace(':amer_code',my_code)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let limit = res.data.am_m_limit;
                let remain = res.data.am_m_remain;
                let per = remain / limit * 100;
                    per = per.toFixed(0);
                setPercent(per);
                setMyInfo(res.data);
            }
        })
        .catch((error) => {
            // console.log(error);
        });
    };


    //페이지 들어왔을때
    useEffect(()=>{
        //경매시작/낙찰 자동선택 체크
        let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
            autoCheck = true;
            localStorage.setItem("autoCheck", JSON.stringify(autoCheck));

        //중도매인 자신정보 가져오기
        getMyInfo();
        
        //채팅내역 가져오기
        axios.get(`${m_chat.replace(':auct_code',auct_code).replace(':auct_date', auct_date)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                // console.log(res.data);
                let chatList = res.data;
                let NewChatList = chatList.map((data)=>{
                    let newData = {};
                    newData["msg"] = data.msg;
                    newData["time"] = moment(data.reg_dt).utc().format("HH:mm");
                    newData["type"] = my_code == data.sender_asso_code ? "send" : "receive";
                    newData["user"] = {"mem_nm":data.sender_name};
                    return newData;
                });
                setMessages(NewChatList);
            }
        })
        .catch((error) => {
            // console.log(error);
        });

    },[]);


    //응찰내역 조회
    const bidHistoryHandler = (de_date_online,de_no_online,de_seq,de_seq2) => {
        axios.get(`${m_bid_history.replace(':auct_code',auct_code).replace(':auct_date', auct_date).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                setBidList([...res.data]);
            }
        })
        .catch((error) => {
            // console.log(error);
        });
    };

    //경매상품리스트 가져오기
    const getSubAucList = async() =>{              
        axios.get(`${m_sublist_auction.replace(':asso_code', auct_code).replace(':auct_date', auct_date)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                // console.log(res.data)
                const data = res.data.detltrns;
                let state = [...data];
                setSubList(state);

                //물품리스트 localStorage 저장
                let list = JSON.parse(localStorage.getItem('subList'));
                list = [...data];
                localStorage.setItem("subList", JSON.stringify(list));

                let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));

                //경매중인물품 없으면 물픔리스트중 맨처음물품 on ,localStorage auctProData 저장
                if(auctStartData === null){
                    auctProData = {
                        de_date_online:state[0].de_date_online,
                        de_no_online:state[0].de_no_online,
                        de_seq:state[0].de_seq,
                        de_seq2:state[0].de_seq2,
                        de_status:state[0].de_status,
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                    let f_date = state[0].de_date_online;
                    let f_no = state[0].de_no_online;
                    let f_seq = state[0].de_seq;
                    let f_seq2 = state[0].de_seq2;
                    getSubDetail(f_date,f_no,f_seq,f_seq2);
                    bidHistoryHandler(f_date,f_no,f_seq,f_seq2);
                }
                // 경매중인물품 있으면 그물품 on ,localStorage auctProData 저장
                else{
                    auctProData = {
                        de_date_online:auctStartData.de_date_online,
                        de_no_online:auctStartData.de_no_online,
                        de_seq:auctStartData.de_seq,
                        de_seq2:auctStartData.de_seq2,
                        de_status:auctStartData.de_status,
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                    let list = JSON.parse(localStorage.getItem('subList'));
                    getSubDetail(auctStartData.de_date_online, auctStartData.de_no_online, auctStartData.de_seq, auctStartData.de_seq2);
                    bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
                    let idx = list.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                    setSubListOn(idx);
                }
            }
        })
        .catch((error) => {
            // console.log(error);
        })

    }

    //경매상품 상세정보 뿌려주기
    const getSubDetail = (de_date_online, de_no_online, de_seq, de_seq2) =>{
        axios.get (`${m_subdetail_auction.replace(':asso_code', auct_code).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            // // console.log(res)
            if(res.status === 200){
                // console.log(res.data);
                // res.data.nowStatus =  enum_de_status[res.data.de_status.trim()][0];
                res.data.de_status =  res.data.de_status.trim();
                res.data.as_name =  res.data.as_name.trim();
                res.data.de_weight = CF.MakeIntComma(res.data.de_weight);
                res.data.de_quantity = CF.MakeIntComma(res.data.de_quantity);
                if(res.data.ip_kpp != null){
                    res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                }
                if(res.data.ip_kcp != null){
                    res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                }
                if(res.data.de_img1 != null){
                    res.data.de_img1 = res.data.de_img1.trim();
                    if(res.data.de_img1.length > 0){
                        res.data.de_img1 = res.data.de_img1.replace(/\\/ig,"\\\\");
                        res.data.de_img1 = socketUrl+'/'+res.data.de_img1;
                    }
                }
                if(res.data.de_img2 != null){
                    res.data.de_img2 = res.data.de_img2.trim();
                    if(res.data.de_img2.length > 0){
                        res.data.de_img2 = res.data.de_img2.replace(/\\/ig,"\\\\");
                        res.data.de_img2 = socketUrl+'/'+res.data.de_img2;
                    }
                }
                if(res.data.de_video != null){
                    res.data.de_video = res.data.de_video.trim();
                    if(res.data.de_video.length > 0){
                        res.data.de_video = res.data.de_video.replace(/\\/ig,"\\\\");
                        res.data.de_video = socketUrl+'/'+res.data.de_video;
                    }
                }
                if (res.data.memo.hasOwnProperty('price')) {
                    res.data.memo.price = CF.MakeIntComma(res.data.memo.price);
                }else{
                    res.data.memo.price = 0;
                }
                setSubDetail(res.data);

                //물품상세 localStorage 저장
                JSON.parse(localStorage.getItem('subDetail'));
                localStorage.setItem("subDetail", JSON.stringify(res.data));
            }
        })
        .catch((error) => {
            // console.log(error);
        })

    }

    //응찰기본단위 설정
    const priceSetHandler = (price) => {
        let body = {base_price:price};
        axios.put(`${m_base_price.replace(':asso_code',my_code)}`,body,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                
            }
        })
        .catch((error) => {
            // console.log(error);
        });
    };

    //페이지 들어왔을때 응찰기본단위 조회
    useEffect(()=>{
        axios.get(`${m_base_price.replace(':asso_code',my_code)}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                let price = res.data.base_price.trim();
                // console.log(price);
                setBidInput(price);
                if(price == ''){
                    setBidPriceBtnOn(0);
                }else if(price == '0'){
                    setBidPriceBtnOn(1);
                }else if(price == '00'){
                    setBidPriceBtnOn(2);
                }else if(price == '000'){
                    setBidPriceBtnOn(3);
                }
            }
        })
        .catch((error) => {
            // console.log(error);
        }); 
    },[]);

    // 실시간 정보 관련 초기화
    // 경매방, 닉네임 설정
    const socketInit = (e) => {
        const data = { auct_code: auct_code, auct_date: auct_date };
        socket.emit(
        "init",
        {
            room: room,
            data: data,
            headers: {
            Authorization: `Bearer ${token}`,
            },
        },
        (data) => {
            // console.log(`init`);
            // console.log(socket.id);
            // console.log(data);
            
            //경매방입장했을때 경매중인 물품있으면 localStorage에 auctStartData 저장
            JSON.parse(localStorage.getItem('auctStartData'));
            if(data.currentAuctionItem !== null){
                let auctStartData = {
                    de_date_online:data.currentAuctionItem.de_date_online,
                    de_no_online:data.currentAuctionItem.de_no_online,
                    de_seq:data.currentAuctionItem.de_seq,
                    de_seq2:data.currentAuctionItem.de_seq2,
                    de_status:"6",
                };
                localStorage.setItem("auctStartData", JSON.stringify(auctStartData));
            }else{
                localStorage.setItem("auctStartData", null);
            }

            //경매방입장했을때 경매물품 리스트 가져오기
            getSubAucList(room);
        }
        );
    };
 
    // 응찰
    const handleBid = async (e) => {
        const value = CF.RemoveIntComma(bid.current.value);
        let auctProData = await JSON.parse(localStorage.getItem('auctProData'));

        //경매중인 물품만 응찰가능
        if(auctProData.de_status == 6){
            
            if(value > 0){
                if(bidPriceBtnOn === 0){
                    setBidInput('');
                    bid.current.focus();
                }else if(bidPriceBtnOn === 1){
                    setBidInput('0');
                    bid.current.value = '0';
                    setTimeout(()=>{
                        bid.current.setSelectionRange(0,0);
                        bid.current.focus();
                    },100);
                }else if(bidPriceBtnOn === 2){
                    setBidInput('00');
                    bid.current.value = '00';
                    setTimeout(()=>{
                        bid.current.setSelectionRange(0,0);
                        bid.current.focus();
                    },100);
                }else if(bidPriceBtnOn === 3){
                    setBidInput('000');
                    bid.current.value = '000';
                    setTimeout(()=>{
                        bid.current.setSelectionRange(0,0);
                        bid.current.focus();
                    },100);
                }

                if(value <= 500000){
                    socket.emit(
                        "bid",
                        { room: room, data: {path: "bid", de_date_online: auctProData.de_date_online, de_no_online: auctProData.de_no_online, de_seq:auctProData.de_seq, de_seq2:auctProData.de_seq2, price: value}},
                        (result) => {
                            // console.log(JSON.stringify(result));
                            if (result.statusCode !== 200) {
                                // console.log(`result.error.msg: ${result.msg}`);
                                //응찰내역 조회
                                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                                //응찰가 오류시에 메세지 받기
                                let time2 = new Date();
                                    time2 = moment(time2).format('HH:mm');
                                let msg = {
                                    "type": "receive",
                                    "msg":result.msg,
                                    "user":{"mem_nm":""},
                                    "time":time2
                                }
                                setMessages(messages => [...messages, msg]);

                            } else {
                                // console.log(`서버 처리 완료 - 메시지 창에 내 메시지 갱신할 것 `);
                                //응찰내역 조회
                                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
                            }
                        }
                    );
                }
                if(value > 500000){
                    setConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'최대응찰금액은 500,000원입니다.',
                        confirmPopBtn:1,
                    }));
                }
            }else{
                setConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'응찰가격을 입력해주세요.',
                    confirmPopBtn:1,
                }));
                // console.log(bidPriceBtnOn,bid.current.value,bidInput)
            }
        }else{
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'현재 응찰이 불가합니다.',
                confirmPopBtn:1,
            }));
        }
    };

    //메모가 응찰
    const handleMemoBid = async () =>{
        let auctProData = await JSON.parse(localStorage.getItem('auctProData'));
        let value = CF.RemoveIntComma(subDetail.memo.price);

        //경매중인 물품만 메모가 응찰가능
        if(auctProData.de_status == 6){
            // setBidInput(value);
            if(value > 0){
                if(value <= 500000){
                    setBidInput(value);
                    socket.emit(
                        "bid",
                        { room: room, data: {path: "bid", de_date_online: auctProData.de_date_online, de_no_online: auctProData.de_no_online, de_seq:auctProData.de_seq, de_seq2:auctProData.de_seq2, price: value}},
                        (result) => {
                            // console.log(JSON.stringify(result));
                            if (result.statusCode !== 200) {
                                // console.log(`result.error.msg: ${result.msg}`);
                                //응찰내역 조회
                                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                                //응찰가 오류시에 메세지 받기
                                let time2 = new Date();
                                    time2 = moment(time2).format('HH:mm');
                                let msg = {
                                    "type": "receive",
                                    "msg":result.msg,
                                    "user":{"mem_nm":""},
                                    "time":time2
                                }
                                setMessages(messages => [...messages, msg]);

                                if(bidPriceBtnOn === 0){
                                    setBidInput('');
                                    bid.current.focus();
                                }else if(bidPriceBtnOn === 1){
                                    setBidInput('0');
                                    bid.current.value = '0';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn === 2){
                                    setBidInput('00');
                                    bid.current.value = '00';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn === 3){
                                    setBidInput('000');
                                    bid.current.value = '000';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }

                            } else {
                                // console.log(`서버 처리 완료 - 메시지 창에 내 메시지 갱신할 것 `);
                                //응찰내역 조회
                                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                                if(bidPriceBtnOn === 0){
                                    setBidInput('');
                                    bid.current.focus();
                                }else if(bidPriceBtnOn === 1){
                                    setBidInput('0');
                                    bid.current.value = '0';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn === 2){
                                    setBidInput('00');
                                    bid.current.value = '00';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn === 3){
                                    setBidInput('000');
                                    bid.current.value = '000';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }
                            }
                        }
                    );
                }
                if(value > 500000){
                    setConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'최대응찰금액은 500,000원입니다.',
                        confirmPopBtn:1,
                    }));
                }
            }else{
                setConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'응찰가격을 입력해주세요.',
                    confirmPopBtn:1,
                }));
            }
        }else{
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'현재 메모가응찰이 불가합니다.',
                confirmPopBtn:1,
            }));
        }
    };

    // 응찰 취소 
    const handleCancelBid = async () => {
        let auctProData = await JSON.parse(localStorage.getItem('auctProData'));

        //경매중인 물품만 응찰취소 가능
        if(auctProData.de_status == 6){
            socket.emit(
                "bid_cancel",
                { room: room, data: {path: "bid", de_date_online:auctProData.de_date_online, de_no_online:auctProData.de_no_online, de_seq:auctProData.de_seq, de_seq2:auctProData.de_seq2}},
                (result) => {
                    // console.log(JSON.stringify(result));
                    if (result.statusCode !== 200) {
                        // console.log(`result.msg: ${result.msg}`);
                    } else {
                        // console.log(`서버 처리 완료 - 메시지 창에 내 메시지 갱신할 것 `);
                        //응찰내역 조회
                        bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
                    }
                }
            );
        }else{
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'현재 응찰취소가 불가합니다.',
                confirmPopBtn:1,
            }));
        }
    };

    // 메시지 - 노출
    const addMessage = (msg) => {
        setMessages(messages => [...messages, msg]);
    };

    //메시지 시간
    function getNow() {
        const currDate = new Date();
        return (
            ("0" + currDate.getHours()).slice(-2) +
            ":" +
            ("0" + currDate.getMinutes()).slice(-2)
        );
    }

    // 메시지 - 전송
    const handleMessage = (e) => {
        console.log(`handleMessage`);
        const value = message.current.value;
        // console.log(auctionStart, auctionEnd)

        // 경매종료시,경매사가 없을경우 메시지전송안됨
        if(auctionEnd || !auctConnect){
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'메시지전송이 불가합니다.',
                confirmPopBtn:1,
            }));
        }if(!auctionEnd && auctConnect){
            // 경매시작전, 경매진행중일때는 메시지전송가능
            if(auctionStart === null || auctionStart === true){
                if(value.length > 0){

                    const logMsg = {
                        auct_code: auct_code,
                        auct_dt: auct_date,
                        sender_asso_code: my_code,
                        receiver_asso_code: auct_code,
                        msg: value,
                    }

                    // console.log(`data.logMsg`);
                    // console.log(JSON.stringify(logMsg, null, 2));
                    
                    socket.emit("message_private", {room: room, path: "message", message: value, logMsg: logMsg}, (result) => {
                        // console.log(`my socket id: ${socket.id}`);
                        // console.log(`server res`);
                        // console.log(`${JSON.stringify(result)}`);
                        const msg = {
                            "type": "send",
                            "msg": value,
                            "time": getNow()
                        }
                        addMessage(msg);
                    });
                    message.current.value = '';
                }else{
                    setConfirm(true);
                    dispatch(confirmPop({
                        confirmPopTit:'알림',
                        confirmPopTxt:'메시지를 입력해주세요.',
                        confirmPopBtn:1,
                    }));
                }
            }
            // 경매종료후에는 메시지전송 불가
            if(auctionStart === false){
                setConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'메시지전송이 불가합니다.',
                    confirmPopBtn:1,
                }));
            }
        }
    };

    //경매리스트 스크롤자동으로 변경하기
    const scrollMove = (idx) => {
        const targetTr = document.querySelectorAll('.pro_list_table tr')[idx];
        const targetTrOffsetTop = targetTr.offsetTop;
        proListScrollWrap.current.scrollTo({ top: targetTrOffsetTop, behavior: 'smooth' });
    };
    
    useEffect(()=>{

        socket.on('connect', () => {
            setMyConnect(true);
        });
        
        socket.on('disconnect', () => {
            socketInit();
            setMyConnect(false);
        });

        // 경매사 - 재접속
        socket.on("rejoin", (msg) => {
            // console.log(`rec: ${JSON.stringify(msg, null, 2)}`);
            setAuctConnect(true);
            const message = {
                "msg": msg.msg,
                "time": getNow(),
            }
            addMessage(message);
        });

        // 경매사 - 경매 비정상종료시
        socket.on("leave_auctioneer", (msg) => {
            // console.log(`rec: ${JSON.stringify(msg, null, 2)}`);
            setAuctConnect(false);
            if(auctConnect){
                const message = {
                    "msg": msg.msg,
                    "time": getNow(),
                }
                addMessage(message);
            }
        });

        // 메시지 - 수신
        socket.on("message", (msg) => {
            // // console.log(`rec: ${JSON.stringify(msg, null, 2)}`);
            addMessage(msg);
        });

        // 경매시작 
        socket.on("auction_start", (msg) => {
            //응찰가격입력 input blur처리
            bid.current.blur();

            setCountPop(true);
            setCountPopType('start');
            setAuctionEnd(false);
            setAuctionStart(true);

            //경매시작 물품 localStorage에 auctStartData 저장
            JSON.parse(localStorage.getItem('auctStartData'));
            let auctStartData = {
                de_date_online:msg.detltrns.de_date_online,
                de_no_online:msg.detltrns.de_no_online,
                de_seq:msg.detltrns.de_seq,
                de_seq2:msg.detltrns.de_seq2,
                de_status:msg.detltrns.de_status,
            }
            localStorage.setItem("auctStartData", JSON.stringify(auctStartData));

            //경매시작 물품 on -> localStorage에 auctProData 저장
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));
            setTimeout(()=>{
                let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
                let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
                let subList = JSON.parse(localStorage.getItem('subList'));

                //물품리스트 경매시작한물품 상태값 경매중으로 변경
                let newSubList = subList.filter(pro => {
                    if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                        pro.de_status = "6";
                        pro.de_amer_code = "";
                        pro.de_price1 = 0;
                        return pro;
                    }else{
                        return pro;
                    }
                });
                setSubList(newSubList);
                localStorage.setItem("subList", JSON.stringify(newSubList));

                //자동선택 체크시
                if(autoCheck === true){
                    auctProData = {
                        de_date_online:auctStartData.de_date_online,
                        de_no_online:auctStartData.de_no_online,
                        de_seq:auctStartData.de_seq,
                        de_seq2:auctStartData.de_seq2,
                        de_status:"6",
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                    getSubDetail(auctStartData.de_date_online, auctStartData.de_no_online, auctStartData.de_seq, auctStartData.de_seq2);
                    let idx = subList.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                    setSubListOn(idx);
                    bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                    //경매리스트 스크롤자동변경
                    scrollMove(idx);
                }
            },100);
        });

        // 경매 - 낙찰 
        socket.on("auction_successfull", (msg) => {
            // console.log(`event listen: auction_successfull`);
            // console.log(`rec: ${JSON.stringify(msg, null, 2)}`);

            //중도매인 자신정보 가져오기
            getMyInfo();

            setCountPop(true);
            setCountPopType('end');

            let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));

            //응찰내역 조회
            // bidHistoryHandler(auctStartData.de_date_online,auctStartData.de_no_online,auctStartData.de_seq,auctStartData.de_seq2);

            let subList = JSON.parse(localStorage.getItem('subList'));
            let newSubList = subList.filter(pro => {
                if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                    pro.de_status = "10";
                    pro.de_amer_code = msg.de_amer_code;
                    pro.de_price1 = msg.detltrns.de_price1;
                    return pro;
                }else{
                    return pro;
                }
            });
            setSubList(newSubList);
            localStorage.setItem("subList", JSON.stringify(newSubList));

            let newSubDetail = JSON.parse(localStorage.getItem('subDetail'));
            newSubDetail['de_status'] = "10";
            localStorage.setItem("subDetail", JSON.stringify(newSubDetail));
            setSubDetail(newSubDetail);
            localStorage.setItem("auctStartData", JSON.stringify(null));

            //자동선택 체크시 낙찰물품 on
            let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));
            if(autoCheck === true){
                auctProData = {
                    de_date_online:auctStartData.de_date_online,
                    de_no_online:auctStartData.de_no_online,
                    de_seq:auctStartData.de_seq,
                    de_seq2:auctStartData.de_seq2,
                    de_status:"10",
                };
                localStorage.setItem("auctProData", JSON.stringify(auctProData));
                getSubDetail(auctStartData.de_date_online, auctStartData.de_no_online, auctStartData.de_seq, auctStartData.de_seq2);
                let idx = subList.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                setSubListOn(idx);
                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                //경매리스트 스크롤자동변경
                scrollMove(idx);
            }
        });
        
        // 경매 - 유찰 
        socket.on("auction_fail", (msg) => {
            // console.log(`event listen: auction_fail`);
            // console.log(`rec: ${JSON.stringify(msg, null, 2)}`);

            setCountPop(true);
            setCountPopType('end');

            let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));

            //응찰내역 조회
            // bidHistoryHandler(auctStartData.de_date_online,auctStartData.de_no_online,auctStartData.de_seq,auctStartData.de_seq2);

            let subList = JSON.parse(localStorage.getItem('subList'));
            let newSubList = subList.filter(pro => {
                if(pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2){
                    pro.de_status = "11";
                    return pro;
                }else{
                    return pro;
                }
            });
            setSubList(newSubList);
            localStorage.setItem("subList", JSON.stringify(newSubList));

            let newSubDetail = JSON.parse(localStorage.getItem('subDetail'));
            newSubDetail['de_status'] = "11";
            localStorage.setItem("subDetail", JSON.stringify(newSubDetail));
            setSubDetail(newSubDetail);
            localStorage.setItem("auctStartData", JSON.stringify(null));

            //자동선택 체크시 유찰물품 on
            let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
            let auctProData = JSON.parse(localStorage.getItem('auctProData'));
            if(autoCheck === true){
                auctProData = {
                    de_date_online:auctStartData.de_date_online,
                    de_no_online:auctStartData.de_no_online,
                    de_seq:auctStartData.de_seq,
                    de_seq2:auctStartData.de_seq2,
                    de_status:"11",
                };
                localStorage.setItem("auctProData", JSON.stringify(auctProData));
                getSubDetail(auctStartData.de_date_online, auctStartData.de_no_online, auctStartData.de_seq, auctStartData.de_seq2);
                let idx = subList.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                setSubListOn(idx);
                bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                //경매리스트 스크롤자동변경
                scrollMove(idx);
            }
        });
        
        // 온라인 경매 종료 
        socket.on("online_auction_close", (result) => {
            // console.log(`rec: ${JSON.stringify(result, null, 2)}`);
            // console.log(`경매 종료`);
            setAuctionEnd(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'현재 경매가 종료되었습니다.',
                confirmPopBtn:2,
            }));
            setAuctionStart(false);

            //경매종료 메세지 받기
            let time2 = new Date();
                time2 = moment(time2).format('HH:mm');
            let msg = {
                "type": "receive",
                "msg":result.msg,
                "user":{"mem_nm":result.mem_nm},
                "time":time2
            }
            setMessages(messages => [...messages, msg]);
        });

    },[socket]);


    //경매종료 confirm팝업 확인클릭시 온라인경매 목록페이지로 나가기
    const auctionExitHandler = () => {
        navigate("/merchant/auction");
    };
    

    // 페이지 접근시 바로 닉네임 세팅
    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }
    }, []);
    
    useEffect(()=>{
        socketInit();
    },[socket.connected]);

    //페이지이동시 
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            //전광판 나가기
            socket.emit("leave_merchant", 
                {
                    room: room,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
                () => {

                }
            );

            //온라인경매상세 localStorage 삭제
            localStorage.removeItem("auctStartData");
            localStorage.removeItem("auctProData");
            localStorage.removeItem("autoCheck");
            localStorage.removeItem("subList");
            localStorage.removeItem("subDetail");
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Cleanup function: 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    //경매시작, 경매종료 타이머 팝업
    useEffect(() => {
        if(countPop){
            const countdown = setInterval(() => {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 1) {
                    setCountPop(false);
                    setSeconds(3);
                }
            }, 1000);
            return () => clearInterval(countdown);
        }
    },[seconds, countPop]);

    //경매시작/낙찰 자동선택
    const auctionAutoHandler = (checked) => {
        setAuctionAutoCheck(auctionAutoCheck => !auctionAutoCheck);

        if(checked){
            let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
            autoCheck = true;
            localStorage.setItem("autoCheck", JSON.stringify(autoCheck));

            let list = JSON.parse(localStorage.getItem('subList'));
            if(list.length > 0){
                let auctStartData = JSON.parse(localStorage.getItem('auctStartData'));
                let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                // 경매시작한 물품이 있을때만
                if(auctStartData){
                    auctProData = {
                        de_date_online:auctStartData.de_date_online,
                        de_no_online:auctStartData.de_no_online,
                        de_seq:auctStartData.de_seq,
                        de_seq2:auctStartData.de_seq2,
                        de_status:auctStartData.de_status,
                    };
                    localStorage.setItem("auctProData", JSON.stringify(auctProData));
                    let list = JSON.parse(localStorage.getItem('subList'));
                    getSubDetail(auctStartData.de_date_online, auctStartData.de_no_online, auctStartData.de_seq, auctStartData.de_seq2);
                    let idx = list.findIndex(pro => pro.de_date_online === auctStartData.de_date_online && pro.de_no_online === auctStartData.de_no_online && pro.de_seq === auctStartData.de_seq && pro.de_seq2 === auctStartData.de_seq2);
                    setSubListOn(idx);
                    // console.log(idx);
                }
            }
        }else{
            let autoCheck = JSON.parse(localStorage.getItem('autoCheck'));
            autoCheck = false;
            localStorage.setItem("autoCheck", JSON.stringify(autoCheck));
        }
    };


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className="auction_wrap">
                    <div className="cont">
                        <div className="top_cont flex_between">
                            <p className="tit">온라인 경매 응찰시스템</p>
                            <ul className="flex_between inner_ul">
                                {/* <li>
                                    <div className="custom_select">
                                        <select>
                                            <option>[고추] 22년 08월 30일 21시 54분</option>
                                        </select>
                                    </div>
                                </li> */}
                                <li className="flex">
                                    <p className="txt">입찰자 정보</p>
                                    <div className="box flex_between">
                                        <div className="over_txt f_14"><span>{myInfo && `[${myInfo.am_code}]`} <strong className="medium">{myInfo && myInfo.am_name}</strong></span></div>
                                        <span className="tag_s">거래상태 - 정상</span>
                                    </div>
                                </li>
                                <li className="flex">
                                    <p className="txt">거래 가능액</p>
                                    <div className="box flex_between">
                                        <div className="bar_box">
                                            <div className="bar" style={{width:+percent+'%'}}></div>
                                        </div>
                                        <p className="f_16 medium">{myInfo && CF.MakeIntComma(myInfo.am_m_remain)}원</p>
                                    </div>
                                </li>
                                <li>
                                    <ul className="flex connect_box">
                                        <li className="flex">
                                            <span>경매사</span>
                                            <p className={`circle ${auctConnect ? "" : "out"}`}></p>
                                        </li>
                                        <li className="flex">
                                            <span>중도매인</span>
                                            <p className={`circle ${myConnect ? "" : "out"}`}></p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="auction_cont flex_between">
                            <div className="left_cont">
                                <div className="border_box">
                                    <div className="scroll_wrap" ref={proListScrollWrap}>
                                        <div className="custom_table4 pro_list_table" ref={proListTable}>
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'10%'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'10%'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'8%'}} />
                                                    <col style={{width:'8%'}} />
                                                    <col style={{width:'8%'}} />
                                                    <col style={{width:'8%'}} />
                                                    <col style={{width:'10%'}} />
                                                    <col style={{width:'8%'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>번호</th>
                                                        <th>출하주</th>
                                                        <th>생산자</th>
                                                        <th>품명</th>
                                                        <th>규격</th>
                                                        <th>등급</th>
                                                        <th>중량</th>
                                                        <th>수량</th>
                                                        <th>가격</th>
                                                        <th>낙찰자</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* 등록물품 있을때 ▼ */}
                                                    {subList.length > 0 && subList.map((listVals,idx) => {
                                                        return (
                                                            <tr 
                                                                className={subListOn === idx ? "tr_current" 
                                                                            : listVals.de_status == 11 ? "tr_red2"
                                                                            : listVals.de_status > 8 ? "tr_red" : "tr_blue"} 
                                                                key={idx} 
                                                                onClick ={()=>{
                                                                    //클릭한 물품 localStorage auctProData 저장
                                                                    let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                                                                    auctProData = {
                                                                        de_date_online:listVals.de_date_online,
                                                                        de_no_online:listVals.de_no_online,
                                                                        de_seq:listVals.de_seq,
                                                                        de_seq2:listVals.de_seq2,
                                                                        de_status:listVals.de_status,
                                                                    };
                                                                    localStorage.setItem("auctProData", JSON.stringify(auctProData));

                                                                    //클릭한 물품 정보 가져오기
                                                                    getSubDetail(listVals.de_date_online, listVals.de_no_online, listVals.de_seq, listVals.de_seq2);

                                                                    //클린한 물품 on
                                                                    setSubListOn(idx);

                                                                    //클릭한 물품상세 localStorage subDetail 저장
                                                                    JSON.parse(localStorage.getItem('subDetail'));
                                                                    localStorage.setItem("subDetail", JSON.stringify(listVals));

                                                                    //클릭한 물품 응찰내역 조회
                                                                    bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);
                                                                }}
                                                            >
                                                                <td>{listVals.de_no_online+listVals.de_seq+listVals.de_seq2}</td>
                                                                <td><span className="ellipsis">{listVals.as_name}</span></td>
                                                                <td>{listVals.de_farm_name}</td>
                                                                <td><span className="ellipsis">{listVals.gm_name}</span></td>
                                                                <td>{listVals.de_packing_name}</td>
                                                                <td>{listVals.de_dansu > 1 && listVals.de_dansu + "/"}{listVals.de_high_name}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_weight)}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_quantity)}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_price1)}</td>
                                                                <td>{listVals.de_amer_code ? listVals.de_amer_code : '-'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ul className="flex_end tp8 rp20 bp8">
                                        <li className="custom_check">
                                            <label htmlFor="auctionAutoCheck">
                                                <input type="checkbox" id="auctionAutoCheck" onChange={e => auctionAutoHandler(e.currentTarget.checked)} checked={auctionAutoCheck} />
                                                <span className="check">체크</span>
                                                <span className="txt">경매시작/낙찰 시 항목 자동선택</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tp20">
                                    <div className="flex_between">
                                        <ul className="tab_ul flex">
                                            <li className={tabContOn === 0 ? 'on' : ''} onClick={()=>{setTabContOn(0)}}>기본정보</li>
                                            <li className={tabContOn === 1 ? 'on' : ''} onClick={()=>{setTabContOn(1)}}>사진/동영상</li>
                                        </ul>
                                        <span className="tag">경매사 : {subDetail && subDetail.ip_auct_name}</span>
                                    </div>
                                    <div className="tab_wrap">
                                        <div className={`tab_cont ${tabContOn === 0 ? 'on' : ''}`}>
                                            <div className="custom_table gray_table">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width:'70px'}} />
                                                        <col style={{width:'20%'}} />
                                                        <col style={{width:'70px'}} />
                                                        <col style={{width:'auto'}} />
                                                        <col style={{width:'70px'}} />
                                                        <col style={{width:'auto'}} />
                                                        <col style={{width:'70px'}} />
                                                        <col style={{width:'auto'}} />
                                                        <col style={{width:'90px'}} />
                                                        <col style={{width:'auto'}} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th>현재상태</th>
                                                            <td>{subDetail && enum_de_status[subDetail.de_status][0]}</td>
                                                            <th>출하주</th>
                                                            <td colSpan="5">
                                                                <div className="over_txt"><span>{subDetail && subDetail.as_name}</span></div>
                                                            </td>
                                                            <th>팰릿</th>
                                                            <td className="tx_r">{subDetail && subDetail.ip_kpp}개</td>
                                                        </tr>
                                                        <tr>
                                                            <th>입하번호</th>
                                                            <td>{subDetail && `${subDetail.de_date_online}-${subDetail.de_no_online}`}</td>
                                                            <th>번호</th>
                                                            <td>{subDetail && subDetail.de_gods_code}</td>
                                                            <th>생산자</th>
                                                            <td colSpan="3">{subDetail && subDetail.de_farm_name}</td>
                                                            <th>목재(옥타곤)</th>
                                                            <td className="tx_r">{subDetail && subDetail.ip_kcp}개</td>
                                                        </tr>
                                                        <tr>
                                                            <th>품목</th>
                                                            {/* <td><div className="over_txt"><span>{subDetail && `${subDetail.gm_name}(${subDetail.de_gods_code})`}</span></div></td> */}
                                                            <td><div className="over_txt"><span>{subDetail && `${subDetail.gm_name}`}</span></div></td>
                                                            <th>규격</th>
                                                            <td>{subDetail && subDetail.de_packing_name}</td>
                                                            <th>등급</th>
                                                            <td>{subDetail && subDetail.de_high_name}</td>
                                                            <th>수량</th>
                                                            <td className="tx_r">{subDetail && subDetail.de_quantity}개</td>
                                                            <th>중량</th>
                                                            <td className="tx_r">{subDetail && subDetail.de_weight}kg</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="custom_table gray_table tm10">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width:'100px'}} />
                                                        <col style={{width:'auto'}} />
                                                        <col style={{width:'100px'}} />
                                                        <col style={{width:'auto'}} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th>품질</th>
                                                            <td><div className="over_txt"><span>{subDetail && subDetail.de_cont1}</span></div></td>
                                                            <th>당도, 산도 등</th>
                                                            <td><div className="over_txt"><span>{subDetail && subDetail.de_cont2}</span></div></td>
                                                        </tr>
                                                        <tr>
                                                            <th>특이사항</th>
                                                            <td><div className="over_txt"><span>{subDetail && subDetail.de_cont3}</span></div></td>
                                                            <th>입찰자 메모</th>
                                                            <td className="p0">
                                                                <div className="flex_between">
                                                                    <div className="lp9 rp9 w_50">
                                                                        <div className="over_txt"><span>{subDetail && subDetail.memo.memo}</span></div>
                                                                    </div>
                                                                    <div className="flex_between memo_txt w_50">
                                                                        <p>메모단가</p>
                                                                        <p>{subDetail && subDetail.memo.price}원</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={`tab_cont ${tabContOn === 1 ? 'on' : ''}`}>
                                            <div className="custom_table gray_table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="tx_c">사진1</th>
                                                            <th className="tx_c">사진2</th>
                                                            <th className="tx_c">동영상</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="tx_c">
                                                                {subDetail && subDetail.de_img1 === "" ? 
                                                                    <div className="flex_center h_160"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                                    :   subDetail && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${subDetail.de_img1}`}))
                                                                        }}>
                                                                            <img src={`${subDetail.de_img1}`} alt="이미지"/>
                                                                        </div>
                                                                }
                                                            </td>
                                                            <td className="tx_c">
                                                                {subDetail && subDetail.de_img2 === "" ? 
                                                                    <div className="flex_center h_160"><img src={imgNone} alt="이미지" className="w_49px"/></div>
                                                                    :   subDetail && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'사진1',mediaSrc:`${subDetail.de_img2}`}))
                                                                        }}>
                                                                            <img src={`${subDetail.de_img2}`} alt="이미지"/>
                                                                        </div>
                                                                }
                                                            </td>
                                                            <td className="tx_c">
                                                                {subDetail &&  subDetail.de_video === "" ? 
                                                                    <div className="flex_center h_160"><img src={videoUp} alt="이미지"/></div>
                                                                    :   subDetail && <div className="thumb_box h_160 over_hidden" onClick={() => {
                                                                            dispatch(mediaPop({mediaPop:true,mediaTit:'동영상',mediaSrc:`${subDetail.de_video}`}))
                                                                        }}>
                                                                            <video src={`${subDetail.de_video}`} />
                                                                        </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_cont">
                                <div className="border_box">
                                    <div className="scroll_wrap" ref={bidHistoryBox}>
                                        <div className="custom_table5">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'90px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>응찰시간</th>
                                                        <th>응찰단가</th>
                                                        <th>구분</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bidList.length > 0 && bidList.map((bid,i)=>{
                                                        // console.log(bid)
                                                        // let num = bid.de_no_online+bid.de_seq+bid.de_seq2;
                                                        // let time = moment(bid.reg_dt).utc().format("HH:mm.ss.SSS");
                                                        // let price = CF.MakeIntComma(bid.price);
                                                        // let status = enum_memo_status[bid.memo_status.trim()][0];
                                                        let time = moment(bid.l_time).format("HH:mm.ss.SSS");
                                                        let gbn = bid.l_gbn;
                                                        let price = CF.MakeIntComma(bid.l_price);
                                                        return(
                                                            <tr key={i} className={bid.memo_status == 0 ? 'tr_orange' : bid.memo_status == 7 ? 'tr_yellow' : bid.memo_status == 8 ? 'tr_blue' : ''}>
                                                                <td>{time}</td>
                                                                <td className="tx_r">{price}</td>
                                                                <td>{gbn}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ul className="btn_ul flex">
                                        <li onClick={()=>{
                                                setBidPriceBtnOn(0);
                                                setBidInput('');
                                                bid.current.focus();
                                                priceSetHandler('');
                                            }} 
                                            className={bidPriceBtnOn === 0 ? 'on' : ''}
                                        >
                                            <button>기본</button>
                                        </li>
                                        <li onClick={()=>{
                                                setBidPriceBtnOn(1);
                                                setBidInput('0');
                                                bid.current.value = '0';
                                                setTimeout(()=>{
                                                    bid.current.setSelectionRange(0,0);
                                                    bid.current.focus();
                                                },100);
                                                priceSetHandler('0');
                                            }} 
                                            className={bidPriceBtnOn === 1 ? 'on' : ''}
                                        >
                                            <button>십원</button>
                                        </li>
                                        <li onClick={()=>{
                                                setBidPriceBtnOn(2);
                                                setBidInput('00');
                                                bid.current.value = '00';
                                                setTimeout(()=>{
                                                    bid.current.setSelectionRange(0,0);
                                                    bid.current.focus();
                                                },100);
                                                priceSetHandler('00');
                                            }} 
                                            className={bidPriceBtnOn === 2 ? 'on' : ''}
                                        >
                                            <button>백원</button>
                                        </li>
                                        <li onClick={()=>{
                                                setBidPriceBtnOn(3);
                                                setBidInput('000');
                                                bid.current.value = '000';
                                                setTimeout(()=>{
                                                    bid.current.setSelectionRange(0,0);
                                                    bid.current.focus();
                                                },100);
                                                priceSetHandler('000');
                                            }} 
                                            className={bidPriceBtnOn === 3 ? 'on' : ''}
                                        >
                                            <button>천원</button>
                                        </li>
                                    </ul>
                                    <ul className="btn_ul2 flex_wrap">
                                        <li>
                                            <div className="custom_input">
                                                <NumericFormat thousandSeparator="," decimalScale={0} allowLeadingZeros getInputRef={bid} value={bidInput} onChange={(e)=>{setBidInput(e.currentTarget.value)}} className="tx_r" onKeyDown={(e)=>{
                                                    // 응찰가격입력후 엔터치면 응찰
                                                    if(e.key === "Enter") {
                                                        if(auctionEnd === false || auctConnect === true){
                                                            if (e.nativeEvent.isComposing === false) {
                                                                e.preventDefault();
                                                                handleBid();
                                                            }
                                                        }
                                                    }
                                                }}/>
                                            </div>
                                        </li>
                                        <li>
                                            {auctionEnd || !auctConnect ?
                                                <button onClick={()=>{
                                                    setConfirm(true);
                                                    dispatch(confirmPop({
                                                        confirmPopTit:'알림',
                                                        confirmPopTxt:'메모가응찰이 불가합니다.',
                                                        confirmPopBtn:1,
                                                    }));
                                                }} className="f_15">메모가 응찰</button>
                                                :<button onClick={handleMemoBid} className="f_15">메모가 응찰</button>
                                            }
                                        </li>
                                        <li>
                                            {auctionEnd || !auctConnect ?
                                                <button onClick={()=>{
                                                    setConfirm(true);
                                                    dispatch(confirmPop({
                                                        confirmPopTit:'알림',
                                                        confirmPopTxt:'응찰취소가 불가합니다.',
                                                        confirmPopBtn:1,
                                                    }));
                                                }} className="f_15"><span>응찰취소</span></button>
                                                :<button onClick={handleCancelBid} className="f_15"><span>응찰취소</span></button>
                                            }
                                        </li>
                                        <li>
                                            {auctionEnd || !auctConnect ?
                                                <button onClick={()=>{
                                                    setConfirm(true);
                                                    dispatch(confirmPop({
                                                        confirmPopTit:'알림',
                                                        confirmPopTxt:'응찰이 불가합니다.',
                                                        confirmPopBtn:1,
                                                    }));
                                                }} className="f_15"><span>응찰</span></button>
                                                :<button onClick={handleBid} className="f_15"><span>응찰</span></button>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="message_wrap tm20">
                                    <div className="top_box b_border_none">
                                        <div className="scroll_wrap" ref={messageBox}>
                                            <ul className="box" id="message_list">
                                                {messages && messages.map((msg,i)=>{
                                                    return(
                                                        <li key={i} className={msg.type}>
                                                            <p className="txt">
                                                                {msg.type === "receive" && <strong>{msg.user.mem_nm && msg.user.mem_nm+'경매사'}</strong>}
                                                                {msg.msg}
                                                            </p>
                                                            <span className="time">{msg.time}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="bottom_box">
                                        <div className="custom_input">
                                            <input type="text" placeholder="메시지를 입력해주세요" ref={message} onKeyDown={(e)=>{
                                                if(e.key === "Enter") {
                                                    if (e.nativeEvent.isComposing === false) {
                                                        e.preventDefault();
                                                        handleMessage();
                                                    }
                                                }
                                            }}/>
                                        </div>
                                        <button className="btn_s" onClick={handleMessage} >전송</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <div className="top_price_box"><span>잔액 </span>{myInfo && CF.MakeIntComma(myInfo.am_m_remain)}원</div>
                <div className="mo_auto_btn">
                    <div className="custom_check custom_check3">
                        <label htmlFor="auctionAutoCheck">
                            <input type="checkbox" id="auctionAutoCheck" onChange={e => auctionAutoHandler(e.currentTarget.checked)} checked={auctionAutoCheck} />
                            <span className="btn">전광판 자동</span>
                        </label>
                    </div>
                </div>
                <div className="auction_wrap">
                    <div className="scroll_wrap over_x" ref={proListScrollWrap}>
                        <div className="custom_table4 w_900px pro_list_table" ref={proListTable}>
                            <table>
                                <colgroup>
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'10%'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'8%'}} />
                                    <col style={{width:'8%'}} />
                                    <col style={{width:'8%'}} />
                                    <col style={{width:'8%'}} />
                                    <col style={{width:'10%'}} />
                                    <col style={{width:'70px'}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>출하주</th>
                                        <th>생산자</th>
                                        <th>품명</th>
                                        <th>규격</th>
                                        <th>등급</th>
                                        <th>중량</th>
                                        <th>수량</th>
                                        <th>가격</th>
                                        <th>낙찰자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 등록물품 있을때 ▼ */}
                                    {subList.length > 0 && subList.map((listVals,idx) => {
                                        return (
                                            <tr 
                                                className={subListOn === idx ? "tr_current" 
                                                            : listVals.de_status == 11 ? "tr_red2"
                                                            : listVals.de_status > 8 ? "tr_red" : "tr_blue"} 
                                                key={idx} 
                                                onClick ={()=>{
                                                    //클릭한 물품 localStorage auctProData 저장
                                                    let auctProData = JSON.parse(localStorage.getItem('auctProData'));
                                                    auctProData = {
                                                        de_date_online:listVals.de_date_online,
                                                        de_no_online:listVals.de_no_online,
                                                        de_seq:listVals.de_seq,
                                                        de_seq2:listVals.de_seq2,
                                                        de_status:listVals.de_status,
                                                    };
                                                    localStorage.setItem("auctProData", JSON.stringify(auctProData));

                                                    //클릭한 물품 정보 가져오기
                                                    getSubDetail(listVals.de_date_online, listVals.de_no_online, listVals.de_seq, listVals.de_seq2);

                                                    //클린한 물품 on
                                                    setSubListOn(idx);

                                                    //클릭한 물품상세 localStorage subDetail 저장
                                                    JSON.parse(localStorage.getItem('subDetail'));
                                                    localStorage.setItem("subDetail", JSON.stringify(listVals));

                                                    //클릭한 물품 응찰내역 조회
                                                    bidHistoryHandler(auctProData.de_date_online,auctProData.de_no_online,auctProData.de_seq,auctProData.de_seq2);

                                                    //클릭한 물품 상세팝업
                                                    dispatch(auctionProDetailPop(true));
                                                }}
                                            >
                                                <td>{listVals.de_no_online+listVals.de_seq+listVals.de_seq2}</td>
                                                <td><span className="ellipsis">{listVals.as_name}</span></td>
                                                <td>{listVals.de_farm_name.trim()}</td>
                                                <td><span className="ellipsis">{listVals.gm_name}</span></td>
                                                <td>{listVals.de_packing_name}</td>
                                                <td>{listVals.de_dansu > 1 && listVals.de_dansu + "/"}{listVals.de_high_name}</td>
                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_weight)}</td>
                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_quantity)}</td>
                                                <td className="tx_r">{CF.MakeIntComma(listVals.de_price1)}</td>
                                                <td>{listVals.de_amer_code ? listVals.de_amer_code : '-'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="memo_cont">
                        <div className="black_box">
                            <div className="txt_box">
                                <div className="top_box">
                                    <button className="btn_memo" onClick={()=>{setMoMemoPop(true)}}>메모</button>
                                    <p>[{subDetail && subDetail.memo.price}원]</p>
                                </div>
                                {bidList.length > 0 &&
                                    <p className="yellow_txt">{CF.MakeIntComma(bidList[bidList.length-1].l_price)}</p>
                                }
                            </div>
                            <div className="num">
                                <NumericFormat thousandSeparator="," decimalScale={0} allowLeadingZeros getInputRef={bid} value={bidInput} onChange={(e)=>{setBidInput(e.currentTarget.value)}} className="tx_r" inputMode="none" />
                            </div>
                        </div>
                        <ul className="btn_ul">
                            <li onClick={()=>{
                                    setBidPriceBtnOn(0);
                                    setBidInput('');
                                    bid.current.focus();
                                    priceSetHandler('');
                                }} 
                                className={bidPriceBtnOn === 0 ? 'on' : ''}
                            >
                                <button>기본</button>
                            </li>
                            <li onClick={()=>{
                                    setBidPriceBtnOn(1);
                                    setBidInput('0');
                                    bid.current.value = '0';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                    priceSetHandler('0');
                                }} 
                                className={bidPriceBtnOn === 1 ? 'on' : ''}
                            >
                                <button>십원</button>
                            </li>
                            <li onClick={()=>{
                                    setBidPriceBtnOn(2);
                                    setBidInput('00');
                                    bid.current.value = '00';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                    priceSetHandler('00');
                                }} 
                                className={bidPriceBtnOn === 2 ? 'on' : ''}
                            >
                                <button>백원</button>
                            </li>
                            <li onClick={()=>{
                                    setBidPriceBtnOn(3);
                                    setBidInput('000');
                                    bid.current.value = '000';
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                    priceSetHandler('000');
                                }} 
                                className={bidPriceBtnOn === 3 ? 'on' : ''}
                            >
                                <button>천원</button>
                            </li>
                        </ul>
                    </div>
                    <ul className="keypad_cont">
                        <li>
                            <button onClick={() => {
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "7" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>7</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(8+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "8" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>8</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(9+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "9" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>9</span>
                            </button>
                        </li>
                        <li>
                            <button 
                                onClick={()=>{
                                dispatch(bidListPop({
                                    bidListPop:true,
                                    bidListPopCode:auct_code,
                                    bidListPopDate:auct_date,
                                }));
                            }}>
                                <span>
                                    응찰 <br />
                                    목록
                                </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(4+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "4" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>4</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(5+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "5" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>5</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(6+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "6" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>6</span>
                            </button>
                        </li>
                        <li>
                            {auctionEnd || !auctConnect ?
                                <button onClick={()=>{
                                    setConfirm(true);
                                    dispatch(confirmPop({
                                        confirmPopTit:'알림',
                                        confirmPopTxt:'응찰취소가 불가합니다.',
                                        confirmPopBtn:1,
                                    }));
                                }}>
                                    <span>
                                        응찰 <br />
                                        취소
                                    </span>
                                </button>
                                :<button onClick={handleCancelBid}>
                                    <span>
                                        응찰 <br />
                                        취소
                                    </span>
                                </button>
                            }
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(1+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "1" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>1</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(2+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "2" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>2</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                // setBidInput(3+bidInput);
                                const inputStr = String(bidInput);
                                const lastIdx = inputStr.length - bidPriceBtnOn;
                                const newInputStr = inputStr.slice(0, lastIdx) + "3" + inputStr.slice(lastIdx);
                                setBidInput(Number(newInputStr));
                            }}>
                                <span>3</span>
                            </button>
                        </li>
                        <li>
                            {auctionEnd || !auctConnect ?
                                <button onClick={()=>{
                                    setConfirm(true);
                                    dispatch(confirmPop({
                                        confirmPopTit:'알림',
                                        confirmPopTxt:'메모가응찰이 불가합니다.',
                                        confirmPopBtn:1,
                                    }));
                                }} >
                                    <span>
                                        메모가 <br />
                                        응찰
                                    </span>
                                </button>
                                :<button onClick={handleMemoBid}>
                                    <span>
                                        메모가 <br />
                                        응찰
                                    </span>
                                </button>
                            }
                        </li>
                        <li>
                            <button onClick={()=>{
                                if(bidPriceBtnOn == 0){
                                    setBidInput('');
                                    bid.current.focus();
                                }else if(bidPriceBtnOn == 1){
                                    setBidInput('0');
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn == 2){
                                    setBidInput('00');
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }else if(bidPriceBtnOn == 3){
                                    setBidInput('000');
                                    setTimeout(()=>{
                                        bid.current.setSelectionRange(0,0);
                                        bid.current.focus();
                                    },100);
                                }
                            }}>
                                <span>지우기</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>{
                                if(bidInput > 0){
                                    // setBidInput(bidInput+0);
                                    const inputStr = String(bidInput);
                                    const lastIdx = inputStr.length - bidPriceBtnOn;
                                    const newInputStr = inputStr.slice(0, lastIdx) + "0" + inputStr.slice(lastIdx);
                                    setBidInput(Number(newInputStr));
                                }
                            }}>
                                <span>0</span>
                            </button>
                        </li>
                        <li>
                            {auctionEnd || !auctConnect ?
                                <button onClick={()=>{
                                    setConfirm(true);
                                    dispatch(confirmPop({
                                        confirmPopTit:'알림',
                                        confirmPopTxt:'응찰이 불가합니다.',
                                        confirmPopBtn:1,
                                    }));
                                }}>
                                    <span>응찰하기</span>
                                </button>
                                :<button onClick={handleBid}>
                                    <span>응찰하기</span>
                                </button>
                            }
                        </li>
                    </ul>  
                    <div className="message_wrap">
                        <div className="top_box b_border_none">
                            <div className="scroll_wrap" ref={messageBox}>
                                <ul className="box" id="message_list">
                                    {messages && messages.map((msg,i)=>{
                                        return(
                                            <li key={i} className={msg.type}>
                                                <p className="txt">
                                                    {msg.type === "receive" && <strong>{msg.user.mem_nm && '['+msg.user.mem_nm+'경매사]'}</strong>}
                                                    {msg.msg}
                                                </p>
                                                <span className="time">{msg.time}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="bottom_box">
                            <div className="custom_input">
                                <input type="text" placeholder="메시지를 입력해주세요" ref={message} />
                            </div>
                            <button className="btn_s" onClick={handleMessage}>전송</button>
                        </div>
                    </div> 
                </div>
                
                {/* 메모팝업 */}
                {moMemoPop &&
                    <div className="pop_wrap">
                        <div className="dim" onClick={()=>{setMoMemoPop(false)}}></div>
                        <div className="mo_pop_cont small_pop memo_pop">
                            <div className="mo_pop_tit">
                                <p className="tit">메모<span className="txt_sky">{subDetail && subDetail.memo.price}원</span></p>
                                <button className="pop_close" onClick={()=>{setMoMemoPop(false)}}>닫기버튼</button>
                            </div>
                            <div className="t_border tx_c">
                                <div className="txt_box">{subDetail && subDetail.memo.memo}</div>
                                <div className="tp10">
                                    <button className="btn_type3 w_100" onClick={()=>{setMoMemoPop(false)}}>닫기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        {/* 경매카운트 ( 경매종료시 count_cont 클래스에 end 추가)*/}
        {countPop &&
            <div className="pop_wrap">
                <div className="dim"></div>
                <div className={countPopType == 'end' ? 'count_cont end' : 'count_cont'}>
                    <p className="num">{seconds}</p>
                    <p className="txt">{countPopType == 'end' ? '경매종료' : '경매시작'}</p>
                </div>
            </div>
        }

        {/* 응찰클릭시 confirm팝업 */}
        {bidConfirm && <ConfirmPop onClickHandler={closePopHandler} />}

        {/* 경매종료시 confirm팝업 */}
        {auctionEnd && <ConfirmPop onClickHandler={auctionExitHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}

    </>);
};

export default AuctionDetail;