import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { enum_api_uri, enum_ip_status, enum_sell_kind, enum_from_kind, enum_de_status, enum_memo_status } from "../../../../config/enum";
import * as CF from "../Function";
import { resultDetailPop, reasonPop, reasonPopOk, confirmPop } from "../../../../store/popupSlice";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import ConfirmPop from "./ConfirmPop";

const ResultDetailPop = () => {
    const a_list_product = enum_api_uri.a_list_product;
    const a_list_product_bid_result = enum_api_uri.a_list_product_bid_result;
    const a_list_product_results = enum_api_uri.a_list_product_results;
    const a_auction_result_bid_history = enum_api_uri.a_auction_result_bid_history;
    const a_bid_success = enum_api_uri.a_bid_success;
    const a_total_amount = enum_api_uri.a_total_amount;
    const popup = useSelector((state)=>state.popup);
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState({});
    const [proList, setProList] = useState([]);
    const [onProduct, setOnProduct] = useState(0);
    const [proData, setProData] = useState([]);
    const [bidList, setBidList] = useState([]);
    const [bidSuccess, setBidSuccess] = useState({});
    const [SuccessConfirm, setSuccessConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const token = localStorage.getItem("token");
    const proListBox = useRef();
    const [total, setTotal] = useState(0);

    const closePopHandler = () => {
        dispatch(resultDetailPop(null));
    }

    useEffect(()=>{
        setInvoiceData(invoiceData);
        // console.log(invoiceData);
    },[invoiceData]);

    useEffect(()=>{
        setProList(proList);
    },[proList]);

    useEffect(()=>{
        setBidList(bidList);
        // console.log(bidList);
    },[bidList]);

    useEffect(()=>{
        setBidSuccess(bidSuccess);
        // console.log(bidSuccess);
    },[bidSuccess]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setSuccessConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);
    
    // 송품장,물품정보 가져오기
    useEffect(() => {
        let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
        let ip_date_online = getData.ip_date_online;
        let ip_no_online = getData.ip_no_online;

        axios.get(`${a_list_product_bid_result.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                res.data.ip_reg_dt = moment(res.data.ip_reg_dt).utc().format('YYYY년 MM월 DD일 HH시 mm분 ss초');
                res.data.ip_auct_date_start = res.data.ip_auct_date_start.trim();
                res.data.ip_auct_date_start = res.data.ip_auct_date_start.replace(/(\d{4})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:');
                res.data.ip_auct_date_end = res.data.ip_auct_date_end.trim();
                res.data.ip_auct_date_end = res.data.ip_auct_date_end.replace(/(\d{4})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:');
                let ip_status = Number(res.data.ip_status.trim());
                res.data.ip_status = enum_ip_status[ip_status][0];
                let ip_s_kind = Number(res.data.ip_s_kind.trim());
                res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                let ip_kind = Number(res.data.ip_kind.trim());
                res.data.ip_kind = enum_from_kind[ip_kind];
                if(res.data.ip_kpp !== null){
                    res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                }
                if(res.data.ip_kcp !== null){
                    res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                }
                if(res.data.ip_wo_tot !== null){
                    res.data.ip_wo_tot = CF.MakeIntComma(res.data.ip_wo_tot);
                }
                if(res.data.ip_quan_tot !== null){
                    res.data.ip_quan_tot = CF.MakeIntComma(res.data.ip_quan_tot);
                }
                res.data.ip_delivery_date_start  = moment(res.data.ip_delivery_date_start.trim()).format('YYYY-MM-DD');
                res.data.ip_delivery_date_end  = moment(res.data.ip_delivery_date_end.trim()).format('YYYY-MM-DD');
                res.data.ip_loca_name = res.data.ip_loca_name.trim();
                res.data.ip_loca = res.data.ip_loca.trim();
                setInvoiceData(res.data);
                let list = [...res.data.detltrns];
                setProList(list);

                // 등록된물품 리스트의 맨처음 물품정보 뿌려주기
                proListBox.current.scrollTo(0,0);
                setOnProduct(0);
                if(list.length > 0){
                    // console.log(list[0])
                    proListClickHandler(list[0]);
                    let productData = JSON.parse(localStorage.getItem('productData'));
                    productData = {
                        de_ip_date:list[0].de_ip_date,
                        de_ip_no:list[0].de_ip_no,
                        de_date_online:list[0].de_date_online,
                        de_no_online:list[0].de_no_online,
                        de_seq:list[0].de_seq,
                        de_seq2:list[0].de_seq2,
                        de_quantity:list[0].de_quantity
                    }
                    localStorage.setItem("productData", JSON.stringify(productData));

                    //입찰기록 가져오기
                    axios.get(`${a_auction_result_bid_history.replace(':de_date_online', list[0].de_date_online).replace(':de_no_online', list[0].de_no_online).replace(':de_seq', list[0].de_seq).replace(':de_seq2', list[0].de_seq2)}`, {
                        headers:{Authorization: `Bearer ${token}`}
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setBidList(res.data.bidHistory);
                            setBidSuccess(res.data.successMarchant);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });

                    // 낙찰총금액 가져오기
                    getTotalAmount();
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    }, []);

    //송품장에 등록된물품 리스트 클릭시 물품정보 뿌려주기
    const proListClickHandler = (data) => {
        axios.get(`${a_list_product_results.replace(':de_ip_date', data.de_ip_date).replace(':de_ip_no', data.de_ip_no).replace(':de_date_online', data.de_date_online).replace(':de_no_online', data.de_no_online).replace(':de_seq', data.de_seq).replace(':de_seq2', data.de_seq2)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                let data = res.data;
                // console.log(data)
                data.de_weight = CF.MakeIntComma(data.de_weight);
                data.de_quantity = CF.MakeIntComma(data.de_quantity);
                data.de_cost2 = CF.MakeIntComma(data.de_cost2);
                data.de_dansu = CF.MakeIntComma(data.de_dansu);
                data.de_hope_pri = CF.MakeIntComma(data.de_hope_pri);
                data.de_deli_req_date = data.de_deli_req_date !== null ? moment(data.de_deli_req_date.trim()).format('YYYY-MM-DD') : '-';
                data.de_deli_rece_nm = data.de_deli_rece_nm !== null ? data.de_deli_rece_nm : '-';

                // 인수등록일시 있을때
                if (res.data.memo.hasOwnProperty('receive_confirm_dt')) {
                    if(res.data.memo.receive_confirm_dt != null){
                        res.data.memo.receive_confirm_dt = moment(res.data.memo.receive_confirm_dt).format('YYYY-MM-DD HH:mm');
                    }
                }

                //배송정보 입력시간
                res.data.de_deli_rece_process_dt = CF.objValueTrim(res.data.de_deli_rece_process_dt);
                res.data.de_deli_rece_process_dt = moment(res.data.de_deli_rece_process_dt).utc().format('YYYY-MM-DD HH:mm');

                //운임정보 입력시간
                res.data.de_deli_process_dt = CF.objValueTrim(res.data.de_deli_process_dt);
                res.data.de_deli_process_dt = moment(res.data.de_deli_process_dt).utc().format('YYYY-MM-DD HH:mm');

                //운임정보 입력시간
                if(res.data.de_complete_close_dt) {
                    res.data.de_complete_close_dt = CF.objValueTrim(res.data.de_complete_close_dt);
                    res.data.de_complete_close_dt = moment(res.data.de_complete_close_dt).utc().format('YYYY-MM-DD HH:mm');
                }

                setProData(data);

                let productData = JSON.parse(localStorage.getItem('productData'));
                productData = {
                    de_ip_date:data.de_ip_date,
                    de_ip_no:data.de_ip_no,
                    de_date_online:data.de_date_online,
                    de_no_online:data.de_no_online,
                    de_seq:data.de_seq,
                    de_seq2:data.de_seq2,
                    de_quantity:data.de_quantity
                }
                localStorage.setItem("productData", JSON.stringify(productData));

                //입찰기록 가져오기
                axios.get(`${a_auction_result_bid_history.replace(':de_date_online', productData.de_date_online).replace(':de_no_online', productData.de_no_online).replace(':de_seq', productData.de_seq).replace(':de_seq2', productData.de_seq2)}`, {
                    headers:{Authorization: `Bearer ${token}`}
                })
                .then((res)=>{
                    if(res.status === 200){
                        setBidList(res.data.bidHistory);
                        setBidSuccess(res.data.successMarchant);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //입찰 - 낙찰처리하기
    const bidSuccessHandler = () => {
        let getData = localStorage.getItem("productData");
            getData = JSON.parse(getData);
        let body = {
            idx:bidSuccess.idx,
            de_amer_code:bidSuccess.asso_code,
            price:bidSuccess.price,
        };
        axios.put(`${a_bid_success.replace(':de_date_online', getData.de_date_online).replace(':de_no_online', getData.de_no_online).replace(':de_seq', getData.de_seq).replace(':de_seq2', getData.de_seq2)}`,body,{
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'낙찰처리되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);

                let newProList = proList.filter(pro => {
                    if(pro.de_date_online === proData.de_date_online && pro.de_no_online === proData.de_no_online && pro.de_seq === proData.de_seq && pro.de_seq2 === proData.de_seq2){
                        pro.de_status = "12";
                        return pro;
                    }else{
                        return pro;
                    }
                });
                setProList(newProList);

                //물품상세정보 다시 불러오기
                let productData = JSON.parse(localStorage.getItem('productData'));
                proListClickHandler(productData);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    }

    //입찰 - 낙찰처리, 유찰처리시 물품상태 업데이트, 낙찰총금액 업데이트
    useEffect(()=>{
        let productData = JSON.parse(localStorage.getItem('productData'));
        if(popup.reasonPopOk){
            //물품리스트 상태값 변경
            let newProList = proList.filter(pro => {
                if(pro.de_date_online === productData.de_date_online && pro.de_no_online === productData.de_no_online && pro.de_seq === productData.de_seq && pro.de_seq2 === productData.de_seq2){
                    pro.de_status = productData.de_status;
                    return pro;
                }else{
                    return pro;
                }
            });
            setProList(newProList);

            //물품상세정보 다시 불러오기
            proListClickHandler(productData);

            dispatch(reasonPopOk(false));

            getTotalAmount();
        }
    },[popup.reasonPopOk]);

    //낙찰총금액 가져오기
    const getTotalAmount = () => {
        let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
        let ip_date_online = getData.ip_date_online;
        let ip_no_online = getData.ip_no_online;

        axios.get(`${a_total_amount.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online)}`, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                setTotal(res.data.totalAmount);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    
    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim" onClick={closePopHandler}></div>
                <div className="pop_cont">
                    <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">물품상세</p>
                    </div>
                    <div className="scroll_wrap">
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'90px'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">
                                            <div className="flex_between">
                                                <span>{invoiceData.as_name}</span>
                                                {invoiceData.ip_auct_name !== null &&
                                                    <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                                                }
                                            </div>
                                        </td>
                                        <th>등록일시</th>
                                        <td>{invoiceData.ip_reg_dt}</td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td>{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                        <th>상태</th>
                                        <td>
                                            <span className="tag">{invoiceData.ip_status}</span>
                                        </td>
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            <th>입찰시간</th>
                                            : <th>경매시간</th>
                                        }
                                        <td>{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'+invoiceData.ip_auct_date_end}</td>
                                    </tr>
                                    <tr>
                                        <th>매매구분</th>
                                        <td>{invoiceData.ip_s_kind}</td>
                                        <th>팰릿</th>
                                        <td className="tx_r">{invoiceData.ip_kpp}개</td>
                                        <th>목재<span className="f_12">(옥타곤)</span></th>
                                        <td className="tx_r">{invoiceData.ip_kcp}개</td>
                                    </tr>
                                    <tr>
                                        <th>판매구분</th>
                                        <td>{invoiceData.ip_kind}</td>
                                        <th>산지</th>
                                        <td>{`${invoiceData.ip_loca_name} (${invoiceData.ip_loca})`}</td>
                                        <th>낙찰 총 금액</th>
                                        <td className="tx_r">{CF.MakeIntComma(total)}원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                    <col style={{width:'100px'}} />
                                    <col style={{width:'auto'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>총 운송비</th>
                                        <td className="tx_r">{invoiceData.ip_wo_tot}원</td>
                                        <th>총 수량</th>
                                        <td className="tx_r">{invoiceData.ip_quan_tot}개</td>
                                        <th>배송가능기간</th>
                                        <td>{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="tm10">
                            <div className="border_box scroll_wrap h_160" ref={proListBox}>
                                <div className="custom_table4">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'70px'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'13%'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'7%'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'8%'}} />
                                            <col style={{width:'6%'}} />
                                            <col style={{width:'6%'}} />
                                            <col style={{width:'11%'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>경매순서</th>
                                                <th>번호</th>
                                                <th>상태</th>
                                                <th>생산자</th>
                                                <th>품목</th>
                                                <th>품목명</th>
                                                <th>중량</th>
                                                <th>규격</th>
                                                <th>등급</th>
                                                <th>수량</th>
                                                <th>희망단가</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {proList && proList.map((product, idx)=>{
                                                let de_status_num = product.de_status.trim();
                                                let de_status = product.de_status.trim();
                                                    de_status = enum_de_status[de_status][0];
                                                let de_weight = CF.MakeIntComma(product.de_weight);
                                                let de_quantity = CF.MakeIntComma(product.de_quantity);
                                                let de_hope_pri = CF.MakeIntComma(product.de_hope_pri);
                                                return(
                                                    <tr key={idx} 
                                                        onClick={()=> {
                                                            setOnProduct(idx);
                                                            proListClickHandler(product);
                                                            let productData = JSON.parse(localStorage.getItem('productData'));
                                                            productData = {
                                                                de_ip_date:product.de_ip_date,
                                                                de_ip_no:product.de_ip_no,
                                                                de_date_online:product.de_date_online,
                                                                de_no_online:product.de_no_online,
                                                                de_seq:product.de_seq,
                                                                de_seq2:product.de_seq2,
                                                                de_quantity:product.de_quantity
                                                            }
                                                            localStorage.setItem("productData", JSON.stringify(productData));
                                                        }}
                                                        className={onProduct === idx ? "tr_current" 
                                                                    //경매
                                                                    : invoiceData.ip_s_kind == "경매" ?
                                                                        de_status_num == 11 ? "tr_red2"
                                                                        : de_status_num > 8 ? "tr_red" 
                                                                        : "tr_blue"
                                                                    //입찰
                                                                    : invoiceData.ip_s_kind == "입찰" &&
                                                                        de_status_num == 13 || de_status_num == 17 ? "tr_red2"
                                                                        : de_status_num > 9 ? "tr_red" 
                                                                        : "tr_blue"
                                                        }
                                                    >
                                                        <td>{idx+1}</td>
                                                        <td>{product.de_no_online+product.de_seq+product.de_seq2}</td>
                                                        <td>{de_status}</td>
                                                        <td><span className="ellipsis">{product.de_farm_name}</span></td>
                                                        <td>{product.de_gods_code}</td>
                                                        <td><span className="ellipsis">{product.gm_name}</span></td>
                                                        <td className="tx_r">{de_weight}kg</td>
                                                        <td>{product.de_packing_name}</td>
                                                        <td>{product.de_high_name}</td>
                                                        <td className="tx_r">{de_quantity}</td>
                                                        <td className="tx_r">{de_hope_pri}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'70px'}} />
                                        <col style={{width:'auto'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>{proData.de_farm_name}</td>
                                            <th>품명</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.gm_name === null ? proData.gm_stmd_name : proData.gm_name}</span></div>
                                            </td>
                                            <th>중량</th>
                                            <td className="tx_r">{proData.de_weight}kg</td>
                                            <th>규격</th>
                                            <td>{proData.de_packing_name}</td>
                                            <th>친환경</th>
                                            <td>{proData.de_quality_name}</td>
                                        </tr>
                                        <tr>
                                            <th>등급</th>
                                            <td>{proData.de_high_name}</td>
                                            <th>수량</th>
                                            <td className="tx_r">{proData.de_quantity}개</td>
                                            <th>운임비</th>
                                            <td className="tx_r">{proData.de_cost2}원</td>
                                            <th>단수</th>
                                            <td className="tx_r">{proData.de_dansu}단</td>
                                            <th className="sky_bg">희망단가</th>
                                            <td className="tx_r sky_bg">{proData.de_hope_pri}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table t_border_none">
                                <table>
                                    <colgroup>
                                        <col style={{width:'90px'}} />
                                        <col style={{width:'auto'}} />
                                        <col style={{width:'90px'}} />
                                        <col style={{width:'auto'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.de_cont1}</span></div>
                                            </td>
                                            <th>당도,산도 등</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.de_cont2}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.de_cont3}</span></div>
                                            </td>
                                            <th>경매사메모</th>
                                            <td>
                                                <div className="over_txt"><span>{proData.auctioneer_memo}</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="bid_box tp20">
                                <div className="box">
                                    <div className="flex_between">
                                        <h5 className="f_16 bold bp10">{invoiceData.ip_s_kind == '입찰' ? '입찰' : '응찰'}관리</h5>
                                        <div className="flex">
                                            <p className="f_14 rm10">{proData.de_complete_close_dt}</p>
                                            <p className="blue_status">
                                                {proData.de_status && enum_de_status[proData.de_status.trim()][0]}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="border_box">
                                        <div className="custom_table5">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'18%'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'40%'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>번호</th>
                                                        <th>응찰시간</th>
                                                        <th>응찰단가</th>
                                                        <th>구분</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bidSuccess ?
                                                        <tr className="tr_green">
                                                            <td>{bidSuccess.asso_code}</td>
                                                            <td>{bidSuccess.reg_dt && bidSuccess.reg_dt.substring(11)}</td>
                                                            <td className="tx_r">{bidSuccess.price && CF.MakeIntComma(bidSuccess.price)}</td>
                                                            <td>{bidSuccess.memo_status && enum_memo_status[bidSuccess.memo_status.trim()][0]}</td>
                                                        </tr>
                                                        :<tr><td colSpan="4">응찰자가 없습니다.</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {proData.memo && proData.memo.length > 0 && <>
                                        <h5 className="f_16 bold bp10 tm10">경매사 처리결과</h5>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <tbody>
                                                    {proData.memo.map((data,i)=>{
                                                        let time = data.reg_dt = CF.objValueTrim(data.reg_dt);
                                                            time = moment(time).utc().format('YYYY-MM-DD HH:mm');
                                                        return(
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="memo_txt_box">
                                                                        <span>{time}</span>
                                                                        <span>{data.memo}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}
                                    {proData.de_status >= 14 && <>
                                        <div className="flex_between">
                                            <h5 className="f_16 bold bp10 tm10">배송정보</h5>
                                            <p className="f_14">{proData.de_deli_rece_process_dt}</p>
                                        </div>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>배송요청일자</th>
                                                        <td>{proData.de_deli_req_date}</td>
                                                        <th>수령자명</th>
                                                        <td>{proData.de_deli_rece_nm}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>배송지</th>
                                                        <td colSpan="3">
                                                            {proData.de_deli_rece_addr1 !== null ?
                                                                proData.de_deli_rece_addr1+proData.de_deli_rece_addr2+'('+proData.de_deli_rece_post+')'
                                                                : '-'
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>메모</th>
                                                        <td colSpan="3">{proData.de_deli_rece_remk !== null ? proData.de_deli_rece_remk : '-'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}
                                    {proData.de_status >= 15 && <>
                                        <div className="flex_between">
                                            <h5 className="f_16 bold bp10 tm10">운임정보{proData.de_deli_type == "O" && <span className="normal f_14"> - 자차배송</span>}</h5>
                                            <p className="f_14">{proData.de_deli_process_dt}</p>
                                        </div>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>운송비</th>
                                                        {proData.de_deli_type == "O" ? <td>-</td>
                                                            : <td className="tx_r">{proData.de_cost2}원</td>
                                                        }
                                                        <th>기사명<span className="f_12">(예금주)</span></th>
                                                        {proData.de_deli_type == "O" ? <td>-</td>
                                                            : <td>{proData.de_deli_nm}</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th>전화번호</th>
                                                        {proData.de_deli_type == "O" ? <td>-</td>
                                                            : <td>{CF.MakePhoneForm(proData.de_deli_tel)}</td>
                                                        }
                                                        <th>차량번호</th>
                                                        {proData.de_deli_type == "O" ? <td>-</td>
                                                            : <td>{proData.de_deli_car_num}</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th>운임 송금은행</th>
                                                        {proData.de_deli_type == "O" ? <td>-</td>
                                                            : <td>{proData.de_deli_bank_nm}</td>
                                                        }
                                                        <th>배송일자</th>
                                                        <td>{moment(proData.de_deli_date).format('YYYY-MM-DD')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>운임계좌</th>
                                                        {proData.de_deli_type == "O" ? <td colSpan="3">-</td>
                                                            : <td colSpan="3">{proData.de_deli_bank_num}</td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}
                                    <div className="btn_box tp10">
                                        {proData.de_status == 9 && <>
                                            <button onClick={()=>{
                                                let hope_price = CF.RemoveIntComma(proData.de_hope_pri);
                                                // 입찰자가 있을때만 낙찰처리가능
                                                if(bidSuccess){
                                                    // 희망단가보다 낮은금액의 응찰가의 낙찰처리일경우 사유작성팝업
                                                    if(bidSuccess.price < hope_price){
                                                        dispatch(reasonPop({reasonPop:true,reasonPopTxt:'낙찰처리',reasonPopbidSuccess:bidSuccess}));
                                                    }else{
                                                        dispatch(confirmPop({
                                                            confirmPopTit:'알림',
                                                            confirmPopTxt:'낙찰처리를 하시겠습니까?',
                                                            confirmPopBtn:2,
                                                        }));
                                                        setSuccessConfirm(true);
                                                    }
                                                }else{
                                                    dispatch(confirmPop({
                                                        confirmPopTit:'알림',
                                                        confirmPopTxt:'응찰자가없어 낙찰처리가 불가합니다.',
                                                        confirmPopBtn:1,
                                                    }));
                                                    setConfirm(true);
                                                }
                                            }}><span>낙찰처리</span></button>
                                            <button className="btn_no" onClick={()=>{
                                                dispatch(reasonPop({reasonPop:true,reasonPopTxt:'유찰처리'}));
                                            }}><span>유찰처리</span></button>
                                        </>}
                                        {proData.de_status == 12 || proData.de_status == 13 || proData.de_status == 17 ?
                                            <button className="btn_cancel" onClick={()=>{
                                                dispatch(reasonPop({reasonPop:true,reasonPopTxt:'결과 취소'}));
                                            }}><span>결과 취소</span></button>
                                            :null
                                        }
                                    </div>
                                </div>
                                <div className="box">
                                    <h5 className="f_16 bold bp10">{invoiceData.ip_s_kind == '입찰' ? '입찰' : '응찰'}기록</h5>
                                    <div className="border_box scroll_wrap">
                                        <div className="custom_table5">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'65px'}} />
                                                    <col style={{width:'100px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'45%'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>번호</th>
                                                        <th>응찰시간</th>
                                                        <th>응찰단가</th>
                                                        <th>구분</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bidList.length == 0 && <tr><td colSpan="4">응찰자가 없습니다.</td></tr>}
                                                    {bidList.length > 0 && bidList.map((bid,i)=>{
                                                        
                                                        // let time = bid.reg_dt.substring(11);
                                                        // let price = CF.MakeIntComma(bid.price);
                                                        // let status = enum_memo_status[bid.memo_status.trim()][0];

                                                        let l_amer = bid.l_amer;
                                                        let time = moment(bid.l_time).format("HH:mm.ss.SSS");
                                                        let gbn = bid.l_gbn;
                                                        let price = CF.MakeIntComma(bid.l_price);
                                                        let status = 99;

                                                        return(
                                                            <tr key={i}>
                                                                <td>{l_amer}</td>
                                                                <td>{time}</td>
                                                                <td className="tx_r">{price}</td>
                                                                <td>{gbn}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">물품상세</p>
                        <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <div className="cont2 flex_between tm15 bm10">
                            <p className="f_16 medium">기본정보</p>
                            {invoiceData.ip_auct_name !== null &&
                                <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                            }
                        </div>
                        <div className="custom_table gray_table">
                            <table>
                                <colgroup>
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                    <col style={{width:'23%'}} />
                                    <col style={{width:'27%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출하주</th>
                                        <td colSpan="3">{invoiceData.as_name}</td>
                                    </tr>
                                    <tr>
                                        <th>등록일시</th>
                                        <td colSpan="3">{invoiceData.ip_reg_dt}</td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td colSpan="3">{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                    </tr>
                                    <tr>
                                        <th>상태</th>
                                        <td colSpan="3">
                                            <span className="tag">{invoiceData.ip_status}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {invoiceData.ip_s_kind == '입찰' ?
                                            <th>입찰시간</th>
                                            : <th>경매시간</th>
                                        }
                                        {invoiceData.ip_auct_date_start !== null ?
                                            <td colSpan="3">{` ${invoiceData.ip_auct_date_start} ~ ${invoiceData.ip_auct_date_end}`}</td>
                                            : <td colSpan="3">-</td>
                                        }
                                    </tr>
                                    <tr>
                                        <th>매매구분</th>
                                        <td>{invoiceData.ip_s_kind}</td>
                                        <th>팰릿</th>
                                        <td className="tx_r">{invoiceData.ip_kpp}개</td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">목재<span className="f_12">(옥타곤)</span></th>
                                        <td className="tx_r">{invoiceData.ip_kcp}개</td>
                                        <th>판매구분</th>
                                        <td>{invoiceData.ip_kind}</td>
                                    </tr>
                                    <tr>
                                        <th>산지</th>
                                        <td colSpan="3">{`${invoiceData.ip_loca_name}(${invoiceData.ip_loca})`}</td>
                                    </tr>
                                    <tr>
                                        <th>낙찰 총 금액</th>
                                        <td colSpan="3" className="tx_r">{CF.MakeIntComma(total)}원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="f_16 medium tp20 lp10">운송정보</p>
                        <div className="custom_table gray_table tm10">
                            <table>
                                <colgroup>
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                    <col style={{width:'25%'}} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>총 운송비</th>
                                        <td className="tx_r">{invoiceData.ip_wo_tot}원</td>
                                        <th>총 수량</th>
                                        <td className="tx_r">{invoiceData.ip_quan_tot}개</td>
                                    </tr>
                                    <tr>
                                        <th className="rp0">배송가능기간</th>
                                        <td colSpan="3">{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="f_16 medium tp20 lp10">물품목록</p>
                        <div className="tm10">
                            <div className="border_box scroll_wrap over_x h_160" ref={proListBox}>
                                <div className="w_900px">
                                    <div className="custom_table4">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'70px'}} />
                                                <col style={{width:'8%'}} />
                                                <col style={{width:'13%'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'7%'}} />
                                                <col style={{width:'auto'}} />
                                                <col style={{width:'8%'}} />
                                                <col style={{width:'8%'}} />
                                                <col style={{width:'6%'}} />
                                                <col style={{width:'6%'}} />
                                                <col style={{width:'11%'}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>경매순서</th>
                                                    <th>번호</th>
                                                    <th>상태</th>
                                                    <th>생산자</th>
                                                    <th>품목</th>
                                                    <th>품목명</th>
                                                    <th>중량</th>
                                                    <th>규격</th>
                                                    <th>등급</th>
                                                    <th>수량</th>
                                                    <th>희망단가</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proList && proList.map((product, idx)=>{
                                                    let de_status_num = product.de_status.trim();
                                                    let de_status = product.de_status.trim();
                                                        de_status = enum_de_status[de_status][0];
                                                    let de_weight = CF.MakeIntComma(product.de_weight);
                                                    let de_quantity = CF.MakeIntComma(product.de_quantity);
                                                    let de_hope_pri = CF.MakeIntComma(product.de_hope_pri);
                                                    return(
                                                        <tr key={idx} 
                                                            onClick={()=> {
                                                                setOnProduct(idx);
                                                                proListClickHandler(product);
                                                                let productData = JSON.parse(localStorage.getItem('productData'));
                                                                productData = {
                                                                    de_ip_date:product.de_ip_date,
                                                                    de_ip_no:product.de_ip_no,
                                                                    de_date_online:product.de_date_online,
                                                                    de_no_online:product.de_no_online,
                                                                    de_seq:product.de_seq,
                                                                    de_seq2:product.de_seq2,
                                                                    de_quantity:product.de_quantity
                                                                }
                                                                localStorage.setItem("productData", JSON.stringify(productData));
                                                            }}
                                                            className={onProduct === idx ? "tr_current" 
                                                                    //경매
                                                                    : invoiceData.ip_s_kind == "경매" ?
                                                                        de_status_num == 11 ? "tr_red2"
                                                                        : de_status_num > 8 ? "tr_red" 
                                                                        : "tr_blue"
                                                                    //입찰
                                                                    : invoiceData.ip_s_kind == "입찰" &&
                                                                        de_status_num == 13 || de_status_num == 17 ? "tr_red2"
                                                                        : de_status_num > 9 ? "tr_red" 
                                                                        : "tr_blue"
                                                        }
                                                        >
                                                            <td>{idx+1}</td>
                                                            <td>{product.de_no_online+product.de_seq+product.de_seq2}</td>
                                                            <td>{de_status}</td>
                                                            <td><span className="ellipsis">{product.de_farm_name}</span></td>
                                                            <td>{product.de_gods_code}</td>
                                                            <td><span className="ellipsis">{product.gm_name === null ? product.gm_stmd_name : product.gm_name}</span></td>
                                                            <td className="tx_r">{de_weight}kg</td>
                                                            <td>{product.de_packing_name}</td>
                                                            <td>{product.de_high_name}</td>
                                                            <td className="tx_r">{de_quantity}</td>
                                                            <td className="tx_r">{de_hope_pri}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <p className="f_16 medium tp20 lp10">물품상세</p>
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'23%'}} />
                                        <col style={{width:'27%'}} />
                                        <col style={{width:'23%'}} />
                                        <col style={{width:'27%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>생산자명</th>
                                            <td>{proData.de_farm_name}</td>
                                            <th>품명</th>
                                            <td>{proData.gm_name === null ? proData.gm_stmd_name : proData.gm_name}</td>
                                        </tr>
                                        <tr>
                                            <th>중량</th>
                                            <td className="tx_r">{proData.de_weight}kg</td>
                                            <th>규격</th>
                                            <td>{proData.de_packing_name}</td>
                                        </tr>
                                        <tr>
                                            <th>친환경</th>
                                            <td>{proData.de_quality_name}</td>
                                            <th>등급</th>
                                            <td>{proData.de_high_name}</td>
                                        </tr>
                                        <tr>
                                            <th>수량</th>
                                            <td className="tx_r">{proData.de_quantity}개</td>
                                            <th>운임비</th>
                                            <td className="tx_r">{proData.de_cost2}원</td>
                                        </tr>
                                        <tr>
                                            <th>단수</th>
                                            <td className="tx_r">{proData.de_dansu}개</td>
                                            <th className="sky_bg">희망단가</th>
                                            <td className="tx_r sky_bg">{proData.de_hope_pri}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="custom_table gray_table tm10">
                                <table>
                                    <colgroup>
                                        <col style={{width:'25%'}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>품질</th>
                                            <td>{proData.de_cont1}</td>
                                        </tr>
                                        <tr>
                                            <th>당도,산도 등</th>
                                            <td>{proData.de_cont2}</td>
                                        </tr>
                                        <tr>
                                            <th>특이사항</th>
                                            <td>{proData.de_cont3}</td>
                                        </tr>
                                        <tr>
                                            <th>경매사메모</th>
                                            <td>{proData.memo && proData.memo.memo !== undefined && proData.memo.memo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="bid_box">
                                <div className="flex_between lp10 rp10 tp20">
                                    <h5 className="f_16 medium">입찰관리</h5>
                                    <div className="flex">
                                        <p className="rm10">{proData.de_complete_close_dt}</p>
                                        <p className="blue_status">
                                            {proData.de_status && enum_de_status[proData.de_status.trim()][0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="custom_table5 tm10">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'18%'}} />
                                            <col style={{width:'90px'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'35%'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>번호</th>
                                                <th>응찰시간</th>
                                                <th>응찰단가</th>
                                                <th>구분</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bidSuccess ?
                                                <tr className="tr_green">
                                                    <td>{bidSuccess.asso_code}</td>
                                                    <td>{bidSuccess.reg_dt && bidSuccess.reg_dt.substring(11)}</td>
                                                    <td className="tx_r">{bidSuccess.price && CF.MakeIntComma(bidSuccess.price)}</td>
                                                    <td>{bidSuccess.memo_status && enum_memo_status[bidSuccess.memo_status.trim()][0]}</td>
                                                </tr>
                                                :<tr><td colSpan="4">응찰자가 없습니다.</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="btn_box cont2 tm10">
                                    {proData.de_status == 9 && <>
                                        <button onClick={()=>{
                                            let hope_price = CF.RemoveIntComma(proData.de_hope_pri);
                                            // 입찰자가 있을때만 낙찰처리가능
                                            if(bidSuccess){
                                                // 희망단가보다 낮은금액의 응찰가의 낙찰처리일경우 사유작성팝업
                                                if(bidSuccess.price < hope_price){
                                                    dispatch(reasonPop({reasonPop:true,reasonPopTxt:'낙찰처리',reasonPopbidSuccess:bidSuccess}));
                                                }else{
                                                    dispatch(confirmPop({
                                                        confirmPopTit:'알림',
                                                        confirmPopTxt:'낙찰처리를 하시겠습니까?',
                                                        confirmPopBtn:2,
                                                    }));
                                                    setSuccessConfirm(true);
                                                }
                                            }else{
                                                dispatch(confirmPop({
                                                    confirmPopTit:'알림',
                                                    confirmPopTxt:'응찰자가없어 낙찰처리가 불가합니다.',
                                                    confirmPopBtn:1,
                                                }));
                                                setConfirm(true);
                                            }
                                        }}><span>낙찰처리</span></button>
                                        <button className="btn_no" onClick={()=>{
                                            dispatch(reasonPop({reasonPop:true,reasonPopTxt:'유찰처리'}));
                                        }}><span>유찰처리</span></button>
                                    </>}
                                    {proData.de_status == 12 || proData.de_status == 13 || proData.de_status == 17 ?
                                        <button className="btn_cancel w_100" onClick={()=>{
                                            dispatch(reasonPop({reasonPop:true,reasonPopTxt:'결과 취소'}));
                                        }}><span>결과 취소</span></button>
                                        :null
                                    }
                                </div>
                                {proData.de_status >= 14 && <>
                                    <h5 className="f_16 medium tp20 lp10">배송정보</h5>
                                    <div className="custom_table gray_table tm10">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'100px'}} />
                                                <col style={{width:'auto'}} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>배송요청일자</th>
                                                    <td>{proData.de_deli_req_date}</td>
                                                </tr>
                                                <tr>
                                                    <th>수령자명</th>
                                                    <td>{proData.de_deli_rece_nm}</td>
                                                </tr>
                                                <tr>
                                                    <th>배송지</th>
                                                    <td>
                                                        {proData.de_deli_rece_addr1 !== null ?
                                                            proData.de_deli_rece_addr1+proData.de_deli_rece_addr2+'('+proData.de_deli_rece_post+')'
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>메모</th>
                                                    <td>{proData.de_deli_rece_remk !== null ? proData.de_deli_rece_remk : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}
                                {proData.memo && proData.memo.length > 0 && <>
                                    <h5 className="f_16 medium tp20 lp10">경매사 처리결과</h5>
                                    <div className="custom_table gray_table tm10">
                                        <table>
                                            <tbody>
                                                {proData.memo.map((data,i)=>{
                                                    let time = data.reg_dt = CF.objValueTrim(data.reg_dt);
                                                        time = moment(time).utc().format('YYYY-MM-DD HH:mm');
                                                    return(
                                                        <tr key={i}>
                                                            <td>
                                                                <div className="memo_txt_box">
                                                                    <span>{time}</span>
                                                                    <span>{data.memo}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>}
                                {proData.de_status >= 15 && <>
                                    <h5 className="f_16 medium tp20 lp10">운임정보{proData.de_deli_type == "O" && <span className="normal f_14"> - 자차배송</span>}</h5>
                                    <div className="custom_table gray_table tm10">
                                        <table>
                                            <colgroup>
                                                <col style={{width:'100px'}} />
                                                <col style={{width:'auto'}} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>운송비</th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td className="tx_r">{proData.de_cost2}원</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>기사명<span className="f_12">(예금주)</span></th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td>{proData.de_deli_nm}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>전화번호</th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td>{CF.MakePhoneForm(proData.de_deli_tel)}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>차량번호</th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td>{proData.de_deli_car_num}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>운임 송금은행</th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td>{proData.de_deli_bank_nm}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>운임계좌</th>
                                                    {proData.de_deli_type == "O" ? <td>-</td>
                                                        : <td>{proData.de_deli_bank_num}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>배송일자</th>
                                                    <td>{moment(proData.de_deli_date).format('YYYY-MM-DD')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}
                                <h5 className="f_16 medium tp20 lp10">입찰기록</h5>
                                <div className="custom_table5 tm10 bm10">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'65px'}} />
                                            <col style={{width:'90px'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'35%'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>번호</th>
                                                <th>응찰시간</th>
                                                <th>응찰단가</th>
                                                <th>구분</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bidList.length > 0 && bidList.map((bid,i)=>{
                                                let l_amer = bid.l_amer;
                                                let time = moment(bid.l_time).format("HH:mm.ss.SSS");
                                                let gbn = bid.l_gbn;
                                                let price = CF.MakeIntComma(bid.l_price);

                                                return(
                                                    <tr key={i}>
                                                        <td>{l_amer}</td>
                                                        <td>{time}</td>
                                                        <td className="tx_r">{price}</td>
                                                        <td>{gbn}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 낙찰처리버튼 클릭시 confirm팝업 */}
        {SuccessConfirm && <ConfirmPop onClickHandler={bidSuccessHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>)
};

export default ResultDetailPop;