import { Link, NavLink, useNavigate  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, menuToggle } from "../../../store/userSlice";
import { dealHistoryPop } from "../../../store/popupSlice";
import util from "../../../config/util";
import ic_register from "../../../images/ic_register.svg";
import ic_auction from "../../../images/ic_auction.svg";
import shipper_manual from "../../../images/shipper_manual.pdf";
import merchant_manual from "../../../images/merchant_manual.pdf";
import auctioneer_manual from "../../../images/auctioneer_manual.pdf";

const LeftCont = (props) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 토글버튼
    let toggle = localStorage.getItem("menuToggle");
    const toggleHandler = () => {
        if(toggle === "true"){
            localStorage.setItem("menuToggle","false");
            props.setContFold("false");
        }else{
            localStorage.setItem("menuToggle","true");
            props.setContFold("true");
        }
    };


    // 로그아웃
    const logoutHandler = (e) => {
        e.preventDefault();
        dispatch(clearUser());
        util.setCookie("login",user.id,-1);
        navigate("/");

        localStorage.removeItem('token');
        localStorage.removeItem('auctCode');
        localStorage.removeItem('auctStartData');
        localStorage.removeItem('subList');
        localStorage.removeItem('auctProData');
        localStorage.removeItem('invoiceData');
        localStorage.removeItem('productData');
        localStorage.removeItem('autoCheck');
        localStorage.removeItem('assoCode');
        localStorage.removeItem('subDetail');
        localStorage.removeItem('menuToggle');
        localStorage.removeItem('clientList');
    };

    return (
        <div className="left_cont">
            <button className="btn_toggle" onClick={toggleHandler}>
                토글버튼
            </button>
            <div className="user_box">
                {toggle === "false" && (
                    <>
                        <div className="top_box">
                            <p className="f_14 txt_gray">사용자</p>
                            <div className="flex">
                                <p className="f_16 medium">{user.name}<span className="tag3">사용자인증</span></p>
                            </div>
                        </div>
                        <p className="f_14 txt_gray">로그인 일시</p>
                        <p className="f_16">{user.lastDate}</p>
                    </>
                )}
                <button type="button" className="btn_type3 w_100 tm20 bm10" onClick={(e) => {
                    e.preventDefault();
                    dispatch(dealHistoryPop());
                }}>거래내역</button>
                <button type="button" className="btn_type2 w_100" onClick={logoutHandler}>로그아웃</button>
            </div>
            <div className="register_box">
                {user.kind === 1 && (<Link to="/shipper/invoice-register">
                    <p className="txt">송품장 등록</p>
                    {toggle === "false" && (
                        <p className="txt2">
                            송품장을 작성하여 <br />
                            출하상품을 등록하세요.
                        </p>
                    )}
                    <img src={ic_register} alt="아이콘" />
                </Link>)}
                {user.kind === 3 && (<Link to="/merchant/auction" className="auction">
                    <p className="txt">온라인 경매</p>
                    {toggle === "false" && (
                        <p className="txt2">
                            빠르고 편리한 <br />
                            온라인경매를 진행 가능합니다.</p>
                    )}
                    <img src={ic_auction} alt="아이콘" />
                </Link>)}
            </div>
            <ul className="link_box">
                {user.kind === 1 && (
                    <>
                        <li>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "on" : ""
                                }
                                to="/shipper/invoice-check"
                            >
                                {toggle === "false" && <span>송품장 조회</span>}
                                {toggle === "true" && (
                                    <span>
                                        송품장 <br />
                                        조회
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li className="link2">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "on" : ""
                                }
                                to="/shipper/online-check"
                            >
                                {toggle === "false" && (
                                    <span>온라인거래결과 조회</span>
                                )}
                                {toggle === "true" && (
                                    <span>
                                        온라인
                                        <br />
                                        경매결과 조회
                                    </span>
                                )}
                            </NavLink>
                        </li>
                    </>
                )}
                {user.kind === 3 && (
                    <>
                        <li>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "on" : ""
                                }
                                to="/merchant/shipment-products"
                            >
                                {toggle === "false" && <span>출하물품 조회</span>}
                                {toggle === "true" && (
                                    <span>
                                        출하물품 <br />
                                        조회
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li className="link3">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "on" : ""
                                }
                                to="/merchant/online-bid"
                            >
                                {toggle === "false" && (
                                    <span>온라인입찰</span>
                                )}
                                {toggle === "true" && (
                                    <span>
                                        온라인
                                        <br />
                                        입찰
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li className="link2">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "on" : ""
                                }
                                to="/merchant/bid-products"
                            >
                                {toggle === "false" && (
                                    <span>낙찰물품 조회</span>
                                )}
                                {toggle === "true" && (
                                    <span>
                                        낙찰물품
                                        <br />
                                        조회
                                    </span>
                                )}
                            </NavLink>
                        </li>
                    </>
                )}
            </ul>
            <div className="down_box">
                {toggle === "false" && <p>설명서 다운로드</p>}
                {toggle === "true" && (
                    <p>
                        설명서 <br />
                        다운로드
                    </p>
                )}
                {user.kind === 1 &&
                    <a href={shipper_manual} download className="btn_down">
                        출하주{toggle === "false" && " 설명서"}
                    </a>
                }
                {user.kind === 7 &&
                    <a href={auctioneer_manual} download className="btn_down">
                        경매사{toggle === "false" && " 설명서"}
                    </a>
                }
                {user.kind === 3 &&
                    <a href={merchant_manual} download className="btn_down">
                        중도매인{toggle === "false" && " 설명서"}
                    </a>
                }
            </div>
        </div>
    );
};

export default LeftCont;
