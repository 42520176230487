import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { enum_api_uri } from "../../../../config/enum";
import * as CF from "../Function";
import ConfirmPop from "./ConfirmPop";
import { confirmPop, messageSetAddPop, messageSetPop } from "../../../../store/popupSlice";
import Pagination from "../Pagination";

const MessageSetPop = () => {
    const a_message_list = enum_api_uri.a_message_list;
    const a_message_setting = enum_api_uri.a_message_setting;
    const token = localStorage.getItem("token");
    const user = useSelector((state)=>state.user);
    const popup = useSelector((state)=>state.popup);
    const dispatch = useDispatch();
    const [messageList, setMessageList] = useState([]);
    const [messageText, setMessageText] = useState([]);
    const [msgListIdx, setMsgListIdx] = useState(null);
    const [msgIdx, setMsgIdx] = useState(null);
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [editConfirm, setEditConfirm] = useState(false);
    const [deltConfirm, setDeltConfirm] = useState(false);

    //팝업닫기
    const closePopHandler = () => {
        dispatch(messageSetPop(null));
    }

    useEffect(()=>{
        setMessageList(messageList);
    },[messageList]);

    useEffect(()=>{
        setMessageText(messageText);
    },[messageText]);

    useEffect(()=>{
        setMsgListIdx(msgListIdx);
    },[msgListIdx]);

    useEffect(()=>{
        setMsgIdx(msgIdx);
    },[msgIdx]);

    useEffect(()=>{
        let val = [];
        val.page_no_now = 1;
        setPageNo(1);
        getList(val); //리스트 받아오기
    },[popup.messageSetAddPop]);

    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        console.log(goPage)
        let val = [];
        val.page_no_now = goPage; //움직일 페이지
        setPageNo(goPage);
        getList(val); //리스트 받아오기
    };

    //주요메시지 전체조회
    const getList = (val) => {
        //리스팅 날짜 정보를 저장한다.
        val.record_size = perPage;

        axios.get(`${a_message_list.replace(':asso_code', user.assoCode)}?page_no=${val.page_no_now}&record_size=${val.record_size}&message_type=`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                let state = [...data.message];
                let resCount = data.total_record_count;
                setMessageList(state);//리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤
                let arr = [];
                state.map(cont => {
                    arr.push(cont['content']);
                    setMessageText(arr);
                })
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //주요메시지 수정
    const textChangeHandler = () => {
        let body = {
            messageIdx:msgIdx,
            content:messageText[msgListIdx]
        };
        axios.put(`${a_message_setting.replace(':asso_code', user.assoCode).replace(':messageIdx', msgIdx)}`, body, {
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'적용되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //주요메시지 삭제
    const textDeltHandler = () => {
        axios.delete(`${a_message_setting.replace(':asso_code', user.assoCode).replace(':messageIdx', msgIdx)}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res)=>{
            if(res.status === 200){
                const newMsgList = messageList.filter(msg => msg.messageIdx !== msgIdx);
                setMessageList(newMsgList);
                let arr = [];
                newMsgList.map(cont => {
                    arr.push(cont['content']);
                    setMessageText(arr);
                });

                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'삭제되었습니다.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };


    return(<>
        <div className="pop_wrap">
            <div className="dim" onClick={closePopHandler}></div>
            <div className="pop_cont pop_cont3">
                <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                <div className="pop_tit">
                    <p className="tit">주요메시지 설정</p>
                </div>
                <div className="scroll_wrap">
                    <div className="custom_table s_table">
                        <table>
                            <colgroup>
                                <col style={{width:'8%'}} />
                                <col style={{width:'100px'}} />
                                <col style={{width:'auto'}} />
                                <col style={{width:'75px'}} />
                                <col style={{width:'75px'}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>구분</th>
                                    <th>품종</th>
                                    <th>적용</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messageList && messageList.length > 0 ? 
                                    messageList.map((msg,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{msg.message_type === 'P' ? '개인메시지' : '전체메시지'}</td>
                                                <td>
                                                    <div className="custom_input h_30">
                                                        <input type="text" value={messageText[i]} 
                                                            onChange={(e)=>{
                                                                setMessageText({
                                                                    ...messageText,
                                                                    [i]:e.currentTarget.value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <button className="btn_s2" onClick={()=>{
                                                        setMsgListIdx(i);
                                                        setMsgIdx(msg.messageIdx);
                                                        dispatch(confirmPop({
                                                            confirmPopTit:'알림',
                                                            confirmPopTxt:'메시지를 적용하시겠습니까?',
                                                            confirmPopBtn:2,
                                                        }));
                                                        setEditConfirm(true);
                                                    }}>적용</button>
                                                </td>
                                                <td>
                                                    <button className="btn_s3" onClick={()=>{
                                                        setMsgIdx(msg.messageIdx);
                                                        dispatch(confirmPop({
                                                            confirmPopTit:'알림',
                                                            confirmPopTxt:'메시지를 삭제하시겠습니까?',
                                                            confirmPopBtn:2,
                                                        }));
                                                        setDeltConfirm(true);
                                                    }} >삭제</button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <tr><td colSpan={5}>등록된 메시지가없습니다.</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex_end tp10">
                    <button className="btn_type" onClick={()=>{dispatch(messageSetAddPop(true))}}>등록</button>
                </div>
                {/* 조회가 없을때 ▼ */}
                {viewPaging === 0 && (
                    <div className="pagination">
                        <button className="prev">
                            <span>이전 페이지</span>
                        </button>
                        <ul className="num_box">
                            <li className="on">
                                <button>1</button>
                            </li>
                        </ul>
                        <button className="next">
                            <span>다음 페이지</span>
                        </button>
                    </div>
                )}
                {/* 조회가 페이징보다 클때 ▼ */}
                {viewPaging > 0 &&
                    <Pagination 
                        nowPage={page_no}
                        totalCnt={viewPaging}
                        setPerPage={perPage}
                        moveToPage={moveToPage}
                    />
                }
            </div>
        </div>

        {/* 메시지 수정 confirm팝업 */}
        {editConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setEditConfirm(false)}} onClickHandler={textChangeHandler} />}

        {/* 메시지 삭제 confirm팝업 */}
        {deltConfirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setDeltConfirm(false)}} onClickHandler={textDeltHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}}/>} 
    </>);
};

export default MessageSetPop;