
const Paging = (props) => {
    // console.log(props)
    let nowPage = props.nowPage; //현재페이지
    let totalCnt = props.totalCnt; //전체 갯수    
    const listSize = props.setPerPage; //전체 갯수; //한 페이지에 보이는 목록갯수
    const scale = 10;   //페이징라인에 보여지는 숫자 최대 갯수
    let start_page = 0; //페이징 시작 번호
    let offset = (nowPage - 1) * listSize;//해당 페이지에서 시작하는 목록번호
    let block =  Math.floor((nowPage-1) / scale); //페이지를 10개씩보여준다면 1~10페이지까지는 0블럭..
    let no = totalCnt - offset; //목록에서 번호나열할때 필요.. (하단 사용법을보세요..)

    let page_max =  Math.ceil(totalCnt / listSize);  //총 페이지개수
    let lops = [];
    let nPage = 0;
    let prev = '';
    let next = '';


    //왼쪽 화살표 소스
    if(block > 0) { 
        let prev_block = (block - 1) * scale + 1;
        prev = <button  type = "button" className="prev" onClick = {()=>props.moveToPage(prev_block)}><span>이전 페이지</span></button>
    }
    else prev = <button  type = "button" className="prev" ><span>이전 페이지</span></button>

    //오른쪽 화살표 소스
    if(page_max > (block + 1) * scale) {
        let next_block = (block + 1) * scale + 1;
        next = <button  type = "button" className="next" onClick = {()=>props.moveToPage(next_block)}><span>이전 페이지</span></button>
    }
    else next = <button  type = "button" className="next"><span>이전 페이지</span></button>


    //메인 루프 소스
    start_page = block * scale + 1;
    for(var i = 1; i <= scale && start_page <=page_max; i++, start_page++) {
        nPage = start_page;
        if(start_page == nowPage)
        lops.push({index:i,startPage : start_page, className : 'on'})
        else
        lops.push({index:i,startPage : start_page, className : ''})
        // console.log(lops)
    }
    const pageList = lops.map((lop) => <li className={lop.className} key = {lop.index}><button type = "button" onClick = {()=>props.moveToPage(lop.startPage)}>{lop.startPage}</button></li>);
    

    //결론
    return(     
        <><div className="pagination">{prev}<ul className="num_box">{pageList}</ul>{next}</div></>        
    );
};

export default Paging;