import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { enum_api_uri,enum_mo_title,enum_is_status,enum_de_status } from "../../../config/enum";
import Pagination from "../../../components/pc/component/Pagination";
import * as CF from "../../../components/pc/component/Function";
import { resultDetailPop } from "../../../store/popupSlice";
import { detailPageBack } from "../../../store/commonSlice";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import InputDatepicker from "../../../components/pc/component/InputDatepicker";

const OnlineAuction = () => {
    const token = localStorage.getItem("token");
    const common = useSelector((state)=>state.common);
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [dateError, setDateError] = useState(false);
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const [proList, setProList] = useState([]);
    const basicDate = new Date();
    const m_list_auction = enum_api_uri.m_list_auction;
    const [mine, setMine] = useState(false);
    registerLocale('ko', ko);
    const dispatch = useDispatch();
    const [scrollMove, setScrollMove] = useState(false);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);
        
        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(()=>{
        setProList(proList);
        console.log(proList);
    },[proList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
        console.log(viewPaging);
    },[viewPaging]);

    useEffect(()=>{ //처음 한번 리스트 불러오기a1

        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);

    //경매목록 조회 버튼 클릭시
    const submit = async (values) => {
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');

        let val = [];
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };
    
    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };
    

    //리스트 받아오기(현재 페이지 공통)
    const getList = async(val) =>{
        let my_auction = '';

        if(mine == true){
            my_auction = true;
        }

        axios.get(`${m_list_auction}?ip_auct_date_start=${val.ip_date_start}&ip_auct_date_end=${val.ip_date_end}&page_no=${val.page_no_now}&record_size=10&my_auction=${my_auction}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            console.log(res.data);
            if(res.status === 200){
                const data = res.data
                let state = [...data.results];
                let resCount = data.total_record_count;
                // console.log(data.total_record_count)
                setProList(state); //리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })

    }

    const resultData = {
        head: ["등록번호","출하주","생산자","품목명","중량","규격","친환경","등급","수량","상태"],
    };

    const setMineCheck = (e) => {
        if(e.target.checked){
            setMine(true);
        }else{
            setMine(false);
        }
    };

    return(
        <div className="page_sub">
            <div className="auction_wrap auction_wrap2 bp30">
                <div className="cont">
                    <Formik
                        initialValues={{
                            // ip_date_start: basicDate || "",
                            // ip_date_end: basicDate || "",
                        }}
                        onSubmit={submit}
                    >
                        {({values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="top_cont flex_between">
                                    <p className="tit">경매목록</p>
                                    <ul className="flex inner_ul2 inner_ul3">
                                        <li className="flex rm10">
                                            <div className="date_input2 flex_between rm5">
                                                <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                                <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                            </div>
                                            <button className="btn_s" type="submit">조회</button>
                                        </li>
                                        <li>
                                            <div className="custom_check">
                                                <label htmlFor="mineCheck">
                                                    <input type="checkbox" id="mineCheck" onChange={e => setMineCheck(e)} checked={mine} />
                                                    <span className="check">체크</span>
                                                    <span className="txt">내 자료보기</span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <div className="tp20">
                        <p className="medium bp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                        {/* 등록물품 없을때 ▼ */}
                        {proList.length == 0 && <div className="none_data"><span>데이터가 없습니다.</span></div>}
                        
                        {/* 등록물품 있을때 ▼ */}
                        {proList.length > 0 && proList.map((listVals,idx) => {
                            let auctStartTime = CF.MDFWithHour(listVals.auct_dt,2);
                            let statusName = enum_is_status[listVals.auct_status.trim()][0];
                            listVals.auct_status = listVals.auct_status.trim();
                            let linkUrl = '/auctioneer/online-auction/';
                            let linkCode = listVals.auct_code + '/' + listVals.auct_dt;

                            return (
                            <div  key = {idx}>
                                <div className="flex_between bp10">
                                    <div className="flex">
                                        <span className="tag">경매사 : {listVals.is_name}</span>
                                        <p className="f_15 bold lm7">경매시간 : {auctStartTime}</p>
                                    </div>
                                    <div className="flex">
                                        <p className="f_17 bold rm10">{statusName}</p>
                                        {listVals.auct_status < 5 ?
                                            <Link to={`${linkUrl}${linkCode}`} className="btn_s" onClick={()=>{
                                                //리스트페이지 현재스크롤위치 저장
                                                CF.setScrollY(window.scrollY);
                                            }}>입장하기</Link>
                                            :<Link to={`${linkUrl}${linkCode}`} className="btn_s2" onClick={()=>{
                                                //리스트페이지 현재스크롤위치 저장
                                                CF.setScrollY(window.scrollY);
                                            }}>내역보기</Link>
                                        }
                                    </div>
                                </div>
                                <div className="custom_table s_table bp20">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'120px'}} />
                                            <col style={{width:'160px'}} />
                                            <col style={{width:'100px'}} />
                                            <col style={{width:'auto'}} />
                                            <col style={{width:'70px'}} />
                                            <col style={{width:'100px'}} />
                                            <col style={{width:'130px'}} />
                                            <col style={{width:'130px'}} />
                                            <col style={{width:'70px'}} />
                                            <col style={{width:'100px'}} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                {resultData.head.map(data => {
                                                    return <th key={data.toString()}>{data}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listVals.detltrns.map((subVals,ids) => {
                                                let subCode = subVals.de_date_online+'-'+subVals.de_no_online
                                                let auct_status = enum_de_status[subVals.de_status.trim()][0];
                                                return(
                                                    <tr  key = {ids}>
                                                        <td>{subCode}</td>
                                                        <td><div className="over_txt"><span>{subVals.as_name}</span></div></td>
                                                        <td><div className="over_txt"><span>{subVals.de_farm_name}</span></div></td>
                                                        <td><div className="over_txt"><span>{subVals.gm_name}</span></div></td>
                                                        <td className="tx_r">{CF.MakeIntComma(subVals.de_weight)}kg</td>
                                                        <td>{subVals.de_packing_name}</td>
                                                        <td>{subVals.de_quality_name}</td>
                                                        <td>{subVals.de_high_name}</td>
                                                        <td className="tx_r">{CF.MakeIntComma(subVals.de_quantity)}개</td>
                                                        <td>{auct_status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    {/* 조회가 없을때 ▼ */}
                    {viewPaging == 0 && (
                        <div className="pagination">
                            <button className="prev">
                                <span>이전 페이지</span>
                            </button>
                            <ul className="num_box">
                                <li className="on">
                                    <button>1</button>
                                </li>
                            </ul>
                            <button className="next">
                                <span>다음 페이지</span>
                            </button>
                        </div>
                    )}
                    {/* 조회가 페이징보다 클때 ▼ */}
                    {viewPaging > 0 &&
                        <Pagination 
                            nowPage={page_no}
                            totalCnt={viewPaging}
                            setPerPage={perPage}
                            moveToPage={moveToPage}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default OnlineAuction;