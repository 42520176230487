import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import LeftCont from "../../../components/pc/component/LeftCont";
import ProductInfo from "../../../components/pc/component/ProductInfo";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { enum_api_uri, enum_sell_kind, enum_from_kind, enum_ip_status, enum_mo_title, enum_de_status } from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import { confirmPop, closePop, deliveryPop, deliverySelect, deliveryPopNum } from "../../../store/popupSlice";
import { NumericFormat, PatternFormat, NumberFormatBase } from 'react-number-format';
import history from "../../../config/history";

const OnlineCheckDetail = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const { invoiceId } = useParams();
    const ipDate = invoiceId.substring(0, 8);
    const ipNoOnline = invoiceId.substring(9, 14);
    const p_list_product2 = enum_api_uri.p_list_product2;
    const p_product_delivery = enum_api_uri.p_product_delivery;
    const m_bank = enum_api_uri.m_bank;
    const token = localStorage.getItem("token");
    const popup = useSelector((state) => state.popup);
    const [invoiceData, setInvoiceData] = useState({});
    const [proList, setProList] = useState([]);
    const [txtQuantity, setTxtQuantity] = useState();
    const [txtPrice, setTxtPrice] = useState();
    registerLocale('ko', ko);
    const dispatch = useDispatch();
    const location = useLocation();
    const [deliConfirm,setDeliConfirm] = useState(false);
    const [confirm,setConfirm] = useState(false);
    const [okConfirm,setOkConfirm] = useState(false);
    const [deliInfo, setDeliInfo] = useState({});
    const [errorCost, setErrorCost] = useState([]);
    const [errorName, setErrorName] = useState([]);
    const [errorTel, setErrorTel] = useState([]);
    const [errorCar, setErrorCar] = useState([]);
    const [errorBank, setErrorBank] = useState([]);
    const [errorBank2, setErrorBank2] = useState([]);
    const [errorDate, setErrorDate] = useState([]);
    const [deliStartDate, setDeliStartDate] = useState();
    const [deliEndDate, setDeliEndDate] = useState();
    const [hphone, setHphone] = useState("");
    const [bankList, setBankList] = useState([]);
    const [myDeli, setMyDeli] = useState([]);
    let sumPrice = 0;
    let sumQuantity = 0;

    //상세페이지 뒤로가기
    useEffect(() => {

        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setDeliConfirm(false);
            setConfirm(false);
            setOkConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setMyDeli(myDeli);
        console.log(myDeli);
    },[myDeli]);

    useEffect(()=>{
        setBankList(bankList);
    },[bankList]);

    useEffect(()=>{
        setErrorCost(errorCost);
    },[errorCost]);

    useEffect(()=>{
        setErrorName(errorName);
    },[errorName]);

    useEffect(()=>{
        setErrorTel(errorTel);
    },[errorTel]);

    useEffect(()=>{
        setErrorCar(errorCar);
    },[errorCar]);

    useEffect(()=>{
        setErrorBank(errorBank);
    },[errorBank]);

    useEffect(()=>{
        setErrorBank2(errorBank2);
    },[errorBank2]);

    useEffect(()=>{
        setErrorDate(errorDate);
    },[errorDate]);

    useEffect(()=>{
        setInvoiceData(invoiceData);
    },[invoiceData]);

    useEffect(()=>{
        setProList(proList);
        console.log(proList);
    },[proList])

    useEffect(()=>{
        setTxtPrice(txtPrice);
    },[txtPrice]);

    useEffect(()=>{
        setDeliInfo(deliInfo);
        console.log(deliInfo);
    },[deliInfo]);

    useEffect(() => {
        setHphone(hphone);
    },[hphone]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf('/');
            let tit = path.substring(0, idx);
                tit = enum_mo_title[tit];
            dispatch(moTitle(tit));
        }
    },[]);

    
    useEffect(() => {
        // 송품장,물품정보 가져오기
        axios.get(`${p_list_product2.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline)}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                res.data.ip_reg_dt = moment(res.data.ip_reg_dt).utc().format('YYYY년 MM월 DD일 HH시 mm분 ss초');
                // //시작일시&종료일시
                if(res.data.ip_auct_date_start !== null){
                    res.data.ip_auct_date_start = res.data.ip_auct_date_start.trim();
                    if(res.data.ip_auct_date_start !=""){
                        res.data.ip_auct_date_start  = CF.MDFWithHour(res.data.ip_auct_date_start)
                    }
                }
                if(res.data.ip_auct_date_end !== null){
                    res.data.ip_auct_date_end = res.data.ip_auct_date_end.trim();
                    if(res.data.ip_auct_date_end !=""){
                        res.data.ip_auct_date_end  = CF.MDFWithHour(res.data.ip_auct_date_end)
                    }
                }
                let ip_status = res.data.ip_status.trim();
                res.data.ip_status = enum_ip_status[ip_status][0];
                let ip_s_kind = res.data.ip_s_kind.trim();
                res.data.ip_s_kind = enum_sell_kind[ip_s_kind];
                let ip_kind = res.data.ip_kind.trim();
                res.data.ip_kind = enum_from_kind[ip_kind];
                if(!res.data.ip_kpp === null){
                    if(res.data.ip_kpp.trim().length > 0){
                        res.data.ip_kpp = CF.MakeIntComma(res.data.ip_kpp);
                    }
                }
                if(!res.data.ip_kcp === null){
                    if(res.data.ip_kcp.trim().length > 0){
                        res.data.ip_kcp = CF.MakeIntComma(res.data.ip_kcp);
                    }
                }
                if(!res.data.ip_wo_tot === null){
                    if(res.data.ip_wo_tot.trim().length > 0){
                        res.data.ip_wo_tot = CF.MakeIntComma(res.data.ip_wo_tot);
                    }
                }
                if(!res.data.ip_quan_tot === null){
                    if(res.data.ip_quan_tot.trim().length > 0){
                        res.data.ip_quan_tot = CF.MakeIntComma(res.data.ip_quan_tot);
                    }
                }
                res.data.ip_delivery_date_start  = moment(res.data.ip_delivery_date_start.trim()).format('YYYY-MM-DD');
                res.data.ip_delivery_date_end  = moment(res.data.ip_delivery_date_end.trim()).format('YYYY-MM-DD');

                //배송일자
                let oriReqDate = res.data.ip_delivery_date_start;
                let oriReqDate2 = res.data.ip_delivery_date_end;
                if(oriReqDate != null){
                    let plk = moment(oriReqDate).toISOString();
                    res.data.detltrns.newReqDate  = new Date(plk);
                    setDeliStartDate(res.data.detltrns.newReqDate);
                    let plk2 = moment(oriReqDate2).toISOString();
                        plk2  = new Date(plk2);
                    setDeliEndDate(plk2);
                }

                // 총 판매금액 
                res.data.total_sale_amount = CF.MakeIntComma(res.data.total_sale_amount);

                setInvoiceData(res.data);
                let list = [...res.data.detltrns];
                setProList(list);

                //운임정보등록시 에러문구
                let error = res.data.detltrns.map(()=>{
                    return false;
                });
                setErrorCost(error);
                setErrorName(error);
                setErrorTel(error);
                setErrorCar(error);
                setErrorBank(error);
                setErrorBank2(error);
                setErrorDate(error);

                let newMyDeli = res.data.detltrns.map(()=>{
                    return false;
                });
                setMyDeli(newMyDeli);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        // 은행목록 가져오기
        axios.get(`${m_bank}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
            if (res.status === 200) {
                setBankList(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    //운임정보 등록
    const registerClickHandler = (values,idx,product) => {
        // 자차배송 체크안했을때 운송비 ~ 운임계좌까지 필수입력
        if(!myDeli[idx]){
            if(CF.RemoveIntComma(values.de_cost2) === 0 || values.de_cost2.length === 0){
                let copy = [...errorCost];
                copy[idx] = true;
                setErrorCost(copy);
            }if(values.de_deli_nm.length === 0){
                let copy = [...errorName];
                copy[idx] = true;
                setErrorName(copy);
            }if(values.de_deli_tel.length === 0){
                let copy = [...errorTel];
                copy[idx] = true;
                setErrorTel(copy);
            }if(values.de_deli_car_num.length === 0){
                let copy = [...errorCar];
                copy[idx] = true;
                setErrorCar(copy);
            }if(values.de_deli_bank_nm.length === 0){
                let copy = [...errorBank];
                copy[idx] = true;
                setErrorBank(copy);
            }if(values.de_deli_bank_num.length === 0){
                let copy = [...errorBank2];
                copy[idx] = true;
                setErrorBank2(copy);
            }if(values.de_deli_date === "" || values.de_deli_date === null){
                let copy = [...errorDate];
                copy[idx] = true;
                setErrorDate(copy);
            }if(CF.RemoveIntComma(values.de_cost2) > 0 && 
                values.de_deli_nm.length > 0 && 
                values.de_deli_tel.length > 0 && 
                values.de_deli_car_num.length > 0 && 
                values.de_deli_bank_nm.length > 0 && 
                values.de_deli_bank_num.length > 0 &&
                values.de_deli_date !== "" && values.de_deli_date !== null){

                let copyCost = [...errorCost];
                copyCost[idx] = false;
                setErrorCost(copyCost);

                let copyName = [...errorName];
                copyName[idx] = false;
                setErrorName(copyName);

                let copyTel = [...errorTel];
                copyTel[idx] = false;
                setErrorTel(copyTel);

                let copyCar = [...errorCar];
                copyCar[idx] = false;
                setErrorCar(copyCar);

                let copyBank = [...errorBank];
                copyBank[idx] = false;
                setErrorBank(copyBank);

                let copyBank2 = [...errorBank2];
                copyBank2[idx] = false;
                setErrorBank2(copyBank2);

                let copyDate = [...errorDate];
                copyDate[idx] = false;
                setErrorDate(copyDate);

                let data = {
                    de_date_online: product.de_date_online,
                    de_no_online: product.de_no_online,
                    de_seq: product.de_seq,
                    de_seq2: product.de_seq2,
                    de_deli_type: "D",
                }
                console.log(data)
                setDeliInfo({...values,...data});
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'운임정보를 등록하시겠습니까?',
                    confirmPopBtn:2,
                }));
                setDeliConfirm(true);
            }
        }
        // 자차배송 체크했을때 배송일자만 필수입력
        if(myDeli[idx]){
            let copyCost = [...errorCost];
            copyCost[idx] = false;
            setErrorCost(copyCost);

            let copyName = [...errorName];
            copyName[idx] = false;
            setErrorName(copyName);

            let copyTel = [...errorTel];
            copyTel[idx] = false;
            setErrorTel(copyTel);

            let copyCar = [...errorCar];
            copyCar[idx] = false;
            setErrorCar(copyCar);

            let copyBank = [...errorBank];
            copyBank[idx] = false;
            setErrorBank(copyBank);

            let copyBank2 = [...errorBank2];
            copyBank2[idx] = false;
            setErrorBank2(copyBank2);
            if(values.de_deli_date === "" || values.de_deli_date === null){
                let copy = [...errorDate];
                copy[idx] = true;
                setErrorDate(copy);
            }
            if(values.de_deli_date !== "" && values.de_deli_date !== null){
                let copyCost = [...errorCost];
                copyCost[idx] = false;
                setErrorCost(copyCost);

                let copyName = [...errorName];
                copyName[idx] = false;
                setErrorName(copyName);

                let copyTel = [...errorTel];
                copyTel[idx] = false;
                setErrorTel(copyTel);

                let copyCar = [...errorCar];
                copyCar[idx] = false;
                setErrorCar(copyCar);

                let copyBank = [...errorBank];
                copyBank[idx] = false;
                setErrorBank(copyBank);

                let copyBank2 = [...errorBank2];
                copyBank2[idx] = false;
                setErrorBank2(copyBank2);

                let copyDate = [...errorDate];
                copyDate[idx] = false;
                setErrorDate(copyDate);

                let data = {
                    de_date_online: product.de_date_online,
                    de_no_online: product.de_no_online,
                    de_seq: product.de_seq,
                    de_seq2: product.de_seq2,
                    de_deli_type: "O"
                }
                setDeliInfo({...values,...data});
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'운임정보를 등록하시겠습니까?',
                    confirmPopBtn:2,
                }));
                setDeliConfirm(true);
            }
        }
    }

    //운임정보 등록 확인
    const registerHandler = () => {
        let de_date_online = deliInfo.de_date_online;
        let de_no_online = deliInfo.de_no_online;
        let de_seq = deliInfo.de_seq;
        let de_seq2 = deliInfo.de_seq2;
        let de_deli_date = moment(deliInfo.de_deli_date).format('YYYYMMDD');
        let tel = deliInfo.de_deli_tel.replace(/[^0-9]/g, '');
        let body = {
            de_cost2: CF.RemoveIntComma(deliInfo.de_cost2),
            de_deli_nm: deliInfo.de_deli_nm,
            de_deli_tel: tel,
            de_deli_car_num: deliInfo.de_deli_car_num,
            de_deli_bank_nm: deliInfo.de_deli_bank_nm,
            de_deli_bank_num: deliInfo.de_deli_bank_num,
            de_deli_date: de_deli_date,
            de_deli_type: deliInfo.de_deli_type,
        };
        axios.post(`${p_product_delivery.replace(':ip_date_online', ipDate).replace(':ip_no_online', ipNoOnline).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`, body,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
            if (res.status === 200) {
                // dispatch(detailPageBack(true));
                setOkConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'운임정보 등록이 완료되었습니다.',
                    confirmPopBtn:1,
                }));
            } 
            if (res.status !== 200) {
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: res.data.msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            } 
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    const validationSchema = Yup.object().shape({
        // ip_date_start: Yup.string()
        //     .required("출하일자를 입력해주세요."),
        // ip_date_end: Yup.string()
        //     .required("출하일자를 입력해주세요."),
    });

    //연락처input 포맷
    function MakePhoneForm(origin) {
        // 입력된 문자열에서 문자 제거
        const digitsOnly = origin.replace(/\D+/g, '');
      
        // 전화번호 정규식 (앞자리: 02 또는 3자리 숫자, 중간 자리: 3~4자리 숫자, 마지막 자리: 4자리 숫자)
        const phoneNumberRegex = /^(02|\d{3})(\d{3,4})(\d{4})$/;
      
        // 정규식을 사용해 전화번호 형식에 맞게 변경
        const formatted = digitsOnly.replace(phoneNumberRegex, '$1-$2-$3');
      
        // 변경된 전화번호 반환
        return formatted;
    }

    //목록으로 이동 (뒤로가기)
    const pageBack = () => {
        history.back();
    };

    //운임정보 자차배송 체크박스
    const setMyDeliCheck = (e,idx) => {
        if(e.target.checked){
            let copy = [...myDeli];
            copy[idx] = true;
            setMyDeli(copy);
        }else{
            let copy = [...myDeli];
            copy[idx] = false;
            setMyDeli(copy);
        }
    };

    //운임정보 팝업에서 선택시 선택한 운임정보 뿌려주기
    useEffect(() => {
        if(popup.deliverySelect){
            dispatch(deliverySelect(false));
            let newProList = [...proList];
            newProList[popup.deliveryPopNum].de_cost2 = popup.deliveryInfo.deli_cost2;
            newProList[popup.deliveryPopNum].de_deli_nm = popup.deliveryInfo.deli_nm;
            newProList[popup.deliveryPopNum].de_deli_tel = popup.deliveryInfo.deli_tel;
            newProList[popup.deliveryPopNum].de_deli_car_num = popup.deliveryInfo.deli_car_num;
            newProList[popup.deliveryPopNum].de_deli_bank_nm = popup.deliveryInfo.deli_bank_nm;
            newProList[popup.deliveryPopNum].de_deli_bank_num = popup.deliveryInfo.deli_bank_num;
            setProList(newProList);

            //배송일자 제외 에러문구 false
            let copyCost = [...errorCost];
            copyCost[popup.deliveryPopNum] = false;
            setErrorCost(copyCost);

            let copyName = [...errorName];
            copyName[popup.deliveryPopNum] = false;
            setErrorName(copyName);

            let copyTel = [...errorTel];
            copyTel[popup.deliveryPopNum] = false;
            setErrorTel(copyTel);

            let copyCar = [...errorCar];
            copyCar[popup.deliveryPopNum] = false;
            setErrorCar(copyCar);

            let copyBank = [...errorBank];
            copyBank[popup.deliveryPopNum] = false;
            setErrorBank(copyBank);

            let copyBank2 = [...errorBank2];
            copyBank2[popup.deliveryPopNum] = false;
            setErrorBank2(copyBank2);
        }
    },[popup.deliverySelect]);

    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont
                        user={props.user}
                        contFold={contFold}
                        setContFold={setContFold}
                    />
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">온라인거래결과 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>출하자</li>
                                    <li>온라인거래결과 조회</li>
                                </ul>
                            </div>
                            <div className="flex_between bp10">
                                <p className="medium">기본정보</p>
                                {invoiceData.ip_auct_name !== null &&
                                    <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                                }
                            </div>
                            <div className="custom_table2 bp10">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "14%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "14%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "14%" }} />
                                        <col style={{ width: "auto" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="th_gray">상태</th>
                                            <td><span className="tag">{invoiceData.ip_status}</span></td>
                                            <th className="th_gray">등록번호</th>
                                            <td>{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                                            <th className="th_gray">{invoiceData.ip_s_kind == '경매' ? '경매' : '입찰'}일시</th>
                                            {invoiceData.ip_auct_date_start ?
                                                <td>{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'+invoiceData.ip_auct_date_end}</td>
                                                : <td>-</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th><span>* </span>매매구분</th>
                                            <td>{invoiceData.ip_s_kind}</td>
                                            <th>팰릿</th>
                                            <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kpp)} 개</td>
                                            <th>목재(옥타곤)</th>
                                            <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kcp)} 개</td>
                                        </tr>
                                        <tr>
                                            <th><span>* </span>판매구분</th>
                                            <td>{invoiceData.ip_kind}</td>
                                            <th><span>* </span>산지</th>
                                            <td colSpan="3">{`${invoiceData.ip_loca_name}(${invoiceData.ip_loca})`}</td>
                                        </tr>
                                        <tr>
                                            <th>총 운송비</th>
                                            <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_wo_tot)} 원</td>
                                            <th>총 수량</th>
                                            <td colSpan="3" className="tx_r">{CF.MakeIntComma(invoiceData.ip_quan_tot)} 개</td>
                                        </tr>
                                        <tr>
                                            <th><span>* </span>배송가능기간</th>
                                            <td colSpan="3">{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                                            <th className="bg_sky">총 판매금액 </th>
                                            <td className="tx_r bg_sky">{`${invoiceData.total_sale_amount}`} 원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="inner_box line_top">
                            <div className="flex_between bp10">
                                {proList && proList.map((pro)=>{
                                    sumPrice = sumPrice + pro.de_price1;
                                    sumQuantity = sumQuantity + pro.de_quantity;
                                })}
                                <p className="medium">등록된 물품 : {proList.length}건</p>
                            </div>
                            {proList && proList.map((pro, idx) => {
                                const product = CF.trimObjectValues(pro);
                                let deliDate = product.de_deli_date;
                                const successAuctionState = ["10", "12", "14", "15", "16"];
                                const failAuctionState = ["10", "12", "14", "15", "16"];
                                if(deliDate > 0){
                                    deliDate = moment(deliDate).toISOString();
                                    deliDate = new Date(deliDate);
                                }else{
                                    deliDate = "";
                                }
                                
                                let deliReqDate = product.de_deli_req_date;
                                if(deliReqDate !== null){
                                    deliReqDate = deliReqDate.trim();
                                }
                                return (<div className="bm70" key={idx}>
                                    <div className="tx_r bp10">
                                        {successAuctionState.includes(product.de_status.trim()) &&
                                            <span className="tag2">낙찰단가 : {CF.MakeIntComma(product.de_price1)}원 * 수량 {CF.MakeIntComma(product.de_quantity)}개{product.de_dansu > 0 && " * 단수 " + CF.MakeIntComma(product.de_dansu) + "단"} = 판매금액 : <strong>{CF.MakeIntComma(product.de_amt1)}원</strong></span>
                                        }
                                        <span className="tag lm10 f_15">{enum_de_status[product.de_status.trim()][0]}</span>
                                    </div>
                                    <ProductInfo
                                        deFarmName={product.de_farm_name || ""}
                                        deGodsName={product.gm_name || ""}
                                        deWeight={product.de_weight || ""}
                                        dePacking={product.de_packing_name || ""}
                                        deQuality={product.de_quality_name || ""}
                                        deHigh={product.de_high_name || ""}
                                        deQuantity={product.de_quantity || ""}
                                        deCost2={product.de_cost2 || ""}
                                        deDansu={product.de_dansu || ""}
                                        deHopePri={product.de_hope_pri || ""}
                                        deCont1={product.de_cont1 || ""}
                                        deCont2={product.de_cont2 || ""}
                                        deCont3={product.de_cont3 || ""}
                                        deImg1={product.de_img1 || ""}
                                        deImg2={product.de_img2 || ""}
                                        deVideo={product.de_video || ""}
                                    />
                                    {deliReqDate !== null && deliReqDate.length > 0 && <>
                                        <p className="f_15 medium tp20 bp10">배송정보</p>
                                        <div className="custom_table wide_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{ width: '11%' }} />
                                                    <col style={{ width: '89%' }} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>배송정보</th>
                                                        <td className="tx_l">
                                                            <span>({product.de_deli_rece_post}) </span>
                                                            <span>{product.de_deli_rece_addr1}</span>
                                                            <span>{product.de_deli_rece_addr2}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>수령자정보</th>
                                                        <td className="tx_l">
                                                            <span>{product.de_deli_rece_nm}</span>
                                                            <p>연락처 : {CF.MakePhoneForm(product.de_deli_rece_tel)}</p>
                                                            <p className="bold">배송요청일 : {CF.MDFWithOutHour(product.de_deli_req_date)}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>기타메모</th>
                                                        <td className="tx_l">{product.de_deli_rece_remk}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}
                                    
                                    {/* de_status 가 14:운송대기 15:배송중 일때 운임정보등록폼 노출 */}
                                    {product.de_status == 14 || product.de_status == 15 ? <>
                                        <Formik
                                            initialValues={{
                                                de_cost2: product.de_cost2 || "",
                                                de_deli_nm: CF.objValueTrim(product.de_deli_nm) || "",
                                                de_deli_tel: CF.objValueTrim(product.de_deli_tel) ||  "",
                                                de_deli_car_num: CF.objValueTrim(product.de_deli_car_num) ||  "",
                                                de_deli_bank_nm: CF.objValueTrim(product.de_deli_bank_nm) ||  "",
                                                de_deli_bank_num: CF.objValueTrim(product.de_deli_bank_num) ||  "",
                                                de_deli_date: deliDate,
                                            }}
                                            validationSchema={validationSchema}
                                            // onSubmit={(e) => {
                                            //     registerHandler(e, product.de_date_online, product.de_no_online, product.de_seq, product.de_seq2);
                                            // }}
                                            validateOnMount={true}
                                            enableReinitialize={true}
                                        >
                                            {({ values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="flex tp20 bp10">
                                                        <p className="f_15 medium rp20">운임정보</p>
                                                        <div className="custom_check">
                                                            <label htmlFor={`mineCheck_${idx}`}>
                                                                <input type="checkbox" id={`mineCheck_${idx}`} 
                                                                    onChange={e => {
                                                                        setMyDeliCheck(e,idx);
                                                                        
                                                                        // 자차배송 체크시 입력값 비우기
                                                                        if(e.target.checked){
                                                                            setFieldValue("de_cost2","");
                                                                            setFieldValue("de_deli_nm","");
                                                                            setFieldValue("de_deli_tel","");
                                                                            setFieldValue("de_deli_car_num","");
                                                                            setFieldValue("de_deli_bank_nm","");
                                                                            setFieldValue("de_deli_bank_num","");
                                                                        }
                                                                    }} 
                                                                    checked={myDeli[idx]} 
                                                                />
                                                                <span className="check">체크</span>
                                                                <span className="txt">자차배송</span>
                                                            </label>
                                                        </div>
                                                        <button type="button" className="btn2_s lm20" onClick={()=>{
                                                            dispatch(deliveryPop(true));
                                                            dispatch(deliveryPopNum(idx));
                                                        }}>선택</button>
                                                    </div>
                                                    <div className="custom_table wide_table input_table">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '13%' }} />
                                                                <col style={{ width: '20.333%' }} />
                                                                <col style={{ width: '13%' }} />
                                                                <col style={{ width: '20.333%' }} />
                                                                <col style={{ width: '13%' }} />
                                                                <col style={{ width: '20.333%' }} />
                                                            </colgroup>
                                                            <tbody>
                                                                <tr>
                                                                    <th>운송비</th>
                                                                    <td>
                                                                        <div className={`custom_input h_40 txt_input ${myDeli[idx] ? "read_only" : ""}`}>
                                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" name="de_cost2" value={values.de_cost2} onChange={handleChange}
                                                                                onKeyDown={(e)=>{
                                                                                    if(e.key === "Enter") {
                                                                                        e.preventDefault();
                                                                                        registerClickHandler(values,idx,product);
                                                                                    }
                                                                                }}
                                                                                readOnly={myDeli[idx] ? true : false}
                                                                            />
                                                                            <span className="txt">원</span>
                                                                        </div>
                                                                        {errorCost[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">운송비를 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                    <th>기사명<span className="f_13">(예금주)</span></th>
                                                                    <td>
                                                                        <div className="custom_input h_40 tx_l">
                                                                            <input type="text" name="de_deli_nm" value={values.de_deli_nm} onChange={handleChange} 
                                                                                onKeyDown={(e)=>{
                                                                                    if(e.key === "Enter") {
                                                                                        e.preventDefault();
                                                                                        registerClickHandler(values,idx,product);
                                                                                    }
                                                                                }}
                                                                                readOnly={myDeli[idx] ? true : false}
                                                                            />
                                                                        </div>
                                                                        {errorName[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">기사명을 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                    <th>전화번호</th>
                                                                    <td>
                                                                        <div className="custom_input h_40">
                                                                            <NumberFormatBase name="de_deli_tel" maxLength="13" value={values.de_deli_tel} onChange={handleChange} format={MakePhoneForm} 
                                                                                onKeyDown={(e)=>{
                                                                                    if(e.key === "Enter") {
                                                                                        e.preventDefault();
                                                                                        registerClickHandler(values,idx,product);
                                                                                    }
                                                                                }}
                                                                                readOnly={myDeli[idx] ? true : false}
                                                                            />
                                                                        </div>
                                                                        {errorTel[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">전화번호를 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>차량번호</th>
                                                                    <td>
                                                                        <div className="custom_input h_40">
                                                                            <input type="text" name="de_deli_car_num" value={values.de_deli_car_num} onChange={handleChange} 
                                                                                onKeyDown={(e)=>{
                                                                                    if(e.key === "Enter") {
                                                                                        e.preventDefault();
                                                                                        registerClickHandler(values,idx,product);
                                                                                    }
                                                                                }}
                                                                                readOnly={myDeli[idx] ? true : false}
                                                                            />
                                                                        </div>
                                                                        {errorCar[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">차량번호를 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                    <th>운임 송금은행</th>
                                                                    <td>
                                                                        <div className="custom_select">
                                                                            <select
                                                                                name="de_deli_bank_nm"
                                                                                value={values.de_deli_bank_nm}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                style={{ display: 'block' }}
                                                                                disabled={myDeli[idx] ? true : false}
                                                                            >
                                                                                <option value="" hidden>은행선택</option>
                                                                                {bankList && bankList.map((bank,i)=>{
                                                                                    bank = CF.trimObjectValues(bank);
                                                                                    return(
                                                                                        <option value={bank.et_name} key={i}>{bank.et_name}</option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        {errorBank[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">송금은행을 선택해주세요.</div>
                                                                        }
                                                                    </td>
                                                                    <th>운임계좌</th>
                                                                    <td>
                                                                        <div className="custom_input h_40">
                                                                            <input type="text" name="de_deli_bank_num" value={values.de_deli_bank_num} onChange={handleChange} 
                                                                                onKeyDown={(e)=>{
                                                                                    if(e.key === "Enter") {
                                                                                        e.preventDefault();
                                                                                        registerClickHandler(values,idx,product);
                                                                                    }
                                                                                }}
                                                                                readOnly={myDeli[idx] ? true : false}
                                                                            />
                                                                        </div>
                                                                        {errorBank2[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">계좌를 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>배송일자</th>
                                                                    <td colSpan="5">
                                                                        <div className="date_input">
                                                                            <div className="custom_input f_15">
                                                                                <DatePicker
                                                                                    selected={values.de_deli_date}
                                                                                    minDate={deliStartDate}
                                                                                    maxDate={deliEndDate}
                                                                                    onChange={date => setFieldValue('de_deli_date', date)}
                                                                                    locale="ko"
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    name="de_deli_date"
                                                                                    onKeyDown={(e)=>{
                                                                                        if(e.key === "Enter") {
                                                                                            e.preventDefault();
                                                                                            registerClickHandler(values,idx,product);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {errorDate[idx] === true &&
                                                                            <div className="alert_txt f_12 tx_l">배송일자를 입력해주세요.</div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex_end tp20 bp20">
                                                        <button className="btn_type3" type="button" onClick={()=>{
                                                            registerClickHandler(values,idx,product);
                                                        }}>운임정보 등록</button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </>:null}

                                    {/* 중도매인이 인수등록하면 운임정보수정불가 */}
                                    {product.de_status == 16 && <>
                                        <p className="f_15 medium tp20 bp10">운임정보</p>
                                        <div className="custom_table wide_table input_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{ width: '13%' }} />
                                                    <col style={{ width: '20.333%' }} />
                                                    <col style={{ width: '13%' }} />
                                                    <col style={{ width: '20.333%' }} />
                                                    <col style={{ width: '13%' }} />
                                                    <col style={{ width: '20.333%' }} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>운송비</th>
                                                        <td className="tx_r">{CF.MakeIntComma(product.de_cost2)}원</td>
                                                        <th>기사명<span className="f_13">(예금주)</span></th>
                                                        <td className="tx_l">{product.de_deli_nm}</td>
                                                        <th>전화번호</th>
                                                        <td className="tx_l">{CF.MakePhoneForm(product.de_deli_tel)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>차량번호</th>
                                                        <td className="tx_l">{product.de_deli_car_num}</td>
                                                        <th>운임 송금은행</th>
                                                        <td className="tx_l">{product.de_deli_bank_nm}</td>
                                                        <th>운임계좌</th>
                                                        <td className="tx_l">{product.de_deli_bank_num}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>배송일자</th>
                                                        <td colSpan="5" className="tx_l">{moment(product.de_deli_date).format('YYYY-MM-DD')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}
                                </div>)
                            })}
                        </div>
                        <div className="rp30 bp30 lp30 flex_end">
                            <button className="btn_type4" onClick={pageBack}>목록으로 이동</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <div className="cont2">
                    <div className="flex_between bp10 tp20">
                        <p className="medium f_16">기본정보</p>
                        {invoiceData.ip_auct_name !== null &&
                            <span className="tag">경매사 : {invoiceData.ip_auct_name}</span>
                        }
                    </div>
                </div>
                <div className="custom_table2 bp10">
                    <table>
                        <colgroup>
                            <col style={{ width: "23%" }} />
                            <col style={{ width: "27%" }} />
                            <col style={{ width: "23%" }} />
                            <col style={{ width: "27%" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="th_gray">상태</th>
                                <td colSpan="3"><span className="tag">{invoiceData.ip_status}</span></td>
                            </tr>
                            <tr>
                                <th className="th_gray">등록번호</th>
                                <td colSpan="3">{`${invoiceData.ip_date_online}-${invoiceData.ip_no_online}`}</td>
                            </tr>
                            <tr>
                                <th className="th_gray">{invoiceData.ip_s_kind == '경매' ? '경매' : '입찰'}일시</th>
                                {invoiceData.ip_auct_date_start ?
                                    <td colSpan="3">{invoiceData.ip_auct_date_start}{invoiceData.ip_auct_date_end && '~'+invoiceData.ip_auct_date_end}</td>
                                    : <td>-</td>
                                }
                            </tr>
                            <tr>
                                <th><span>* </span>매매구분</th>
                                <td>{invoiceData.ip_s_kind}</td>
                                <th>팰릿</th>
                                <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kpp)} 개</td>
                            </tr>
                            <tr>
                                <th className="rp0">목재(옥타곤)</th>
                                <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_kcp)} 개</td>
                                <th><span>* </span>판매구분</th>
                                <td>{invoiceData.ip_kind}</td>
                            </tr>
                            <tr>
                                <th><span>* </span>산지</th>
                                <td colSpan="3">{`${invoiceData.ip_loca_name}(${invoiceData.ip_loca})`}</td>
                            </tr>
                            <tr>
                                <th>총 운송비</th>
                                <td className="tx_r">{CF.MakeIntComma(invoiceData.ip_wo_tot)} 원</td>
                                <th>총 수량</th>
                                <td colSpan="3" className="tx_r">{CF.MakeIntComma(invoiceData.ip_quan_tot)} 개</td>
                            </tr>
                            <tr>
                                <th><span>* </span>배송가능기간</th>
                                <td colSpan="3">{`${invoiceData.ip_delivery_date_start} ~ ${invoiceData.ip_delivery_date_end}`}</td>
                            </tr>
                            <tr>
                                <th className="bg_sky">총 판매금액 </th>
                                <td colSpan="3" className="tx_r bg_sky">{`${invoiceData.total_sale_amount}`} 원</td>                            
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="cont2">
                    <p className="medium bp10">등록된 물품 : <span className="txt_sky">{proList.length}</span>건</p>
                </div>
                {proList && proList.map((pro)=>{
                    sumPrice = sumPrice + pro.de_price1;
                    sumQuantity = sumQuantity + pro.de_quantity;
                })}
                {/* <div className="tag2 tx_r bp10">
                    <p>낙찰단가 : {CF.MakeIntComma(sumPrice)}원 * 수량 {CF.MakeIntComma(sumQuantity)}개</p>
                    <p>= 판매금액 : <strong>{CF.MakeIntComma(sumPrice*sumQuantity)}원</strong></p>
                </div> */}
                {proList && proList.map((pro, idx) => {
                    const product = CF.trimObjectValues(pro);
                    let deliDate = product.de_deli_date;
                    if(deliDate > 0){
                        deliDate = moment(deliDate).toISOString();
                        deliDate = new Date(deliDate);
                    }else{
                        deliDate = "";
                    }
                    
                    let deliReqDate = product.de_deli_req_date;
                    if(deliReqDate !== null){
                        deliReqDate = deliReqDate.trim();
                    }

                    return (<div className="bm30" key={idx}>
                        <div className="tag2 tx_r bp10">
                            <p>낙찰단가 : {CF.MakeIntComma(product.de_price1)}원 * 수량 {CF.MakeIntComma(product.de_quantity)}개{product.de_dansu > 0 && " * 단수 " + CF.MakeIntComma(product.de_dansu) + "단"}</p>
                            <p>= 판매금액 : <strong>{CF.MakeIntComma(product.de_amt1)}원</strong></p>
                        </div>
                        <ProductInfo
                            deFarmName={product.de_farm_name || ""}
                            deFarmCode={product.de_farm_code.trim() || ""}
                            deGodsName={product.gm_name || ""}
                            deWeight={product.de_weight || ""}
                            dePacking={product.de_packing_name || ""}
                            deQuality={product.de_quality_name || ""}
                            deHigh={product.de_high_name || ""}
                            deQuantity={product.de_quantity || ""}
                            deCost2={product.de_cost2 || ""}
                            deDansu={product.de_dansu || ""}
                            deHopePri={product.de_hope_pri || ""}
                            deCont1={product.de_cont1 || ""}
                            deCont2={product.de_cont2 || ""}
                            deCont3={product.de_cont3 || ""}
                            deImg1={product.de_img1 || ""}
                            deImg2={product.de_img2 || ""}
                            deVideo={product.de_video || ""}
                        />
                        {deliReqDate !== null && deliReqDate.length > 0 && <>
                            <p className="f_16 medium tp10 lp10 bp10">배송정보</p>
                            <div className="custom_table gray_table">
                                <table>
                                    <colgroup>
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '70%' }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>배송정보</th>
                                            <td className="tx_l">
                                                <p>({product.de_deli_rece_post})</p>
                                                <span>{product.de_deli_rece_addr1}</span>
                                                <span>{product.de_deli_rece_addr2}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>수령자정보</th>
                                            <td className="tx_l">
                                                <span>{product.de_deli_rece_nm}</span>
                                                <p>연락처 : {CF.MakePhoneForm(product.de_deli_rece_tel)}</p>
                                                <p className="bold">배송요청일 : {CF.MDFWithOutHour(product.de_deli_req_date)}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>기타메모</th>
                                            <td className="tx_l">{product.de_deli_rece_remk}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        
                        {/* de_status 가 14:운송대기 일때 운임정보등록폼 노출 */}
                        {product.de_status == 14 || product.de_status == 15 ? <>
                            <Formik
                                initialValues={{
                                    de_cost2: product.de_cost2 || "",
                                    de_deli_nm: CF.objValueTrim(product.de_deli_nm) || "",
                                    de_deli_tel: CF.objValueTrim(product.de_deli_tel) ||  "",
                                    de_deli_car_num: CF.objValueTrim(product.de_deli_car_num) ||  "",
                                    de_deli_bank_nm: CF.objValueTrim(product.de_deli_bank_nm) ||  "",
                                    de_deli_bank_num: CF.objValueTrim(product.de_deli_bank_num) ||  "",
                                    de_deli_date: deliDate,
                                }}
                                validationSchema={validationSchema}
                                // onSubmit={(e) => {
                                //     registerHandler(e, product.de_date_online, product.de_no_online, product.de_seq, product.de_seq2);
                                // }}
                                validateOnMount={true}
                                enableReinitialize={true}
                            >
                                {({ values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex tp10 lp10 bp10">
                                            <p className="f_16 medium rp10">운임정보</p>
                                            <div className="custom_check">
                                                <label htmlFor={`mineCheck_${idx}`}>
                                                    <input type="checkbox" id={`mineCheck_${idx}`} 
                                                        onChange={e => {
                                                            setMyDeliCheck(e,idx);

                                                            // 자차배송 체크시 입력값 비우기
                                                            if(e.target.checked){
                                                                setFieldValue("de_cost2","");
                                                                setFieldValue("de_deli_nm","");
                                                                setFieldValue("de_deli_tel","");
                                                                setFieldValue("de_deli_car_num","");
                                                                setFieldValue("de_deli_bank_nm","");
                                                                setFieldValue("de_deli_bank_num","");
                                                            }
                                                        }} 
                                                        checked={myDeli[idx]} 
                                                    />
                                                    <span className="check">체크</span>
                                                    <span className="txt">자차배송</span>
                                                </label>
                                            </div>
                                            <button type="button" className="btn2_s lm10" onClick={()=>{
                                                dispatch(deliveryPop(true));
                                                dispatch(deliveryPopNum(idx));
                                            }}>선택</button>
                                        </div>
                                        <div className="custom_table gray_table">
                                            <table>
                                                <colgroup>
                                                    <col style={{ width: '30%' }} />
                                                    <col style={{ width: '70%' }} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>운송비</th>
                                                        <td>
                                                            <div className={`custom_input h_40 txt_input ${myDeli[idx] ? "read_only" : ""}`}>
                                                                <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" name="de_cost2" value={values.de_cost2}  onChange={handleChange}
                                                                    readOnly={myDeli[idx] ? true : false}
                                                                />
                                                                <span className="txt">원</span>
                                                            </div>
                                                            {errorCost[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">운송비를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>기사명<span className="f_13">(예금주)</span></th>
                                                        <td>
                                                            <div className="custom_input h_40">
                                                                <input type="text" name="de_deli_nm" value={values.de_deli_nm} onChange={handleChange} 
                                                                    readOnly={myDeli[idx] ? true : false}
                                                                />
                                                            </div>
                                                            {errorName[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">기사명을 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>전화번호</th>
                                                        <td>
                                                            <div className="custom_input h_40">
                                                                <NumberFormatBase name="de_deli_tel" maxLength="13" value={values.de_deli_tel} onChange={handleChange} format={MakePhoneForm} 
                                                                    readOnly={myDeli[idx] ? true : false}
                                                                />
                                                            </div>
                                                            {errorTel[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">전화번호를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>차량번호</th>
                                                        <td>
                                                            <div className="custom_input h_40">
                                                                <input type="text" name="de_deli_car_num" value={values.de_deli_car_num} onChange={handleChange} 
                                                                    readOnly={myDeli[idx] ? true : false}
                                                                />
                                                            </div>
                                                            {errorCar[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">차량번호를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>운임 송금은행</th>
                                                        <td>
                                                            <div className="custom_select">
                                                                <select
                                                                    name="de_deli_bank_nm"
                                                                    value={values.de_deli_bank_nm}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    style={{ display: 'block' }}
                                                                    disabled={myDeli[idx] ? true : false}
                                                                >
                                                                    <option value="" hidden>은행선택</option>
                                                                    {bankList && bankList.map((bank,i)=>{
                                                                        bank = CF.trimObjectValues(bank);
                                                                        return(
                                                                            <option value={bank.et_name} key={i}>{bank.et_name}</option>
                                                                        );
                                                                    })}                                                                                                 
                                                                </select>
                                                            </div>
                                                            {errorBank[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">송금은행을 선택해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>운임계좌</th>
                                                        <td>
                                                            <div className="custom_input h_40">
                                                                <input type="text" name="de_deli_bank_num" value={values.de_deli_bank_num} onChange={handleChange} 
                                                                    readOnly={myDeli[idx] ? true : false}
                                                                />
                                                            </div>
                                                            {errorBank2[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">계좌를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>배송일자</th>
                                                        <td>
                                                            <div className="date_input">
                                                                <div className="custom_input w_100">
                                                                    <DatePicker
                                                                        selected={values.de_deli_date}
                                                                        minDate={deliStartDate}
                                                                        maxDate={deliEndDate}
                                                                        onChange={date => setFieldValue('de_deli_date', date)}
                                                                        locale="ko"
                                                                        dateFormat="yyyy-MM-dd"
                                                                        name="de_deli_date"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errorDate[idx] === true &&
                                                                <div className="alert_txt f_12 tx_l">배송일자를 입력해주세요.</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="cont2 tm20 bm50">
                                            <button className="btn_type3 w_100" type="button" onClick={()=>{
                                                registerClickHandler(values,idx,product);
                                            }}>운임정보 등록</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </>:null}

                        {/* 중도매인이 인수등록하면 운임정보수정불가 */}
                        {product.de_status == 16 && <>
                            <p className="f_15 medium tp20 lp10 bp10">운임정보</p>
                            <div className="custom_table gray_table">
                                <table>
                                    <colgroup>
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '70%' }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>운송비</th>
                                            <td className="tx_r">{CF.MakeIntComma(product.de_cost2)}원</td>
                                        </tr>
                                        <tr>
                                            <th>기사명<span className="f_13">(예금주)</span></th>
                                            <td className="tx_l">{product.de_deli_nm}</td>
                                        </tr>
                                        <tr>
                                            <th>전화번호</th>
                                            <td className="tx_l">{CF.MakePhoneForm(product.de_deli_tel)}</td>
                                        </tr>
                                        <tr>
                                            <th>차량번호</th>
                                            <td className="tx_l">{product.de_deli_car_num}</td>
                                        </tr>
                                        <tr>
                                            <th>운임 송금은행</th>
                                            <td className="tx_l">{product.de_deli_bank_nm}</td>
                                        </tr>
                                        <tr>
                                            <th>운임계좌</th>
                                            <td className="tx_l">{product.de_deli_bank_num}</td>
                                        </tr>
                                        <tr>
                                            <th>배송일자</th>
                                            <td className="tx_l">{moment(product.de_deli_date).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}
                    </div>)
                })}
                <div className="cont2 flex_end">
                    <button className="btn_type4 bm20" onClick={pageBack}>목록으로 이동</button>
                </div>
            </div>
        }

        {/* 운임정보등록 confirm팝업 */}
        {deliConfirm && <ConfirmPop onClickHandler={registerHandler} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}

        {/* 운임정보등록완료 뒤로가기 팝업 */}
        {okConfirm && <ConfirmPop />}
    </>);
};

export default OnlineCheckDetail;
