import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { PatternFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { NumericFormat } from 'react-number-format';


import { closePop } from "../../../../store/popupSlice";
import {enum_api_uri, debounce, debounce2,enum_de_status,enum_sell_kind} from "../../../../config/enum";
import moment from "moment";

const TableProducePop = () => {
    registerLocale('ko', ko);
    const token = localStorage.getItem("token");
    const a_list_table = enum_api_uri.a_list_table;
    const a_closed_bids = enum_api_uri.a_closed_bids;
    const a_check_bids = enum_api_uri.a_check_bids;
    const a_cancel_bids = enum_api_uri.a_cancel_bids;
    
    const dispatch = useDispatch();
    const [tableList, setTableList] = useState([]);
    const [dateInput, setDateInput] = useState('');


    useEffect(() => {
        setTableList(tableList);
    },[tableList]);

    useEffect(() => {
        setDateInput(dateInput);
    },[dateInput]);


    const closePopHandler = () => {
        dispatch(closePop());
    }

    const validationSchema = Yup.object().shape({
        // date_start: Yup.string()
        // .test('len','검색 시잓 일자를 정확히 입력해주세요.',(val) => {
        //     if(val){
        //         const regex = /[^0-9]/g;
        //         val = val.replace(regex,'');
        //         return  (val.length == 12)
        //     }
        // }),
        // date_end: Yup.string().test('len','검색 종료 일자를 정확히 입력해주세요.',(val) => {
        //     if(val){
        //         const regex = /[^0-9]/g;
        //         val = val.replace(regex,'');
        //         return  (val.length == 12)
        //     }
        // }),
        // regNo_start: Yup.string().test('len','검색 등록번호1을 정확히 입력해주세요.',(val) => {
        //     if(val){
        //         const regex = /[^0-9]/g;
        //         val = val.replace(regex,'');
        //         console.log(val.length)
        //         return  (val.length == 11)
        //     }
        // }),
        // regNo_end: Yup.string().test('len','검색 등록번호2를 정확히 입력해주세요.',(val) => {
        //     if(val){
        //         const regex = /[^0-9]/g;
        //         val = val.replace(regex,'');
        //         return  (val.length == 11)
        //     }
        // }),
    });


    //리스트 받아오기(현재 페이지 공통)
    const getList = async(val) =>{
        let assoCode = JSON.parse(localStorage.getItem('assoCode'));
            assoCode = assoCode.asso_code;
        // let date_start = val.date_start.replace(/[^0-9]/g, '');
        // let date_end = val.date_end.replace(/[^0-9]/g, '');
            
        axios.get(`${a_list_table.replace(':asso_code',assoCode)}?ip_auct_date_start=${val.date_start}&ip_auct_date_end=${val.date_end}&regNo_start=${val.regNo_start}&regNo_end=${val.regNo_end}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                const data = res.data
                let state = [...data];
                // let resCount = data.total_record_count;
                // console.log(data.total_record_count)
                setTableList(state); //리스트 생성
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    

    //원장일자 일괄적용하기
    const submit = (values) => {
        let date = moment(values.select_date).format('YYYY-MM-DD')
        setDateInput(date);
    };

    //원표넘기기
    const passHandler = () => {
        let assoCode = JSON.parse(localStorage.getItem('assoCode'));
            assoCode = assoCode.asso_code

        let ip_date_online = []
        let ip_no_online = []
        let ip_date = []
        let ip_no = []

        tableList.map((listVal,i) => {
            ip_date_online.push(listVal.ip_date_online)
            ip_no_online.push(listVal.ip_no_online)
            ip_date.push(moment(dateInput).format('YYYYMMDD'))
            ip_no.push(tableList[i].ip_no)
        });


        let body = {ip_date_online:ip_date_online, ip_no_online:ip_no_online, ip_date:ip_date, ip_no:ip_no, };
        axios.put(`${a_closed_bids.replace(':asso_code',assoCode)}`,body,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                alert('넘기기완료!');
            }
        })
        .catch((error) => {
            console.log(error);
        })
    };


    //원장번호 유효성 검사
    const checkTable = (e,idx) => {
        let assoCode = JSON.parse(localStorage.getItem('assoCode'));
            assoCode = assoCode.asso_code
        let ip_no = e.target.value

        axios.get(`${a_check_bids.replace(':asso_code',assoCode).replace(':ip_no',ip_no)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                tableList[idx].ip_no= ip_no
                let newTableList = [...tableList]
                setTableList(newTableList)
            }   
        })
        .catch((error) => {
            console.log(error);
        })
    };

    //원장번호 취소
    const cancelTable = (ipdo,ipno,idx) => {
        let assoCode = JSON.parse(localStorage.getItem('assoCode'));
            assoCode = assoCode.asso_code

        let ip_date_online = [ipdo]
        let ip_no_online = [ipno]

        let body = {ip_date_online:ip_date_online, ip_no_online:ip_no_online };

        axios.put(`${a_cancel_bids.replace(':asso_code',assoCode)}`,body,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                tableList[idx].ip_no = null;
                let newTableList = [...tableList]
                setTableList(newTableList)
            }
        })
        .catch((error) => {
            console.log(error);
        })
    };


    
    return(
        <div className="pop_wrap">
            <div className="dim"></div>
            <div className="pop_cont pop_cont3">
                <button className="pop_close" onClick={closePopHandler}>닫기버튼</button>
                <div className="pop_tit">
                    <p className="tit">경매입찰 원표생성</p>
                </div>

                <Formik
                    initialValues={{
                        date_start: "",
                        date_end: "",
                        regNo_start: "",
                        regNo_end: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={getList}
                    validateOnMount={true}
                >
                {({values, setFieldValue, handleChange, handleSubmit, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <ul className="search_box search_box2">
                            <li className="bp10">
                                <p className="txt">경매일자</p>
                                <div className="box">
                                    <div className="input_box input_box5">
                                        <div className="custom_input">
                                            <PatternFormat
                                                name="date_start"
                                                format="####-##-## ##:##"
                                                onChange={handleChange}
                                                value={values.date_start} 
                                            />
                                        </div>
                                        <div className="custom_input">
                                            <PatternFormat
                                                name="date_end"
                                                format="####-##-## ##:##"
                                                onChange={handleChange}
                                                value={values.date_end} 
                                            />
                                        </div>
                                    </div>
                                    {errors.date_start && touched.date_start ?
                                        <div className="alert_txt">
                                            {errors.date_start}
                                        </div>
                                    : null}
                                    {errors.date_end && touched.date_end ?
                                        <div className="alert_txt">
                                            {errors.date_end}
                                        </div>
                                    : null}
                                </div>
                            </li>
                            <li>
                                <p className="txt">등록번호</p>
                                <div className="box">
                                    <div className="input_box input_box5">
                                        <div className="custom_input">
                                            <PatternFormat
                                                name="regNo_start"
                                                format="######-#####"
                                                value={values.regNo_start} 
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="custom_input">
                                            <PatternFormat
                                                name="regNo_end"
                                                format="######-#####"
                                                value={values.regNo_end} 
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <button type = 'submit' className="btn_type3">자료조회</button>
                                    </div>
                                    {errors.regNo_start && touched.regNo_start ?
                                        <div className="alert_txt">
                                            {errors.regNo_start}
                                        </div>
                                    : null}
                                    {errors.regNo_end && touched.regNo_end ?
                                        <div className="alert_txt">
                                            {errors.regNo_end}
                                        </div>
                                    : null}
                                </div>
                            </li>
                        </ul>
                    </form>
                )}
                </Formik>
                <div className="scroll_wrap">
                <Formik
                    initialValues={{
                        select_date: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                {({values, setFieldValue, handleChange, handleSubmit, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex_between tp30">
                            <div>
                                <p className="f_15 medium">검색결과 목록</p>
                                <p className="f_12 txt_gray">※ 원장 일자 변경을 원하실 경우 개별 일자 설정이 가능합니다. <br/>※ 일괄 일자 변경을 원하실 경우 우측의 기능을 사용하세요.</p>
                            </div>
                            <div className="flex">
                                <div className="custom_input w_120px h_40 rm5">
                                    <DatePicker
                                        selected={values.select_date}
                                        onChange={date => setFieldValue('select_date', date)}
                                        locale="ko"
                                        dateFormat="yyyy-MM-dd"
                                        name="date"
                                    />
                                </div>
                                <button className="btn_s3" type="submit">원장일자 일괄적용</button>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>


                    <div className="custom_table s_table tm20">
                        <table>
                            <colgroup>
                                <col style={{width:'110px'}} />
                                <col style={{width:'70px'}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>등록번호</th>
                                    <th>구분</th>
                                    <th>출하주</th>
                                    <th>대표품목</th>
                                    <th>총수량</th>
                                    <th>총금액</th>
                                    <th>원장일자</th>
                                    <th>원장번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* 등록물품 없을때 ▼ */}
                            {tableList.length === 0 && (
                                <tr>
                                    <td colSpan = '8'>
                                    조회된 물품이 없습니다. 
                                    </td>
                                </tr>
                            )}
                            {/* 등록물품 있을때 ▼ */}
                            {tableList.length > 0 && tableList.map((tableVals,idx) => {
                                
                                    let s_kind = enum_sell_kind[tableVals.ip_s_kind.trim()]
                                    let ip_no_reg = tableVals.ip_date_online+"-"+tableVals.ip_no_online
                                    let tableInputBtn = "";
                                    let table_ip_date = "";
                                    //원장번호
                                    if(tableVals.ip_no){
                                        tableInputBtn =<button className="cancel_tag" onClick={()=>{cancelTable(tableVals.ip_date_online,tableVals.ip_no_online,idx)}}>원표취소({tableVals.ip_no})</button>
                                    }else{
                                        tableInputBtn = 
                                        <NumericFormat onBlur={(e)=>checkTable(e,idx)} maxLength='4'/>
                                    }
                                    //원장일자 
                                    if(tableVals.ip_date){
                                        let newDate = moment(tableVals.ip_date).format('YYYY-MM-DD')
                                        table_ip_date = <input type="text" value={newDate}/>
                                    }else{
                                        table_ip_date = <input type="text" value={dateInput} onChange={(e)=>{setDateInput(e.currentTarget.value)}}/>
                                    }
                                return (
                                
                                <tr key = {idx}>
                                    <td>{ip_no_reg}</td>
                                    <td>{s_kind}</td>
                                    <td>{tableVals.is_name}</td>
                                    <td>
                                        <div className="over_txt"><span>파프리카(빨강) 파프리카(빨강)</span></div>
                                    </td>
                                    <td>{tableVals.ip_quan_tot}</td>
                                    <td>{tableVals.ip_wo_tot}</td>
                                    <td>
                                        <div className="custom_input h_30">
                                            {/* <input type="text" value={dateInput} onChange={(e)=>{setDateInput(e.currentTarget.value)}}/> */}
                                            {table_ip_date}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="custom_input h_30">
                                            {tableInputBtn}
                                        </div>
                                    </td>
                                </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex_center tp30">
                        <button className="btn_type" onClick={passHandler}>원표넘기기</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableProducePop;