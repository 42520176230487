import { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {enum_api_uri, debounce, debounce2, enum_mo_title} from "../../../config/enum";
import moment from "moment";
import { isMobile, isBrowser } from 'react-device-detect';
import LeftCont from "../../../components/pc/component/LeftCont";
import {ShipProductInfo} from "../../../components/pc/component/ProductList";
import Pagination from "../../../components/pc/component/Pagination";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
// import { Pagination } from "swiper";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { confirmPop } from "../../../store/popupSlice";
import * as CF from "../../../components/pc/component/Function"
import InputDatepicker from "../../../components/pc/component/InputDatepicker";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";

const ShipmentProducts = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const basicDate = new Date();
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [ipSKind, setIpSKind] = useState('');
    const [dateError, setDateError] = useState(false);
    const [proList, setProList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const popup = useSelector((state) => state.popup);
    const common = useSelector((state) => state.common);
    const location = useLocation();
    const m_list_ship_product = enum_api_uri.m_list_ship_product;
    registerLocale('ko', ko);
    const [confirm, setConfirm] = useState(false);
    const [scrollMove, setScrollMove] = useState(false);
    const [sort, setSort] = useState("");
    const [auctStartHour, setAuctStartHour] = useState('00');
    const [auctStartMinute, setAuctStartMinute] = useState('00');
    const [auctEndHour, setAuctEndHour] = useState('23');
    const [auctEndMinute, setAuctEndMinute] = useState('50');
    const [hourOptions, setHourOptions] = useState([
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
    ]);
    const [minuteOptions, setMinuteOptions] = useState([
        "00",
        "10",
        "20",
        "30",
        "40",
        "50",
    ]);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);
        
        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setSort(sort);
    },[sort]);

    useEffect(()=>{
        setIpSKind(ipSKind);
    },[ipSKind]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(() => {
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
    },[viewPaging]);

    useEffect(()=>{ //처음 한번 리스트 불러오기a1

        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_s_kind = listPage.ip_s_kind;
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.auction_time_start = listPage.auction_start_hour+listPage.auction_start_minute;
            val.auction_time_end = listPage.auction_end_hour+listPage.auction_end_minute;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            if(listPage.ip_s_kind === "2"){
                setSort("2");
            }
            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setIpSKind(listPage.ip_s_kind);
            setAuctStartHour(listPage.auction_start_hour);
            setAuctStartMinute(listPage.auction_start_minute);
            setAuctEndHour(listPage.auction_end_hour);
            setAuctEndMinute(listPage.auction_end_minute);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_s_kind = '';
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.auction_time_start = auctStartHour+auctStartMinute;
            val.auction_time_end = auctEndHour+auctEndMinute;
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_s_kind:'',
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);

    //경매목록 조회 버튼 클릭시
    const submit = (values) => {
        let regex = /[^0-9]/g;
        let ip_s_kind = ipSKind.replace(regex,'');
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        let auction_time_start = auctStartHour+auctStartMinute;
        let auction_time_end = auctEndHour+auctEndMinute;

        let val = [];
        val.ip_s_kind = ip_s_kind;
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.auction_time_start = auction_time_start;
        val.auction_time_end = auction_time_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_s_kind:ip_s_kind,
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end,
            auction_start_hour:auctStartHour,
            auction_start_minute:auctStartMinute,
            auction_end_hour:auctEndHour,
            auction_end_minute:auctEndMinute,
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };
    
    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_s_kind = ipSKind;
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.auction_time_start = auctStartHour+auctStartMinute;
        val.auction_time_end = auctEndHour+auctEndMinute;
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };

    //리스트 받아오기(현재 페이지 공통)
    const getList = (val) =>{
        axios.get(`${m_list_ship_product}?ip_date_start=${val.ip_date_start}&ip_date_end=${val.ip_date_end}&ip_s_kind=${val.ip_s_kind}&record_size=${val.record_size}&page_no=${val.page_no_now}&auction_time_start=${val.auction_time_start}&auction_time_end=${val.auction_time_end}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                // console.log(res);
                const data = res.data
                let state = [...data.results];
                let resCount = data.total_record_count;
                setProList(state); //리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    


    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont
                        user={props.user}
                        contFold={contFold}
                        setContFold={setContFold}
                    />
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">출하물품 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>출하물품 조회</li>
                                </ul>
                            </div>
                            <Formik
                                initialValues={{
                                    // ip_s_kind: ipSKind || "",
                                }}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                                {({values, setFieldValue, handleSubmit,handleChange,handleBlur, errors, touched}) => (
                                    <form onSubmit={handleSubmit}>
                                        <ul className="search_box">
                                            <li className={sort === "2" ? "w_auto" : ""}>
                                                <p className="txt">출하일자</p>
                                                <div className="date_input">
                                                    <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                                    <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                                </div>
                                            </li>
                                            <li className={sort === "2" ? "w_auto" : ""}>
                                                {sort !== "2" && <p className="txt">구분</p>}
                                                <div className={`flex ${sort === "2" ? "sel_box lm20" : ""}`}>
                                                    <div className="custom_select rm5">
                                                        <select 
                                                            value={`ip_s_kind_${ipSKind}`}
                                                            onChange={(e)=>{
                                                                let value = e.currentTarget.value;
                                                                    value = value.replace(/[^0-9]/g,'');
                                                                setIpSKind(value);
                                                                setSort(value);
                                                            }}
                                                            style={{ display: 'block' }}
                                                        >
                                                            <option value="">전체</option>
                                                            <option value="ip_s_kind_1">입찰</option>
                                                            <option value="ip_s_kind_2">경매</option>
                                                        </select>
                                                    </div>
                                                    {sort === "2" && <>
                                                        <div className="custom_select rm5">
                                                            <select 
                                                                value={auctStartHour}
                                                                onChange={(e)=>{
                                                                    setAuctStartHour(e.currentTarget.value);
                                                                }}
                                                            >
                                                                <option value="" hidden>시</option>
                                                                {hourOptions.map((option,i)=>{
                                                                    return(
                                                                        <option key={i} value={option}>{option}시</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="custom_select rm5">
                                                            <select 
                                                                value={auctStartMinute}
                                                                onChange={(e)=>{
                                                                    setAuctStartMinute(e.currentTarget.value);
                                                                }}
                                                            >
                                                                <option value="" hidden>분</option>
                                                                {minuteOptions.map((option,i)=>{
                                                                    return(
                                                                        <option key={i} value={option}>{option}분</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="custom_select rm5">
                                                            <select 
                                                                value={auctEndHour}
                                                                onChange={(e)=>{
                                                                    setAuctEndHour(e.currentTarget.value);
                                                                }}
                                                            >
                                                                <option value="" hidden>시</option>
                                                                {hourOptions.map((option,i)=>{
                                                                    return(
                                                                        <option key={i} value={option}>{option}시</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="custom_select rm5">
                                                            <select 
                                                                value={auctEndMinute}
                                                                onChange={(e)=>{
                                                                    setAuctEndMinute(e.currentTarget.value);
                                                                }}
                                                            >
                                                                <option value="" hidden>분</option>
                                                                {minuteOptions.map((option,i)=>{
                                                                    return(
                                                                        <option key={i} value={option}>{option}분</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </>}
                                                    <button type="submit" className="btn_s">조회</button>
                                                </div>
                                            </li>
                                        </ul>
                                        {dateError && 
                                            <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                                        }
                                        <div className="tm30">
                                            <p className="medium bp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                            {/* 등록물품 없을때 ▼ */}
                                            {proList.length === 0 && (
                                                <div className="none_data"><span>데이터가 없습니다.</span></div>
                                            )}

                                            {/* 등록물품 있을때 ▼ */}
                                            {proList.length > 0 &&
                                                <div>
                                                    <div className="custom_table s_table">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: "135px" }} />
                                                                <col style={{ width: "50px" }} />
                                                                <col style={{ width: "90px" }} />
                                                                <col style={{ width: "130px" }} />
                                                                <col style={{ width: "auto" }} />
                                                                <col style={{ width: "70px" }} />
                                                                <col style={{ width: "70px" }} />
                                                                <col style={{ width: "auto" }} />
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th>등록번호</th>
                                                                    <th>구분</th>
                                                                    <th>경매사</th>
                                                                    <th>경매/입찰시간</th>
                                                                    <th className="th_link"><span>대표품목</span></th>
                                                                    <th>입하</th>
                                                                    <th>수량</th>
                                                                    <th>상태</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {proList.map((a,i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <ShipProductInfo 
                                                                                ip_date_online={proList[i].ip_date_online}
                                                                                ip_no_online={proList[i].ip_no_online}
                                                                                ip_s_kind={proList[i].ip_s_kind}
                                                                                ip_auct_name={proList[i].ip_auct_name}
                                                                                ip_auct_date_start={proList[i].ip_auct_date_start}
                                                                                ip_auct_date_end={proList[i].ip_auct_date_end}
                                                                                gm_name={proList[i].gm_name}
                                                                                ip_status={proList[i].ip_status}
                                                                                de_count={proList[i].de_count}
                                                                                de_quantity={proList[i].de_quantity}
                                                                                de_memo_count={proList[i].de_memo_count}
                                                                                pindex={i}
                                                                            />
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }

                                            {/* 조회가 없을때 ▼ */}
                                            {viewPaging === 0 && (
                                            <div className="pagination">
                                                <button className="prev">
                                                    <span>이전 페이지</span>
                                                </button>
                                                <ul className="num_box">
                                                    <li className="on">
                                                        <button>1</button>
                                                    </li>
                                                </ul>
                                                <button className="next">
                                                    <span>다음 페이지</span>
                                                </button>
                                            </div>
                                            )}
                                            {/* 조회가 페이징보다 클때 ▼ */}
                                            {viewPaging > 0 &&
                                                    <Pagination 
                                                    nowPage={page_no}
                                                    totalCnt={viewPaging}
                                                    setPerPage={perPage}
                                                    moveToPage={moveToPage}

                                                    />
                                            }
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        // ip_s_kind: ipSKind || ""
                    }}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                    {({values, setFieldValue, handleSubmit, errors, touched, handleChange, handleBlur}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="search_box">
                                <ul>
                                    <li>
                                        <p className="txt">출하일자</p>
                                        <div className="date_input">
                                            <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                            <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                        </div>
                                    </li>
                                    <li>
                                        <p className="txt">구분</p>
                                        <div className="custom_select">
                                            <select 
                                                value={`ip_s_kind_${ipSKind}`}
                                                onChange={(e)=>{
                                                    let value = e.currentTarget.value;
                                                        value = value.replace(/[^0-9]/g,'');
                                                    setIpSKind(value);
                                                    setSort(value);
                                                }}
                                                style={{ display: 'block' }}
                                            >
                                                <option value="">전체</option>
                                                <option value="ip_s_kind_1">입찰</option>
                                                <option value="ip_s_kind_2">경매</option>
                                            </select>
                                        </div>
                                    </li>
                                    {sort === "2" &&
                                        <li className="flex_between">
                                            <div className="custom_select rm5">
                                                <select 
                                                    value={auctStartHour}
                                                    onChange={(e)=>{
                                                        setAuctStartHour(e.currentTarget.value);
                                                    }}
                                                >
                                                    <option value="" hidden>시</option>
                                                    {hourOptions.map((option,i)=>{
                                                        return(
                                                            <option key={i} value={option}>{option}시</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="custom_select rm5">
                                                <select 
                                                    value={auctStartMinute}
                                                    onChange={(e)=>{
                                                        setAuctStartMinute(e.currentTarget.value);
                                                    }}
                                                >
                                                    <option value="" hidden>분</option>
                                                    {minuteOptions.map((option,i)=>{
                                                        return(
                                                            <option key={i} value={option}>{option}분</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="custom_select rm5">
                                                <select 
                                                    value={auctEndHour}
                                                    onChange={(e)=>{
                                                        setAuctEndHour(e.currentTarget.value);
                                                    }}
                                                >
                                                    <option value="" hidden>시</option>
                                                    {hourOptions.map((option,i)=>{
                                                        return(
                                                            <option key={i} value={option}>{option}시</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="custom_select">
                                                <select 
                                                    value={auctEndMinute}
                                                    onChange={(e)=>{
                                                        setAuctEndMinute(e.currentTarget.value);
                                                    }}
                                                >
                                                    <option value="" hidden>분</option>
                                                    {minuteOptions.map((option,i)=>{
                                                        return(
                                                            <option key={i} value={option}>{option}분</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </li>
                                    }
                                </ul>
                                <button type="submit" className="btn_s2 w_100 tm10">조회</button>
                            </div>
                            {dateError && 
                                <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                            }
                            <div className="tm30">
                                <p className="medium bp10 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_data"><span>데이터가 없습니다.</span></div>
                                )}

                                {/* 등록물품 있을때 ▼ */}
                                {proList.length > 0 && 
                                    <div className="list_result_box">
                                        {proList.map((a,i) => {
                                            return (
                                                <div className="custom_table gray_table" key={i}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: "23%" }} />
                                                            <col style={{ width: "27%" }} />
                                                            <col style={{ width: "23%" }} />
                                                            <col style={{ width: "27%" }} />
                                                        </colgroup>
                                                        <tbody>
                                                            <ShipProductInfo 
                                                                ip_date_online={proList[i].ip_date_online}
                                                                ip_no_online={proList[i].ip_no_online}
                                                                ip_s_kind={proList[i].ip_s_kind}
                                                                ip_auct_name={proList[i].ip_auct_name}
                                                                ip_auct_date_start={proList[i].ip_auct_date_start}
                                                                ip_auct_date_end={proList[i].ip_auct_date_end}
                                                                gm_name={proList[i].gm_name}
                                                                ip_status={proList[i].ip_status}
                                                                de_count={proList[i].de_count}
                                                                de_quantity={proList[i].de_quantity}
                                                                de_memo_count={proList[i].de_memo_count}
                                                                pindex={i}
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {/* 조회가 없을때 ▼ */}
                                {viewPaging === 0 && (
                                <div className="pagination">
                                    <button className="prev">
                                        <span>이전 페이지</span>
                                    </button>
                                    <ul className="num_box">
                                        <li className="on">
                                            <button>1</button>
                                        </li>
                                    </ul>
                                    <button className="next">
                                        <span>다음 페이지</span>
                                    </button>
                                </div>
                                )}
                                {/* 조회가 페이징보다 클때 ▼ */}
                                {viewPaging > 0 &&
                                        <Pagination 
                                        nowPage={page_no}
                                        totalCnt={viewPaging}
                                        setPerPage={perPage}
                                        moveToPage={moveToPage}

                                        />
                                }
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default ShipmentProducts;
