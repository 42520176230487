import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import LoginBox from "../../components/pc/component/LoginBox";
import MoMenu from "../../components/mo/component/MoMenu";
import txtImg from "../../images/main_slider_txt.svg";
import shipper_manual from "../../images/shipper_manual.pdf";
import merchant_manual from "../../images/merchant_manual.pdf";
import auctioneer_manual from "../../images/auctioneer_manual.pdf";
import { Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { useState, useEffect, useRef } from "react";
import { enum_api_uri } from "../../config/enum";
import util from "../../config/util";
import axios from "axios";
import ConfirmPop from "../../components/pc/component/popup/ConfirmPop";
import { confirmPop } from "../../store/popupSlice";
import { loginUser, isLogin, clearUser, dealList } from "../../store/userSlice";
import moment from "moment";
import logo from "../../images/logo.svg";
import logo_w from "../../images/mo/logo_w.svg";
import login_bg from "../../images/mo/login_bg.png";
import InputDatepicker from "../../components/pc/component/InputDatepicker";
import * as CF from "../../components/pc/component/Function";



const Main = () => {
    let today = new Date();
    const [userState, setUserState] = useState('before');
    const [dealDate, setDealDate] = useState(today);
    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(''); 
    const [codeLock, setCodeLock] = useState(false);
    const [saveId, setSaveId] = useState(false);
    const m_cert_code = enum_api_uri.m_cert_code;
    const m_sign_in = enum_api_uri.m_sign_in;
    const m_info = enum_api_uri.m_info;
    const c_deal_statistics = enum_api_uri.c_deal_statistics;
    const notice_list = enum_api_uri.notice_list;
    const user = useSelector((state) => state.user);
    const popup = useSelector((state)=>state.popup);
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const inputRef = useRef(null);
    const appToken = util.getCookie("app_token");
    const [notice, setNotice] = useState([]);

    //메인슬라이드
    SwiperCore.use([Navigation, Pagination, EffectFade]);

    const swiperOptions = {
        navigation: {
            nextEl: ".main_slider .btn_next",
            prevEl: ".main_slider .btn_prev",
        },
        pagination: {
            el: ".main_slider .slider_pagination",
            type: "fraction",
            formatFractionCurrent: function (number) {
                return ("0" + number).slice(-2);
            },
            formatFractionTotal: function (number) {
                return ("0" + number).slice(-2);
            },
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                );
            },
        },
        loop: true,
        effect: "fade",
        slideToClickedSlide: true,
    };

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    //아이디저장값 있을때 그 회원으로 아이디값 넣기
    useEffect(() => {
        if(util.getCookie("rememberId")){
            setId(util.getCookie("rememberId"));
            setSaveId(true);
        }
    },[]);

    useEffect(() => {
        if(util.getCookie("login")){
            let state = [...userState];
            state = 'after';
            setUserState(state);
        }
    },[]);

    // 로그인시 아이디저장체크
    const saveIdCheck = (e) => {
        if(e.target.checked){
            setSaveId(true);
        }else{
            setSaveId(false);
        }
    };

    // 인증번호 받기
    const getCode = () => {
        let body = {
            mem_id:id,
            mem_pw:password
        };
        axios.post(`${m_cert_code.replace(':mem_id',id)}`, body)
        .then((res) => {
            if(res.status === 200) {
                let state = [...userState];
                state = 'login';
                setUserState(state);
                setId(id);
                setPassword(password);
                // inputRef.current.focus();
            }
        })
        .catch((error) => {
            console.log(error);
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'아이디 또는 비밀번호가 일치하지 않습니다.',
                confirmPopBtn:1,
            }));
        });
        // setLoading(true);
    };

    const loginHandler = (e) => {
        e.preventDefault();
        if(!id){
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'아이디를 입력해주세요.',
                confirmPopBtn:1,
            }));
        }else if(!password){
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'비밀번호를 입력해주세요.',
                confirmPopBtn:1,
            }));
        }else{
            getCode();

            //id 앞에 @ 있으면 @ 제거후 id 저장
            let idVal = id;
            if(idVal.startsWith("@")){
                idVal = idVal.substring(1);
            }

            if(saveId){
                util.setCookie("rememberId",idVal,1);
            }else{
                util.setCookie("rememberId",idVal,-1);
            }
        }
    };

    //인증번호확인 타이머
    useEffect(() => {
        if(userState === 'login'){
            const countdown = setInterval(() => {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                        setConfirm(true);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt:'유효시간이 지났습니다. 재발송버튼을 클릭해주세요.',
                            confirmPopBtn:1,
                        }));
                        setCodeLock(true);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
            return () => clearInterval(countdown);
        }
    },[minutes, seconds, userState]);

    useEffect(()=>{
        setCodeLock(codeLock);
    },[codeLock]);

    // 인증번호 재발송
    const resendHandler = (e) => {
        e.preventDefault();
        getCode();
        setMinutes(3);
        setSeconds(0);
        setCodeLock(false);
    };

    // 로그인하기
    const afterHandler = (e) => {
        e.preventDefault();

        if(code.trim().length === 6 ){
            let body = {
                mem_id:id,
                mem_pw:password,
                code:code,
                app_token:appToken,
            };
            axios.post(`${m_sign_in}`, body)
            .then((res) => {
                if(res.status === 200) {
                    //앱일때 앱에 로그인한 id,password 값 넘겨주기
                    if(isMobile && window.flutter_inappwebview && window.flutter_inappwebview.callHandler){
                        window.flutter_inappwebview.callHandler('flutterLogined', JSON.stringify({ "id": id, "pw": password })).then(function(result) {
                            // Flutter 측에서 보내는 응답을 처리합니다.
                            console.log(result);
                        });
                    }

                    setCode('');
                    let state = [...userState];
                    state = 'after';
                    setUserState(state);
                    
                    //id 앞에 @ 있으면 @ 제거후 id 저장
                    let idVal = id;
                    if(idVal.startsWith("@")){
                        idVal = idVal.substring(1);
                    }
                    util.setCookie("login",idVal);

                    //회원정보 가져오기
                    axios.get(`${m_info.replace(':mem_id',idVal)}`)
                    .then((res)=>{
                        if(res.status === 200){
                            console.log(res.data);
                            let username = res.data.mem_nm;
                            let date = moment(res.data.last_dt).utc().format('YYYY-MM-DD HH:mm');
                            let kind = res.data.mem_kind;
                            let token = res.data.token;
                            let lnkno = res.data.mem_lnkno;
                            let mem_id = res.data.mem_id.trim();
                            //회원 mem_lnko 가 없으면 아이디를 asso_code에 넣어줌
                            if(lnkno != null){
                                if(lnkno.length === 0){
                                    dispatch(loginUser({name:username,id:idVal,kind:kind,lastDate:date,assoCode:mem_id}));
                                    dispatch(isLogin());
                                    localStorage.setItem("token",token);
                                    localStorage.setItem("auctCode",mem_id);//경매사 코드
                                }else{
                                    dispatch(loginUser({name:username,id:idVal,kind:kind,lastDate:date,assoCode:lnkno}));
                                    dispatch(isLogin());
                                    localStorage.setItem("token",token);
                                    localStorage.setItem("auctCode",lnkno);//경매사 코드
                                }
                            }
                            token = localStorage.getItem("token");
                            let today = new Date();
                            let dateString = moment(today).format('YYYYMMDD');

                            localStorage.setItem("menuToggle","false");

                            // 회원 거래내역가져오기
                            axios.get(`${c_deal_statistics}?de_date_online=${dateString}`,
                                {headers:{Authorization: `Bearer ${token}`}}
                            )
                            .then((res)=>{
                                if(res.status === 200){
                                    let deal = res.data;
                                    dispatch(dealList(deal));
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    })
                }
                if(res.status === 401) {
                    console.log("로그인 실패");
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            })
            //setLoading(true);
        }else{
            setConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'인증번호를 확인해주세요.',
                confirmPopBtn:1,
            }));
        }
    };

    // 거래내역일자변경
    const dealDateChange = (date) => {
        setDealDate(date);
    };

    useEffect(() => {
        if(util.getCookie("login")){
            let token = localStorage.getItem("token");
            let today = new Date(dealDate);
            let year = today.getFullYear();
            let month = ('0' + (today.getMonth() + 1)).slice(-2);
            let day = ('0' + today.getDate()).slice(-2);
            let dateString = year + month + day;
            axios.get(`${c_deal_statistics}?de_date_online=${dateString}`,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    let deal = res.data;
                    dispatch(dealList(deal));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            })
        }
    },[dealDate]);

    // 로그아웃
    const logoutHandler = (e) => {
        e.preventDefault();
        util.setCookie("login",id,-1);
        let state = [...userState];
        state = 'before';
        setUserState(state);
        setPassword('');
        dispatch(clearUser());
        if(!saveId){
            setId('');
        }

        localStorage.removeItem('token');
        localStorage.removeItem('auctCode');
        localStorage.removeItem('auctStartData');
        localStorage.removeItem('subList');
        localStorage.removeItem('auctProData');
        localStorage.removeItem('invoiceData');
        localStorage.removeItem('productData');
        localStorage.removeItem('autoCheck');
        localStorage.removeItem('assoCode');
        localStorage.removeItem('subDetail');
        localStorage.removeItem('menuToggle');
        localStorage.removeItem('clientList');

        //앱일때 앱에 로그아웃 값 넘겨주기
        if(isMobile && window.flutter_inappwebview && window.flutter_inappwebview.callHandler){
            window.flutter_inappwebview.callHandler('flutterLogout');
        }
    };


    //공지사항 리스트 가져오기
    const getNoticeList = () => {
        axios.get(`${notice_list}`)
        .then((res)=>{
            if(res.status === 200){
                let data = res.data;
                setNotice(data);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        })
    };

    //맨처음 공지사항 리스트 가져오기
    useEffect(()=>{
        getNoticeList();
    },[]);


    //앱자동로그인 
    useEffect(()=>{
        if(user.appMemId){
            setCode('');
            let state = [...userState];
            state = 'after';
            setUserState(state);
            
            //id 앞에 @ 있으면 @ 제거후 id 저장
            let idVal = user.appMemId;
            if(idVal.startsWith("@")){
                idVal = idVal.substring(1);
            }
            util.setCookie("login",idVal);

            //회원정보 가져오기
            axios.get(`${m_info.replace(':mem_id',idVal)}`)
            .then((res)=>{
                if(res.status === 200){
                    console.log(res.data);
                    let username = res.data.mem_nm;
                    let date = moment(res.data.last_dt).utc().format('YYYY-MM-DD HH:mm');
                    let kind = res.data.mem_kind;
                    let token = res.data.token;
                    let lnkno = res.data.mem_lnkno;
                    let mem_id = res.data.mem_id.trim();
                    //회원 mem_lnko 가 없으면 아이디를 asso_code에 넣어줌
                    if(lnkno != null){
                        if(lnkno.length === 0){
                            dispatch(loginUser({name:username,id:idVal,kind:kind,lastDate:date,assoCode:mem_id}));
                            dispatch(isLogin());
                            localStorage.setItem("token",token);
                            localStorage.setItem("auctCode",mem_id);//경매사 코드
                        }else{
                            dispatch(loginUser({name:username,id:idVal,kind:kind,lastDate:date,assoCode:lnkno}));
                            dispatch(isLogin());
                            localStorage.setItem("token",token);
                            localStorage.setItem("auctCode",lnkno);//경매사 코드
                        }
                    }
                    token = localStorage.getItem("token");
                    let today = new Date();
                    let dateString = moment(today).format('YYYYMMDD');

                    localStorage.setItem("menuToggle","false");

                    // 회원 거래내역가져오기
                    axios.get(`${c_deal_statistics}?de_date_online=${dateString}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            let deal = res.data;
                            dispatch(dealList(deal));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            })
        }
    },[user.appMemId]);



    return (<>
        {/* PC일때 */}
        {isBrowser && 
            <div className="page_main">
                <div className="main_slider_wrap">
                    <Swiper className="main_slider" {...swiperOptions}>
                        <SwiperSlide className="slide1">
                            {({ isActive }) => (
                                <div
                                    className={isActive ? "img_box on" : "img_box"}
                                ></div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="slide2">
                            {({ isActive }) => (
                                <div
                                    className={isActive ? "img_box on" : "img_box"}
                                ></div>
                            )}
                        </SwiperSlide>
                        <div className="txt_box">
                            <img src={txtImg} alt="이미지" />
                        </div>
                        <div className="btn_box">
                            <div className="btn_prev">이전</div>
                            <div className="slider_pagination"></div>
                            <div className="btn_next">다음</div>
                        </div>
                        <div className="login_cont">
                            <div className="login_box">
                                {/* PC일때 && 로그인전 */}
                                {isBrowser && userState === 'before' && (<form onSubmit={loginHandler}>
                                    <h4 className="tit">로그인<span className="txt">LOGIN</span></h4>
                                    <ul className="tp10">
                                        <li className="custom_input bm5">
                                            <input type="text" placeholder="아이디" id="id" value={id} onChange={e=> setId(e.target.value)} autoFocus={true} />
                                        </li>
                                        <li className="custom_input bm18">
                                            <input type="password" placeholder="비밀번호" id="password" value={password} onChange={e=> setPassword(e.target.value)} />
                                        </li>
                                    </ul>
                                    <div className="custom_check">
                                        <label htmlFor="saveId">
                                            <input type="checkbox" id="saveId" onChange={e => saveIdCheck(e)} checked={saveId} />
                                            <span className="check">체크</span>
                                            <span className="txt">아이디저장</span>
                                        </label>
                                    </div>
                                    <div className="tp16">
                                        {/* <button type="submit" className="btn_type w_100" disabled={loading}>인증번호받기</button> */}
                                        <button type="submit" className="btn_type w_100">인증번호받기</button>
                                        <div className="link_box">
                                            {/* <div>
                                                <Link to="/find-id" className="link_find">아이디 찾기</Link>
                                                <Link to="/find-password" className="link_find">비밀번호 찾기</Link>
                                            </div> */}
                                            <Link to="/signup" className="link_join">회원가입</Link>
                                        </div>
                                        <div className="alert_box"><span>로그인 후 더 많은 정보 이용이 가능합니다.</span></div>
                                    </div>
                                </form>)}

                                {/* PC일때 && 인증번호발송후 */}
                                {isBrowser && userState === 'login' && (<form onSubmit={afterHandler}>
                                    <h4 className="tit">로그인<span className="txt">LOGIN</span></h4>
                                    <div className="tm30">
                                        <div className="auth_box">
                                            <p>인증번호확인</p>
                                            <div className="right_box">
                                                <span>유효시간: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                                <button type="button" onClick={resendHandler}>재발송</button>
                                            </div>
                                        </div>
                                        <div className="custom_input">
                                            {!codeLock ? 
                                                <input type="text" maxLength="6" placeholder="인증번호 입력" id="code" value={code} onChange={e=> setCode(e.target.value)} autoFocus={true} />
                                                : <input type="text" maxLength="6" placeholder="인증번호 입력" id="code" value={code} onChange={e=> setCode(e.target.value)} disabled />
                                            }   
                                        </div>
                                        <div className="alert_txt"><span>발송된 인증번호를 입력해 주세요</span></div>
                                        <button type="submit" className="btn_type w_100 bm50">인증하여 로그인</button>
                                    </div>
                                </form>)}

                                {/* PC일때 && 로그인완료후 */}
                                {isBrowser && userState === 'after' && (<>
                                    <div className="blue_box">
                                        <h4 className="tit"><span className="name">{user.name}</span><span className="tag3">사용자인증</span></h4>
                                    </div>
                                    <div className="tp18">
                                        <ul className="flex">
                                            <li className="flex w_60">
                                                <p className="sub_tit rm5">거래일자</p>
                                                <div className="date_input w_65">
                                                    <InputDatepicker selectedDate={dealDate} ChangeHandler={dealDateChange} />
                                                </div>
                                            </li>
                                            <li className="flex_between w_40">
                                                <p className="sub_tit">완료</p>
                                                <p className="f_20 bold txt_sky">{user.dealList.st_11 || 0}</p>
                                            </li>
                                        </ul>
                                        <div className="deal_box">
                                            {/* 출하주,경매사 일경우 */}
                                            {user.kind === 1 || user.kind === 7 ? (
                                                <ul className="txt_ul">
                                                    <li className="bg_yellow">
                                                        <p>입하</p>
                                                        <strong>{user.dealList.st_1 || 0}</strong>
                                                    </li>
                                                    <li className="bg_blue">
                                                        <p>승인</p>
                                                        <strong>{user.dealList.st_2 || 0}</strong>
                                                    </li>
                                                    <li className="bg_blue">
                                                        <p>미승인</p>
                                                        <strong>{user.dealList.st_3 || 0}</strong>
                                                    </li>
                                                    <li className="bg_green">
                                                        <p>대기</p>
                                                        <strong>{user.dealList.st_4 || 0}</strong>
                                                    </li>
                                                    <li className="bg_green">
                                                        <p>진행</p>
                                                        <strong>{user.dealList.st_5 || 0}</strong>
                                                    </li>
                                                    <li className="bg_green">
                                                        <p>종료</p>
                                                        <strong>{user.dealList.st_6 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_purple">
                                                        <p>낙찰</p>
                                                        <strong>{user.dealList.st_7 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_purple">
                                                        <p>유찰</p>
                                                        <strong>{user.dealList.st_8 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_orange">
                                                        <p>운송대기</p>
                                                        <strong>{user.dealList.st_9 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_orange">
                                                        <p>배송</p>
                                                        <strong>{user.dealList.st_10 || 0}</strong>
                                                    </li>
                                                </ul>
                                            ): null}
                                            
                                            {/* 중도매인 일경우 */}
                                            {user.kind === 3 && (
                                                <ul className="txt_ul">
                                                    <li className="bg_green">
                                                        <p>대기</p>
                                                        <strong>{user.dealList.st_4 || 0}</strong>
                                                    </li>
                                                    <li className="bg_green">
                                                        <p>진행</p>
                                                        <strong>{user.dealList.st_5 || 0}</strong>
                                                    </li>
                                                    <li className="bg_green">
                                                        <p>종료</p>
                                                        <strong>{user.dealList.st_6 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_purple">
                                                        <p>낙찰</p>
                                                        <strong>{user.dealList.st_7 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_purple">
                                                        <p>유찰</p>
                                                        <strong>{user.dealList.st_8 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_orange">
                                                        <p>운송대기</p>
                                                        <strong>{user.dealList.st_9 || 0}</strong>
                                                    </li>
                                                    <li className="w_25 bg_orange">
                                                        <p>배송</p>
                                                        <strong>{user.dealList.st_10 || 0}</strong>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                        <div className="flex bp20">
                                            <p className="sub_tit">로그인 일시</p>
                                            <p>{user.lastDate}</p>
                                        </div>
                                        <button type="button" className="btn_type2 w_100 bm50" onClick={logoutHandler}>로그아웃</button>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </Swiper>
                </div>
                <div className="purpose_wrap">
                    <div className="cont">
                        <div className="top_tit tx_c">
                            <p className="txt">ONLINE AUCTION PURPOSE</p>
                            <p className="tit">온라인 경매의 목적</p>
                        </div>
                        <ul className="purpose_ul">
                            <li>
                                <span className="num">0 1</span>
                                <div className="img_box"></div>
                                <p className="txt">생산지 연계</p>
                                <p className="txt2">
                                    산지에서 물품정보를 <br />
                                    직접 등록을 통해 경매를 <br />
                                    진행합니다.
                                </p>
                            </li>
                            <li>
                                <span className="num">0 2</span>
                                <div className="img_box"></div>
                                <p className="txt">경매시간 절약</p>
                                <p className="txt2">
                                    경매시간이 절약되어 <br />
                                    고객의 요구 및 소리에 더욱 <br />
                                    귀를 귀울여 응대 가능합니다.
                                </p>
                            </li>
                            <li>
                                <span className="num">0 3</span>
                                <div className="img_box"></div>
                                <p className="txt">비용절감 및 품질유지</p>
                                <p className="txt2">
                                    농산물 품질유지 및 <br />
                                    물류비 절감, 출하자, 중도매인의 편익이
                                    증대됩니다.
                                </p>
                            </li>
                            <li>
                                <span className="num">0 4</span>
                                <div className="img_box"></div>
                                <p className="txt">서비스 향상</p>
                                <p className="txt2">
                                    소비자의 서비스 <br />
                                    만족도가 향상됩니다.
                                </p>
                            </li>
                        </ul>
                        <div className="down_box">
                            <div className="txt_box">
                                <h5>지금 설명서를 다운로드 받아보세요.</h5>
                                <p>
                                    온라인경매시스템의 이용방법을 다운로드 받을 수
                                    있습니다.
                                </p>
                            </div>
                            <div className="btn_box">
                                {user.kind === 1 &&
                                    <a href={shipper_manual} download className="btn_down">
                                        출하주 설명서
                                    </a>
                                }
                                {user.kind === 7 &&
                                    <a href={auctioneer_manual} download className="btn_down">
                                        경매사 설명서
                                    </a>
                                }
                                {user.kind === 3 &&
                                    <a href={merchant_manual} download className="btn_down">
                                        중도매인 설명서
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="notice_wrap">
                    <div className="cont">
                        <div className="top_tit">
                            <p className="txt">NOTICE</p>
                            <a href="https://www.kurifnv.com/cust/cust_1.asp?vBoardNum=1" className="tit" target="_blank" rel="noreferrer">
                                공지사항
                            </a>
                        </div>
                        <ul className="notice_ul">
                            {notice.map((data,i) => {
                                const date = moment(data.regdate).format("YYYY-MM-DD");
                                return (
                                    <li key={i}>
                                        <a href={`https://www.kurifnv.com/cust/cust_1_view.asp?vBoardNum=1&vRowNum=${data.rownum}`} className="ellipsis" target="_blank" rel="noreferrer">
                                            {data.title}
                                        </a>
                                        <span className="date">{date}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile && 
            <div className="page_main">
                {/* <LoginBox onShowPopup={props.onShowPopup} /> */}
                <div className={`login_box ${isMobile && userState === 'after' && 'login'}`}>
                    {/* 모바일일때 && 로그인전 */}
                    {isMobile && userState === 'before' && (<>
                        <div className="logo_box">
                            <img src={logo} alt="로고" />
                            <p>온라인 경매시스템</p>
                        </div>
                        <form onSubmit={loginHandler}>
                            <h4 className="tit">로그인<span className="txt">LOGIN</span></h4>
                            <ul className="tp10">
                                <li className="custom_input bm5">
                                    <input type="text" placeholder="아이디" id="id" value={id} onChange={e=> setId(e.target.value)} autoFocus={true} />
                                </li>
                                <li className="custom_input bm18">
                                    <input type="password" placeholder="비밀번호" id="password" value={password} onChange={e=> setPassword(e.target.value)} />
                                </li>
                            </ul>
                            <div className="custom_check">
                                <label htmlFor="saveId">
                                    <input type="checkbox" id="saveId" onChange={e => saveIdCheck(e)} checked={saveId} />
                                    <span className="check">체크</span>
                                    <span className="txt">아이디저장</span>
                                </label>
                            </div>
                            <div className="tp16">
                                {/* <button type="submit" className="btn_type w_100" disabled={loading}>인증번호받기</button> */}
                                <button type="submit" className="btn_type w_100">인증번호받기</button>
                                <div className="link_box">
                                    {/* <div>
                                        <Link to="/find-id" className="link_find">아이디 찾기</Link>
                                        <Link to="/find-password" className="link_find">비밀번호 찾기</Link>
                                    </div> */}
                                    <Link to="/signup" className="link_join">회원가입</Link>
                                </div>
                                <div className="alert_box"><span>로그인 후 더 많은 정보 이용이 가능합니다.</span></div>
                            </div>
                        </form>
                    </>)}

                    {/* 모바일일때 && 인증번호발송후 */}
                    {isMobile && userState === 'login' && (<>
                        <div className="logo_box">
                            <img src={logo} alt="로고" />
                            <p>온라인 경매시스템</p>
                        </div>
                        <form onSubmit={afterHandler}>
                            <h4 className="tit">로그인<span className="txt">LOGIN</span></h4>
                            <div className="tm30">
                                <div className="auth_box">
                                    <p>인증번호확인</p>
                                    <div className="right_box">
                                        <span>유효시간: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                        <button type="button" onClick={resendHandler}>재발송</button>
                                    </div>
                                </div>
                                <div className="custom_input">
                                    {!codeLock ? 
                                        <input type="text" maxLength="6" placeholder="인증번호 입력" id="code" value={code} onChange={e=> setCode(e.target.value)} autoFocus={true}/>
                                        : <input type="text" maxLength="6" placeholder="인증번호 입력" id="code" value={code} onChange={e=> setCode(e.target.value)} disabled />
                                    }   
                                </div>
                                <div className="alert_txt"><span>발송된 인증번호를 입력해 주세요</span></div>
                                <button type="submit" className="btn_type w_100 bm50">인증하여 로그인</button>
                            </div>
                        </form>
                    </>)}

                    {/* 모바일일때 && 로그인완료후 */}
                    {isMobile && userState === 'after' && (<>
                        <div className="m_login_box">
                            <div className="bg_img">
                                <img src={login_bg} alt="배경이미지" />
                            </div>
                            <div className="top_box">
                                <div className="flex_between">
                                    <img src={logo_w} alt="로고" />
                                    <button type="button" className="" onClick={logoutHandler}>로그아웃</button>
                                </div>
                                <p className="txt">KURI Fruits And Vegetables</p>
                                <p className="txt2"><strong>안정적인 농산물 유통시장 형성</strong>에<br/> 항상 매진 할 것을 약속드립니다.</p>
                            </div>
                            <div className="round_box">
                                <ul className="txt_ul">
                                    <li className="flex">
                                        <span className="txt">사용자정보</span>
                                        <p>{user.name}</p>
                                    </li>
                                    <li className="flex">
                                        <span className="txt">거래일자</span>
                                        <div className="date_input">
                                            <InputDatepicker selectedDate={dealDate} ChangeHandler={dealDateChange} />
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <span className="txt">완료</span>
                                        <p className="bold txt_sky">{user.dealList.st_11 || 0}</p>
                                    </li>
                                </ul>
                                <div className="deal_box">
                                    {/* 출하주,경매사 일경우 */}
                                    {user.kind === 1 || user.kind === 7 ? (
                                        <ul className="txt_ul">
                                            <li className="bg_yellow">
                                                <p>입하</p>
                                                <strong>{user.dealList.st_1 || 0}</strong>
                                            </li>
                                            <li className="bg_blue">
                                                <p>승인</p>
                                                <strong>{user.dealList.st_2 || 0}</strong>
                                            </li>
                                            <li className="bg_blue">
                                                <p>미승인</p>
                                                <strong>{user.dealList.st_3 || 0}</strong>
                                            </li>
                                            <li className="bg_green">
                                                <p>대기</p>
                                                <strong>{user.dealList.st_4 || 0}</strong>
                                            </li>
                                            <li className="bg_green">
                                                <p>진행</p>
                                                <strong>{user.dealList.st_5 || 0}</strong>
                                            </li>
                                            <li className="bg_green">
                                                <p>종료</p>
                                                <strong>{user.dealList.st_6 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_purple">
                                                <p>낙찰</p>
                                                <strong>{user.dealList.st_7 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_purple">
                                                <p>유찰</p>
                                                <strong>{user.dealList.st_8 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_orange">
                                                <p>운송대기</p>
                                                <strong>{user.dealList.st_9 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_orange">
                                                <p>배송</p>
                                                <strong>{user.dealList.st_10 || 0}</strong>
                                            </li>
                                        </ul>
                                    ): null}
                                    
                                    {/* 중도매인 일경우 */}
                                    {user.kind === 3 && (
                                        <ul className="txt_ul">
                                            <li className="bg_green">
                                                <p>대기</p>
                                                <strong>{user.dealList.st_4 || 0}</strong>
                                            </li>
                                            <li className="bg_green">
                                                <p>진행</p>
                                                <strong>{user.dealList.st_5 || 0}</strong>
                                            </li>
                                            <li className="bg_green">
                                                <p>종료</p>
                                                <strong>{user.dealList.st_6 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_purple">
                                                <p>낙찰</p>
                                                <strong>{user.dealList.st_7 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_purple">
                                                <p>유찰</p>
                                                <strong>{user.dealList.st_8 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_orange">
                                                <p>운송대기</p>
                                                <strong>{user.dealList.st_9 || 0}</strong>
                                            </li>
                                            <li className="w_25 bg_orange">
                                                <p>배송</p>
                                                <strong>{user.dealList.st_10 || 0}</strong>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <ul className="txt_ul">
                                    <li className="flex">
                                        <span className="txt">로그인 일시</span>
                                        <p>{user.lastDate}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bottom_box">
                            <div className="tit_box">
                                <p className="txt">온라인 경매시스템</p>
                                <p className="txt2">농민의 소중한 땀의 결실에 보답하기위해 최선을 다하겠습니다.</p>
                            </div>
                            <MoMenu />
                        </div>
                        <div className="call_txt">
                            <a href="tel:031-560-6162">고객센터 031-560-6162</a>
                        </div>
                    </>)}
                </div>
                {/* <div className="bottom_box">
                    <div className="tit_box">
                        <p className="txt">온라인 경매시스템</p>
                        <p className="txt2">농민의 소중한 땀의 결실에 보답하기위해 최선을 다하겠습니다.</p>
                    </div>
                    <MoMenu />
                    <div className="call_txt">
                        <a href="tel:031-560-6162">고객센터 031-560-6162</a>
                    </div>
                </div> */}
            </div>
        }

        {/* confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default Main;
