import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import util from "./config/util";
import { loginUser, isLogin, dealList } from "./store/userSlice";
import {enum_api_uri} from "./config/enum";
import moment from "moment";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import * as CF from "./components/pc/component/Function";
import { confirmPop } from "./store/popupSlice";
import { clearUser } from "./store/userSlice";
import ConfirmPop from "./components/pc/component/popup/ConfirmPop";
import Layout from "./components/pc/layout/Layout";
import AppLogin from "./pages/mo/AppLogin";
import Main from "./pages/pc/Main";
import SignUp from "./pages/pc/SignUp";
import FindId from "./pages/pc/FindId";
import FindPassword from "./pages/pc/FindPassword";
import Print from "./pages/pc/Print";
import InvoiceRegister from "./pages/pc/shipper/InvoiceRegister";
import InvoiceCheck from "./pages/pc/shipper/InvoiceCheck";
import InvoiceCheckDetail from "./pages/pc/shipper/InvoiceCheckDetail";
import OnlineCheck from "./pages/pc/shipper/OnlineCheck";
import OnlineCheckDetail from "./pages/pc/shipper/OnlineCheckDetail";
import Auction from "./pages/pc/merchant/Auction";
import AuctionDetail from "./pages/pc/merchant/AuctionDetail";
import ShipmentProducts from "./pages/pc/merchant/ShipmentProducts";
import ShipmentProductsDetail from "./pages/pc/merchant/ShipmentProductsDetail";
import OnlineBid from "./pages/pc/merchant/OnlineBid";
import OnlineBidDetail from "./pages/pc/merchant/OnlineBidDetail";
import BidProducts from "./pages/pc/merchant/BidProducts";
import BidProductsDetail from "./pages/pc/merchant/BidProductsDetail";
import Receiving from "./pages/pc/auctioneer/Receiving";
import OnlineAuction from "./pages/pc/auctioneer/OnlineAuction";
import OnlineAuctionDetail from "./pages/pc/auctioneer/OnlineAuctionDetail";
import BidResult from "./pages/pc/auctioneer/BidResult";
import Statistics from "./pages/pc/auctioneer/Statistics";
import StatisticsPdf from "./pages/pc/auctioneer/StatisticsPdf";
import Popup from "./components/pc/component/popup/Popup";
import MoReceiving from "./pages/mo/auctioneer/MoReceiving";
import MoReceivingDetail from "./pages/mo/auctioneer/MoReceivingDetail";
import { SocketProvider } from "./components/SocketProvider";
import "./css/pc/main.css";
import "./css/pc/form.css";
import "./css/pc/content.css";
import "./css/mo/main.css";
import "./css/mo/form.css";
import "./css/mo/content.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

function App() {
    const m_info = enum_api_uri.m_info;
    const c_deal_statistics = enum_api_uri.c_deal_statistics;
    const user = useSelector((state) => state.user);
    const common = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const [id, setId] = useState('');

    //페이지이동시 스크롤탑으로 이동 (상세->목록으로 뒤로가기시 제외)
    useEffect(()=>{
        if(!common.detailPageBack){
            window.scrollTo(0,0);
        }
    },[location]);

    // 로그인되어있을시 회원정보 가져오기
    useEffect(() => {
        if(util.getCookie("login")){
            let userid = util.getCookie("login");
            dispatch(isLogin());

            axios.get(`${m_info.replace(':mem_id',userid)}`)
            .then((res)=>{
                if(res.status === 200){
                    let username = res.data.mem_nm;
                    let date = moment(res.data.last_dt).utc().format('YYYY-MM-DD HH:mm');
                    let kind = res.data.mem_kind;
                    let token = res.data.token;
                    let lnkno = res.data.mem_lnkno;
                    dispatch(loginUser({name:username,id:userid,kind:kind,lastDate:date,assoCode:lnkno}));
                    localStorage.setItem("token",token);

                    token = localStorage.getItem("token");
                    let today = new Date();
                    let dateString = moment(today).format('YYYYMMDD');

                    // 회원 거래내역가져오기
                    axios.get(`${c_deal_statistics}?de_date_online=${dateString}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            let deal = res.data;
                            dispatch(dealList(deal));
                            
                        }
                    })
                    .catch((error) => {
                        // const err_msg = CF.errorMsgHandler(error);
                        // dispatch(confirmPop({
                        //     confirmPopTit:'알림',
                        //     confirmPopTxt: err_msg,
                        //     confirmPopBtn:1,
                        // }));
                        // setConfirm(true);

                        //로그아웃
                        util.setCookie("login",id,-1);
                        dispatch(clearUser());

                        localStorage.removeItem('token');
                        localStorage.removeItem('auctCode');
                        localStorage.removeItem('auctStartData');
                        localStorage.removeItem('subList');
                        localStorage.removeItem('auctProData');
                        localStorage.removeItem('invoiceData');
                        localStorage.removeItem('productData');
                        localStorage.removeItem('autoCheck');
                        localStorage.removeItem('assoCode');
                        localStorage.removeItem('subDetail');
                        localStorage.removeItem('menuToggle');
                        localStorage.removeItem('clientList');

                        //메인페이지로 이동후 새로고침
                        navigate("/");
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                // const err_msg = CF.errorMsgHandler(error);
                // dispatch(confirmPop({
                //     confirmPopTit:'알림',
                //     confirmPopTxt: err_msg,
                //     confirmPopBtn:1,
                // }));
                // setConfirm(true);

                //로그아웃
                util.setCookie("login",id,-1);
                dispatch(clearUser());

                localStorage.removeItem('token');
                localStorage.removeItem('auctCode');
                localStorage.removeItem('auctStartData');
                localStorage.removeItem('subList');
                localStorage.removeItem('auctProData');
                localStorage.removeItem('invoiceData');
                localStorage.removeItem('productData');
                localStorage.removeItem('autoCheck');
                localStorage.removeItem('assoCode');
                localStorage.removeItem('subDetail');
                localStorage.removeItem('menuToggle');
                localStorage.removeItem('clientList');

                //메인페이지로 이동후 새로고침
                navigate("/");
                window.location.reload();
            })
        }
    },[]);
        
    return (
        <SocketProvider>
            <div id="wrap" className={isBrowser ? 'pc_wrap' : 'mo_wrap'}>
                <Routes>

                    {/* 앱 자동로그인 */}
                    <Route path="/app" element={<AppLogin />} />

                    {/* 메인 */}
                    <Route path="/" element={<Layout><Main /></Layout>} />

                    {/* 회원가입 */}
                    <Route path="/signup" element={<Layout><SignUp login={'after'} /></Layout>} /> 

                    {/* 아이디 찾기 */}
                    <Route path="/find-id" element={<Layout><FindId login={'after'} /></Layout>} />

                    {/* 비밀번호 찾기 */}
                    <Route path="/find-password" element={<Layout><FindPassword login={'after'} /></Layout>} />

                    {/* 경매사 - 송품장출력 페이지 */}
                    <Route path="/print/:ip_date_online/:ip_no_online" element={<Print/>} />

                    {/* 경매사 - 통계 pdf다운페이지 */}
                    <Route path="/auctioneer/statistics/pdf/:type/:date_start/:date_end/:code" element={<StatisticsPdf />} />

                    {/* 출하주 shipper--------------------------------------------------------------*/}
                    {user.isLogin === true && user.kind === 1 ? (
                        <Route path="/shipper" element={<Layout><Outlet user={user.kind} /></Layout>}>
                        
                            {/* 송품장 작성 */}
                            <Route path="invoice-register" element={<InvoiceRegister user={'shipper'} />} />

                            {/* 송품장 조회 */}
                            <Route path="invoice-check" element={<InvoiceCheck user={'shipper'} />} />
                            <Route path="invoice-check/detail/:invoiceId" element={<InvoiceCheckDetail user={'shipper'} />} />

                            {/* 온라인거래결과 조회 */}
                            <Route path="online-check" element={<OnlineCheck user={'shipper'} />} />
                            <Route path="online-check/detail/:invoiceId" element={<OnlineCheckDetail user={'shipper'} />} />
                        </Route>
                    ):null}

                    {/* 경매사 auctioneer --------------------------------------------------------------*/}
                    {user.isLogin === true && user.kind === 7 ? (
                        <Route path="/auctioneer" element={<Layout><Outlet user={user.kind} /></Layout>}>
                            {/* 입하작업 */}
                            <Route path="receiving" element={<Receiving user={'auctioneer'} />} />

                            {/* 모바일일때 입하작업리스트 */}
                            <Route path="mo/receiving" element={<MoReceiving user={'auctioneer'} />} />
                            {/* 모바일일때 입하작업상세 */}
                            <Route path="mo/receiving/detail/:invoiceId" element={<MoReceivingDetail user={'auctioneer'} />} />

                            {/* 온라인 경매 */}
                            <Route path="online-auction/" element={<OnlineAuction user={'auctioneer'} />} />
                            <Route path="online-auction/:auct_code/:auct_date" element={<OnlineAuctionDetail user={'auctioneer'} />} />

                            {/* 경매입찰결과 */}
                            <Route path="bid-result" element={<BidResult user={'auctioneer'} />} />

                            {/* 통계 */}
                            <Route path="statistics" element={<Statistics user={'auctioneer'} />} />
                        </Route>
                    ):null}

                    {/* 중도매인 merchant --------------------------------------------------------------*/}
                    {user.isLogin === true && user.kind === 3 ? (
                        <Route path="/merchant" element={<Layout><Outlet user={user.kind} /></Layout>}>
                            {/* 온라인 경매 */}
                            <Route path="auction" element={<Auction user={'merchant'} />} />
                            <Route path="auction/:auct_code/:auct_date" element={<AuctionDetail user={'merchant'} />} />

                            {/* 출하물품 조회 */}
                            <Route path="shipment-products" element={<ShipmentProducts user={'merchant'} />} />
                            <Route path="shipment-products/detail/:spId" element={<ShipmentProductsDetail user={'merchant'} />} />
                            
                            {/* 온라인입찰 */}
                            <Route path="online-bid" element={<OnlineBid user={'merchant'} />} />
                            <Route path="online-bid/detail/:obId" element={<OnlineBidDetail user={'merchant'} />} />

                            {/* 낙찰물품 조회 */}
                            <Route path="bid-products" element={<BidProducts user={'merchant'} />} />
                            <Route path="bid-products/detail/:bpId/:seq/:seq2" element={<BidProductsDetail user={'merchant'} />} />
                        </Route>
                    ):null}

                    {/* <Route path="*" element={<div>404페이지</div>} /> */}
                    {/* <Route path="/shipper/*" element={<Navigate to="/"></Navigate>} /> */}
                </Routes>
                <Popup />

                {/* 확인confirm팝업 */}
                {confirm && <ConfirmPop />}
            </div>
        </SocketProvider>
    );
}

export default App;
