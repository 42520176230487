import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import {enum_api_uri, debounce, debounce2,enum_de_status,enum_mo_title,enum_is_status} from "../../../config/enum";
import { ToastContainer, toast } from 'react-toastify';
import {AuctProductInfo} from "../../../components/pc/component/ProductList";
import Pagination from "../../../components/pc/component/Pagination";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import LeftCont from "../../../components/pc/component/LeftCont";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { isMobile, isBrowser } from 'react-device-detect';
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { confirmPop } from "../../../store/popupSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";

const Auction = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    // const [de_gods_code, set_gods_code] = useState(null);
    const [gmStmdName, setGmStmdName] =  useState([]);
    const [gmNameInput, setGmNameInput] = useState('');
    const [gmCodeInput, setGmCodeInput] = useState('');
    const basicDate = new Date();
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [dateError, setDateError] = useState(false);
    const [gmNameInputClick, setGmNameInputClick] =  useState(false);
    const [proList, setProList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const [gmName, setGmName] =  useState([]);
    const [gmNameDrop, setGmNameDrop] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const location = useLocation();
    const m_list_auction = enum_api_uri.m_list_auction;
    const c_search_gm_name = enum_api_uri.c_search_gm;
    const c_search_gm_stmd_name = enum_api_uri.c_search_gm_stmd;
    registerLocale('ko', ko);
    const popup = useSelector((state) => state.popup);
    const common = useSelector((state) => state.common);
    const [confirm, setConfirm] = useState(false);
    const [scrollMove, setScrollMove] = useState(false);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);

        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(() => {
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
    },[viewPaging]);


    useEffect(()=>{ //처음 한번 리스트 불러오기a1

        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);


    //품명그룹 조회
    useEffect(() => {
        setGmNameInput(gmNameInput);
        // console.log(user.token)
        if(gmNameInputClick){
            if(gmNameInput.length > 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_gm_stmd_name}?gm_stmd=${gmNameInput}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setGmStmdName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerAxios2 = setTimeout(() => {
                    axios.get(`${c_search_gm_name}?gm_name=${gmNameInput}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setGmName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setGmNameDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerAxios2,timerDrop);
            }else{
                setGmNameDrop(false);
                setGmNameInputClick(false);
            }
        }
    },[gmNameInput]);


    //경매목록 조회 버튼 클릭시
    const submit = async (values) => {
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');

        let val = [];
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };
    
    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };
    

    //리스트 받아오기(현재 페이지 공통)
    const getList = async(val) =>{
        axios.get(`${m_list_auction}?ip_auct_date_start=${val.ip_date_start}&ip_auct_date_end=${val.ip_date_end}&de_gods_code=&page_no=${val.page_no_now}&record_size=10`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            console.log(res.data);
            if(res.status === 200){
                const data = res.data
                let state = [...data.results];
                let resCount = data.total_record_count;
                setProList(state); //리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    }


    //검색폼 초기화
    const clearForm = (resetForm)=>{
        let reset = ''
        setGmNameInput(reset)
        setGmCodeInput(reset)
        resetForm()
    }


    const resultData = {
        head: ["등록번호","품목명","중량","규격","친환경","등급","수량","낙찰","상태"],
    };

    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">온라인 경매 - 경매목록</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>경매목록 조회</li>
                                </ul>
                            </div>
                            <Formik
                                initialValues={{
                                    de_gods_code: "",
                                }}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                            {({values, setFieldValue, handleSubmit, errors, touched,resetForm}) => (
                            <form onSubmit={handleSubmit}>
                                <ul className="search_box">
                                    <li>
                                        <p className="txt">출하일자</p>
                                        <div className="date_input">
                                            <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                            <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                        </div>
                                    </li>
                                    {/* <li className="item_li">
                                        <p className="txt">품목</p>
                                        <div className="flex_between">
                                            <div className="flex">
                                                <div className="drop_wrap rm5">
                                                    <div className="custom_input2">
                                                        <input type="text" value={gmNameInput || ""} onClick={() => {setGmNameInputClick(true)}} onChange={(e) => {
                                                            setGmNameInput(e.target.value);
                                                        }} />
                                                    </div>
                                                    {gmNameDrop && (<>
                                                        <div className="dim" onClick={() => {setGmNameDrop(false)}}></div>
                                                        <div className="drop_box">
                                                            <ul className="list_ul2">
                                                                {gmStmdName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품목]</p>
                                                                        <ul className="list_ul">
                                                                            {gmStmdName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    let name = e.currentTarget.innerText;
                                                                                    let bracket = name.lastIndexOf('(');
                                                                                    name = name.slice(0,bracket);
                                                                                    setGmNameInput(name);
                                                                                    setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품명]</p>
                                                                        <ul className="list_ul">
                                                                            {gmName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    let name = e.currentTarget.innerText;
                                                                                    let bracket = name.lastIndexOf('(');
                                                                                    name = name.slice(0,bracket);
                                                                                    setGmNameInput(name);
                                                                                    setGmCodeInput(gmName[i].gm_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmName[i].gm_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                                </div>
                                                <div className="custom_input2 w_40">
                                                    <input type="text" value={gmCodeInput} readOnly />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <button type="button" className="btn_s rm5" onClick = {()=>clearForm(resetForm)}>선택 초기화</button>
                                                <button type="submit" className="btn_s2">조회</button>
                                            </div>
                                        </div>
                                    </li> */}
                                    <li><button type="submit" className="btn_s">조회</button></li>
                                </ul>
                                {dateError && 
                                    <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                                }
                            </form>
                        )}
                        </Formik>
                            <div className="tm30">
                                <p className="medium bp10">검색 결과 : <span className="txt_sky">{proList.length}</span>건</p>
                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_data"><span>데이터가 없습니다.</span></div>
                                )}

                                {/* 등록물품 있을때 ▼ */}
                                {proList.length > 0 && proList.map((listVals,idx) => {

                                    // let auctStartTime = CF.MDFWithHour(listVals.ip_auct_date_start,2);
                                    // let auctEndTime = CF.MDFWithHour(listVals.ip_auct_date_end,2);

                                    let auctStartTime = CF.MDFWithHour(listVals.auct_dt,2);
                                    let statusName = enum_is_status[listVals.auct_status.trim()][0];
                                    let btnClassName = "";
                                    listVals.auct_status = listVals.auct_status.trim();
                                    let linkUrl = '/merchant/auction/';
                                    let linkCode = listVals.auct_code + '/' + listVals.auct_dt;
  
                                    return (
                                    <div  key = {idx}>
                                        <div className="flex_between bp10">
                                            <div className="flex">
                                                <span className="tag">경매사 : {listVals.is_name}</span>
                                                <p className="f_15 bold lm7">경매시간 : {auctStartTime}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="f_17 bold rm10">{statusName}</p>
                                                {listVals.auct_status < 3 ? <div className="btn_s3">경매참가</div>
                                                : listVals.auct_status === "3" || listVals.auct_status === "4" ? <Link to={`${linkUrl}${linkCode}`} className="btn_s" onClick={()=>{CF.setScrollY(window.scrollY)}}>경매참가</Link>
                                                : listVals.auct_status === "5" && <Link to={`${linkUrl}${linkCode}`} className="btn_s2" onClick={()=>{CF.setScrollY(window.scrollY)}}>내역보기</Link>
                                                }
                                            </div>
                                        </div>
                                        <div className="custom_table s_table bp20">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'120px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'70px'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'auto'}} />
                                                    <col style={{width:'70px'}} />
                                                    <col style={{width:'60px'}} />
                                                    <col style={{width:'100px'}} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        {resultData.head.map(data => {
                                                            return <th key={data.toString()}>{data}</th>
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listVals.detltrns.map((subVals,ids) => {
                                                        let subCode = subVals.de_date_online+'-'+subVals.de_no_online
                                                        let auct_status = enum_de_status[subVals.de_status.trim()][0];
                                                        const my_code = localStorage.getItem("auctCode");
                                                        let de_amer_code = subVals.de_amer_code;
                                                        if(de_amer_code === my_code){
                                                            de_amer_code = '낙찰';
                                                        }else{
                                                            de_amer_code = '-';
                                                        }
                                                        return(
                                                            <tr  key = {ids}>
                                                                <td>{subCode}</td>
                                                                <td><div className="over_txt"><span>{subVals.gm_name == null ? subVals.gm_stmd_name : subVals.gm_name}</span></div></td>
                                                                <td className="tx_r">{CF.MakeIntComma(subVals.de_weight)}kg</td>
                                                                <td>{subVals.de_packing_name}</td>
                                                                <td>{subVals.de_quality_name}</td>
                                                                <td>{subVals.de_high_name}</td>
                                                                <td className="tx_r">{CF.MakeIntComma(subVals.de_quantity)}개</td>
                                                                <td>{de_amer_code}</td>
                                                                <td>{auct_status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    )
                                })}
                                {/* 조회가 없을때 ▼ */}
                                {viewPaging === 0 && (
                                <div className="pagination">
                                    <button className="prev">
                                        <span>이전 페이지</span>
                                    </button>
                                    <ul className="num_box">
                                        <li className="on">
                                            <button>1</button>
                                        </li>
                                    </ul>
                                    <button className="next">
                                        <span>다음 페이지</span>
                                    </button>
                                </div>
                                )}
                                {/* 조회가 페이징보다 클때 ▼ */}
                                {viewPaging > 0 &&
                                        <Pagination 
                                        nowPage={page_no}
                                        totalCnt={viewPaging}
                                        setPerPage={perPage}
                                        moveToPage={moveToPage}

                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        de_gods_code: "",
                    }}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                    {({values, setFieldValue, handleSubmit, errors, touched,resetForm}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="search_box">
                            <ul>
                                <li>
                                    <p className="txt">출하일자</p>
                                    <div className="date_input">
                                        <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                        <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                    </div>
                                </li>
                                {/* <li className="item_li">
                                    <p className="txt">품목</p>
                                    <div className="flex_between">
                                        <div className="flex">
                                            <div className="drop_wrap rm5">
                                                <div className="custom_input">
                                                    <input type="text" value={gmNameInput || ""} onClick={() => {setGmNameInputClick(true)}} onChange={(e) => {
                                                        setGmNameInput(e.target.value);
                                                    }} />
                                                </div>
                                                {gmNameDrop && (<>
                                                    <div className="dim" onClick={() => {setGmNameDrop(false)}}></div>
                                                    <div className="drop_box">
                                                        <ul className="list_ul2">
                                                            {gmStmdName.length > 0 && (
                                                                <li>
                                                                    <p className="txt">[품목]</p>
                                                                    <ul className="list_ul">
                                                                        {gmStmdName.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let name = e.currentTarget.innerText;
                                                                                let bracket = name.lastIndexOf('(');
                                                                                name = name.slice(0,bracket);
                                                                                setGmNameInput(name);
                                                                                setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                setGmNameDrop(false);
                                                                                setGmNameInputClick(false);
                                                                            }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                            {gmName.length > 0 && (
                                                                <li>
                                                                    <p className="txt">[품명]</p>
                                                                    <ul className="list_ul">
                                                                        {gmName.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let name = e.currentTarget.innerText;
                                                                                let bracket = name.lastIndexOf('(');
                                                                                name = name.slice(0,bracket);
                                                                                setGmNameInput(name);
                                                                                setGmCodeInput(gmName[i].gm_code.trim());
                                                                                setGmNameDrop(false);
                                                                                setGmNameInputClick(false);
                                                                            }}>{gmName[i].gm_name.trim()}</li>;
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                            {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                        </ul>
                                                    </div>
                                                </>)}
                                            </div>
                                            <div className="custom_input w_45">
                                                <input type="text" value={gmCodeInput} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                            {/* <div className="btn_half_box tp10">
                                <button type="button" className="btn_s rm5"  onClick = {()=>clearForm(resetForm)}>선택 초기화</button>
                                <button type="submit" className="btn_s2">조회</button>
                            </div> */}
                            <button type="submit" className="btn_s w_100 tm10">조회</button>
                        </div>
                        {dateError && 
                            <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                        }
                    </form>
                )}
                </Formik>
                <div className="tm30">
                    <p className="medium bp10 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                    {/* 등록물품 없을때 ▼ */}
                    {proList.length === 0 && (
                        <div className="none_data"><span>데이터가 없습니다.</span></div>
                    )}

                    {/* 등록물품 있을때 ▼ */}
                    {proList.length > 0 && proList.map((listVals,idx) => {

                        // let auctStartTime = CF.MDFWithHour(listVals.ip_auct_date_start,2);
                        // let auctEndTime = CF.MDFWithHour(listVals.ip_auct_date_end,2);

                        let auctStartTime = CF.MDFWithHour(listVals.auct_dt,2);
                        let statusName = enum_de_status[listVals.auct_status.trim()][0];
                        let btnClassName = "";
                        if(listVals.auct_status.trim() == 6){
                            btnClassName = "btn_s";
                        }else{
                            btnClassName = "btn_s3";
                        }
                        let linkUrl = '/merchant/auction/';
                        let linkCode = listVals.auct_code + '/' + listVals.auct_dt;


                        return (
                            <div className="bp20">
                                <div className="custom_table2 input_table bm10">
                                    <table>
                                        <colgroup>
                                            <col style={{width:'23%'}} />
                                            <col style={{width:'27%'}} />
                                            <col style={{width:'23%'}} />
                                            <col style={{width:'27%'}} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>경매사</th>
                                                <td><span className="tag">{listVals.is_name}</span></td>
                                                <th>상태</th>
                                                <td className="tx_c">
                                                    {listVals.auct_status < 3 ? <div className="btn_s3 h_24">경매참가</div>
                                                        : listVals.auct_status === "3" || listVals.auct_status === "4" ? <Link to={`${linkUrl}${linkCode}`} className="btn_s h_24" onClick={()=>{CF.setScrollY(window.scrollY)}}>경매참가</Link>
                                                        : listVals.auct_status === "5" && <Link to={`${linkUrl}${linkCode}`} className="btn_s2 h_24" onClick={()=>{CF.setScrollY(window.scrollY)}}>내역보기</Link>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매시간</th>
                                                <td colSpan="3">{auctStartTime}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {listVals.detltrns.map((subVals,ids) => {
                                    let subCode = subVals.de_date_online+'-'+subVals.de_no_online
                                    let auct_status = enum_de_status[subVals.de_status.trim()][0];
                                    const my_code = localStorage.getItem("auctCode");
                                    let de_amer_code = subVals.de_amer_code;
                                    if(de_amer_code === my_code){
                                        de_amer_code = '낙찰';
                                    }else{
                                        de_amer_code = '-';
                                    }
                                    // console.log(subVals.de_status +":"+auct_status);
                                    return(
                                        <div className="custom_table s_table bp10">
                                            <table>
                                                <colgroup>
                                                    <col style={{width:'23%'}} />
                                                    <col style={{width:'27%'}} />
                                                    <col style={{width:'23%'}} />
                                                    <col style={{width:'27%'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th className="tx_l">등록번호</th>
                                                        <td colSpan="3" className="tx_l">{subCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tx_l">경매시간</th>
                                                        <td colSpan="3" className="tx_l">{auctStartTime}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tx_l">품목명</th>
                                                        <td colSpan="3" className="tx_l">{subVals.gm_name === null ? subVals.gm_stmd_name : subVals.gm_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tx_l">수량</th>
                                                        <td className="tx_r">{CF.MakeIntComma(subVals.de_quantity)}개</td>
                                                        <th className="tx_l">낙찰</th>
                                                        <td className="tx_l">{de_amer_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tx_l">상태</th>
                                                        <td colSpan="3" className="tx_l">{auct_status}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    {/* 조회가 없을때 ▼ */}
                    {viewPaging === 0 && (
                    <div className="pagination">
                        <button className="prev">
                            <span>이전 페이지</span>
                        </button>
                        <ul className="num_box">
                            <li className="on">
                                <button>1</button>
                            </li>
                        </ul>
                        <button className="next">
                            <span>다음 페이지</span>
                        </button>
                    </div>
                    )}
                    {/* 조회가 페이징보다 클때 ▼ */}
                    {viewPaging > 0 &&
                            <Pagination 
                            nowPage={page_no}
                            totalCnt={viewPaging}
                            setPerPage={perPage}
                            moveToPage={moveToPage}

                            />
                    }
                </div>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default Auction;