import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { enum_api_uri, debounce, debounce2 } from "../../../../config/enum";
import { newProductAdd, closePop, productPopModifyClose, confirmPop, newProductPop } from "../../../../store/popupSlice";
import { proModifyData } from "../../../../store/invoiceSlice";
import { isBrowser, isMobile } from "react-device-detect";
import { NumericFormat } from 'react-number-format';
import * as CF from "../../../../components/pc/component/Function"; // (customFunction)
import ConfirmPop from "./ConfirmPop";

const NewProductPop = () => {
    const [confirm, setConfirm] = useState(false);
    const [image1Src, setImage1Src] = useState('');
    const [image2Src, setImage2Src] = useState('');
    const [videoSrc, setVideoSrc] = useState('');
    const [farmNameDrop, setFarmNameDrop] = useState(false);
    const [farmNameInput, setFarmNameInput] = useState('');
    const [farmNames, setFarmNames] = useState([]);
    const [farmNameInputClick, setFarmNameInputClick] =  useState(false);
    const [farmCodeDrop, setFarmCodeDrop] = useState(false);
    const [farmCodeInput, setFarmCodeInput] = useState('');
    const [farmCode, setFarmCode] = useState([]);
    const [farmCodeInputClick, setFarmCodeInputClick] =  useState(false);
    const [farmAddInput, setFarmAddInput] = useState('');
    const [gmCodeInput, setGmCodeInput] = useState('');
    const [gmNameInput, setGmNameInput] = useState('');
    const [gmNameDrop, setGmNameDrop] = useState(false);
    const [gmStmdName, setGmStmdName] =  useState([]);
    const [gmName, setGmName] =  useState([]);
    const [gmNameInputClick, setGmNameInputClick] =  useState(false);
    const [packingInput, setPackingInput] = useState('');
    const [packingCode, setPackingCode] = useState('');
    const [packingDrop, setPackingDrop] = useState(false);
    const [packing, setPacking] =  useState([]);
    const [packingInputClick, setPackingInputClick] =  useState(false);
    const [qualityInput, setQualityInput] = useState('');
    const [qualityCode, setQualityCode] = useState('');
    const [qualityDrop, setQualityDrop] = useState(false);
    const [quality, setQuality] =  useState([]);
    const [qualityInputClick, setQualityInputClick] =  useState(false);
    const [highInput, setHighInput] = useState('');
    const [highCode, setHighCode] = useState('');
    const [highDrop, setHighDrop] = useState(false);
    const [high, setHigh] =  useState([]);
    const [highInputClick, setHighInputClick] =  useState(false);
    const [img1, setImg1] =  useState('');
    const [img2, setImg2] =  useState('');
    const [video, setVideo] =  useState('');
    const [dropLoading, setDropLoading] = useState(false);
    const popup = useSelector((state) => state.popup);
    const invoice = useSelector((state) => state.invoice);
    const dispatch = useDispatch();
    const api_uri = enum_api_uri.api_uri;
    const p_create_product = enum_api_uri.p_create_product;
    const p_modify_product = enum_api_uri.p_modify_product;
    const c_search_farms = enum_api_uri.c_search_farms;
    const c_farmmast = enum_api_uri.c_farmmast;
    const c_search_gm_stmd = enum_api_uri.c_search_gm_stmd;
    const c_search_gm = enum_api_uri.c_search_gm;
    const c_search_origin = enum_api_uri.c_search_origin;
    const p_upload_media = enum_api_uri.p_upload_media;
    const token = localStorage.getItem("token");

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    const closePopHandler = () => {
        // dispatch(newProductPop(null));
        dispatch(closePop());
    }

    useEffect(()=>{
        setDropLoading(dropLoading);
    },[dropLoading]);

    useEffect(() => {
        setFarmNameInputClick(farmNameInputClick);
    },[farmNameInputClick]);

    useEffect(() => {
        setFarmCodeInputClick(farmCodeInputClick);
    },[farmCodeInputClick]);


    //생산자명 검색
    useEffect(() => {
        setFarmNameInput(farmNameInput);
        if(farmNameInputClick){
            if(farmNameInput.length > 0){
                setDropLoading(true);
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_farms}?ft_farm=${farmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setFarmNames(res.data);
                            setDropLoading(false);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setFarmNameDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setFarmNameDrop(false);
            }
        }
    },[farmNameInput]);


    //생산자코드 검색
    useEffect(() => {
        setFarmCodeInput(farmCodeInput);
        if(farmCodeInputClick){
            if(farmCodeInput.length > 0){
                setDropLoading(true);
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_farms}?ft_farm=${farmCodeInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setFarmCode(res.data);
                            setDropLoading(false);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setFarmCodeDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setFarmCodeDrop(false);
            }
        }
    },[farmCodeInput]);

    //신규생산자 등록
    useEffect(() => {
        setFarmAddInput(farmAddInput);
    },[farmAddInput]);

    const farmAddHandler = (e) => {
        e.preventDefault();
        let body = {
            ft_farm_name:farmAddInput
        };
        axios.post(`${c_farmmast}`,body,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                setFarmAddInput('');

                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: '생산자 신규등록이 완료되었습니다. 생산자를 다시검색해주세요.',
                    confirmPopBtn:1,
                }));
                setConfirm(true);

                setFarmNameInputClick(false);
                setFarmCodeInputClick(false);
                setTimeout(()=>{
                    setFarmNameDrop(false);
                    setFarmCodeDrop(false);
                },200);

                setFarmNameInput('');
                setFarmCodeInput('');
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //품명그룹 검색
    useEffect(() => {
        setGmNameInput(gmNameInput);
        if(gmNameInputClick){
            if(gmNameInput.length > 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_gm_stmd}?gm_stmd=${gmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setGmStmdName(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerAxios2 = setTimeout(() => {
                    axios.get(`${c_search_gm}?gm=${gmNameInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setGmName(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setGmNameDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerAxios2,timerDrop);
            }else{
                setGmNameDrop(false);
            }
        }
    },[gmNameInput]);

    //규격 검색
    useEffect(() => {
        setPackingInput(packingInput);
        if(packingInputClick){
            if(packingInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=D&search_word=${packingInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setPacking(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setPackingDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setPackingDrop(false);
            }
        }
    },[packingInput,packingInputClick]);

    //친환경 검색
    useEffect(() => {
        setQualityInput(qualityInput);
        if(qualityInputClick){
            if(qualityInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=F&search_word=${qualityInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setQuality(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setQualityDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setQualityDrop(false);
            }
        }
    },[qualityInput,qualityInputClick]);

    //등급 검색
    useEffect(() => {
        setHighInput(highInput);
        if(highInputClick){
            if(highInput.length >= 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_origin}?et_kind=E&search_word=${highInput}`,
                        {headers:{Authorization: `Bearer ${token}`}}
                    )
                    .then((res)=>{
                        if(res.status === 200){
                            setHigh(res.data);
                        }
                    })
                    .catch((error) => {
                        const err_msg = CF.errorMsgHandler(error);
                        dispatch(confirmPop({
                            confirmPopTit:'알림',
                            confirmPopTxt: err_msg,
                            confirmPopBtn:1,
                        }));
                        setConfirm(true);
                    });
                },debounce);
                const timerDrop = setTimeout(() => {
                    setHighDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerDrop);
            }else{
                setHighDrop(false);
            }
        }
    },[highInput,highInputClick]);


    //미디어등록
    const img1Up = (fileBlob, postData) => {
        const formData = new FormData();
        formData.append("media", postData.target.files[0]);
        axios.post(`${p_upload_media}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.status === 200) {
                setImg1(res.data.mediaUrl);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve) => {
          reader.onload = () => {
            setImage1Src(reader.result);
            resolve();
          };
        });
    };

    const img2Up = (fileBlob, postData) => {
        const formData = new FormData();
        formData.append("media", postData.target.files[0]);
        axios.post(`${p_upload_media}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.status === 200) {
                setImg2(res.data.mediaUrl);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve) => {
          reader.onload = () => {
            setImage2Src(reader.result);
            resolve();
          };
        });
    };

    const videoUp = (fileBlob,postData) => {
        const formData = new FormData();
        formData.append("media", postData.target.files[0]);
        axios.post(`${p_upload_media}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.status === 200) {
                setVideo(res.data.mediaUrl);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve) => {
          reader.onload = () => {
            setVideoSrc(reader.result);
            resolve();
          };
        });
    };

    // 물품추가 취소
    const cancelProduct = () => {
        setFarmNameInput('');
        setFarmCodeInput('');
        setGmCodeInput('');
        setGmNameInput('');
        setPackingInput('');
        setQualityInput('');
        setHighInput('');
        setImage1Src('');
        setImg1('');
        setImage2Src('');
        setImg2('');
        setVideoSrc('');
        setVideo('');
    };

    // 물품추가등록 유효성체크
    const newProductPopValid = Yup.object().shape({
        // de_farm_name: Yup.string()
        //     .required("아이디를 입력해주세요."),
    });

    // 물품추가 등록
    const newProductPopSubmit = async (values) => {
        let getData = localStorage.getItem("invoiceData");
            getData = JSON.parse(getData);
        let ip_date_online = getData.ip_date_online;
        let ip_no_online = getData.ip_no_online;
        
        let regex = ",";
        let de_weight = values.de_weight.toString().replace(regex,'');

        let body = {
            de_gods_code:gmCodeInput,
            de_s_kind:getData.ip_s_kind,
            de_kind:getData.ip_kind,
            de_loca:getData.ip_loca,
            de_farm_name: farmNameInput.trim(),
            de_farm_code: farmCodeInput.trim(),
            de_weight: de_weight,
            de_unit: 'kg',
            de_packing: packingCode.trim(),
            de_quality: qualityCode.trim(),
            de_high: highCode.trim(),
            de_rmk:highInput.trim(),
            de_quantity: CF.RemoveIntComma(values.de_quantity),
            de_cost2: CF.RemoveIntComma(values.de_cost2),
            de_dansu: values.de_dansu,
            de_hope_pri: CF.RemoveIntComma(values.de_hope_pri),
            de_cont1: values.de_cont1,
            de_cont2: values.de_cont2,
            de_cont3: values.de_cont3,
            de_img1:img1,
            de_img2:img2,
            de_video:video,
        };
        if(!popup.productPopModify){
            await axios.post(`${p_create_product.replace(':ip_date_online',ip_date_online).replace(':ip_no_online',ip_no_online)}`,body,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    closePopHandler();
                    dispatch(newProductAdd(true));
                    setTimeout(() => {
                        dispatch(newProductAdd(false));
                        cancelProduct();
                    },100);
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
        // 등록된물품 수정
        else{
            await axios.put(`${p_modify_product.replace(':ip_date_online',ip_date_online).replace(':ip_no_online',ip_no_online).replace(':de_date_online',popup.deDate).replace(':de_no_online',popup.deNo).replace(':de_seq',popup.deSeq).replace(':de_seq2',popup.deSeq2)}`,body,
                {headers:{Authorization: `Bearer ${token}`}}
            )
            .then((res)=>{
                if(res.status === 200){
                    closePopHandler();
                    dispatch(newProductAdd(true));
                    setTimeout(() => {
                        dispatch(newProductAdd(false));
                        cancelProduct();
                        dispatch(productPopModifyClose());
                    },100);

                    dispatch(proModifyData({...body,gm_name:gmNameInput}));
                }
            })
            .catch((error) => {
                const err_msg = CF.errorMsgHandler(error);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt: err_msg,
                    confirmPopBtn:1,
                }));
                setConfirm(true);
            });
        }
    };

    // 등록물품수정일때 물품정보뿌리기
    useEffect(() => {
        if(popup.deFarmName){
            console.log(`popup.deGodsName: ${popup.deFarmName}`);
            let deImg1 = popup.deImg1.replace(/\\/ig,"\\\\");
            let deImg2 = popup.deImg2.replace(/\\/ig,"\\\\");
            let deVideo = popup.deVideo.replace(/\\/ig,"\\\\");
            setFarmNameInput(CF.objValueTrim(popup.deFarmName));
            setFarmCodeInput(CF.objValueTrim(popup.deFarmCode));
            setGmCodeInput(CF.objValueTrim(popup.deGodsCode));
            setGmNameInput(CF.objValueTrim(popup.deGodsName));
            setPackingInput(CF.objValueTrim(popup.dePacking));
            setPackingCode(CF.objValueTrim(popup.dePackingCode));
            setQualityInput(CF.objValueTrim(popup.deQuality));
            setQualityCode(CF.objValueTrim(popup.deQualityCode));
            setHighInput(CF.objValueTrim(popup.deHigh));
            setHighCode(CF.objValueTrim(popup.deHighCode));
            if(deImg1.length > 0){
                setImage1Src(`${api_uri}/${deImg1}`);
                setImg1(`${deImg1}`);
            }
            if(deImg2.length > 0){
                setImage2Src(`${api_uri}/${deImg2}`);
                setImg2(`${deImg2}`);
            }
            if(deVideo.length > 0){
                setVideoSrc(`${api_uri}/${deVideo}`);
                setVideo(`${deVideo}`);
            }
        }
    },[popup.productPopModify]);


    return(<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="pop_wrap">
                <div className="dim"></div>
                <div className="pop_cont">
                    <button className="pop_close" onClick={() => {
                        cancelProduct();
                        closePopHandler();
                        dispatch(productPopModifyClose());
                    }}>닫기버튼</button>
                    <div className="pop_tit">
                        <p className="tit">물품추가등록(상품등록)</p>
                    </div>
                    <div className="scroll_wrap">
                        <Formik
                            initialValues={{
                                de_weight: popup.deWeight || "",
                                de_quantity: popup.deQuantity || "",
                                de_cost2: popup.deCost2 || "0",
                                de_dansu: popup.deDansu || "0",
                                de_hope_pri: popup.deHopePri || "",
                                de_cont1: popup.deCont1 || "",
                                de_cont2: popup.deCont2 || "",
                                de_cont3: popup.deCont3 || "",
                            }}
                            validationSchema={newProductPopValid}
                            onSubmit={newProductPopSubmit}
                            validateOnMount={true}  
                        >
                            {({values, handleSubmit, handleChange, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="custom_table3 input_table">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "auto" }} />
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "auto" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="tx_l">*생산자명</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input2" >
                                                                <input type="text" value={farmNameInput || ""} 
                                                                    onFocus={() => {setFarmNameInputClick(true)}} 
                                                                    onChange={(e) => {
                                                                        setFarmNameInput(e.target.value); 
                                                                    }} 
                                                                />
                                                            </div>
                                                            {farmNameDrop && (<>
                                                                <div className="drop_box">
                                                                    <div className="dim" onClick={()=>{
                                                                        setFarmNameInputClick(false);
                                                                        setTimeout(()=>{
                                                                            setFarmNameDrop(false);
                                                                        },200);
                                                                    }}></div>
                                                                    <ul className="list_ul">
                                                                        {farmNames.length === 0 &&
                                                                            <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                                        }

                                                                        {farmNames.length > 0 && (farmNames.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setFarmNameInput(farmNames[i].ft_farm_name.trim());
                                                                                setFarmCodeInput(farmNames[i].ft_farm_code.trim());
                                                                                setFarmNameDrop(false);
                                                                                setFarmNameInputClick(false);
                                                                            }}>{`${farmNames[i].ft_farm_name.trim()}(${farmNames[i].ft_farm_code.trim()})`}</li>;
                                                                        }))}
                                                                    </ul>
                                                                    
                                                                    <div className="new_box">
                                                                        <p>생산자 신규등록</p>
                                                                        <div className="flex_between">
                                                                            <div className="custom_input2">
                                                                                <input type="text" value={farmAddInput} 
                                                                                    onChange={(e) => {
                                                                                        setFarmAddInput(e.target.value);
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            <button className="btn_type3" onClick={farmAddHandler}>등록</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <th className="tx_l">*생산자 코드</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input2" >
                                                                <input type="text" value={farmCodeInput || ""} 
                                                                    onFocus={() => {setFarmCodeInputClick(true)}} 
                                                                    onChange={(e) => {
                                                                        setFarmCodeInput(e.target.value);
                                                                    }} 
                                                                />
                                                            </div>
                                                            {farmCodeDrop && (<>
                                                                <div className="drop_box">
                                                                    <div className="dim" onClick={()=>{
                                                                        setFarmCodeInputClick(false);
                                                                        setTimeout(()=>{
                                                                            setFarmCodeDrop(false);
                                                                        },200);
                                                                    }}></div>
                                                                    <ul className="list_ul">
                                                                        {farmCode.length === 0 &&
                                                                            <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                                        }

                                                                        {farmCode.length > 0 && (farmCode.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let code = e.currentTarget.innerText;
                                                                                let regex = /[^0-9]/g;
                                                                                code = code.replace(regex,"");
                                                                                setFarmCodeInput(code);
                                                                                setFarmNameInput(farmCode[i].ft_farm_name.trim());
                                                                                setFarmCodeDrop(false);
                                                                                setFarmCodeInputClick(false);
                                                                            }}>{`${farmCode[i].ft_farm_name.trim()}(${farmCode[i].ft_farm_code.trim()})`}</li>;
                                                                        }))}
                                                                    </ul>
                                                                    <div className="new_box">
                                                                        <p>생산자 신규등록</p>
                                                                        <div className="flex_between">
                                                                            <div className="custom_input2">
                                                                                <input type="text" value={farmAddInput} onChange={(e) => {
                                                                                    setFarmAddInput(e.target.value);
                                                                                }} />
                                                                            </div>
                                                                            <button className="btn_type3" onClick={farmAddHandler}>등록</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="bold tp20">물품정보</p>
                                    <div className="custom_table3 tm10">
                                        <table>
                                            <colgroup>
                                                <col style={{width: "auto"}} />
                                                <col style={{width: "14%"}} />
                                                <col style={{width: "8%"}} />
                                                <col style={{width: "6%"}} />
                                                <col style={{width: "8%"}} />
                                                <col style={{width: "12%"}} />
                                                <col style={{width: "8%"}} />
                                                <col style={{width: "7%"}} />
                                                <col style={{width: "8%"}} />
                                                <col style={{width: "8%"}} />
                                                <col style={{width: "10%"}} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>*상품코드</th>
                                                    <th>*품명</th>
                                                    <th>*중량</th>
                                                    <th>*단위</th>
                                                    <th>*규격</th>
                                                    <th>*친환경</th>
                                                    <th>*등급</th>
                                                    <th>*수량</th>
                                                    <th>*운임비</th>
                                                    <th>*단수</th>
                                                    <th>*희망단가</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={gmCodeInput || ""} readOnly />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input2">
                                                                <input type="text" value={gmNameInput || ""} 
                                                                    onFocus={() => {setGmNameInputClick(true)}} 
                                                                    onBlur={() => {
                                                                        setGmNameInputClick(false);
                                                                        setTimeout(()=>{
                                                                            setGmNameDrop(false);
                                                                        },200);
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setGmNameInput(e.target.value);
                                                                    }} 
                                                                />
                                                            </div>
                                                            {gmNameDrop && (<>
                                                                <div className="drop_box">
                                                                    <ul className="list_ul2">
                                                                        {gmStmdName.length > 0 && (
                                                                            <li>
                                                                                <p className="txt">[품목]</p>
                                                                                <ul className="list_ul">
                                                                                    {gmStmdName.map((a,i) => {
                                                                                        return <li key={i}  onClick={(e) => {
                                                                                            setGmNameInput(gmStmdName[i].gm_stmd_name.trim());
                                                                                            setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                            setGmNameDrop(false);
                                                                                            setGmNameInputClick(false);
                                                                                        }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )}
                                                                        {gmName.length > 0 && (
                                                                            <li>
                                                                                <p className="txt">[품명]</p>
                                                                                <ul className="list_ul">
                                                                                    {gmName.map((a,i) => {
                                                                                        return <li key={i}  onClick={(e) => {
                                                                                            setGmNameInput(gmName[i].gm_name.trim());
                                                                                            setGmCodeInput(gmName[i].gm_code.trim());
                                                                                            setGmNameDrop(false);
                                                                                            setGmNameInputClick(false);
                                                                                        }}>{gmName[i].gm_name.trim()}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )}
                                                                        {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                        {/* {errors.de_weight && touched.de_weight ?
                                                            <div className="alert_txt">
                                                                {errors.de_weight}
                                                            </div>
                                                        : null} */}
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <NumericFormat thousandSeparator="," value={values.de_weight} name="de_weight" onChange={handleChange} className="tx_r"/>
                                                        </div>
                                                        {errors.de_weight && touched.de_weight ?
                                                            <div className="alert_txt">
                                                                {errors.de_weight}
                                                            </div>
                                                        : null}
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value="kg" readOnly />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input h_40">
                                                                <input type="text" value={packingInput || ""} 
                                                                onFocus={() => {
                                                                    setPackingInputClick(true);
                                                                }}
                                                                onBlur={() => {
                                                                    setPackingInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setPackingDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setPackingInput(e.target.value);
                                                                }} />
                                                            </div>
                                                            {packingDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {packing.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {packing.length > 0 && (packing.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setPackingInput(packing[i].et_name.trim());
                                                                                setPackingDrop(false);
                                                                                setPackingInputClick(false);
                                                                                setPackingCode(packing[i].et_code.trim());
                                                                            }}>{packing[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input h_40">
                                                                <input type="text" value={qualityInput || ""} 
                                                                onFocus={() => {setQualityInputClick(true)}} 
                                                                onBlur={() => {
                                                                    setQualityInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setQualityDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setQualityInput(e.target.value);
                                                                }} />
                                                            </div>
                                                            {qualityDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {quality.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {quality.length > 0 && (quality.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setQualityInput(quality[i].et_name.trim());
                                                                                setQualityDrop(false);
                                                                                setQualityInputClick(false);
                                                                                setQualityCode(quality[i].et_code.trim());
                                                                            }}>{quality[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input h_40">
                                                                <input type="text" value={highInput || ""} 
                                                                onFocus={() => {setHighInputClick(true)}} 
                                                                onBlur={() => {
                                                                    setHighInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setHighDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setHighInput(e.target.value);
                                                                    setHighCode("00");
                                                                }} />
                                                            </div>
                                                            {highDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {high.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {high.length > 0 && (high.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setHighInput(high[i].et_name.trim());
                                                                                setHighDrop(false);
                                                                                setHighInputClick(false);
                                                                                setHighCode(high[i].et_code.trim());
                                                                            }}>{high[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_quantity} name="de_quantity" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_cost2} name="de_cost2" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" maxLength="3" value={values.de_dansu} name="de_dansu" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_hope_pri} name="de_hope_pri" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="custom_table3 input_table tm20">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "auto" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>구분</th>
                                                    <th>물품설명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>품질</td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={values.de_cont1} name="de_cont1" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>당도,산도 등</td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={values.de_cont2} name="de_cont2" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>특이사항</td>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={values.de_cont3} name="de_cont3" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="custom_table3 input_table tm20">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "33.333%" }} />
                                                <col style={{ width: "33.333%" }} />
                                                <col style={{ width: "33.333%" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>사진1</th>
                                                    <th>사진2</th>
                                                    <th>동영상(최대 30mb 이하)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className={`file_up_wrap ${!img1 == '' && 'on'}`} >
                                                            <label htmlFor="file_up1">클릭하여 사진을 등록해주세요.</label>
                                                            <input type="file" id="file_up1" accept="image/*" onChange={(e) => {
                                                                img1Up(e.target.files[0], e);
                                                                e.target.value = '';
                                                            }} />
                                                            <div className="preview">
                                                                {img1 && <img src={image1Src} alt="preview-img" />}
                                                            </div>
                                                            <button type="button" className="btn_delt" onClick={() => {
                                                                setImage1Src(null);
                                                                setImg1('');
                                                            }}>삭제버튼</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={`file_up_wrap ${!img2 == '' && 'on'}`} >
                                                            <label htmlFor="file_up2">클릭하여 사진을 등록해주세요.</label>
                                                            <input type="file" id="file_up2" accept="image/*" onChange={(e) => {
                                                                img2Up(e.target.files[0], e);
                                                                e.target.value = '';
                                                            }} />
                                                            <div className="preview">
                                                                {img2 && <img src={image2Src} alt="preview-img" />}
                                                            </div>
                                                            <button type="button" className="btn_delt" onClick={() => {
                                                                setImage2Src(null);
                                                                setImg2('');
                                                            }}>삭제버튼</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={`file_up_wrap ${!video == '' && 'on'}`} >
                                                            <label htmlFor="file_up3" className="video_up">30초이내로 등록해주세요.</label>
                                                            <input type="file" id="file_up3" accept=".mpg,.mpeg,.avi,.wmv,.asf,.asx,.mp4" onChange={(e) => {
                                                                videoUp(e.target.files[0], e);
                                                                e.target.value = '';
                                                            }} />
                                                            <div className="preview">
                                                                {video && <video src={videoSrc} />}
                                                            </div>
                                                            <button type="button" className="btn_delt" onClick={() => {
                                                                setVideoSrc(null);
                                                                setVideo('');
                                                            }}>삭제버튼</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="alert_txt tp10">※ 필수 입력항목을 확인해 주세요.</p>
                                    {!popup.productPopModify &&
                                        <p className="alert_txt2 tp5">※ 추가버튼 클릭시 자동 저장됩니다.</p>
                                    }
                                    <div className="flex_center">
                                        <button className="btn_type rm10" type="submit">{!popup.productPopModify ? `추가` : `저장`}</button>
                                        <button className="btn_type4" onClick={() => {
                                            cancelProduct();
                                            closePopHandler();
                                            dispatch(productPopModifyClose());
                                        }}>취소</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        }
        
        {/* 모바일일때 */}
        {isMobile &&
            <div className="pop_wrap">
                <div className="mo_pop_cont">
                    <div className="mo_pop_tit">
                        <p className="tit">물품추가등록(상품등록)</p>
                        <button className="pop_close" onClick={() => {
                            cancelProduct();
                            closePopHandler();
                            dispatch(productPopModifyClose());
                        }}>닫기버튼</button>
                    </div>
                    <div className="scroll_wrap">
                        <Formik
                            initialValues={{
                                de_weight: popup.deWeight || "",
                                de_quantity: popup.deQuantity || "",
                                de_cost2: popup.deCost2 || "0",
                                de_dansu: popup.deDansu || "0",
                                de_hope_pri: popup.deHopePri || "",
                                de_cont1: popup.deCont1 || "",
                                de_cont2: popup.deCont2 || "",
                                de_cont3: popup.deCont3 || "",
                            }}
                            validationSchema={newProductPopValid}
                            onSubmit={newProductPopSubmit}
                            validateOnMount={true}  
                        >
                            {({values, handleSubmit, handleChange, errors, touched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="tp20 lp10 bp10 f_16 medium">생산자정보</div>
                                    <div className="custom_table gray_table">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "25%" }} />
                                                <col style={{ width: "75%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>*생산자명</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input" >
                                                                <input type="text" value={farmNameInput || ""} 
                                                                    onFocus={() => {setFarmNameInputClick(true)}} 
                                                                    onChange={(e) => {
                                                                        setFarmNameInput(e.target.value); 
                                                                    }} 
                                                                />
                                                            </div>
                                                            {farmNameDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        <div className="dim" onClick={()=>{
                                                                            setFarmNameInputClick(false);
                                                                            setTimeout(()=>{
                                                                                setFarmNameDrop(false);
                                                                            },200);
                                                                        }}></div>
                                                                        {farmNames.length === 0 &&
                                                                            <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                                        }

                                                                        {farmNames.length > 0 && (farmNames.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setFarmNameInput(farmNames[i].ft_farm_name.trim());
                                                                                setFarmCodeInput(farmNames[i].ft_farm_code.trim());
                                                                                setFarmNameDrop(false);
                                                                                setFarmNameInputClick(false);
                                                                            }}>{`${farmNames[i].ft_farm_name.trim()}(${farmNames[i].ft_farm_code.trim()})`}</li>;
                                                                        }))}
                                                                    </ul>
                                                                    <div className="new_box">
                                                                        <p>생산자 신규등록</p>
                                                                        <div className="flex_between">
                                                                            <div className="custom_input2">
                                                                                <input type="text" value={farmAddInput} onChange={(e) => {
                                                                                    setFarmAddInput(e.target.value);
                                                                                }} />
                                                                            </div>
                                                                            <button className="btn_type3" onClick={farmAddHandler}>등록</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="rp0">*생산자 코드</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input" >
                                                                <input type="text" value={farmCodeInput || ""} 
                                                                    onFocus={() => {setFarmCodeInputClick(true)}} 
                                                                    onChange={(e) => {
                                                                        setFarmCodeInput(e.target.value);
                                                                    }} 
                                                                />
                                                            </div>
                                                            {farmCodeDrop && (<>
                                                                <div className="drop_box">  
                                                                    <div className="dim" onClick={()=>{
                                                                        setFarmCodeInputClick(false);
                                                                        setTimeout(()=>{
                                                                            setFarmCodeDrop(false);
                                                                        },200);
                                                                    }}></div>  
                                                                    <ul className="list_ul">
                                                                        {farmCode.length === 0 &&
                                                                            <li className="none">{dropLoading ? '검색중입니다.' : '검색결과 없음'}</li>
                                                                        }

                                                                        {farmCode.length > 0 && (farmCode.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let code = e.currentTarget.innerText;
                                                                                let regex = /[^0-9]/g;
                                                                                code = code.replace(regex,"");
                                                                                setFarmCodeInput(code);
                                                                                setFarmNameInput(farmCode[i].ft_farm_name.trim());
                                                                                setFarmCodeDrop(false);
                                                                                setFarmCodeInputClick(false);
                                                                            }}>{`${farmCode[i].ft_farm_name.trim()}(${farmCode[i].ft_farm_code.trim()})`}</li>;
                                                                        }))}
                                                                    </ul>
                                                                    <div className="new_box">
                                                                        <p>생산자 신규등록</p>
                                                                        <div className="flex_between">
                                                                            <div className="custom_input2">
                                                                                <input type="text" value={farmAddInput} onChange={(e) => {
                                                                                    setFarmAddInput(e.target.value);
                                                                                }} />
                                                                            </div>
                                                                            <button className="btn_type3" onClick={farmAddHandler}>등록</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tp20 lp10 bp10 f_16 medium">물품정보</div>
                                    <div className="custom_table gray_table">
                                        <table>
                                            <colgroup>
                                                <col style={{width: "20%"}} />
                                                <col style={{width: "auto"}} />
                                                <col style={{width: "20%"}} />
                                                <col style={{width: "auto"}} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="rp0">*상품코드</th>
                                                    <td colSpan="3">
                                                        <div className="custom_input">
                                                            <input type="text" value={gmCodeInput || ""} readOnly />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>*품명</th>
                                                    <td colSpan="3">
                                                        <div className="drop_wrap">
                                                            <div className="custom_input">
                                                                <input type="text" value={gmNameInput || ""} 
                                                                    onFocus={() => {setGmNameInputClick(true)}} 
                                                                    onBlur={() => {
                                                                        setGmNameInputClick(false);
                                                                        setTimeout(()=>{
                                                                            setGmNameDrop(false);
                                                                        },200);
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setGmNameInput(e.target.value);
                                                                    }} 
                                                                />
                                                            </div>
                                                            {gmNameDrop && (<>
                                                                <div className="drop_box">
                                                                    <ul className="list_ul2">
                                                                        {gmStmdName.length > 0 && (
                                                                            <li>
                                                                                <p className="txt">[품목]</p>
                                                                                <ul className="list_ul">
                                                                                    {gmStmdName.map((a,i) => {
                                                                                        return <li key={i}  onClick={(e) => {
                                                                                            setGmNameInput(gmStmdName[i].gm_stmd_name.trim());
                                                                                            setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                            setGmNameDrop(false);
                                                                                            setGmNameInputClick(false);
                                                                                        }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )}
                                                                        {gmName.length > 0 && (
                                                                            <li>
                                                                                <p className="txt">[품명]</p>
                                                                                <ul className="list_ul">
                                                                                    {gmName.map((a,i) => {
                                                                                        return <li key={i}  onClick={(e) => {
                                                                                            setGmNameInput(gmName[i].gm_name.trim());
                                                                                            setGmCodeInput(gmName[i].gm_code.trim());
                                                                                            setGmNameDrop(false);
                                                                                            setGmNameInputClick(false);
                                                                                        }}>{gmName[i].gm_name.trim()}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )}
                                                                        {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                        {errors.de_weight && touched.de_weight ?
                                                            <div className="alert_txt">
                                                                {errors.de_weight}
                                                            </div>
                                                        : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>*중량</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," className="tx_r" value={values.de_weight} name="de_weight" onChange={handleChange} />
                                                        </div>
                                                        {errors.de_weight && touched.de_weight ?
                                                            <div className="alert_txt">
                                                                {errors.de_weight}
                                                            </div>
                                                        : null}
                                                    </td>
                                                    <th>*단위</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <input type="text" value="kg" readOnly />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>*규격</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input">
                                                                <input type="text" value={packingInput || ""} 
                                                                onFocus={() => {
                                                                    setPackingInputClick(true);
                                                                }}
                                                                onBlur={() => {
                                                                    setPackingInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setPackingDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setPackingInput(e.target.value);
                                                                }} />
                                                            </div>
                                                            {packingDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {packing.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {packing.length > 0 && (packing.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setPackingInput(packing[i].et_name.trim());
                                                                                setPackingDrop(false);
                                                                                setPackingInputClick(false);
                                                                                setPackingCode(packing[i].et_code.trim());
                                                                            }}>{packing[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <th>*친환경</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input">
                                                                <input type="text" value={qualityInput || ""} 
                                                                onFocus={() => {setQualityInputClick(true)}} 
                                                                onBlur={() => {
                                                                    setQualityInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setQualityDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setQualityInput(e.target.value);
                                                                }} />
                                                            </div>
                                                            {qualityDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {quality.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {quality.length > 0 && (quality.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setQualityInput(quality[i].et_name.trim());
                                                                                setQualityDrop(false);
                                                                                setQualityInputClick(false);
                                                                                setQualityCode(quality[i].et_code.trim());
                                                                            }}>{quality[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>*등급</th>
                                                    <td>
                                                        <div className="drop_wrap">
                                                            <div className="custom_input">
                                                                <input type="text" value={highInput || ""} 
                                                                onFocus={() => {setHighInputClick(true)}} 
                                                                onBlur={() => {
                                                                    setHighInputClick(false);
                                                                    setTimeout(()=>{
                                                                        setHighDrop(false);
                                                                    },200);
                                                                }}
                                                                onChange={(e) =>{
                                                                    setHighInput(e.target.value);
                                                                }} />
                                                            </div>
                                                            {highDrop && (<>
                                                                <div className="drop_box">    
                                                                    <ul className="list_ul">
                                                                        {high.length === 0 && (<li className="none">검색결과 없음</li>)}

                                                                        {high.length > 0 && (high.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                setHighInput(high[i].et_name.trim());
                                                                                setHighDrop(false);
                                                                                setHighInputClick(false);
                                                                                setHighCode(high[i].et_code.trim());
                                                                            }}>{high[i].et_name.trim()}</li>;
                                                                        }))}
                                                                    </ul>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </td>
                                                    <th>*수량</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_quantity} name="de_quantity" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>*운임비</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_cost2} name="de_cost2" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                    <th>*단수</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" maxLength="3" value={values.de_dansu} name="de_dansu" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="rp0">*희망단가</th>
                                                    <td colSpan="3">
                                                        <div className="custom_input">
                                                            <NumericFormat thousandSeparator="," decimalScale={0} className="tx_r" value={values.de_hope_pri} name="de_hope_pri" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tp20 lp10 bp10 f_16 medium">물품설명</div>
                                    <div className="custom_table gray_table">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "25%" }} />
                                                <col style={{ width: "75%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>품질</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <input type="text" value={values.de_cont1} name="de_cont1" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="rp0">당도,산도 등</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <input type="text" value={values.de_cont2} name="de_cont2" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>특이사항</th>
                                                    <td>
                                                        <div className="custom_input">
                                                            <input type="text" value={values.de_cont3} name="de_cont3" onChange={handleChange} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <ul className="media_ul">
                                            <li>
                                                <div className={`file_up_wrap ${!image1Src == '' && 'on'}`} >
                                                    <label htmlFor="file_up1">클릭하여 사진을 등록해주세요.</label>
                                                    <input type="file" id="file_up1" accept="image/*" onChange={(e) => {
                                                        img1Up(e.target.files[0], e);
                                                        e.target.value = '';
                                                    }} />
                                                    <div className="preview">
                                                        {image1Src && <img src={image1Src} alt="preview-img" />}
                                                    </div>
                                                    <button type="button" className="btn_delt" onClick={() => {
                                                        setImage1Src(null);
                                                        setImg1('');
                                                    }}>삭제버튼</button>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={`file_up_wrap ${!image2Src == '' && 'on'}`} >
                                                    <label htmlFor="file_up2">클릭하여 사진을 등록해주세요.</label>
                                                    <input type="file" id="file_up2" accept="image/*" onChange={(e) => {
                                                        img2Up(e.target.files[0], e);
                                                        e.target.value = '';
                                                    }} />
                                                    <div className="preview">
                                                        {image2Src && <img src={image2Src} alt="preview-img" />}
                                                    </div>
                                                    <button type="button" className="btn_delt" onClick={() => {
                                                        setImage2Src(null);
                                                        setImg2('');
                                                    }}>삭제버튼</button>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={`file_up_wrap ${!videoSrc == '' && 'on'}`} >
                                                    <label htmlFor="file_up3" className="video_up">30초이내로 등록해주세요.</label>
                                                    <input type="file" id="file_up3" accept=".mpg,.mpeg,.avi,.wmv,.asf,.asx,.mp4" onChange={(e) => {
                                                        videoUp(e.target.files[0], e);
                                                        e.target.value = '';
                                                    }} />
                                                    <div className="preview">
                                                        {videoSrc && <video src={videoSrc} />}
                                                    </div>
                                                    <button type="button" className="btn_delt" onClick={() => {
                                                        setVideoSrc(null);
                                                        setVideo('');
                                                    }}>삭제버튼</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tp20 rp10 lp10 bp20 btn_half_box">
                                        <button className="btn_type rm10" type="submit">{!popup.productPopModify ? `추가` : `저장`}</button>
                                        <button className="btn_type4" onClick={() => {
                                            cancelProduct();
                                            closePopHandler();
                                            dispatch(productPopModifyClose());
                                        }}>취소</button>
                                        
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        }
        {/* confirm팝업 */}
        {confirm && <ConfirmPop closePop="this" onCloseHandler={()=>{setConfirm(false)}} />}
    </>);
};

export default NewProductPop;