import { useState,useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Formik,useFormikContext } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import { isMobile, isBrowser } from 'react-device-detect';
import {enum_api_uri} from "../../../config/enum";
import {enum_ip_status,enum_sell_kind,enum_from_kind,enum_mo_title} from "../../../config/enum";
import * as CF from "../../../components/pc/component/Function"; // (customFunction)
import LeftCont from "../../../components/pc/component/LeftCont";
import ProductInfo from "../../../components/pc/component/ProductInfo";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import DaumPost from "../../../components/pc/component/DaumPost";
import moment from "moment";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { confirmPop, closePop, addressPop, addressSelect } from "../../../store/popupSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import { PatternFormat } from "react-number-format";
import history from "../../../config/history";

const BidProductsDetail = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const { bpId, seq, seq2 } = useParams();
    // console.log(bpId);
    const m_detail_sbid = enum_api_uri.m_detail_sbid;
    const c_search_origin = enum_api_uri.c_search_origin;
    const m_detail_sbid_deli_info = enum_api_uri.m_detail_sbid_deli_info;
    const m_list_sbid_cf_reg = enum_api_uri.m_list_sbid_cf_reg;
    const token = localStorage.getItem("token");
    const [detail, setDetail] = useState([]);
    const [locInput, setLocInput] = useState();
    const [locTxt, setLocTxt] = useState([]);
    const [proList, setProList] = useState([]);
    const [ipPrice, setIpPrice] = useState();
    const [bidCal, setBidCal] = useState(0);
    const [bidsPrice, setBidsPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [phoneInput, setPhoneInput] = useState('');
    const [deliStartDate, setDeliStartDate] = useState();
    const [deliEndDate, setDeliEndDate] = useState();
    // const [reqDeliDate, setreqDeliDate] = useState('');
    // const [dateError, setDateError] = useState(false);
    const [postInput, setPostInput] = useState('');
    const [addressInput, setAddressInput] = useState('');
    const [addressInput2, setAddressInput2] = useState('');
    registerLocale('ko', ko);
    const location = useLocation();
    const dispatch = useDispatch();
    const [deliConfirm, setDeliConfirm] = useState(false);
    const [insuConfirm, setInsuConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [okConfirm,setOkConfirm] = useState(false);
    const popup = useSelector((state)=>state.popup);
    const [deliInfo, setDeliInfo] = useState({});
    const [detailData, setDetailData] = useState({});
    const [deStatus, setDeStatus] = useState("");
    const [hphone, setHphone] = useState("");
    const [receiver, setReceiver] = useState("");
    const [deliMemo, setDeliMemo] = useState("");

    //상세페이지 뒤로가기
    useEffect(() => {

        const listenBackEvent = () => {
            dispatch(closePop());
            dispatch(detailPageBack(true));
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[]);

    // 주소찾기
    const [enroll_company, setEnroll_company] = useState({
        address:"",zonecode:""
    });

    // 다음주소팝업
    const [daumPop, setDaumPop] = useState(false);

    useEffect(()=>{
        setPostInput(enroll_company.zonecode);
        setAddressInput(enroll_company.address);
    },[enroll_company]);


    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let path = location.pathname;
            let idx = path.lastIndexOf('/');
                path = path.substring(0, idx);
                idx = path.lastIndexOf('/');
                path = path.substring(0, idx);
                idx = path.lastIndexOf('/');
            let tit = path.substring(0, idx);
                tit = enum_mo_title[tit];
                console.log(tit)
            dispatch(moTitle(tit));
        }
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setDeliConfirm(false);
            setInsuConfirm(false);
            setConfirm(false);
            setOkConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(() => {
        setDeStatus(deStatus);
    },[deStatus]);

    useEffect(() => {
        setDetailData(detailData);
    },[detailData]);

    useEffect(()=>{
        setDeliInfo(deliInfo);
    },[deliInfo]);
    
    useEffect(() => {
        getDetail(bpId, seq, seq2);
    },[]);

    useEffect(() => {
        setDetail(detail);
    },[detail]);

    useEffect(() => {
        setLocTxt(locTxt);
    },[locTxt]);

    useEffect(() => {
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setPostInput(postInput);
    },[postInput]);

    useEffect(() => {
        setHphone(hphone);
    },[hphone]);

    // input 자동하이픈
    useEffect(() => {
        if (phoneInput.length === 11) {
            setPhoneInput(phoneInput.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
        if (phoneInput.length === 13) {
            setPhoneInput(phoneInput.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phoneInput]);


    //품명그룹 조회
    useEffect(() => {
        setIpPrice(ipPrice);
    },[ipPrice]);

    //낙찰물품 상세정보 받아오기
    const getDetail = async(bpId, seq, seq2) =>{

        let dinfoArr = bpId.split('-');
        let ip_date_online = dinfoArr[0]
        let ip_no_online = dinfoArr[1]
        let de_date_online = dinfoArr[0]
        let de_no_online = dinfoArr[1]

        axios.get (`${m_detail_sbid.replace(':ip_date_online', ip_date_online).replace(':ip_no_online', ip_no_online).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', seq).replace(':de_seq2', seq2)}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
         console.log(res);
            if(res.status === 200){
                
                // //날짜 형식 변경              
                if(res.data.ip_delivery_date_start && res.data.ip_delivery_date_start.trim() !=""){res.data.deli_start_date  =CF.MDFWithOutHour(res.data.ip_delivery_date_start); }
                if(res.data.ip_delivery_date_end && res.data.ip_delivery_date_end.trim() !=""){res.data.deli_start_end  = CF.MDFWithOutHour(res.data.ip_delivery_date_end); }


                // //시작일시&종료일시
                if(res.data.ip_auct_date_start.trim() !=""){res.data.auct_start_date  = CF.MDFWithHour(res.data.ip_auct_date_start); }
                if(res.data.ip_auct_date_end.trim() !=""){res.data.auct_end_date  = CF.MDFWithHour(res.data.ip_auct_date_end);}
                console.log(res.data.auct_start_date)

                //현재상태
                let ipStatus = res.data.ip_status.trim();
                res.data.ipStatus = enum_ip_status[ipStatus][0];

                //매매구분 (입찰/경매)
                let inv_type = res.data.ip_s_kind.trim();
                res.data.inv_type = enum_sell_kind[inv_type];

                //판매구분 (국내/수입)
                let from_type = res.data.ip_kind.trim();
                res.data.from_type = enum_from_kind[from_type];

                //출하주
                res.data.as_name = res.data.as_name.trim();

                //경매사
                res.data.auct_name = (res.data.ip_auct_name === null) ? '미정' : res.data.ip_auct_name;
                                
                
                //산지
                // let loc = res.data.ip_loca.trim()
                // setLocInput(loc)
                res.data.ip_loca_name = CF.objValueTrim(res.data.ip_loca_name);

                //배송요청일
                let oriReqDate = res.data.ip_delivery_date_start;
                let oriReqDate2 = res.data.ip_delivery_date_end;
                if(oriReqDate != null){
                    let plk = moment(oriReqDate).toISOString();
                    res.data.detltrns.newReqDate  = new Date(plk);
                    setDeliStartDate(res.data.detltrns.newReqDate);
                    let plk2 = moment(oriReqDate2).toISOString();
                        plk2  = new Date(plk2);
                    setDeliEndDate(plk2);
                }

                //수령자명
                if(res.data.detltrns.de_deli_rece_nm != null){
                    res.data.detltrns.de_deli_rece_nm = res.data.detltrns.de_deli_rece_nm.trim();
                }
                setReceiver(res.data.detltrns.de_deli_rece_nm);

                //연락처
                res.data.detltrns.de_deli_rece_tel = CF.objValueTrim(res.data.detltrns.de_deli_rece_tel);
                if(res.data.detltrns.de_deli_rece_tel.length > 0){
                    res.data.detltrns.de_deli_rece_tel = CF.MakePhoneForm(res.data.detltrns.de_deli_rece_tel);
                }
                setHphone(res.data.detltrns.de_deli_rece_tel);

                //배송지 - 우편번호
                if(res.data.detltrns.de_deli_rece_post != null){
                    res.data.detltrns.de_deli_rece_post = res.data.detltrns.de_deli_rece_post.trim();
                }else{
                    res.data.detltrns.de_deli_rece_post = '';
                }
                setPostInput(res.data.detltrns.de_deli_rece_post);

                //배송지 - 주소1
                if(res.data.detltrns.de_deli_rece_addr1 != null){
                    res.data.detltrns.de_deli_rece_addr1 = res.data.detltrns.de_deli_rece_addr1.trim();
                }else{
                    res.data.detltrns.de_deli_rece_addr1 = '';
                }
                setAddressInput(res.data.detltrns.de_deli_rece_addr1);

                //배송지 - 주소2
                if(res.data.detltrns.de_deli_rece_addr2 != null){
                    res.data.detltrns.de_deli_rece_addr2 = res.data.detltrns.de_deli_rece_addr2.trim();
                }else{
                    res.data.detltrns.de_deli_rece_addr2 = '';
                }
                setAddressInput2(res.data.detltrns.de_deli_rece_addr2);

                //메모
                if(res.data.detltrns.de_deli_rece_remk != null){
                    res.data.detltrns.de_deli_rece_remk = res.data.detltrns.de_deli_rece_remk.trim();
                }
                setDeliMemo(res.data.detltrns.de_deli_rece_remk);

                let data = CF.trimObjectValues(res.data.detltrns)
                setProList(data);

                //낙찰금액 계산하기
                setBidCal(CF.MakeIntComma(res.data.detltrns.de_quantity));


                //낙찰금액 계산하기
                setBidsPrice(CF.MakeIntComma(res.data.detltrns.de_price1))
                setTotalPrice(CF.MakeIntComma(res.data.detltrns.de_quantity * res.data.detltrns.de_price1))

                //상세항목 넣기a
                setDetail(res.data)

                //물품상태값 저장
                res.data.detltrns.de_status = CF.objValueTrim(res.data.detltrns.de_status);
                setDeStatus(res.data.detltrns.de_status);
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });

    }

    //배송정보 저장(수정)
    const deliSaveClickHandler = (values) => {
        if(receiver.length == 0){
            setErrorReceiver(true);
        }else{
            setErrorReceiver(false);
        }
        if(hphone.length == 0){
            setErrorHphone(true);
        }else{
            setErrorHphone(false);
        }
        if(postInput.trim().length == 0){
            setErrorAddress(true);
        }else{
            setErrorAddress(false);
        }
        if(receiver.length > 0 && hphone.length > 0 && postInput.trim().length > 0){
            setErrorReceiver(false);
            setErrorHphone(false);
            setErrorAddress(false);
            let data = {
                de_date_online: detail.detltrns.de_date_online,
                de_no_online: detail.detltrns.de_no_online,
                de_seq: detail.detltrns.de_seq,
                de_seq2: detail.detltrns.de_seq2
            }
            setDeliInfo({...values,...data});
            setDeliConfirm(true);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt:'배송정보를 저장하시겠습니까?',
                confirmPopBtn:2,
            }));
        }
    };

    //배송정보 저장(수정) 하기
    const deliSaveHandler = () => {
        let de_date_online = deliInfo.de_date_online;
        let de_no_online = deliInfo.de_no_online;
        let de_seq = deliInfo.de_seq;
        let de_seq2 = deliInfo.de_seq2;
        let newReqDate = moment(deliInfo.reqDeliDate).format("YYYYMMDD");
        let phone = hphone.replace(/[^0-9]/g, '');
        let body = {
            de_deli_req_date:newReqDate,
            de_deli_rece_nm:receiver,
            de_deli_rece_tel:phone,
            de_deli_rece_post:postInput,
            de_deli_rece_addr1:addressInput,
            de_deli_rece_addr2:addressInput2,
            de_deli_rece_remk:deliMemo
        };
        axios.put (`${m_detail_sbid_deli_info.replace(':ip_date_online', de_date_online).replace(':ip_no_online', de_no_online).replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,body,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                setOkConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'배송정보가 저장되었습니다.',
                    confirmPopBtn:1,
                }));
                dispatch(detailPageBack(true));
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    const handleComplete = (e) => {
        e.preventDefault();
        setDaumPop(!daumPop);
    }

    const [errorReceiver, setErrorReceiver] = useState(false);
    const [errorHphone, setErrorHphone] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);

    //인수등록
    const insuReg = async () => { 
        let de_date_online = deliInfo.de_date_online;
        let de_no_online = deliInfo.de_no_online;
        let de_seq = deliInfo.de_seq;
        let de_seq2 = deliInfo.de_seq2;
        let body = {};
        axios.post(`${m_list_sbid_cf_reg.replace(':de_date_online', de_date_online).replace(':de_no_online', de_no_online).replace(':de_seq', de_seq).replace(':de_seq2', de_seq2)}`,body,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            console.log(res);
            if(res.status === 200){
                // window.location.reload();
                setConfirm(true);
                dispatch(confirmPop({
                    confirmPopTit:'알림',
                    confirmPopTxt:'인수등록이 완료되었습니다.',
                    confirmPopBtn:1,
                }));
                setDeStatus("16");
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({	
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //목록으로 이동 (뒤로가기)
    const pageBack = () => {
        history.back(-1);
    };

    //배송정보 팝업에서 선택시 선택한 배송정보 뿌려주기
    useEffect(() => {
        if(popup.addressSelect){
            dispatch(addressSelect(false));

            setReceiver(popup.addressInfo.deli_rece_nm);
            setHphone(popup.addressInfo.deli_rece_tel);
            setPostInput(popup.addressInfo.deli_rece_post);
            setAddressInput(popup.addressInfo.deli_rece_addr1);
            setAddressInput2(popup.addressInfo.deli_rece_addr2);
            setDeliMemo("");
        }
    },[popup.addressSelect]);
    
    return (<>
        {isBrowser &&
            <div className="page_sub">
                <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={true} draggable={false} />
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">낙찰물품 조회</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>낙찰물품 조회</li>
                                </ul>
                            </div>
                            <div className="flex_between bp10">
                                <p className="medium">낙찰물품 상세</p>
                                <span className="tag">경매사 : {detail.auct_name}</span>
                            </div>
                            <div className="custom_table">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "13%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="tx_l">등록번호</th>
                                            <td className="tx_l txt_gray">{bpId}</td>
                                            <th className="tx_l">출하주</th>
                                            <td className="tx_l txt_gray">{detail.as_name}</td>
                                            <th className="tx_l">현재상태</th>
                                            <td className="tx_l"><span className="tag">{detail.ipStatus}</span></td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">매매구분</th>
                                            <td className="tx_l txt_gray">{detail.inv_type}</td>
                                            <th className="tx_l">시작일시</th>
                                            {/* <td className="tx_l txt_gray">08월08일 13시00분</td> */}
                                            <td className="tx_l txt_gray">{detail.auct_start_date}</td>
                                            <th className="tx_l">팰릿</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">판매구분</th>
                                            <td className="tx_l txt_gray">{detail.from_type}</td>
                                            <th className="tx_l">종료일시</th>
                                            {/* <td className="tx_l txt_gray">08월08일 13시00분</td> */}
                                            <td className="tx_l txt_gray">{detail.auct_end_date}</td>
                                            <th className="tx_l">목재(옥타곤)</th>
                                            <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">산지</th>
                                            <td className="tx_l txt_gray" colSpan="5">{detail.ip_loca_name} ({detail.ip_loca})</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">배송가능기간</th>
                                            {/* <td className="tx_l txt_gray" colSpan="5">2022/10/12  ~ 2022/10/12</td> */}
                                            <td className="tx_l txt_gray" colSpan="5">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="inner_box line_top">
                            <p className="medium bp20">낙찰된 물품</p>
                            {/* : <span className="txt_sky">{proList.de_quantity}</span>건</p> */}
                            <div className="bm30">
                                <ProductInfo 
                                    deFarmName={proList.de_farm_name || ""}
                                    deGodsName={proList.gm_name === null ? proList.gm_stmd_name : proList.gm_name || ""}
                                    deWeight={proList.de_weight || ""}
                                    dePacking={proList.de_packing_name || ""}
                                    deQuality={proList.de_quality_name || ""}
                                    deHigh={proList.de_high_name || ""}
                                    deQuantity={proList.de_quantity || ""}
                                    deCost2={proList.de_cost2 || ""}
                                    deDansu={proList.de_dansu || ""}
                                    deHopePri={proList.de_hope_pri || ""}
                                    deCont1={proList.de_cont1 || ""}
                                    deCont2={proList.de_cont2 || ""}
                                    deCont3={proList.de_cont3 || ""}
                                    deImg1={proList.de_img1 || ""}
                                    deImg2={proList.de_img2 || ""}
                                    deVideo={proList.de_video || ""}
                                />
                            </div>
                            <p className="f_15 medium tp20">낙찰 금액</p>
                            <div className="custom_table wide_table t_border2 tm10">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "auto" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "auto" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="tx_l">낙찰 수량</th>
                                            <td className="tx_r">{bidCal}개</td>
                                            <th className="tx_l">낙찰단가</th>
                                            <td className="tx_r">{bidsPrice}원</td>
                                            <th className="tx_l">총 금액</th>
                                            <td className="tx_r">{totalPrice}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex tp20">
                                <p className="f_15 medium">배송정보</p>
                                {proList.de_status < 15 &&
                                    <button type="button" className="btn2_s lm20" onClick={()=>{dispatch(addressPop(true))}}>선택</button>
                                }
                            </div>
                            {proList.de_status < 15 ? 
                                <div className="custom_table t_border2 tm10">
                                    <Formik
                                        initialValues={{
                                            reqDeliDate: proList.newReqDate, //배송요청일
                                            //receiver: proList.de_deli_rece_nm || "", //수령인
                                            // hphone: proList.de_deli_rece_tel || "", //연락처
                                            // zonecode: proList.de_deli_rece_post || "", //배송지 - 우편번호
                                            // address: proList.de_deli_rece_addr1 || "", //배송지 - 주소1
                                            // address2: proList.de_deli_rece_addr2 || "", //배송지 - 주소2
                                            //deliMemo: proList.de_deli_rece_remk || "" //메모
                                        }}
                                        // validationSchema={validationSchema}
                                        // onSubmit={(e) => {
                                        //     submit(e, detail.detltrns.de_date_online, detail.detltrns.de_no_online, detail.detltrns.de_seq, detail.detltrns.de_seq2);
                                        // }}
                                        validateOnMount={true}
                                    >
                                        {({values, handleSubmit, handleChange,setFieldValue, errors, touched}) => (
                                            <form onSubmit={handleSubmit}>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: "15%" }} />
                                                        <col style={{ width: "auto" }} />
                                                        <col style={{ width: "15%" }} />
                                                        <col style={{ width: "auto" }} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th className="tx_l">배송 가능일자</th>
                                                            <td className="tx_l">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                                            <th className="tx_l">배송 요청일자</th>
                                                            <td>
                                                            <div className="date_input">
                                                                <div className={`custom_input`}>
                                                                    <DatePicker
                                                                        selected={values.reqDeliDate}
                                                                        minDate={deliStartDate}
                                                                        maxDate={deliEndDate}
                                                                        dateFormat="yyyy-MM-dd"
                                                                        locale="ko"
                                                                        name="reqDeliDate"
                                                                        onChange={date => setFieldValue('reqDeliDate', date)}
                                                                    />
                                                                </div>
                                                            </div>
                                                                {errors.reqDeliDate && touched.reqDeliDate ?
                                                                    <div className="alert_txt">
                                                                        {errors.reqDeliDate}
                                                                    </div>
                                                                : null}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="tx_l">수령자명</th>
                                                            <td>
                                                                <div className="custom_input2">
                                                                    <input type="text" value={receiver} onChange={(e)=>{setReceiver(e.currentTarget.value)}}
                                                                        onKeyDown={(e)=>{
                                                                            if(e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                deliSaveClickHandler(values);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorReceiver &&
                                                                    <div className="alert_txt f_12 tx_l">수령자명을 입력해주세요.</div>
                                                                }
                                                            </td>
                                                            <th className="tx_l">연락처</th>
                                                            <td>
                                                                <div className="custom_input2">
                                                                    <input type="text" value={hphone} maxLength="13" 
                                                                        onChange={(e) => {
                                                                            setHphone(CF.MakePhoneForm(e.currentTarget.value));
                                                                        }}
                                                                        onKeyDown={(e)=>{
                                                                            if(e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                deliSaveClickHandler(values);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorHphone &&
                                                                    <div className="alert_txt f_12 tx_l">연락처를 입력해주세요.</div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="tx_l">배송지</th>
                                                            <td colSpan="3">
                                                                <div className="flex_between address_box">
                                                                    <div className="flex">
                                                                        <div className="custom_input2 w_100px rm5">
                                                                            <input type="text" value={enroll_company.zonecode || postInput} name="zipcode" onChange={(e) => {
                                                                                handleChange(e);
                                                                                setPostInput(e.currentTarget.value);
                                                                            }} readOnly />
                                                                        </div>
                                                                        <button className="btn_s3"  onClick={handleComplete}>우편번호 검색</button>
                                                                    </div>
                                                                    <div className="custom_input2">
                                                                        <input type="text"  value={enroll_company.address || addressInput} name="address" onChange={(e) => {
                                                                                handleChange(e);
                                                                                setAddressInput(e.currentTarget.value);
                                                                            }} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className="custom_input2 tm10">
                                                                    <input type="text" value={addressInput2} 
                                                                        onChange={(e) => {
                                                                            setAddressInput2(e.currentTarget.value);
                                                                        }}
                                                                        onKeyDown={(e)=>{
                                                                            if(e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                deliSaveClickHandler(values);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorAddress &&
                                                                    <div className="alert_txt f_12 tx_l">배송지를 입력해주세요.</div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="tx_l">메모</th>
                                                            <td colSpan="3">
                                                                <div className="custom_input2">
                                                                    <input type="text" value={deliMemo} onChange={(e)=>{setDeliMemo(e.currentTarget.value)}}
                                                                        onKeyDown={(e)=>{
                                                                            if(e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                deliSaveClickHandler(values);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
                                                <div className="flex_between tp15 bp40">
                                                    <p className="alert_txt2">* 서울 경기지역 외 배송의 경우 낙찰자에게 일부 배송비가 발생할 수 있습니다.</p>
                                                    <button className="btn_s2" type="button" onClick={()=>{
                                                        deliSaveClickHandler(values);
                                                    }}>배송정보 저장</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                :<div className="custom_table wide_table t_border2 tm10">
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "auto" }} />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "auto" }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th className="tx_l">배송 가능일자</th>
                                                <td className="tx_l">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                                <th className="tx_l">배송 요청일자</th>
                                                <td className="tx_l">{moment(proList.newReqDate).format('YYYY-DD-MM')}</td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">수령자명</th>
                                                <td className="tx_l">{proList.de_deli_rece_nm}</td>
                                                <th className="tx_l">연락처</th>
                                                <td className="tx_l">{proList.de_deli_rece_tel != null && CF.MakePhoneForm(proList.de_deli_rece_tel)}</td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">배송지</th>
                                                <td className="tx_l" colSpan="3">{proList.de_deli_rece_addr1+proList.de_deli_rece_addr2+'('+proList.de_deli_rece_post+')'}</td>
                                            </tr>
                                            <tr>
                                                <th className="tx_l">메모</th>
                                                <td className="tx_l" colSpan="3">{proList.de_deli_rece_remk}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {proList.de_status >= 15 && <>
                                <p className="f_15 medium tp20">운임정보{proList.de_deli_type == "O" && <span className="normal"> - 자차배송</span>}</p>
                                <div className="custom_table wide_table t_border2 tm10">
                                    <table>
                                        <colgroup>
                                            <col style={{ width: '13%' }} />
                                            <col style={{ width: '20.333%' }} />
                                            <col style={{ width: '13%' }} />
                                            <col style={{ width: '20.333%' }} />
                                            <col style={{ width: '13%' }} />
                                            <col style={{ width: '20.333%' }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>운송비</th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_r">{CF.MakeIntComma(proList.de_cost2)}원</td>
                                                }
                                                <th>기사명<span className="f_13">(예금주)</span></th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_l">{proList.de_deli_nm}</td>
                                                }
                                                <th>전화번호</th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_l">{CF.MakePhoneForm(proList.de_deli_tel)}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <th>차량번호</th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_l">{proList.de_deli_car_num}</td>
                                                }
                                                <th>운임 송금은행</th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_l">{proList.de_deli_bank_nm}</td>
                                                }
                                                <th>운임계좌</th>
                                                {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                    : <td className="tx_l">{proList.de_deli_bank_num}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <th>배송일자</th>
                                                <td colSpan="5" className="tx_l">{moment(proList.de_deli_date).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {deStatus == 15 && 
                                    <div className="flex_end tp15 bp40">
                                        <button className="btn_s2" type="button" onClick={()=>{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'인수등록을 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setInsuConfirm(true);
                                            let data = {
                                                de_date_online: detail.detltrns.de_date_online,
                                                de_no_online: detail.detltrns.de_no_online,
                                                de_seq: detail.detltrns.de_seq,
                                                de_seq2: detail.detltrns.de_seq2
                                            }
                                            setDeliInfo({...data});
                                        }}>인수등록</button>
                                    </div>
                                }
                            </>}
                        </div>
                        <div className="rp30 bp30 lp30 flex_end">
                            <button className="btn_type4" onClick={pageBack}>목록으로 이동</button>
                        </div>
                    </div>               
                </div>
            </div>
        }

        {isMobile &&
            <div className="page_sub">
                <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={true} draggable={false} />
                <div className="cont2">
                    <div className="flex_between bp10 tp20">
                        <p className="medium">낙찰물품 상세</p>
                        <span className="tag">경매사 : {detail.auct_name}</span>
                    </div>
                </div>
                <div className="custom_table gray_table bp10">
                    <table>
                        <colgroup>
                            <col style={{ width: "23%" }} />
                            <col style={{ width: "27%" }} />
                            <col style={{ width: "23%" }} />
                            <col style={{ width: "27%" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="tx_l">등록번호</th>
                                <td className="tx_l txt_gray" colSpan="3">{bpId}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">출하주</th>
                                <td className="tx_l txt_gray" colSpan="3">{detail.as_name}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">현재상태</th>
                                <td className="tx_l"><span className="tag">{detail.ipStatus}</span></td>
                                <th className="tx_l">매매구분</th>
                                <td className="tx_l txt_gray">{detail.inv_type}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">시작일시</th>
                                <td className="tx_l txt_gray" colSpan="3">{detail.auct_start_date}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">종료일시</th>
                                <td className="tx_l txt_gray" colSpan="3">{detail.auct_end_date}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">판매구분</th>
                                <td className="tx_l txt_gray" colSpan="3">{detail.from_type}</td>
                            </tr>
                            <tr>
                                <th className="tx_l">산지</th>
                                <td className="tx_l txt_gray" colSpan="3">{detail.ip_loca_name} ({detail.ip_loca})</td>
                            </tr>
                            <tr>
                                <th className="tx_l">팰릿</th>
                                <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kpp}`)}개</td>
                                <th className="tx_l rp0">목재(옥타곤)</th>
                                <td className="tx_r txt_gray">{CF.MakeIntComma(`${detail.ip_kcp}`)}개</td>
                            </tr>
                            <tr>
                                <th className="tx_l rp0">배송가능기간</th>
                                {/* <td className="tx_l txt_gray" colSpan="5">2022/10/12  ~ 2022/10/12</td> */}
                                <td className="tx_l txt_gray" colSpan="3">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {proList.de_quantity > 0  &&
                    <div className="inner_box line_top">
                        <div className="cont2">
                            <p className="medium bp10">낙찰된 물품 </p>
                            {/* : <span className="txt_sky">{proList.de_quantity}</span>건</p> */}
                        </div>
                        <div className="bm30">
                            <ProductInfo 
                                deFarmName={proList.de_farm_name || ""}
                                deFarmCode={proList.de_farm_code || ""}
                                deGodsName={proList.gm_name === null ? proList.gm_stmd_name : proList.gm_name || ""}
                                deWeight={proList.de_weight || ""}
                                dePacking={proList.de_packing_name || ""}
                                deQuality={proList.de_quality_name || ""}
                                deHigh={proList.de_high_name || ""}
                                deQuantity={proList.de_quantity || ""}
                                deCost2={proList.de_cost2 || ""}
                                deDansu={proList.de_dansu || ""}
                                deHopePri={proList.de_hope_pri || ""}
                                deCont1={proList.de_cont1 || ""}
                                deCont2={proList.de_cont2 || ""}
                                deCont3={proList.de_cont3 || ""}
                                deImg1={proList.de_img1 || ""}
                                deImg2={proList.de_img2 || ""}
                                deVideo={proList.de_video || ""}
                            />
                        </div>
                        <p className="f_15 medium tp20 lp10">낙찰 금액</p>
                        <div className="custom_table gray_table t_border2 tm10">
                            <table>
                                <colgroup>
                                    <col style={{ width: "23%" }} />
                                    <col style={{ width: "27%" }} />
                                    <col style={{ width: "23%" }} />
                                    <col style={{ width: "27%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th className="tx_l">낙찰 수량</th>
                                        <td className="tx_l">{bidCal}개</td>
                                        <th className="tx_l">낙찰단가</th>
                                        <td className="tx_r">{bidsPrice}원</td>
                                    </tr>
                                    <tr>
                                        <th className="tx_l">총 금액</th>
                                        <td className="tx_r" colSpan="3">{totalPrice}원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flex tp20 lp10">
                            <p className="f_15 medium">배송정보</p>
                            {proList.de_status < 15 &&
                                <button type="button" className="btn2_s lm20" onClick={()=>{dispatch(addressPop(true))}}>선택</button>
                            }
                        </div>
                        {proList.de_status < 15 ?
                            <div className="custom_table gray_table t_border2 tm10">
                                <Formik
                                    initialValues={{
                                        reqDeliDate: proList.newReqDate, //배송요청일
                                        // receiver: proList.de_deli_rece_nm || "", //수령인
                                        // hphone: proList.de_deli_rece_tel || "", //연락처
                                        // zonecode: proList.de_deli_rece_post, //배송지 - 우편번호
                                        // address: proList.de_deli_rece_addr1, //배송지 - 주소1
                                        // address2: proList.de_deli_rece_addr2, //배송지 - 주소2
                                        // deliMemo: proList.de_deli_rece_remk //메모
                                    }}
                                    // validationSchema={validationSchema}
                                    // onSubmit={(e) => {
                                    //     submit(e, detail.detltrns.de_date_online, detail.detltrns.de_no_online, detail.detltrns.de_seq, detail.detltrns.de_seq2);
                                    // }}
                                    validateOnMount={true}
                                    >
                                    {({values, handleSubmit, handleChange,setFieldValue, errors, touched}) => (
                                        <form onSubmit={handleSubmit}>
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "23%" }} />
                                                <col style={{ width: "77%" }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="tx_l">배송 가능일자</th>
                                                    <td className="tx_l">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">배송 요청일자</th>
                                                    <td>
                                                        <div className="date_input">
                                                            <div className="custom_input">
                                                                <DatePicker
                                                                    selected={values.reqDeliDate}
                                                                    minDate={deliStartDate}
                                                                    maxDate={deliEndDate}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    locale="ko"
                                                                    name="reqDeliDate"
                                                                    onChange={date => setFieldValue('reqDeliDate', date)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.reqDeliDate && touched.reqDeliDate ?
                                                            <div className="alert_txt">
                                                                {errors.reqDeliDate}
                                                            </div>
                                                        : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">수령자명</th>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={receiver} onChange={(e)=>{setReceiver(e.currentTarget.value)}}/>
                                                        </div>
                                                        {errorReceiver &&
                                                            <div className="alert_txt f_12 tx_l">수령자명을 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">연락처</th>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={hphone} maxLength="13" onChange={(e) => {
                                                                setHphone(CF.MakePhoneForm(e.currentTarget.value));
                                                            }}/>
                                                        </div>
                                                        {errorHphone &&
                                                            <div className="alert_txt f_12 tx_l">연락처를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">배송지</th>
                                                    <td>
                                                        <div className="flex_between address_box">
                                                            <div className="custom_input2 rm5">
                                                                <input type="text" value={enroll_company.zonecode || postInput}  name="zipcode" onChange={(e) => {
                                                                    handleChange(e);
                                                                    setPostInput(e.currentTarget.value);
                                                                }} readOnly />
                                                            </div>
                                                            <button className="btn_s3 w_120px"  onClick={handleComplete}>우편번호 검색</button>
                                                        </div>
                                                        <div className="custom_input2 tm5">
                                                            <input type="text"  value={enroll_company.address || addressInput} name="address1" onChange={(e) => {
                                                                    handleChange(e);
                                                                    setAddressInput(e.currentTarget.value);
                                                                }} readOnly />
                                                        </div>
                                                        <div className="custom_input2 tm10">
                                                            <input type="text" value={addressInput2} onChange={(e) => {
                                                                setAddressInput2(e.currentTarget.value);
                                                            }}/>
                                                        </div>
                                                        {errorAddress &&
                                                            <div className="alert_txt f_12 tx_l">배송지를 입력해주세요.</div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="tx_l">메모</th>
                                                    <td>
                                                        <div className="custom_input2">
                                                            <input type="text" value={deliMemo} onChange={(e)=>{setDeliMemo(e.currentTarget.value)}}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {daumPop && <DaumPost company={enroll_company} setcompany={setEnroll_company} handleComplete={handleComplete} />}
                                        <div className="cont2">
                                            <div className="tp15 bp40">
                                                <p className="alert_txt2 bp30 f_12">* 서울 경기지역 외 배송의 경우 낙찰자에게 일부 배송비가 발생할 수 있습니다.</p>
                                                <button className="btn_s2 w_100" type="button" onClick={()=>{
                                                    deliSaveClickHandler(values);
                                                }}>배송정보 저장</button>
                                            </div>
                                        </div>
                                    
                                    </form>
                                    )}
                                </Formik>
                            </div>
                            :<div className="custom_table gray_table t_border2 tm10">
                                <table>
                                    <colgroup>
                                        <col style={{ width: "23%" }} />
                                        <col style={{ width: "77%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className="tx_l">배송 가능일자</th>
                                            <td className="tx_l">{detail.deli_start_date} ~ {detail.deli_start_end}</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">배송 요청일자</th>
                                            <td className="tx_l">{moment(proList.newReqDate).format('YYYY-DD-MM')}</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">수령자명</th>
                                            <td className="tx_l">{proList.de_deli_rece_nm}</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">연락처</th>
                                            <td className="tx_l">{proList.de_deli_rece_tel != null && CF.MakePhoneForm(proList.de_deli_rece_tel)}</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">배송지</th>
                                            <td className="tx_l">{proList.de_deli_rece_addr1+proList.de_deli_rece_addr2+'('+proList.de_deli_rece_post+')'}</td>
                                        </tr>
                                        <tr>
                                            <th className="tx_l">메모</th>
                                            <td className="tx_l">{proList.de_deli_rece_remk}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                        {proList.de_status >= 15 && <>
                            <p className="f_15 medium tp20 lp10">운임정보{proList.de_deli_type == "O" && <span className="normal"> - 자차배송</span>}</p>
                            <div className="custom_table gray_table t_border2 tm10">
                                <table>
                                    <colgroup>
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '70%' }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>운송비</th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_r">{CF.MakeIntComma(proList.de_cost2)}원</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>기사명<span className="f_13">(예금주)</span></th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_l">{proList.de_deli_nm}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>전화번호</th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_l">{CF.MakePhoneForm(proList.de_deli_tel)}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>차량번호</th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_l">{proList.de_deli_car_num}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>운임 송금은행</th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_l">{proList.de_deli_bank_nm}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>운임계좌</th>
                                            {proList.de_deli_type == "O" ? <td className="tx_l">-</td>
                                                : <td className="tx_l">{proList.de_deli_bank_num}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>배송일자</th>
                                            <td className="tx_l">{moment(proList.de_deli_date).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {deStatus == 15 && 
                                <div className="cont2">
                                    <div className="tp15 bp40">
                                        <button className="btn_s2 w_100" type="button" onClick={()=>{
                                            dispatch(confirmPop({
                                                confirmPopTit:'알림',
                                                confirmPopTxt:'인수등록을 하시겠습니까?',
                                                confirmPopBtn:2,
                                            }));
                                            setInsuConfirm(true);
                                            let data = {
                                                de_date_online: detail.detltrns.de_date_online,
                                                de_no_online: detail.detltrns.de_no_online,
                                                de_seq: detail.detltrns.de_seq,
                                                de_seq2: detail.detltrns.de_seq2
                                            }
                                            setDeliInfo({...data});
                                        }}>인수등록</button>
                                    </div>
                                </div>
                            }
                        </>}
                    </div>
                }
                <div className="cont2 flex_end tm20">
                    <button className="btn_type4 bm20" onClick={pageBack}>목록으로 이동</button>
                </div>
            </div>
        }

        {/* 배송정보저장 confirm팝업 */}
        {deliConfirm && <ConfirmPop onClickHandler={deliSaveHandler} />}

        {/* 인수등록 완료 확인confirm팝업 */}
        {insuConfirm && <ConfirmPop onClickHandler={insuReg} />}

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}

        {/* 배송정보저장 완료 뒤로가기 팝업 */}
        {okConfirm && <ConfirmPop goBack={2} />}
    </>);
};

export default BidProductsDetail;