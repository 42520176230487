//const api_uri = "https://api.kurifnv.com:8080";
const api_uri = "https://api.kurifnv.com:5001";
//const api_uri = "http://localhost:8080";
//const api_uri = "http://192.168.0.160:8080";

exports.enum_api_uri = {
  api_uri: `${api_uri}`,
  m_cert_code: `${api_uri}/v1/user/:mem_id/code`,
  m_sign_in: `${api_uri}/v1/user/signin`,
  m_email_exist: `${api_uri}/v1/user/:mem_id/exist`,
  m_join: `${api_uri}/v1/user`,
  m_findid: `${api_uri}/v1/user/findid`,
  m_resetpw: `${api_uri}/v1/user/resetpw`,
  m_info: `${api_uri}/v1/user/:mem_id`,
  m_chat: `${api_uri}/v1/common/chat/:auct_code/:auct_date`,
  m_bank: `${api_uri}/v1/common/search_etcs?et_kind=G`,
  m_print: `${api_uri}/v1/common/invoice/:ip_date_online/:ip_no_online/print`,
  m_auction: `${api_uri}/v1/common/online-auction`,
  m_auction_products: `${api_uri}/v1/common/online-realtime-auction`,
  notice_list: `${api_uri}/v1/etc/notice`,

  //출하주
  p_create_invoice: `${api_uri}/v1/shipper/invoice`,
  p_modify_invoice: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online`,
  p_approval_invoice: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/req`,
  p_create_product: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/product`,
  p_modify_product: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/product/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  c_search_origin: `${api_uri}/v1/common/search_etcs`,
  c_search_farms: `${api_uri}/v1/common/search_farms`,
  c_farmmast: `${api_uri}/v1/common/farmmast`,
  c_search_gm_stmd: `${api_uri}/v1/common/search_gm_stmd`,
  c_search_gm: `${api_uri}/v1/common/search_gm`,
  c_deal_statistics: `${api_uri}/v1/common/deal_statistics`,
  p_upload_media: `${api_uri}/v1/shipper/media`,
  p_list_invoice: `${api_uri}/v1/shipper/invoices`, //송품장 조회
  p_list_product: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/products`, //물품조회(이 송품장에 등록된 모든물품들 정보만)
  p_list_product2: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online`, //물품조회(이 송품장의 정보와 등록된 모든물품들 정보)
  p_list_product3: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/product/:de_date_online/:de_no_online/:de_seq/:de_seq2`, //물품조회(이 송품장의 정보와 등록된 하나의물품정보)
  p_list_product_prev: `${api_uri}/v1/shipper/products/prev`,
  p_list_product_prev_copy: `${api_uri}/v1/shipper/:ip_date_online/:ip_no_online/products/prev-copy`,
  p_product_delivery: `${api_uri}/v1/shipper/invoice/:ip_date_online/:ip_no_online/product/:de_date_online/:de_no_online/:de_seq/:de_seq2/delivery`,
  p_deli_list: `${api_uri}/v1/shipper/delivery-fees`,
  p_deli: `${api_uri}/v1/shipper/delivery-fee`,
  p_deli_edit: `${api_uri}/v1/shipper/delivery-fee/:idx`,
  
  //경매사
  a_list_invoces: `${api_uri}/v1/auctioneer/invoices`,
  a_list_product: `${api_uri}/v1/auctioneer/invoice/products/:ip_date_online/:ip_no_online`, //등록된 물품목록조회 (송품장정보 포함)
  a_list_product_bid_result: `${api_uri}/v1/auctioneer/invoice/products/:ip_date_online/:ip_no_online/bid-auction-result`, // 승인된 물품목록조회 (송품장정보 포함)
  a_list_product2: `${api_uri}/v1/auctioneer/invoice/product/:de_ip_date/:de_ip_no/:de_date_online/:de_no_online/:de_seq/:de_seq2`, //물품상세조회
  a_list_product_results: `${api_uri}/v1/auctioneer/invoice/product/:de_ip_date/:de_ip_no/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-auction-result`, //물품상세조회 - 메모 배열로 받기 
  a_invoice_status: `${api_uri}/v1/auctioneer/invoice/:ip_date_online/:ip_no_online/status`,
  a_modify_invoice: `${api_uri}/v1/auctioneer/invoice/:ip_date_online/:ip_no_online`,
  a_devide_product: `${api_uri}/v1/auctioneer/invoice/product/:de_ip_date/:de_ip_no/:de_date_online/:de_no_online/:de_seq/:de_seq2/devide`,
  a_copy_product: `${api_uri}/v1/auctioneer/invoice/product/:de_ip_date/:de_ip_no/:de_date_online/:de_no_online/:de_seq/:de_seq2/copy`,
  a_status_product: `${api_uri}/v1/auctioneer/invoice/product/:ip_date_online/:ip_no_online/status`,
  a_delt_product: `${api_uri}/v1/auctioneer/invoice/product/:ip_date_online/:ip_no_online`,
  a_msg_product: `${api_uri}/v1/auctioneer/invoice/product/msg/:de_ip_date/:de_ip_no/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  a_message_list: `${api_uri}/v1/auctioneer/setting/:asso_code/messages`,
  a_message_add: `${api_uri}/v1/auctioneer/setting/:asso_code/message/`,
  a_message_setting: `${api_uri}/v1/auctioneer/setting/:asso_code/message/:messageIdx`,
  a_list_auction: `${api_uri}/v1/auctioneer/online-auction/products/:asso_code/:auct_date`,
  a_list_order_auction_dt: `${api_uri}/v1/auctioneer/:asso_code/auct_dt`,
  a_list_order_auction: `${api_uri}/v1/auctioneer/:asso_code/:auct_dt/products`,
  a_detail_auction: `${api_uri}/v1/auctioneer/online-auction/:asso_code/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  a_client_info: `${api_uri}/v1/auctioneer/online-auction/merchant/:asso_code`,
  a_list_table: `${api_uri}/v1/auctioneer/:asso_code/closed-bids`,
  a_check_bids: `${api_uri}/v1/auctioneer/:asso_code/closed-bids/:ip_no`,//원장번호 유효성 검사
  a_closed_bids: `${api_uri}/v1/auctioneer/:asso_code/closed-bids`,//원표생성 - 원표넘기기
  a_cancel_bids: `${api_uri}/v1/auctioneer/:asso_code/closed-bids/cencel`,//원표생성 - 원표취소
  a_invoice_vali: `${api_uri}/v1/auctioneer/invoice/:ip_date/:ip_no/validation`,//입하작업 - 원장날짜,원장번호 유효성검사
  a_status: `${api_uri}/v1/auctioneer/:asso_code/status`,
  a_bid_history: `${api_uri}/v1/auctioneer/online-auction/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-history`,
  a_bid_restart: `${api_uri}/v1/auctioneer/online-auction/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-restart`,
  a_bid_restart_list: `${api_uri}/v1/common/search_etcs?et_kind=Z`,
  a_auction_result: `${api_uri}/v1/auctioneer/:asso_code/bid-auction-result`,
  a_auction_result_bid_history: `${api_uri}/v1/auctioneer/bid-result/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-history`,
  a_bid_fail: `${api_uri}/v1/auctioneer/online-auction/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-fail-close`,
  a_bid_success: `${api_uri}/v1/auctioneer/online-auction/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-close`,
  a_bid_return: `${api_uri}/v1/auctioneer/online-auction/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-restoration`,
  a_total_amount: `${api_uri}/v1/auctioneer/online-auction/:ip_date_online/:ip_no_online/total-amount`,
  a_auctioneers_list: `${api_uri}/v1/common/auctioneers`,
  a_statistics_month: `${api_uri}/v1/etc/statistics/month`,
  a_statistics_day: `${api_uri}/v1/etc/statistics/days`,
  a_statistics_month_excel: `${api_uri}/v1/etc/statistics/month/excel`,
  a_statistics_day_excel: `${api_uri}/v1/etc/statistics/days/excel`,

  //중도매인
  m_list_ship_product: `${api_uri}/v1/merchant/shipment-products`,
  m_detail_ship_product: `${api_uri}/v1/merchant/shipment-product/:ip_date_online/:ip_no_online`,
  m_list_product: `${api_uri}/v1/merchant/products`,
  m_list_invoice: `${api_uri}/v1/merchant/invoices`,
  m_memo_insert: `${api_uri}/v1/merchant/shipment-product/:ip_date_online/:ip_no_online/:de_date_online/:de_no_online/:de_seq/:de_seq2/memo`,
  m_list_bid: `${api_uri}/v1/merchant/online-bid`,
  m_detail_bid: `${api_uri}/v1/merchant/online-bid/:ip_date_online/:ip_no_online`,
  m_bid_insert: `${api_uri}/v1/merchant/online-bid/:ip_date_online/:ip_no_online/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  m_list_sbid: `${api_uri}/v1/merchant/bid-products`,
  m_list_sbid_cf_reg: `${api_uri}/v1/merchant/bid-product/:de_date_online/:de_no_online/:de_seq/:de_seq2/accept`,
  m_detail_sbid: `${api_uri}/v1/merchant/bid-product/:ip_date_online/:ip_no_online/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  m_detail_sbid_deli_info: `${api_uri}/v1/merchant/bid-product/:ip_date_online/:ip_no_online/:de_date_online/:de_no_online/:de_seq/:de_seq2/delivery-info`,
  m_list_auction: `${api_uri}/v1/merchant/online-auctions`,
  m_sublist_auction: `${api_uri}/v1/merchant/online-auction/:asso_code/:auct_date`,
  m_subdetail_auction: `${api_uri}/v1/merchant/online-auction/:asso_code/:de_date_online/:de_no_online/:de_seq/:de_seq2`,
  m_merchant_info: `${api_uri}/v1/merchant/online-auctions/merchant/:amer_code`,
  // m_bid_history: `${api_uri}/v1/merchant/online-auction/:auct_code/:auct_date/bid-history`,
  m_bid_history: `${api_uri}/v1/merchant/online-auction/:auct_code/:auct_date/:de_date_online/:de_no_online/:de_seq/:de_seq2/bid-history`,
  m_base_price: `${api_uri}/v1/merchant/:asso_code/base-price`,
  m_deli_list: `${api_uri}/v1/merchant/delivery-places`,
  m_deli: `${api_uri}/v1/merchant/delivery-place`,
  m_deli_edit: `${api_uri}/v1/merchant/delivery-place/:idx`,
};
exports.debounce = 500;
exports.debounce2 = 600;

const enum_de_status = {
  1: ["입하 대기중", "출하주 물품 등록", "DE_STATUS_INCOME"],
  2: ["입하승인", "경매사 승인처리", "DE_STATUS_GRANTED"],
  3: ["입하미승인", "경매사 미승인처리", "DE_STATUS_DENIED"],
  4: ["경매준비", "경매당일", "DE_STATUS_AUCTION_PRE"],
  5: ["경매대기", "경매사가 온라인 경매 접속시", "DE_STATUS_AUCTION_STANDBY"],
  6: ["경매중", "현재 경매중인 상품", "DE_STATUS_AUCTIONING"],
  7: ["입찰대기", "온라인 입찰상품 -경매사가 승인처리시", "DE_STATUS_BIDWAIT"],
  8: ["입찰중", "현재 시간이 입찰 시작 종료 사이", "DE_STATUS_BIDING"],
  9: ["입찰종료", "입찰 시간 종료", "DE_STATUS_BID_CLOSED"],
  10: ["거래완료", "경매 - 낙찰시", "DE_STATUS_AUCTION_TRANS_CLOSE"],
  11: ["유찰", "경매 - 유찰시", "DE_STATUS_AUCTION_FAILURE"],
  12: ["거래완료", "입찰 - 낙찰시", "DE_STATUS_BID_TRANS_CLOSE"],
  13: ["유찰", "입찰 - 불낙", "DE_STATUS_BID_NOT_SUCCESSFUL"],
  14: ["운송대기", "낙찰자 배송지 정보 등록", "DE_STATUS_TRANSPORT_WATING"],
  15: ["배송중", "출하주 운송정보 등록", "DE_STATUS_TRANSPORTING"],
  16: ["완료", "인수등록", "DE_CLOSED"],
  17: ["유찰", "입찰 - 유찰시", "DE_STATUS_BID_FAILURE"],
};
exports.enum_de_status = enum_de_status;

//송품장 상태코드
const enum_ip_status = {
	1:["작성중","PRE"],
	2:["입하신청","REQ"],
	3:["입하승인","GRANTED"],
	4:["입하미승인","DENIED"],
	5:["경매중","PROGRESS"],
	6:["경매완료","CLOSE"],
	7:["입찰대기","BID_STANDBY"],
	8:["입찰중","BIDING"],
	9:["입찰종료","BID_CLOSE"]
};
exports.enum_ip_status = enum_ip_status;

//매매구분 (입찰/경매) - ip_s_kind
const enum_sell_kind = {
  0: ["미정"],
  1: ["입찰"],
  2: ["경매"],
};
exports.enum_sell_kind = enum_sell_kind;

//판매구분 - ip_kind
const enum_from_kind = {
  0: ["미정"],
  1: ["국내"],
  2: ["수입"],
};
exports.enum_from_kind = enum_from_kind;

//1:원표회수/2:현금회수 회수구분? - ip_recall_kind1/2/3
const enum_recall_kind = {
  0: ["미정"],
  1: ["원표회수"],
  2: ["현금회수"],
};
exports.enum_recall_kind = enum_recall_kind;

//경매사 상태코드
const enum_is_status = {
  1: ["경매준비", "PRE"],
  2: ["경매사 입장전", "NOT_YET_ENTERED"],
  3: ["경매대기", "STANDBY"],
  4: ["경매중", "PROGRESS"],
  5: ["경매종료", "CLOSE"],
};
exports.enum_is_status = enum_is_status;

//메모(경매 입찰, 응찰) 상태코드
const enum_memo_status = {
  0: ["취소", "응찰취소", "CANCEL"],
  1: ["응찰", "갱신으로 자동 취소", "UPDATE_CANCEL"],
  2: ["메모", "메모", "MEMO"],
  3: ["입찰", "온라인 입찰", "TENDER_BID"],
  4: ["응찰", "온라인 경매 응찰", "TENDER_AUCTION"],
  5: ["유찰", "온라인 입찰 유찰", "BID_FAIL"],
  6: ["낙찰", "온라인 입찰 낙찰", "BID_SUCCESS"],
  7: ["유찰", "온라인 경매 유찰", "AUCTION_FAIL"],
  8: ["낙찰", "온라인 경매 낙찰", "AUCTION_SUCCESS"],
  9: ["응찰오류", "온라인 경매 응찰오류", "TENDER_AUCTION_ERROR"],
  10: ["유찰", "경매사 유찰", "FAIL_CLOSE"],
  11: ["재경매", "재경매", "RESTART"],
  12: ["취소", "메모 갱신으로 자동 취소", "MEMO_UPDATE_CANCEL"],
  13: ["취소", "경매사 결과 취소", "RESTORATION"],
  99: ["경매사처리", "경매사 처리", "AUCTIONEER_PROCESS"],
};
exports.enum_memo_status = enum_memo_status;

//모바일일때 헤더 타이틀
const enum_mo_title = {
  "/signup": ["회원가입"],
  "/find-id": ["아이디찾기"],
  "/find-password": ["비밀번호찾기"],
  //출하주
  "/shipper/invoice-register": ["송품장 등록"],
  "/shipper/invoice-check": ["송품장 조회"],
  "/shipper/invoice-check/detail": ["송품장 상세"],
  "/shipper/online-check": ["온라인 경매결과 조회"],
  "/shipper/online-check/detail": ["온라인 경매결과 상세"],
  //경매사
  "/auctioneer/mo/receiving": ["입하작업"], //입하작업
  "/auctioneer/mo/receiving/detail": ["입하작업 상세"], //입하작업 상세
  "/auctioneer/bid-result": ["경매/입찰 결과조회"],
  "/auctioneer/statistics": ["통계"],
  //중도매인
  "/merchant/auction": ["온라인 경매"],
  "/merchant/shipment-products": ["출하물품 조회"],
  "/merchant/shipment-products/detail": ["출하물품 상세"],
  "/merchant/online-bid": ["온라인 입찰"],
  "/merchant/online-bid/detail": ["온라인 입찰 상세"],
  "/merchant/bid-products": ["낙찰물품 조회"],
  "/merchant/bid-products/detail": ["낙찰물품 조회 상세"],
};
exports.enum_mo_title = enum_mo_title;
