import { createSlice } from "@reduxjs/toolkit";

const invoice = createSlice({
    name: "invoice",
    initialState:{
        invIdx:null,
        invProIdx:null,
        invList:[],
        proModifyData:{},
    },
    reducers:{
        invIdx:(state, action) => {
            state.invIdx = action.payload;
        },
        invProIdx:(state, action) => {
            state.invProIdx = action.payload;
        },
        invList:(state, action) => {
            state.invList = action.payload;
        },
        proModifyData:(state, action) => {
            state.proModifyData = action.payload;
        },
    }
});

export const { invIdx, invProIdx, invList, proModifyData } = invoice.actions;
export default invoice;