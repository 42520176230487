import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import enum_api_uri from "../../config/enum";

const FindId = () => {
    const [findState, setFindState] = useState(false);
    const [userId, setUserId] = useState('');
    const [joinDate, setJoinDate] = useState('');
    const api_url = enum_api_uri.api_uri;
    const m_findid = enum_api_uri.m_findid;

    // let maskingFunc = {
    //     checkNull : function (str){
    //         if(typeof str == "undefined" || str === null || str === ""){
    //             return true;
    //         }
    //         else{
    //             return false;
    //         }
    //     },
    //     name : function(str){
    //         let originStr = str;
    //         let maskingStr;
    //         let strLength;
            
    //         if(this.checkNull(originStr) === true){
    //             return originStr;
    //         }
            
    //         strLength = originStr.length;
            
    //         if(strLength < 3){
    //             maskingStr = originStr.replace(/(?<=.{1})./gi, "*");
    //         }else {
    //             maskingStr = originStr.replace(/(?<=.{2})./gi, "*");
    //         }
    //         return maskingStr;
    //     }
    // }

    const submit = (values) => {
        let body = {
            mem_nm:values.name,
            mem_email:values.email,
        };
        //console.log(body);
        axios.post(`${api_url}${m_findid}`,body)
        .then((res)=>{
            if(res.status === 200){
                setFindState(true);
                // setUserId(maskingFunc.name(values.userid));
                setUserId(values.userId);
                setJoinDate(res.data.reg_dt);
            }
        })
        .catch((error) => {
            console.log(error);
            toast.error(`입력하신 정보로 가입 된 회원 아이디는 존재하지 않습니다.`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
            });
        })
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("이름을 입력해주세요."),
        email: Yup.string()
            .required("이메일을 입력해주세요.")
            .email("올바른 이메일 형식이 아닙니다."),
    });

    return(
        <div className="page_form">
            <ToastContainer />
            <div className="top_banner">
                <div>
                    <h3>아이디 찾기</h3>
                    <p>농민의 소중한 땀의 결실에 보답하기위해 최선을 다하겠습니다.</p>
                </div>
            </div>
            <div className="cont2">
                {/* 아이디찾기 */}
                {!findState && 
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={submit}
                        validateOnMount={true}
                    >
                        {({values, handleSubmit, handleChange, errors, touched}) => (
                            <form onSubmit={handleSubmit}>
                                <p className="big_tit tx_c">아이디 찾기</p>
                                <p className="sub_tit tp5 tx_c">아이디 찾기 관련 문의사항은 고객센터 031-560-6147로 연락주세요.</p>
                                <div className="gray_box">
                                    <ul className="form_ul">
                                        <li>
                                            <p className="input_tit">이름</p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="text" value={values.name} name="name" onChange={handleChange} />
                                                </div>
                                                {errors.name && touched.name ?
                                                    <div className="alert_txt">
                                                        {errors.name}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li>
                                            <p className="input_tit">이메일</p>
                                            <div className="box">
                                                <div className="custom_input">
                                                    <input type="text" value={values.email} name="email" onChange={handleChange} />
                                                </div>
                                                {errors.email && touched.email ?
                                                    <div className="alert_txt">
                                                        {errors.email}
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex_center tp40 btn_box">
                                    <button type="submit" className="btn_type">확인</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                }

                {/* 아이디찾기 완료 */}
                {findState && 
                    <div className="tx_c">
                        <p className="big_tit">아이디 찾기</p>
                        <div className="line_box">
                            <div className="img"></div>
                            <p className="f_15 txt_gray">개인정보 보호를 위해 <span className="txt_sky">일부분은 *로 표시</span>됩니다. <br/>동명이인이 있는 경우, 본인 외의 ID가 노출될 수 있습니다.</p>
                            <p className="f_15 txt_gray tp15">입력된 정보로 등록된 아이디는 총 <span className="txt_sky">1개</span> 있습니다.</p>
                            <div className="box">
                                <p className="f_15 txt_gray"><strong className="txt_sky">{userId}</strong> (가입일 : {joinDate})</p>
                            </div>
                        </div>
                        <div className="flex_center tp20 btn_box">
                            <Link to="/find-password" className="btn_type4 rm10">비밀번호 찾기</Link>
                            <Link to="/" className="btn_type">로그인</Link>
                        </div>
                    </div>
                }
                
            </div>
        </div>
    );
};

export default FindId;