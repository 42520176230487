import { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import {enum_api_uri, debounce, debounce2, enum_mo_title} from "../../../config/enum";
import LeftCont from "../../../components/pc/component/LeftCont";
import {BidProductInfo} from "../../../components/pc/component/ProductList";
import Pagination from "../../../components/pc/component/Pagination";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { isMobile, isBrowser } from 'react-device-detect';
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import { confirmPop } from "../../../store/popupSlice";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";
import * as CF from "../../../components/pc/component/Function";

const OnlineBid = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    // const [de_gods_code, set_gods_code] = useState(null);
    const [gmStmdName, setGmStmdName] =  useState([]);
    const [gmNameInput, setGmNameInput] = useState('');
    const [gmCodeInput, setGmCodeInput] = useState('');
    const basicDate = new Date();
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [dateError, setDateError] = useState(false);
    const [gmNameInputClick, setGmNameInputClick] =  useState(false);
    const user = useSelector((state) => state.user);
    const popup = useSelector((state) => state.popup);
    const common = useSelector((state) => state.common);
    const [proList, setProList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const [gmName, setGmName] =  useState([]);
    const [gmNameDrop, setGmNameDrop] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const location = useLocation();
    const m_list_bid = enum_api_uri.m_list_bid;
    const c_search_gm_name = enum_api_uri.c_search_gm;
    const c_search_gm_stmd_name = enum_api_uri.c_search_gm_stmd;
    const [confirm, setConfirm] = useState(false);
    registerLocale('ko', ko);
    const [scrollMove, setScrollMove] = useState(false);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);
        
        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setConfirm(false);
        }
    },[popup.confirmPop]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(() => {
        setProList(proList);
    },[proList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
    },[viewPaging]);

    //품명그룹 조회
    useEffect(() => {
        setGmNameInput(gmNameInput);
        // console.log(user.token)
        if(gmNameInputClick){
            if(gmNameInput.length > 0){
                const timerAxios = setTimeout(() => {
                    axios.get(`${c_search_gm_stmd_name}?gm_stmd=${gmNameInput}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setGmStmdName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerAxios2 = setTimeout(() => {
                    axios.get(`${c_search_gm_name}?gm_name=${gmNameInput}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((res)=>{
                        if(res.status === 200){
                            setGmName(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                },debounce);
                const timerDrop = setTimeout(() => {
                    setGmNameDrop(true);
                },debounce2);
                //setLocaError(false);
                return () => clearTimeout(timerAxios,timerAxios2,timerDrop);
            }else{
                setGmNameDrop(false);
                setGmNameInputClick(false);
            }
        }
    },[gmNameInput]);

    useEffect(()=>{ //처음 한번 리스트 불러오기a1

        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);
    
    //경매목록 조회 버튼 클릭시
    const submit = (values) => {
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');

        let val = [];
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };
    
    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };
    
    //리스트 받아오기(현재 페이지 공통)
    const getList = (val) =>{
        axios.get(`${m_list_bid}?ip_date_start=${val.ip_date_start}&ip_date_end=${val.ip_date_end}&de_gods_code=&record_size=${val.record_size}&page_no=${val.page_no_now}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{
            if(res.status === 200){
                const data = res.data
                let state = [...data.results];
                let resCount = data.total_record_count;
                setProList(state); //리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    }


    //검색폼 초기화
    const clearForm = (resetForm)=>{
        let reset = ''
        setGmNameInput(reset)
        setGmCodeInput(reset)
        resetForm()
    }

    
    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">온라인 입찰 - 상품목록</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>중도매인</li>
                                    <li>온라인입찰</li>
                                    <li>출하물품 조회</li>
                                </ul>
                            </div>
                            <Formik
                                initialValues={{
                                    de_gods_code: ""
                                }}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                            {({values, setFieldValue, handleSubmit, errors, touched,resetForm}) => (
                            <form onSubmit={handleSubmit}>
                            <ul className="search_box">
                                <li>
                                    <p className="txt">출하일자</p>
                                    <div className="date_input">
                                        <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                        <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                    </div>
                                </li>
                                {/* <li className="item_li">
                                    <p className="txt">품목</p>
                                    <div className="flex_between">
                                    <div className="flex">
                                            <div className="drop_wrap rm5">
                                                <div className="custom_input2">
                                                <input type="text" value={gmNameInput || ""} onClick={() => {setGmNameInputClick(true)}} onChange={(e) => {
                                                            setGmNameInput(e.target.value);
                                                        }} />
                                                </div>
                                                {gmNameDrop && (<>
                                                        <div className="dim" onClick={() => {setGmNameDrop(false)}}></div>
                                                        <div className="drop_box">
                                                            <ul className="list_ul2">
                                                                {gmStmdName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품목]</p>
                                                                        <ul className="list_ul">
                                                                            {gmStmdName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    let name = e.currentTarget.innerText;
                                                                                    let bracket = name.lastIndexOf('(');
                                                                                    name = name.slice(0,bracket);
                                                                                    setGmNameInput(name);
                                                                                    setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmName.length > 0 && (
                                                                    <li>
                                                                        <p className="txt">[품명]</p>
                                                                        <ul className="list_ul">
                                                                            {gmName.map((a,i) => {
                                                                                return <li key={i}  onClick={(e) => {
                                                                                    let name = e.currentTarget.innerText;
                                                                                    let bracket = name.lastIndexOf('(');
                                                                                    name = name.slice(0,bracket);
                                                                                    setGmNameInput(name);
                                                                                    setGmCodeInput(gmName[i].gm_code.trim());
                                                                                    setGmNameDrop(false);
                                                                                    setGmNameInputClick(false);
                                                                                }}>{gmName[i].gm_name.trim()}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                                {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                            </ul>
                                                        </div>
                                                    </>)}
                                            </div>
                                            <div className="custom_input2 w_40">
                                                    <input type="text" value={gmCodeInput} readOnly />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <button type="button" className="btn_s rm5" onClick = {()=>clearForm(resetForm)}>선택 초기화</button>
                                            <button type="submit" className="btn_s2">조회</button>
                                        </div>
                                    </div>
                                </li> */}
                                <li><button type="submit" className="btn_s">조회</button></li>
                            </ul>
                            {dateError && 
                                <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                            }
                            <div className="tm30">
                                <p className="medium bp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                {/* 등록물품 없을때 ▼ */}
                                {proList.length === 0 && (
                                    <div className="none_data"><span>데이터가 없습니다.</span></div>
                                )}

                                {/* 등록물품 있을때 ▼ */}
                                {proList.length > 0 && 
                                    <div>
                                        <div className="custom_table s_table">
                                            <table>
                                            <colgroup>
                                                    <col style={{ width: "135px" }} />
                                                    <col style={{ width: "90px" }} />
                                                    <col style={{ width: "130px" }} />
                                                    <col style={{ width: "100px" }} />
                                                    <col style={{ width: "50px" }} />
                                                    <col style={{ width: "50px" }} />
                                                    <col style={{ width: "50px" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "100px" }} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>등록번호</th>
                                                        <th>경매사</th>
                                                        <th>입찰시간</th>
                                                        <th className="th_link"><span>대표품목</span></th>
                                                        <th>입하</th>
                                                        <th>입찰</th>
                                                        <th>낙찰</th>
                                                        <th>상태</th>
                                                        <th>입찰하기</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {proList.map((a,i) => {
                                                        return (
                                                            <BidProductInfo 
                                                            ip_date_online={proList[i].ip_date_online || ""}
                                                            ip_no_online={proList[i].ip_no_online || ""}
                                                            ip_s_kind={proList[i].ip_s_kind || ""}
                                                            ip_auct_name={proList[i].ip_auct_name || ""}
                                                            ip_auct_date_start={proList[i].ip_auct_date_start || ""}
                                                            ip_auct_date_end={proList[i].ip_auct_date_end || ""}
                                                            gm_name={proList[i].gm_name === null ? proList[i].gm_stmd_name : proList[i].gm_name || ""}
                                                            ip_status={proList[i].ip_status || ""}
                                                            income_tot={proList[i].income_tot || ""}
                                                            income_quantity_tot={proList[i].income_quantity_tot || ""}
                                                            bidding_tot={proList[i].bidding_tot || ""}
                                                            success_tot={proList[i].success_tot || ""}
                                                            de_count={proList[i].de_count || ""}
                                                            de_memo_count={proList[i].de_memo_count || ""}
                                                            pindex={i +1 || 1}
                                                            />
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }

                                {/* 조회가 없을때 ▼ */}
                                {viewPaging === 0 && (
                                <div className="pagination">
                                    <button className="prev">
                                        <span>이전 페이지</span>
                                    </button>
                                    <ul className="num_box">
                                        <li className="on">
                                            <button>1</button>
                                        </li>
                                    </ul>
                                    <button className="next">
                                        <span>다음 페이지</span>
                                    </button>
                                </div>
                                )}
                                {/* 조회가 페이징보다 클때 ▼ */}
                                {viewPaging > 0 &&
                                        <Pagination 
                                        nowPage={page_no}
                                        totalCnt={viewPaging}
                                        setPerPage={perPage}
                                        moveToPage={moveToPage}

                                        />
                                }
                            </div>
                            </form>
                        )}
                        </Formik>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        de_gods_code: ""
                    }}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                    {({values, setFieldValue, handleSubmit, errors, touched,resetForm}) => (
                    <form onSubmit={handleSubmit}>
                    <div className="search_box">
                        <ul>
                            <li>
                                <p className="txt">출하일자</p>
                                <div className="date_input">
                                    <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                    <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                </div>
                            </li>
                            {/* <li className="item_li">
                                <p className="txt">품목</p>
                                <div className="flex_between">
                                    <div className="flex">
                                        <div className="drop_wrap rm5">
                                            <div className="custom_input">
                                                <input type="text" value={gmNameInput || ""} onClick={() => {setGmNameInputClick(true)}} onChange={(e) => {
                                                    setGmNameInput(e.target.value);
                                                }} />
                                            </div>
                                            {gmNameDrop && (<>
                                                    <div className="dim" onClick={() => {setGmNameDrop(false)}}></div>
                                                    <div className="drop_box">
                                                        <ul className="list_ul2">
                                                            {gmStmdName.length > 0 && (
                                                                <li>
                                                                    <p className="txt">[품목]</p>
                                                                    <ul className="list_ul">
                                                                        {gmStmdName.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let name = e.currentTarget.innerText;
                                                                                let bracket = name.lastIndexOf('(');
                                                                                name = name.slice(0,bracket);
                                                                                setGmNameInput(name);
                                                                                setGmCodeInput(gmStmdName[i].gm_stmd_code.trim());
                                                                                setGmNameDrop(false);
                                                                                setGmNameInputClick(false);
                                                                            }}>{gmStmdName[i].gm_stmd_name.trim()}</li>;
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                            {gmName.length > 0 && (
                                                                <li>
                                                                    <p className="txt">[품명]</p>
                                                                    <ul className="list_ul">
                                                                        {gmName.map((a,i) => {
                                                                            return <li key={i}  onClick={(e) => {
                                                                                let name = e.currentTarget.innerText;
                                                                                let bracket = name.lastIndexOf('(');
                                                                                name = name.slice(0,bracket);
                                                                                setGmNameInput(name);
                                                                                setGmCodeInput(gmName[i].gm_code.trim());
                                                                                setGmNameDrop(false);
                                                                                setGmNameInputClick(false);
                                                                            }}>{gmName[i].gm_name.trim()}</li>;
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                            {gmStmdName.length === 0 && gmName.length === 0 && <li className="none f_13">검색결과가 없습니다.</li>}
                                                        </ul>
                                                    </div>
                                                </>)}
                                        </div>
                                        <div className="custom_input w_45">
                                            <input type="text" value={gmCodeInput} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                        {/* <div className="btn_half_box tp10">
                            <button type="button" className="btn_s rm5" onClick = {()=>clearForm(resetForm)}>선택 초기화</button>
                            <button type="submit" className="btn_s2">조회</button>
                        </div> */}
                        <button type="submit" className="btn_s w_100 tm10">조회</button>
                    </div>
                    {dateError && 
                        <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                    }
                    <div className="tm30">
                        <p className="medium bp10 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                        {/* 등록물품 없을때 ▼ */}
                        {proList.length === 0 && (
                            <div className="none_data"><span>데이터가 없습니다.</span></div>
                        )}

                        {/* 등록물품 있을때 ▼ */}
                        {proList.length > 0 && 
                            <div className="list_result_box">
                                {proList.map((a,i) => {
                                    return (
                                        <div className="custom_table gray_table" key={i}>
                                            <table>
                                                <colgroup>
                                                    <col style={{ width: "23%" }} />
                                                    <col style={{ width: "27%" }} />
                                                    <col style={{ width: "23%" }} />
                                                    <col style={{ width: "27%" }} />
                                                </colgroup>
                                                <BidProductInfo 
                                                    ip_date_online={proList[i].ip_date_online || ""}
                                                    ip_no_online={proList[i].ip_no_online || ""}
                                                    ip_s_kind={proList[i].ip_s_kind || ""}
                                                    ip_auct_name={proList[i].ip_auct_name || ""}
                                                    ip_auct_date_start={proList[i].ip_auct_date_start || ""}
                                                    ip_auct_date_end={proList[i].ip_auct_date_end || ""}
                                                    gm_name={proList[i].gm_name === null ? proList[i].gm_stmd_name : proList[i].gm_name || ""}
                                                    ip_status={proList[i].ip_status || ""}
                                                    income_tot={proList[i].income_tot || ""}
                                                    income_quantity_tot={proList[i].income_quantity_tot || ""}
                                                    bidding_tot={proList[i].bidding_tot || ""}
                                                    success_tot={proList[i].success_tot || ""}
                                                    de_count={proList[i].de_count || ""}
                                                    de_memo_count={proList[i].de_memo_count || ""}
                                                    pindex={i +1 || 1}
                                                />
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        }

                        {/* 조회가 없을때 ▼ */}
                        {viewPaging === 0 && (
                        <div className="pagination">
                            <button className="prev">
                                <span>이전 페이지</span>
                            </button>
                            <ul className="num_box">
                                <li className="on">
                                    <button>1</button>
                                </li>
                            </ul>
                            <button className="next">
                                <span>다음 페이지</span>
                            </button>
                        </div>
                        )}
                        {/* 조회가 페이징보다 클때 ▼ */}
                        {viewPaging > 0 &&
                                <Pagination 
                                nowPage={page_no}
                                totalCnt={viewPaging}
                                setPerPage={perPage}
                                moveToPage={moveToPage}

                                />
                        }
                    </div>
                    </form>
                )}
                </Formik>
            </div>
        }

        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default OnlineBid;