import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import LeftCont from "../../../components/pc/component/LeftCont";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import { enum_api_uri, enum_de_status, enum_mo_title,enum_sell_kind,enum_ip_status } from "../../../config/enum";
import { productPopModify, newProductPop, proDetailPop, confirmPop } from "../../../store/popupSlice";
import { invIdx, invProIdx, invList } from "../../../store/invoiceSlice";
import Pagination from "../../../components/pc/component/Pagination";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { moTitle, detailPageBack } from "../../../store/commonSlice";
import * as CF from "../../../components/pc/component/Function";
import ConfirmPop from "../../../components/pc/component/popup/ConfirmPop";
import InputDatepicker from "../../../components/pc/component/InputDatepicker";

const InvoiceCheck = (props) => {
    //왼쪽메뉴 토글
    let toggle = localStorage.getItem("menuToggle");
    const [contFold, setContFold] = useState(toggle);

    useEffect(()=>{
        setContFold(contFold);
    },[contFold]);
    
    const [invoiceList, setInvoiceList] = useState([]);
    const popup = useSelector((state) => state.popup);
    const common = useSelector((state) => state.common);
    const invoice = useSelector((state) => state.invoice);
    const p_list_invoice = enum_api_uri.p_list_invoice;
    const p_modify_invoice = enum_api_uri.p_modify_invoice;
    registerLocale('ko', ko);
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const basicDate = new Date();
    const [ipDateStart, setIpDateStart] = useState('');
    const [ipDateEnd, setIpDateEnd] = useState('');
    const [ipStatus, setIpStatus] = useState('');
    const [dateError, setDateError] = useState(false);
    const [page_no, setPageNo] = useState(1);
    const perPage = 10;//페이지당 리스트수 
    const [totalCount, setTotalCount] = useState(0);
    const [viewPaging, setPaging] = useState(0);
    const location = useLocation();
    const [deltConfirm, setDeltConfirm] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [invoNum, setInvoNum] = useState({});
    const [scrollMove, setScrollMove] = useState(false);

    //모바일일때 헤더타이틀 변경
    useEffect(()=>{
        if(isMobile){
            let tit = enum_mo_title[location.pathname];
            dispatch(moTitle(tit));
        }
    },[]);

    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === null){
            setDeltConfirm(false);
            setConfirm(false);
        }
    },[popup.confirmPop]);

    //상세->목록으로 뒤로가기시 저장되었던 스크롤위치로 이동
    useEffect(()=>{
        setScrollMove(scrollMove);
        
        if(scrollMove){
            let ScrollY = sessionStorage.getItem("ScrollY");
            window.scrollTo(0,ScrollY); 
        }
    },[scrollMove]);

    useEffect(()=>{
        setIpStatus(ipStatus);
    },[ipStatus]);

    useEffect(()=>{
        setIpDateStart(ipDateStart);
    },[ipDateStart]);

    useEffect(()=>{
        setIpDateEnd(ipDateEnd);
    },[ipDateEnd]);

    const dStartChange = (date) => {
        setIpDateStart(date);
        setDateError(false);
    };

    const dEndChange = (date) => {
        setIpDateEnd(date);
        setDateError(false);
    };

    useEffect(()=>{
        setInvoiceList(invoiceList);
        console.log(invoiceList)
    },[invoiceList]);

    useEffect(() => {
        setTotalCount(totalCount);
    },[totalCount]);

    useEffect(() => {
        setPaging(viewPaging);
    },[viewPaging]);

    useEffect(() => {
        setInvoNum(invoNum);
    },[invoNum]);

    useEffect(()=>{ //처음 한번 리스트 불러오기a1
        
        // 상세페이지에서 뒤로가기클릭시 저장된 정보로 리스트불러오기
        if(common.detailPageBack === true){
            dispatch(detailPageBack(false));

            let listPage = JSON.parse(sessionStorage.getItem("listPage"));
            let pageNum = sessionStorage.getItem("pageNum");

            let val = [];
            val.ip_status = listPage.ip_status;
            val.ip_date_start = listPage.ip_date_start;
            val.ip_date_end = listPage.ip_date_end;
            val.page_no_now = pageNum;
            val.record_size = 10;

            let startDate = moment(listPage.ip_date_start).toISOString();
            startDate = new Date(startDate);
            let endDate = moment(listPage.ip_date_end).toISOString();
            endDate = new Date(endDate);

            setIpDateStart(startDate);
            setIpDateEnd(endDate);
            setIpStatus(listPage.ip_status);
            setPageNo(pageNum);
            getList(val);
        }
        if(common.detailPageBack === false){
            let val = [];
            val.ip_status = '';
            val.ip_date_start  = moment(basicDate).format('YYYYMMDD');
            val.ip_date_end  = moment(basicDate).format('YYYYMMDD');
            val.page_no_now = 1
            val.record_size = 10

            setIpDateStart(basicDate)
            setIpDateEnd(basicDate)
            setPageNo(1);
            getList(val);

            //리스트페이지 조회 데이터저장
            let listPageData = {
                ip_status:'',
                ip_date_start: moment(basicDate).format('YYYYMMDD'),
                ip_date_end: moment(basicDate).format('YYYYMMDD')
            };
            CF.setListPage(listPageData);
            CF.setPageNum(1);
        }
    },[]);

    //송품장 조회
    const submit = (values) => {
        let regex = /[^0-9]/g;
        let ip_status = ipStatus.replace(regex,'');
        let ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        let ip_date_end = moment(ipDateEnd).format('YYYYMMDD');

        let val = [];
        val.ip_status = ip_status;
        val.ip_date_start = ip_date_start;
        val.ip_date_end = ip_date_end;
        val.page_no_now = 1;
        val.record_size = 10;
        setPageNo(1);
        getList(val); //리스트 받아오기
        console.log(val)

        //리스트페이지 조회 데이터저장
        let listPageData = {
            ip_status:ip_status,
            ip_date_start:ip_date_start,
            ip_date_end:ip_date_end
        };
        CF.setListPage(listPageData);
        CF.setPageNum(1);
    };

    //페이징 숫자 클릭시
    const moveToPage = (goPage) => {
        //console.log('moveToPage:'+goPage);
        let val = [];
        val.ip_status = ipStatus;
        val.ip_date_start = moment(ipDateStart).format('YYYYMMDD');
        val.ip_date_end = moment(ipDateEnd).format('YYYYMMDD');
        val.page_no_now = goPage; //움직일 페이지
        val.record_size = 10;
        setPageNo(goPage);
        getList(val); //리스트 받아오기

        //리스트페이지 페이징숫자 저장
        CF.setPageNum(goPage);
    };

    const getList = (val) => {
        axios.get(`${p_list_invoice}?ip_date_start=${val.ip_date_start}&ip_date_end=${val.ip_date_end}&ip_status=${val.ip_status}&record_size=${val.record_size}&page_no=${val.page_no_now}`,
            {headers:{Authorization: `Bearer ${token}`}}
        )
        .then((res)=>{
            if(res.status === 200){
                // console.log(res);
                let data = res.data;
                let state = [...data.results];
                let resCount = data.total_record_count;
                setInvoiceList(state);//리스트 생성
                setTotalCount(resCount); // 검색결과 전체 건수 컨트롤
                setPaging(resCount); //페이징 컨트롤

                if(common.detailPageBack){
                    setScrollMove(true);
                }
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //송품장삭제
    const deltHandler = () => {
        axios.delete(`${p_modify_invoice.replace(':ip_date_online', invoNum.ip_date_online).replace(':ip_no_online', invoNum.ip_no_online)}`,{
            headers:{Authorization: `Bearer ${token}`}
        })
        .then((res)=>{
            if(res.status === 200){
                window.location.reload();
            }
        })
        .catch((error) => {
            const err_msg = CF.errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPopTit:'알림',
                confirmPopTxt: err_msg,
                confirmPopBtn:1,
            }));
            setConfirm(true);
        });
    };

    //송품장출력 
    const printPop = (ip_date,ip_no) => {
        window.open(`/print/${ip_date}/${ip_no}`);
    };

    //물풀 수정시
    useEffect(()=>{
        if(popup.newProductAdd){
            let newList = [...invoiceList];
            let updatedObject = {...newList[invoice.invIdx].detltrns[invoice.invProIdx]};
            updatedObject.de_farm_name = invoice.proModifyData.de_farm_name;
            updatedObject.gm_name = invoice.proModifyData.gm_name;
            updatedObject.de_weight = invoice.proModifyData.de_weight;
            updatedObject.de_quantity = invoice.proModifyData.de_quantity;
            updatedObject.de_cost2 = invoice.proModifyData.de_cost2;
            newList[invoice.invIdx].detltrns[invoice.invProIdx] = updatedObject;
            setInvoiceList(newList);
        }
    },[popup.newProductAdd]);


    return (<>
        {/* PC일때 */}
        {isBrowser &&
            <div className="page_sub">
                <div className={`cont cont_wrap ${contFold === "true" && "fold"}`}>
                    <LeftCont user={props.user} contFold={contFold} setContFold={setContFold}/>
                    <div className="right_cont">
                        <div className="inner_box">
                            <div className="top_tit">
                                <p className="tit">송품장 조회(입하신청 조회)</p>
                                <ul className="menu_list">
                                    <li>홈</li>
                                    <li>출하자</li>
                                    <li>송품장 조회</li>
                                </ul>
                            </div>
                            <Formik
                                initialValues={{
                                    // ip_status: ipStatus || "",
                                }}
                                onSubmit={submit}
                                validateOnMount={true}
                            >
                                {({values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                                    <form onSubmit={handleSubmit}>
                                        <ul className="search_box">
                                            <li>
                                                <p className="txt">출하일자</p>
                                                <div className="date_input">
                                                    <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                                    <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                                </div>
                                            </li>
                                            <li>
                                                <p className="txt">상태</p>
                                                <div className="clearfix">
                                                    <div className="custom_select rm5">
                                                        <select 
                                                            value={`ip_status_${ipStatus}`}
                                                            onChange={(e)=>{
                                                                let value = e.currentTarget.value;
                                                                    value = value.replace(/[^0-9]/g,'');
                                                                setIpStatus(value);
                                                            }}
                                                            style={{ display: 'block' }}
                                                        >
                                                            <option value="">전체</option>
                                                            <option value="ip_status_1">입하</option>
                                                            <option value="ip_status_2">입하승인</option>
                                                            <option value="ip_status_3">입하미승인</option>
                                                        </select>
                                                    </div>
                                                    <button className="btn_s" type="submit">조회</button>
                                                </div>
                                            </li>
                                        </ul>
                                        {dateError && 
                                            <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                                        }
                                        <div className="tm30">
                                            <p className="medium bp20">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                            {invoiceList.length == 0 && <div className="none_data"><span>데이터가 없습니다.</span></div>}
                                            {invoiceList.length > 0 && invoiceList.map((a,i) => {
                                                let sumQuantity = 0;
                                                let sumCost2 = 0;
                                                let ip_auct_name = invoiceList[i].ip_auct_name;
                                                let ip_auct_date_start = invoiceList[i].ip_auct_date_start;
                                                let ip_auct_date_end = invoiceList[i].ip_auct_date_end;
                                                let inKind = enum_sell_kind[a.ip_s_kind.trim()];
                                                let ip_status = invoiceList[i].ip_status.trim();
                                                let ip_status_txt = enum_ip_status[ip_status][0];

                                                return (
                                                    <div className="bp30" key={i}>
                                                        <div className={`flex_between bp10`}>
                                                            <p className="table_gray_txt">
                                                                {inKind}
                                                                <span>등록번호: {`${invoiceList[i].ip_date_online}-${invoiceList[i].ip_no_online}`}</span>
                                                                <span className="txt_sky bold">{ip_status_txt}</span>
                                                                {ip_auct_name !== null && ip_auct_name.trim().length > 0 &&
                                                                    <span>경매사 : {ip_auct_name}</span>
                                                                }
                                                                {ip_auct_date_start !== null && ip_auct_date_start.trim().length > 0 &&
                                                                    <span>{inKind}시간 : {CF.MDFWithHour(ip_auct_date_start,2)}
                                                                        {ip_auct_date_end !== null && ip_auct_date_end.trim().length > 0 &&
                                                                            "~"+CF.MDFWithHour(ip_auct_date_end,2)
                                                                        }
                                                                    </span>
                                                                }
                                                            </p>
                                                            <div>
                                                                <Link className="btn2_s"
                                                                    to={`/shipper/invoice-check/detail/${invoiceList[i].ip_date_online}-${invoiceList[i].ip_no_online}`}
                                                                    onClick={()=>{
                                                                        //리스트페이지 현재스크롤위치 저장
                                                                        CF.setScrollY(window.scrollY);
                                                                    }}
                                                                >
                                                                    보기
                                                                </Link>
                                                                {ip_status == 1 &&
                                                                    <button type="button" className="btn2_s lm10" onClick={()=>{
                                                                        let invo = {
                                                                            ip_date_online:invoiceList[i].ip_date_online,
                                                                            ip_no_online:invoiceList[i].ip_no_online
                                                                        }
                                                                        setInvoNum({...invo});
                                                                        setDeltConfirm(true);
                                                                        dispatch(confirmPop({
                                                                            confirmPopTit:'알림',
                                                                            confirmPopTxt:'삭제 하시겠습니까?',
                                                                            confirmPopBtn:2,
                                                                        }));
                                                                    }}>삭제</button>
                                                                }
                                                                <button type="button" className="btn2_s lm10" onClick={()=>{printPop(invoiceList[i].ip_date_online,invoiceList[i].ip_no_online)}}>송품장 출력</button>
                                                            </div>
                                                        </div>
                                                        <div className="custom_table">
                                                        {invoiceList[i].detltrns && invoiceList[i].detltrns.length > 0 ? (
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width:'50px'}} />
                                                                    <col style={{width:'15%'}} />
                                                                    <col style={{width:'auto'}} />
                                                                    <col style={{width:'auto'}} />
                                                                    <col style={{width:'auto'}} />
                                                                    <col style={{width:'auto'}} />
                                                                    <col style={{width:'auto'}} />
                                                                    <col style={{width:'100px'}} />
                                                                    <col style={{width:'70px'}} />
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        <th>번호</th>
                                                                        <th>생산자</th>
                                                                        <th>품목</th>
                                                                        <th>중량</th>
                                                                        <th>수량</th>
                                                                        <th>운임</th>
                                                                        <th>기사명</th>
                                                                        <th>상태</th>
                                                                        <th>수정</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {invoiceList[i].detltrns.map((a,idx) => {
                                                                        //상태
                                                                        let status = invoiceList[i].detltrns[idx].de_status.trim();
                                                                        status = enum_de_status[status][0];

                                                                        sumQuantity = sumQuantity + invoiceList[i].detltrns[idx].de_quantity;
                                                                        sumCost2 = sumCost2 + invoiceList[i].detltrns[idx].de_cost2;

                                                                        let weight = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_weight);
                                                                        let quantity = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_quantity);
                                                                        let cost2 = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_cost2);
                                                                        let de_farm_name = CF.objValueTrim(invoiceList[i].detltrns[idx].de_farm_name);
                                                                        
                                                                        return(
                                                                            <tr key={idx}>
                                                                                <td>{idx+1}</td>
                                                                                <td>{de_farm_name}</td>
                                                                                <td>
                                                                                    {ip_status < 2 ?
                                                                                        <Link 
                                                                                            to={`/shipper/invoice-check/detail/${invoiceList[i].detltrns[idx].de_ip_date}-${invoiceList[i].detltrns[idx].de_ip_no}`} 
                                                                                            onClick={() => {
                                                                                                //리스트페이지 현재스크롤위치 저장
                                                                                                CF.setScrollY(window.scrollY);

                                                                                                dispatch(newProductPop());
                                                                                                dispatch(productPopModify({
                                                                                                    deDate:invoiceList[i].detltrns[idx].de_date_online,
                                                                                                    deNo:invoiceList[i].detltrns[idx].de_no_online,
                                                                                                    deSeq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                    deSeq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                                    deFarmName:invoiceList[i].detltrns[idx].de_farm_name,
                                                                                                    deFarmCode:invoiceList[i].detltrns[idx].de_farm_code,
                                                                                                    deGodsCode:invoiceList[i].detltrns[idx].gm_code,
                                                                                                    deGodsName:invoiceList[i].detltrns[idx].gm_name,
                                                                                                    deWeight:invoiceList[i].detltrns[idx].de_weight,
                                                                                                    dePacking:invoiceList[i].detltrns[idx].de_packing_name,
                                                                                                    dePackingCode:invoiceList[i].detltrns[idx].de_packing,
                                                                                                    deQuality:invoiceList[i].detltrns[idx].de_quality_name,
                                                                                                    deQualityCode:invoiceList[i].detltrns[idx].de_quality,
                                                                                                    deHigh:invoiceList[i].detltrns[idx].de_high_name,
                                                                                                    deHighCode:invoiceList[i].detltrns[idx].de_high,
                                                                                                    deQuantity:invoiceList[i].detltrns[idx].de_quantity,
                                                                                                    deCost2:invoiceList[i].detltrns[idx].de_cost2,
                                                                                                    deDansu:invoiceList[i].detltrns[idx].de_dansu,
                                                                                                    deHopePri:invoiceList[i].detltrns[idx].de_hope_pri,
                                                                                                    deCont1:invoiceList[i].detltrns[idx].de_cont1,
                                                                                                    deCont2:invoiceList[i].detltrns[idx].de_cont2,
                                                                                                    deCont3:invoiceList[i].detltrns[idx].de_cont3,
                                                                                                    deImg1:invoiceList[i].detltrns[idx].de_img1,
                                                                                                    deImg2:invoiceList[i].detltrns[idx].de_img2,
                                                                                                    deVideo:invoiceList[i].detltrns[idx].de_video,
                                                                                                }));
                                                                                            }}
                                                                                        >
                                                                                            <div className="over_txt"><span>{invoiceList[i].detltrns[idx].gm_name}</span></div>
                                                                                        </Link>
                                                                                        :
                                                                                            <div className="over_txt cursor" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                                                let productData = JSON.parse(localStorage.getItem('productData'));
                                                                                                invoiceData = {
                                                                                                    ip_date_online:invoiceList[i].ip_date_online.trim(),
                                                                                                    ip_no_online:invoiceList[i].ip_no_online.trim(),
                                                                                                    ip_s_kind:invoiceList[i].ip_s_kind.trim(),
                                                                                                    ip_kind:invoiceList[i].ip_kind.trim(),
                                                                                                    ip_loca:invoiceList[i].ip_loca.trim(),
                                                                                                };
                                                                                                productData = {
                                                                                                    de_date_online:invoiceList[i].detltrns[idx].de_date_online.trim(),
                                                                                                    de_no_online:invoiceList[i].detltrns[idx].de_no_online.trim(),
                                                                                                    de_seq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                    de_seq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                                }
                                                                                                localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                                                                localStorage.setItem("productData", JSON.stringify(productData));
                                                                                                dispatch(proDetailPop({
                                                                                                    proDetailPop:true,
                                                                                                }));
                                                                                            }}><span>{invoiceList[i].detltrns[idx].gm_name}</span></div>
                                                                                    }
                                                                                </td>
                                                                                <td className="tx_r">{weight}kg</td>
                                                                                <td className="tx_r">{quantity}개</td>
                                                                                <td className="tx_r">{cost2}원</td>
                                                                                <td>
                                                                                    {invoiceList[i].detltrns[idx].de_deli_nm !== null ? 
                                                                                        invoiceList[i].detltrns[idx].de_deli_nm : '-'
                                                                                    }
                                                                                </td>
                                                                                <td>{status}</td>
                                                                                <td>
                                                                                    {ip_status < 2 ? 
                                                                                        <button  
                                                                                            className="btn2_s" 
                                                                                            // to={`/shipper/invoice-check/detail/${invoiceList[i].detltrns[idx].de_ip_date}-${invoiceList[i].detltrns[idx].de_ip_no}`} 
                                                                                            onClick={() => {
                                                                                                //리스트페이지 현재스크롤위치 저장
                                                                                                // CF.setScrollY(window.scrollY);

                                                                                                dispatch(invIdx(i));
                                                                                                dispatch(invProIdx(idx));

                                                                                                let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                                                invoiceData = {
                                                                                                    ip_date_online:invoiceList[i].ip_date_online.trim(),
                                                                                                    ip_no_online:invoiceList[i].ip_no_online.trim(),
                                                                                                    ip_s_kind:invoiceList[i].ip_s_kind.trim(),
                                                                                                    ip_kind:invoiceList[i].ip_kind.trim(),
                                                                                                    ip_loca:invoiceList[i].ip_loca.trim(),
                                                                                                    ip_status:invoiceList[i].ip_status.trim(),
                                                                                                }
                                                                                                localStorage.setItem("invoiceData", JSON.stringify(invoiceData));

                                                                                                dispatch(newProductPop());
                                                                                                dispatch(productPopModify({
                                                                                                    deDate:invoiceList[i].detltrns[idx].de_date_online,
                                                                                                    deNo:invoiceList[i].detltrns[idx].de_no_online,
                                                                                                    deSeq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                    deSeq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                                    deFarmName:invoiceList[i].detltrns[idx].de_farm_name,
                                                                                                    deFarmCode:invoiceList[i].detltrns[idx].de_farm_code,
                                                                                                    deGodsCode:invoiceList[i].detltrns[idx].gm_code,
                                                                                                    deGodsName:invoiceList[i].detltrns[idx].gm_name,
                                                                                                    deWeight:invoiceList[i].detltrns[idx].de_weight,
                                                                                                    dePacking:invoiceList[i].detltrns[idx].de_packing_name,
                                                                                                    dePackingCode:invoiceList[i].detltrns[idx].de_packing,
                                                                                                    deQuality:invoiceList[i].detltrns[idx].de_quality_name,
                                                                                                    deQualityCode:invoiceList[i].detltrns[idx].de_quality,
                                                                                                    deHigh:invoiceList[i].detltrns[idx].de_high_name,
                                                                                                    deHighCode:invoiceList[i].detltrns[idx].de_high,
                                                                                                    deQuantity:invoiceList[i].detltrns[idx].de_quantity,
                                                                                                    deCost2:invoiceList[i].detltrns[idx].de_cost2,
                                                                                                    deDansu:invoiceList[i].detltrns[idx].de_dansu,
                                                                                                    deHopePri:invoiceList[i].detltrns[idx].de_hope_pri,
                                                                                                    deCont1:invoiceList[i].detltrns[idx].de_cont1,
                                                                                                    deCont2:invoiceList[i].detltrns[idx].de_cont2,
                                                                                                    deCont3:invoiceList[i].detltrns[idx].de_cont3,
                                                                                                    deImg1:invoiceList[i].detltrns[idx].de_img1,
                                                                                                    deImg2:invoiceList[i].detltrns[idx].de_img2,
                                                                                                    deVideo:invoiceList[i].detltrns[idx].de_video,
                                                                                                }));
                                                                                            }}
                                                                                        >
                                                                                            수정
                                                                                        </button>
                                                                                        :
                                                                                        <button 
                                                                                            className="btn2_s"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                                                let productData = JSON.parse(localStorage.getItem('productData'));
                                                                                                invoiceData = {
                                                                                                    ip_date_online:invoiceList[i].ip_date_online.trim(),
                                                                                                    ip_no_online:invoiceList[i].ip_no_online.trim(),
                                                                                                    ip_s_kind:invoiceList[i].ip_s_kind.trim(),
                                                                                                    ip_kind:invoiceList[i].ip_kind.trim(),
                                                                                                    ip_loca:invoiceList[i].ip_loca.trim(),
                                                                                                };
                                                                                                productData = {
                                                                                                    de_date_online:invoiceList[i].detltrns[idx].de_date_online.trim(),
                                                                                                    de_no_online:invoiceList[i].detltrns[idx].de_no_online.trim(),
                                                                                                    de_seq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                    de_seq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                                }
                                                                                                localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                                                                localStorage.setItem("productData", JSON.stringify(productData));
                                                                                                dispatch(proDetailPop({
                                                                                                    proDetailPop:true,
                                                                                                }));
                                                                                            }}
                                                                                        >
                                                                                            보기
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    <tr className="total_tr">
                                                                        <td colSpan='4'>합계</td>
                                                                        <td className="tx_r">{CF.MakeIntComma(sumQuantity)}개</td>
                                                                        <td className="tx_r">{CF.MakeIntComma(sumCost2)}원</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>)
                                                            : (<div className="none_data"><span>등록된물품이 없습니다.</span></div>)
                                                        }   
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {/* 조회가 없을때 ▼ */}
                                        {viewPaging === 0 && (
                                            <div className="pagination">
                                                <button className="prev">
                                                    <span>이전 페이지</span>
                                                </button>
                                                <ul className="num_box">
                                                    <li className="on">
                                                        <button>1</button>
                                                    </li>
                                                </ul>
                                                <button className="next">
                                                    <span>다음 페이지</span>
                                                </button>
                                            </div>
                                        )}
                                        {/* 조회가 페이징보다 클때 ▼ */}
                                        {viewPaging > 0 &&
                                            <Pagination 
                                                nowPage={page_no}
                                                totalCnt={viewPaging}
                                                setPerPage={perPage}
                                                moveToPage={moveToPage}
                                            />
                                        }
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* 모바일일때 */}
        {isMobile &&
            <div className="page_sub">
                <Formik
                    initialValues={{
                        // ip_status: ipStatus || "",
                    }}
                    onSubmit={submit}
                    validateOnMount={true}
                >
                    {({values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="search_box">
                                <ul>
                                    <li>
                                        <p className="txt">출하일자</p>
                                        <div className="date_input">
                                            <InputDatepicker selectedDate={ipDateStart} ChangeHandler={dStartChange} />
                                            <InputDatepicker selectedDate={ipDateEnd} ChangeHandler={dEndChange} />
                                        </div>
                                    </li>
                                    <li>
                                        <p className="txt">상태</p>
                                        <div className="custom_select">
                                            <select 
                                                value={`ip_status_${ipStatus}`}
                                                onChange={(e)=>{
                                                    let value = e.currentTarget.value;
                                                        value = value.replace(/[^0-9]/g,'');
                                                    setIpStatus(value);
                                                }}
                                                style={{ display: 'block' }}
                                            >
                                                <option value="">전체</option>
                                                <option value="ip_status_1">입하</option>
                                                <option value="ip_status_2">입하승인</option>
                                                <option value="ip_status_3">입하미승인</option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                                <button className="btn_s2 w_100 tm10" type="submit">조회</button>
                            </div>
                            {dateError && 
                                <div className="alert_txt f_12">출하일자를 선택해주세요.</div>
                            }
                            <div className="tm20">
                                <p className="medium bp20 lp10">검색 결과 : <span className="txt_sky">{totalCount}</span>건</p>
                                {invoiceList.length == 0 && <div className="none_data"><span>데이터가 없습니다.</span></div>}
                                {invoiceList.length > 0 && invoiceList.map((a,i) => {
                                    let sumWeight = 0;
                                    let sumQuantity = 0;
                                    let sumCost2 = 0;
                                    let ip_auct_name = invoiceList[i].ip_auct_name;
                                    let ip_auct_date_start = invoiceList[i].ip_auct_date_start;
                                    let ip_auct_date_end = invoiceList[i].ip_auct_date_end;
                                    let ip_status = invoiceList[i].ip_status.trim();
                                    let ip_status_txt = enum_ip_status[ip_status][0];

                                    return (
                                        <div className="bp30" key={i}>
                                            <div className="custom_table2">
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: "23%" }} />
                                                        <col style={{ width: "27%" }} />
                                                        <col style={{ width: "23%" }} />
                                                        <col style={{ width: "27%" }} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th>등록번호</th>
                                                            <td colSpan="3">
                                                                <span>{`${invoiceList[i].ip_date_online}-${invoiceList[i].ip_no_online}`}</span>
                                                                {ip_status < 3 && <>
                                                                    <Link to={`/shipper/invoice-check/detail/${invoiceList[i].ip_date_online}-${invoiceList[i].ip_no_online}`} onClick={()=>{
                                                                        //리스트페이지 현재스크롤위치 저장
                                                                        CF.setScrollY(window.scrollY);
                                                                    }} className="btn_view lm10">보기</Link>
                                                                    {ip_status == 1 && 
                                                                        <button type="button" className="btn2_s lm10 f_13" onClick={()=>{
                                                                            let invo = {
                                                                                ip_date_online:invoiceList[i].ip_date_online,
                                                                                ip_no_online:invoiceList[i].ip_no_online
                                                                            }
                                                                            setInvoNum({...invo});
                                                                            setDeltConfirm(true);
                                                                            dispatch(confirmPop({
                                                                                confirmPopTit:'알림',
                                                                                confirmPopTxt:'삭제 하시겠습니까?',
                                                                                confirmPopBtn:2,
                                                                            }));
                                                                        }}>삭제</button>
                                                                    }
                                                                </>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>상태</th>
                                                            <td><span className="tag">{ip_status_txt}</span></td>
                                                            <th>경매사</th>
                                                            <td>
                                                                {ip_auct_name !== null && ip_auct_name.trim().length > 0 ?
                                                                    <span className="tag">{ip_auct_name}</span> : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="rp0">경매/입찰<br/>시간</th>
                                                            <td colSpan="3">
                                                                {ip_auct_date_start !== null && ip_auct_date_start.trim().length > 0 &&
                                                                    <span>{CF.MDFWithHour(ip_auct_date_start,2)}
                                                                        {ip_auct_date_end !== null && ip_auct_date_end.trim().length > 0 &&
                                                                            "~"+CF.MDFWithHour(ip_auct_date_end,2)
                                                                        }
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                            </div>
                                            
                                            {invoiceList[i].detltrns && invoiceList[i].detltrns.length > 0 ? (<>
                                                    {invoiceList[i].detltrns.map((a,idx) => {
                                                        //상태
                                                        let status = invoiceList[i].detltrns[idx].de_status.trim();
                                                        status = enum_de_status[status][0];

                                                        sumWeight = sumWeight + invoiceList[i].detltrns[idx].de_weight;
                                                        sumQuantity = sumQuantity + invoiceList[i].detltrns[idx].de_quantity;
                                                        sumCost2 = sumCost2 + invoiceList[i].detltrns[idx].de_cost2;

                                                        let weight = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_weight);
                                                        let quantity = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_quantity);
                                                        let cost2 = CF.MakeIntComma(invoiceList[i].detltrns[idx].de_cost2);
                                                        let de_farm_name = CF.objValueTrim(invoiceList[i].detltrns[idx].de_farm_name);
                                                        
                                                        return(
                                                            <div className="custom_table gray_table t_border2 tm10" key={idx}>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: "23%" }} />
                                                                        <col style={{ width: "27%" }} />
                                                                        <col style={{ width: "23%" }} />
                                                                        <col style={{ width: "27%" }} />
                                                                    </colgroup>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th className="tx_l">생산자</th>
                                                                            <td colSpan="3" className="tx_l">{de_farm_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="tx_l">품목</th>
                                                                            <td colSpan="3">
                                                                                <div className="flex_wrap">
                                                                                <div className="over_txt rm10"><span>{invoiceList[i].detltrns[idx].gm_name}</span></div>
                                                                                {ip_status < 2 ? 
                                                                                    <button type="button" 
                                                                                        className="btn_modify" 
                                                                                        // to={`/shipper/invoice-check/detail/${invoiceList[i].detltrns[idx].de_ip_date}-${invoiceList[i].detltrns[idx].de_ip_no}`} 
                                                                                        onClick={() => {
                                                                                            //리스트페이지 현재스크롤위치 저장
                                                                                            // CF.setScrollY(window.scrollY);

                                                                                            dispatch(invIdx(i));
                                                                                            dispatch(invProIdx(idx));

                                                                                            let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                                            invoiceData = {
                                                                                                ip_date_online:invoiceList[i].ip_date_online.trim(),
                                                                                                ip_no_online:invoiceList[i].ip_no_online.trim(),
                                                                                                ip_s_kind:invoiceList[i].ip_s_kind.trim(),
                                                                                                ip_kind:invoiceList[i].ip_kind.trim(),
                                                                                                ip_loca:invoiceList[i].ip_loca.trim(),
                                                                                                ip_status:invoiceList[i].ip_status.trim(),
                                                                                            }
                                                                                            localStorage.setItem("invoiceData", JSON.stringify(invoiceData));

                                                                                            dispatch(newProductPop());
                                                                                            dispatch(productPopModify({
                                                                                                deDate:invoiceList[i].detltrns[idx].de_date_online,
                                                                                                deNo:invoiceList[i].detltrns[idx].de_no_online,
                                                                                                deSeq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                deSeq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                                deFarmName:invoiceList[i].detltrns[idx].de_farm_name,
                                                                                                deFarmCode:invoiceList[i].detltrns[idx].de_farm_code,
                                                                                                deGodsCode:invoiceList[i].detltrns[idx].gm_code,
                                                                                                deGodsName:invoiceList[i].detltrns[idx].gm_name,
                                                                                                deWeight:invoiceList[i].detltrns[idx].de_weight,
                                                                                                dePacking:invoiceList[i].detltrns[idx].de_packing_name,
                                                                                                dePackingCode:invoiceList[i].detltrns[idx].de_packing,
                                                                                                deQuality:invoiceList[i].detltrns[idx].de_quality_name,
                                                                                                deQualityCode:invoiceList[i].detltrns[idx].de_quality,
                                                                                                deHigh:invoiceList[i].detltrns[idx].de_high_name,
                                                                                                deHighCode:invoiceList[i].detltrns[idx].de_high,
                                                                                                deQuantity:invoiceList[i].detltrns[idx].de_quantity,
                                                                                                deCost2:invoiceList[i].detltrns[idx].de_cost2,
                                                                                                deDansu:invoiceList[i].detltrns[idx].de_dansu,
                                                                                                deHopePri:invoiceList[i].detltrns[idx].de_hope_pri,
                                                                                                deCont1:invoiceList[i].detltrns[idx].de_cont1,
                                                                                                deCont2:invoiceList[i].detltrns[idx].de_cont2,
                                                                                                deCont3:invoiceList[i].detltrns[idx].de_cont3,
                                                                                                deImg1:invoiceList[i].detltrns[idx].de_img1,
                                                                                                deImg2:invoiceList[i].detltrns[idx].de_img2,
                                                                                                deVideo:invoiceList[i].detltrns[idx].de_video,
                                                                                            }));
                                                                                        }}
                                                                                    >
                                                                                        수정
                                                                                    </button>
                                                                                    :
                                                                                    <button 
                                                                                        className="btn_view"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            let invoiceData = JSON.parse(localStorage.getItem('invoiceData'));
                                                                                            let productData = JSON.parse(localStorage.getItem('productData'));
                                                                                            invoiceData = {
                                                                                                ip_date_online:invoiceList[i].ip_date_online.trim(),
                                                                                                ip_no_online:invoiceList[i].ip_no_online.trim(),
                                                                                                ip_s_kind:invoiceList[i].ip_s_kind.trim(),
                                                                                                ip_kind:invoiceList[i].ip_kind.trim(),
                                                                                                ip_loca:invoiceList[i].ip_loca.trim(),
                                                                                            };
                                                                                            productData = {
                                                                                                de_date_online:invoiceList[i].detltrns[idx].de_date_online.trim(),
                                                                                                de_no_online:invoiceList[i].detltrns[idx].de_no_online.trim(),
                                                                                                de_seq:invoiceList[i].detltrns[idx].de_seq,
                                                                                                de_seq2:invoiceList[i].detltrns[idx].de_seq2,
                                                                                            }
                                                                                            localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
                                                                                            localStorage.setItem("productData", JSON.stringify(productData));
                                                                                            dispatch(proDetailPop({
                                                                                                proDetailPopType:true,
                                                                                            }));
                                                                                        }}
                                                                                    >
                                                                                        보기
                                                                                    </button>
                                                                                }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="tx_l">중량</th>
                                                                            <td className="tx_r">{weight}kg</td>
                                                                            <th className="tx_l">수량</th>
                                                                            <td className="tx_r">{quantity}개</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="tx_l">운임</th>
                                                                            <td className="tx_r">{cost2}원</td>
                                                                            <th className="tx_l">기사명</th>
                                                                            <td className="tx_l">
                                                                                {invoiceList[i].detltrns[idx].de_deli_nm !== null ? 
                                                                                    invoiceList[i].detltrns[idx].de_deli_nm : '-'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="tx_l">상태</th>
                                                                            <td colSpan="3" className="tx_l"><span className="tag">{status}</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        );
                                                    })}
                                                </>)
                                                : (<div className="custom_table t_border2"><div className="none_data"><span>등록된물품이 없습니다.</span></div></div>)
                                            }   
                                        </div>
                                    );
                                })}
                            </div>
                            {/* 조회가 없을때 ▼ */}
                            {viewPaging === 0 && (
                                <div className="pagination">
                                    <button className="prev">
                                        <span>이전 페이지</span>
                                    </button>
                                    <ul className="num_box">
                                        <li className="on">
                                            <button>1</button>
                                        </li>
                                    </ul>
                                    <button className="next">
                                        <span>다음 페이지</span>
                                    </button>
                                </div>
                            )}
                            {/* 조회가 페이징보다 클때 ▼ */}
                            {viewPaging > 0 &&
                                <Pagination 
                                    nowPage={page_no}
                                    totalCnt={viewPaging}
                                    setPerPage={perPage}
                                    moveToPage={moveToPage}
                                />
                            }
                        </form>
                    )}
                </Formik>
            </div>
        }

        {/* 송품장삭제 confirm팝업 */}
        {deltConfirm && <ConfirmPop onClickHandler={deltHandler} />}
        
        {/* 확인confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default InvoiceCheck;